function encodeTriplet(
	e1: number,
	e2: number,
	e3: number,
) {
	/* eslint-disable no-bitwise */
	const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	const enc1 = e1 >> 2;
	const enc2 = ((e1 & 3) << 4) | (e2 >> 4);
	const enc3 = ((e2 & 15) << 2) | (e3 >> 6);
	const enc4 = e3 & 63;
	return keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
	/* eslint-enable no-bitwise */
}

function encodeRGB(
	r: number,
	g: number,
	b: number,
) {
	return encodeTriplet(
		0,
		r,
		g,
	) + encodeTriplet(
		b,
		255,
		255,
	);
}

function encodeHex(s: string) {
	s = s.substring(
		1,
		7,
	);
	if (s.length < 6) {
		s = s[0] + s[0] + s[1] + s[1] + s[2] + s[2];
	}
	return encodeRGB(
		parseInt(
			s[0] + s[1],
			16,
		),
		parseInt(
			s[2] + s[3],
			16,
		),
		parseInt(
			s[4] + s[5],
			16,
		),
	);
}

const getPixelUrl = (hexColor?: string) => {
	const dataURL = hexColor
		? `data:image/gif;base64,R0lGODlhAQABAPAA${encodeHex(hexColor)}/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
		: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

	return dataURL;
};

export default getPixelUrl;
