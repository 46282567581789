import './defines';
import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component
export default class SavingProjectView extends Vue.extend(Template) {
	@Prop({ type: Boolean, default: false })
	public hasCancel!: boolean;

	onCancel() {
		this.$emit('cancel');
	}
}
