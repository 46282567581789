import { render, staticRenderFns } from "./template.vue?vue&type=template&id=368a7860&scoped=true&lang=pug&"
var script = {}
import style0 from "./template.vue?vue&type=style&index=0&id=368a7860&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368a7860",
  null
  
)

export default component.exports