export default function getPermutations<T>(arr: T[]): T[][] {
	const result: T[][] = [];

	function permute(current: T[], remaining: T[]) {
		if (remaining.length === 0) {
			result.push([...current]);
			return;
		}

		for (let i = 0; i < remaining.length; i += 1) {
			const next = remaining.splice(
				i,
				1,
			)[0];
			current.push(next);
			permute(
				current,
				remaining,
			);
			remaining.splice(
				i,
				0,
				next,
			);
			current.pop();
		}
	}

	permute(
		[],
		arr,
	);

	return result;
}
