// eslint-disable-next-line spaced-comment,@typescript-eslint/triple-slash-reference
///<reference path="../customTypings/global.d.ts" />
import {
	type Colors,
	type Hex3,
	type Hex6,
} from '@sosocio/color-converter';
import { AxiosRequestConfig } from 'axios';
import DropboxSDK, {
	Dropbox,
	DropboxAuth,
} from 'dropbox';
import { MimeType } from 'file-type';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { Component } from 'vue';

// Regular types (alphabetical)
export interface AjaxOptions {
	auth: boolean; // send authorization headers with request?
	debug?: {
		offline?: boolean; // show dialog with offline message when request detects offline status?
		dialog?: boolean; // show dialog with error?
		abort?: boolean; // show abort request button in error dialog?
		log?: boolean;
	};
	retry?: number; // number of times to automatically retry a failed request
	wait?: boolean; // wait for other requests to finish?
}

export interface AjaxRequestArguments {
	[0]: AxiosRequestConfig;
	[1]: Required<AjaxOptions>;
}

export interface AnalyticsEcommerceProduct {
	// Product id displayed on the list
	product_id: string; // eslint-disable-line camelcase
	// Sku of the product being viewed
	sku: string;
	// Product category being viewed
	category?: string;
	// Name of the product being viewed
	name?: string;
	// Brand associated with the product
	brand?: string;
	// Variant of the product (e.g. Black)
	variant?: string;
	// Price ($) of the product being viewed
	price: number;
	// Quantity of a product
	quantity: number;
	// Coupon code associated with a product (e.g MAY_DEALS_3)
	coupon?: string;
	// Position in the product list (ex. 3)
	position?: number;
	// URL of the product page
	url?: string;
	// Image url of the product
	image_url?: string; // eslint-disable-line camelcase
}

export interface AnalyticsSessionProperties {
	trackerId?: number;
	platform?: PlatformType;
	appVersion?: string;
}

export interface AnalyticsProjectProperties {
	groupid: DB.ProductModel['group'];
	typeid: DB.ProductModel['typeid'];
	variantid: DB.OfferingModel['variantid'] | null;
	product_origin: DB.ProductModel['origin'];
	type?: DB.OfferingModel['type'];
}

export type AnalyticsCreateProjectProperties = AnalyticsSessionProperties & AnalyticsProjectProperties;

export type AnalyticsOpenProjectProperties = AnalyticsSessionProperties & AnalyticsProjectProperties;

export interface AnalyticsProjectReadyProperties extends AnalyticsProjectProperties, AnalyticsSessionProperties {
	pageCount: number;
	photoCount: number;
}

export interface AnalyticsUserProperties {
	Affiliateid?: DB.UserModel['affiliateid'];
	Language?: string | null;
	Currency?: DB.UserModel['currency'];
	ISO?: DB.CountryModel['iso'];
	First_name?: DB.UserModel['first_name'];
	Last_name?: DB.UserModel['last_name'];
	Email?: DB.UserModel['email'];
	'Account Created'?: DB.UserModel['timestamp'];
}

export interface AnalyticsSubController {
	init: (deviceDetails: DeviceDetails) => boolean;
	aliasUser: (
		oldUserId: DB.UserModel['id'],
		newUserId: DB.UserModel['id'],
	) => void;
	registerUser: () => void;
	identifyUser: () => void;
	logout: () => void;
	setExperimentFlags: (flags: Record<string, string | number | boolean | null>) => void;
	setUserProperties: (objProperties: AnalyticsUserProperties) => void;
	trackPageView: (
		route: string,
		title: string,
		objProperties: AnalyticsSessionProperties,
	) => void;
	trackEvent: (
		action: string,
		objProperties: AnalyticsSessionProperties & Record<string, any>,
	) => void;
	trackProductReady: (
		productModel: DB.ProductModel | null,
		objPrice: PricingObject | null,
		objProperties: AnalyticsProjectReadyProperties,
	) => void;
	trackPerformance: (
		action: string,
		timing: number,
		objProperties: Record<string, any>,
	) => void;
	trackAddToCart: (
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
		objProperties: AnalyticsSessionProperties
	) => void;
	trackRemoveFromCart: (
		cartItemModel: DB.ShoppingCartItemModel,
		objProperties: AnalyticsSessionProperties,
	) => void;
	trackCreateProject: (
		productModel: DB.ProductModel,
		objProperties: AnalyticsCreateProjectProperties,
	) => void;
	trackOpenProduct: (
		productModel: DB.ProductModel,
		objProperties: AnalyticsOpenProjectProperties,
	) => void;
	trackTransaction: (
		orderModel: DB.OrderModel,
		orderItems: DB.OrderItemModel[],
		objProperties: AnalyticsSessionProperties,
	) => void;
}

export interface BulkProductTypeModel extends DB.BulkProductTypeModel {
	quantity?: number;
}

export type ButtonToggleComponentVariant = 'default' | 'big' | 'big-filled';

export interface ChannelModel extends DB.PhotoSourceModel {
	profile: null | ChannelProfileModel;
}

export interface ChannelProfileModel {
	id: string|null;
	picture?: string;
	first_name?: string;
	last_name?: string;
	display_name?: string;
	email?: string;
	[key: string]: any;
}

export interface ColorPickerFromColor {
	color: string;
	visual?: string;
}

export type ColorPickerFromColors = ColorPickerFromColor[];

export interface ColorPickerPantonePalette {
	pantone: string;
	real: string;
}

export type ColorUtilsGetDominantOptions = Exclude<ColorUtilsGetPaletteOptions, 'colorCount'>;

export type ColorUtilsGetPaletteFinalOptions = {
	[K in keyof ColorUtilsGetPaletteOptions]-?: ColorUtilsGetPaletteOptions[K];
}

export interface ColorUtilsGetPaletteOptions {
	colorCount?: number;
	backgroundColor?: string;
	quality?: number;
	region?: ColorUtilsGetPaletteOptionsRegion;
	sourceImage: HTMLImageElement | HTMLCanvasElement;
}

export interface ColorUtilsGetPaletteOptionsRegion {
	height: number;
	width: number;
	x: number;
	y: number;
}

export interface ConnectFolderData {
	id: string;
	name: string;
	parentid?: string;
}

export interface ConnectorBridgeResponseData {
	data: any;
	paging: {
		nextOptions?: any;
		nextPage?: string;
	};
}

export interface CrossSellInspire {
	id: DB.CrossSellModel['id'];
	offeringid: DB.CrossSellModel['crosssellid'];
	themeid: DB.CrossSellModel['themeid'];
	image: string;
	photos: DB.PhotoModel[];
	showOfferingOption: DB.CrossSellModel['showOfferingOption'];
}

export interface CrossSellSuggestion {
	id: CrossSellInspire['id'];
	offeringid: CrossSellInspire['offeringid'];
	image: CrossSellInspire['image'];
	themeid?: CrossSellInspire['themeid'];
	photoModels?: CrossSellInspire['photos'];
	showOfferingOption: CrossSellInspire['showOfferingOption'];
}

interface WebDeviceDetails {
	browserName: string;
	browserVersion: string;
	browserMajorVersion: number;
}

export interface DeviceDetails {
	deviceUUID?: string;
	os: NativeDeviceDetails['platform'] | string;
	platform: 'Native' | 'Web';
	platformDetails: NativeDeviceDetails | WebDeviceDetails;
}

export interface DialogButtonModel {
	id: string;
	image?: string;
	text: string;
	target?: (
		payload?: any,
		event?: {
			preventDefault: () => void;
		},
	) => void;
}

export interface DialogCheckBoxModel {
	id: string;
	target?: (checked: boolean) => any;
	text: string;
}

export interface DialogOptions {
	beforeClose?: DialogModel['beforeClose'];
	bodyStyle?: DialogModel['bodyStyle'];
	buttons?: DialogModel['buttons'];
	checkBoxes?: DialogModel['checkBoxes'];
	chrome?: DialogModel['chrome'];
	code?: DialogModel['code'];
	data?: DialogModel['data'];
	description?: DialogModel['description'];
	focusMode?: DialogModel['focusMode'];
	hasclose?: DialogModel['hasclose'];
	height?: DialogModel['height'];
	lightModal?: DialogModel['lightModal'];
	modal?: DialogModel['modal'];
	multipleButtons?: DialogModel['multipleButtons'];
	noBodyPadding?: DialogModel['noBodyPadding'];
	onDateChange?: DialogModel['onDateChange'];
	opacity?: DialogModel['opacity'];
	options?: DialogModel['options'];
	picker?: DialogModel['picker'];
	replaceTags?: {
		[key: string]: string | number;
	};
	showLogin?: DialogModel['showLogin'];
	showSignup?: DialogModel['showSignup'];
	title?: DialogModel['title'];
	text?: DialogModel['text'];
	type?: DialogModel['type'];
	vueListeners?: DialogModel['vueListeners'];
	vueProps?: DialogModel['vueProps'];
	vueView?: DialogModel['vueView'];
	vueViewProps?: DialogModel['vueViewProps'];
	width?: DialogModel['width'];
	wrapButtons?: DialogModel['wrapButtons'];
}

export type DialogGroup =
	| 'main'
	| 'loader'
	| 'auth'
	| 'error'

export type DialogType =
	| 'default'
	| 'loader'
	| 'confirm'
	| 'prompt'
	| 'alert'
	| 'progress'
	| 'error'
	| 'date'

export interface DialogClosePayloadVirtalOfferingSelect {
	typeid: DB.OfferingModel['id'] | 'bestFit';
	variantid?: DB.OfferingModel['variantid'];
	layoutid?: DB.LayoutModel['id'];
	offeringid?: DB.OfferingModel['id'];
}

export interface DialogModel {
	/** Function to run before the dialog is closed */
	beforeClose?: (payload?: any) => void;
	bodyStyle?: Partial<CSSStyleRule['style']>;
	buttons: DialogButtonModel[];
	checkBoxes?: DialogCheckBoxModel[];
	/** Show the dialog chrome? (padding etc.) */
	chrome?: boolean;
	/** Unique identifier for the dialog model */
	cid: string | null;
	code?: number | null;
	data?: {
		[key: string]: any;
	}[];
	description: string | null;
	/** Focus mode covers everything else on the screen */
	focusMode: boolean;
	/** Can the dialog be closed by the user? */
	hasclose: boolean;
	height: number;
	lightModal: boolean;
	/** Show a semi-transparent modal behind the dialog? */
	modal: boolean;
	multipleButtons: boolean; // Does the dialog have multiple select buttons?
	noBodyPadding?: boolean;
	/** Function hook to invoke when changing the date picker inside the dialog */
	onDateChange?: (objData: Date) => void;
	opacity: number;
	options?: Record<string, any>;
	picker?: boolean;
	posy: number;
	/** Show the dialog in the screen? */
	show: boolean;
	showLogin?: boolean;
	showSignup?: boolean;
	/** Body text of the dialog */
	text: string | null;
	/** Title shown in the dialog */
	title: string | null;
	type?: DialogType;
	vueListeners?: Record<string, (...args: any[]) => any>;
	vueProps?: Record<string, any> | null;
	vueView?: Component | null;
	vueViewProps?: null | (() => Record<string, any>);
	/** Define the default (maximum) width of the dialog in pixels */
	width: number;
	wrapButtons?: boolean;
}

export interface ExternalCartItemModel {
	id: string;
	name: string;
	price: number;
	quantity: number;
}

export interface FontModel extends DB.FontModel {
	_attempt: number;
	_attemptDatauri: number;
	_datauri: string;
	_failed: boolean;
	_failedDatauri: boolean;
	_fontUrl: string;
	_loaded: boolean;
	_loadedBold: boolean;
	_loadedBoldItalic: boolean;
	_loadedItalic: boolean;
	_loadedRegular: boolean;
	_loading: boolean;
	_loadingDataUri: boolean;
}

export interface GridCell {
	x: number;
	y: number;
	w: number;
	h: number;
}
export type GridRatio = [1, 1] | [2, 3] | [3, 2] | [3, 4] | [4, 3] | [9, 16] | [16, 9] | [9, 21] | [21, 9];
export type GridRatioLabel = '1_1' | '2_3' | '3_2' | '3_4' | '4_3' | '9_16' | '16_9' | '9_21' | '21_9';

export type HorizontalScrollDirection = 'left' | 'right';

export interface InteractionMapModel {
	id?: string;
	type: 'cropleft'|'cropright'|'dragger'|'lock'|'resizer'|'rotator'|'photoobject'|'textobject'|'photoposition'|'textposition';
	topleft: {
		x: number;
		y: number;
	};
	topright: {
		x: number;
		y: number;
	};
	bottomright: {
		x: number;
		y: number;
	};
	bottomleft: {
		x: number;
		y: number;
	};
	centerx?: number;
	centery?: number;
	positionstateid?: number;
}

export interface LoginReturnData {
	expires: number;
	access_token: string;
	refresh_token: string;
	token_type: string;
}

export interface LogoColorsResult {
	logoColors: number;
	offeringColors: number;
	valid: boolean;
}

export type MimeTypeExtended = MimeType | 'image/svg+xml';

export type NativePlatform = 'iOS' | 'Android';

export interface NativeDeviceDetails {
	platform: NativePlatform; // iOS or Android
	deviceModel: string; // model/type e.g. 'iPhone11,2' for iPhone XS
	deviceUUID: string; // Device unique identifier
	osVersion: string; // version of the OS e.g. '13.5.1'
	appVersion: string; // e.g. '1.0'
	appCode: number; // build code (iOS), version code (Android)
}

interface NativePermissionsDetails {
	pushEnabled: boolean;
}

export interface NativeSelectedFile {
	id: string;
	source: string;
	url_thumb: string;
	url_web: string;
	url_full: string;
}

export interface NativeObjectLocation {
	source?: NativePlatform|DB.PhotoSourceModel['id']; // The source of the image (e.g. iOS, Android, Facebook, Instagram e.t.c.)
	location?: string; // e.g. "albumId" or "/folder/subfolder/image.png"
	section?: number; // The section in the native list where the image is located
	row?: number; // The row in the section of the native list where the image is located
}

export interface NativeFilePickerOptions {
	/** Minimum number of images to select */
	minNrOfImages?: number;
	/** Maximum number of images to select */
	maxNrOfImages: number;
	/** Maximum width of the image the picker can return. Defaults to 4960px */
	maxImageWidth: number;
	/** Maximum height of the image the picker can return. Defaults to 4960px */
	maxImageHeight: number;
	/** The destination bucket name (where the picked files will be uploaded to) */
	awsBucketName: string;
	/** The destination bucket region */
	awsRegion: string;
	/** String to define if we should sort automatically without showing the popup. Options are:
	 * (empty string): Not sortable
	 * Normal: Photo's are sortable and popup is shown
	 * Auto: Chronologial sorting is automatically selected
	 * Manual Manual sorting is automatically selected
	*/
	sortMode: PI.ProductSettings['sortMode'];
	/** Indicate if the picker should directly navigate to the selection screen */
	showSelection: boolean;
	selectedFiles: NativeSelectedFile[];
	/** Save last location in native file picker, this so we can reopen at the same position */
	objectLocation?: NativeObjectLocation;
}

interface NativePickerFile {
	id: string;
	source: NativePlatform|DB.PhotoSourceModel['id'];
	url: string;
	width: number;
	height: number;
	orientation?: number; // number in the range from 1 to 8
	photodate?: number; // timestamp of the time and date the original picture was taken
	objectLocation?: NativeObjectLocation; // Location of photo in native file picker, this so we can reopen at the same position
	fcx?: number;
	fcy?: number;
	fcw?: number;
	fch?: number;
}

interface NativePickedOptions {
	sortMode: PI.ProductSettings['sortMode']; // Define photo sorting mode
}

// Bridge to native app components
export interface NativeToWeb {
	getBearerToken?: () => void;
	pickerDismissed?: () => void;
	setPickedFiles?: (
		files: NativePickerFile[],
		pickedOptions?: NativePickedOptions,
	) => void;
	onApplicationResume?: (payload: { unreadMessagesCount?: number; }) => void;
	onUploadFailed?: (
		id: string, // ID of the file
	) => void;
	onUploadProgress?: (
		id: string, // ID of the file that's being uploaded
		bytesUploaded: number, // The amount of bytes that are uploaded
		actualFileSize: number, // The total filesize of the photo uploaded
	) => void;
	onUploadSucceeded?: (
		id: string, // ID of the uploaded file
		url: string, // URL of the uploaded file
	) => void;
}

interface NativeTranslationLabels {
	[key: string]: string;
}

interface NativeTranslationOptions {
	locale: string;
	labels: NativeTranslationLabels;
}

export interface NavigateOptions {
	hash?: string;
	query?: string;
}

export interface OfferingFrameModel {
	id: DB.OfferingFrameModel['id'];
	required: boolean;
	overpage: boolean;
	imageModel: DB.OfferingFrameImageModel;
	templateModel: DB.OfferingFrameTemplateModel;
}

export interface PageObjectVars {
	x_axis: number; // eslint-disable-line camelcase
	y_axis: number; // eslint-disable-line camelcase
	width: number;
	height: number;
	maxwidth: number;
	maxheight: number;
	cropx: number;
	cropy: number;
	cropwidth: number;
	cropheight: number;
	borderwidth: number;
	bordercolor: string | null;
	angle: number;
	flop: number;
	flip: number;
	rotate: number;
	cosinus: number;
	sinus: number;
	topleft: {
		x: number;
		y: number;
	};
	topright: {
		x: number;
		y: number;
	};
	bottomright: {
		x: number;
		y: number;
	};
	bottomleft: {
		x: number;
		y: number;
	};
	center: {
		x: number;
		y: number;
	};
	imagemap: {
		topleft: {
			x: number;
			y: number;
		};
		topright: {
			x: number;
			y: number;
		};
		bottomright: {
			x: number;
			y: number;
		};
		bottomleft: {
			x: number;
			y: number;
		};
	};
	max: {
		x_axis: number; // eslint-disable-line camelcase
		y_axis: number; // eslint-disable-line camelcase
		width: number;
		height: number;
		cropx: number;
		cropy: number;
		topleft: {
			x: number;
			y: number;
		};
		placement: {
			x: number;
			y: number;
		};
		center?: {
			x: number;
			y: number;
		};
	};
	placement: {
		x: number;
		y: number;
	};
	canvas: {
		top: number;
		bottom: number;
		left: number;
		right: number;
		width: number;
		height: number;
	};
	draw: {
		x: number;
		y: number;
	};
}

export type PhotoFilter = 'none' | 'boost' | 'grey' | 'sepia' | 'negate';
export type PhotoMask = 'none' | 'squareLocked' | 'circle' | 'circleLocked' | 'heart' | 'diamond' | 'brush' | 'cloud' | 'star' | 'flower' | 'stamp' | 'octagon' | 'clover';

export type PlatformType = 'native' | 'web' | 'server';

export interface PricingObject {
	totalPageCount: number;
	printPageCount: number;
	extraPageCount: number;
	extraPagePrice: number;
	costUpsell: number;
	subTotal: number;
	subTotalFrom: number | null;
	offeringModel: DB.OfferingModel;
	upsellCollection: DB.UpsellModel[];
	bulkDiscountValue: number;
	costBase: number;

	bulkDiscount?: {
		bulkModel: DB.BulkModel;
		bulkQuantityModels: DB.BulkQuantityModel[];
	};
	costBaseFrom?: number | null;
	costPages?: number;
	costPagesFrom?: number | null;
	costHandling?: number;
	handlingModel?: DB.HandlingModel;
	subItems?: PricingObject[];
}

export interface RemoteUserModel {
	Id: string | null;
	FirstName: string | null;
	Infix: string | null;
	LastName: string | null;
	DOB: string | null;
	Company: string | null;
	Street: string | null;
	HouseNumber: string | null;
	HouseNumberAddition: string | null;
	AddressLine2: string | null;
	AddressLine3: string | null;
	PostalCode: string | null;
	CountryCode: string | null;
	EmailAddress: string | null;
	TelNumber: string | null;
}

export interface RouteOptions {
	onComplete?: () => void;
	replace?: boolean;
	trigger?: boolean;
}

interface onScrollbarChange {
	(value: any, scrollbar: Scrollbar): void;
}
export interface Scrollbar {
	minBarWidth?: number;
	type: 'size'|'colors'|'scale';
	text?: string;
	value: number|string|null;
	values: (number|string|null)[];
	startObject?: PI.PageObjectModel;
	onOpen?: () => void;
	onChange?: onScrollbarChange;
	onClose?: () => void;
	onDragEnd?: () => void;
}

export interface SelectMenuItem {
	text: string;
	target: () => void;
	icon?: string;
}

export interface TemplateCarouselModel {
	id: string;
	positionStateCollection: TemplatePositionStateModel[];
	priority: number;
}

export interface TemplatePositionStateModel extends Omit<DB.TemplatePositionStateModel, 'id'|'templateid'> {
	id: number | string;
	templateid: number | string;
	position?: DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel;
	phototitle?: DB.TemplatePositionTextModel;
	available: boolean;
}

export interface TemplateStateModel {
	id: number;
	templateModel?: DB.TemplateModel;
	positions: TemplatePositionStateModel[];
}

export interface TemplatePosition {
	id: number | string;
	x: number;
	y: number;
	z: number;
	width: number;
	height: number;
	angle: number;
	borderwidth: number;
	bordercolor: string;
	transformable: boolean;
	available: boolean;
	overlap_top: number;
	overlap_left: number;
	overlap_right: number;
	overlap_bottom: number;
}

export interface TemplatePhotoPosition extends TemplatePosition {
	type: 'photo';
	mask: string | null;
	flag: boolean;
	autorotate: boolean;
	/** Method to apply when filling the template position with the selected photo
	 * - cover: The photo is sized to maintain its aspect ratio while filling the template position's entire content box. If the photo's aspect ratio does not match the aspect ratio of the template position, then the photo will be cropped to fit.
	 * - contain: The photo is scaled to maintain its aspect ratio while fitting within the template position. The entire object is made to fill the position, while preserving its aspect ratio, so the object will be "letterboxed" if its aspect ratio does not match the aspect ratio of the box.
	*/
	fillMethod: 'cover' | 'contain';
}

export interface TemplateTextPosition extends TemplatePosition {
	type: 'text';
	fontface: string;
	pointsize: number;
	fontcolor: string;
	fontbold: boolean;
	fontitalic: boolean;
	snap: boolean;
	bgcolor: string | null;
	align: 'Left' | 'Center' | 'Right';
	productattribute: string | null;
}

export interface TemplateSet {
	id: string;
	templateId: number;
	positions: Array<TemplatePhotoPosition | TemplateTextPosition>;
}

export interface ThemeCategoryModelTranslation extends DB.ThemeCategoryModel {
	lines: string[];
}

export interface ToolbarButton {
	class?: string;
	faClass?: string;
	miClass?: string;
	dropdown?: ToolbarButton[];
	icon?: string;
	id: string;
	pressed?: boolean;
	inverse?: boolean;
	scrollbars?: Scrollbar[];
	serialnumber?: number;
	subscript?: string;
	target?: (payload?: ToolbarButton) => void;
	thumbnail?: string;
	type: string;
}

export interface TopToolbarButton {
	id: string;
	disabled?: boolean;
	inverse?: boolean;
	focus?: boolean;
	icon: string;
	advanced?: boolean;
	text: string;
	alignment: 'left'|'right';
	target?: () => void;
}

export interface UploadModel {
	_type?: MimeTypeExtended;
	id: string;
	errorCount: number;
	name: string;
	overall: number;
	photoModelId: number | string | null;
	queue: number; // fraction of bytes uploaded (0 - 1)
	uploadEnd: number | null;
	uploadStart: number | null;
	url?: string;
	totalBytes: number; // total filesize in bytes
	width?: number;
	height?: number;
}

export interface UploadProgressMessage {
	progressPercentage: number;
	submittedCount: number;
	photos?: UploadProgressPhoto[];
}

export interface UploadProgressPhoto {
	id: PI.PhotoModel['id'];
	url?: string;
}

export interface CartItemUpsellModel {
	cartItemModel: DB.ShoppingCartItemModel;
	upsellModel: DB.UpsellModel;
	quantity: number;
}

export interface WebToNative {
	cancelUpload: (id: string) => void;
	cancelUploads: () => void;
	getNativeDetails: () => Promise<NativeDeviceDetails>;
	getPermissions: () => Promise<NativePermissionsDetails>;
	identifyUser: (id: string|null, libraryProperties?: Record<string, any>) => void;
	onFilePickerClicked?: (params: NativeFilePickerOptions) => void;
	openNativeBrowser: (url: string, inApp: boolean) => void;
	openNotificationCenter: () => void;
	retryUpload: (id: string) => void;
	requestAppReview: () => void;
	requestPushPermission: () => void;
	sendDebugLog: () => void;
	setBearerToken: (accessToken: string) => void;
	setRefreshToken: (token: string) => void;
	setTranslation: (options: NativeTranslationOptions) => void;
}

export interface EmailProjectLinkEvent {
	projectId: DB.ProductModel['id'];
	projectUrl: string;
	imageUrl: string;
	offeringName: DB.OfferingModel['name'];
}

export interface SubnavbarViewPrice {
	productPrice: number;
	productPriceFrom: number | null;
}

export interface SubnavbarProjectItemModel {
	icon: string;
	priceFrom: number;
	price: number;
	quantity?: string;
	subscript: string;
	title: string;
}

export interface PDPSelectOption {
	id: number;
	name: string;
	show: boolean;
	valueModel: Pick<DB.OfferingOptionValueModel, 'id' | 'offeringoptionid' | 'value'> | undefined;
	valueModels: DB.OfferingOptionValueModel[];
}

export type HeaderAccountNavigate = 'account' | 'account-photos' | 'create' | 'login' | 'logout' | 'shelf' | 'signup' | 'inbox';

export type HeaderMenuProductCategories = Pick<DB.ProductCategoryModel, 'id' | 'name'>[];

export type HeaderCartItems = ExternalCartItemModel[];

export type PDPUserModel = Pick<DB.UserModel, 'countryid' | 'currency' | 'language'>;

export type PDPBadgeImagesModel = Pick<DB.BadgeImageModel, 'id' | 'url'>[];

export type PDPHyperlinkModel = Pick<DB.HyperlinkModel, 'delegate' | 'url'>;

export type PDPImageModel = Pick<DB.PDPImageModel, 'languageid' | 'url'>;

export type PDPImagesModel = PDPImageModel[];

export type PDPCarouselImageModel = PDPImageModel['url'];

export type PDPCarouselImagesModel = PDPCarouselImageModel[];

export interface FullOfferingOptionValueIntersectModel extends DB.OfferingOptionValueModel {
	matchingOfferingIds: DB.OfferingModel['id'][];
}

export type FullOfferingOptionValueIntersectModels = FullOfferingOptionValueIntersectModel[];

export interface FullOfferingOptionValueVirtualIntersectModel extends Pick<DB.OfferingOptionValueModel, 'image' | 'serialnumber' | 'tag' | 'value'> {
	id?: DB.OfferingOptionValueModel['id'];
	matchingOfferingIds: DB.OfferingModel['id'][];
	offeringoptionid?: DB.OfferingOptionValueModel['offeringoptionid'];
	offeringid?: DB.OfferingModel['id'];
}

export type FullOfferingOptionValueVirtualIntersectModels = FullOfferingOptionValueVirtualIntersectModel[];

export interface FullOfferingOptionIntersectModel extends DB.OfferingOptionModel {
	items: FullOfferingOptionValueIntersectModel[];
	value: (
		OptionalExceptFor<DB.OfferingOptionValueModel, 'serialnumber' | 'tag' | 'value'>
		& {
			offeringid?: DB.OfferingModel['id'];
		}
	);
}

export type FullOfferingOptionIntersectModels = FullOfferingOptionIntersectModel[];

export interface FullOfferingOptionVirtualIntersectModel extends Pick<DB.OfferingOptionModel, 'name' | 'serialnumber'> {
	id?: DB.OfferingOptionModel['id'];
	items: FullOfferingOptionValueVirtualIntersectModel[];
	tag?: DB.OfferingOptionModel['tag'];
	value: (
		OptionalExceptFor<DB.OfferingOptionValueModel, 'serialnumber' | 'tag' | 'value'>
		& {
			offeringid?: DB.OfferingModel['id'];
		}
	);
	variantid?: DB.OfferingModel['variantid'];
}

export type FullOfferingOptionVirtualIntersectModels = FullOfferingOptionVirtualIntersectModel[];

export interface PartialOfferingOptionValueIntersectModel extends Pick<FullOfferingOptionValueIntersectModel, 'matchingOfferingIds'> {
	id?: FullOfferingOptionValueIntersectModel['id'];
	offeringid?: DB.OfferingModel['id'];
}

export type PartialOfferingOptionValueIntersectModels = PartialOfferingOptionValueIntersectModel[];

export interface PartialOfferingOptionIntersectModel {
	id?: FullOfferingOptionIntersectModel['id'];
	items: PartialOfferingOptionValueIntersectModels;
}

export type PDPProductOptionValueModel = Pick<FullOfferingOptionValueIntersectModel, 'id' | 'matchingOfferingIds' | 'serialnumber' | 'value'>;

export type PDPProductOptionValueModels = PDPProductOptionValueModel[];

export interface PDPProductOptionModel extends Pick<FullOfferingOptionIntersectModel, 'id' | 'name'> {
	items: PDPProductOptionValueModels;
}

export type PDPProductOptionsModel = PDPProductOptionModel[];

export type PDPOfferingModel = Pick<DB.OfferingModel, 'id' | 'instock' | 'thumbnail'>;

export type PDPOfferingModels = PDPOfferingModel[];

export interface PDPShippingModel extends DB.ShippingModel {
	days: string;
}

export type PDPNavigateModule = (
	{
		delegate: boolean;
		url: PDPHyperlinkModel['url'];
	}
	| {
		offeringid: PDPOfferingModel['id'];
		listeritemid: DB.ProductCategoryModel['id'];
	}
);

export interface PDPFullOfferingModel extends Pick<FullOfferingModel, 'id' | 'instock' | 'thumbnail' | 'typeid' | 'variantid'> {
	options: PDPFullOfferingOptionModel[];
}

export type PDPFullOfferingModels = PDPFullOfferingModel[];

export interface PDPFullOfferingOptionModel extends Pick<FullOfferingOptionModel, 'id' | 'name'> {
	value: PDPFullOfferingOptionValueModel;
}

export type PDPFullOfferingOptionValueModel = Pick<DB.OfferingOptionValueModel, 'id' | 'value'>;

export interface PartialOfferingOptionModel {
	id?: FullOfferingOptionModel['id'];
	value: {
		id?: DB.OfferingOptionValueModel['id'];
		offeringid?: DB.OfferingModel['id'];
	};
}

export interface PartialOfferingModel extends Pick<DB.OfferingModel, 'id' | 'typeid' | 'variantid'> {
	options: PartialOfferingOptionModel[];
}

export interface FullOfferingModel extends DB.OfferingModel {
	options: FullOfferingOptionModel[];
}

export type FullOfferingModels = FullOfferingModel[];

export interface FullOfferingOptionModel extends OptionalExceptFor<DB.OfferingOptionModel, 'name' | 'serialnumber' | 'tag'> {
	value: (
		OptionalExceptFor<DB.OfferingOptionValueModel, 'serialnumber' | 'tag' | 'value'>
		& {
			offeringid?: DB.OfferingModel['id'];
		}
	);
	variantid?: DB.OfferingModel['variantid'];
}

export type ToolbarPhotoActiveView = 'library' | 'channels';

export type ToolbarPhotoChannel = ChannelModel['id'];

export type ToolbarPhotoPhotoQueued = PI.PhotoModel;

export type ToolbarPhotoPhotosQueued = ToolbarPhotoPhotoQueued[];

export type CloudSelectorSource = ChannelModel['id'];

export type ImportLibraryProductPhoto = PI.PhotoModel;

export type ImportLibraryProductPhotos = ImportLibraryProductPhoto[];

export type ImportLibraryPageObject = PI.PageObjectModel;

export type ImportLibraryPageObjects = ImportLibraryPageObject[];

export type OfferingOptionsSummaryToolbarCurrencyModel = Pick<DB.CurrencyModel, 'id' | 'separator'>;

export interface OfferingOptionsSummaryToolbarFilterTag {
	show?: boolean;
	tag: string;
}

export type OfferingOptionsSummaryToolbarFilterTags = Array<string> | Array<OfferingOptionsSummaryToolbarFilterTag>;

export type OfferingOptionsSummaryToolbarFullOfferingModel = FullOfferingModel;

export type OfferingOptionsSummaryToolbarFullOfferingModels = OfferingOptionsSummaryToolbarFullOfferingModel[];

export interface OfferingOptionsSummaryToolbarFullOfferingOptionModel extends Pick<FullOfferingOptionModel, 'name' | 'tag'> {
	id?: FullOfferingOptionModel['id'];
	value: OfferingOptionsSummaryToolbarOfferingOptionValueModel;
	variantid?: DB.OfferingModel['variantid'];
}

export interface OfferingOptionsSummaryToolbarOfferingOptionValueModel extends Pick<DB.OfferingOptionValueModel, 'value' | 'serialnumber'> {
	id?: DB.OfferingOptionValueModel['id'];
	offeringid?: FullOfferingModel['id'];
}

export type OfferingOptionsSummaryToolbarProductOptionValueModel = Pick<FullOfferingOptionValueIntersectModel, 'id' | 'matchingOfferingIds' | 'serialnumber' | 'value' | 'tag'>;

export type OfferingOptionsSummaryToolbarProductOptionValueModels = OfferingOptionsSummaryToolbarProductOptionValueModel[];

export interface OfferingOptionsSummaryToolbarProductOptionModel extends Pick<FullOfferingOptionVirtualIntersectModel, 'id' | 'name' | 'tag' | 'variantid'> {
	items: OfferingOptionsSummaryToolbarProductOptionValueModels;
}

export interface ColorPickerColor {
	id: string;
	colors: {
		primary: string;
		palette: string[];
	};
}

export type ColorPickerColors = ColorPickerColor[];

export interface ColorPickerPattern {
	color: string;
	id: number;
	image: string;
	name: string;
}

export type ColorPickerPatterns = ColorPickerPattern[];

export interface DropboxFile extends DropboxSDK.files.FileMetadataReference {
	thumb_url: string; // eslint-disable-line camelcase
}

export interface DropboxType extends Dropbox {
	auth?: DropboxAuth; // eslint-disable-line camelcase
}

export interface EditorBorderSelectorBorder {
	cssBorder?: number;
	value: number;
}

export type EditorBorderSelectorBorders = EditorBorderSelectorBorder[];

export type EditorBorderSelectorModel = Pick<DB.PageObjectModel, 'bordercolor' | 'borderimage' | 'borderwidth' | 'id'>;

export type EditorToolbarActiveMode = 'background' | 'border' | 'crop' | 'edit-colors' | 'edit-photo' | 'edit-text' | 'filter' | 'layers' | 'layout' | 'shape' | 'text' | 'theme';

export interface EditorToolbarLayoutOption {
	placeholder: string;
	templateSets: EditorToolbarLayouts;
	title: string;
	value: keyof EditorToolbarLayoutOptions;
}

export interface EditorToolbarLayoutOptions {
	'only-images': EditorToolbarLayoutOption;
	'with-texts': EditorToolbarLayoutOption;
	'one-image': EditorToolbarLayoutOption;
	'two-images': EditorToolbarLayoutOption;
	'three-images': EditorToolbarLayoutOption;
	'four-images': EditorToolbarLayoutOption;
	'fiveplus-images': EditorToolbarLayoutOption;
}

export type EditorToolbarLayouts = TemplateSet[];

export type EditorToolbarPageObjectModel = PI.PageObjectModel;

export type EditorToolbarPageObjectsModel = EditorToolbarPageObjectModel[];

export type EditorToolbarPhoto = Pick<DB.PhotoModel, 'id' | 'full_width' | 'full_height' | 'thumb_url' | 'url'>;

export type EditorToolbarPhotos = EditorToolbarPhoto[];

export type EditorToolbarThemes = DB.ThemeModel[];

export type EditorTextOptionsActiveMode = 'align' | 'color-picker' | 'font-family' | 'font-size';

export type EditorTextOptionsCloseTooltipToolbarKeys = Exclude<EditorTextOptionsIsMobileUnwatchersKeys, 'main'>;

export type EditorTextOptionsFontModels = DB.FontModel[];

export type EditorTextOptionsIsMobileUnwatchersKeys = 'align' | 'color-picker' | 'font-family' | 'font-size' | 'main';

export type EditorTextOptionsOfferingModel = Pick<DB.OfferingModel, 'color' | 'minfontsize' | 'type'>;

export interface OfferingOptionsToolbarFilterTag {
	tag: string;
}

export type OfferingOptionsToolbarFilterTags = Array<string> | Array<OfferingOptionsToolbarFilterTag>;

export type OfferingOptionsToolbarCurrencyModel = Pick<DB.CurrencyModel, 'id' | 'separator'>;

export type OfferingOptionsToolbarFullOfferingModel = FullOfferingModel;

export type OfferingOptionsToolbarFullOfferingModels = OfferingOptionsToolbarFullOfferingModel[];

export type OfferingOptionsToolbarFullOfferingOptionModel = FullOfferingOptionModel;

export interface OfferingOptionsToolbarFullOfferingOptionValueModel extends Pick<DB.OfferingOptionValueModel, 'image' | 'value' | 'serialnumber'> {
	id?: DB.OfferingOptionValueModel['id'];
	offeringid?: FullOfferingModel['id'];
}

export type OfferingOptionsToolbarProductOptionValueModel = Pick<FullOfferingOptionValueVirtualIntersectModel, 'id' | 'image' | 'matchingOfferingIds' | 'offeringid' | 'serialnumber' | 'tag' | 'value'>;

export type OfferingOptionsToolbarProductOptionValueModels = OfferingOptionsToolbarProductOptionValueModel[];

export interface OfferingOptionsToolbarProductOptionModel extends Pick<FullOfferingOptionVirtualIntersectModel, 'id' | 'name' | 'tag' | 'variantid'> {
	items: OfferingOptionsToolbarProductOptionValueModels;
}

export type EditorCanvasPageObjectModel = PI.PageObjectModel;

export type EditorCanvasPageObjectsModel = EditorCanvasPageObjectModel[];

export interface EditorCanvasOfferingDepthModel {
	cornerEndStyles: Partial<CSSStyleDeclaration> & Record<string, string>;
	cornerStartStyles: Partial<CSSStyleDeclaration> & Record<string, string>;
	height: number;
	styles: Partial<CSSStyleDeclaration> & Record<string, string>;
	width: number;
}

export type ButtonComponentColor = 'primary' | 'secondary';

export type SelectComponentColor = 'primary' | 'secondary';

export type SelectComponentOptions = Array<string | Record<string, boolean | number | string>>;

export type SelectComponentOptionsClasses = Array<string> | string | Record<string, boolean>;

export type SelectComponentOptionStylesCallback = (option: SelectComponentOptions[number]) => Partial<CSSStyleDeclaration>;

export type EditorProjectNameProjectModel = Pick<DB.ProductModel, 'title'>;

export interface GroupComponentListener<Component extends Vue> {
	component: Component;
	listener: (value: boolean) => void;
}

export interface EditorBookFillPage {
	id: PI.ProductSettings['autoFillPageDensityId'];
	/* Number of inner pages the book will have after auto filling */
	innerPagesAfterAutoFill: number;
	/* Price of the book after auto filling */
	price: number;
	/* Number of pages we send to the auto fill method */
	autoFillPageCount: number;
	/* Average number of photos per page */
	averageNumberOfPhotosPerPage: number;
}

export type EditorBookFillPages = EditorBookFillPage[];

export interface EditorBookFillMargin {
	id: PI.ProductSettings['autoFillMarginId'];
	value: number;
}

export type EditorBookFillMargins = EditorBookFillMargin[];

export interface EditorBookFillPagesMarginsModel {
	pages?: PI.ProductSettings['autoFillPageDensityId'];
	margins?: PI.ProductSettings['autoFillMarginId'];
}

export type EditorBookFillImagePageOrientation = 'landscape' | 'portrait' | 'square';

export interface EditorBookFillImagePage {
	image: string;
	id: PI.ProductSettings['autoFillPageDensityId'];
}

export type EditorBookFillImagePages = EditorBookFillImagePage[];

export interface EditorBookFillImageMargin {
	image: string;
	id: PI.ProductSettings['autoFillMarginId'];
}

export type EditorBookFillImageMargins = EditorBookFillImageMargin[];

export type EditorDrawResolution = 'high' | 'low' | 'thumb';

export type EditorModuleCropMode = 'topleft' | 'top' | 'topright' | 'right' | 'bottomright' | 'bottom' | 'bottomleft' | 'left' | 'pinch';

export interface EditorModuleProductToolsSupported {
	/**
	 * Indicates if there a background choice available for the product
	 */
	background: boolean;
	/**
	 * Indicates if the selected photo object supports adding a border
	 */
	border: boolean;
	/**
	 * Indicates if the active product supports remove the active page
	 */
	deletePage: boolean;
	/**
	 * Indicates if the active product supports removing the selected print/photo
	 */
	deletePrintOrPhoto: boolean;
	/**
	 * Indicates if the active product supports editing the colors (for SVG vectors)
	 */
	editColors: boolean;
	/**
	 * Indicates if the active product supports adding a frame
	 */
	frame: boolean;
	/**
	 * Indicates if the active product supports layouts in the page
	 */
	layout: boolean;
	/**
	 * Indicates if the active product supports multiple photos in the page
	 */
	multiplePhoto: boolean;
	/**
	 * Indicates if the active product supports multiple texts in the page
	 */
	multipleText: boolean;
	/**
	 * Indicates if the active product supports photo template position shuffle
	 */
	shuffle: boolean;
	/**
	 * Indicates if the active product supports text in the page
	 */
	text: boolean;
	/**
	 * Indicates if the active product supports themes
	 */
	theme: boolean;
}

export type EditorModulePageTemplatePositions = TemplateSet['positions'];

export interface EditorModuleTextObjectRect {
	minX: number;
	minY: number;
	maxX: number;
	maxY: number;
}

export type EditorModuleTextObjectResizeSide = 'left' | 'right';

export type EditorModuleWindowClickHandlerType = 'toolbar';

export type EditorModuleWindowClickHandlers = Record<EditorModuleWindowClickHandlerType, (event: MouseEvent) => void>;

export interface EditorVectorEditColorsImageVectorColor {
	color: string;
	matches: string[];
	replace?: PI.PageObjectColorReplacementReplaceModel;
}

export interface EditorVectorEditColorsImageVectorColors {
	background?: EditorVectorEditColorsImageVectorColor;
	foreground: EditorVectorEditColorsImageVectorColor[];
}

export type ZoomObjectResult = Pick<PI.PageObjectModel, 'cropheight' | 'cropwidth' | 'cropx' | 'cropy'>;

export interface CropFromRatioOptions {
	object: PI.PageObjectModel;
	value: number;
	type: 'height' | 'width';
}

export enum EditorTooltipType {
	INFO = 'info',
	WARNING = 'warning',
}

export type EditorTooltipKeys = 'fill-to-size' | 'tap-to-continue' | 'unsupported-landscape';

export interface AddObjectModel {
	data: PI.PageObjectModel;
	pageId?: string;
}

export interface AddPageObjectModelWithoutNoStore {
	data: OptionalExceptFor<PI.PageObjectModel, 'width' | 'height' | 'type'>;
	pageId?: string;
	noStore?: false;
}

export interface AddPageObjectModelWithNoStore {
	data: OptionalExceptFor<PI.PageObjectModel, 'width' | 'height' | 'type'>;
	noStore: true;
	pageModel: PI.PageModel;
	pageObjects: PI.PageObjectModel[];
}

export type AddPageObjectModel = AddPageObjectModelWithoutNoStore | AddPageObjectModelWithNoStore;

export interface ChangePageObjectModelWithIgnorePointerSizeResize extends OptionalExceptFor<PI.PageObjectModel, 'id'> {
	ignorePointerSizeResize?: boolean;
	noStore?: false;
}

export interface ChangePageObjectModelWithNoStore extends OptionalExceptFor<PI.PageObjectModel, 'id'> {
	ignorePointerSizeResize?: boolean;
	noStore: true;
	originalPageObject: PI.PageObjectModel;
}

export type ChangePageObjectModel = ChangePageObjectModelWithNoStore | ChangePageObjectModelWithIgnorePointerSizeResize;

export interface DeselectePageObjectsModel {
	skipEmptyTextObjectsDeletion?: boolean;
}

export interface PageObjectModelChangeResult {
	partialPageObject: OptionalExceptFor<PI.PageObjectModel, 'id'>;
	updatedPageObject: PI.PageObjectModel;
}

export type TooltipPositionSide = 'bottom' | 'right' | 'top' | 'left';

export type TooltipPosition = 'bottom center' | 'bottom left' | 'bottom right' | 'right' | 'top center' | 'top left' | 'top right' | 'left';

export interface ThemeTemplateSet {
	templateId: DB.TemplateModel['id'];
	photoCount?: number;
	marginAroundEdge?: number;
	marginBetweenPositions?: number;
	templateSets: TemplateSet[];
}

export type EditorPaginationOfferingModel = Pick<DB.OfferingModel, 'groupid'>;

export type EditorPaginationPageType = 'cover_front' | 'coverInside' | 'cover_back' | 'pageNumber';

export interface EditorProductSettingsModel extends Pick<PI.ProductSettings, 'applyEnhancement'> {
	showBleedMargin?: boolean;
}

export type EditorTopToolbarCurrencyModel = Pick<DB.CurrencyModel, 'id' | 'separator'>;

export type EditorPreviewModel = '2d' | '3d';

export type EditorPreviewPageObjectModels = PI.PageObjectModel[];

export type EditorPreviewScalingBase = 'auto' | 'height' | 'width';

export interface EditorInteractiveInteractionPageObjectModel {
	objectVars: PageObjectVars;
	object: EditorInteractivePageObjectModel;
}

export interface EditorInteractiveInteractionTemplatePhotoModel {
	objectVars: PageObjectVars;
	templatePosition: TemplatePhotoPosition;
}

export interface EditorInteractiveInteractionTemplateTextModel {
	objectVars: PageObjectVars;
	templatePosition: TemplateTextPosition;
}

/**
 * a(x,y) _____________________________  b(x,y)
 * 	     |                             |
 * 	     |                             |
 * 	     |                             |
 * 	     |                             |
 * d(x,y)|_____________________________| c(x,y)
 */
export interface EditorInteractivePageObjectCoordinates {
	ax: number;
	ay: number;
	bx: number;
	by: number;
	cx: number;
	cy: number;
	dx: number;
	dy: number;
}

export type EditorInteractivePageObjectFixResizeSide = Exclude<EditorInteractivePageObjectResizeSide, 'top' | 'right' | 'bottom' | 'left'>;

export type EditorInteractivePageObjectInitialPosition = Pick<EditorInteractivePageObjectModel, 'rotate' | 'x_axis' | 'y_axis'>;

export type EditorInteractivePageObjectInitialSize = Pick<EditorInteractivePageObjectModel, 'cropheight' | 'cropwidth' | 'cropx' | 'cropy' | 'height' | 'rotate' | 'width' | 'x_axis' | 'y_axis'>;

export type EditorInteractivePageObjectModel = PI.PageObjectModel;

export type EditorInteractivePageObjectModels = EditorInteractivePageObjectModel[];

export type EditorInteractivePageObjectResizeSide = 'bottom-left' | 'bottom' | 'bottom-right' | 'left' | 'right' | 'top-left' | 'top' | 'top-right';

export type ExceededColorSelectionPageObjectModel = OptionalExceptFor<PI.PageObjectModel, '_vectorSVG' | '_vectorColors'>;

export type LogoBackgroundDetectedPageObjectModel = OptionalExceptFor<PI.PageObjectModel, '_vectorSVG' | '_vectorColors'>;

export interface PantoneMatchingSystemColor {
	name: string;
	styles: Partial<CSSStyleDeclaration> & Record<string, string>;
}

export type PmsColorPickerColor = (
	NumbersToString<Exclude<Colors, Hex3 | Hex6>>
	| {
		hex: string;
	}
);

export type PmsColorPickerMode = 'full' | 'pms';

export type PmsColorPickerSelectFromSource = 'CMYK' | 'RGB' | 'Hex';

export type PmsColorPickerSelectFromSources = PmsColorPickerSelectFromSource[];

export interface ProjectProductSideData {
	id: string;
	label: string;
	pageIndex: number;
	pageModel: PI.PageModel;
	pageObjects: PI.PageObjectModel[];
	scaling: number;
	styles: Partial<CSSStyleDeclaration>;
}

export interface SelectSlotOptions<T> {
	count?: number;
	text: string;
	value: T;
}

export type StartAppDataToExclude = 'languages' | 'stateprovs' | 'fonts' | 'buckets' | 'branches' | 'hyperlinks' | 'photosources' | 'productgroups' | 'handling' | 'pricing' | 'regions' | 'region_offerings' | 'region_currencies' | 'countries' | 'currencies' | 'upsell' | 'flexgroups' | 'offerings' | 'offeringoptions' | 'offeringoptionvalues' | 'offeringoptionvalue_offerings' | 'model3ds' | 'model2ds' | 'pdps' | 'pdpimages' | 'pdpfilters' | 'pdpfiltervalues' | 'pdpfiltervalue_offerings' | 'offeringframes' | 'offeringframeimages' | 'offeringframetemplates' | 'productcategories' | 'productcategory_offerings' | 'translations';

export interface SVGColorReplacement {
	color: string;
	replace: string;
}

export type SVGGetColorsResult = Record<string, string[]>;

export interface SVGLoadResult {
	content: string;
	image: HTMLImageElement;
}

export interface TabSwitchTab {
	id: number | string;
	text: string;
}

export type TabSwitchTabs = TabSwitchTab[];

export type VirtualOfferingSelectOfferingModel = OptionalExceptFor<DB.OfferingModel, 'id' | 'groupid' | 'flexgroupid'>;
