import {
	Vue,
	Component,
	Prop,
	Ref,
} from 'vue-property-decorator';
import * as PI from 'interfaces/project';
import filterText from '../tools/filter-text';
import {
	FontModule,
	ProductStateModule,
} from '../store/index';
import PageObject from '../classes/pageobject';

@Component
export default class EditTextView extends Vue {
	@Prop({ default: false, type: Boolean })
	readonly closeIcon!: boolean;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Ref('textArea') readonly textArea!: HTMLInputElement;

	get objectModel() {
		return ProductStateModule.getSelectedPageObject;
	}

	get text() {
		return this.objectModel ? this.objectModel.text : null;
	}

	mounted() {
		this.autoFocus();
	}

	beforeDestroy() {
		ProductStateModule.pushHistory();
	}

	autoFocus() {
		if (this.textArea) {
			// Set focus to text field
			this.textArea.focus();

			if (this.text && this.text.length) {
				// Set cursor at the end of the text
				this.textArea.setSelectionRange(
					this.text.length,
					this.text.length,
				);
			}
		}
	}

	close() {
		this.$emit('hideToolbar');

		if (this.objectModel) {
			PageObject.select(
				this.pageModel,
				this.objectModel.id,
				{
					draggable: false,
				},
			);
		}
	}

	editText(text: string) {
		if (this.objectModel && this.objectModel.fontface) {
			const fontModel = FontModule.getById(this.objectModel.fontface);
			if (!fontModel) {
				throw new Error('Could not find required font model');
			}

			const subset = fontModel.subset.split(',');
			text = filterText(
				text,
				subset,
			);
			ProductStateModule.changePageObject({
				id: this.objectModel.id,
				text,
			});
		}
	}
}
