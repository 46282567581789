export interface FullyVisibleResult {
	isBottomVisible: boolean;
	isFullyVisible: boolean;
	isLeftVisible: boolean;
	isRightVisible: boolean;
	isTopVisible: boolean;
}

export interface SelectionResult {
	caretLine: number;
	end: number;
	start: number;
}

export function getSelection(element: HTMLElement): SelectionResult {
	if (
		element.tagName === 'TEXTAREA'
		|| element.tagName === 'INPUT'
	) {
		return {
			caretLine: (element as HTMLTextAreaElement | HTMLInputElement)
				.value
				.substring(
					0,
					(element as HTMLTextAreaElement | HTMLInputElement).selectionStart || 0,
				)
				.split('\n')
				.length,
			start: (element as HTMLTextAreaElement | HTMLInputElement).selectionStart || 0,
			end: (element as HTMLTextAreaElement | HTMLInputElement).selectionEnd || 0,
		};
	}

	const selection = window.getSelection();

	if (!selection) {
		return {
			caretLine: 1,
			start: 0,
			end: 0,
		};
	}

	const range = selection.getRangeAt(0);

	return {
		caretLine: 0,
		start: range.startOffset,
		end: range.endOffset,
	};
}

export function isFullyVisible(element: HTMLElement): FullyVisibleResult {
	const result: FullyVisibleResult = {
		isBottomVisible: true,
		isFullyVisible: true,
		isLeftVisible: true,
		isRightVisible: true,
		isTopVisible: true,
	};

	if (!element) {
		return {
			isBottomVisible: false,
			isFullyVisible: false,
			isLeftVisible: false,
			isRightVisible: false,
			isTopVisible: false,
		};
	}

	const elementRect = element.getBoundingClientRect();
	let currentElement = element;

	while (
		currentElement
		&& currentElement.parentElement
	) {
		const { parentElement } = currentElement;
		const parentRect = parentElement.getBoundingClientRect();

		const isScrollNotMatching = (
			element.scrollWidth > parentElement.scrollWidth
			|| element.scrollHeight > parentElement.scrollHeight
		);
		const isTopNotVisible = elementRect.top < parentRect.top;
		const isLeftNotVisible = elementRect.left < parentRect.left;
		const isRightNotVisible = Math.floor(parentRect.left + parentElement.scrollWidth) < Math.floor(elementRect.left + element.scrollWidth);
		const isBottomNotVisible = Math.floor(parentRect.top + parentElement.scrollHeight) < Math.floor(elementRect.top + element.scrollHeight);

		if (
			isScrollNotMatching
			|| isTopNotVisible
			|| isLeftNotVisible
			|| isRightNotVisible
			|| isBottomNotVisible
		) {
			result.isFullyVisible = false;
		}

		if (isTopNotVisible) {
			result.isTopVisible = false;
		}
		if (isLeftNotVisible) {
			result.isLeftVisible = false;
		}
		if (isRightNotVisible) {
			result.isRightVisible = false;
		}
		if (isBottomNotVisible) {
			result.isBottomVisible = false;
		}

		currentElement = currentElement.parentElement;
	}

	return result;
}

export function isOrHasParent(
	element: HTMLElement,
	parent: HTMLElement,
): boolean {
	if (element === parent) {
		return true;
	}

	if (!element.parentElement) {
		return false;
	}

	return isOrHasParent(
		element.parentElement,
		parent,
	);
}

export function isRectScrollable(
	element: HTMLElement,
	parentToStop?: HTMLElement,
): boolean {
	const isElementScrollable = !(
		element.scrollWidth <= element.clientWidth
		&& element.scrollHeight <= element.clientHeight
	);

	if (
		isElementScrollable
		|| !element.parentElement
		|| element.parentElement.id === 'webapp'
		|| parentToStop?.isSameNode(element.parentElement)
	) {
		return isElementScrollable;
	}

	return isRectScrollable(
		element.parentElement,
		parentToStop,
	);
}

export function onElementChildrenChange(
	element: Element,
	callback: () => void,
): () => void {
	if (!element) {
		return () => undefined;
	}

	const observersDisconnects: (() => void)[] = [];
	const onImageLoad = (
		target: HTMLElement,
		onLoad: () => void,
	) => {
		const styles = getComputedStyle(target);

		if (target instanceof HTMLImageElement) {
			if (
				target.complete
				&& target.naturalWidth !== 0
			) {
				onLoad();
			} else {
				target.addEventListener(
					'load',
					() => onLoad(),
				);
			}
		} else if (styles.backgroundImage !== 'none') {
			const image = new Image();
			image.addEventListener(
				'load',
				() => onLoad(),
			);

			if (styles.backgroundImage.startsWith('url(')) {
				image.src = styles.backgroundImage
					.slice(
						4,
						-1,
					)
					.replace(
						/("|')/g,
						'',
					);
			} else {
				image.src = styles.backgroundImage;
			}
		}
	};
	const checkForImagesLoad = (
		target: HTMLElement,
		onLoad: () => void,
	) => {
		onImageLoad(
			target,
			onLoad,
		);

		if (target.children.length > 0) {
			Array.prototype.forEach.call(
				target.children,
				(child: HTMLElement) => {
					checkForImagesLoad(
						child as HTMLElement,
						onLoad,
					);
				},
			);
		}
	};
	const mutationObserver = new MutationObserver((mutationsList) => {
		callback();

		// eslint-disable-next-line no-restricted-syntax
		for (const mutation of mutationsList) {
			if (mutation.type === 'childList') {
				const target = mutation.target as HTMLElement;

				Array.prototype.forEach.call(
					target.children,
					(child: HTMLElement) => {
						onImageLoad(
							child,
							callback,
						);

						if (child.children.length > 0) {
							observersDisconnects.push(
								onElementChildrenChange(
									child,
									callback,
								),
							);
						}
					},
				);
			} else if (mutation.type === 'attributes') {
				if (mutation.target instanceof HTMLElement) {
					onImageLoad(
						mutation.target,
						callback,
					);
				}
			}
		}
	});
	mutationObserver.observe(
		element,
		{
			childList: true,
			subtree: true,
			attributes: true,
			characterData: true,
		},
	);
	const resizeObserver = new ResizeObserver(() => callback());
	resizeObserver.observe(element);
	observersDisconnects.push(() => resizeObserver.disconnect());
	observersDisconnects.push(() => mutationObserver.disconnect());
	checkForImagesLoad(
		element as HTMLElement,
		callback,
	);

	return () => {
		// eslint-disable-next-line no-restricted-syntax
		for (const observerDisconnect of observersDisconnects) {
			observerDisconnect();
		}
	};
}
