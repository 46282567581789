import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { ProductStateModule } from '../store/index';

@Component
export default class LayoutPickerItemView extends Vue {
	@Prop({ required: true, type: Object })
	readonly layoutModel!: DB.LayoutModel;

	@Prop({ default: true, type: Boolean })
	readonly showSelectIcon!: boolean;

	private isVisible = false;

	get selectedLayout() {
		const productModel = ProductStateModule.getProduct;
		return productModel && this.showSelectIcon
			? productModel.layoutid
			: null;
	}

	imageLoaded() {
		this.$emit('imageLoaded');
	}

	selectLayout() {
		this.$emit(
			'selectLayout',
			this.layoutModel,
		);
	}

	visibilityChanged(isVisible: boolean) {
		this.isVisible = isVisible;
	}
}
