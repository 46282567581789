import './defines';
import { nanoid } from 'nanoid';
import { Public } from 'utils/decorators';
import {
	Component,
	Model,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogCheckboxComponent',
})
export default class DialogCheckboxComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			type: [Object, Array],
		},
	)
	public readonly value?: DialogCheckboxValue[] | DialogCheckboxValue;

	@Prop({
		required: true,
		type: [Object, Array],
	})
	public readonly checkboxes!: DialogCheckbox[] | DialogCheckbox;

	@Prop({
		default: undefined,
		type: String,
	})
	public readonly content?: string;

	protected get computedCheckboxes(): DialogCheckbox[] {
		if (Array.isArray(this.checkboxes)) {
			return this.checkboxes;
		}

		return [this.checkboxes];
	}

	private checkboxesIds: Record<DialogCheckboxValue['id'], string> = {};

	protected getCheckboxNanoId(id: DialogCheckboxValue['id']): string {
		if (!this.checkboxesIds[id]) {
			this.checkboxesIds[id] = nanoid();
		}

		return this.checkboxesIds[id];
	}

	@Public()
	public getValue(id: DialogCheckboxValue['id']): DialogCheckboxValue['value'] {
		if (Array.isArray(this.value)) {
			return this.value.find((value) => value.id === id)?.value ?? false;
		}

		return this.value?.value ?? false;
	}

	protected onCheckboxChange(
		id: DialogCheckboxValue['id'],
		checked: boolean,
	): void {
		let newValue: DialogCheckboxValue[];
		const isMultiple = Array.isArray(this.checkboxes);

		if (this.value) {
			if (isMultiple) {
				newValue = (this.value as DialogCheckboxValue[]).map((value) => ({ ...value }));
			} else {
				newValue = [
					{
						...(this.value as DialogCheckboxValue),
					},
				];
			}
		} else {
			newValue = [];
		}

		const checkboxValue = newValue.find((value) => value.id === id);

		if (checkboxValue) {
			checkboxValue.value = checked;
		} else {
			newValue.push({
				id,
				value: checked,
			});
		}

		if (isMultiple) {
			this.$emit(
				'change',
				newValue,
			);
		} else {
			this.$emit(
				'change',
				newValue[0],
			);
		}
	}
}
