// eslint-disable-next-line import/prefer-default-export
export function mergeAndExcludeByKey<T>(
	array1: T[],
	array2: T[],
	key: keyof T,
): T[] {
	return array1
		.concat(
			array2.filter((item2) => (
				!array1.some((item1) => item1[key] === item2[key])
			)),
		);
}
