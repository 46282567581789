import './defines';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'CheckboxComponent',
})
export default class CheckboxComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: false,
			description: 'Indicates the checked state of the checkbox',
			type: Boolean,
		},
	)
	public readonly checked!: boolean;

	@Prop({
		description: 'Defines the name of the checkbox',
		type: String,
	})
	public readonly name?: string;

	@Ref('input')
	protected readonly inputElement!: HTMLInputElement;

	protected internalChecked = false;

	@Watch(
		'checked',
		{
			immediate: true,
		},
	)
	protected onCheckedChange(): void {
		this.internalChecked = this.checked;
	}

	@Watch('internalChecked')
	protected onInternalCheckedChange(): void {
		if (this.internalChecked !== this.checked) {
			this.$emit(
				'change',
				this.internalChecked,
			);
		}
	}

	protected onChange(event: InputEvent): void {
		this.internalChecked = (event.target as HTMLInputElement).checked;
	}

	protected onClick(): void {
		this.internalChecked = !this.internalChecked;
		this.$emit('click');
	}
}
