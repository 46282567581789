import detectBrowser from 'tools/detect-browser';

export default function maxCanvasSize(): number {
	const browser = detectBrowser();
	const mobile = (
		browser.os == 'iOS'
		|| browser.os == 'Android'
	);

	// 3mb canvas limit for iOS and Android devices, 10mb for others
	return (
		mobile
			? 3000000
			: 10000000
	);
}
