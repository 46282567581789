import * as PI from 'interfaces/project';
import getImageNode from '../tools/get-image-node';
import {
	PhotosModule,
	ProductStateModule,
	AppStateModule,
} from '../store/index';
import PageObject from '../classes/pageobject';
import zoomObject from '../mutations/pageobject/zoom';
import maxObjectSize from '../tools/max-object-size';

class TouchHandler {
	drag: {
		enabled: boolean;
		start: {
			x: number;
			y: number;
		};
		last: {
			x: number;
			y: number;
		};
	} = {
			enabled: false,
			start: {
				x: 0,
				y: 0,
			},
			last: {
				x: 0,
				y: 0,
			},
		};

	resize: {
		enabled: boolean;
		x: number;
		y: number;
	} = {
			enabled: false,
			x: 0,
			y: 0,
		};

	rotate: {
		enabled: boolean;
		x: number;
		y: number;
		temp: null | number;
	} = {
			enabled: false,
			x: 0,
			y: 0,
			temp: null,
		};

	crop: {
		drag: {
			enabled: boolean;
			start: {
				x: number;
				y: number;
			};
			last: {
				x: number;
				y: number;
			};
		};
		left: {
			enabled: boolean;
			x: number;
			y: number;
		};
		right: {
			enabled: boolean;
			x: number;
			y: number;
		};
	} = {
			drag: {
				enabled: false,
				start: {
					x: 0,
					y: 0,
				},
				last: {
					x: 0,
					y: 0,
				},
			},
			left: {
				enabled: false,
				x: 0,
				y: 0,
			},
			right: {
				enabled: false,
				x: 0,
				y: 0,
			},
		};

	// event properties for dropping a photo on the page (can be a template position)
	drop: {
		enabled: boolean;
		photoid: number|string|null;
		fontid: string|null;
		img: HTMLImageElement|null;
	} = {
			enabled: false,
			photoid: null,
			fontid: null,
			img: null,
		};

	pinch: {
		enabled: boolean;
		startObject: null | PI.PageObjectModel;
		startPointSize: number;
		startZoom: number;
	} = {
			enabled: false,
			startObject: null,
			startPointSize: 0,
			startZoom: 0,
		};

	startDrag(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.drag.enabled = true;
			this.drag.last.x = evt.pageX;
			this.drag.last.y = evt.pageY;
			this.drag.start.x = evt.pageX;
			this.drag.start.y = evt.pageY;
		}
	}

	startResize(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.resize.enabled = true;
			this.resize.x = evt.pageX;
			this.resize.y = evt.pageY;
		}
	}

	startRotate(
		x: number,
		y: number,
	) {
		this.rotate.enabled = true;
		this.rotate.x = x;
		this.rotate.y = y;
	}

	startCropDrag(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.crop.drag.enabled = true;
			this.crop.drag.last.x = evt.pageX;
			this.crop.drag.last.y = evt.pageY;
			this.crop.drag.start.x = evt.pageX;
			this.crop.drag.start.y = evt.pageY;
		}
	}

	startCropLeft(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.crop.left.enabled = true;
			this.crop.left.x = evt.pageX;
			this.crop.left.y = evt.pageY;
		}
	}

	startCropRight(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.crop.right.enabled = true;
			this.crop.right.x = evt.pageX;
			this.crop.right.y = evt.pageY;
		}
	}

	startDrop(
		evt: MouseEvent|Touch,
		photoid: number|string,
		imageUrl?: string,
	) {
		this.drop.enabled = true;
		this.drop.photoid = photoid;

		if (this.drop.img && this.drop.img.parentNode) {
			// Clean previous image
			this.drop.img.parentNode.removeChild(this.drop.img);
		}

		if (imageUrl) {
			const imgNode = document.createElement('img');
			imgNode.src = imageUrl;
			this.drop.img = imgNode;
		} else {
			const photoModel = PhotosModule.getById(photoid);
			if (!photoModel) {
				throw new Error('Could not find required photo model');
			}

			imageUrl = photoModel.thumb_url && photoModel.thumb_url.length > 0
				? photoModel.thumb_url
				: photoModel.url || photoModel.full_url || undefined;
			if (imageUrl) {
				this.drop.img = getImageNode(imageUrl);
			}
		}

		if (this.drop.img) {
			const offsetLeft = evt.pageX - this.drop.img.clientWidth / 2;
			const offsetTop = evt.pageY - this.drop.img.clientHeight / 2;
			this.drop.img.style.cssText = 'position: absolute; z-index: 100; max-height: 75px; cursor: move;';
			this.drop.img.style.left = `${offsetLeft}px`;
			this.drop.img.style.top = `${offsetTop}px`;
			document.body.appendChild(this.drop.img);
		}
	}

	startTextDrop(
		evt: MouseEvent|Touch,
		fontid: string,
		imageUrl?: string,
	) {
		this.drop.enabled = true;
		this.drop.fontid = fontid;

		if (this.drop.img && this.drop.img.parentNode) {
			// Clean previous image
			this.drop.img.parentNode.removeChild(this.drop.img);
		}

		if (imageUrl) {
			const imgNode = document.createElement('img');
			imgNode.src = imageUrl;
			this.drop.img = imgNode;

			const offsetLeft = evt.pageX - this.drop.img.clientWidth / 2;
			const offsetTop = evt.pageY - this.drop.img.clientHeight / 2;

			this.drop.img.style.cssText = 'position: absolute; z-index: 100; cursor: move; background: rgba(255,255,255,0.5);';
			this.drop.img.style.left = `${offsetLeft}px`;
			this.drop.img.style.top = `${offsetTop}px`;
			document.body.appendChild(this.drop.img);
		}
	}

	endDrop() {
		if (this.drop.img && this.drop.img.parentNode) {
			this.drop.img.parentNode.removeChild(this.drop.img);
		}

		this.drop.enabled = false;
		this.drop.photoid = null;
		this.drop.fontid = null;
		this.drop.img = null;
	}

	dragEvent(
		e: MouseEvent|TouchEvent,
		scaling: number,
	) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
			? e.changedTouches.item(0)
			: e;
		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			const offeringModel = ProductStateModule.getOffering;
			const pageModel = ProductStateModule.getActivePage;
			const objectModel = ProductStateModule.getSelectedPageObject;

			if (
				this.drag.enabled
				|| this.resize.enabled
				|| this.rotate.enabled
				|| this.crop.drag.enabled
				|| this.crop.left.enabled
				|| this.crop.right.enabled
				|| this.drop.enabled
			) {
				// Disable native browser events
				e.preventDefault();
			}

			if (!this.pinch.enabled
				&& this.crop.drag.enabled
				&& objectModel
				&& (evt.pageX - this.crop.drag.last.x !== 0 || evt.pageY - this.crop.drag.last.y !== 0)
			) {
				// Calculate mouse movement since last check
				const xDelta = evt.pageX - this.crop.drag.last.x;
				const yDelta = evt.pageY - this.crop.drag.last.y;

				if (objectModel.photoid) {
					const specs = PageObject.specs(
						objectModel,
						pageModel,
					);
					const ro = 0; // In this cropping modus, we always show the photo straight up, rotation is ignored
					const cos = Math.cos(Math.PI / 180 * ro);
					const sin = Math.sin(Math.PI / 180 * ro);
					const xChange = 1 / specs.objectZoom * ((xDelta * cos + yDelta * sin) / scaling);
					const yChange = 1 / specs.objectZoom * ((yDelta * cos - xDelta * sin) / scaling);

					ProductStateModule.changePageObject({
						id: objectModel.id,
						cropx: Math.max(
							0,
							Math.min(
								objectModel.maxwidth - objectModel.cropwidth,
								objectModel.cropx + xChange,
							),
						),
						cropy: Math.max(
							0,
							Math.min(
								objectModel.maxheight - objectModel.cropheight,
								objectModel.cropy + yChange,
							),
						),
					});
				}

				this.crop.drag.last.x = evt.pageX;
				this.crop.drag.last.y = evt.pageY;
			}

			if (!this.pinch.enabled
				&& this.drag.enabled
				&& objectModel
				&& (evt.pageX - this.drag.last.x !== 0 || evt.pageY - this.drag.last.y !== 0)
			) {
				// Calculate mouse movement since last check
				const xDelta = evt.pageX - this.drag.last.x;
				const yDelta = evt.pageY - this.drag.last.y;
				let updateXposition = true;
				let updateYposition = true;

				if (
					AppStateModule.objectLock
					&& objectModel.photoid
				) {
					const specs = PageObject.specs(
						objectModel,
						pageModel,
					);
					const cos = Math.cos(Math.PI / 180 * objectModel.rotate);
					const sin = Math.sin(Math.PI / 180 * objectModel.rotate);
					const xChange = 1 / specs.objectZoom * ((xDelta * cos + yDelta * sin) / scaling);
					const yChange = 1 / specs.objectZoom * ((yDelta * cos - xDelta * sin) / scaling);

					ProductStateModule.changePageObject({
						id: objectModel.id,
						cropx: Math.max(
							0,
							Math.min(
								objectModel.maxwidth - objectModel.cropwidth,
								objectModel.cropx - xChange,
							),
						),
						cropy: Math.max(
							0,
							Math.min(
								objectModel.maxheight - objectModel.cropheight,
								objectModel.cropy - yChange,
							),
						),
					});
				} else if (objectModel.transformable && pageModel) {
					// First we make sure that there will always be a little part of the object visible on the page
					const minVisiblePixels = 50;
					const xMarginMax = pageModel.width - minVisiblePixels;
					const yMarginMax = Math.max(
						pageModel.height - minVisiblePixels,
						pageModel.height - objectModel.height,
					);
					const xMarginMin = minVisiblePixels - objectModel.width;
					const yMarginMin = Math.min(
						minVisiblePixels - objectModel.height,
						0,
					);

					let newXaxis = Math.max(
						xMarginMin,
						Math.min(
							xMarginMax,
							Math.round(objectModel.x_axis + xDelta / scaling),
						),
					);
					let newYaxis = Math.max(
						yMarginMin,
						Math.min(
							yMarginMax,
							Math.round(objectModel.y_axis + yDelta / scaling),
						),
					);

					// Next we apply "magnetism" to the edges of the pages
					// so when a user is moving the object close to the edges, it will automatically
					// snap to the edge of the page or to the page safety margin
					const pageMargin = offeringModel?.pagemargin ?? 0;
					const bleedMargin = offeringModel?.bleedmargin ?? 0;

					const minNegativeMagnetismPageOrigin = -bleedMargin;
					const minPositiveMagnetismPageOrigin = pageMargin;
					const minCenterMagnetismPageOrigin = (minNegativeMagnetismPageOrigin + minPositiveMagnetismPageOrigin) / 2;

					const maxNegativeMagnetismPageRightSide = pageModel.width - pageMargin;
					const maxPositiveMagnetismPageRightSide = pageModel.width + bleedMargin;
					const maxCenterMagnetismPageRightSide = (maxNegativeMagnetismPageRightSide + maxPositiveMagnetismPageRightSide) / 2;

					const maxNegativeMagnetismPageBottomSide = pageModel.height - pageMargin;
					const maxPositiveMagnetismPageBottomSide = pageModel.height + bleedMargin;
					const maxCenterMagnetismPageBottomSide = (maxNegativeMagnetismPageBottomSide + maxPositiveMagnetismPageBottomSide) / 2;

					if (newXaxis > minNegativeMagnetismPageOrigin
						&& newXaxis < minPositiveMagnetismPageOrigin
					) {
						// The object is somewhere between the left magnetic line and the right magnetic line
						// on the left side of the page, so we snap it to the closest one
						newXaxis = newXaxis < minCenterMagnetismPageOrigin
							? minNegativeMagnetismPageOrigin
							: minPositiveMagnetismPageOrigin;
					}

					if (newYaxis > minNegativeMagnetismPageOrigin
						&& newYaxis < minPositiveMagnetismPageOrigin
					) {
						// The object is somewhere between the top magnetic line and the bottom magnetic line
						// on the top side of the page, so we snap it to the closest one
						newYaxis = newYaxis < minCenterMagnetismPageOrigin
							? minNegativeMagnetismPageOrigin
							: minPositiveMagnetismPageOrigin;
					}

					if (newXaxis + objectModel.width > maxNegativeMagnetismPageRightSide
						&& newXaxis + objectModel.width < maxPositiveMagnetismPageRightSide
					) {
						// The object is somewhere between the left magnetic line and the right magnetic line
						// on the right side of the page, so we snap it to the closest one
						newXaxis = newXaxis + objectModel.width < maxCenterMagnetismPageRightSide
							? maxNegativeMagnetismPageRightSide - objectModel.width
							: maxPositiveMagnetismPageRightSide - objectModel.width;
					}

					if (newYaxis + objectModel.height > maxNegativeMagnetismPageBottomSide
						&& newYaxis + objectModel.height < maxPositiveMagnetismPageBottomSide
					) {
						// The object is somewhere between the top magnetic line and the bottom magnetic line
						// on the bottom side of the page, so we snap it to the closest one
						newYaxis = newYaxis + objectModel.height < maxCenterMagnetismPageBottomSide
							? maxNegativeMagnetismPageBottomSide - objectModel.height
							: maxPositiveMagnetismPageBottomSide - objectModel.height;
					}

					// We should only update the internal drag coordinates when the position of the object has moved
					// Here we disable the flag in case the movement stayed within the page border margins and thus
					// hasn't really moved
					if (newXaxis == objectModel.x_axis) {
						updateXposition = false;
					}
					if (newYaxis == objectModel.y_axis) {
						updateYposition = false;
					}

					if (newXaxis != objectModel.x_axis || newYaxis != objectModel.y_axis) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							x_axis: newXaxis,
							y_axis: newYaxis,
						});

						if (pageModel
							&& !pageModel.customLayout
						) {
						// The user is moving the photos on the page, thereby indicating it wants to freeform
						// and we disable the automatic template switch when new photos are added to the page
							ProductStateModule.changePage({
								id: pageModel.id,
								customLayout: true,
							});
						}
					}
				}

				if (updateXposition) {
					this.drag.last.x = evt.pageX;
				}
				if (updateYposition) {
					this.drag.last.y = evt.pageY;
				}
			}

			if (this.resize.enabled
				&& objectModel
				&& (evt.pageX - this.resize.x !== 0 || evt.pageY - this.resize.y !== 0)
			) {
				// Calculate mouse movement since last check
				const xDelta = evt.pageX - this.resize.x;
				const yDelta = evt.pageY - this.resize.y;

				// Flag parameter that will be set if the object has reached maximum size
				let flagMaxSize = false;

				if (objectModel.transformable) {
					// Calculate mouse movements with respect to the current scaling of the displayed page
					const ro = objectModel.rotate % 180;
					const cos = Math.abs(Math.cos(Math.PI / 180 * ro));
					const sin = Math.abs(Math.sin(Math.PI / 180 * ro));
					let xChange = (xDelta * cos + yDelta * sin) / scaling;
					const yChange = (yDelta * cos + xDelta * sin) / scaling;

					if (objectModel.type == 'photo') {
						// Keep width-height ratio of the object
						const scale = objectModel.width / objectModel.height;
						xChange = scale * yChange;
					}

					// Calculate the maximum size of the object
					const photoModel = objectModel.photoid
						? PhotosModule.getById(objectModel.photoid)
						: undefined;
					const photoData = photoModel
						? {
							width: photoModel.full_width,
							height: photoModel.full_height,
						}
						: undefined;
					let maxsize = maxObjectSize(
						{
							maxwidth: objectModel.maxwidth,
							maxheight: objectModel.maxheight,
							cropwidth: objectModel.cropwidth,
							cropheight: objectModel.cropheight,
							type: objectModel.type,
						},
						photoData,
						offeringModel,
					);

					// Check if the object will have the minimum of 10x10 pixel size with the new values
					if (objectModel.width + xChange >= 10 && objectModel.height + yChange >= 10) {
						// Only change the size of the object if it is not a photo object
						// or if it's not bigger than the maximum allowable size for the photo with respect to the minimum dpi setting for the product
						if (objectModel.type != 'photo'
							|| yChange < 0
							|| (objectModel.width + xChange <= maxsize.width && objectModel.height + yChange <= maxsize.height)
						) {
							// Set the new size of the object
							ProductStateModule.changePageObject({
								id: objectModel.id,
								width: objectModel.width + xChange,
								height: objectModel.height + yChange,
								x_axis: objectModel.x_axis - xChange / 2,
								y_axis: objectModel.y_axis - yChange / 2,
							});
						} else if (objectModel.type == 'photo') {
							// Set maximum size for object
							if (objectModel.width + xChange > maxsize.width) {
								// Width is over maximum, so we set the object to its maximum width
								ProductStateModule.changePageObject({
									id: objectModel.id,
									width: maxsize.width,
									height: (objectModel.height / objectModel.width * maxsize.width),
								});
							} else {
								// Height is over maximum, so we set the object to its maximum height
								ProductStateModule.changePageObject({
									id: objectModel.id,
									height: maxsize.height,
									width: (objectModel.width / objectModel.height * maxsize.height),
								});
							}
						}
					}

					if (objectModel.type == 'photo') {
						// Get the object properties with respect to the new with and height settings
						const specs = PageObject.specs(
							objectModel,
							pageModel,
						);
						maxsize = maxObjectSize(
							{
								maxwidth: objectModel.maxwidth,
								maxheight: objectModel.maxheight,
								cropwidth: objectModel.cropwidth,
								cropheight: objectModel.cropheight,
								type: objectModel.type,
							},
							photoData,
							offeringModel,
						);

						// Check if we need to change the cropping of the photo (if the object has outgrown the current cropping)
						if (objectModel.cropwidth > 0 && objectModel.cropheight > 0 && specs.zoomValue > 1) {
							const newcropwidth = Math.min(
								specs.maxCropWidth,
								objectModel.width / maxsize.width * specs.maxCropWidth,
							);
							const newcropheight = Math.min(
								specs.maxCropHeight,
								objectModel.height / maxsize.height * specs.maxCropHeight,
							);
							const newcropx = Math.max(
								0,
								objectModel.cropx - (newcropwidth - objectModel.cropwidth) / 2,
							);
							const newcropy = Math.max(
								0,
								objectModel.cropy - (newcropheight - objectModel.cropheight) / 2,
							);

							ProductStateModule.changePageObject({
								id: objectModel.id,
								cropx: Math.min(
									objectModel.maxwidth - newcropwidth,
									newcropx,
								),
								cropy: Math.min(
									objectModel.maxheight - newcropheight,
									newcropy,
								),
								cropwidth: newcropwidth,
								cropheight: newcropheight,
							});
						}

						// Check if the object is bigger than the minimum dpi setting for the product
						if (Math.round(objectModel.width) >= Math.round(maxsize.width) || Math.round(objectModel.height) >= Math.round(maxsize.height)) {
							flagMaxSize = true;
						}
					}
				}

				// Update the internal resize properties
				if (!flagMaxSize || yDelta < 0) {
					this.resize.x = evt.pageX;
					this.resize.y = evt.pageY;
				}
			}

			if (objectModel
				&& this.rotate.enabled
				&& (evt.pageX - this.rotate.x !== 0 || evt.pageY - this.rotate.y !== 0)
			) {
				const xMove = evt.pageX - this.rotate.x;
				const yMove = evt.pageY - this.rotate.y;
				const angle = Math.atan2(
					yMove,
					xMove,
				);
				const rotate = angle / (Math.PI / 180);

				if (objectModel && objectModel.transformable) {
					if (this.rotate.temp === null) {
						// calculate corner of object right top corner to middle
						const cornerAngle = Math.atan2(
							objectModel.height / 2,
							objectModel.width / 2,
						) / (Math.PI / 180);
						// set that angle to the start compensation value
						this.rotate.temp = -cornerAngle;
					}

					ProductStateModule.changePageObject({
						id: objectModel.id,
						rotate: (objectModel.rotate + rotate - this.rotate.temp) % 360,
					});
				}

				this.rotate.temp = rotate;
			}

			if (this.crop.left.enabled
				&& (evt.pageX - this.crop.left.x !== 0 || evt.pageY - this.crop.left.y !== 0)
			) {
				if (!objectModel) {
					// No product selected, reset status
					this.crop.left.enabled = false;
				} else {
					const photoModel = objectModel.photoid
						? PhotosModule.getById(objectModel.photoid)
						: undefined;
					const photoData = photoModel
						? {
							width: photoModel.full_width,
							height: photoModel.full_height,
						}
						: undefined;
					const maxsize = maxObjectSize(
						{
							maxwidth: objectModel.maxwidth,
							maxheight: objectModel.maxheight,
							cropwidth: objectModel.cropwidth,
							cropheight: objectModel.cropheight,
							type: objectModel.type,
						},
						photoData,
						offeringModel,
					);

					// Calculate mouse movement
					let xDelta = evt.pageX - this.crop.left.x;
					let yDelta = evt.pageY - this.crop.left.y;

					// Prevent illegal values
					if (objectModel.cropwidth >= objectModel.maxwidth && xDelta < 0) {
						xDelta = 0;
					}
					if (objectModel.cropheight >= objectModel.maxheight && yDelta < 0) {
						yDelta = 0;
					}

					// The scale of the object on the page
					const objectScale = objectModel.cropwidth / objectModel.width;

					// Calculate the rotation angle of the object
					const cos = Math.cos(Math.PI / 180 * objectModel.rotate);
					const sin = Math.sin(Math.PI / 180 * objectModel.rotate);

					// Include the rotation of the object in the mouse movement
					const xChange = xDelta * objectScale * cos + yDelta * sin;
					const yChange = yDelta * objectScale * cos - xDelta * sin;

					// Store old crop values in local variable for future reference
					const oldcropx = objectModel.cropx;
					const oldcropy = objectModel.cropy;

					// Calculate the new crop x value
					let newcropx = Math.min(
						maxsize.width,
						objectModel.cropx + xChange / scaling,
					);
					newcropx = Math.max(
						0,
						newcropx,
					);

					// Calculate the new crop y value
					let newcropy = Math.min(
						maxsize.height,
						objectModel.cropy + yChange / scaling,
					);
					newcropy = Math.max(
						0,
						newcropy,
					);

					// Calculate the new size of the object
					const newWidth = objectModel.width + (oldcropx - newcropx) / objectScale;
					const newHeight = objectModel.height + (oldcropy - newcropy) / objectScale;

					// Calculate the difference between the old- and the new values
					const deltaXaxis = oldcropx - newcropx;
					const deltaYaxis = oldcropy - newcropy;

					// Calculate the new position of the object
					const newX = objectModel.x_axis - deltaXaxis / objectScale;
					const newY = objectModel.y_axis - deltaYaxis / objectScale;

					// Calculate new crop width and height
					const newcropwidth = objectModel.cropwidth + oldcropx - newcropx;
					const newcropheight = objectModel.cropheight + oldcropy - newcropy;

					if (newWidth >= 10 && newHeight >= 10) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							cropx: Math.min(
								objectModel.maxwidth - newcropwidth,
								newcropx,
							),
							cropy: Math.min(
								objectModel.maxheight - newcropheight,
								newcropy,
							),
							cropwidth: newcropwidth,
							cropheight: newcropheight,
							width: newWidth,
							height: newHeight,
							x_axis: newX,
							y_axis: newY,
						});

						if (xDelta !== 0) {
							this.crop.left.x = evt.pageX;
						}
						if (yDelta !== 0) {
							this.crop.left.y = evt.pageY;
						}
					}
				}
			}

			if (this.crop.right.enabled
				&& (evt.pageX - this.crop.right.x !== 0 || evt.pageY - this.crop.right.y !== 0)
			) {
				if (!objectModel) {
					// No product selected, reset status
					this.crop.right.enabled = false;
				} else {
					// Calculate mouse movement
					const xDelta = evt.pageX - this.crop.right.x;
					const yDelta = evt.pageY - this.crop.right.y;

					// The scale of the object on the page
					const objectScale = objectModel.cropwidth / objectModel.width;

					// Calculate the rotation angle of the object
					const cos = Math.cos(Math.PI / 180 * objectModel.rotate);
					const sin = Math.sin(Math.PI / 180 * objectModel.rotate);

					// Include the rotation of the object in the mouse movement
					const xChange = xDelta * objectScale * cos + yDelta * sin;
					const yChange = yDelta * objectScale * cos - xDelta * sin;

					// Store old crop values in local variable for future reference
					const oldcropwidth = objectModel.cropwidth;
					const oldcropheight = objectModel.cropheight;

					// Calculate the new crop x value
					let newcropwidth = Math.min(
						objectModel.maxwidth - objectModel.cropx,
						objectModel.cropwidth + xChange / scaling,
					);
					newcropwidth = Math.max(
						0,
						newcropwidth,
					);

					// Calculate the new crop y value
					let newcropheight = Math.min(
						objectModel.maxheight - objectModel.cropy,
						objectModel.cropheight + yChange / scaling,
					);
					newcropheight = Math.max(
						0,
						newcropheight,
					);

					// Calculate the new size of the object
					const newWidth = objectModel.width + (newcropwidth - oldcropwidth) / objectScale;
					const newHeight = objectModel.height + (newcropheight - oldcropheight) / objectScale;

					if (newWidth >= 10 && newHeight >= 10) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							cropwidth: newcropwidth,
							cropheight: newcropheight,
							width: newWidth,
							height: newHeight,
						});

						if (newcropwidth - oldcropwidth !== 0) {
							this.crop.right.x = evt.pageX;
						}
						if (newcropheight - oldcropheight !== 0) {
							this.crop.right.y = evt.pageY;
						}
					}
				}
			}

			if (this.drop.img) {
				this.drop.img.style.left = `${evt.pageX - this.drop.img.clientWidth / 2}px`;
				this.drop.img.style.top = `${evt.pageY - this.drop.img.clientHeight / 2}px`;
			}
		}
	}

	pinchEvent(hammerEvent: HammerInput) {
		const pageModel = ProductStateModule.getActivePage;
		const objectModel = ProductStateModule.getSelectedPageObject;
		if (objectModel) {
			if (!this.pinch.enabled) {
				this.pinch.enabled = true;
				this.pinch.startObject = JSON.parse(JSON.stringify(objectModel));

				if (objectModel.type == 'text') {
					this.pinch.startPointSize = objectModel.pointsize || 10;
				} else {
					const specs = PageObject.specs(
						objectModel,
						pageModel,
					);
					this.pinch.startZoom = specs.zoomValue;
				}
			}

			const offeringModel = ProductStateModule.getOffering;
			if (objectModel.type == 'text') {
				const { startPointSize } = this.pinch;
				const value = hammerEvent.scale < 1
					? Math.max(
						offeringModel ? offeringModel.minfontsize : 12,
						startPointSize * hammerEvent.scale,
					)
					: Math.min(
						300,
						startPointSize * Math.sqrt(hammerEvent.scale),
					);

				ProductStateModule.changePageObject({
					id: objectModel.id,
					pointsize: Math.round(value),
				});
			} else if (AppStateModule.objectLock && objectModel.photoid) {
				const { startZoom, startObject } = this.pinch;
				const zoom = hammerEvent.scale < 1 ? startZoom * hammerEvent.scale : Math.min(
					1,
					startZoom + (1 / 10 * (hammerEvent.scale - 1)),
				);

				if (startObject && pageModel) {
					zoomObject(
						objectModel,
						zoom,
						startObject,
						pageModel,
					);
				}
			} else if (objectModel.transformable) {
				// Calculate the maximum size of the object
				const photoModel = objectModel.photoid
					? PhotosModule.getById(objectModel.photoid)
					: undefined;
				const photoData = photoModel
					? {
						width: photoModel.full_width,
						height: photoModel.full_height,
					}
					: undefined;
				const maxsize = maxObjectSize(
					{
						maxwidth: objectModel.maxwidth,
						maxheight: objectModel.maxheight,
						cropwidth: objectModel.cropwidth,
						cropheight: objectModel.cropheight,
						type: objectModel.type,
					},
					photoData,
					offeringModel,
				);
				const { startObject } = this.pinch;

				if (startObject && pageModel) {
					// Calculate new width and height of the object
					const newObjectWidth = hammerEvent.scale < 1
						? startObject.width * hammerEvent.scale
						: startObject.width + (pageModel.width / 10 * (hammerEvent.scale - 1));
					const newObjectHeight = (newObjectWidth / startObject.width) * startObject.height;

					// Check if the object will have the minimum of 10x10 pixel size with the new values
					if (newObjectWidth > 10 && newObjectHeight > 10) {
						// Only change the size of the object if it is not a photo object
						// or if it's not bigger than the maximum allowable size for the photo with respect to the minimum dpi setting for the product
						if (objectModel.type != 'photo' || (newObjectWidth <= maxsize.width && newObjectHeight <= maxsize.height)) {
							// Set the new size of the object, but do not trigger change event yet
							ProductStateModule.changePageObject({
								id: objectModel.id,
								width: newObjectWidth,
								height: newObjectHeight,
								x_axis: startObject.x_axis - (newObjectWidth - startObject.width) / 2,
								y_axis: startObject.y_axis - (newObjectHeight - startObject.height) / 2,
							});
						} else if (objectModel.type == 'photo') {
							// Set maximum size for object
							if (newObjectWidth > maxsize.width) {
								// Width is over maximum, so we set the object to its maximum width
								ProductStateModule.changePageObject({
									id: objectModel.id,
									width: maxsize.width,
									height: (objectModel.height / objectModel.width * maxsize.width),
								});
							} else {
								// Height is over maximum, so we set the object to its maximum height
								ProductStateModule.changePageObject({
									id: objectModel.id,
									height: maxsize.height,
									width: (objectModel.width / objectModel.height * maxsize.height),
								});
							}
						}
					}
				}
			}
		}
	}

	endDragEvent() {
		const pageModel = ProductStateModule.getActivePage;

		if (pageModel) {
			this.drag.enabled = false;
			this.resize.enabled = false;
			this.rotate.enabled = false;
			this.rotate.temp = null;
			this.crop.drag.enabled = false;
			this.crop.left.enabled = false;
			this.crop.right.enabled = false;
			this.pinch.enabled = false;

			this.endDrop();
		}
	}
}

export default new TouchHandler();
