import momentExtended from 'moment-business-days';
import holidays from '../settings/holidays';

const compileBusinessDays = (
	date: string | null,
	days: number,
	country: string,
	format?: string,
) => {
	// @ts-ignore: Typescript does not understand the momentExtended constructor
	const Moment = date ? momentExtended(date) : momentExtended();
	format = format || 'YYYY-MM-DD HH:mm:ss';

	if (country) {
		country = country.toLowerCase();
		if (holidays.hasOwnProperty(country)) {
			momentExtended.locale(
				country,
				{
					holidays: holidays[country],
					holidayFormat: 'YYYY-MM-DD',
				},
			);
		}
	}

	return Moment.businessAdd(days).format(format);
};

export default compileBusinessDays;
