import { Vue, Component, Prop } from 'vue-property-decorator';
import analytics from '../controllers/analytics';
import auth from '../controllers/auth';

@Component
export default class AuthView extends Vue {
	@Prop({ default: true, type: Boolean })
	readonly showGuest!: boolean;

	anonymousLogin() {
		analytics.trackEvent(
			'Login as guest',
			{
				category: 'Button',
			},
		);

		auth.guest();
	}

	login() {
		auth.showLogin();
	}

	signup() {
		auth.showSignup();
	}
}
