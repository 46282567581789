import {
	AppDataModule,
	ConfigModule,
} from '../store/index';

export default function getBucketUrl(bucketId: string): string | undefined {
	const bucketModel = AppDataModule.getBucket(bucketId);
	if (bucketModel) {
		let bucketURL = bucketModel.cdn || bucketModel.url;
		if (bucketId == 'appfiles' && (!window.glStack || window.glStack != 'local')) {
			if (ConfigModule.appDataVersion) {
				const arrAppDataVersion = ConfigModule.appDataVersion.split('.');
				bucketURL += `${arrAppDataVersion[0]}/`;
			}
		}

		return bucketURL;
	} if (bucketId == 'appfiles') {
		return '/';
	}

	return undefined;
}
