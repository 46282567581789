import ProductState from 'classes/productstate';
import TemplatePosition from 'classes/templateposition';
import { EventEmitter } from 'events';
import * as PI from 'interfaces/project';
import * as DB from 'interfaces/database';
import { TemplateSet, TemplatePhotoPosition, TemplateTextPosition } from 'interfaces/app';
import * as DialogService from 'services/dialog';
import { OfferingGroups } from 'settings/offerings';
import {
	AppDataModule,
	AppStateModule,
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
} from 'store';
import _ from 'underscore';
import splitNumber from 'tools/split-number';
import analytics from './analytics';

let progressDialog: ReturnType<typeof DialogService.openProgressDialog> | undefined;

interface DynamicPage {
	index: number;
	pageModel: PI.PageModel;
	photoCount: number;
}

class Template extends EventEmitter {
	private pageModels: PI.PageModel[] = [];

	private photoModels: PI.PhotoModel[] = [];

	private getPhotoModels(): PI.PhotoModel[] {
		const models = ProductStateModule.getUnusedPhotos.filter(
			// Filter out photos of which we don't know the size
			(photoModel) => photoModel.full_height && photoModel.full_width,
		);

		if (ProductStateModule.getProductSettings.autoFillMethod == 'photodate') {
			return _.sortBy(
				models,
				'photodate',
			);
		}

		return models;
	}

	public autoFill(
		pageCount?: number,
	): Promise<void> {
		const offeringModel = ProductStateModule.getOffering;
		if (!offeringModel) {
			throw new Error('Could not find required offering model');
		}

		if (pageCount
			&& pageCount > ProductStateModule.getPages.length
			&& pageCount < offeringModel.maxpages
		) {
			const pagesToAdd = pageCount - ProductStateModule.getPages.length;
			const promiseAll: Promise<PI.PageModel>[] = [];
			for (
				let y = 0;
				y < pagesToAdd && ProductStateModule.getPages.length < offeringModel.maxpages;
				y += 1
			) {
				promiseAll.push(
					ProductState.addPage(false),
				);
			}
			return Promise.all(
				promiseAll,
			).then(
				() => this.autoFill(pageCount),
			);
		}

		this.photoModels = this.getPhotoModels();

		if (this.photoModels.length) {
			// Setup progress model for dialog
			if (progressDialog) {
				progressDialog.close();
				progressDialog = undefined;
			}

			progressDialog = DialogService.openProgressDialog({
				header: {
					title: window.App.router.$t('dialogHeaderGenerate'),
				},
				body: {
					props: {
						value: 0,
						total: this.photoModels.length,
					},
					listeners: {
						complete: () => {
							progressDialog?.close();
							progressDialog = undefined;
						},
					},
				},
				lightOverlay: true,
			});
		}

		const productModel = ProductStateModule.getProduct;
		let pageModels = ProductStateModule.getEditablePages;

		if (
			productModel
			&& productModel.group == 102
		) {
			const cardFrontPageModel = ProductStateModule.getPageByNumber(1);

			if (!cardFrontPageModel) {
				throw new Error('Missing postcard front page model');
			}

			pageModels = [cardFrontPageModel];
		}

		// Filter pages with templates that are part of auto filling and have available template positions
		pageModels = pageModels.filter((pageModel) => {
			if (!pageModel.template) {
				return false;
			}

			const templateModel = ThemeDataModule.getTemplate(pageModel.template);
			if (!templateModel || !templateModel.autofill) {
				return false;
			}

			if (templateModel.dynamic
				&& (
					!pageModel.objectList
					|| pageModel.objectList.length === 0
				)
			) {
				return true;
			}

			const available = ProductStateModule.getPageTemplatePositionsAvailable(
				pageModel,
				[this.photoModels[0]],
			);
			return available.length > 0;
		});

		this.pageModels = pageCount && pageModels.length > pageCount
			? pageModels.slice(
				0,
				pageCount,
			)
			: pageModels;

		AppStateModule.setHeavyLoad();

		return this.fillPages()
			.then(() => this.checkReady())
			.then(() => this.fillProductAttributes())
			.finally(() => {
				ProductStateModule.pushHistory();
				AppStateModule.unsetHeavyLoad();
			});
	}

	private checkReady(): Promise<void> {
		const productModel = ProductStateModule.getProduct;

		if (!productModel) {
			throw new Error('Could not find required product model');
		}

		const offeringModel = ProductStateModule.getOffering;

		if (!offeringModel) {
			throw new Error('Could not find required offering model');
		}

		const offerings = AppDataModule.findOffering({
			groupid: productModel.group,
			typeid: productModel.typeid,
		});
		const maxOfferingModel = _.max(
			offerings,
			(offering) => offering.maxpages,
		);

		if (typeof maxOfferingModel === 'number') {
			throw new Error(`Could not find required offeringModel with groupid ${productModel.group} and typeid ${productModel.typeid}`);
		}

		const { maxpages } = maxOfferingModel;
		const minOfferingModel = _.min(
			offerings,
			(offering) => offering.minpages,
		);

		if (typeof minOfferingModel === 'number') {
			throw new Error(`Could not find required offeringModel with groupid ${productModel.group} and typeid ${productModel.typeid}`);
		}

		const { minpages } = minOfferingModel;
		const pagecount = ProductStateModule.getPagesQuantity;

		return new Promise((resolve, reject) => {
			if (this.photoModels.length > 0
				&& pagecount < maxpages
			) {
				const photoModel = this.photoModels[0];
				// give some breathing space to prevent browser freeze
				const waitMiliseconds = 10;
				window.setTimeout(
					() => {
						// Refresh photo models
						this.photoModels = this.getPhotoModels();

						// add new page to product
						ProductState
							.addPage(
								false,
								photoModel.full_height > photoModel.full_width ? 'p' : 'l',
								photoModel.full_width,
								photoModel.full_height,
							)
							.then((newPageModel) => {
								this.pageModels.push(newPageModel);
								return this
									.fillPage(
										newPageModel,
										this.photoModels.slice(0),
									).catch(() => {
										// Swallow error: no action required
									});
							}).then(() => this.checkReady())
							.then(resolve)
							.catch(reject);
					},
					waitMiliseconds,
				);
			} else if (
				maxpages > 1
				&& this.photoModels.length > 0
				&& pagecount >= maxpages
				&& maxpages > minpages // Do not show dialog for products with fixed number of pages
			) {
				const closeAlert = DialogService.openAlertDialog({
					header: {
						title: window.App.router.$t('dialogHeaderMaxPages'),
					},
					body: {
						content: window.App.router.$t('dialogTextMaxPages'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonMaxPagesOk'),
								click: () => {
									// Close progress dialog (automatically at 100%)

									if (progressDialog) {
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										const dialogProgressComponent = progressDialog.api.bodyComponent()!;
										dialogProgressComponent.value = dialogProgressComponent.total;
									}

									resolve();
									closeAlert();
								},
							},
						],
					},
				});
			} else {
				// Check minimum setup pages
				const extraPageQuantity = minpages - ProductStateModule.getPages.length;
				const afterAdding = _.after(
					extraPageQuantity,
					() => {
						if (
							productModel.group == 103
							&& offeringModel.hasback
							&& ProductStateModule.getPages.length % 2 === 1
						) {
							// Make page count even
							ProductState.addPage(true);
						} else if (
							OfferingGroups(
								productModel.group,
								['DoubleSidePrints'],
							)
							&& ProductStateModule.getPages.length % 2 === 1
						) {
							// Make page count even
							ProductState.addPage(true);
						} else if (
							OfferingGroups(
								productModel.group,
								['BookTypes'],
							)
							&& minOfferingModel.pageinterval === 2
							&& ProductStateModule.getPages.length % 2 === 1
						) {
							// Make page count even
							ProductState.addPage(true);
						}

						// Close progress dialog (automatically at 100%)

						if (progressDialog) {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							const dialogProgressComponent = progressDialog.api.bodyComponent()!;
							dialogProgressComponent.value = dialogProgressComponent.total;
						}

						analytics.trackEvent(
							'Product filled',
							{
								category: 'Process',
								label: 'auto',
								group: productModel.group,
							},
						);

						resolve();
					},
				);

				if (extraPageQuantity > 0) {
					for (let y = 0; y < extraPageQuantity; y += 1) {
						ProductState
							.addPage(false)
							.then(() => {
								afterAdding();
							});
					}
				} else {
					afterAdding();
				}
			}
		});
	}

	private getPageType(
		groupId: DB.OfferingModel['groupid'],
		pageModel: PI.PageModel,
	): 'backCover' | 'frontCover' | undefined {
		if (
			OfferingGroups(
				groupId,
				['BookTypes'],
			)
		) {
			const pageIndex = ProductStateModule.getPageIndex(
				pageModel,
			);

			if (pageIndex === 0) {
				return 'backCover';
			}

			if (pageIndex === 1) {
				return 'frontCover';
			}

			if (pageIndex === 3) {
				// Check if this is a page behind a cover with transparency
				const coverPageModel = ProductStateModule.getPageByNumber(1);

				if (coverPageModel?.bgcolor == 'transparent') {
					return 'frontCover';
				}
			}
		}

		return undefined;
	}

	private async fillPages(): Promise<void> {
		// We keep a count of all photos that need to be placed inside a dynamic template
		let photosToPlaceDynamically = this.photoModels.length;

		// We create an array of all pages with a dynamic template that we need to fill
		const dynamicPages: DynamicPage[] = [];

		// We loop over all pages to find out how many photos we need to place inside dynamic templates
		this.pageModels.forEach((pageModel, i) => {
			// If the page doens't have a template, we can skip it
			if (!pageModel.template) {
				return;
			}

			// Get the template from the theme data
			const templateModel = ThemeDataModule.getTemplate(
				pageModel.template,
			);

			// If the template doesn't exist or doesn't have the autofill flag, we can skip it
			if (!templateModel?.autofill) {
				return;
			}

			if (!templateModel.dynamic) {
				// This is not a dynamic template,
				// so we need to check if it has any photo positions with the flag set
				// and deduct this from the number of photos we need to place dynamically
				const templatePositions = ProductStateModule.getPageTemplatePositionsAvailable(
					pageModel,
				);
				const photoPositionsWithFlag = templatePositions.filter(
					(positionModel) => positionModel.type === 'photo' && positionModel.flag,
				) as TemplatePhotoPosition[];

				photosToPlaceDynamically -= photoPositionsWithFlag.length;
			} else if (!templateModel.dynamicPhotoCount
				// This second condition (themeHasDynamicLayout) should be removed after the transition period (when dynamicPhotoCount property is changed in database)
				// This is to support legacy versions of the Creator with current theme settings
				|| ThemeStateModule.themeHasDynamicLayout
			) {
				// This is a page with a dynamic template, and it has not been assigned
				// a specific number of photos, so we add it to the array
				dynamicPages.push({
					index: i,
					pageModel,
					photoCount: 0,
				});
			}
		});

		let maxPhotosPerPage = 5;
		if (ProductStateModule.productSettings.autoFillPageDensityId) {
			const offeringModel = ProductStateModule.getOffering;
			if (offeringModel) {
				const pageOption = ProductState.getDynamicFillPageOption(
					ProductStateModule.getProductSettings.autoFillPageDensityId,
					ProductStateModule.getPhotosSelected.length,
					offeringModel,
				);
				if (pageOption) {
					maxPhotosPerPage = Math.min(
						Math.round(pageOption.averageNumberOfPhotosPerPage * 2),
						5,
					);
				}
			}
		}

		// We split the number of photos we need to place dynamically
		// into the number of pages with a dynamic template
		const parts = splitNumber(
			photosToPlaceDynamically,
			dynamicPages.length,
			maxPhotosPerPage,
		);

		parts.forEach((part, i) => {
			const dynamicPage = dynamicPages[i];
			if (dynamicPage) {
				dynamicPage.photoCount = part;
			}
		});

		/* eslint-disable no-await-in-loop */
		// eslint-disable-next-line no-restricted-syntax
		for (const [i, pageModel] of this.pageModels.entries()) {
			const dynamicPage = dynamicPages.find(
				(m) => m.index === i,
			);
			await this.fillPage(
				pageModel,
				this.photoModels.slice(0),
				dynamicPage?.photoCount,
			).catch(
				// Swallow error: no action required
				() => undefined,
			).then(() => new Promise((resolve) => {
				window.setTimeout(
					() => {
						resolve(undefined);
					},
					10,
				);
			}));
		}

		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			throw new Error('Could not find required product model');
		}

		return undefined;
	}

	private fillPage(
		pageModel: PI.PageModel,
		photoCollection: PI.PhotoModel[],
		photoCount?: number,
	): Promise<void> {
		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			return Promise.reject(
				new Error('Could not find required product model'),
			);
		}

		const offeringModel = ProductStateModule.getOffering;
		if (!offeringModel) {
			return Promise.reject(
				new Error('Could not find required offering model'),
			);
		}

		const productSettings = ProductStateModule.getProductSettings;

		const pageType = this.getPageType(
			productModel.group,
			pageModel,
		);
		if (pageType === 'frontCover'
			|| pageType === 'backCover'
		) {
			return this.fillPositions(
				pageModel,
				photoCollection,
				photoCount,
				false,
				true,
			);
		}

		if (
			(offeringModel.maxpages === 1)
			|| OfferingGroups(
				productModel.group,
				['Cards'],
			)
		) {
			const {
				templateSet,
				photoModels,
			} = ProductStateModule.getPageTemplateSet(
				pageModel,
				pageModel.templateSetId || undefined,
				photoCollection,
			);

			// Fill template set
			if (templateSet && photoModels) {
				ProductStateModule.changePage({
					id: pageModel.id,
					templateSetId: templateSet.id,
				});

				return this.fillTemplateSet(
					pageModel,
					templateSet,
					photoModels,
				);
			}

			return Promise.reject(new Error('Could not find carousel'));
		}

		return this.fillPositions(
			pageModel,
			photoCollection,
			photoCount,
			productSettings.autoFillCaptions,
		);
	}

	private async fillPositions(
		pageModel: PI.PageModel,
		photoCollection: PI.PhotoModel[],
		photoCount?: number,
		fillTitles?: boolean,
		coverPage?: boolean,
	): Promise<void> {
		let sortedPhotoCollection = photoCollection;
		if (coverPage) {
			// Get the positions on this cover page to check if this is a collage
			const photoPositions = ProductStateModule.getPageTemplatePositionsAvailable(pageModel).filter(
				(positionModel) => positionModel.type === 'photo',
			) as TemplatePhotoPosition[];
			if (photoPositions.length > 1
				|| (
					photoPositions.length === 1
					&& !photoPositions[0].flag
				)
			) {
				sortedPhotoCollection = _.sortBy(
					sortedPhotoCollection,
					(photoModel) => {
						// Sort photos by size, biggest resolution first
						const pixels = photoModel.full_width * photoModel.full_height;

						if (photoModel.fcx && photoModel.fcy && photoModel.fcw && photoModel.fch) {
						// Get photos with faces in front of sorting by giving them a negative number
							return -pixels;
						}

						return 1 / pixels;
					},
				);
			}
		}

		if (pageModel.template) {
			const templateModel = ThemeDataModule.getTemplate(
				pageModel.template,
			);
			if (templateModel?.dynamic) {
				const sliceCount = photoCount
					?? templateModel.dynamicPhotoCount
					?? Math.ceil(Math.random() * 4);
				const { templateSet, photoModels } = ProductStateModule.getPageTemplateSet(
					pageModel,
					undefined,
					[
						...sortedPhotoCollection.slice(
							0,
							sliceCount,
						),
					],
				);
				if (templateSet && photoModels) {
					ProductStateModule.changePage({
						id: pageModel.id,
						templateSetId: templateSet.id,
					});

					return this.fillTemplateSet(
						pageModel,
						templateSet,
						photoModels,
					).then(() => {
						sortedPhotoCollection.splice(
							0,
							sliceCount,
						);
					});
				}
			}
		}

		/* eslint-disable no-await-in-loop */
		// eslint-disable-next-line no-restricted-syntax
		for (const currentPhotoModel of sortedPhotoCollection) {
			// Place photo
			await this.placePhoto(
				pageModel,
				currentPhotoModel,
				{
					fillTitles,
				},
			).catch(
				// Swallow error: no action required
				() => undefined,
			);
		}
		/* eslint-enable no-await-in-loop */

		return Promise.resolve();
	}

	/**
	 * Fill a set of template positions on a project's page with the provided photo models
	 *
	 * @param pageModel The page model to add the photos to
	 * @param templateSet The set of template positions
	 * @param photoModels The photo models to add to the page
	 * @param options Configuration options to use
	 * @param options.fit Use the "fit" method (instead of "fill") to make sure photos will be fully visible (no cropping)
	 */
	public async fillTemplateSet(
		pageModel: PI.PageModel,
		templateSet: TemplateSet,
		photoModels: PI.PhotoModel[],
		options?: {
			fit?: boolean,
		},
	): Promise<void> {
		const photoCollection: PI.PhotoModel[] = [];
		photoModels.forEach(
			(photoModel) => {
			// Create shallow copy of model
				photoCollection.push(
					JSON.parse(JSON.stringify(photoModel)),
				);
			},
		);

		/* eslint-disable no-await-in-loop */
		// eslint-disable-next-line no-restricted-syntax
		for (const currentPhotoModel of photoCollection) {
			// Place photo
			await this.placePhoto(
				pageModel,
				currentPhotoModel,
				{
					templateSet,
					fit: options?.fit,
				},
			).catch(
				// Swallow error: no action required
				() => undefined,
			);
		}
		/* eslint-enable no-await-in-loop */

		return Promise.resolve();
	}

	public fillProductAttributes() {
		ProductStateModule.getEditablePages.forEach(
			(pageModel) => {
				if (pageModel.template) {
					const templateModel = ThemeDataModule.getTemplate(
						pageModel.template,
					);
					if (!templateModel?.dynamic) {
						const templatePositions = ProductStateModule.getPageTemplatePositionsAvailable(pageModel);
						const textPositions = templatePositions.filter(
							(positionModel) => positionModel.type === 'text',
						) as TemplateTextPosition[];
						textPositions.forEach((positionModel) => {
							if (positionModel.productattribute) {
								const attributeModel = ProductStateModule.getAttribute(positionModel.productattribute);
								if (attributeModel && attributeModel.value) {
									TemplatePosition
										.fillTextPosition(
											pageModel,
											positionModel,
											attributeModel.value,
										)
										.catch(() => {
											// Swallow error: no action required
										});
								}
							}
						});
					}
				}
			},
		);
	}

	private placePhoto(
		pageModel: PI.PageModel,
		photoData: PI.PhotoModel,
		options: {
			fillTitles?: boolean;
			templateSet?: TemplateSet;
			fit?: boolean;
		},
	): Promise<void> {
		// Update progress model
		if (progressDialog) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const dialogProgressComponent = progressDialog.api.bodyComponent()!;
			dialogProgressComponent.value = dialogProgressComponent.total - this.photoModels.length;
		}

		let available: TemplatePhotoPosition[] = [];
		if (options.templateSet) {
			const objectModels = ProductStateModule.getPageObjects(
				pageModel,
			);
			available = options.templateSet.positions.filter(
				(position) => position.type == 'photo' && TemplatePosition.getAvailability(
					position,
					objectModels,
				),
			) as TemplatePhotoPosition[];
		} else {
			available = ProductStateModule.getPageTemplatePositionsAvailable(pageModel).filter(
				(position) => position.type == 'photo',
			) as TemplatePhotoPosition[];
		}

		if (available.length === 0) {
			// There are no available positions left
			this.pageModels = this.pageModels.filter(
				(p) => p.id != pageModel.id,
			);

			throw new Error(
				'no more available template positions',
			);
		}

		if (available.length === 0) {
			return Promise.resolve();
		}

		/**
		 * Sort photos to find best fit with stock item
		 */

		available.sort(
			(positionModel) => {
				if (!positionModel) {
					throw new Error('Missing required position model');
				}

				let w = positionModel.width;
				let h = positionModel.height;

				if (
					positionModel.borderwidth
					&& positionModel.borderwidth > 0
				) {
					w -= 2 * positionModel.borderwidth;
					h -= 2 * positionModel.borderwidth;
				}

				const widthScale = photoData.full_width / w;
				const heightScale = photoData.full_height / h;
				const minscale = Math.min(
					widthScale,
					heightScale,
				);

				const deltaWidth = Math.abs(w - (photoData.full_width / minscale));
				const deltaHeight = Math.abs(h - (photoData.full_height / minscale));

				return (deltaWidth + deltaHeight) * (minscale ** 2);
			},
		);

		const positionModel = available[0];
		return TemplatePosition
			.fillPhotoPosition(
				pageModel,
				positionModel,
				photoData,
				{
					fillTitles: options.fillTitles,
					fit: options.fit,
				},
			)
			.then(() => {
				// Flag photo so that it won't be added in a different place
				if (positionModel.flag) {
					this.photoModels = _.reject(
						this.photoModels,
						(m) => m.id == photoData.id,
					);
				}

				positionModel.available = false;
			});
	}
}

export default new Template();
