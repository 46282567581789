import { render, staticRenderFns } from "./page-flat.vue?vue&type=template&id=6eee7688&scoped=true&"
import script from "./page-flat.ts?vue&type=script&lang=js&"
export * from "./page-flat.ts?vue&type=script&lang=js&"
import style0 from "./page-flat.vue?vue&type=style&index=0&id=6eee7688&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eee7688",
  null
  
)

export default component.exports