import './defines';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogLoader',
})
export default class DialogLoader extends Vue.extend(Template) {
	@Prop({
		default: undefined,
		type: String,
	})
	public readonly content?: string;

	@Prop({
		default: '#ccc',
		type: String,
	})
	public readonly textColor?: CSSStyleDeclaration['color'];
}
