export default function getScaledFilename(
	src: string,
	scaling: number,
): string {
	const dot = src.lastIndexOf('.');

	return `${src.substring(
		0,
		dot,
	)}_s${scaling}${src.substring(dot)}`;
}
