import './defines';
import { ConfigModule } from 'store';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'BleedMarginView',
})
export default class BleedMarginView extends Vue.extend(Template) {
	protected imageUrl: string | null = null;

	protected beforeCreate() {
		const labelDirectory = (
			ConfigModule.partnerID === 2
				? 'hema/'
				: ''
		);

		import(/* webpackMode: "eager" */ `../../../img/bleed-margin/${labelDirectory}preview.jpg`)
			.then((imageUrl) => {
				this.imageUrl = imageUrl.default;
			});
	}
}
