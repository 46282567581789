const originalAddEventListener = EventTarget.prototype.addEventListener;
const originalRemoveEventListener = EventTarget.prototype.removeEventListener;
const doubleTapEventHandlers: Map<HTMLElement, DoubleTapEventHandler> = new Map();
const doubleTapInterval = 300;

class DoubleTapEventHandler {
	public get listenersCount(): number {
		return this.listeners.length;
	}

	private element: HTMLElement;

	private lastTapTime?: number;

	private listeners: TouchEventListener[] = [];

	private tapListeners?: TapListeners;

	constructor(element: HTMLElement) {
		this.element = element;
	}

	public add(listener: (event: TouchEvent) => void): void {
		if (!this.tapListeners) {
			let wasMoreThanOneFinger = false;
			this.tapListeners = {
				end: (event: TouchEvent) => {
					const now = Date.now();

					if (
						this.lastTapTime
						&& now - this.lastTapTime < doubleTapInterval
						&& !wasMoreThanOneFinger
					) {
						// eslint-disable-next-line no-restricted-syntax
						for (const currentListener of this.listeners) {
							currentListener(event);
						}
					}

					this.lastTapTime = now;
				},
				start: (event: TouchEvent) => {
					wasMoreThanOneFinger = event.touches.length > 1;
				},
			};
			this.element.addEventListener(
				'touchstart',
				this.tapListeners.start,
			);
			this.element.addEventListener(
				'touchend',
				this.tapListeners.end,
			);
		}

		this.listeners.push(listener);
	}

	public remove(listener: TouchEventListener): void {
		const index = this.listeners.indexOf(listener);

		if (index !== -1) {
			this.listeners.splice(
				index,
				1,
			);
		}

		if (
			this.listeners.length === 0
			&& this.tapListeners
		) {
			this.element.removeEventListener(
				'touchend',
				this.tapListeners.end,
			);
			this.element.removeEventListener(
				'touchend',
				this.tapListeners.start,
			);
			this.tapListeners = undefined;
		}
	}
}

export default {
	setup(): void {
		if (EventTarget.prototype.addEventListener === originalAddEventListener) {
			EventTarget.prototype.addEventListener = function addEventListener(
				type: string,
				listener: EventListenerOrEventListenerObject,
				options?: boolean | AddEventListenerOptions,
			) {
				if (type === 'dblclick') {
					let eventHandlerFound = doubleTapEventHandlers.get(this as HTMLElement);

					if (!eventHandlerFound) {
						eventHandlerFound = new DoubleTapEventHandler(this as HTMLElement);
						doubleTapEventHandlers.set(
							this as HTMLElement,
							eventHandlerFound,
						);
					}

					eventHandlerFound.add(listener as TouchEventListener);
				}

				originalAddEventListener.apply(
					this,
					[
						type,
						listener,
						options,
					],
				);
			};

			if (EventTarget.prototype.removeEventListener === originalRemoveEventListener) {
				EventTarget.prototype.removeEventListener = function removeEventListener(
					type: string,
					listener: EventListenerOrEventListenerObject,
					options?: boolean | AddEventListenerOptions,
				) {
					if (type === 'dblclick') {
						const eventHandlerFound = doubleTapEventHandlers.get(this as HTMLElement);

						if (eventHandlerFound) {
							eventHandlerFound.remove(listener as TouchEventListener);

							if (eventHandlerFound.listenersCount === 0) {
								doubleTapEventHandlers.delete(this as HTMLElement);
							}
						}
					}

					originalRemoveEventListener.apply(
						this,
						[
							type,
							listener,
							options,
						],
					);
				};
			}
		}
	},

	reset(): void {
		if (EventTarget.prototype.addEventListener !== originalAddEventListener) {
			EventTarget.prototype.addEventListener = originalAddEventListener;
		}

		if (EventTarget.prototype.removeEventListener !== originalRemoveEventListener) {
			EventTarget.prototype.removeEventListener = originalRemoveEventListener;
		}
	},
};
export { doubleTapInterval };
