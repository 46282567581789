import { type Vue } from 'vue/types/vue';
import { createDecorator } from 'vue-class-component';

// eslint-disable-next-line import/prefer-default-export
export function Public(name?: string) {
	return (target: Vue, key: string) => {
		createDecorator((options, memberKey) => {
			if (
				options.methods
				&& Object.prototype.hasOwnProperty.call(
					options.methods,
					name || memberKey,
				)
			) {
				options.methods[name || memberKey].public = true;
			}
		})(
			target,
			key,
		);
	};
}
