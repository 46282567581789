import filterText from './filter-text';

export interface breakModel {
	phrase: string;
	subset: string[];
	bold: boolean;
	italic: boolean;
	maxPxLength: number;
	maxPxHeight: number;
	fontface: string;
	pointsize: number;
	resize: {
		up: number;
		down: number;
	};
}

export interface testModel {
	pointsize: number;
	valid: boolean;
	lines: string[];
	linesWithHyphen: string[];
}

export interface BreakTextResult {
	pointsize: number;
	phraseArray: string[];
	textheight: number;
	text_formatted: string;
	text_formatted_for_canvas: string;
}

function getBestFit(tested: testModel[]): testModel | undefined {
	const valids = tested.filter(
		(obj) => obj.valid === true,
	);

	if (valids.length) {
		const sortedValidSizes = valids.sort(
			(a, b) => a.pointsize - b.pointsize,
		);
		const objLargest = sortedValidSizes[sortedValidSizes.length - 1];

		return tested.find(
			(obj) => obj.pointsize === objLargest?.pointsize,
		);
	}
	const sortedSizes = tested.sort(
		(a, b) => a.pointsize - b.pointsize,
	);
	const objSmallest = sortedSizes[0];

	return tested.find(
		(obj) => obj.pointsize === objSmallest?.pointsize,
	);
}

function testPointsize(
	pointsize: number,
	tempdiv: HTMLDivElement,
	lines: string[],
	props: breakModel,
	tested: testModel[],
): number {
	const arrLines: string[] = [];
	const arrLinesWithHyphen: string[] = [];
	let y = 0;

	// Change pointsize in temporary div
	tempdiv.style.fontSize = `${pointsize * 31496 / 15120}px`;

	lines.forEach(
		(line) => {
			if (line.length > 0) {
				const arrWords = line.split(' ');
				const arrWordsWithHyphen = [
					...arrWords,
				];
				let lastPhrase = '';
				let lastPhraseWithHyphen = '';

				// check for words that are too big to be displayed on one line, and break these words with the - character
				for (let iterator = 0; iterator < arrWords.length; iterator += 1) {
					const word = arrWords[iterator];

					// Add word to span element
					const tempspan = document.createElement('span');
					// Make sure word is not broken at special characters
					tempspan.style.whiteSpace = 'nowrap';
					tempspan.style.wordBreak = 'keep-all';
					tempspan.style.display = 'inline-block';

					// Add word to temporary span element
					tempspan.textContent += word;

					// Add the span element to the temporary div
					tempdiv.appendChild(tempspan);

					// Check if the temporary div has exceeded the maximum object width
					if (tempspan.clientWidth > props.maxPxLength) {
						// Remove the full word from the string, as we're going to break it up
						if (tempspan.textContent) {
							tempspan.textContent = tempspan.textContent.substring(
								0,
								tempspan.textContent.length - word.length,
							);
						}

						// The object is now too wide, so we have to break the sentence here
						for (let char = 0; char < word.length; char += 1) {
							// Add word until character to temporary span to measure the width until this character
							tempspan.textContent += word.substring(
								char,
								char + 1,
							);

							/**
							 * Check if we have exceeded the maximum width with this character
							 * but only if we are not at the first character of the word
							 */
							if (
								tempspan.clientWidth > props.maxPxLength
								&& char > 0
							) {
								// Break the word at this character
								arrWords[iterator] = `${word.substring(
									0,
									char,
								)}`;
								arrWordsWithHyphen[iterator] = `${word.substring(
									0,
									char,
								)}-`;

								// Move rest of word to new line
								arrWords.splice(
									iterator + 1,
									0,
									word.substring(char),
								);
								arrWordsWithHyphen.splice(
									iterator + 1,
									0,
									word.substring(char),
								);
								break;
							}
						}
					}
				}

				// Reset the temporary div
				tempdiv.textContent = '';

				// Find line break positions
				for (let iWord = 0; iWord < arrWords.length; iWord += 1) {
					const word = arrWords[iWord];
					const wordWithHyphen = arrWordsWithHyphen[iWord];

					const tempspan = document.createElement('span');

					// Make sure word is not broken at special characters
					tempspan.style.whiteSpace = 'nowrap';
					tempspan.style.wordBreak = 'keep-all';
					tempspan.style.display = 'inline-block';

					if (!word) {
						// If there is no word, just add a space
						tempspan.textContent = ' ';
					} else {
						// Add word to span element
						tempspan.textContent = word;

						// Add the span element to the temporary div
						tempdiv.appendChild(tempspan);
					}

					if (iWord === 0) {
						// This is the first word in the text phrase, set y to the position of this word
						y = tempspan.offsetTop;
					}

					// Check if this word is on a new line
					if (tempspan.offsetTop > y) {
						// This word is on a new line, so push the phrase until this word to the phrase array
						arrLines.push(lastPhrase.substring(
							0,
							lastPhrase.length - 1,
						));
						arrLinesWithHyphen.push(lastPhraseWithHyphen.substring(
							0,
							lastPhraseWithHyphen.length - 1,
						));

						// Reset the lastPhrase variable to the current word
						lastPhrase = word;
						lastPhraseWithHyphen = wordWithHyphen;

						// Reset y to the position of the current word
						y = tempspan.offsetTop;
					} else {
						// This word is not on a new line, so add it to the lastPhrase
						lastPhrase += word;
						lastPhraseWithHyphen += wordWithHyphen;
					}

					// Add a space to lastPhrase, so we can add the next word
					lastPhrase += ' ';
					lastPhraseWithHyphen += ' ';

					// Add the space to the temporary div
					tempdiv.textContent += ' ';

					// If this is the last word, push lastPhrase as a line
					if (iWord === arrWords.length - 1) {
						arrLines.push(lastPhrase.substring(
							0,
							lastPhrase.length - 1,
						));
						arrLinesWithHyphen.push(lastPhraseWithHyphen.substring(
							0,
							lastPhraseWithHyphen.length - 1,
						));
					}
				}

				// Reset the temporary div
				tempdiv.textContent = '';
			} else {
				// This is an empty line, so push a space to the arrLines
				arrLines.push(' ');
				arrLinesWithHyphen.push(' ');
			}
		},
	);

	// Create temporary div where we can calculate the space the text will occupy
	const temp = document.createElement('div');
	temp.style.cssText = window.getComputedStyle(
		tempdiv,
		'',
	).cssText;
	document.body.appendChild(temp);

	// Add text lines to temporary div
	arrLines.forEach(
		(line, index) => {
			temp.textContent += line;
			if (index < arrLines.length - 1) {
				temp.textContent += '\r\n';
			}
		},
	);

	// Calculate width and height of text div
	const textwidth = temp.clientWidth;
	const textheight = arrLines.length * 1.25 * pointsize * 31496 / 15120;

	// Add this pointsize to the tested array, so we know we don't have to test it again
	tested.push({
		pointsize,
		valid: textheight < props.maxPxHeight,
		lines: arrLines,
		linesWithHyphen: arrLinesWithHyphen,
	});

	// If pointsize is set, calculate optimal pointsize
	if (
		props.resize.up
		|| props.resize.down
	) {
		// Check if text occupies more space than is available
		if (
			props.resize.down
			&& (
				textheight > props.maxPxHeight
				|| (
					arrLines.length == 1
					&& textwidth > props.maxPxLength
				)
			)
		) {
			// Check if text may be downscaled and hasn't reached the minimum size yet
			if (props.resize.down < pointsize) {
				// Use smaller font size
				pointsize -= 1;
			}
		} else if (
			props.resize.up
			&& (
				textwidth < 0.9 * props.maxPxLength
				|| textheight < 0.9 * props.maxPxHeight
			)
		) {
			// Check if text occupies less than 90% of available space
			// Check if text may be upscaled and hans't reached the maximum size yet
			if (props.resize.up > pointsize) {
				// Use larger font size
				pointsize += 1;
			}
		}
	}

	// Remove temporary div
	document.body.removeChild(temp);

	return pointsize;
}

export default function breakText(props: breakModel): BreakTextResult | false {
	let { pointsize } = props;
	let pxsize = pointsize * 31496 / 15120;
	const phrase = filterText(
		String(props.phrase),
		props.subset,
	); // Note: fixed bug where we need to convert number in text

	// Split the text string by hard line breaks
	const lines = phrase.split('\n');

	// Create an array where we store the result of all tested pointsizes
	const tested: testModel[] = [];

	// If there is no text, stop and return false value
	if (phrase.length === 0) {
		return false;
	}

	// Create the temporary div that will be used to find the line breaks in the text based on the "box" width
	const tempdiv = document.createElement('div');
	tempdiv.style.font = `${pxsize}px ${props.fontface}`;
	tempdiv.style.fontStyle = props.italic ? 'italic' : 'normal';
	tempdiv.style.fontWeight = props.bold ? 'bold' : 'normal';
	tempdiv.style.lineHeight = '125%';
	tempdiv.style.margin = '0';
	tempdiv.style.float = 'left';
	tempdiv.style.padding = '0';
	tempdiv.style.position = 'relative';
	tempdiv.style.textTransform = 'none';
	tempdiv.style.width = `${props.maxPxLength}px`;
	document.body.appendChild(tempdiv);

	// Try different pointsizes
	while (tested.map(
		(test) => test.pointsize,
	).indexOf(pointsize) < 0) {
		pointsize = testPointsize(
			pointsize,
			tempdiv,
			lines,
			props,
			tested,
		);
	}

	// Get the best fit for the box
	const bestFit = getBestFit(tested);

	if (!bestFit) {
		// Remove temporary div
		tempdiv.textContent = '';
		document.body.removeChild(tempdiv);

		throw new Error('Could not find a best fit');
	}

	/**
	 * Reset the font size to the best fit pointsize,
	 * this is needed since the `testPointsize()` function
	 * changes the font size in the temporary div which
	 * is used to calculate the `textheight`
	 */
	pxsize = bestFit.pointsize * 31496 / 15120;
	tempdiv.style.font = `${pxsize}px ${props.fontface}`;
	tempdiv.style.lineHeight = '125%';

	// Clean temporary div
	tempdiv.textContent = '';

	// Build formatted text string
	let textFormatted = '';
	let textFormattedForCanvas = '';

	// If font size is at minimum value and text still overflows, shorten text
	if (
		props.resize.down
		&& bestFit.pointsize == props.resize.down
	) {
		// Find the line at which the text overflows the box
		bestFit.lines.find(
			(line, index) => {
				tempdiv.textContent += line;

				if (tempdiv.clientHeight > props.maxPxHeight) {
					// Text overflows box now, so shorten text at this point
					textFormatted = textFormatted.substring(
						0,
						textFormatted.length - 3,
					);
					textFormatted += '...';
					textFormattedForCanvas = textFormattedForCanvas.substring(
						0,
						textFormattedForCanvas.length - 3,
					);
					textFormattedForCanvas += '...';

					return true;
				}

				// Text still fits, so add line to the formatted text and continue
				textFormatted += line;
				textFormattedForCanvas += bestFit.linesWithHyphen[index];

				// If there are more lines left, add a line break
				if (index < bestFit.lines.length - 1) {
					textFormatted += '\n';
					textFormattedForCanvas += '\n';
					tempdiv.textContent += '\r\n';
				}

				return false;
			},
		);
	} else {
		tempdiv.style.whiteSpace = 'pre-wrap';
		// Convert the text lines to a string for the text_formatted property
		bestFit.lines.forEach(
			(line, index) => {
				textFormatted += line;
				textFormattedForCanvas += bestFit.linesWithHyphen[index];
				tempdiv.textContent += line;

				if (index < bestFit.lines.length - 1) {
					textFormatted += '\n';
					textFormattedForCanvas += '\n';
					tempdiv.textContent += '\r\n';
				}
			},
		);
	}

	// Get text height from the temporary div
	const textheight = tempdiv.clientHeight;

	// Remove temporary div
	document.body.removeChild(tempdiv);

	// Return properties
	return {
		pointsize: bestFit.pointsize,
		phraseArray: bestFit.lines,
		textheight,
		text_formatted: textFormatted,
		text_formatted_for_canvas: textFormattedForCanvas,
	};
}
