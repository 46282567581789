// eslint-disable-next-line import/prefer-default-export
export class ServiceEvent<PayloadType = any> implements _ServiceEvent {
	private _defaultPrevented = false;

	public get defaultPrevented(): boolean {
		return this._defaultPrevented;
	}

	public type: string;

	public payload!: PayloadType;

	constructor(
		options: {
			type: string;
			payload?: PayloadType;
		},
	) {
		this.type = options.type;

		if (typeof options.payload !== 'undefined') {
			this.payload = options.payload;
		}
	}

	public preventDefault(): void {
		this._defaultPrevented = true;
	}
}
