export function callSafe(fn: (...args: any[]) => any): void {
	try {
		fn();
	} catch {
		// Do nothing
	}
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export function debounce<
	// eslint-disable-next-line @typescript-eslint/ban-types
	F extends Function,
	A extends ArgumentTypes<F>,
>(
	fn: F,
	wait: number,
): (...args: A) => void {
	let timeout: NodeJS.Timeout | undefined;

	return (...args: A): void => {
		if (!timeout) {
			fn(...args);
			timeout = setTimeout(
				() => {
					timeout = undefined;
				},
				wait,
			);
			return;
		}

		clearTimeout(timeout);
		timeout = setTimeout(
			() => {
				fn(...args);
				timeout = undefined;
			},
			wait,
		);
	};
}

export function requestAnimationFrameDebounce<
	// eslint-disable-next-line @typescript-eslint/ban-types
	F extends Function,
	A extends ArgumentTypes<F>,
>(
	fn: F,
): (...args: A) => void {
	let raf: number | undefined;

	return (...args: A): void => {
		if (raf) {
			cancelAnimationFrame(raf);
		}

		raf = requestAnimationFrame(() => {
			fn(...args);
			raf = undefined;
		});
	};
}
