import './defines';
import EventBus from 'components/event-bus';
import auth from 'controllers/auth';
import connector, { ConnectorScopeCategory } from 'controllers/connector';
import {
	ChannelModel,
	CloudSelectorSource,
} from 'interfaces/app';
import * as PI from 'interfaces/project';
import store, {
	ChannelsModule,
	UserModule,
} from 'store';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import FacebookButtonView from '../facebook-button';
import GoogleButtonView from '../google-button';
import InstagramButtonView from '../instagram-button';
import MicrosoftButtonView from '../microsoft-button';
import CloudAlbumSelectorView from '../cloud-album-selector';
import CloudPhotoSelectorView from '../cloud-photo-selector';
import Template from './template.vue';

@Component({
	components: {
		CloudAlbumSelectorView,
		CloudPhotoSelectorView,
		FacebookButtonView,
		GoogleButtonView,
		InstagramButtonView,
		MicrosoftButtonView,
	},
})
export default class CloudSelectorView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates if the user can select multiple photos.',
		type: Boolean,
	})
	public readonly importMode!: boolean;

	@Prop({
		required: true,
		schema: 'CloudSelectorSource',
		type: String,
	})
	public readonly source!: CloudSelectorSource;

	@Prop({
		default: false,
		description: 'Indicates if this is run inside the bottom toolbar.',
		type: Boolean,
	})
	public readonly toolbarMode!: boolean;

	protected hasRequiredScopes = false;

	protected isConnected = false;

	protected loaded = false;

	private get channelModel(): ChannelModel | undefined {
		return ChannelsModule.getById(this.source);
	}

	private get channelModelName(): ChannelModel['name'] {
		if (this.channelModel) {
			return this.channelModel.name;
		}

		return '';
	}

	protected get connectIcon(): string[] | string {
		if (!this.channelModel) {
			return [''];
		}

		return this.channelModel.id == 'app'
			? store.state.config['auth.loginIcon']
			: this.channelModel.icon;
	}

	protected get profileName(): string | undefined {
		if (this.channelModel?.profile) {
			return this.channelModel.profile.display_name;
		}

		return '';
	}

	protected get profilePicture(): string | undefined {
		if (this.channelModel?.profile) {
			return this.channelModel.profile.picture;
		}

		return undefined;
	}

	private get scope(): ConnectorScopeCategory[] {
		const scope: ConnectorScopeCategory[] = [];

		if (this.channelModel) {
			if (this.channelModel.folders) {
				scope.push('folders');
			}
			if (
				this.channelModel.albums
				|| this.channelModel.photos
			) {
				scope.push('photos');
			}
		}

		return scope;
	}

	protected created(): void {
		this.checkConnected();
	}

	protected mounted(): void {
		EventBus.$on(
			'auth:login',
			this.checkConnected,
		);
		EventBus.$on(
			'auth.logout',
			this.checkConnected,
		);
	}

	protected beforeDestroy(): void {
		EventBus.$off(
			'auth:login',
			this.checkConnected,
		);
		EventBus.$off(
			'auth.logout',
			this.checkConnected,
		);
	}

	@Watch('channelModel')
	protected onChannelModelChange(): void {
		this.loaded = false;
		this.isConnected = false;
		this.checkConnected();
	}

	private checkConnected(): void {
		if (this.channelModel) {
			const channelModelId = this.channelModel.id;

			if (channelModelId == 'app') {
				this.loaded = true;

				if (!UserModule.temporary) {
					this.isConnected = true;
					this.hasRequiredScopes = true;
				}
			} else {
				this.loaded = false;
				connector.setup(channelModelId)
					.then(() => connector.refresh(
						channelModelId,
						{
							scopeCategories: ['basic'],
						},
					))
					.then(() => {
						this.isConnected = true;

						return connector.refresh(
							channelModelId,
							{
								scopeCategories: this.scope,
							},
						);
					})
					.then(() => {
						this.hasRequiredScopes = true;
					})
					.finally(() => {
						this.loaded = true;
					})
					.catch(() => {
						// Swallow error: no action required
					});
			}
		} else {
			this.loaded = false;
			this.isConnected = false;
			this.hasRequiredScopes = false;
		}
	}

	protected connect(): void {
		if (!this.channelModel) {
			throw new Error('Could not find required channelModel');
		}

		if (this.channelModel.id == 'app') {
			auth.showLogin({
				hasclose: true,
			});
		} else {
			const options = {
				display: 'popup',
				scopeCategories: this.scope,
			};

			connector
				.login(
					this.channelModel.id,
					options,
				)
				.finally(() => {
					this.checkConnected();
				})
				.catch(() => {
					// Swallow error: no action required
				});
		}
	}

	protected selectPhoto(photoModel: Partial<PI.PhotoModel>): void {
		this.$emit(
			'selectPhoto',
			photoModel,
		);
	}
}
