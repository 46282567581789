import './defines';
import PageObject from 'classes/pageobject';
import TooltipComponent from 'components/tooltip';
import {
	ColorPickerFromColors,
	EditorInteractiveInteractionPageObjectModel,
	EditorInteractiveInteractionTemplatePhotoModel,
	EditorInteractiveInteractionTemplateTextModel,
	EditorInteractivePageObjectCoordinates,
	EditorInteractivePageObjectFixResizeSide,
	EditorInteractivePageObjectInitialPosition,
	EditorInteractivePageObjectInitialSize,
	EditorInteractivePageObjectModel,
	EditorInteractivePageObjectModels,
	EditorInteractivePageObjectResizeSide,
	EditorModulePageTemplatePositions,
	EditorTextOptionsActiveMode,
	FontModel,
	PageObjectModelChangeResult,
} from 'interfaces/app';
import { OfferingModel } from 'interfaces/database';
import { PageModel } from 'interfaces/project';
import { COLOR_FULL } from 'settings/offerings';
import {
	FontModule,
	ProductStateModule,
} from 'store';
import resizeObjectText from 'store/modules/productstate/helpers/resize-text';
import {
	logoColors as logoColorsTools,
	mobile as mobileTools,
} from 'tools';
import filterText from 'tools/filter-text';
import {
	dom as domUtils,
	object as objectUtils,
} from 'utils';
import { doubleTapInterval } from 'utils/element-double-tap';
import EditorDrawView from 'views/editor-draw';
import EditorTextOptionsView from 'views/editor-text-options';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorInteractiveView',
	components: {
		EditorDrawView,
		EditorTextOptionsView,
	},
})
export default class EditorInteractiveView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			required: true,
			schema: 'EditorInteractivePageObjectModels',
			type: Array,
		},
	)
	public readonly pageObjects!: EditorInteractivePageObjectModels;

	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		description: 'Defines the canvas element that is going to be passed to the editor-text-options-view component',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		required: true,
		type: Number,
	})
	public readonly height!: number;

	@Prop({
		default: false,
		description: 'Indicates if the eye dropper is shown, in which case the pointer events are disabled',
		event: 'eye-dropper-shown-change',
		type: Boolean,
	})
	public readonly isEyeDropperShown?: boolean;

	@Prop({
		description: 'Indicates if the photo object is transformable (resizable and rotatable)',
		required: true,
		type: Boolean,
	})
	public readonly isPhotoObjectTransformable!: boolean;

	@Prop({
		description: 'Indicates if the active product supports multiple texts in the page',
		required: true,
		type: Boolean,
	})
	public readonly multipleTextSupport!: boolean;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		default: () => ([]),
		schema: 'EditorModulePageTemplatePositions',
		type: Array,
	})
	public readonly pageTemplatePositions!: EditorModulePageTemplatePositions;

	@Prop({
		default: () => ([]),
		schema: 'EditorModulePageTemplatePositions',
		type: Array,
	})
	public readonly pageTemplatePositionsAvailable!: EditorModulePageTemplatePositions;

	@Prop({
		description: 'Defines the type of product the user is working on',
		default: 'photo',
		type: String,
	})
	public readonly productType?: OfferingModel['type'];

	@Prop({
		required: true,
		type: Number,
	})
	public readonly scaling!: number;

	@Prop({
		default: undefined,
		description: 'Defines the border color of the selected object button',
		type: String,
	})
	public readonly selectedObjectBorderColor?: string;

	@Prop({
		default: false,
		description: 'Indicates if the bleed area should is shown',
		type: Boolean,
	})
	public readonly showBleed!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the text and icon color of the add photo button',
		type: String,
	})
	public readonly textColor?: string;

	@Prop({
		default: '#webapp',
		type: [HTMLElement, String],
	})
	public readonly textOptionsAnchor!: HTMLElement | string;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly width!: number;

	@Prop({
		default: 1,
		description: 'Defines the zoom level of the editor canvas',
		type: Number,
	})
	public readonly zoomLevel!: number;

	private get areTextColorsLimitedToChooseFrom(): boolean {
		if (this.offeringModel.color == COLOR_FULL) {
			return false;
		}

		const logoObject = (
			this.productType
			&& this.internalPageObjects.find((internalPageObject) => internalPageObject.type === 'photo')
		);

		if (logoObject) {
			return logoColorsTools.getUniqueUsedColors(logoObject).size >= this.offeringModel.color;
		}

		return false;
	}

	private get computedTextOptionsAnchor(): HTMLElement | null {
		if (typeof this.textOptionsAnchor === 'string') {
			return document.querySelector(this.textOptionsAnchor);
		}
		if (this.textOptionsAnchor) {
			return this.textOptionsAnchor;
		}

		return document.getElementById('webapp');
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			'editor-interactive-view-eye-dropper-shown': this.internalIsEyeDropperShown,
			'editor-interactive-view-text-object-selected': !!this.selectedTextObjectModel,
		};
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
		const height = this.height + (this.bleedMargin * 2);
		const width = this.width + (this.bleedMargin * 2);
		styles.height = `${Math.round(height * this.scaling)}px`;
		styles.width = `${Math.round(width * this.scaling)}px`;

		if (this.textColor) {
			styles['--editor-interactive-view-add-photo-text-color'] = this.textColor;
		}

		return styles;
	}

	protected get cropPhotoObjectCanvasHeight(): number {
		if (!this.selectedObjectForEdition) {
			return 0;
		}

		const heightRatio = this.selectedObjectForEdition.height / this.selectedObjectForEdition.cropheight;
		const objectRatio = this.selectedObjectForEdition.maxwidth / this.selectedObjectForEdition.maxheight;
		let cropPhotoObjectCanvasHeight = this.selectedObjectForEdition.maxheight * heightRatio;
		const cropPhotoObjectCanvasWidth = cropPhotoObjectCanvasHeight * objectRatio;

		if (this.selectedObjectForEdition.rotate) {
			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...this.selectedObjectForEdition,
				height: cropPhotoObjectCanvasHeight,
				width: cropPhotoObjectCanvasWidth,
			});
			cropPhotoObjectCanvasHeight = rotatedBoundingBox.height;
		}

		return cropPhotoObjectCanvasHeight;
	}

	protected get cropPhotoObjectCanvasStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (
			this.cropPhotoObjectVersion
			&& this.selectedObjectForEdition
			&& this.pageModel
		) {
			const bleedMargin = (
				this.showBleed
					? this.bleedMargin
					: 0
			);
			const originalObjectHeightRatio = this.selectedObjectForEdition.height / this.selectedObjectForEdition.cropheight;
			const originalObjectWidthRatio = this.selectedObjectForEdition.width / this.selectedObjectForEdition.cropwidth;

			let originalObjectCenterX = (this.selectedObjectForEdition.cropx + (this.selectedObjectForEdition.cropwidth / 2)) * originalObjectWidthRatio;
			let originalObjectCenterY = (this.selectedObjectForEdition.cropy + (this.selectedObjectForEdition.cropheight / 2)) * originalObjectHeightRatio;

			let left = (bleedMargin + this.selectedObjectForEdition.x_axis + (this.selectedObjectForEdition.width / 2)) - originalObjectCenterX;
			let top = (bleedMargin + this.selectedObjectForEdition.y_axis + (this.selectedObjectForEdition.height / 2)) - originalObjectCenterY;

			if (this.cropPhotoObjectVersion.rotate) {
				const newObjectModel: EditorInteractivePageObjectModel = {
					...this.selectedObjectForEdition,
				};
				const heightRatio = newObjectModel.height / newObjectModel.cropheight;
				const widthRatio = newObjectModel.width / newObjectModel.cropwidth;
				newObjectModel.height = newObjectModel.maxheight * heightRatio;
				newObjectModel.width = newObjectModel.maxwidth * widthRatio;

				const originalObjectRotatedBoundingBox = this.calculateRotatedBoundingBox({
					...this.selectedObjectForEdition,
					rotate: -this.selectedObjectForEdition.rotate,
				});
				const {
					x: rotatedCenterX,
					y: rotatedCenterY,
				} = this.rotatePointInRectangle(
					originalObjectCenterX,
					originalObjectCenterY,
					{
						...this.cropPhotoObjectVersion,
						height: newObjectModel.height,
						width: newObjectModel.width,
						x_axis: originalObjectRotatedBoundingBox.x,
						y_axis: originalObjectRotatedBoundingBox.y,
					},
				);

				originalObjectCenterX = rotatedCenterX;
				originalObjectCenterY = rotatedCenterY;
				left = (bleedMargin + this.selectedObjectForEdition.x_axis + (this.selectedObjectForEdition.width / 2)) - (originalObjectCenterX);
				top = (bleedMargin + this.selectedObjectForEdition.y_axis + (this.selectedObjectForEdition.height / 2)) - (originalObjectCenterY);
				styles['--editor-interactive-view-crop-preview-background-left'] = '50%';
				styles['--editor-interactive-view-crop-preview-background-rotate'] = `${this.cropPhotoObjectVersion.rotate}deg`;
				styles['--editor-interactive-view-crop-preview-background-top'] = '50%';
				styles['--editor-interactive-view-crop-preview-background-translate'] = '-50%, -50%';
			}

			styles['--editor-interactive-view-crop-preview-background-color'] = this.pageModel.bgcolor || '#FFFFFF';
			styles['--editor-interactive-view-crop-preview-background-height'] = `${Math.round(this.cropPhotoObjectVersion.height * this.scaling)}px`;
			styles['--editor-interactive-view-crop-preview-background-width'] = `${Math.round(this.cropPhotoObjectVersion.width * this.scaling)}px`;
			styles.left = `${left * this.scaling}px`;
			styles.top = `${top * this.scaling}px`;
		}

		return styles;
	}

	protected get cropPhotoObjectCanvasWidth(): number {
		if (!this.selectedObjectForEdition) {
			return 0;
		}

		const widthRatio = this.selectedObjectForEdition.width / this.selectedObjectForEdition.cropwidth;
		const objectRatio = this.selectedObjectForEdition.maxwidth / this.selectedObjectForEdition.maxheight;
		let cropPhotoObjectCanvasWidth = this.selectedObjectForEdition.maxwidth * widthRatio;
		const cropPhotoObjectCanvasHeight = cropPhotoObjectCanvasWidth / objectRatio;

		if (this.selectedObjectForEdition.rotate) {
			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...this.selectedObjectForEdition,
				height: cropPhotoObjectCanvasHeight,
				width: cropPhotoObjectCanvasWidth,
			});
			cropPhotoObjectCanvasWidth = rotatedBoundingBox.width;
		}

		return cropPhotoObjectCanvasWidth;
	}

	protected get cropPhotoObjectVersion(): EditorInteractivePageObjectModel | undefined {
		if (!this.selectedObjectForEdition) {
			return undefined;
		}

		const cropPhotoObjectVersion: EditorInteractivePageObjectModel = {
			...this.selectedObjectForEdition,
		};

		const heightRatio = cropPhotoObjectVersion.height / cropPhotoObjectVersion.cropheight;
		const widthRatio = cropPhotoObjectVersion.width / cropPhotoObjectVersion.cropwidth;

		cropPhotoObjectVersion.height = cropPhotoObjectVersion.maxheight * heightRatio;
		cropPhotoObjectVersion.width = cropPhotoObjectVersion.maxwidth * widthRatio;
		cropPhotoObjectVersion.cropheight = cropPhotoObjectVersion.maxheight;
		cropPhotoObjectVersion.cropwidth = cropPhotoObjectVersion.maxwidth;
		cropPhotoObjectVersion.cropx = 0;
		cropPhotoObjectVersion.cropy = 0;

		const rotatedBoundingBox = this.calculateRotatedBoundingBox({
			...cropPhotoObjectVersion,
			x_axis: 0,
			y_axis: 0,
		});

		cropPhotoObjectVersion.x_axis = -rotatedBoundingBox.x;
		cropPhotoObjectVersion.y_axis = -rotatedBoundingBox.y;

		return cropPhotoObjectVersion;
	}

	private get fontModels(): FontModel[] {
		return FontModule.getPrintable;
	}

	protected get interactionObjectModels(): EditorInteractiveInteractionPageObjectModel[] {
		return this.interactionPhotoObjectModels
			.concat(this.interactionTextObjectModels)
			.sort((a, b) => a.object.z_axis - b.object.z_axis);
	}

	protected get interactionObjectModelClasses(): (objectModel: EditorInteractivePageObjectModel) => Record<string, boolean> {
		return (objectModel): Record<string, boolean> => ({
			'editor-interactive-view-edit-object-hover': this.isObjectHovered(objectModel) && !this.isObjectCroppingMove(objectModel),
			'editor-interactive-view-edit-object-photo': this.isObjectPhotoObject(objectModel),
			'editor-interactive-view-edit-object-selected': this.isObjectSelected(objectModel) && !this.isObjectCroppingMove(objectModel),
			'editor-interactive-view-edit-object-selected-for-edition': this.isObjectSelectedForEdition(objectModel) && !this.isObjectCroppingMove(objectModel),
			'editor-interactive-view-edit-object-text': this.isObjectTextObject(objectModel),
			'editor-interactive-view-edit-object-text-movable': (
				objectModel.type === 'text'
				&& !objectModel._selected
			),
		});
	}

	protected get interactionObjectModelStyles(): (interactionPageObjectModel: EditorInteractiveInteractionPageObjectModel) => Partial<CSSStyleDeclaration> & Record<string, string> {
		return ({ object }): Partial<CSSStyleDeclaration> & Record<string, string> => {
			const bleedMargin = (
				this.showBleed
					? this.bleedMargin
					: 0
			);
			const borderWidth = object.borderwidth || 0;

			const left = Math.round((bleedMargin + object.x_axis - borderWidth) * this.scaling);
			const top = Math.round((bleedMargin + object.y_axis - borderWidth) * this.scaling);
			let {
				height,
				width,
			} = object;

			if (borderWidth) {
				height += borderWidth * 2;
				width += borderWidth * 2;
			}

			height = Math.round(height * this.scaling);
			width = Math.round(width * this.scaling);

			const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {
				height: `${height}px`,
				left: `${left}px`,
				top: `${top}px`,
				transform: `rotate(${object.rotate}deg)`,
				width: `${width}px`,
			};

			if (
				this.$el
				&& (
					object._selectedForEdition
					|| object._hovered
					|| (
						object.type === 'text'
						&& object._selected
					)
				)
			) {
				const computedStyles = getComputedStyle(this.$el);
				const cssBorderWidth = computedStyles.getPropertyValue('--editor-interactive-view-edit-object-border-width');

				if (cssBorderWidth) {
					const cssBorderWidthValue = parseInt(
						cssBorderWidth,
						10,
					);
					styles.left = `${left - cssBorderWidthValue}px`;
					styles.top = `${top - cssBorderWidthValue}px`;
				}
			}

			if (this.selectedObjectBorderColor) {
				styles['--editor-interactive-view-edit-object-border-color'] = this.selectedObjectBorderColor;
			}
			if (this.internalIsEyeDropperShown) {
				styles.pointerEvents = 'none';
			}

			return styles;
		};
	}

	protected get interactionPhotoObjectModels(): EditorInteractiveInteractionPageObjectModel[] {
		return this.internalPageObjects
			.filter((pageObject) => (
				pageObject.type === 'photo'
				&& pageObject.editable
			))
			.sort((a, b) => a.z_axis - b.z_axis)
			.map((pageObject) => ({
				objectVars: PageObject.calculatePosition(
					{
						x_axis: pageObject.x_axis,
						y_axis: pageObject.y_axis,
						width: pageObject.width,
						height: pageObject.height,
						borderwidth: pageObject.borderwidth,
						bordercolor: pageObject.bordercolor,
						cropx: pageObject.cropx,
						cropy: pageObject.cropy,
						cropwidth: pageObject.cropwidth,
						cropheight: pageObject.cropheight,
						angle: pageObject.rotate,
						flop: Boolean(pageObject.flop),
						flip: Boolean(pageObject.flip),
						type: pageObject.type,
						maxwidth: pageObject.maxwidth,
						photoid: pageObject.photoid || undefined,
					},
					this.scaling,
					null,
					this.bleedMargin,
				),
				object: pageObject,
			}));
	}

	protected get interactionTemplatePhotoTextModels(): (EditorInteractiveInteractionTemplatePhotoModel | EditorInteractiveInteractionTemplateTextModel)[] {
		return this.pageTemplatePositionsAvailable
			.reduce(
				(interactionTemplatePhotoTextModels, pageTemplatePosition) => {
					interactionTemplatePhotoTextModels.push({
						objectVars: PageObject.calculatePosition(
							{
								x_axis: pageTemplatePosition.x,
								y_axis: pageTemplatePosition.y,
								width: pageTemplatePosition.width,
								height: pageTemplatePosition.height,
								borderwidth: pageTemplatePosition.borderwidth,
								bordercolor: pageTemplatePosition.bordercolor,
								angle: pageTemplatePosition.angle,
								type: pageTemplatePosition.type,
							},
							this.scaling,
							null,
							this.bleedMargin,
						),
						templatePosition: pageTemplatePosition as EditorInteractiveInteractionTemplatePhotoModel['templatePosition'] & EditorInteractiveInteractionTemplateTextModel['templatePosition'],
					});

					return interactionTemplatePhotoTextModels;
				},
				[] as EditorInteractiveInteractionTemplatePhotoModel[],
			);
	}

	protected get interactionTemplatePhotoTextModelClasses(): (templatePosition: EditorInteractiveInteractionTemplatePhotoModel['templatePosition'] | EditorInteractiveInteractionTemplateTextModel['templatePosition']) => Record<string, boolean> {
		return (templatePosition) => ({
			'editor-interactive-view-add-photo': templatePosition.type === 'photo',
			'editor-interactive-view-add-text': templatePosition.type === 'text',
		});
	}

	protected get interactionTemplatePhotoTextModelStyles(): (interactionTemplatePhotoTextModel: EditorInteractiveInteractionTemplatePhotoModel | EditorInteractiveInteractionTemplateTextModel) => Partial<CSSStyleDeclaration> & Record<string, string> {
		return ({
			objectVars,
			templatePosition,
		}) => {
			const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

			if (this.$el) {
				const addObjectElement = (
					this.$el.querySelector('.editor-interactive-view-add-photo')
					|| this.$el.querySelector('.editor-interactive-view-add-text')
				);

				if (!addObjectElement) {
					return styles;
				}

				const addObjectPlaceholderElement = (
					addObjectElement.querySelector('.editor-interactive-view-add-image-placeholder')
					|| addObjectElement.querySelector('.editor-interactive-view-add-text-placeholder')
				);

				if (!addObjectPlaceholderElement) {
					return styles;
				}

				const addObjectElementStyles = getComputedStyle(addObjectElement);
				const addObjectPlaceholderElementStyles = getComputedStyle(addObjectPlaceholderElement);
				let addImageTextFontSize: number;
				let addImageTextIconSize: number;
				let addImageTextRowGap: number;

				if (!this.isMobile) {
					addImageTextFontSize = parseInt(
						getComputedStyle(this.$el).getPropertyValue('--font-size-l'),
						10,
					);
					addImageTextIconSize = parseInt(
						getComputedStyle(this.$el).getPropertyValue('--font-size-x3l'),
						10,
					);
					addImageTextRowGap = 16;
				} else {
					addImageTextFontSize = parseInt(
						getComputedStyle(this.$el).getPropertyValue('--font-size-s'),
						10,
					);
					addImageTextIconSize = parseInt(
						getComputedStyle(this.$el).getPropertyValue('--font-size-x2l'),
						10,
					);
					addImageTextRowGap = 8;
				}

				let height = Math.round(
					Math.min(
						objectVars.height * this.scaling,
						objectVars.canvas.height,
					),
				);
				let width = Math.round(
					Math.min(
						objectVars.width * this.scaling,
						objectVars.canvas.width,
					),
				);
				let marginLeft = Math.round(objectVars.placement.x * this.scaling);
				let marginTop = Math.round(objectVars.placement.y * this.scaling);
				const addImageIconSizeScaleFactor = addImageTextIconSize / addImageTextFontSize;
				const addImageRowGapScaleFactor = addImageTextRowGap / (addImageTextFontSize + addImageTextIconSize);

				let textPlaceholder: string;

				if (templatePosition.type === 'photo') {
					textPlaceholder = (
						!this.isMobile
							? this.$t(`views.editorInteractive.add.${this.productType}.desktop`)
							: this.$t(`views.editorInteractive.add.${this.productType}.mobile`)
					);
				} else {
					textPlaceholder = (
						!this.isMobile
							? this.$t('views.editorInteractive.add.text.desktop')
							: this.$t('views.editorInteractive.add.text.mobile')
					);
				}

				if (
					this.bleedMargin
					&& !this.showBleed
				) {
					marginLeft -= this.bleedMargin * this.scaling;
					marginTop -= this.bleedMargin * this.scaling;
				}

				if (marginLeft < 0) {
					width -= Math.abs(marginLeft);
					marginLeft = 0;
				}
				if (marginTop < 0) {
					height -= Math.abs(marginTop);
					marginTop = 0;
				}

				const temporaryElement = document.createElement('div');
				temporaryElement.style.alignItems = addObjectElementStyles.alignItems;
				temporaryElement.style.border = addObjectElementStyles.border;
				temporaryElement.style.display = addObjectElementStyles.display;
				temporaryElement.style.flexDirection = addObjectElementStyles.flexDirection;
				temporaryElement.style.fontFamily = addObjectPlaceholderElementStyles.fontFamily;
				temporaryElement.style.fontSize = `${addImageTextFontSize}px`;
				temporaryElement.style.fontWeight = addObjectPlaceholderElementStyles.fontWeight;
				temporaryElement.style.height = `${height}px`;
				temporaryElement.style.justifyContent = addObjectElementStyles.justifyContent;
				temporaryElement.style.position = 'absolute';
				temporaryElement.style.visibility = 'hidden';
				temporaryElement.style.width = `${width}px`;
				const temporaryElementText = document.createElement('span');
				temporaryElementText.style.whiteSpace = 'nowrap';
				temporaryElementText.textContent = textPlaceholder;
				temporaryElement.appendChild(temporaryElementText);
				document.body.appendChild(temporaryElement);

				while (
					temporaryElementText.getBoundingClientRect().width > temporaryElement.clientWidth
					&& addImageTextFontSize > 0
				) {
					addImageTextFontSize -= 1;
					temporaryElement.style.fontSize = `${addImageTextFontSize}px`;
				}

				addImageTextIconSize = Math.round(addImageTextFontSize * addImageIconSizeScaleFactor);
				addImageTextRowGap = Math.round((addImageTextFontSize + addImageTextIconSize) * addImageRowGapScaleFactor);
				temporaryElement.style.rowGap = `${addImageTextRowGap}px`;
				const temporaryElementIcon = document.createElement('i');
				temporaryElementIcon.classList.add(
					'icon-component',
					'label-icon',
				);
				temporaryElementIcon.textContent = (
					templatePosition.type === 'photo'
						? 'add_photo'
						: 'add_text'
				);
				temporaryElementIcon.style.fontSize = `${addImageTextIconSize}px`;
				temporaryElement.prepend(temporaryElementIcon);

				while (
					temporaryElement.scrollHeight > temporaryElement.clientHeight
					&& addImageTextIconSize > 0
				) {
					addImageTextIconSize -= 1;
					temporaryElementIcon.style.fontSize = `${addImageTextIconSize}px`;
					addImageTextRowGap = Math.round((addImageTextFontSize + addImageTextIconSize) * addImageRowGapScaleFactor);
					temporaryElement.style.rowGap = `${addImageTextRowGap}px`;
				}

				temporaryElement.remove();

				if (addImageTextFontSize === 0) {
					addImageTextFontSize = 10;
					addImageTextIconSize = 20;
					addImageTextRowGap = 0;
				}

				styles['--add-image-text-font-size'] = `${addImageTextFontSize}px`;
				styles['--add-image-text-icon-size'] = `${addImageTextIconSize}px`;
				styles['--add-image-text-row-gap'] = `${addImageTextRowGap}px`;
				styles.height = `${height}px`;
				styles.marginLeft = `${marginLeft}px`;
				styles.marginTop = `${marginTop}px`;
				styles.transform = `rotate(${objectVars.rotate}deg)`;
				styles.width = `${width}px`;
			}

			return styles;
		};
	}

	protected get interactionTextObjectModelStyles(): (objectModel: EditorInteractivePageObjectModel) => Partial<CSSStyleDeclaration> {
		return (objectModel: EditorInteractivePageObjectModel): Partial<CSSStyleDeclaration> => {
			const styles: Partial<CSSStyleDeclaration> = {
				position: 'absolute',
				top: '0',
			};

			if (objectModel.align === 'Center') {
				styles.left = '50%';
				styles.transform = 'translateX(-50%)';
				styles.textAlign = 'center';
			} else if (objectModel.align === 'Right') {
				styles.right = '0';
				styles.textAlign = 'right';
			} else if (objectModel.align === 'Left') {
				styles.left = '0';
				styles.textAlign = 'left';
			}

			if (objectModel.pointsize) {
				const fontSizeScaled = ((objectModel.pointsize * 31496) / 15120) * this.scaling;
				styles.fontSize = `${fontSizeScaled}px`;
				styles.lineHeight = `${fontSizeScaled * 1.25}px`;

				if (objectModel.text_svg) {
					styles.width = `${Math.round(objectModel.width * this.scaling)}px`;
				} else {
					styles.width = `${Math.round((objectModel.width + ((objectModel.pointsize * 31496) / 15120) * 0.2) * this.scaling)}px`;
				}
			}

			styles.height = `${Math.round(objectModel.height * this.scaling)}px`;
			styles.fontFamily = objectModel.fontface || '';
			styles.fontStyle = objectModel.fontitalic ? 'italic' : '';
			styles.fontWeight = objectModel.fontbold ? 'bold' : '';
			styles.textDecoration = objectModel.fontunderline ? 'underline' : '';

			return styles;
		};
	}

	protected get interactionTextObjectModelText(): (objectModel: EditorInteractivePageObjectModel) => string {
		return (objectModel: EditorInteractivePageObjectModel): string => {
			if (
				objectModel?.text_formatted
				&& objectModel.text
			) {
				let newFormattedText = objectModel.text;
				const formattedNewLines = Array.from(objectModel.text_formatted.matchAll(/\n/g));

				// eslint-disable-next-line no-restricted-syntax
				for (const formattedNewLine of formattedNewLines) {
					if (formattedNewLine.index) {
						const firstPartOfNewText = newFormattedText.substring(
							0,
							formattedNewLine.index,
						);
						const secondPartOfNewText = newFormattedText.substring(formattedNewLine.index);
						const secondPartPlusOneCharacterOfNewText = newFormattedText.substring(formattedNewLine.index + 1);
						const firstCharacterOfNewText = newFormattedText.substring(
							formattedNewLine.index,
							formattedNewLine.index + 1,
						);
						const secondCharacterOfNewText = newFormattedText.substring(
							formattedNewLine.index + 1,
							formattedNewLine.index + 2,
						);
						const secondCharacterOfOldTextFormatted = objectModel.text_formatted.substring(
							formattedNewLine.index + 1,
							formattedNewLine.index + 2,
						);

						if (firstCharacterOfNewText !== '\n') {
							const isCurrentTextCharacterSpace = /^\s$/.test(firstCharacterOfNewText);
							let lastPartOfText = '';

							if (isCurrentTextCharacterSpace) {
								lastPartOfText = `${secondPartPlusOneCharacterOfNewText}`;
							} else {
								lastPartOfText = secondPartOfNewText;
							}

							newFormattedText = `${firstPartOfNewText}\r${lastPartOfText}`;
						} else if (
							secondCharacterOfNewText !== ' '
							&& secondCharacterOfOldTextFormatted === ' '
						) {
							newFormattedText = `${firstPartOfNewText}\r ${secondPartPlusOneCharacterOfNewText}`;
						} else {
							newFormattedText = `${firstPartOfNewText}\r${secondPartPlusOneCharacterOfNewText}`;
						}
					}
				}

				const newFormattedTextLines = newFormattedText.split('\r');

				if (newFormattedTextLines.length < 1) {
					return '';
				}

				let htmlContent = '';

				// eslint-disable-next-line no-restricted-syntax
				for (const computedTextObjectTextLine of newFormattedTextLines) {
					const spanElement = document.createElement('span');

					if (objectModel.pointsize) {
						const fontSizeScaled = ((objectModel.pointsize * 31496) / 15120) * this.scaling;
						spanElement.style.height = `${fontSizeScaled * 1.25}px`;
					}

					if (objectModel.align === 'Left') {
						spanElement.style.justifyContent = 'safe flex-start';
					} else if (objectModel.align === 'Center') {
						spanElement.style.justifyContent = 'safe center';
					} else if (objectModel.align === 'Right') {
						spanElement.style.justifyContent = 'safe flex-end';
					}

					spanElement.innerHTML = computedTextObjectTextLine.replace(
						/ /g,
						'&nbsp;',
					);
					htmlContent += spanElement.outerHTML;
					spanElement.remove();
				}

				return htmlContent;
			}

			return '';
		};
	}

	protected get interactionTextObjectModels(): EditorInteractiveInteractionPageObjectModel[] {
		return this.internalPageObjects
			.filter((pageObject) => pageObject.type === 'text')
			.sort((a, b) => a.z_axis - b.z_axis)
			.map((pageObject) => ({
				objectVars: PageObject.calculatePosition(
					{
						x_axis: pageObject.x_axis,
						y_axis: pageObject.y_axis,
						width: pageObject.width,
						height: pageObject.height,
						borderwidth: pageObject.borderwidth,
						bordercolor: pageObject.bordercolor,
						cropx: pageObject.cropx,
						cropy: pageObject.cropy,
						cropwidth: pageObject.cropwidth,
						cropheight: pageObject.cropheight,
						angle: pageObject.rotate,
						flop: Boolean(pageObject.flop),
						flip: Boolean(pageObject.flip),
						type: pageObject.type,
						maxwidth: pageObject.maxwidth,
						photoid: pageObject.photoid || undefined,
					},
					this.scaling,
					null,
					this.bleedMargin,
				),
				object: pageObject,
			}));
	}

	private get isObjectCroppingMove(): (objectModel?: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			typeof objectModel !== 'undefined'
			&& this.isObjectSelectedForEdition(objectModel)
			&& this.objectCropMoveMouseDown
		);
	}

	private get isObjectDeletable(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			this.isObjectTextObject(objectModel)
			&& (
				!!objectModel._selected
				|| (
					this.isObjectSelectedForEdition(objectModel)
					&& !this.multipleTextSupport
				)
			)
			&& !this.isObjectCroppingMove(objectModel)
		);
	}

	private get isObjectEditable(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			this.isObjectSelectedForEdition(objectModel)
			&& this.isObjectTextObject(objectModel)
			&& !this.multipleTextSupport
		);
	}

	private get isObjectHovered(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => !!objectModel._hovered;
	}

	protected get isObjectMovable(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			this.isObjectSelectedForEdition(objectModel)
			&& this.isObjectPhotoObject(objectModel)
		);
	}

	private get isObjectPhotoObject(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => objectModel.type === 'photo';
	}

	protected get isObjectRotable(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			this.isObjectSelectedForEdition(objectModel)
			&& !this.isObjectCroppingMove(objectModel)
		);
	}

	private get isObjectSelected(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => !!objectModel._selected;
	}

	private get isObjectSelectedForEdition(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => !!objectModel._selectedForEdition;
	}

	private get isObjectTextObject(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => objectModel.type === 'text';
	}

	protected get isObjectTransformable(): (objectModel: EditorInteractivePageObjectModel) => boolean {
		return (objectModel) => (
			this.isObjectTextObject(objectModel)
			|| this.isPhotoObjectTransformable
		);
	}

	protected get resizeCursorClasses(): (
		objectModel: EditorInteractivePageObjectModel,
		side: EditorInteractivePageObjectResizeSide,
	) => Record<string, boolean> {
		return (
			objectModel,
			side,
		): Record<string, boolean> => {
			const normalizedSmallAngle = (objectModel.rotate + 7) % 15;
			const adjustedAngle = Math.round((objectModel.rotate - normalizedSmallAngle + 7) % 180);

			if (
				side === 'top-right'
				|| side === 'bottom-left'
			) {
				return {
					[`cursor-${(adjustedAngle + 45) % 180}deg`]: true,
				};
			}
			if (
				side === 'top-left'
				|| side === 'bottom-right'
			) {
				return {
					[`cursor-${(adjustedAngle + 135) % 180}deg`]: true,
				};
			}
			if (
				side === 'top'
				|| side === 'bottom'
			) {
				return {
					[`cursor-${adjustedAngle % 180}deg`]: true,
				};
			}
			if (
				side === 'left'
				|| side === 'right'
			) {
				return {
					[`cursor-${(adjustedAngle + 90) % 180}deg`]: true,
				};
			}

			return {};
		};
	}

	private get selectedObjectForEdition(): EditorInteractivePageObjectModel | undefined {
		return this.internalPageObjects.find((pageObject) => !!pageObject._selectedForEdition);
	}

	private get selectedObjectForEditionElement(): HTMLElement | undefined {
		if (!this.$el) {
			return undefined;
		}

		if (this.selectedObjectForEdition) {
			return (
				this.$el.querySelector(`.editor-interactive-view-edit-object[data-object-id="${this.selectedObjectForEdition.id}"]`) as HTMLElement
				|| undefined
			);
		}

		return undefined;
	}

	private get selectedPageObjectElement(): HTMLElement | undefined {
		if (!this.$el) {
			return undefined;
		}

		let selectedPageObject: EditorInteractivePageObjectModel | undefined;

		if (
			this.selectedPhotoObjectModel
			&& !this.selectedTextObjectModel
		) {
			selectedPageObject = this.selectedPhotoObjectModel;
		} else if (this.selectedTextObjectModel) {
			selectedPageObject = this.selectedTextObjectModel;
		}

		if (selectedPageObject) {
			return (
				this.$el.querySelector(`.editor-interactive-view-edit-object[data-object-id="${selectedPageObject.id}"]`) as HTMLElement
				|| undefined
			);
		}

		return undefined;
	}

	private get selectedPhotoObjectModel(): EditorInteractivePageObjectModel | undefined {
		return this.internalPageObjects.find((pageObject) => (
			pageObject.type === 'photo'
			&& !!pageObject._selected
		));
	}

	private get selectedTextObjectModel(): EditorInteractivePageObjectModel | undefined {
		return this.internalPageObjects.find((pageObject) => (
			pageObject.type === 'text'
			&& !!pageObject._selected
		));
	}

	protected get shouldShowObjectResizeHandle(): (
		objectModel: EditorInteractivePageObjectModel,
		side: EditorInteractivePageObjectResizeSide,
	) => boolean {
		return (
			objectModel: EditorInteractivePageObjectModel,
			side: EditorInteractivePageObjectResizeSide,
		): boolean => {
			if (side === 'top-left') {
				return (
					this.isObjectSelectedForEdition(objectModel)
					&& !this.isObjectEditable(objectModel)
					&& !this.isObjectCroppingMove(objectModel)
				);
			}
			if (side === 'top-right') {
				return (
					(
						this.isObjectSelectedForEdition(objectModel)
						|| (
							objectModel.type === 'text'
							&& this.isObjectSelected(objectModel)
						)
					)
					&& !this.isObjectDeletable(objectModel)
					&& !this.isObjectCroppingMove(objectModel)
				);
			}

			return (
				this.isObjectSelectedForEdition(objectModel)
				&& !this.isObjectCroppingMove(objectModel)
			);
		};
	}

	private get textColorsChooseFrom(): ColorPickerFromColors | undefined {
		const logoObject = (
			this.productType
			&& this.internalPageObjects.find((internalPageObject) => internalPageObject.type === 'photo')
		);
		const logoColors = (
			logoObject
				? logoColorsTools.getColors(logoObject)
				: {
					foreground: [],
				}
		);
		const uniqueForegroundColors = new Set(
			logoColors.foreground
				.map((vectorColor) => (
					vectorColor.replace?.real
					|| vectorColor.color
				))
				.filter((vectorColor) => vectorColor !== 'transparent'),
		);
		const chooseFromColors = Array
			.from(uniqueForegroundColors)
			.reduce(
				(imageVectorColors, uniqueColor) => {
					const vectorColorFound = logoColors.foreground.find((vectorColor) => (
						vectorColor.replace?.real === uniqueColor
						|| vectorColor.color === uniqueColor
					));

					if (vectorColorFound) {
						imageVectorColors.push({
							color: vectorColorFound.replace?.real || vectorColorFound.color,
							visual: vectorColorFound.replace?.visual,
						});
					}

					return imageVectorColors;
				},
				[] as ColorPickerFromColors,
			);

		if (chooseFromColors.length) {
			return chooseFromColors;
		}

		return undefined;
	}

	@Ref('editorTextOptionsView')
	private editorTextOptionsViewComponent!: EditorTextOptionsView;

	private editorTextOptionsTooltips!: Record<EditorInteractivePageObjectModel['id'], ServiceOpenReturn<TooltipComponent<typeof EditorTextOptionsView>>>;

	private internalIsEyeDropperShown = false;

	private internalPageObjects: EditorInteractivePageObjectModels = [];

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private isPinch?: boolean;

	private objectContentMouseDown?: boolean;

	private objectCropMoveMouseDown = false;

	private objectEditorContentRotateCenterX!: number;

	private objectEditorContentRotateCenterY!: number;

	private objectEditorContentX!: number;

	private objectEditorContentY!: number;

	private objectInitialBoundingBox!: DOMRect;

	private objectInitialPosition!: EditorInteractivePageObjectInitialPosition;

	private objectInitialSize!: EditorInteractivePageObjectInitialSize;

	private objectResizeMouseDown?: boolean;

	private objectResizeSide?: EditorInteractivePageObjectResizeSide;

	private objectRotateMouseDown?: boolean;

	protected renderEditObjectList = true;

	private windowClickHandlers!: Record<EditorInteractivePageObjectModel['id'], (event: MouseEvent) => void>;

	private windowTouchHandlers!: Record<EditorInteractivePageObjectModel['id'], (event: TouchEvent) => void>;

	protected beforeDestroy(): void {
		const windowClickHandlers = Object.entries(this.windowClickHandlers);

		// eslint-disable-next-line no-restricted-syntax
		for (const [objectId, windowClickHandler] of windowClickHandlers) {
			window.removeEventListener(
				'click',
				windowClickHandler,
			);
			delete this.windowClickHandlers[objectId];
		}

		const windowTouchHandlers = Object.entries(this.windowTouchHandlers);

		// eslint-disable-next-line no-restricted-syntax
		for (const [objectId, windowTouchHandler] of windowTouchHandlers) {
			window.removeEventListener(
				'touchstart',
				windowTouchHandler,
			);
			window.removeEventListener(
				'touchend',
				windowTouchHandler,
			);
			delete this.windowTouchHandlers[objectId];
		}

		this.destroyEditorTextOptionsTooltips();
		this.isMobileUnwatch?.();
		window.removeEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		document.removeEventListener(
			'selectionchange',
			this.onDocumentSelectionChange,
		);
	}

	protected created(): void {
		this.editorTextOptionsTooltips = {};
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
		this.windowClickHandlers = {};
		this.windowTouchHandlers = {};
	}

	protected mounted(): void {
		this.$forceCompute('selectedPageObjectElement');
		this.$forceCompute('interactionTemplatePhotoTextModelStyles');
	}

	@Watch(
		'isEyeDropperShown',
		{
			immediate: true,
		},
	)
	protected onIsEyeDropperShownChange(): void {
		this.internalIsEyeDropperShown = this.isEyeDropperShown || false;
	}

	@Watch(
		'pageObjects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPageObjectsChange(): void {
		let objectAdded = false;
		const newObjects = this.pageObjects;
		const oldObjects = this.internalPageObjects;
		const objectsToRemove: EditorInteractivePageObjectModel[] = [];

		// eslint-disable-next-line no-restricted-syntax
		for (const newObject of newObjects) {
			const oldObjectFound = oldObjects.find((oldObject) => oldObject.id === newObject.id);

			if (!oldObjectFound) {
				objectAdded = true;
				oldObjects.push({
					...newObject,
				});
			} else {
				const objectDifferences = objectUtils.getObjectDifferences(
					newObject,
					oldObjectFound,
				);

				if (objectDifferences.length) {
					// eslint-disable-next-line no-restricted-syntax
					for (const objectDifference of objectDifferences) {
						(oldObjectFound as any)[objectDifference] = newObject[objectDifference];
					}
				}
			}
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const oldObject of oldObjects) {
			const newObjectFound = newObjects.find((newObject) => newObject.id === oldObject.id);

			if (!newObjectFound) {
				objectsToRemove.push(oldObject);
			}
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const objectToRemove of objectsToRemove) {
			oldObjects.splice(
				oldObjects.indexOf(objectToRemove),
				1,
			);
		}

		if (objectAdded) {
			this.forceReRenderEditObjectList();
		}
	}

	@Watch('selectedObjectForEdition')
	protected onSelectedObjectForEditionChange(
		newSelectedObjectForEdition?: EditorInteractivePageObjectModel,
		oldSelectedObjectForEdition?: EditorInteractivePageObjectModel,
	): void {
		requestAnimationFrame(() => {
			if (oldSelectedObjectForEdition) {
				this.removeWindowClickHandler(oldSelectedObjectForEdition);
				this.removeWindowTouchHandler(oldSelectedObjectForEdition);
				this.checkTextObjectChanges(oldSelectedObjectForEdition);
			}
			if (newSelectedObjectForEdition) {
				this.addWindowClickHandler(newSelectedObjectForEdition);
				this.addWindowTouchHandler(newSelectedObjectForEdition);
			}
		});
	}

	@Watch('selectedTextObjectModel')
	protected onSelectedTextObjectModelChange(
		newSelectedTextObjectModel?: EditorInteractivePageObjectModel,
		oldSelectedTextObjectModel?: EditorInteractivePageObjectModel,
	): void {
		if (oldSelectedTextObjectModel?.id !== newSelectedTextObjectModel?.id) {
			this.$nextTick(() => {
				document.removeEventListener(
					'selectionchange',
					this.onDocumentSelectionChange,
				);
				this.destroyEditorTextOptionsTooltips(newSelectedTextObjectModel?.id);

				if (
					this.selectedPageObjectElement
					&& newSelectedTextObjectModel
				) {
					if (this.selectedTextObjectModel) {
						const selectedTextObjectTextContentElement = this.selectedPageObjectElement.querySelector<HTMLTextAreaElement>('.editor-interactive-view-edit-object-text-content');

						if (selectedTextObjectTextContentElement) {
							document.addEventListener(
								'selectionchange',
								this.onDocumentSelectionChange,
							);
							selectedTextObjectTextContentElement.focus();
						} else {
							this.onSelectedTextObjectModelChange(
								newSelectedTextObjectModel,
								oldSelectedTextObjectModel,
							);
							return;
						}

						this.openEditorTextOptionsTooltip(
							this.selectedPageObjectElement,
							newSelectedTextObjectModel,
						);
					}

					if (!mobileTools.isMobile) {
						requestAnimationFrame(() => this.addWindowClickHandler(newSelectedTextObjectModel));
					}
				} else if (
					!this.selectedPageObjectElement
					&& newSelectedTextObjectModel
				) {
					this.$forceCompute('selectedPageObjectElement');
					this.onSelectedTextObjectModelChange(
						newSelectedTextObjectModel,
						oldSelectedTextObjectModel,
					);
					return;
				}

				if (oldSelectedTextObjectModel) {
					this.removeWindowClickHandler(oldSelectedTextObjectModel);
					this.checkTextObjectChanges(oldSelectedTextObjectModel);
				}
			});
		}
	}

	@Watch(
		'fontModels',
		{
			deep: true,
		},
	)
	@Watch(
		'selectedTextObjectModel',
		{
			deep: true,
		},
	)
	@Watch(
		'offeringModel',
		{
			deep: true,
		},
	)
	protected onPossibleEditorTextOptionsPropsChange(): void {
		if (
			this.selectedTextObjectModel
			&& this.editorTextOptionsTooltips[this.selectedTextObjectModel.id]
		) {
			const editorTextOptionsViewInstance = this.editorTextOptionsTooltips[this.selectedTextObjectModel.id].api.bodyComponent();

			if (editorTextOptionsViewInstance) {
				editorTextOptionsViewInstance.fontModels = this.fontModels;
				editorTextOptionsViewInstance.objectModel = this.selectedTextObjectModel;
				editorTextOptionsViewInstance.offeringModel = this.offeringModel;
			}
		}
	}

	private addWindowClickHandler(objectModel: EditorInteractivePageObjectModel): void {
		if (!this.windowClickHandlers[objectModel.id]) {
			const handler = (event: MouseEvent) => this.onWindowClick(
				objectModel.id,
				event,
			);
			window.addEventListener(
				'click',
				handler,
			);
			this.windowClickHandlers[objectModel.id] = handler;
		}
	}

	private addWindowTouchHandler(objectModel: EditorInteractivePageObjectModel): void {
		if (!this.windowTouchHandlers[objectModel.id]) {
			const handler = (event: TouchEvent) => {
				if (event.type === 'touchstart') {
					this.onObjectMouseDown(
						objectModel,
						event,
					);
				} else if (event.type === 'touchend') {
					this.onObjectTouchEnd(objectModel);
				}
			};
			window.addEventListener(
				'touchstart',
				handler,
			);
			window.addEventListener(
				'touchend',
				handler,
			);
			this.windowTouchHandlers[objectModel.id] = handler;
		}
	}

	private calculateRotatedBoundingBox(objectModel: EditorInteractivePageObjectModel): {
		height: number;
		width: number;
		x: number;
		y: number;
	} {
		const {
			height,
			x_axis: left,
			rotate,
			y_axis: top,
			width,
		} = objectModel;

		// Convert the rotation angle to radians
		const angle = rotate * (Math.PI / 180);

		// Calculate the center of the rectangle
		const cx = left + width / 2;
		const cy = top + height / 2;

		// Calculate the coordinates of the corners before rotation
		const corners = [
			{
				x: left,
				y: top,
			}, // top-left
			{
				x: left + width,
				y: top,
			}, // top-right
			{
				x: left,
				y: top + height,
			}, // bottom-left
			{
				x: left + width,
				y: top + height,
			}, // bottom-right
		];

		// Rotate each corner around the center
		const rotatedCorners = corners.map(({ x, y }) => {
			const dx = x - cx;
			const dy = y - cy;

			return {
				x: cx + (dx * Math.cos(angle) - dy * Math.sin(angle)),
				y: cy + (dx * Math.sin(angle) + dy * Math.cos(angle)),
			};
		});

		// Find the new bounding box
		const xs = rotatedCorners.map((corner) => corner.x);
		const ys = rotatedCorners.map((corner) => corner.y);

		const minX = Math.min(...xs);
		const maxX = Math.max(...xs);
		const minY = Math.min(...ys);
		const maxY = Math.max(...ys);

		return {
			height: maxY - minY,
			width: maxX - minX,
			x: minX,
			y: minY,
		};
	}

	private checkTextObjectChanges(objectModel: EditorInteractivePageObjectModel): void {
		if (objectModel.type === 'text') {
			if (!objectModel.text) {
				this.$emit(
					'delete-object',
					objectModel,
				);
			} else {
				// TODO: add logic to check if text object has pending changes
				this.$emit('push-changes');
			}
		} else if (objectModel._crop) {
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[objectModel.id]: [
					'_crop',
				],
			};
			objectModel._crop = false;
			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
		}
	}

	private destroyEditorTextOptionsTooltips(objectIdToOmit?: EditorInteractivePageObjectModel['id']): void {
		if (this.editorTextOptionsTooltips) {
			const editorTextOptionsTooltips = Object.entries(this.editorTextOptionsTooltips);

			// eslint-disable-next-line no-restricted-syntax
			for (const [objectId, tooltip] of editorTextOptionsTooltips) {
				if (objectId !== objectIdToOmit) {
					tooltip.destroy();
					delete this.editorTextOptionsTooltips[objectId];
				}
			}
		}
	}

	private fixCornerPosition(
		newObjectModel: EditorInteractivePageObjectInitialSize,
		oldObjectModel: EditorInteractivePageObjectInitialSize,
		side: EditorInteractivePageObjectResizeSide,
	): EditorInteractivePageObjectInitialSize {
		let cornerToFix: EditorInteractivePageObjectFixResizeSide = 'top-left';

		if (side === 'bottom-right') {
			cornerToFix = 'top-left';
		} else if (side === 'bottom-left') {
			cornerToFix = 'top-right';
		} else if (side === 'top-right') {
			cornerToFix = 'bottom-left';
		} else if (side === 'top-left') {
			cornerToFix = 'bottom-right';
		} else if (side === 'top') {
			cornerToFix = 'bottom-right';
		} else if (side === 'right') {
			cornerToFix = 'top-left';
		} else if (side === 'bottom') {
			cornerToFix = 'top-left';
		} else if (side === 'left') {
			cornerToFix = 'top-right';
		}

		const oldSizeCoordinates = this.getCoordinates(oldObjectModel);
		const newSizeCoordinates = this.getCoordinates(newObjectModel);

		let newXAxis = 0;
		let newYAxis = 0;

		if (cornerToFix === 'top-left') {
			newXAxis = newObjectModel.x_axis + oldSizeCoordinates.ax - newSizeCoordinates.ax;
			newYAxis = newObjectModel.y_axis + oldSizeCoordinates.ay - newSizeCoordinates.ay;
		} else if (cornerToFix === 'top-right') {
			newXAxis = newObjectModel.x_axis + oldSizeCoordinates.bx - newSizeCoordinates.bx;
			newYAxis = newObjectModel.y_axis + oldSizeCoordinates.by - newSizeCoordinates.by;
		} else if (cornerToFix === 'bottom-right') {
			newXAxis = newObjectModel.x_axis + oldSizeCoordinates.cx - newSizeCoordinates.cx;
			newYAxis = newObjectModel.y_axis + oldSizeCoordinates.cy - newSizeCoordinates.cy;
		} else if (cornerToFix === 'bottom-left') {
			newXAxis = newObjectModel.x_axis + oldSizeCoordinates.dx - newSizeCoordinates.dx;
			newYAxis = newObjectModel.y_axis + oldSizeCoordinates.dy - newSizeCoordinates.dy;
		} else {
			newXAxis = newObjectModel.x_axis + oldSizeCoordinates.ax - newSizeCoordinates.ax;
			newYAxis = newObjectModel.y_axis + oldSizeCoordinates.ay - newSizeCoordinates.ay;
		}

		return {
			...newObjectModel,
			x_axis: newXAxis,
			y_axis: newYAxis,
		};
	}

	private async forceReRenderEditObjectList(onlyCheckForTextElement?: boolean): Promise<void> {
		if (!onlyCheckForTextElement) {
			this.renderEditObjectList = false;
			await this.$nextTick();
			this.renderEditObjectList = true;
			await this.$nextTick();
		}

		if (this.selectedTextObjectModel) {
			this.destroyEditorTextOptionsTooltips(this.selectedTextObjectModel.id);
			const selectedPageObjectElement = this.$el.querySelector<HTMLElement>(`.editor-interactive-view-edit-object[data-object-id="${this.selectedTextObjectModel.id}"]`);
			const selectedTextObjectTextContentElement = selectedPageObjectElement?.querySelector<HTMLTextAreaElement>('.editor-interactive-view-edit-object-text-content');

			if (
				!selectedPageObjectElement
				|| !selectedTextObjectTextContentElement
			) {
				requestAnimationFrame(() => {
					this.forceReRenderEditObjectList(true);
				});
				return;
			}

			if (selectedTextObjectTextContentElement) {
				selectedTextObjectTextContentElement.focus();
			}

			this.openEditorTextOptionsTooltip(
				selectedPageObjectElement,
				this.selectedTextObjectModel,
			);

			if (!mobileTools.isMobile) {
				const { selectedTextObjectModel } = this;
				this.removeWindowClickHandler(selectedTextObjectModel);
				requestAnimationFrame(() => this.addWindowClickHandler(selectedTextObjectModel));
			}
		}
	}

	private getCoordinates(objectModel: EditorInteractivePageObjectInitialSize): EditorInteractivePageObjectCoordinates {
		/**
		 * a(x,y) _____________________________  b(x,y)
		 * 	     |                             |
		 * 	     |                             |
		 * 	     |                             |
		 * 	     |                             |
		 * d(x,y)|_____________________________| c(x,y)
		 */
		const radians = (Math.PI / -180) * objectModel.rotate;
		const cos = Math.cos(radians);
		const sin = Math.sin(radians);
		const x1 = objectModel.x_axis;
		const y1 = objectModel.y_axis;
		const x2 = x1 + objectModel.width;
		const y2 = y1;
		const x3 = x1 + objectModel.width;
		const y3 = y1 + objectModel.height;
		const x4 = x1;
		const y4 = y1 + objectModel.height;
		const centerX = x1 + (objectModel.width / 2);
		const centerY = y1 + (objectModel.height / 2);

		const ax = cos * (x1 - centerX) + sin * (y1 - centerY) + centerX;
		const ay = cos * (y1 - centerY) - sin * (x1 - centerX) + centerY;

		const bx = cos * (x2 - centerX) + sin * (y2 - centerY) + centerX;
		const by = cos * (y2 - centerY) - sin * (x2 - centerX) + centerY;

		const cx = cos * (x3 - centerX) + sin * (y3 - centerY) + centerX;
		const cy = cos * (y3 - centerY) - sin * (x3 - centerX) + centerY;

		const dx = cos * (x4 - centerX) + sin * (y4 - centerY) + centerX;
		const dy = cos * (y4 - centerY) - sin * (x4 - centerX) + centerY;

		return {
			ax,
			ay,
			bx,
			by,
			cx,
			cy,
			dx,
			dy,
		};
	}

	protected onAddPhotoTextClick(templatePosition: EditorInteractiveInteractionTemplatePhotoModel['templatePosition'] | EditorInteractiveInteractionTemplateTextModel['templatePosition']): void {
		if (templatePosition.type === 'photo') {
			this.$emit(
				'add-photo',
				templatePosition,
			);
		} else if (templatePosition.type === 'text') {
			this.$emit(
				'add-text',
				templatePosition,
			);
		}
	}

	private onDocumentSelectionChange(): void {
		const selectedTextObjectTextContentElement = this.selectedPageObjectElement?.querySelector<HTMLTextAreaElement>('.editor-interactive-view-edit-object-text-content');

		if (
			document.activeElement
			&& selectedTextObjectTextContentElement
			&& document.activeElement.isSameNode(selectedTextObjectTextContentElement)
			&& this.selectedTextObjectModel
		) {
			const selection = domUtils.getSelection(selectedTextObjectTextContentElement);
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[this.selectedTextObjectModel.id]: [],
			};

			if (this.selectedTextObjectModel._caretLine !== selection.caretLine) {
				this.selectedTextObjectModel._caretLine = selection.caretLine;
				objectsDifferences[this.selectedTextObjectModel.id].push('_caretLine');
			}
			if (this.selectedTextObjectModel._selectionEnd !== selection.end) {
				this.selectedTextObjectModel._selectionEnd = selection.end;
				objectsDifferences[this.selectedTextObjectModel.id].push('_selectionEnd');
			}
			if (this.selectedTextObjectModel._selectionStart !== selection.start) {
				this.selectedTextObjectModel._selectionStart = selection.start;
				objectsDifferences[this.selectedTextObjectModel.id].push('_selectionStart');
			}

			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
		} else if (
			document.activeElement
			&& this.selectedTextObjectModel
			&& this.selectedTextObjectModel._selectionStart !== -1
			&& this.selectedTextObjectModel._selectionEnd !== -1
		) {
			this.selectedTextObjectModel._caretLine = 0;
			this.selectedTextObjectModel._selectionStart = -1;
			this.selectedTextObjectModel._selectionEnd = -1;
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[this.selectedTextObjectModel.id]: [
					'_selectionStart',
					'_selectionEnd',
				],
			};
			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
		}
	}

	protected onEditClick(pageObject: EditorInteractivePageObjectModel): void {
		if (
			this.selectedObjectForEdition?.id !== pageObject.id
			&& !pageObject._selected
		) {
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[pageObject.id]: ['_selectedForEdition'],
			};
			pageObject._selectedForEdition = true;
			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
			requestAnimationFrame(() => this.addWindowClickHandler(pageObject));
		}
	}

	protected onEditDoubleClick(pageObject: EditorInteractivePageObjectModel): void {
		if (!pageObject._selected) {
			// TODO: check if this is actually necessary, basically if the click event happens and this is already removed
			this.removeWindowClickHandler(pageObject);
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[pageObject.id]: [
					'_selected',
					'_selectedForEdition',
				],
			};
			pageObject._selectedForEdition = false;
			pageObject._selected = true;

			if (pageObject.type === 'text') {
				pageObject._previousState = {
					...pageObject,
				};
				objectsDifferences[pageObject.id].push('_previousState');
			}

			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
		}
	}

	protected onEditorTextOptionsActiveModeChange(activeMode: EditorTextOptionsActiveMode | null): void {
		this.$emit(
			'editor-text-options-active-mode-change',
			activeMode,
		);
	}

	private async onEditorTextOptionsChange(
		newTextObject: EditorInteractivePageObjectModel,
		objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>>,
	): Promise<void> {
		const textObjectModel = (
			this.selectedTextObjectModel
			|| (
				this.selectedObjectForEdition?.type === 'text'
					? this.selectedObjectForEdition
					: undefined
			)
		);

		if (textObjectModel) {
			let objectDifferences = objectsDifferences[textObjectModel.id];

			if (objectDifferences.length) {
				const partialObjectToChange = objectDifferences.reduce(
					(partialObject, keyDifference) => {
						partialObject[keyDifference] = newTextObject[keyDifference];

						return partialObject;
					},
					{
						id: textObjectModel.id,
					} as OptionalExceptFor<EditorInteractivePageObjectModel, 'id'>,
				);
				let updatedTextObjectResult: PageObjectModelChangeResult | undefined;

				if (partialObjectToChange.fontface) {
					const newFontModel = FontModule.getById(partialObjectToChange.fontface) as FontModel;

					updatedTextObjectResult = await FontModule
						.loadModel(partialObjectToChange.fontface)
						.then(() => {
							if (
								textObjectModel.fontbold
								&& newFontModel.bold
							) {
								partialObjectToChange.fontbold = 1;
							} else {
								partialObjectToChange.fontbold = 0;
							}

							if (
								textObjectModel.fontitalic
								&& newFontModel.italic
							) {
								partialObjectToChange.fontitalic = 1;
							} else {
								partialObjectToChange.fontitalic = 0;
							}

							return ProductStateModule.changePageObject({
								...partialObjectToChange,
								noStore: true,
								originalPageObject: textObjectModel,
							});
						});
				} else {
					updatedTextObjectResult = await ProductStateModule.changePageObject({
						...partialObjectToChange,
						noStore: true,
						originalPageObject: textObjectModel,
					});
				}

				if (updatedTextObjectResult) {
					const { partialPageObject } = updatedTextObjectResult;
					objectsDifferences = {
						[partialPageObject.id]: [
							...(Object
								.keys(partialPageObject) as Array<keyof EditorInteractivePageObjectModel>)
								.filter((key) => partialPageObject[key] !== textObjectModel[key]),
						],
					};
					this.internalPageObjects.splice(
						this.internalPageObjects.indexOf(textObjectModel),
						1,
						updatedTextObjectResult.updatedPageObject,
					);
					objectDifferences = objectsDifferences[partialPageObject.id];
					objectDifferences.splice(
						objectDifferences.indexOf('id'),
						1,
					);
					this.$emit(
						'change',
						this.internalPageObjects,
						objectsDifferences,
					);
				}
			}
		}
	}

	protected onEyeDropperEnd(): void {
		this.internalIsEyeDropperShown = false;
		this.$emit(
			'eye-dropper-shown-change',
			this.internalIsEyeDropperShown,
		);
	}

	protected onEyeDropperStart(): void {
		this.internalIsEyeDropperShown = true;
		this.$emit(
			'eye-dropper-shown-change',
			this.internalIsEyeDropperShown,
		);
	}

	protected onObjectCropMoveMouseDown(event: MouseEvent | TouchEvent): void {
		event.preventDefault();
		let finalEvent: MouseEvent | Touch;

		if (
			!this.selectedObjectForEditionElement
			|| !this.selectedObjectForEdition
		) {
			return;
		}

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		this.objectEditorContentX = finalEvent.clientX;
		this.objectEditorContentY = finalEvent.clientY;

		this.objectInitialSize = {
			...this.selectedObjectForEdition,
		};
		this.objectCropMoveMouseDown = true;
		const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
			[this.selectedObjectForEdition.id]: [
				'_crop',
			],
		};
		this.selectedObjectForEdition._crop = true;
		this.$emit(
			'change',
			this.internalPageObjects,
			objectsDifferences,
		);

		window.addEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
	}

	protected onObjectDeleteClick(pageObject: EditorInteractivePageObjectModel): void {
		this.$emit(
			'delete-object',
			pageObject,
		);
	}

	protected onObjectEditClick(pageObject: EditorInteractivePageObjectModel): void {
		if (!pageObject._selected) {
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[pageObject.id]: [
					'_selected',
					'_selectedForEdition',
				],
			};
			pageObject._selectedForEdition = false;
			pageObject._selected = true;

			if (pageObject.type === 'text') {
				pageObject._previousState = {
					...pageObject,
				};
				objectsDifferences[pageObject.id].push('_previousState');
			}

			this.$emit(
				'change',
				this.internalPageObjects,
				objectsDifferences,
			);
		}
	}

	protected onObjectTouchEnd(objectModel: EditorInteractivePageObjectModel): void {
		setTimeout(
			() => {
				if (
					objectModel._selected
					|| (
						!objectModel._selected
						&& !objectModel._selectedForEdition
					)
				) {
					this.isPinch = false;
					this.objectContentMouseDown = false;
					window.removeEventListener(
						'mousemove',
						this.onWindowMouseMove,
					);
					window.removeEventListener(
						'touchmove',
						this.onWindowMouseMove,
					);
					window.removeEventListener(
						'mouseup',
						this.onWindowMouseUp,
					);
					window.removeEventListener(
						'touchend',
						this.onWindowMouseUp,
					);
				}
			},
			doubleTapInterval,
		);
	}

	protected onObjectMouseDown(
		objectModel: EditorInteractivePageObjectModel,
		event: MouseEvent | TouchEvent,
	): void {
		if (
			!event.defaultPrevented
			&& (
				objectModel.type !== 'text'
				|| !objectModel._selected
			)
		) {
			if (
				this.isPinch
				|| this.objectContentMouseDown
			) {
				window.removeEventListener(
					'mousemove',
					this.onWindowMouseMove,
				);
				window.removeEventListener(
					'touchmove',
					this.onWindowMouseMove,
				);
				window.removeEventListener(
					'mouseup',
					this.onWindowMouseUp,
				);
				window.removeEventListener(
					'touchend',
					this.onWindowMouseUp,
				);
			}

			let finalEvent: MouseEvent | Touch | Touch[];

			if ('touches' in event) {
				if (event.touches.length == 2) {
					finalEvent = Array.from(event.touches);
				} else {
					// eslint-disable-next-line prefer-destructuring
					finalEvent = event.touches[0];
				}
			} else {
				finalEvent = event;
			}

			const editorInteractiveViewEditObjectElement = (event.target as HTMLElement)?.closest(`.editor-interactive-view-edit-object[data-object-id="${objectModel.id}"]`);

			if (
				!editorInteractiveViewEditObjectElement
				&& !Array.isArray(finalEvent)
			) {
				return;
			}

			if (!Array.isArray(finalEvent)) {
				this.objectEditorContentX = finalEvent.clientX;
				this.objectEditorContentY = finalEvent.clientY;
			} else {
				this.objectEditorContentX = finalEvent[1].clientX - finalEvent[0].clientX;
				this.objectEditorContentY = finalEvent[1].clientY - finalEvent[0].clientY;
			}

			this.objectInitialSize = {
				...objectModel,
			};
			this.isPinch = Array.isArray(finalEvent);
			this.objectContentMouseDown = !this.isPinch;
			window.addEventListener(
				'mousemove',
				this.onWindowMouseMove,
			);
			window.addEventListener(
				'touchmove',
				this.onWindowMouseMove,
				{
					passive: false,
				},
			);
			window.addEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.addEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
		}
	}

	protected onObjectResizeMouseDown(
		event: MouseEvent | TouchEvent,
		side: EditorInteractivePageObjectResizeSide,
	): void {
		event.preventDefault();
		let finalEvent: MouseEvent | Touch;

		if (
			!this.selectedObjectForEditionElement
			|| !this.selectedObjectForEdition
		) {
			return;
		}

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		this.objectEditorContentX = finalEvent.clientX;
		this.objectEditorContentY = finalEvent.clientY;
		this.objectInitialPosition = {
			rotate: this.selectedObjectForEdition.rotate,
			x_axis: this.objectEditorContentX + document.body.scrollLeft,
			y_axis: this.objectEditorContentY + document.body.scrollTop,
		};
		const computedStyles = getComputedStyle(this.$el);
		const editObjectBorderWidth = parseInt(
			computedStyles.getPropertyValue('--editor-interactive-view-edit-object-border-width') || '0',
			10,
		);
		this.objectInitialSize = {
			cropheight: this.selectedObjectForEdition.cropheight,
			cropwidth: this.selectedObjectForEdition.cropwidth,
			cropx: this.selectedObjectForEdition.cropx,
			cropy: this.selectedObjectForEdition.cropy,
			height: this.selectedObjectForEdition.height * this.scaling,
			rotate: this.selectedObjectForEdition.rotate,
			width: this.selectedObjectForEdition.width * this.scaling,
			x_axis: this.selectedObjectForEditionElement.offsetLeft + editObjectBorderWidth,
			y_axis: this.selectedObjectForEditionElement.offsetTop + editObjectBorderWidth,
		};
		this.objectInitialBoundingBox = this.selectedObjectForEditionElement.getBoundingClientRect();

		if (this.selectedObjectForEdition.rotate) {
			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...this.selectedObjectForEdition,
				...this.objectInitialSize,
			});
			this.objectInitialBoundingBox.height = rotatedBoundingBox.height;
			this.objectInitialBoundingBox.width = rotatedBoundingBox.width;
			const centerX = this.objectInitialBoundingBox.x + (this.objectInitialBoundingBox.width / 2);
			const centerY = this.objectInitialBoundingBox.y + (this.objectInitialBoundingBox.height / 2);
			this.objectInitialPosition = this.rotateObjectModel(
				this.objectInitialPosition,
				centerX,
				centerY,
			);
		}

		this.objectResizeMouseDown = true;
		this.objectResizeSide = side;
		window.addEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
	}

	protected onObjectRotateMouseDown(event: MouseEvent | TouchEvent): void {
		const eventTarget = event.target as HTMLDivElement;
		event.preventDefault();
		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		const editorInteractiveViewEditObjectElement = eventTarget.closest('.editor-interactive-view-edit-object') as HTMLDivElement;
		const editorInteractiveViewEditObjectElementRect = editorInteractiveViewEditObjectElement.getBoundingClientRect();
		this.objectEditorContentRotateCenterX = editorInteractiveViewEditObjectElementRect.left + (editorInteractiveViewEditObjectElementRect.width / 2);
		this.objectEditorContentRotateCenterY = editorInteractiveViewEditObjectElementRect.top + (editorInteractiveViewEditObjectElementRect.height / 2);
		this.objectEditorContentX = finalEvent.clientX;
		this.objectEditorContentY = finalEvent.clientY;
		this.objectRotateMouseDown = true;
		window.addEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
	}

	protected async onTextObjectContentInput(
		textObject: EditorInteractivePageObjectModel,
		event: InputEvent,
	): Promise<void> {
		if (
			textObject.fontface
			&& event.target instanceof HTMLElement
		) {
			const fontModel = FontModule.getById(textObject.fontface);

			if (!fontModel) {
				throw new Error('Could not find required font model');
			}

			event.preventDefault();
			const targetElement = event.target as HTMLTextAreaElement;
			const subset = fontModel.subset.split(',');
			const text = filterText(
				targetElement.value,
				subset,
			);
			const updatedTextObjectResult = await ProductStateModule.changePageObject({
				id: textObject.id,
				text,
				noStore: true,
				originalPageObject: textObject,
			});

			if (updatedTextObjectResult) {
				this.internalPageObjects.splice(
					this.internalPageObjects.indexOf(textObject),
					1,
					updatedTextObjectResult.updatedPageObject,
				);
				const { partialPageObject } = updatedTextObjectResult;
				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[partialPageObject.id]: [
						...Object.keys(partialPageObject) as Array<keyof EditorInteractivePageObjectModel>,
					],
				};
				const objectDifferences = objectsDifferences[partialPageObject.id];
				objectDifferences.splice(
					objectDifferences.indexOf('id'),
					1,
				);
				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
				this.$nextTick(this.onDocumentSelectionChange);
			}
		}
	}

	private onWindowClick(
		objectId: EditorInteractivePageObjectModel['id'],
		event: MouseEvent | TouchEvent,
	): void {
		const objectModel = this.internalPageObjects.find((pageObject) => pageObject.id === objectId);

		if (objectModel) {
			const eventTarget = event.target as HTMLElement;
			const editorInteractiveViewEditObjectElement = (
				this.$el.querySelector(`.editor-interactive-view-edit-object[data-object-id="${objectModel.id}"]`) as HTMLElement
				|| undefined
			);

			if (this.isObjectSelectedForEdition(objectModel)) {
				if (
					(
						!editorInteractiveViewEditObjectElement
						|| (
							!editorInteractiveViewEditObjectElement.isSameNode(eventTarget)
							&& !editorInteractiveViewEditObjectElement.contains(eventTarget)
						)
					)
					&& !event.defaultPrevented
				) {
					objectModel._selectedForEdition = false;
					const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
						[objectModel.id]: ['_selectedForEdition'],
					};
					this.$emit(
						'change',
						this.internalPageObjects,
						objectsDifferences,
					);
					this.removeWindowClickHandler(objectModel);
				}
			} else if (
				(
					this.isObjectSelected(objectModel)
					&& objectModel.type === 'photo'
					&& !this.selectedTextObjectModel
				)
				|| this.selectedTextObjectModel
			) {
				if (
					(
						!editorInteractiveViewEditObjectElement
						|| (
							!editorInteractiveViewEditObjectElement.isSameNode(eventTarget)
							&& !editorInteractiveViewEditObjectElement.contains(eventTarget)
							&& !this.editorTextOptionsViewComponent?.$el?.isSameNode(eventTarget)
							&& !this.editorTextOptionsViewComponent?.$el?.contains(eventTarget)
						)
					)
					&& !event.defaultPrevented
				) {
					objectModel._selected = false;
					const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
						[objectModel.id]: ['_selected'],
					};
					this.$emit(
						'change',
						this.internalPageObjects,
						objectsDifferences,
					);
					this.removeWindowClickHandler(objectModel);
				}
			}
		}
	}

	private onWindowMouseMove(event: MouseEvent | TouchEvent): void {
		const selectedObjectTemplatePosition = this.pageTemplatePositions.sort((a, b) => (
			(a.width * a.height) - (b.width * b.height)
		))[0];

		const minHeight = Math.min(
			this.pageModel.height,
			this.selectedPhotoObjectModel?.maxheight || Infinity,
			selectedObjectTemplatePosition?.height || Infinity,
		) * 0.05;
		const minWidth = Math.min(
			this.pageModel.width,
			this.selectedPhotoObjectModel?.maxwidth || Infinity,
			selectedObjectTemplatePosition?.width || Infinity,
		) * 0.05;

		/**
		 * Minimum size limit for photo and text object to be (height and width)
		 */
		const minSizeLimit = Math.max(
			minHeight,
			minWidth,
		) * this.scaling;
		let finalEvent: MouseEvent | Touch | Touch[];

		if ('touches' in event) {
			if (event.touches.length === 2) {
				finalEvent = Array.from(event.touches);

				if (!this.isPinch) {
					this.isPinch = true;
					this.objectCropMoveMouseDown = false;
					this.objectEditorContentX = finalEvent[1].clientX - finalEvent[0].clientX;
					this.objectEditorContentY = finalEvent[1].clientY - finalEvent[0].clientY;
				}
			} else {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];

				if (this.isPinch) {
					this.isPinch = false;
					this.objectEditorContentX = finalEvent.clientX;
					this.objectEditorContentY = finalEvent.clientY;
				}
			}
		} else {
			finalEvent = event;

			if (this.isPinch) {
				this.isPinch = false;
				this.objectEditorContentX = finalEvent.clientX;
				this.objectEditorContentY = finalEvent.clientY;
			}
		}

		if (
			this.objectContentMouseDown
			&& this.selectedObjectForEdition
			&& this.pageModel
			&& !Array.isArray(finalEvent)
		) {
			event.preventDefault();
			const startPosition = {
				x: this.objectEditorContentX + document.body.scrollLeft,
				y: this.objectEditorContentY + document.body.scrollTop,
			};
			const xDifference = (finalEvent.clientX + document.body.scrollLeft - startPosition.x) / this.zoomLevel / this.scaling;
			const yDifference = (finalEvent.clientY + document.body.scrollTop - startPosition.y) / this.zoomLevel / this.scaling;
			let newXAxis = this.objectInitialSize.x_axis + xDifference;
			let newYAxis = this.objectInitialSize.y_axis + yDifference;

			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...this.selectedObjectForEdition,
				x_axis: newXAxis,
				y_axis: newYAxis,
			});

			if ((rotatedBoundingBox.x + this.bleedMargin) < 0) {
				const excessWidth = rotatedBoundingBox.x + this.bleedMargin;
				newXAxis -= excessWidth;
			}
			if ((rotatedBoundingBox.y + this.bleedMargin) < 0) {
				const excessHeight = rotatedBoundingBox.y + this.bleedMargin;
				newYAxis -= excessHeight;
			}
			if ((rotatedBoundingBox.x + this.bleedMargin + rotatedBoundingBox.width) > (this.pageModel.width + (this.bleedMargin * 2))) {
				const excessWidth = ((rotatedBoundingBox.x + this.bleedMargin + rotatedBoundingBox.width)) - (this.pageModel.width + (this.bleedMargin * 2));
				newXAxis -= excessWidth;
			}
			if ((rotatedBoundingBox.y + this.bleedMargin + rotatedBoundingBox.height) > (this.pageModel.height + (this.bleedMargin * 2))) {
				const excessHeight = (rotatedBoundingBox.y + this.bleedMargin + rotatedBoundingBox.height) - (this.pageModel.height + (this.bleedMargin * 2));
				newYAxis -= excessHeight;
			}

			/**
			 * Next we apply "magnetism" to the edges of the pages so when a user is moving
			 * the object close to the edges, it will automatically snap to the edge of the
			 * page or to the page safety margin.
			 */
			const pageMargin = this.offeringModel.pagemargin ?? 0;
			const bleedMargin = this.offeringModel?.bleedmargin ?? 0;

			const minNegativeMagnetismPageOrigin = -bleedMargin;
			const minPositiveMagnetismPageOrigin = pageMargin;
			const minCenterMagnetismPageOrigin = (minNegativeMagnetismPageOrigin + minPositiveMagnetismPageOrigin) / 2;

			const maxNegativeMagnetismPageRightSide = this.pageModel.width - pageMargin;
			const maxPositiveMagnetismPageRightSide = this.pageModel.width + bleedMargin;
			const maxCenterMagnetismPageRightSide = (maxNegativeMagnetismPageRightSide + maxPositiveMagnetismPageRightSide) / 2;

			const maxNegativeMagnetismPageBottomSide = this.pageModel.height - pageMargin;
			const maxPositiveMagnetismPageBottomSide = this.pageModel.height + bleedMargin;
			const maxCenterMagnetismPageBottomSide = (maxNegativeMagnetismPageBottomSide + maxPositiveMagnetismPageBottomSide) / 2;

			if (
				newXAxis > minNegativeMagnetismPageOrigin
				&& newXAxis < minPositiveMagnetismPageOrigin
			) {
				/**
				 * The object is somewhere between the left magnetic line and the right magnetic
				 * line on the left side of the page, so we snap it to the closest one
				 */
				newXAxis = (
					newXAxis < minCenterMagnetismPageOrigin
						? minNegativeMagnetismPageOrigin
						: minPositiveMagnetismPageOrigin
				);
			}

			if (
				newYAxis > minNegativeMagnetismPageOrigin
				&& newYAxis < minPositiveMagnetismPageOrigin
			) {
				/**
				 * The object is somewhere between the top magnetic line and the bottom magnetic
				 * line on the top side of the page, so we snap it to the closest one
				 */
				newYAxis = (
					newYAxis < minCenterMagnetismPageOrigin
						? minNegativeMagnetismPageOrigin
						: minPositiveMagnetismPageOrigin
				);
			}

			if (
				(newXAxis + this.selectedObjectForEdition.width) > maxNegativeMagnetismPageRightSide
				&& (newXAxis + this.selectedObjectForEdition.width) < maxPositiveMagnetismPageRightSide
			) {
				/**
				 * The object is somewhere between the left magnetic line and the right magnetic
				 * line on the right side of the page, so we snap it to the closest one
				 */
				newXAxis = (
					(newXAxis + this.selectedObjectForEdition.width) < maxCenterMagnetismPageRightSide
						? maxNegativeMagnetismPageRightSide - this.selectedObjectForEdition.width
						: maxPositiveMagnetismPageRightSide - this.selectedObjectForEdition.width
				);
			}

			if (
				(newYAxis + this.selectedObjectForEdition.height) > maxNegativeMagnetismPageBottomSide
				&& (newYAxis + this.selectedObjectForEdition.height) < maxPositiveMagnetismPageBottomSide
			) {
				/**
				 * The object is somewhere between the top magnetic line and the bottom magnetic
				 * line on the bottom side of the page, so we snap it to the closest one
				 */
				newYAxis = (
					(newYAxis + this.selectedObjectForEdition.height) < maxCenterMagnetismPageBottomSide
						? maxNegativeMagnetismPageBottomSide - this.selectedObjectForEdition.height
						: maxPositiveMagnetismPageBottomSide - this.selectedObjectForEdition.height
				);
			}

			const partialObject: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'> = {
				id: this.selectedObjectForEdition.id,
			};
			let xAxisChanged = false;
			let yAxisChanged = false;

			if (this.selectedObjectForEdition.x_axis !== newXAxis) {
				xAxisChanged = true;
				partialObject.x_axis = newXAxis;
			}
			if (this.selectedObjectForEdition.y_axis !== newYAxis) {
				yAxisChanged = true;
				partialObject.y_axis = newYAxis;
			}

			if (
				xAxisChanged
				|| yAxisChanged
			) {
				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[this.selectedObjectForEdition.id]: [],
				};
				const objectDifferences = Object.keys(partialObject) as (keyof EditorInteractivePageObjectModel)[];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					if (objectDifference !== 'id') {
						objectsDifferences[this.selectedObjectForEdition.id].push(objectDifference);
						this.selectedObjectForEdition[objectDifference] = partialObject[objectDifference];
					}
				}

				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
			}
		} else if (
			this.objectResizeMouseDown
			&& this.selectedObjectForEdition
			&& this.pageModel
			&& this.objectResizeSide
			&& !Array.isArray(finalEvent)
		) {
			event.preventDefault();
			const clientX = finalEvent.clientX + document.body.scrollLeft;
			const clientY = finalEvent.clientY + document.body.scrollTop;
			let xDifference = finalEvent.clientX + document.body.scrollLeft - this.objectInitialPosition.x_axis;
			let yDifference = finalEvent.clientY + document.body.scrollTop - this.objectInitialPosition.y_axis;
			let {
				height: newHeight,
				width: newWidth,
				x_axis: newXAxis,
				y_axis: newYAxis,
			} = this.objectInitialSize;
			const aspectRatio = this.selectedObjectForEdition.type === 'photo';
			const { rotate } = this.selectedObjectForEdition;
			const objectSizeRatio = this.selectedObjectForEdition.width / this.selectedObjectForEdition.height;

			if (rotate) {
				const centerX = this.objectInitialBoundingBox.x + (this.objectInitialBoundingBox.width / 2);
				const centerY = this.objectInitialBoundingBox.y + (this.objectInitialBoundingBox.height / 2);
				const rotatedXY = this.rotateObjectModel(
					{
						...this.selectedObjectForEdition,
						x_axis: clientX,
						y_axis: clientY,
					},
					centerX,
					centerY,
				);
				xDifference = rotatedXY.x_axis - this.objectInitialPosition.x_axis;
				yDifference = rotatedXY.y_axis - this.objectInitialPosition.y_axis;
			}

			xDifference /= this.zoomLevel;
			yDifference /= this.zoomLevel;

			switch (this.objectResizeSide) {
				case 'bottom-right':
					newWidth += xDifference;
					newHeight += yDifference;

					if (aspectRatio) {
						newHeight = newWidth / objectSizeRatio;
					}

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;

						if (aspectRatio) {
							newHeight = newWidth / objectSizeRatio;
						}
					}
					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;

						if (aspectRatio) {
							newWidth = newHeight * objectSizeRatio;
						}
					}
					break;
				case 'bottom-left':
					newWidth -= xDifference;
					newHeight += yDifference;

					if (aspectRatio) {
						newHeight = newWidth / objectSizeRatio;
					}

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;

						if (aspectRatio) {
							newHeight = newWidth / objectSizeRatio;
						}
					}
					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;

						if (aspectRatio) {
							newWidth = newHeight * objectSizeRatio;
						}
					}

					newXAxis += this.objectInitialSize.width - newWidth;
					break;
				case 'top-right':
					newWidth += xDifference;
					newHeight -= yDifference;

					if (aspectRatio) {
						newHeight = newWidth / objectSizeRatio;
					}

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;

						if (aspectRatio) {
							newHeight = newWidth / objectSizeRatio;
						}
					}
					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;

						if (aspectRatio) {
							newWidth = newHeight * objectSizeRatio;
						}
					}

					newYAxis += this.objectInitialSize.height - newHeight;
					break;
				case 'top-left':
					newWidth -= xDifference;
					newHeight -= yDifference;

					if (aspectRatio) {
						newHeight = newWidth / objectSizeRatio;
					}

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;

						if (aspectRatio) {
							newHeight = newWidth / objectSizeRatio;
						}
					}
					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;

						if (aspectRatio) {
							newWidth = newHeight * objectSizeRatio;
						}
					}

					newYAxis += this.objectInitialSize.height - newHeight;
					newXAxis += this.objectInitialSize.width - newWidth;
					break;
				case 'top':
					newHeight -= yDifference;

					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;
					}

					if (this.selectedObjectForEdition.type === 'photo') {
						const unusedBottomSpace = this.selectedObjectForEdition.maxheight - this.selectedObjectForEdition.cropy - this.selectedObjectForEdition.cropheight;
						const cropRatio = this.selectedObjectForEdition.cropheight / this.selectedObjectForEdition.height;
						let newCropHeight = (newHeight / this.scaling) * cropRatio;
						let newCropY = this.selectedObjectForEdition.maxheight - unusedBottomSpace - newCropHeight;

						if (newCropY < 0) {
							newHeight += (newCropY / cropRatio) * this.scaling;
							newCropY = 0;
							newCropHeight = (newHeight / this.scaling) * cropRatio;
						}

						if ((newCropY + newCropHeight) > this.selectedObjectForEdition.maxheight) {
							newCropHeight = this.selectedObjectForEdition.maxheight - newCropY;
							newHeight = (newCropHeight / cropRatio) * this.scaling;
						}
					}

					newYAxis += this.objectInitialSize.height - newHeight;
					break;
				case 'right':
					newWidth += xDifference;

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;
					}
					break;
				case 'bottom':
					newHeight += yDifference;

					if (newHeight < minSizeLimit) {
						newHeight = minSizeLimit;
					}
					break;
				case 'left':
					newWidth -= xDifference;

					if (newWidth < minSizeLimit) {
						newWidth = minSizeLimit;
					}

					if (this.selectedObjectForEdition.type === 'photo') {
						const unusedRightSpace = this.selectedObjectForEdition.maxwidth - this.selectedObjectForEdition.cropx - this.selectedObjectForEdition.cropwidth;
						const cropRatio = this.selectedObjectForEdition.cropwidth / this.selectedObjectForEdition.width;
						let newCropWidth = (newWidth / this.scaling) * cropRatio;
						let newCropX = this.selectedObjectForEdition.maxwidth - unusedRightSpace - newCropWidth;

						if (newCropX < 0) {
							newWidth += (newCropX / cropRatio) * this.scaling;
							newCropX = 0;
							newCropWidth = (newWidth / this.scaling) * cropRatio;
						}

						if ((newCropX + newCropWidth) > this.selectedObjectForEdition.maxwidth) {
							newCropWidth = this.selectedObjectForEdition.maxwidth - newCropX;
							newWidth = (newCropWidth / cropRatio) * this.scaling;
						}
					}

					newXAxis += this.objectInitialSize.width - newWidth;
					break;
				default:
					break;
			}

			if (rotate) {
				const cornerPositionFixed = this.fixCornerPosition(
					{
						...this.objectInitialSize,
						height: newHeight,
						width: newWidth,
					},
					this.objectInitialSize,
					this.objectResizeSide,
				);
				newXAxis = cornerPositionFixed.x_axis;
				newYAxis = cornerPositionFixed.y_axis;
			}

			newXAxis /= this.scaling;
			newYAxis /= this.scaling;
			newWidth /= this.scaling;
			newHeight /= this.scaling;

			if (this.showBleed) {
				newXAxis -= this.bleedMargin;
				newYAxis -= this.bleedMargin;
			}

			if (rotate) {
				const rotatedBoundingBox = this.calculateRotatedBoundingBox({
					...this.selectedObjectForEdition,
					x_axis: newXAxis,
					y_axis: newYAxis,
					width: newWidth,
					height: newHeight,
				});

				if (
					(rotatedBoundingBox.x + this.bleedMargin) < 0
					|| (rotatedBoundingBox.y + this.bleedMargin) < 0
					|| (rotatedBoundingBox.x + this.bleedMargin + rotatedBoundingBox.width) > (this.pageModel.width + (this.bleedMargin * 2))
					|| (rotatedBoundingBox.y + this.bleedMargin + rotatedBoundingBox.height) > (this.pageModel.height + (this.bleedMargin * 2))
				) {
					if ((rotatedBoundingBox.x + this.bleedMargin) < 0) {
						const excessWidth = rotatedBoundingBox.x + this.bleedMargin;
						newXAxis -= excessWidth;
					}
					if ((rotatedBoundingBox.y + this.bleedMargin) < 0) {
						const excessHeight = rotatedBoundingBox.y + this.bleedMargin;
						newYAxis -= excessHeight;
					}
					if ((rotatedBoundingBox.x + this.bleedMargin + rotatedBoundingBox.width) > (this.pageModel.width + (this.bleedMargin * 2))) {
						newWidth = this.selectedObjectForEdition.width;

						if (
							aspectRatio
							&& (
								this.objectResizeSide === 'top-left'
								|| this.objectResizeSide === 'top-right'
								|| this.objectResizeSide === 'bottom-left'
								|| this.objectResizeSide === 'bottom-right'
							)
						) {
							newHeight = this.selectedObjectForEdition.height;
						}
					}
					if ((rotatedBoundingBox.y + this.bleedMargin + rotatedBoundingBox.height) > (this.pageModel.height + (this.bleedMargin * 2))) {
						newHeight = this.selectedObjectForEdition.height;

						if (
							aspectRatio
							&& (
								this.objectResizeSide === 'top-left'
								|| this.objectResizeSide === 'top-right'
								|| this.objectResizeSide === 'bottom-left'
								|| this.objectResizeSide === 'bottom-right'
							)
						) {
							newWidth = this.selectedObjectForEdition.width;
						}
					}
				}
			} else {
				if ((newXAxis + this.bleedMargin) < 0) {
					const excessWidth = newXAxis + this.bleedMargin;
					newXAxis -= excessWidth;

					if (
						this.objectResizeSide === 'left'
						|| this.objectResizeSide === 'top-left'
						|| this.objectResizeSide === 'bottom-left'
					) {
						newWidth += excessWidth;

						if (aspectRatio) {
							newHeight = newWidth / objectSizeRatio;

							if (this.objectResizeSide === 'top-left') {
								newYAxis = (this.objectInitialSize.y_axis / this.scaling) + (this.objectInitialSize.height / this.scaling) - newHeight;

								if (this.showBleed) {
									newYAxis -= this.bleedMargin;
								}
							}
						}
					}
				}
				if ((newYAxis + this.bleedMargin) < 0) {
					const excessHeight = newYAxis + this.bleedMargin;
					newYAxis -= excessHeight;

					if (
						this.objectResizeSide === 'top-left'
						|| this.objectResizeSide === 'top'
						|| this.objectResizeSide === 'top-right'
					) {
						newHeight += excessHeight;

						if (aspectRatio) {
							newWidth = newHeight * objectSizeRatio;

							if (this.objectResizeSide === 'top-left') {
								newXAxis = (this.objectInitialSize.x_axis / this.scaling) + (this.objectInitialSize.width / this.scaling) - newWidth;

								if (this.showBleed) {
									newXAxis -= this.bleedMargin;
								}
							}
						}
					}
				}
				if ((newXAxis + this.bleedMargin + newWidth) > (this.pageModel.width + (this.bleedMargin * 2))) {
					const excessWidth = (newXAxis + this.bleedMargin + newWidth) - (this.pageModel.width + (this.bleedMargin * 2));
					newWidth -= excessWidth;

					if (
						aspectRatio
						&& (
							this.objectResizeSide === 'top-left'
							|| this.objectResizeSide === 'top-right'
							|| this.objectResizeSide === 'bottom-left'
							|| this.objectResizeSide === 'bottom-right'
						)
					) {
						newHeight = newWidth / objectSizeRatio;
					}
				}
				if ((newYAxis + this.bleedMargin + newHeight) > (this.pageModel.height + (this.bleedMargin * 2))) {
					const excessHeight = (newYAxis + this.bleedMargin + newHeight) - (this.pageModel.height + (this.bleedMargin * 2));
					newHeight -= excessHeight;

					if (
						aspectRatio
						&& (
							this.objectResizeSide === 'top-left'
							|| this.objectResizeSide === 'top-right'
							|| this.objectResizeSide === 'bottom-left'
							|| this.objectResizeSide === 'bottom-right'
						)
					) {
						newWidth = newHeight * objectSizeRatio;
					}
				}
			}

			const partialObject: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'> = {
				id: this.selectedObjectForEdition.id,
			};
			let widthChanged = false;
			let heightChanged = false;

			if (this.selectedObjectForEdition.width !== newWidth) {
				widthChanged = true;
				partialObject.width = newWidth;
			}
			if (this.selectedObjectForEdition.height !== newHeight) {
				heightChanged = true;
				partialObject.height = newHeight;
			}

			if (
				widthChanged
				|| heightChanged
			) {
				partialObject.x_axis = newXAxis;
				partialObject.y_axis = newYAxis;

				if (partialObject.x_axis === this.selectedObjectForEdition.x_axis) {
					delete partialObject.x_axis;
				}
				if (partialObject.y_axis === this.selectedObjectForEdition.y_axis) {
					delete partialObject.y_axis;
				}

				if (
					this.selectedObjectForEdition.type === 'photo'
					&& (
						this.objectResizeSide === 'left'
						|| this.objectResizeSide === 'right'
					)
				) {
					const cropRatio = this.selectedObjectForEdition.cropwidth / this.selectedObjectForEdition.width;
					partialObject.cropwidth = newWidth * cropRatio;

					if (this.objectResizeSide === 'left') {
						const unusedRightSpace = this.selectedObjectForEdition.maxwidth - this.selectedObjectForEdition.cropx - this.selectedObjectForEdition.cropwidth;
						partialObject.cropx = Math.max(
							this.selectedObjectForEdition.maxwidth - unusedRightSpace - partialObject.cropwidth,
							0,
						);
					}

					const newCropX = partialObject.cropx ?? this.selectedObjectForEdition.cropx;

					if ((newCropX + partialObject.cropwidth) > this.selectedObjectForEdition.maxwidth) {
						partialObject.cropwidth = this.selectedObjectForEdition.maxwidth - newCropX;
						partialObject.width = partialObject.cropwidth / cropRatio;

						if (rotate) {
							const cornerPositionFixed = this.fixCornerPosition(
								{
									...this.objectInitialSize,
									height: newHeight * this.scaling,
									width: partialObject.width * this.scaling,
								},
								this.objectInitialSize,
								this.objectResizeSide,
							);
							partialObject.x_axis = cornerPositionFixed.x_axis / this.scaling;
							partialObject.y_axis = cornerPositionFixed.y_axis / this.scaling;

							if (this.showBleed) {
								partialObject.x_axis -= this.bleedMargin;
								partialObject.y_axis -= this.bleedMargin;
							}
						}
					}
				}

				if (
					this.selectedObjectForEdition.type === 'photo'
					&& (
						this.objectResizeSide === 'bottom'
						|| this.objectResizeSide === 'top'
					)
				) {
					const cropRatio = this.selectedObjectForEdition.cropheight / this.selectedObjectForEdition.height;
					partialObject.cropheight = newHeight * cropRatio;

					if (this.objectResizeSide === 'top') {
						const unusedBottomSpace = this.selectedObjectForEdition.maxheight - this.selectedObjectForEdition.cropy - this.selectedObjectForEdition.cropheight;
						partialObject.cropy = Math.max(
							this.selectedObjectForEdition.maxheight - unusedBottomSpace - partialObject.cropheight,
							0,
						);
					}

					const newCropY = partialObject.cropy ?? this.selectedObjectForEdition.cropy;

					if ((newCropY + partialObject.cropheight) > this.selectedObjectForEdition.maxheight) {
						partialObject.cropheight = this.selectedObjectForEdition.maxheight - newCropY;
						partialObject.height = partialObject.cropheight / cropRatio;

						if (rotate) {
							const cornerPositionFixed = this.fixCornerPosition(
								{
									...this.objectInitialSize,
									height: partialObject.height * this.scaling,
									width: newWidth * this.scaling,
								},
								this.objectInitialSize,
								this.objectResizeSide,
							);
							partialObject.x_axis = cornerPositionFixed.x_axis / this.scaling;
							partialObject.y_axis = cornerPositionFixed.y_axis / this.scaling;

							if (this.showBleed) {
								partialObject.x_axis -= this.bleedMargin;
								partialObject.y_axis -= this.bleedMargin;
							}
						}
					}
				}

				if (this.selectedObjectForEdition.fontface) {
					const fontModel = FontModule.getById(this.selectedObjectForEdition.fontface);

					if (fontModel) {
						const fontSizeUp = resizeObjectText(
							{
								...this.selectedObjectForEdition,
								...partialObject,
							},
							fontModel,
							{
								resizeFont: {
									up: 300,
									down: 0,
								},
							},
						);
						const fontSizeDown = resizeObjectText(
							{
								...this.selectedObjectForEdition,
								...partialObject,
							},
							fontModel,
							{
								resizeFont: {
									up: 0,
									down: this.offeringModel?.minfontsize || 12,
								},
							},
						);

						if (
							fontSizeUp.pointsize
							&& this.selectedObjectForEdition.pointsize !== fontSizeUp.pointsize
						) {
							partialObject.pointsize = fontSizeUp.pointsize;
						} else if (
							fontSizeDown.pointsize
							&& this.selectedObjectForEdition.pointsize !== fontSizeDown.pointsize
						) {
							partialObject.pointsize = fontSizeDown.pointsize;
						}

						if (this.selectedObjectForEdition.text_svg) {
							partialObject._image = null;
						}
					}
				}

				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[partialObject.id]: [],
				};
				const objectDifferences = Object.keys(partialObject) as (keyof EditorInteractivePageObjectModel)[];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					if (objectDifference !== 'id') {
						objectsDifferences[partialObject.id].push(objectDifference);
						this.selectedObjectForEdition[objectDifference] = partialObject[objectDifference];
					}
				}

				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
			}
		} else if (
			this.objectRotateMouseDown
			&& this.selectedObjectForEdition
			&& !Array.isArray(finalEvent)
		) {
			event.preventDefault();
			const angleRadians = (
				Math.atan2(
					finalEvent.clientY - this.objectEditorContentRotateCenterY,
					finalEvent.clientX - this.objectEditorContentRotateCenterX,
				)
				- Math.atan2(
					this.objectEditorContentY - this.objectEditorContentRotateCenterY,
					this.objectEditorContentX - this.objectEditorContentRotateCenterX,
				)
			);
			const angleDegrees = angleRadians * (180 / Math.PI);

			let newRotate = this.selectedObjectForEdition.rotate + angleDegrees;

			if (newRotate > 360) {
				newRotate -= 360;
			} else if (newRotate < 0) {
				newRotate += 360;
			}

			const partialObject: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'> = {
				id: this.selectedObjectForEdition.id,
			};

			if (this.selectedObjectForEdition.rotate !== newRotate) {
				partialObject.rotate = newRotate;
				this.selectedObjectForEdition.rotate = partialObject.rotate;
				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[partialObject.id]: ['rotate'],
				};
				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
				this.objectEditorContentX = finalEvent.clientX;
				this.objectEditorContentY = finalEvent.clientY;
			}
		} else if (
			this.objectCropMoveMouseDown
			&& this.selectedObjectForEdition
			&& !Array.isArray(finalEvent)
		) {
			event.preventDefault();
			const startPosition = {
				x: this.objectEditorContentX + document.body.scrollLeft,
				y: this.objectEditorContentY + document.body.scrollTop,
			};
			const cropHeightRatio = this.selectedObjectForEdition.cropheight / this.selectedObjectForEdition.height;
			const cropWidthRatio = this.selectedObjectForEdition.cropwidth / this.selectedObjectForEdition.width;
			const xDifference = (finalEvent.clientX + document.body.scrollLeft - startPosition.x) / this.zoomLevel * cropWidthRatio / this.scaling;
			const yDifference = (finalEvent.clientY + document.body.scrollTop - startPosition.y) / this.zoomLevel * cropHeightRatio / this.scaling;

			let newCropX = this.objectInitialSize.cropx - xDifference;
			let newCropY = this.objectInitialSize.cropy - yDifference;

			if (this.selectedObjectForEdition.rotate) {
				const angle = this.selectedObjectForEdition.rotate * (Math.PI / 180);
				const cosAngle = Math.cos(angle);
				const sinAngle = Math.sin(angle);

				// Calculate the center of the cropping area
				const cropCenterX = this.objectInitialSize.cropx + (this.objectInitialSize.cropwidth / 2);
				const cropCenterY = this.objectInitialSize.cropy + (this.objectInitialSize.cropheight / 2);

				// Translate the crop center to the origin
				const translatedX = this.objectInitialSize.cropx - cropCenterX;
				const translatedY = this.objectInitialSize.cropy - cropCenterY;

				// Rotate the point around the center
				let rotatedCropX = translatedX * cosAngle - translatedY * sinAngle + cropCenterX;
				let rotatedCropY = translatedX * sinAngle + translatedY * cosAngle + cropCenterY;

				// Add the difference to the rotated points
				rotatedCropX -= xDifference;
				rotatedCropY -= yDifference;

				// Translate back to the original position
				const translatedBackX = rotatedCropX - cropCenterX;
				const translatedBackY = rotatedCropY - cropCenterY;

				newCropX = translatedBackX * cosAngle + translatedBackY * sinAngle + cropCenterX;
				newCropY = translatedBackY * cosAngle - translatedBackX * sinAngle + cropCenterY;
			}

			if (newCropX < 0) {
				newCropX = 0;
			} else if ((newCropX + this.selectedObjectForEdition.cropwidth) > this.selectedObjectForEdition.maxwidth) {
				newCropX = this.selectedObjectForEdition.maxwidth - this.selectedObjectForEdition.cropwidth;
			}

			if (newCropY < 0) {
				newCropY = 0;
			} else if ((newCropY + this.selectedObjectForEdition.cropheight) > this.selectedObjectForEdition.maxheight) {
				newCropY = this.selectedObjectForEdition.maxheight - this.selectedObjectForEdition.cropheight;
			}

			const partialObject: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'> = {
				id: this.selectedObjectForEdition.id,
			};
			let cropXChanged = false;
			let cropYChanged = false;

			if (this.selectedObjectForEdition.cropx !== newCropX) {
				partialObject.cropx = newCropX;
				cropXChanged = true;
			}

			if (this.selectedObjectForEdition.cropy !== newCropY) {
				partialObject.cropy = newCropY;
				cropYChanged = true;
			}

			if (
				cropXChanged
				|| cropYChanged
			) {
				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[this.selectedObjectForEdition.id]: [],
				};
				const objectDifferences = Object.keys(partialObject) as (keyof EditorInteractivePageObjectModel)[];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					if (objectDifference !== 'id') {
						objectsDifferences[this.selectedObjectForEdition.id].push(objectDifference);
						this.selectedObjectForEdition[objectDifference] = partialObject[objectDifference];
					}
				}

				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
			}
		} else if (
			this.selectedObjectForEdition
			&& Array.isArray(finalEvent)
		) {
			event.preventDefault();
			const pinchDifference = Math.hypot(
				finalEvent[1].clientX - finalEvent[0].clientX,
				finalEvent[1].clientY - finalEvent[0].clientY,
			);
			const initialPinchDifference = Math.hypot(
				this.objectEditorContentX || 0,
				this.objectEditorContentY || 0,
			);
			const difference = (pinchDifference - initialPinchDifference) / this.zoomLevel / this.scaling;

			const objectSizeRatio = this.objectInitialSize.width / this.objectInitialSize.height;
			let newWidth = this.objectInitialSize.width + difference;
			let newHeight = newWidth / objectSizeRatio;

			if (newWidth > (this.pageModel.width + (this.bleedMargin * 2))) {
				newWidth = this.pageModel.width + (this.bleedMargin * 2);
				newHeight = newWidth / objectSizeRatio;
			} else if (newHeight > (this.pageModel.height + (this.bleedMargin * 2))) {
				newHeight = this.pageModel.height + (this.bleedMargin * 2);
				newWidth = newHeight * objectSizeRatio;
			} else if (newWidth < 0) {
				newWidth = 0;
				newHeight = 0;
			} else if (newHeight < 0) {
				newHeight = 0;
				newWidth = 0;
			}

			const partialObject: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'> = {
				id: this.selectedObjectForEdition.id,
			};
			let widthChanged = false;
			let heightChanged = false;

			if (this.selectedObjectForEdition.width !== newWidth) {
				widthChanged = true;
				partialObject.width = newWidth;
			}
			if (this.selectedObjectForEdition.height !== newHeight) {
				heightChanged = true;
				partialObject.height = newHeight;
			}

			if (typeof partialObject.width !== 'undefined') {
				if (
					partialObject.width < minSizeLimit
					&& this.selectedObjectForEdition.width > minSizeLimit
				) {
					partialObject.width = minSizeLimit;
					partialObject.height = minSizeLimit / objectSizeRatio;
				} else if (partialObject.width < minSizeLimit) {
					widthChanged = false;
					heightChanged = false;
					delete partialObject.width;
					delete partialObject.height;
					delete partialObject.x_axis;
					delete partialObject.y_axis;
				}
			}
			if (typeof partialObject.height !== 'undefined') {
				if (
					partialObject.height < minSizeLimit
					&& this.selectedObjectForEdition.height > minSizeLimit
				) {
					partialObject.height = minSizeLimit;
					partialObject.width = minSizeLimit * objectSizeRatio;
				} else if (partialObject.height < minSizeLimit) {
					heightChanged = false;
					widthChanged = false;
					delete partialObject.height;
					delete partialObject.width;
					delete partialObject.x_axis;
					delete partialObject.y_axis;
				}
			}

			if (
				widthChanged
				|| heightChanged
			) {
				if (partialObject.width) {
					const widthDifference = partialObject.width - this.selectedObjectForEdition.width;
					partialObject.x_axis = this.selectedObjectForEdition.x_axis - (widthDifference / 2);

					if ((partialObject.x_axis + this.bleedMargin) < 0) {
						partialObject.x_axis = -this.bleedMargin;
					} else if ((partialObject.x_axis + this.bleedMargin + partialObject.width) > (this.pageModel.width + (this.bleedMargin * 2))) {
						partialObject.x_axis = (this.pageModel.width + this.bleedMargin) - partialObject.width;
					}
				}
				if (partialObject.height) {
					const heightDifference = partialObject.height - this.selectedObjectForEdition.height;
					partialObject.y_axis = this.selectedObjectForEdition.y_axis - (heightDifference / 2);

					if ((partialObject.y_axis + this.bleedMargin) < 0) {
						partialObject.y_axis = -this.bleedMargin;
					} else if ((partialObject.y_axis + this.bleedMargin + partialObject.height) > (this.pageModel.height + (this.bleedMargin * 2))) {
						partialObject.y_axis = (this.pageModel.height + this.bleedMargin) - partialObject.height;
					}
				}

				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[partialObject.id]: [],
				};
				const objectDifferences = Object.keys(partialObject) as (keyof EditorInteractivePageObjectModel)[];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					if (objectDifference !== 'id') {
						objectsDifferences[partialObject.id].push(objectDifference);
						this.selectedObjectForEdition[objectDifference] = partialObject[objectDifference];
					}
				}

				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
			}
		}
	}

	private onWindowMouseUp(): void {
		if (
			this.objectContentMouseDown
			|| this.objectCropMoveMouseDown
			|| this.objectResizeMouseDown
			|| this.objectRotateMouseDown
		) {
			this.isPinch = false;
			this.objectContentMouseDown = false;
			this.objectCropMoveMouseDown = false;
			this.objectResizeMouseDown = false;
			this.objectRotateMouseDown = false;
			this.objectEditorContentRotateCenterX = 0;
			this.objectEditorContentRotateCenterY = 0;
			this.objectEditorContentX = 0;
			this.objectEditorContentY = 0;
			this.objectResizeSide = undefined;
			document.body.style.cursor = '';

			if (this.selectedObjectForEdition) {
				const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
					[this.selectedObjectForEdition.id]: [
						'_crop',
					],
				};
				this.selectedObjectForEdition._crop = false;
				this.$emit(
					'change',
					this.internalPageObjects,
					objectsDifferences,
				);
			}

			window.removeEventListener(
				'mousemove',
				this.onWindowMouseMove,
			);
			window.removeEventListener(
				'touchmove',
				this.onWindowMouseMove,
			);
			window.removeEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.removeEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
			this.$emit('push-changes');
		}
	}

	private openEditorTextOptionsTooltip(
		editObjectElement: HTMLElement,
		objectModel: EditorInteractivePageObjectModel,
	): void {
		if (!this.isMobile) {
			this.destroyEditorTextOptionsTooltips(objectModel.id);

			if (!this.editorTextOptionsTooltips[objectModel.id]) {
				this.editorTextOptionsTooltips[objectModel.id] = this.$openTooltip({
					anchor: editObjectElement,
					beforeClose: (event: ServiceEvent<any>) => {
						if (
							(
								this.selectedTextObjectModel
								|| this.selectedObjectForEdition?.type === 'text'
							)
							&& !this.isMobile
						) {
							event.preventDefault();
						}
					},
					body: {
						component: EditorTextOptionsView,
						props: {
							areColorsLimitedToChooseFrom: this.areTextColorsLimitedToChooseFrom,
							canvas: this.computedCanvas,
							chooseFrom: this.textColorsChooseFrom,
							fontModels: this.fontModels,
							objectModel,
							offeringModel: this.offeringModel,
						},
						listeners: {
							change: this.onEditorTextOptionsChange,
							'eye-dropper-end': this.onEyeDropperEnd,
							'eye-dropper-start': this.onEyeDropperStart,
						},
					},
					hasCloseButton: false,
					distance: 24,
					isModal: false,
					listeners: {
						close: () => {
							delete this.editorTextOptionsTooltips[objectModel.id];
						},
					},
					theme: this.internalTheme,
				});
			}
		} else {
			this.$nextTick(() => {
				this.computedTextOptionsAnchor?.append(this.editorTextOptionsViewComponent.$el);
			});
		}
	}

	private removeWindowClickHandler(objectModel: EditorInteractivePageObjectModel): void {
		const handler = this.windowClickHandlers[objectModel.id];

		if (handler) {
			window.removeEventListener(
				'click',
				handler,
			);
			delete this.windowClickHandlers[objectModel.id];
		}
	}

	private removeWindowTouchHandler(objectModel: EditorInteractivePageObjectModel): void {
		const handler = this.windowTouchHandlers[objectModel.id];

		if (handler) {
			window.removeEventListener(
				'touchstart',
				handler,
			);
			window.removeEventListener(
				'touchend',
				handler,
			);
			delete this.windowTouchHandlers[objectModel.id];
		}
	}

	private rotateObjectModel(
		objectInitialPosition: EditorInteractivePageObjectInitialPosition,
		centerX: number,
		centerY: number,
	): EditorInteractivePageObjectInitialPosition {
		/* eslint-disable camelcase */
		const {
			x_axis,
			y_axis,
			rotate,
		} = objectInitialPosition;
		const radians = (Math.PI / 180) * rotate;
		const cos = Math.cos(radians);
		const sin = Math.sin(radians);
		const newXAxis = cos * (x_axis - centerX) + sin * (y_axis - centerY) + centerX;
		const newYAxis = cos * (y_axis - centerY) - sin * (x_axis - centerX) + centerY;
		/* eslint-enable camelcase */

		return {
			...objectInitialPosition,
			x_axis: newXAxis,
			y_axis: newYAxis,
		};
	}

	private rotatePointInRectangle(
		px: number,
		py: number,
		objectModel: EditorInteractivePageObjectModel,
	) {
		// Calculate the center of the rectangle
		const cx = objectModel.width / 2;
		const cy = objectModel.height / 2;

		// Translate the point to the origin
		const translatedX = px - cx;
		const translatedY = py - cy;

		// Convert the angle to radians
		const radians = objectModel.rotate * (Math.PI / 180);

		// Apply the rotation
		const rotatedX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
		const rotatedY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

		// Calculate the rotated bounding box
		const objectRotatedBoundingBox = this.calculateRotatedBoundingBox(objectModel);

		// Calculate the new center of the bounding box
		const newCx = objectRotatedBoundingBox.width / 2;
		const newCy = objectRotatedBoundingBox.height / 2;

		// Translate the point back to the new center
		const newX = rotatedX + newCx;
		const newY = rotatedY + newCy;

		return {
			x: newX,
			y: newY,
		};
	}
}
