import './defines';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';

import Template from './template.vue';

@Component({
	name: 'ProgressBar',
})
export default class ProgressBar extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Number,
	})
	public readonly progressPercentage!: number;

	@Prop({
		required: true,
		type: String,
	})
	public readonly text!: string;
}
