import './defines';
import { HorizontalScrollDirection } from 'interfaces/app';
import { dom as domUtils } from 'utils';
import {
	Component,
	Ref,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'HorizontalArrowScrollComponent',
})
export default class HorizontalArrowScrollComponent extends Vue.extend(Template) {
	protected get shouldShowScrollButton(): (scrollButton: HorizontalScrollDirection) => boolean {
		return (scrollButton) => {
			const rootElement = this.$el as HTMLElement | undefined;

			if (
				!rootElement
				|| !domUtils.isFullyVisible(rootElement)
			) {
				return false;
			}

			const defaultSlotElement = this.horizontalArrowScrollComponentContainerElement?.firstChild as HTMLElement | undefined;

			if (!defaultSlotElement) {
				return false;
			}

			const isFullyVisibleResult = domUtils.isFullyVisible(defaultSlotElement);

			if (scrollButton === 'left') {
				return !isFullyVisibleResult.isLeftVisible;
			}

			return !isFullyVisibleResult.isRightVisible;
		};
	}

	@Ref('horizontalArrowScrollComponentContainer')
	private horizontalArrowScrollComponentContainerElement?: HTMLDivElement;

	private intersectionObserver?: IntersectionObserver;

	private isMouseDown?: boolean;

	private mouseDownInterval?: NodeJS.Timeout;

	private mouseDownTimeout?: NodeJS.Timeout;

	private wasMouseDown?: boolean;

	protected beforeDestroy(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.intersectionObserver?.disconnect();

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}
	}

	protected mounted(): void {
		const rootElement = this.$el as HTMLElement | undefined;

		if (rootElement) {
			this.intersectionObserver = new IntersectionObserver(() => {
				if (domUtils.isFullyVisible(rootElement)) {
					this.$forceCompute('shouldShowScrollButton');
				}
			});
			this.intersectionObserver.observe(rootElement);
		}

		this.onScroll();
	}

	protected onScrollButtonClick(scrollButton: HorizontalScrollDirection): void {
		if (this.wasMouseDown) {
			this.wasMouseDown = false;
			return;
		}

		const rootElement = this.$el as HTMLElement;
		const { horizontalArrowScrollComponentContainerElement } = this;

		if (
			!rootElement
			|| !horizontalArrowScrollComponentContainerElement
		) {
			return;
		}

		const rootElementWidth = rootElement.clientWidth;
		let horizontalArrowScrollComponentContainerElementScrollLeft = horizontalArrowScrollComponentContainerElement.scrollLeft;

		if (scrollButton === 'left') {
			horizontalArrowScrollComponentContainerElementScrollLeft -= rootElementWidth / 2;
		} else {
			horizontalArrowScrollComponentContainerElementScrollLeft += rootElementWidth / 2;
		}

		horizontalArrowScrollComponentContainerElement.scrollTo({
			behavior: 'smooth',
			left: horizontalArrowScrollComponentContainerElementScrollLeft,
		});
	}

	protected onScrollButtonMouseDown(scrollButton: HorizontalScrollDirection): void {
		this.isMouseDown = true;
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
		}

		this.mouseDownTimeout = setTimeout(
			() => {
				if (this.mouseDownInterval) {
					clearInterval(this.mouseDownInterval);
				}

				this.mouseDownInterval = setInterval(
					() => {
						if (!this.isMouseDown) {
							clearInterval(this.mouseDownInterval);
							this.mouseDownInterval = undefined;
							return;
						}

						this.$emit('mousepress');

						if (!this.horizontalArrowScrollComponentContainerElement) {
							return;
						}

						this.wasMouseDown = true;
						let horizontalArrowScrollComponentContainerElementScrollLeft = this.horizontalArrowScrollComponentContainerElement.scrollLeft;

						if (scrollButton === 'left') {
							horizontalArrowScrollComponentContainerElementScrollLeft -= 1;
						} else {
							horizontalArrowScrollComponentContainerElementScrollLeft += 1;
						}

						this.horizontalArrowScrollComponentContainerElement.scrollTo({
							left: horizontalArrowScrollComponentContainerElementScrollLeft,
						});
					},
					10,
				);
			},
			500,
		);
	}

	protected onScroll(): void {
		requestAnimationFrame(() => this.$forceCompute('shouldShowScrollButton'));
	}

	private onWindowMouseUp(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.isMouseDown = false;

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}

		requestAnimationFrame(() => {
			this.wasMouseDown = false;
		});
	}
}
