import { AppStateModule } from '../store/index';
import ajax from '../controllers/ajax';

export default (): Promise<void> => new Promise((resolve, reject) => {
	ajax
		.request(
			{
				url: '/api/ping',
				method: 'get',
			},
			{
				auth: false,
				debug: {
					log: false,
				},
			},
		)
		.then(
			() => {
				AppStateModule.setOnline();
				resolve();
			},
			reject,
		);
});
