import {
	Vue,
	Prop,
	Component,
	Watch,
} from 'vue-property-decorator';
import _ from 'underscore';
import {
	TemplateSet,
	TemplateTextPosition,
	TemplatePhotoPosition,
	FontModel,
} from 'interfaces/app';
import breakText from 'tools/break-text';
import { FontModule } from 'store';
import maskPresets from 'settings/masks';
import loadImage from 'services/load-image';
import Template from './template.vue';

@Component
export default class TemplatePickerItemView extends Vue.extend(Template) {
	@Prop({
		description: 'Template definition',
		required: true,
		schema: 'TemplateSet',
		type: Object,
	})
	public readonly templateSet!: TemplateSet;

	@Prop({
		description: 'Height of the page the template is for',
		required: true,
		type: Number,
	})
	public readonly pageHeight!: number;

	@Prop({
		description: 'Width of the page the template is for',
		required: true,
		type: Number,
	})
	public readonly pageWidth!: number;

	@Prop({
		description: 'Maximum width of the canvas to draw',
		required: false,
		type: Number,
		default: 100,
	})
	public readonly maxWidth!: number;

	@Prop({
		description: 'Maximum height of the canvas to draw',
		required: false,
		type: Number,
		default: 200,
	})
	public readonly maxHeight!: number;

	@Prop({
		description: 'Background color for the page',
		required: false,
		type: String,
		default: '#f1efeb',
	})
	public readonly pageBackgroundColor!: string;

	@Prop({
		description: 'Color of the photo position rectangles',
		required: false,
		type: String,
		default: 'rgba(93, 197, 227, 0.5)',
	})
	public readonly photoPositionColor!: string;

	@Prop({
		description: 'Text to display on the template',
		required: true,
		type: String,
	})
	public readonly defaultText!: string;

	@Prop({
		description: 'Color of the text',
		required: false,
		type: String,
		default: '#000000',
	})
	public readonly textColor!: string;

	private get scale(): number {
		return Math.max(
			this.pageWidth / this.maxWidth,
			this.pageHeight / this.maxHeight,
		);
	}

	protected get height(): number {
		return this.pageHeight / this.scale;
	}

	protected get width(): number {
		return this.pageWidth / this.scale;
	}

	protected mounted(): void {
		_.defer(this.drawTemplateToCanvas);
	}

	@Watch('width')
	private drawTemplateToCanvas() {
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		const context = canvas?.getContext('2d');

		if (context) {
			// First we clear the canvas to remove any previous drawings
			context.clearRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);

			// Draw the background color
			context.fillStyle = this.pageBackgroundColor;
			context.fillRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);

			// Draw all template positions to the canvas
			this.templateSet.positions.forEach((positionModel) => {
				this.drawPosition(
					context,
					positionModel,
				);
			});
		}
	}

	private loadPhotoPositionMask(
		positionModel: TemplatePhotoPosition,
	): Promise<HTMLImageElement | null> {
		if (!positionModel.mask) {
			return Promise.resolve(
				null,
			);
		}

		// Load the mask image and return it
		return loadImage(
			maskPresets[positionModel.mask].src,
		).then(({ image }) => image);
	}

	private loadTextPositionFont(
		positionModel: TemplateTextPosition,
	): Promise<FontModel> {
		// Load the font set to the text position
		return FontModule.loadModel(
			positionModel.fontface,
		);
	}

	private drawPosition(
		context: CanvasRenderingContext2D,
		positionModel: TemplatePhotoPosition | TemplateTextPosition,
	): Promise<void> {
		if (positionModel.type == 'photo') {
			// First load the mask image (if the position has any one assigned to it)
			// Then draw the photo position on the canvas
			return this.loadPhotoPositionMask(
				positionModel,
			).then((maskImage) => this.drawPhotoPosition(
				context,
				positionModel,
				maskImage,
			));
		}

		if (positionModel.type == 'text') {
			// First load the font set to the text position
			// Then draw the text position on the canvas
			return this.loadTextPositionFont(
				positionModel,
			).then((fontModel) => this.drawTextPosition(
				context,
				positionModel,
				fontModel,
			));
		}

		return Promise.resolve();
	}

	private drawPhotoPosition(
		context: CanvasRenderingContext2D,
		positionModel: TemplatePhotoPosition,
		maskImage: HTMLImageElement | null,
	): void {
		// First we scale the canvas relative to the size of the template and the canvas
		// so that we can draw directly with the position size properties
		context.scale(
			1 / this.scale,
			1 / this.scale,
		);

		// Save the context so we can restore it after drawing the position
		context.save();

		if (maskImage) {
			// If the photo position has a mask assigned to it,
			// we need to draw the mask on a temporary canvas
			// and then use it for drawing the template position
			const tempCanvas = document.createElement('canvas');
			tempCanvas.width = positionModel.width;
			tempCanvas.height = positionModel.height;
			const tempContext = tempCanvas.getContext('2d');
			if (tempContext) {
				tempContext.fillStyle = this.photoPositionColor;
				tempContext.fillRect(
					0,
					0,
					positionModel.width,
					positionModel.height,
				);

				tempContext.globalCompositeOperation = 'destination-in';

				tempContext.drawImage(
					maskImage,
					0,
					0,
					positionModel.width,
					positionModel.height,
				);

				context.drawImage(
					tempCanvas,
					positionModel.x,
					positionModel.y,
					positionModel.width,
					positionModel.height,
				);
			}
		} else {
			context.fillStyle = this.photoPositionColor;
			context.fillRect(
				positionModel.x,
				positionModel.y,
				positionModel.width,
				positionModel.height,
			);

			// Add a border around the position so we make sure there's enough contrast to see it
			context.lineWidth = 2;
			context.strokeStyle = this.pageBackgroundColor;

			context.strokeRect(
				positionModel.x + 1,
				positionModel.y + 1,
				positionModel.width - 2,
				positionModel.height - 2,
			);
		}

		// Restore the context to the original state
		context.restore();

		// Scale back the canvas to the original size
		context.scale(
			this.scale,
			this.scale,
		);
	}

	private drawTextPosition(
		context: CanvasRenderingContext2D,
		positionModel: TemplateTextPosition,
		fontModel: FontModel,
	): void {
		// First we scale the canvas relative to the size of the template and the canvas
		// so that we can draw directly with the position size properties
		context.scale(
			1 / this.scale,
			1 / this.scale,
		);

		// Save the context so we can restore it after drawing the position
		context.save();

		// Break the text into lines that fit the position
		const subset = fontModel.subset.split(',');
		const response = breakText({
			phrase: this.defaultText,
			maxPxLength: positionModel.width,
			maxPxHeight: positionModel.height,
			fontface: positionModel.fontface,
			bold: positionModel.fontbold,
			italic: positionModel.fontitalic,
			pointsize: positionModel.pointsize,
			resize: {
				up: positionModel.pointsize,
				down: positionModel.pointsize,
			},
			subset,
		});
		if (response) {
			const pxsize = positionModel.pointsize * 31496 / 15120;
			const lineheight = pxsize * 1.25;
			let indentAlign = 0;

			switch (positionModel.align) {
				case 'Right':
					context.textAlign = 'right';
					indentAlign = positionModel.width;
					break;
				case 'Center':
					context.textAlign = 'center';
					indentAlign = positionModel.width / 2;
					break;
				case 'Left':
				default:
					context.textAlign = 'left';
					break;
			}
			context.textBaseline = 'middle';
			context.fillStyle = this.textColor;

			if (fontModel.url) {
				if (positionModel.fontitalic && positionModel.fontbold && fontModel.bolditalic) {
					context.font = `${pxsize}px ${fontModel.id} BoldItalic`;
				} else if (positionModel.fontitalic && fontModel.italic) {
					context.font = `${pxsize}px ${fontModel.id} Italic`;
				} else if (positionModel.fontbold && fontModel.bold) {
					context.font = `${pxsize}px ${fontModel.id} Bold`;
				} else {
					context.font = `${pxsize}px ${fontModel.id}`;
				}
			} else if (positionModel.fontitalic && positionModel.fontbold && fontModel.bolditalic) {
				context.font = `italic bold ${pxsize}px ${fontModel.id}`;
			} else if (positionModel.fontitalic && fontModel.italic) {
				context.font = `italic ${pxsize}px ${fontModel.id}`;
			} else if (positionModel.fontbold && fontModel.bold) {
				context.font = `bold ${pxsize}px ${fontModel.id}`;
			} else {
				context.font = `${pxsize}px ${fontModel.id}`;
			}

			// Write lines of text on canvas
			response.text_formatted.split('\n').forEach((text, linenr) => {
				context.fillText(
					text,
					positionModel.x + indentAlign,
					positionModel.y + ((linenr + 0.5) * lineheight),
				);
			});
		}

		// Restore the context to the original state
		context.restore();

		// Scale back the canvas to the original size
		context.scale(
			this.scale,
			this.scale,
		);
	}

	protected selectTemplate() {
		this.$emit(
			'selectTemplate',
			this.templateSet.id,
		);
	}
}
