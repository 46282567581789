export default function filterText(
	text: string,
	textEncodingSupport: string[],
): string {
	if (
		typeof text === 'undefined'
		|| !text
	) {
		return '';
	}

	// Replace left- and right quotation marks to straight quotation marks
	text = text.replace(
		'’',
		'\'',
	);
	text = text.replace(
		'‘',
		'\'',
	);
	text = text.replace(
		'“',
		'"',
	);
	text = text.replace(
		'”',
		'"',
	);

	// Replace line break
	text = text.replace(
		'\u2028',
		'\n',
	);

	// Start filter string
	// Check unicode ranges at http://jrgraphix.net/research/unicode_blocks.php
	let filter = '[^\u000A-\u000D,^\u0085,^\u2028-\u2029,^\\n,';

	if (textEncodingSupport.indexOf('latin') != -1) {
		// Basic latin:			0020 — 007F
		// Latin-1 Supplement:	00A0 — 00FF
		// Latin Extended-A:	0100 — 017F
		// Latin Extended-B:	0180 — 024F
		filter += '^\u0020-\u0250,';
	}

	if (textEncodingSupport.indexOf('cyrillic') != -1) {
		filter += '^\u0400-\u0500,';
	}

	// Remove last comma
	filter = filter.substring(
		0,
		filter.length - 1,
	);

	// Close filter string
	filter += ']';

	// Build regular expression
	const regexp = new RegExp(
		filter,
		'g',
	);

	// Return filtered text
	return String(text).replace(
		regexp,
		'',
	);
}
