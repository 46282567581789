import { callSafe } from './functions';

export type OrientationType = 'landscape' | 'portrait';

export type OrientationWatchCallback = (orientation: OrientationType) => void;

// eslint-disable-next-line import/no-mutable-exports
let orientation: OrientationType = 'portrait';
const orientationMediaQueryList = window.matchMedia('(orientation: portrait)');
const watchers: OrientationWatchCallback[] = [];
const lockOrientationUniversal = (
	window.screen.lockOrientation
	|| window.screen.mozLockOrientation
	|| window.screen.msLockOrientation
);

function onOrientationMediaQueryListChange(): void {
	orientation = (
		orientationMediaQueryList.matches
			? 'portrait'
			: 'landscape'
	);

	// eslint-disable-next-line no-restricted-syntax
	for (const watcher of watchers) {
		// eslint-disable-next-line no-loop-func
		callSafe(() => watcher(orientation));
	}
}

function watch(callback: OrientationWatchCallback): () => void {
	watchers.push(callback);

	return () => {
		const index = watchers.indexOf(callback);

		if (index > -1) {
			watchers.splice(
				index,
				1,
			);
		}
	};
}

function lock(orientationToLock: OrientationType): void {
	if (lockOrientationUniversal) {
		lockOrientationUniversal(orientationToLock);
	} else if (window.screen.orientation.lock) {
		window.screen.orientation.lock(orientationToLock);
	}
}

orientationMediaQueryList.addEventListener(
	'change',
	onOrientationMediaQueryListChange,
);

if (orientationMediaQueryList.matches) {
	orientation = 'portrait';
} else {
	orientation = 'landscape';
}

export {
	orientation,
	lock,
	watch,
};
