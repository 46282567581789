import './defines';
import ButtonComponent from 'components/button';
import InputSliderComponent from 'components/input-slider';
import equal from 'deep-equal';
import {
	EditorBookFillMargin,
	EditorBookFillMargins,
	EditorBookFillPage,
	EditorBookFillPages,
	EditorBookFillPagesMarginsModel,
	EditorBookFillImagePageOrientation,
} from 'interfaces/app';
import { CurrencyModel } from 'interfaces/database';
import { ProductSettings } from 'interfaces/project';
import { mobile as mobileTools } from 'tools';
import PriceView from 'views/price';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import analytics from 'controllers/analytics';
import Template from './template.vue';

@Component({
	name: 'EditorBookFillView',
	components: {
		ButtonComponent,
		InputSliderComponent,
		PriceView,
	},
})

export default class EditorBookFillView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the selected page and margin model',
			type: Object,
			default: () => ({
				pages: undefined,
				margins: undefined,
			}),
			schema: 'EditorBookFillPagesMarginsModel',
		},
	)
	public readonly value!: EditorBookFillPagesMarginsModel;

	@Prop({
		description: 'Defines the currency model',
		required: true,
		schema: 'CurrencyModel',
		type: Object,
	})
	public readonly currencyModel!: CurrencyModel;

	@Prop({
		required: true,
		type: Array,
		schema: 'EditorBookFillMargins',
		description: 'Defines the available margins options to select from',
	})
	public readonly margins!: EditorBookFillMargins;

	@Prop({
		acceptedValues: [
			'landscape',
			'portrait',
			'square',
		],
		description: 'Defines the orientation of the images to show',
		required: true,
		type: String,
	})
	public readonly orientation!: EditorBookFillImagePageOrientation;

	@Prop({
		required: true,
		type: Array,
		schema: 'EditorBookFillPages',
		description: 'Defines the available pages options to select from',
	})
	public readonly pages!: EditorBookFillPages;

	@Prop({
		required: true,
		description: 'Label for the product type',
		type: String,
	})
	public readonly productLabel!: string;

	protected get selectedMargin(): EditorBookFillMargin {
		return (
			this.margins.find((margin) => margin.id === this.internalValue.margins)
			|| this.margins[1]
		);
	}

	protected get selectedPage(): EditorBookFillPage {
		return (
			this.pages.find((page) => page.id === this.internalValue.pages)
			|| this.pages[3]
		);
	}

	protected get selectedPageImage(): string {
		return this.getPagesImage(
			this.selectedPage.id,
		);
	}

	protected get sliderValue(): number {
		return this.pages.indexOf(this.selectedPage);
	}

	private internalValue: EditorBookFillPagesMarginsModel = {
		pages: undefined,
		margins: undefined,
	};

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected stepSelected = 1;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected getMarginImage(
		marginId: ProductSettings['autoFillMarginId'],
	) {
		// eslint-disable-next-line
		return require(`./assets/margins_${this.orientation}_${marginId}.png`).default;
	}

	protected getPagesImage(
		pagesId: EditorBookFillPage['id'],
	) {
		// eslint-disable-next-line
		return require(`./assets/pages_${this.orientation}_${pagesId}.png`).default;
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (
			!equal(
				this.value,
				this.internalValue,
			)
		) {
			this.$nextTick(() => {
				this.internalValue = {
					...this.value,
				};
			});
		}
	}

	protected onApplyClick(): void {
		this.internalValue.pages = this.selectedPage.id;
		this.stepSelected = 2;
	}

	protected onBackClick(): void {
		this.stepSelected = 1;
	}

	protected onCloseClick(): void {
		this.$emit('close');
	}

	protected onMarginClick(id: ProductSettings['autoFillMarginId']): void {
		this.internalValue.margins = this.margins.find((margin) => margin.id === id)?.id;
	}

	protected onSelectClick(): void {
		analytics.trackEvent(
			'Book Fill Options Selected',
			{
				margin: this.internalValue.margins,
				density: this.internalValue.pages,
			},
		);

		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onSliderInput(index: number): void {
		this.internalValue.pages = this.pages[index].id;
	}
}
