import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import EditorPreview2DView from 'views/editor-preview-2d';
import EditorPreview3DView from 'views/editor-preview-3d';
import EditorPreviewCubeView from 'views/editor-preview-cube';
import {
	EditorPreviewModel,
	EditorPreviewPageObjectModels,
	OfferingFrameModel,
} from 'interfaces/app';
import {
	Model2DModel,
	Model3DModel,
	OfferingModel,
	PageModel,
} from 'interfaces/database';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorPreviewView',
	components: {
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		EditorPreview2DView,
		EditorPreview3DView,
		EditorPreviewCubeView,
	},
})
export default class EditorPreviewView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			acceptedValues: [
				'2d',
				'3d',
			],
			default: '3d',
			description: 'Defines the preview mode, 2d or 3d',
			schema: 'EditorPreviewModel',
			type: String,
		},
	)
	public readonly value!: EditorPreviewModel;

	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		default: false,
		description: 'Indicates if the model to preview should be shown as a cube',
		type: Boolean,
	})
	public readonly hasCube!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the 2D model to use for the preview',
		schema: 'Model2DModel',
		type: Object,
	})
	public readonly model2D?: Model2DModel;

	@Prop({
		default: undefined,
		description: 'Defines the 3D model to use for the preview',
		schema: 'Model3DModel',
		type: Object,
	})
	public readonly model3D?: Model3DModel;

	@Prop({
		default: undefined,
		schema: 'OfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		required: true,
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects!: EditorPreviewPageObjectModels;

	protected get hasMultipleModels(): boolean {
		return !!(
			this.model2D
			&& this.model3D
		);
	}

	private internalValue: EditorPreviewModel | null = null;

	protected created(): void {
		if (!this.hasMultipleModels) {
			if (
				this.internalValue === '3d'
				&& !this.model3D
			) {
				this.internalValue = '2d';
			} else if (
				this.internalValue === '2d'
				&& !this.model2D
			) {
				this.internalValue = '3d';
			}
		}
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = this.value;
	}

	protected onPreviewModeChange(value: EditorPreviewModel): void {
		this.internalValue = value;
	}
}
