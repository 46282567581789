import './defines';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class CloneView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: String,
	})
	public readonly productImage!: string;
}
