import './defines';
import PriceCalculator from 'classes/price-calculator';
import ProductState from 'classes/productstate';
import analytics from 'controllers/analytics';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import {
	AppDataModule,
	AppStateModule,
	ProductStateModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import PriceView from '../price';
import Template from './template.vue';

interface SelectOption {
	id: number;
	label: string;
	show: boolean;
	valueModels: DB.OfferingOptionValueModel[];
	valueModel: DB.OfferingOptionValueModel | undefined;
}

@Component({
	components: {
		PriceView,
	},
})
export default class OfferingOptionsView extends Vue.extend(Template) {
	@Prop({
		default: () => [],
		type: Array,
	})
	public readonly filterTags!: DB.OfferingOptionModel['tag'][];

	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showAfterEdit!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showDuringEdit!: boolean;

	private selectedOfferingModel: DB.OfferingModel | null = null;

	private get countryModel() {
		return UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
	}

	private get currencyModel() {
		return UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
	}

	private get isAvailableInRegion() {
		if (this.countryModel && this.selectedOfferingModel) {
			return !!AppDataModule.findRegionOfferingLinkWhere({
				regionid: this.countryModel.regionid,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	private get offeringOptionModels() {
		const offeringOptionModels = this.selectedOfferingModel
			? AppDataModule.getOfferingOptionModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];

		return offeringOptionModels.filter(
			(model) => this.filterTags?.indexOf(model.tag) < 0,
		);
	}

	private get offeringOptionValueModels() {
		return this.selectedOfferingModel
			? AppDataModule.getOfferingOptionValueModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];
	}

	private get priceData() {
		if (this.isAvailableInRegion
			&& this.selectedOfferingModel
			&& this.productModel
		) {
			return PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get selectedOfferingPrice() {
		if (this.pageModel) {
			if (!this.currencyModel) {
				throw new Error('Missing required currency model');
			}
			if (!this.selectedOfferingModel) {
				throw new Error('Missing required offering model');
			}

			const pricingModel = AppDataModule.findPricingWhere({
				offeringid: this.selectedOfferingModel.id,
				currency: this.currencyModel.id,
			});

			if (!pricingModel) {
				return undefined;
			}

			return pricingModel.price_page;
		}

		const objPrice = this.priceData;

		let selectedOfferingPrice = objPrice
			? objPrice.subTotal
			: 0;

		if (objPrice && objPrice.offeringModel) {
			const upsellModels = AppDataModule.whereUpsell({
				offeringid: objPrice.offeringModel.id,
				autoinclude: 1,
				optional: 0,
			});

			upsellModels.forEach((upsellModel) => {
				if (this.productModel) {
					const price = PriceCalculator.projectPrice({
						offeringid: upsellModel.upsellid,
						productid: this.productModel.id,
					});
					if (price) {
						selectedOfferingPrice += price.subTotal;
					}
				}
			});
		}

		return selectedOfferingPrice;
	}

	private get selectOptions() {
		const selectOptions: SelectOption[] = [];

		this.offeringOptionModels.forEach((offeringOptionModel) => {
			const valueModels = _.where(
				this.offeringOptionValueModels,
				{
					offeringoptionid: offeringOptionModel.id,
				},
			);

			const prioritizedOptionModels = this.offeringOptionModels.filter(
				(oM) => oM.serialnumber < offeringOptionModel.serialnumber,
			);

			const offeringIds: number[][] = [];
			prioritizedOptionModels.forEach((prioritizedOptionModel) => {
				const vModels = _.where(
					this.offeringOptionValueModels,
					{
						offeringoptionid: prioritizedOptionModel.id,
					},
				);

				const linkModel = _.find(
					AppDataModule.offeringoptionvalueofferinglinks,
					(m) => (
						this.selectedOfferingModel !== null
						&& m.offeringid == this.selectedOfferingModel.id
						&& _.pluck(
							vModels,
							'id',
						).indexOf(m.offeringoptionvalueid) >= 0
					),
				);
				const selectedValueModelOfPrioritizedOptionModel = _.findWhere(
					this.offeringOptionValueModels,
					{
						id: linkModel?.offeringoptionvalueid,
					},
				);
				if (selectedValueModelOfPrioritizedOptionModel) {
					const prioritizedOptionValueLinkModels = _.where(
						AppDataModule.offeringoptionvalueofferinglinks,
						{ offeringoptionvalueid: selectedValueModelOfPrioritizedOptionModel.id },
					);
					offeringIds.push(
						_.pluck(
							prioritizedOptionValueLinkModels,
							'offeringid',
						),
					);
				}
			});
			const filteredOfferingIds = _.intersection(...offeringIds);
			const filteredValueModels = filteredOfferingIds.length
				? valueModels.filter((vM) => _.find(
					AppDataModule.offeringoptionvalueofferinglinks,
					(m) => (
						m.offeringoptionvalueid == vM.id
						&& filteredOfferingIds.indexOf(m.offeringid) >= 0
					),
				))
				: valueModels;

			if (filteredValueModels.length > 1) {
				const linkModel = _.find(
					AppDataModule.offeringoptionvalueofferinglinks,
					(m) => (
						this.selectedOfferingModel !== null
						&& m.offeringid == this.selectedOfferingModel.id
						&& _.pluck(
							filteredValueModels,
							'id',
						).indexOf(m.offeringoptionvalueid) >= 0
					),
				);
				const selectedValueModel = _.findWhere(
					this.offeringOptionValueModels,
					{
						id: linkModel?.offeringoptionvalueid,
					},
				);

				selectOptions.push({
					id: offeringOptionModel.id,
					label: this.$t(
						`offeringOptions:${offeringOptionModel.id}`,
						offeringOptionModel.name,
					),
					show: Boolean((this.showDuringEdit && offeringOptionModel.showduringedit)
						|| (this.showAfterEdit && offeringOptionModel.showafteredit)),
					valueModels: filteredValueModels,
					valueModel: selectedValueModel,
				});
			}
		});

		return selectOptions;
	}

	protected get showPricing(): boolean {
		return Boolean(this.selectedOfferingModel?.showPricing);
	}

	protected created() {
		this.selectedOfferingModel = this.pageModel && this.pageModel.offeringId
			? AppDataModule.getOffering(this.pageModel.offeringId) || null
			: ProductStateModule.getOffering;
	}

	protected beforeDestroy() {
		if (this.selectedOfferingModel && this.pageModel) {
			if (this.selectedOfferingModel.id != this.pageModel.offeringId) {
				analytics.trackEvent(
					'Change Page Offering Variant',
					{},
				);

				ProductStateModule.changePage({
					id: this.pageModel.id,
					offeringId: this.selectedOfferingModel.id,
				});
			}
		} else if (this.selectedOfferingModel && this.productModel) {
			if (this.selectedOfferingModel.groupid == this.productModel.group
				&& this.selectedOfferingModel.typeid == this.productModel.typeid
			) {
				analytics.trackEvent(
					'Change Offering Variant',
					{},
				);
				ProductState
					.changeOfferingVariant(
						this.selectedOfferingModel.variantid,
					)
					.catch(async (error) => {
						await new Promise<void>((bugsnagResolve) => {
							if (typeof window.glBugsnagClient !== 'undefined') {
								window.glBugsnagClient.notify(
									error,
									(bugsnagEvent) => {
										bugsnagEvent.severity = 'warning';
										bugsnagEvent.addMetadata(
											'operationData',
											{
												productModel: this.productModel,
												selectedOfferingModel: this.selectedOfferingModel,
											},
										);
									},
									bugsnagResolve,
								);
								return;
							}

							bugsnagResolve();
						});

						throw error;
					});
			} else {
				analytics.trackEvent(
					'Change Offering',
					{},
				);

				this.changeOffering();
			}
		}
	}

	private changeOffering(): Promise<void> {
		if (!this.selectedOfferingModel) {
			throw new Error('Missing required offering model');
		}

		AppStateModule.setHeavyLoad();
		const closeLoader = this.$openLoaderDialog();

		return ProductState
			.changeOffering(this.selectedOfferingModel.id)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.catch((e: Error) => {
				// Swallow error: no action required
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			})
			.finally(() => {
				closeLoader();
				AppStateModule.unsetHeavyLoad();
			});
	}

	protected getOptionLabel(valueModel: DB.OfferingOptionValueModel) {
		return this.$t(
			`offeringOptionValues:${valueModel.id}`,
			valueModel.value,
		);
	}

	protected selectedOptionOutput(inputEvent: InputEvent) {
		const target = inputEvent.target as HTMLSelectElement;
		const optionModel = _.findWhere(
			AppDataModule.offeringoptions,
			{
				id: parseInt(
					target.id,
					10,
				),
			},
		);

		if (target && optionModel) {
			let linkModels = _.where(
				AppDataModule.offeringoptionvalueofferinglinks,
				{
					offeringoptionvalueid: parseInt(
						target.value,
						10,
					),
				},
			);
			this.selectOptions.forEach((selectOption) => {
				if (selectOption.valueModel && selectOption.valueModel.offeringoptionid != optionModel.id) {
					linkModels = linkModels.filter((linkModel) => _.findWhere(
						AppDataModule.offeringoptionvalueofferinglinks,
						{
							offeringid: linkModel.offeringid,
							offeringoptionvalueid: selectOption.valueModel?.id,
						},
					));
				}
			});

			linkModels = _.sortBy(
				linkModels,
				(linkModel) => {
					const offeringModel = AppDataModule.getOffering(linkModel.offeringid);
					return offeringModel
						&& this.productModel
						&& Boolean(offeringModel.groupid == this.productModel.group && offeringModel.typeid == this.productModel?.typeid)
						? -1
						: 1;
				},
			);

			let offeringModel: DB.OfferingModel | undefined;
			if (linkModels.length) {
				offeringModel = _.findWhere(
					AppDataModule.offerings,
					{
						id: linkModels[0].offeringid,
					},
				);
			} else {
				const linkModel = _.findWhere(
					AppDataModule.offeringoptionvalueofferinglinks,
					{
						offeringoptionvalueid: parseInt(
							target.value,
							10,
						),
					},
				);
				if (linkModel) {
					offeringModel = _.findWhere(
						AppDataModule.offerings,
						{
							id: linkModel.offeringid,
						},
					);
				}
			}

			if (offeringModel) {
				this.selectedOfferingModel = offeringModel;
			}
		}
	}
}
