var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"menuHeaderModal"}),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],class:{
			headerIcon: true,
			pressed: _vm.open,
		},on:{"click":_vm.toggleOpen}},[_c('i',{staticClass:"material-icons menuIcon"},[_vm._v("\n\t\t\tmenu\n\t\t")]),_vm._v(" "),_c('span',{staticClass:"menuText"},[_vm._v("\n\t\t\t"+_vm._s(_vm.hasProductCategories
				? _vm.$t('menuCategories')
				: _vm.$t('menu'))+"\n\t\t")])]),_vm._v(" "),(_vm.hasProductCategories)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"harmonica"},[_vm._l((_vm.productCategories),function(category){return _c('li',{key:category.id,on:{"click":function($event){return _vm.goToCategory(category)}}},[_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(category.name)+"\n\t\t\t")])])}),_vm._v(" "),(_vm.hasPromotions)?_c('li',{staticClass:"newSection",on:{"click":_vm.goToPromotions}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('views.headerMenu.promotions'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasAboutApp)?_c('li',{class:{
				newSection: !_vm.hasPromotions
			},on:{"click":_vm.goToAboutApp}},[_c('span',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('views.headerMenu.aboutApp'))+"\n\t\t\t")])]):_vm._e()],2):_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"harmonica"},[_c('li',{on:{"click":_vm.goToCreate}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('buttonCreateNew'))+"\n\t\t")]),_vm._v(" "),(_vm.hasPricing)?_c('li',{on:{"click":_vm.goToPricing}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('pricing'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasShipping)?_c('li',{on:{"click":_vm.goToShipping}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('menuShipping'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasLiveChat)?_c('li',{on:{"click":_vm.openChat}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('buttonHelp'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasSupport)?_c('li',{on:{"click":_vm.goToSupport}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('buttonHelp'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasPromotions)?_c('li',{on:{"click":_vm.goToPromotions}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('views.headerMenu.promotions'))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasAppReview)?_c('li',{on:{"click":_vm.reviewApp}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('appMenu.reviewApp'))+"\n\t\t")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }