import './defines';
import ColorConverter from '@sosocio/color-converter';
import { PantoneMatchingSystemColor } from 'interfaces/app';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

const hexColors = [
	'ee2028',
	'f67e24',
	'2a95ce',
	'93d7e9',
];
const colors: PantoneMatchingSystemColor[] = [];

// eslint-disable-next-line no-restricted-syntax
for (const hexColor of hexColors) {
	const colorConverter = new ColorConverter();
	colorConverter.hex6 = {
		value: hexColor,
	};
	colors.push({
		name: colorConverter.pantone.name.replace(
			/pantone\s/i,
			'',
		),
		styles: {
			'--color': `#${colorConverter.hex6.value}`,
		},
	});
}

@Component({
	name: 'PantoneMatchingSystemComponent',
})
export default class PantoneMatchingSystemComponent extends Vue.extend(Template) {
	protected colors: PantoneMatchingSystemColor[] = colors;
}
