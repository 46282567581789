import './defines';
import { PhotoModel } from 'interfaces/project';
import appendUrlParameter from 'tools/append-url-parameter';
import {
	isMobile,
	watch as isMobileWatcher,
} from 'tools/is-mobile';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'CloudPhotoView',
})
export default class CloudPhotoView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly cloudPhotoModel!: Partial<PhotoModel>;

	// Import mode is activated in the import module, which means a user can select multiple
	// photos, and we visually show if the photo is already selected
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly importMode!: boolean;

	@Prop({
		default: false,
		type: Boolean,
		description: 'If the photo is selected by the user',
	})
	public readonly isSelected!: boolean;

	@Prop({
		default: false,
		type: Boolean,
		description: 'If the photo is being removed from the account',
	})
	public readonly removing!: boolean;

	// Trash mode is activated in the account module, which means a user can remove the photo
	// from his/her account (destroy the model on the server)
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly trashMode!: boolean;

	protected get checkisMobile(): boolean {
		return isMobile;
	}

	protected get className(): string[] {
		const classes: string[] = [];

		if (
			this.importMode
			&& this.isSelected
		) {
			classes.push('pressed');
		}
		if (!this.trashMode) {
			classes.push('pointer');
		}

		return classes;
	}

	protected get styleObject(): Partial<CSSStyleDeclaration> {
		return {
			backgroundImage: `url(${this.img})`,
		};
	}

	private get url(): string | undefined {
		let url: string | undefined;

		if (
			!this.isMobile
			&& this.cloudPhotoModel.url
		) {
			url = this.cloudPhotoModel.url;
		} else if (this.cloudPhotoModel.thumb_url) {
			url = this.cloudPhotoModel.thumb_url;
		}

		if (
			url
			&& this.cloudPhotoModel.id
		) {
			url = appendUrlParameter(
				url,
				'noCorsHeader',
			);
		}

		return url;
	}

	protected error = false;

	private img: string | null = null;

	private isMobile = isMobile;

	private isMobileUnwatch: (() => void) | undefined;

	// We use the pressed status to show a temporary spinner while the item has been selected by the user,
	// but due to loading of the image (to get its unknown dimensions) it is not included in the
	// project data yet, so the 'isSelected' getter won't see it yet
	// This also prevents double selecting of the same photo
	private pressed = false;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.isMobileUnwatch = undefined;
	}

	protected created(): void {
		this.isMobileUnwatch = isMobileWatcher(() => {
			this.isMobile = isMobile;
		});
	}

	protected mounted(): void {
		this.loadImage();
	}

	protected clickPhoto(): void {
		if (!this.trashMode) {
			if (
				this.importMode
				&& this.isSelected
			) {
				this.pressed = false;
				this.$emit(
					'unselect',
					this.cloudPhotoModel,
				);
			} else if (!this.pressed) {
				this.selectPhoto();
			}
		}
	}

	@Watch('url')
	private loadImage(): void {
		if (this.url) {
			const newImg = new Image();
			newImg.onload = () => {
				this.img = this.url || null;
			};
			newImg.onerror = () => {
				this.error = true;
			};
			newImg.src = this.url;
		} else {
			this.error = true;
		}
	}

	protected removePhoto(): void {
		this.$emit(
			'remove',
			this.cloudPhotoModel,
		);
	}

	private selectPhoto(): void {
		this.pressed = true;
		this.$emit(
			'select',
			this.cloudPhotoModel,
		);
	}
}
