import { DialogModel } from 'interfaces/app';

export const mainState: DialogModel = {
	buttons: [],
	checkBoxes: [],
	chrome: true,
	cid: null,
	data: [],
	description: null,
	focusMode: false,
	hasclose: true,
	height: 250,
	lightModal: false,
	modal: false,
	multipleButtons: false,
	noBodyPadding: false,
	opacity: 0.7,
	picker: false,
	posy: 0,
	show: false,
	title: null,
	text: null,
	vueProps: null,
	vueView: null,
	vueViewProps: null,
	width: 320,
	wrapButtons: false,
};

export const authState: DialogModel = {
	buttons: [],
	chrome: true,
	cid: null,
	description: null,
	focusMode: false,
	hasclose: true,
	height: 250,
	lightModal: false,
	modal: false,
	multipleButtons: false,
	noBodyPadding: false,
	opacity: 0.7,
	options: {},
	posy: 0,
	show: false,
	showLogin: false,
	showSignup: false,
	title: null,
	text: null,
	width: 350,
};
