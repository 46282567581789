import _ from 'underscore';
import storage from 'controllers/storage';
import URL_PARAMETERS from 'settings/url-parameters';
import parseUrl from './parse-url';

const getLocale = () => {
	let locale = 'en';
	const parsedUrl = parseUrl(window.location.href);
	const urlVars = parsedUrl.parameters;
	if (urlVars[URL_PARAMETERS.language]) {
		locale = urlVars[URL_PARAMETERS.language];
	} else if (urlVars[URL_PARAMETERS.locale]) {
		// Backwards compatability - locale parameter has been replaced with language
		locale = urlVars[URL_PARAMETERS.locale];
	} else if (storage.get('locale')) {
		locale = storage.get('locale');
	} else if (typeof navigator !== 'undefined') {
		locale = ((navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en').toLowerCase();
	}

	// Normalize locale string
	locale = locale.replace(
		'_',
		'-',
	).toLowerCase();

	if (window.glLanguages.indexOf(locale) < 0) {
		if (locale.length > 2 && window.glLanguages.indexOf(locale.slice(
			0,
			2,
		)) >= 0) {
			locale = locale.slice(
				0,
				2,
			);
		} else {
			let match;
			if (locale.length == 2) {
				// Search for more specific match
				match = _.find(
					window.glLanguages,
					(l) => l.slice(
						0,
						2,
					) == locale,
				);
			}

			if (match) {
				locale = match;
			} else {
				// No match, set to default
				([locale] = window.glLanguages);
			}
		}
	}

	return locale;
};

export default getLocale;
