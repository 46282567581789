import { Vue, Component, Prop } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import getPixelUrl from '../tools/get-pixel-url';

@Component({
	components: {
		CountryFlag,
	},
})
export default class CountrySelectorItemView extends Vue {
	@Prop({ required: true, type: String })
	readonly iso!: string;

	@Prop({ required: true, type: String })
	readonly name!: string;

	@Prop({ default: false, type: Boolean })
	readonly suggested!: boolean;

	get pixel() {
		return getPixelUrl();
	}

	get itemClassName() {
		let className = 'listItem';
		if (this.suggested) {
			className += ' suggested';
		}
		return className;
	}

	get flagClassName() {
		return `flag flag-${this.iso}`;
	}

	onClick() {
		this.$emit(
			'select',
			this.$vnode.key,
		);
	}
}
