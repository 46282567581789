import merge from 'deepmerge';
import * as PI from 'interfaces/project';
import * as DB from 'interfaces/database';
import {
	PageObjectVars,
} from 'interfaces/app';
import {
	PhotosModule,
	ProductStateModule,
} from '../store/index';
import getScaledFilename from '../tools/get-scaled-filename';
import maxObjectSize from '../tools/max-object-size';
import EventBus from '../components/event-bus';
import touch from '../controllers/touch';
import { ERRORS_INVALID_REQUEST_DATA } from '../settings/errors';

class PageObject {
	public static getPhotoSource(
		objectModel: PI.PageObjectModel,
		options: {
			resolution: 'thumb' | 'low' | 'high';
			maxWidth?: number;
			maxHeight?: number;
		},
	): Promise<string | File> {
		if (objectModel.photoid) {
			return PhotosModule.getModelUrl({
				id: objectModel.photoid,
				resolution: options.resolution,
				maxWidth: options.maxWidth,
				maxHeight: options.maxHeight,
			});
		}

		if (objectModel.source) {
			// if photo has not been downloaded, load from source
			let src: string;

			if (
				options.resolution === 'high'
				&& objectModel.scaling
				&& objectModel.scaling > 1
			) {
				src = getScaledFilename(
					objectModel.source,
					objectModel.scaling,
				);
			} else {
				src = objectModel.source;
			}

			return Promise.resolve(src);
		}

		return Promise.reject(
			new Error(ERRORS_INVALID_REQUEST_DATA),
		);
	}

	static specs(
		objectModel: PI.PageObjectModel,
		pageModel?: PI.PageModel,
	) {
		const offeringModel = ProductStateModule.getOffering;
		if (!pageModel) {
			pageModel = ProductStateModule.findPageFromObject(objectModel.id);
		}

		if (!offeringModel) {
			throw new Error('Could not find required offeringModel');
		}
		if (!pageModel) {
			throw new Error('Could not find required pageModel');
		}

		const fullPageWidth = pageModel.width + 2 * pageModel.offset;
		const fullPageHeight = pageModel.height + 2 * pageModel.offset;

		const photoModel = objectModel.photoid
			? PhotosModule.getById(objectModel.photoid)
			: undefined;
		const photoData = photoModel
			? {
				width: photoModel.full_width,
				height: photoModel.full_height,
			}
			: undefined;
		const maxsize = maxObjectSize(
			{
				maxwidth: objectModel.maxwidth,
				maxheight: objectModel.maxheight,
				cropwidth: objectModel.cropwidth,
				cropheight: objectModel.cropheight,
				type: objectModel.type,
			},
			photoData,
			offeringModel,
		);

		const dpiScale = objectModel.maxwidth / maxsize.width;
		const maxCropW = offeringModel.pagefill
			? Math.min(
				objectModel.maxwidth,
				fullPageWidth / fullPageHeight * objectModel.maxheight,
			)
			: objectModel.maxwidth;
		const maxCropH = offeringModel.pagefill
			? Math.min(
				objectModel.maxheight,
				fullPageHeight / fullPageWidth * objectModel.maxwidth,
			)
			: objectModel.maxheight;
		const minCropW = objectModel.width * dpiScale;
		const minCropH = objectModel.height * dpiScale;

		const objectZoom = objectModel.cropwidth && objectModel.cropwidth > 0
			? Math.max(
				objectModel.width / objectModel.cropwidth,
				objectModel.height / objectModel.cropheight,
			)
			: 1;

		const zoomW = maxCropW - minCropW > 0
			? (objectModel.cropwidth - minCropW) / (maxCropW - minCropW)
			: 0;
		const zoomH = maxCropH - minCropH > 0
			? (objectModel.cropheight - minCropH) / (maxCropH - minCropH)
			: 0;
		const zoomValue = 1 - Math.min(
			zoomW,
			zoomH,
		);

		return {
			maxCropWidth: maxCropW,
			maxCropHeight: maxCropH,
			minCropWidth: minCropW,
			minCropHeight: minCropH,
			zoomValue,
			objectZoom,
		};
	}

	static select(
		pageModel: PI.PageModel,
		objectModelId: string,
		options?: {
			cropModus?: boolean;
			draggable?: boolean;
			editText?: boolean;
			evt?: MouseEvent | TouchEvent | PointerEvent;
			resizable?: boolean;
			rotatable?: boolean;
			showToolbar?: boolean;
		},
	) {
		const defaults = {
			draggable: true,
		};
		const { evt } = options || {};
		options = options ? merge(
			defaults,
			options,
		) : defaults;

		// Note: deepmerge will not copy event data properties,
		// so reset the reference to the original object here
		if (evt) {
			options.evt = evt;
		}

		// Select page object
		ProductStateModule.selectPageObject({
			pageModel,
			objectModelId,
		});

		// Start tracking drag movement
		if (options.evt) {
			if (options.cropModus) {
				touch.startCropDrag(options.evt);
			} else if (options.draggable) {
				touch.startDrag(options.evt);
			}
		}

		EventBus.$emit(
			'selectObject',
			pageModel,
			objectModelId,
			options,
		);
	}

	public static calculatePosition(
		referenceModel: {
			x_axis: number;
			y_axis: number;
			width: number;
			height: number;
			borderwidth: number;
			bordercolor: string | null;
			cropx?: number;
			cropy?: number;
			cropwidth?: number;
			cropheight?: number;
			angle?: number;
			flop?: boolean;
			flip?: boolean;
			type: 'text' | 'photo';
			maxwidth?: number;
			photoid?: PI.PhotoModel['id'];
		},
		scaling: number,
		pageModel: PI.PageModel | null,
		bleedMargin: number,
		offeringModel?: DB.OfferingModel,
	): PageObjectVars {
		const photoModel = (
			referenceModel.photoid
				? PhotosModule.getById(referenceModel.photoid)
				: undefined
		);
		const photoData = (
			photoModel
				? {
					width: photoModel.full_width,
					height: photoModel.full_height,
				}
				: undefined
		);
		const maxsize = maxObjectSize(
			{
				type: referenceModel.type,
				maxwidth: referenceModel.maxwidth,
				cropwidth: referenceModel.cropwidth,
				cropheight: referenceModel.cropheight,
			},
			photoData,
			offeringModel,
		);
		const dpiScale = (
			referenceModel.maxwidth
				? referenceModel.maxwidth / maxsize.width
				: 1
		);

		if (!bleedMargin) {
			bleedMargin = 0;
		}

		const vars: PageObjectVars = {
			x_axis: referenceModel.x_axis + bleedMargin,
			y_axis: referenceModel.y_axis + bleedMargin,
			width: referenceModel.width,
			height: referenceModel.height,
			maxwidth: maxsize.width,
			maxheight: maxsize.height,
			cropx: referenceModel.cropx || 0,
			cropy: referenceModel.cropy || 0,
			cropwidth: referenceModel.cropwidth || 0,
			cropheight: referenceModel.cropheight || 0,
			borderwidth: referenceModel.borderwidth,
			bordercolor: referenceModel.bordercolor,
			angle: referenceModel.angle || 0,
			flop: referenceModel.flop ? 1 : 0,
			flip: referenceModel.flip ? 1 : 0,
			rotate: 0,
			cosinus: 0,
			sinus: 0,
			topleft: {
				x: 0,
				y: 0,
			},
			topright: {
				x: 0,
				y: 0,
			},
			bottomright: {
				x: 0,
				y: 0,
			},
			bottomleft: {
				x: 0,
				y: 0,
			},
			imagemap: {
				topleft: {
					x: 0,
					y: 0,
				},
				topright: {
					x: 0,
					y: 0,
				},
				bottomright: {
					x: 0,
					y: 0,
				},
				bottomleft: {
					x: 0,
					y: 0,
				},
			},
			center: {
				x: 0,
				y: 0,
			},
			max: {
				x_axis: 0,
				y_axis: 0,
				width: 0,
				height: 0,
				cropx: 0,
				cropy: 0,
				topleft: {
					x: 0,
					y: 0,
				},
				placement: {
					x: 0,
					y: 0,
				},
			},
			placement: {
				x: 0,
				y: 0,
			},
			canvas: {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				width: 0,
				height: 0,
			},
			draw: {
				x: 0,
				y: 0,
			},
		};
		vars.center = {
			x: vars.x_axis + vars.width / 2,
			y: vars.y_axis + vars.height / 2,
		};

		const objectscale = vars.cropwidth / vars.width;

		vars.max = {
			x_axis: vars.x_axis - vars.cropx / objectscale,
			y_axis: vars.y_axis - vars.cropy / objectscale,
			width: vars.maxwidth / objectscale * dpiScale,
			height: vars.maxheight / objectscale * dpiScale,
			cropx: 0,
			cropy: 0,
			topleft: {
				x: 0,
				y: 0,
			},
			placement: {
				x: 0,
				y: 0,
			},
		};
		vars.max.center = {
			x: vars.max.x_axis + vars.max.width / 2,
			y: vars.max.y_axis + vars.max.height / 2,
		};

		if (vars.flop) {
			vars.angle = -vars.angle;
		}

		if (vars.flip) {
			vars.angle = -vars.angle;
		}

		vars.rotate = Math.PI / 180 * vars.angle;
		vars.cosinus = Math.cos(vars.rotate);
		vars.sinus = Math.sin(vars.rotate);

		vars.topleft.x = (
			(-(vars.width) / 2 * Math.cos(vars.rotate))
			- (-(vars.height) / 2 * Math.sin(vars.rotate))
		) + vars.center.x;
		vars.imagemap.topleft.x = (
			(-(vars.width + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate))
			- (-(vars.height + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate))
		) + vars.center.x;

		vars.topleft.y = (
			(-(vars.width) / 2 * Math.sin(vars.rotate))
			+ (-(vars.height) / 2 * Math.cos(vars.rotate))
		) + vars.center.y;
		vars.imagemap.topleft.y = (
			(-(vars.width + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate))
			+ (-(vars.height + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate))
		) + vars.center.y;

		vars.max.topleft.x = (
			(-(vars.max.width) / 2 * Math.cos(vars.rotate))
			- (-(vars.max.height) / 2 * Math.sin(vars.rotate))
		) + vars.max.center.x;
		vars.max.topleft.y = (
			(-(vars.max.width) / 2 * Math.sin(vars.rotate))
			+ (-(vars.max.height) / 2 * Math.cos(vars.rotate))
		) + vars.max.center.y;

		vars.topright.x = vars.topleft.x + vars.width * vars.cosinus;
		vars.imagemap.topright.x = vars.imagemap.topleft.x
			+ (vars.width + 2 * vars.borderwidth) * vars.cosinus;

		vars.topright.y = vars.topleft.y + vars.width * vars.sinus;
		vars.imagemap.topright.y = vars.imagemap.topleft.y
			+ (vars.width + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.x = vars.topright.x - vars.height * vars.sinus;
		vars.imagemap.bottomright.x = vars.imagemap.topright.x
			- (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.y = vars.topright.y + vars.height * vars.cosinus;
		vars.imagemap.bottomright.y = vars.imagemap.topright.y
			+ (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.bottomleft.x = vars.topleft.x - vars.height * vars.sinus;
		vars.imagemap.bottomleft.x = vars.imagemap.topleft.x
			- (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomleft.y = vars.topleft.y + vars.height * vars.cosinus;
		vars.imagemap.bottomleft.y = vars.imagemap.topleft.y
			+ (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.placement = {
			x: vars.topleft.x,
			y: vars.topleft.y,
		};
		vars.max.placement = {
			x: vars.max.topleft.x,
			y: vars.max.topleft.y,
		};

		let temp: PageObjectVars;

		if (vars.flop) { // horizontal
			vars.placement.x = -vars.bottomright.x;
			temp = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = temp.bottomleft.x;
			vars.topleft.y = temp.topright.y;
			vars.topright.x = temp.bottomright.x;
			vars.topright.y = temp.topleft.y;
			vars.bottomright.x = temp.topright.x;
			vars.bottomright.y = temp.bottomleft.y;
			vars.bottomleft.x = temp.topleft.x;
			vars.bottomleft.y = temp.bottomright.y;
		}

		if (vars.flip) { // vertical
			if (!vars.flop) {
				vars.placement.y = -vars.bottomright.y;
			} else {
				vars.placement.y = -vars.bottomleft.y;
			}

			temp = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = temp.bottomleft.x;
			vars.topleft.y = temp.topright.y;
			vars.topright.x = temp.bottomright.x;
			vars.topright.y = temp.topleft.y;
			vars.bottomright.x = temp.topright.x;
			vars.bottomright.y = temp.bottomleft.y;
			vars.bottomleft.x = temp.topleft.x;
			vars.bottomleft.y = temp.bottomright.y;
		}

		vars.imagemap = {
			topleft: {
				x: (vars.imagemap.topleft.x) * scaling,
				y: (vars.imagemap.topleft.y) * scaling,
			},
			topright: {
				x: (vars.imagemap.topright.x) * scaling,
				y: (vars.imagemap.topright.y) * scaling,
			},
			bottomright: {
				x: (vars.imagemap.bottomright.x) * scaling,
				y: (vars.imagemap.bottomright.y) * scaling,
			},
			bottomleft: {
				x: (vars.imagemap.bottomleft.x) * scaling,
				y: (vars.imagemap.bottomleft.y) * scaling,
			},
		};

		const pageWidth = (
			pageModel
				? pageModel.width * scaling
				: 1000000
		);
		const pageHeight = (
			pageModel
				? pageModel.height * scaling
				: 100000
		);

		vars.canvas.top = Math.max(
			0,
			Math.min(
				vars.imagemap.topleft.y,
				vars.imagemap.topright.y,
				vars.imagemap.bottomleft.y,
				vars.imagemap.bottomright.y,
			),
		);
		vars.canvas.left = Math.max(
			0,
			Math.min(
				vars.imagemap.topleft.x,
				vars.imagemap.topright.x,
				vars.imagemap.bottomleft.x,
				vars.imagemap.bottomright.x,
			),
		);
		vars.canvas.bottom = Math.min(
			pageHeight + 2 * bleedMargin * scaling,
			Math.max(
				vars.imagemap.topleft.y,
				vars.imagemap.topright.y,
				vars.imagemap.bottomleft.y,
				vars.imagemap.bottomright.y,
			),
		);
		vars.canvas.right = Math.min(
			pageWidth + 2 * bleedMargin * scaling,
			Math.max(
				vars.imagemap.topleft.x,
				vars.imagemap.topright.x,
				vars.imagemap.bottomleft.x,
				vars.imagemap.bottomright.x,
			),
		);

		if (
			referenceModel.type
			&& referenceModel.type === 'text'
		) {
			// The text of a text object can be bigger than the size of the object,
			// therefore we make sure that nothing is cut off by extending the size of the canvas
			vars.canvas.left = 0;
			vars.canvas.right = pageWidth + 2 * bleedMargin * scaling;
			vars.canvas.bottom = pageHeight + 2 * bleedMargin * scaling;
		}

		vars.canvas.width = vars.canvas.right - vars.canvas.left;
		vars.canvas.height = vars.canvas.bottom - vars.canvas.top;

		vars.canvas.width += 2; // 2 pixels margin to compensate decimals
		vars.canvas.height += 2; // 2 pixels margin to compensate decimals

		vars.draw = {
			x: vars.placement.x - vars.canvas.left / scaling + 1, // 1 pixel extra to compensate decimals
			y: vars.placement.y - vars.canvas.top / scaling + 1,
		};

		return vars;
	}
}

export default PageObject;
