import _ from 'underscore';
import Vue from 'vue';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import * as DB from 'interfaces/database';
import { CartModule, UserModule } from 'store/index';
import { discountModelAttributes, voucherModelAttributes } from './defaults';

@Module({ namespaced: true, name: 'discount' })
export default class Discount extends VuexModule {
	discount: DB.DiscountModel = JSON.parse(JSON.stringify(discountModelAttributes));

	productTypes: DB.DiscountOfferingModel[] = [];

	shipping: DB.DiscountShippingModel[] = [];

	voucher: DB.DiscountVoucherModel = JSON.parse(JSON.stringify(voucherModelAttributes));

	public get findWhereShipping() {
		return (properties: {
			countryid?: number;
			shippingtype?: 1|2|3;
		}) => _.findWhere(
			this.shipping,
			_.extend(
				properties,
				{
					discountid: this.discount.id,
				},
			),
		);
	}

	public get getDiscount() {
		return this.discount;
	}

	private get _getProductType() {
		return (id: number) => _.findWhere(
			this.productTypes,
			{ id },
		);
	}

	public get getProductTypes() {
		return this.productTypes;
	}

	private get _getShipping() {
		return (id: number) => _.findWhere(
			this.shipping,
			{ id },
		);
	}

	public get getVoucher() {
		return this.voucher;
	}

	@Mutation
	private _addProductType(data: DB.DiscountOfferingModel): void {
		this.productTypes.push(data);
	}

	@Mutation
	private _addShipping(data: DB.DiscountShippingModel): void {
		this.shipping.push(data);
	}

	@Mutation
	private _resetDiscount(): void {
		this.discount = JSON.parse(JSON.stringify(discountModelAttributes));
	}

	@Mutation
	private _resetProductTypes(): void {
		this.productTypes = [];
	}

	@Mutation
	private _resetShipping(): void {
		this.shipping = [];
	}

	@Mutation
	private _resetVoucher(): void {
		this.voucher = JSON.parse(JSON.stringify(voucherModelAttributes));
	}

	@Mutation
	public setDiscount(data: DB.DiscountModel): void {
		this.discount = data;
	}

	@Mutation
	private _setProductType(data: DB.DiscountOfferingModel): void {
		const i = _.findIndex(
			this.productTypes,
			{ id: data.id },
		);
		Vue.set(
			this.productTypes,
			i,
			data,
		);
	}

	@Mutation
	private _setShipping(data: DB.DiscountShippingModel): void {
		const i = _.findIndex(
			this.shipping,
			{ id: data.id },
		);
		Vue.set(
			this.shipping,
			i,
			data,
		);
	}

	@Mutation
	public setVoucher(data: Partial<DB.DiscountVoucherModel>): void {
		Object.assign(
			this.voucher,
			data,
		);
	}

	@Action
	private _mergeProductType(data: DB.DiscountOfferingModel): void {
		if (this._getProductType(data.id)) {
			return this._setProductType(data);
		}

		return this._addProductType(data);
	}

	@Action
	public addProductTypes(arrData: DB.DiscountOfferingModel[]): void {
		arrData.forEach((data) => {
			this._mergeProductType(data);
		});
	}

	@Action
	private _mergeShipping(data: DB.DiscountShippingModel): void {
		if (this._getShipping(data.id)) {
			return this._setShipping(data);
		}

		return this._addShipping(data);
	}

	@Action
	public addShippings(arrData: DB.DiscountShippingModel[]): void {
		arrData.forEach((data) => {
			this._mergeShipping(data);
		});
	}

	@Action({ rawError: true })
	public fetch({
		voucherCode,
	}: {
		voucherCode: string | null;
	} = {
		voucherCode: null,
	}): Promise<void> {
		const handleResponse = (data: {
			discount_producttypes: DB.DiscountOfferingModel[]; // eslint-disable-line camelcase
			discount_country: DB.DiscountShippingModel[]; // eslint-disable-line camelcase
			discount: DB.DiscountModel;
			voucher: DB.DiscountVoucherModel;
		}) => {
			this.reset();

			if (data?.hasOwnProperty('discount_producttypes')) {
				this.addProductTypes(data.discount_producttypes);
			}
			if (data?.hasOwnProperty('discount_country')) {
				this.addShippings(data.discount_country);
			}
			if (data?.hasOwnProperty('discount')) {
				this.setDiscount(data.discount);
			}
			if (data?.hasOwnProperty('voucher')) {
				// Link voucherid to shopping cart
				CartModule.set({
					voucherid: data.voucher.id,
				});
				// Set voucher model properties
				this.setVoucher(data.voucher);
			} else {
				// Unset voucherid from shoppingcart model
				CartModule.set({
					voucherid: null,
				});
				CartModule.put({});
			}
		};

		if (!_.isUndefined(voucherCode)
			&& !_.isNull(voucherCode)
		) {
			return CartModule.checkVoucher(voucherCode)
				.then((response) => handleResponse(response));
		}

		return UserModule.fetchLoyalty()
			.then((response) => handleResponse(response));
	}

	@Action
	public reset(): void {
		this._resetDiscount();
		this._resetVoucher();
		this._resetProductTypes();
		this._resetShipping();
	}
}
