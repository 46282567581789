import {
	AppDataModule,
	ConfigModule,
	ProductStateModule,
} from 'store';

interface ReturnData {
	width: number;
	height: number;
	qualityWidth: number;
	qualityHeight: number;
	cropWidth: number;
	cropHeight: number;
	croppedQualityWidth: number;
	croppedQualityHeight: number;
}

export default function maxObjectSize(
	objectData: {
		type: 'text' | 'photo',
		maxwidth?: number;
		maxheight?: number;
		cropwidth?: number;
		cropheight?: number;
	},
	photoData?: {
		width: number;
		height: number;
	},
	offeringModel = ProductStateModule.getOffering,
): ReturnData {
	let finalOfferingModel = offeringModel;

	if (
		!finalOfferingModel
		&& window.glPlatform === 'server'
	) {
		finalOfferingModel = (
			ProductStateModule.getOffering
			|| AppDataModule.offerings[0]
		);
	}

	if (!finalOfferingModel) {
		throw new Error('Missing required offering model');
	}

	// Get offering configuration parameters required to define scaling levels
	const {
		configdpi: configDpi,
		minimumdpi: minimumDpi,
		qualitydpi: qualityDpi,
	} = finalOfferingModel;

	// How many times can we scale the object bigger than its original size while meeting the minimum dpi requirement?
	const minimumQualityScale = configDpi / minimumDpi;
	// How many times can we scale the object bigger than its original size while meeting the quality dpi requirement?
	const recommendedQualityScale = configDpi / qualityDpi;

	let maxWidth = 0;
	let maxHeight = 0;

	// Define the default maximum width and height for page objects
	if (objectData.maxwidth) {
		maxWidth = objectData.maxwidth;
	}
	if (objectData.maxheight) {
		maxHeight = objectData.maxheight;
	}

	// Calculate the default maximum crop width and height
	let maxCroppedWidth = maxWidth;
	let maxCroppedHeight = maxHeight;

	if (
		photoData
		&& objectData.cropwidth
		&& objectData.cropheight
	) {
		// In case the page object is linked to a photo model, and we have applied a cropping to that photo,
		// we will limit the maximum crop width of the page object by the applied cropping ratio
		const photoWidthCropRatio = objectData.cropwidth / photoData.width;
		maxCroppedWidth *= photoWidthCropRatio;
		const photoHeightCropRatio = objectData.cropheight / photoData.height;
		maxCroppedHeight *= photoHeightCropRatio;
	}

	if (
		finalOfferingModel.applyUpscaling
		&& ProductStateModule.getProductSettings.applyEnhancement !== false
		&& photoData
	) {
		// When upscaling is enabled, the original photo can be enlarged with a factor
		// The upscale factor depends on the maximum output and the maximum recommended scale factor
		// We will multiply the object dimensions with this scale factor
		const upscaleFactor = Math.min(
			ConfigModule['upscaling.recommendedMaxScale'],
			Math.sqrt(
				ConfigModule['upscaling.maxOutput'] / (maxWidth * maxHeight),
			),
		);
		maxWidth *= upscaleFactor;
		maxHeight *= upscaleFactor;
		maxCroppedWidth *= upscaleFactor;
		maxCroppedHeight *= upscaleFactor;
	}

	// Apply the scale factors to the calculated dimensions
	const width = maxWidth * minimumQualityScale;
	const height = maxHeight * minimumQualityScale;
	const cropWidth = maxCroppedWidth * minimumQualityScale;
	const cropHeight = maxCroppedHeight * minimumQualityScale;
	const qualityWidth = maxWidth * recommendedQualityScale;
	const qualityHeight = maxHeight * recommendedQualityScale;
	const croppedQualityWidth = maxCroppedWidth * recommendedQualityScale;
	const croppedQualityHeight = maxCroppedHeight * recommendedQualityScale;

	return {
		width,
		height,
		qualityWidth,
		qualityHeight,
		cropWidth,
		cropHeight,
		croppedQualityWidth,
		croppedQualityHeight,
	};
}
