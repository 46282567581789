import _ from 'underscore';
import { nanoid } from 'nanoid';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import * as DB from 'interfaces/database';
import {
	AjaxOptions,
} from 'interfaces/app';
import UserDataModel from './defaults';
import { ERRORS_INVALID_REQUEST_DATA } from '../../../settings/errors';
import ajax from '../../../controllers/ajax';

@Module({ namespaced: true, name: 'userdata' })
export default class UserData extends VuexModule {
	credit: Partial<DB.CreditModel> = {
		currency: null,
		balance: 0,
	};

	_deviceId: string = nanoid();

	loyalty: DB.LoyaltyGroupModel | null = null;

	referralVoucher: DB.DiscountVoucherModel | null = null;

	tours: DB.UserTourModel[] = [];

	public get getCredit() {
		return this.credit;
	}

	public get getDeviceId() {
		return this._deviceId;
	}

	public get getLoyalty() {
		return this.loyalty;
	}

	public get getReferralVoucher() {
		return this.referralVoucher;
	}

	public get hasCompletedTour() {
		return (
			tourHandle: DB.UserTourModel['tourHandle'],
			days?: number,
		) => {
			const after = moment().subtract(
				days || 120,
				'days',
			);

			return this.tours.find(
				(tourEntry) => tourEntry.tourHandle === tourHandle && moment(tourEntry.completed) > after,
			);
		};
	}

	@Mutation
	public addTour(data: DB.UserTourModel) {
		this.tours.push(data);
	}

	@Mutation
	public reset() {
		Object.assign(
			this,
			_.omit(
				JSON.parse(JSON.stringify(UserDataModel)),
				'_deviceId',
			),
		);
	}

	@Mutation
	public setCredit(data: DB.CreditModel) {
		this.credit = data;
	}

	@Mutation
	public setDeviceId(id: string) {
		this._deviceId = id;
	}

	@Mutation
	public setLoyalty(data: DB.LoyaltyGroupModel) {
		this.loyalty = data;
	}

	@Mutation
	private _setReferralVoucher(data: DB.DiscountVoucherModel) {
		this.referralVoucher = data;
	}

	@Mutation
	public setToursModels(data: DB.UserTourModel[]) {
		this.tours = data;
	}

	@Action
	public addCompletedTour(
		tourHandle: DB.UserTourModel['tourHandle'],
	): Promise<void> {
		const { rootState, commit } = this.context;

		if (!tourHandle) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		const data: Partial<DB.UserTourModel> = {
			tourHandle,
			completed: moment().unix(),
		};

		const requestOptions: AxiosRequestConfig = {
			method: 'post',
			url: `/api/user/${rootState.user.id}/tour`,
			data,
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: false,
				abort: true,
			},
		};

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				if (response.data) {
					commit(
						'addTour',
						response.data,
					);
				}
			});
	}

	@Action
	public fetchCredit(): Promise<DB.CreditModel> {
		const { getters, rootState, commit } = this.context;

		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `/api/user/${rootState.user.id}/credit`,
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				if (response.data && response.data.currency) {
					commit(
						'setCredit',
						response.data,
					);
				}

				return getters.getCredit;
			});
	}

	@Action
	public fetchReferralVoucher(): Promise<DB.DiscountVoucherModel | null> {
		const { rootState, commit } = this.context;

		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `/api/user/${rootState.user.id}/referralvoucher`,
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				if (response.data) {
					commit(
						'_setReferralVoucher',
						response.data,
					);
				}
				return this.getReferralVoucher;
			});
	}

	@Action
	public loyaltySignup({ data }: {
		data: {
			first_name: string; // eslint-disable-line camelcase
			last_name: string; // eslint-disable-line camelcase
			email: string;
		};
	}): Promise<void> {
		const { commit, rootState } = this.context;

		if (!data) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		return ajax
			.request(
				{
					method: 'post',
					url: `/api/user/${rootState.user.id}/loyaltysignup`,
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data,
				},
				{
					auth: true,
				},
			)
			.then((response) => {
				if (response.data && response.data.user) {
					commit(
						'user/set',
						response.data.user,
						{ root: true },
					);
				}
				if (response.data && response.data.loyaltygroup) {
					commit(
						'setLoyalty',
						response.data.loyaltygroup,
					);
				}
			});
	}
}
