import * as PI from 'interfaces/project';
import { AppDataModule } from 'store';

export default function formatAddress(addressModel: PI.AddressModel): string[] {
	const arrLines = [
		addressModel.name || '',
		addressModel.address1 || '',
	];

	if (
		addressModel.address2
		&& addressModel.address2.length > 0
	) {
		arrLines.push(addressModel.address2);
	}

	const countryIso = addressModel.country;

	if (countryIso) {
		const countryModel = AppDataModule.findCountryWhere({
			iso: countryIso.trim(),
		});
		const city = addressModel.city || '';
		const zipcode = addressModel.zipcode || '';

		if (
			countryModel
			&& countryModel.states
			&& addressModel.state
		) {
			arrLines.push(`${city} ${addressModel.state} ${zipcode}`);
		} else {
			arrLines.push(`${zipcode}  ${city}`);
		}

		if (addressModel.countryFormatted) {
			arrLines.push(addressModel.countryFormatted);
		} else if (countryModel) {
			arrLines.push(AppDataModule.getCountryName(countryModel.id));
		}
	}

	return arrLines;
}
