import './defines';
import analytics from 'controllers/analytics';
import connector, { ConnectorOptions } from 'controllers/connector';
import {
	ChannelModel,
	ConnectFolderData,
} from 'interfaces/app';
import * as PI from 'interfaces/project';
import moment from 'moment';
import {
	ProductStateModule,
	ThemeStateModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	Prop,
	Ref,
	Vue,
} from 'vue-property-decorator';
import CloudPhotoView from '../cloud-photo';
import Template from './template.vue';

@Component({
	components: {
		CloudPhotoView,
	},
	watch: {
		albumid: 'resetCollection',
	},
})
export default class CloudPhotoSelectorView extends Vue.extend(Template) {
	@Prop({
		default: null,
		type: String,
	})
	public readonly albumid!: string;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly byCategory!: boolean;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly channelModel!: ChannelModel;

	@Prop({
		description: 'Indicates if the user can select multiple photos and we visually show if the photo is already selected',
		default: false,
		type: Boolean,
	})
	public readonly importMode!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if this is run inside the bottom toolbar.',
		type: Boolean,
	})
	public readonly toolbarMode!: boolean;

	@Ref('loadMoreButton')
	private readonly loadMoreButton!: HTMLElement;

	private categoryFilter: string | null = null;

	private destroyed = false;

	private folderids: string[] = [];

	private folders: {
		nextPage: string | null;
		nextOptions: string | null;
		fetched: boolean;
		collection: ConnectFolderData[];
	} = {
			nextPage: null,
			nextOptions: null,
			fetched: false,
			collection: [],
		};

	private fromDate: string | null = null;

	private toDate: string | null = null;

	private fromYear = -1;

	private fromMonth = -1;

	private loading = false;

	private photos: {
		nextPage: string | null;
		nextOptions: string | null;
		fetched: boolean;
		collection: Partial<PI.PhotoModel>[];
	} = {
			nextPage: null,
			nextOptions: null,
			fetched: false,
			collection: [],
		};

	// For future support of search box
	protected searchField = false;

	protected searchValue = null;

	protected get arrCategories() {
		return connector.getContentFilters(this.channelModel.id);
	}

	protected get arrMonths() {
		return [
			this.$t('calendar.months.january'),
			this.$t('calendar.months.february'),
			this.$t('calendar.months.march'),
			this.$t('calendar.months.april'),
			this.$t('calendar.months.may'),
			this.$t('calendar.months.june'),
			this.$t('calendar.months.july'),
			this.$t('calendar.months.august'),
			this.$t('calendar.months.september'),
			this.$t('calendar.months.october'),
			this.$t('calendar.months.november'),
			this.$t('calendar.months.december'),
		];
	}

	protected get arrYears() {
		const arrYears: number[] = [];

		for (let x = moment().year(); x > 2003; x -= 1) {
			arrYears.push(x);
		}

		return arrYears;
	}

	protected get filterByDate() {
		return this.channelModel.date && !this.albumid && !this.byCategory;
	}

	protected get folderCollection() {
		return this.folders.collection;
	}

	protected get hasSelectedPhotos() {
		return !!this.photos.collection.find(
			(cloudPhoto) => ProductStateModule.hasPhoto(cloudPhoto),
		);
	}

	protected get photoCollection() {
		return this.photos.collection;
	}

	private get photoCount() {
		return ProductStateModule.getPhotosSelected.length;
	}

	private get profilePicture() {
		return this.channelModel.profile
			? this.channelModel.profile.picture
			: undefined;
	}

	protected get selectAllButtonClass() {
		// Set the correct status to the toolbar buttons
		let className = 'toolbutton';
		if (this.loading) {
			className += ' disabled';
		} else if (this.photos.collection.length === 0 || (this.themeModel && this.themeModel.maxphotos === 1)) {
			className += ' disabled';
		} else {
			// Check for a photo that is not selected
			const unselected = _.find(
				this.photos.collection,
				(cloudphoto) => !ProductStateModule.hasPhoto(cloudphoto),
			);
			if (!unselected && !this.photos.nextPage && !this.photos.nextOptions) {
				className += ' pressed';
			}
		}

		return className;
	}

	protected get showLevelUpButton() {
		return this.albumid || this.folderids.length;
	}

	private get themeModel() {
		return ThemeStateModule.themeModel;
	}

	protected created() {
		if (this.channelModel.switch && !this.profilePicture) {
			connector.me(this.channelModel.id);
		}
	}

	protected mounted() {
		// Fetch collection from api if this hasn't been done before
		if (this.channelModel.folders && !this.folders.fetched) {
			this.fetchFolderCollection();
		} else if (!this.albumid && this.channelModel.date) {
			this.setDate();
		} else if (!this.photos.fetched) {
			this.fetchPhotoCollection();
		}
	}

	protected beforeDestroy() {
		this.destroyed = true;
	}

	protected browseByAlbum() {
		this.$emit('browseByAlbum');
	}

	protected browseByCategory() {
		this.categoryFilter = null;
		this.resetCollection();
		this.$emit('browseByCategory');
	}

	protected browseByDate() {
		this.categoryFilter = null;
		this.resetCollection();
		this.$emit('browseByDate');
	}

	protected unselectPhoto(cloudPhotoModel: PI.PhotoModel): void {
		const photoModel = ProductStateModule.findPhoto(cloudPhotoModel);

		if (photoModel) {
			ProductStateModule.removePhoto(photoModel.id);
		}
	}

	protected isSelected(cloudPhotoModel: PI.PhotoModel): boolean {
		return ProductStateModule.hasPhoto(cloudPhotoModel);
	}

	protected changeCategory() {
		analytics.trackEvent(
			'Filter photos by category',
			{
				category: 'Filter',
				label: 'Category',
				value: this.categoryFilter,
			},
		);

		this.resetCollection();
	}

	protected changeDate() {
		let selectedDate = `${this.fromYear}`;
		if (this.fromMonth !== -1) {
			selectedDate += `/${1 + this.fromMonth}`;
		}

		analytics.trackEvent(
			'Filter photos by date',
			{
				category: 'Filter',
				label: 'Date',
				value: selectedDate,
			},
		);

		this.setDate();
	}

	protected disableLoadingState() {
		if (!this.destroyed) {
			this.loading = false;
		}
	}

	private fetchFolderCollection() {
		if (!this.loading && (!this.folders.fetched || this.folders.nextPage || this.folders.nextOptions)) {
			this.loading = true;

			const options: ConnectorOptions = {
				folderid: _.last(this.folderids),
			};
			if (this.folders.nextPage) {
				options.nextPage = this.folders.nextPage;
			}
			if (this.folders.nextOptions) {
				options.nextOptions = this.folders.nextOptions;
			}

			connector.folders(
				this.channelModel.id,
				options,
			)
				.then(
					({ paging, folderCollection, photoCollection }) => {
						this.disableLoadingState();

						if (!this.destroyed) {
							this.folders.nextPage = paging && paging.nextPage ? paging.nextPage : null;
							this.folders.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
							this.folders.fetched = true;
							this.folders.collection = this.folders.collection.concat(folderCollection);
							this.photos.collection = this.photos.collection.concat(photoCollection);
						}
					},
					(err) => {
						this.disableLoadingState();

						if (!this.destroyed) {
							this.$openErrorDialog({
								body: {
									content: err,
								},
							});
						}
					},
				);
		}
	}

	private fetchPhotoCollection() {
		if (!this.loading && (!this.photos.fetched || this.photos.nextPage || this.photos.nextOptions)) {
			this.loading = true;

			const options: ConnectorOptions = {};
			if (this.byCategory && this.categoryFilter) {
				options.category = this.categoryFilter;
			}
			if (this.channelModel.date) {
				if (this.fromDate) options.fromDate = this.fromDate;
				if (this.toDate) options.toDate = this.toDate;
			}
			if (this.photos.nextPage) {
				options.nextPage = this.photos.nextPage;
			}
			if (this.photos.nextOptions) {
				options.nextOptions = this.photos.nextOptions;
			}

			if (this.albumid) {
				// Fetch the photos of one specific album
				connector.albumPhotos(
					this.channelModel.id,
					this.albumid,
					options,
				)
					.then(
						({ data, paging }) => {
							this.disableLoadingState();

							if (!this.destroyed && this.albumid) {
								this.photos.nextPage = paging && paging.nextPage ? paging.nextPage : null;
								this.photos.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
								this.photos.fetched = true;
								this.photos.collection = this.photos.collection.concat(data);
							} else if (!this.destroyed) {
								this.resetCollection();
								this.fetchPhotoCollection();
							}
						},
						(err) => {
							this.disableLoadingState();

							if (!this.destroyed) {
								this.$openErrorDialog({
									body: {
										content: err,
									},
								});
							}
						},
					);
			} else {
				// Fetch the photos of this user
				connector.photos(
					this.channelModel.id,
					options,
				)
					.then(
						({ data, paging }) => {
							if (
								!this.destroyed
								&& (!this.byCategory || this.categoryFilter == options.category)
								&& (!this.fromDate || this.fromDate == options.fromDate)
								&& (!this.toDate || this.toDate == options.toDate)
							) {
								this.photos.nextPage = paging && paging.nextPage ? paging.nextPage : null;
								this.photos.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
								this.photos.fetched = true;
								this.photos.collection = this.photos.collection.concat(data);
							} else if (!this.destroyed) {
								this.resetCollection();
								this.fetchPhotoCollection();
							}

							this.disableLoadingState();
						},
						(err) => {
							this.disableLoadingState();

							if (!this.destroyed) {
								this.$openErrorDialog({
									body: {
										content: err.message || err,
									},
								});

								connector.logoutNetwork('instagram');
							}
						},
					);
			}
		}
	}

	protected importPhotos() {
		this.$emit('importPhotos');
	}

	protected levelUp() {
		if (this.folderids.length) {
			this.folderids.pop();
			this.photos.collection = [];
			this.photos.nextPage = null;
			this.photos.nextOptions = null;
			this.photos.fetched = false;
			this.folders.collection = [];
			this.folders.nextPage = null;
			this.folders.nextOptions = null;
			this.folders.fetched = false;
			this.fetchFolderCollection();
		} else {
			this.$emit('levelUp');
		}
	}

	private loadMore() {
		if (this.channelModel.folders) {
			this.fetchFolderCollection();
		} else {
			this.fetchPhotoCollection();
		}
	}

	private resetCollection() {
		this.photos.fetched = false;
		this.photos.nextPage = null;
		this.photos.nextOptions = null;
		this.photos.collection = [];
		this.folderids = [];
		this.folders.fetched = false;
		this.folders.nextPage = null;
		this.folders.nextOptions = null;
		this.folders.collection = [];

		// Fetch collection from api if this hasn't been done before
		if (this.channelModel.folders && !this.folders.fetched) {
			this.fetchFolderCollection();
		} else if (!this.albumid && this.channelModel.date) {
			this.setDate();
		} else if (!this.photos.fetched) {
			this.fetchPhotoCollection();
		}
	}

	protected scrollToBottom() {
		_.debounce(
			() => {
				if (this.loadMoreButton) {
					const rect = this.loadMoreButton.getBoundingClientRect();
					if (
						rect.top >= 0
						&& rect.left >= 0
						&& rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
						&& rect.right <= (window.innerWidth || document.documentElement.clientWidth)
					) {
						this.loadMore();
					}
				}
			},
			200,
		)();
	}

	private setDate() {
		let fromDate = null;
		let toDate = null;

		if (this.fromYear > 0) {
			let toYear = this.fromYear;
			let { fromMonth } = this;
			let toMonth;

			if (fromMonth === -1) {
				fromMonth = 0;
				toMonth = 0;
				toYear += 1;
			} else if (fromMonth === 11) {
				toYear += 1;
				toMonth = 0;
			} else {
				toMonth = fromMonth + 1;
			}

			fromDate = moment().set({ year: this.fromYear, month: fromMonth, date: 1 }).format();
			toDate = moment().set({ year: toYear, month: toMonth, date: 1 }).format();
		}

		this.fromDate = fromDate;
		this.toDate = toDate;
		this.photos.fetched = false;
		this.photos.nextPage = null;
		this.photos.nextOptions = null;
		this.photos.collection = [];

		this.fetchPhotoCollection();
	}

	private selectAll() {
		if (!this.loading
			&& this.photos.collection.length > 0
			&& (!this.themeModel || this.themeModel.maxphotos)
		) {
			const maxphotos = !this.themeModel || _.isNull(this.themeModel.maxphotos)
				? 200
				: this.themeModel.maxphotos;
			const unselected = _.filter(
				this.photos.collection,
				(cloudphoto) => !ProductStateModule.hasPhoto(cloudphoto),
			);

			if (this.photoCount + unselected.length > maxphotos) {
				this.$openErrorDialog({
					body: {
						content: this.$t(
							'uploadErrorTooMaanyPhotos',
							{
								maxphotos,
								skipcount: this.photoCount + unselected.length - maxphotos,
							},
						),
					},
				});
			}

			if (
				this.channelModel.folders
				&& (this.folders.nextPage || this.folders.nextOptions)
				&& this.photoCount + unselected.length < maxphotos
			) {
				// Show loading dialog
				const closeLoader = this.$openLoaderDialog();

				// Fetch the photos of this user
				connector
					.folders(
						this.channelModel.id,
						{
							folderid: _.last(this.folderids),
						},
					)
					.then(
						() => {
							this.selectAll();
						},
						() => {
							// Close loading dialog
							closeLoader();
						},
					);
			} else if (
				(this.photos.nextPage || this.photos.nextOptions)
				&& this.photoCount + unselected.length < maxphotos
			) {
				// Show loading dialog
				const closeLoader = this.$openLoaderDialog();

				const options: ConnectorOptions = {};
				if (this.photos.nextPage) {
					options.nextPage = this.photos.nextPage;
				}
				if (this.photos.nextOptions) {
					options.nextOptions = this.photos.nextOptions;
				}

				if (this.albumid) {
					// Fetch the photos of one specific album
					connector
						.albumPhotos(
							this.channelModel.id,
							this.albumid,
							options,
						)
						.then(
							({ data, paging }) => {
								this.photos.nextPage = paging && paging.nextPage ? paging.nextPage : null;
								this.photos.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
								this.photos.fetched = true;
								this.photos.collection = this.photos.collection.concat(data);

								this.selectAll();
							},
							(err) => {
								// Close loading dialog
								closeLoader();

								this.$openErrorDialog({
									body: {
										content: err,
									},
								});
							},
						);
				} else {
					// Fetch the photos of this user
					connector
						.photos(
							this.channelModel.id,
							options,
						)
						.then(
							({ data, paging }) => {
								this.photos.nextPage = paging && paging.nextPage ? paging.nextPage : null;
								this.photos.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
								this.photos.fetched = true;
								this.photos.collection = this.photos.collection.concat(data);

								this.selectAll();
							},
							() => {
								// Close loading dialog
								closeLoader();
							},
						);
				}
			} else if (unselected.length) {
				for (let x = 0; x < unselected.length && this.photoCount < maxphotos; x += 1) {
					this.selectPhoto(unselected[x]);
				}

				this.$closeLoaderDialog();
			} else {
				_.each(
					this.photos.collection,
					(modelData) => {
						const photoModel = ProductStateModule.findPhoto(modelData);
						if (photoModel) {
							ProductStateModule.removePhoto(photoModel.id);
						}
					},
				);

				this.$closeLoaderDialog();
			}
		}
	}

	protected selectFolder(folderModel: ConnectFolderData) {
		this.folderids.push(folderModel.id);
		this.photos.collection = [];
		this.photos.nextPage = null;
		this.photos.nextOptions = null;
		this.photos.fetched = false;
		this.folders.collection = [];
		this.folders.nextPage = null;
		this.folders.nextOptions = null;
		this.folders.fetched = false;
		this.fetchFolderCollection();
	}

	private selectPhoto(photoModel: Partial<PI.PhotoModel>) {
		this.$emit(
			'selectPhoto',
			photoModel,
		);
	}

	protected switchAccount() {
		analytics.trackEvent(
			'Switch cloud account',
			{
				category: 'Button',
			},
		);

		const closeConfirm = this.$openConfirmDialog({
			header: {
				title: this.$t('dialogHeaderOauthSwitch'),
			},
			body: {
				content: this.$t(
					'dialogTextOauthSwitch',
					{
						network: this.channelModel.id,
					},
				),
			},
			footer: {
				buttons: [
					{
						id: 'cancel',
						text: this.$t('dialogButtonCancel'),
						click: () => {
							closeConfirm();
						},
					},
					{
						id: 'confirm',
						text: this.$t('dialogButtonOk'),
						click: () => {
							connector.logoutNetwork(
								this.channelModel.id,
								{ force: true },
							);
							closeConfirm();
						},
					},
				],
			},
		});
	}
}
