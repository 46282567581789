import {
	EditorVectorEditColorsImageVectorColor,
	EditorVectorEditColorsImageVectorColors,
	LogoColorsResult,
} from 'interfaces/app';
import { OfferingModel } from 'interfaces/database';
import {
	PageObjectModel,
	PageObjectVectorColor,
} from 'interfaces/project';

export function check(
	offeringModel: OptionalExceptFor<OfferingModel, 'color'>,
	objectModel: OptionalExceptFor<PageObjectModel, 'colorReplacement'>,
): LogoColorsResult {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const logoColors = getUniqueUsedColors(objectModel).size;

	return {
		logoColors,
		offeringColors: offeringModel.color,
		valid: logoColors <= offeringModel.color,
	};
}

export function getColors(objectModel: OptionalExceptFor<PageObjectModel, 'colorReplacement' | '_vectorColors'>): EditorVectorEditColorsImageVectorColors {
	if (!objectModel._vectorColors) {
		return {
			foreground: [],
		};
	}

	const vectorColorsTypeKeys = Object.keys(objectModel._vectorColors) as Array<keyof EditorVectorEditColorsImageVectorColors>;
	const { _vectorColors } = objectModel;

	return vectorColorsTypeKeys.reduce(
		(photoVectorColors, vectorColorsTypeKey) => {
			let vectorColorsKeys: string[] = [];
			const vectorColorsType = _vectorColors[vectorColorsTypeKey];

			if (vectorColorsType instanceof Map) {
				vectorColorsKeys = Array.from(vectorColorsType.keys());
			} else if (vectorColorsType) {
				vectorColorsKeys = [vectorColorsType.color];
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const vectorColorKey of vectorColorsKeys) {
				let vectorColor: PageObjectVectorColor | undefined;

				if (vectorColorsType instanceof Map) {
					vectorColor = vectorColorsType.get(vectorColorKey);
				} else if (vectorColorsType?.color === vectorColorKey) {
					vectorColor = vectorColorsType;
				}

				if (vectorColor) {
					const photoVectorColor: EditorVectorEditColorsImageVectorColor = {
						color: vectorColorKey,
						matches: vectorColor.matches,
					};

					if (vectorColorsTypeKey === 'foreground') {
						photoVectorColors[vectorColorsTypeKey].push(photoVectorColor);
					} else {
						photoVectorColors[vectorColorsTypeKey] = photoVectorColor;
					}

					const colorReplacementFound = objectModel.colorReplacement?.find((colorReplacement) => colorReplacement.color === vectorColorKey);

					if (colorReplacementFound) {
						photoVectorColor.replace = {
							...colorReplacementFound.replace,
						};
					}
				}
			}

			return photoVectorColors;
		},
		{
			foreground: [],
		} as EditorVectorEditColorsImageVectorColors,
	);
}

export function getUniqueUsedColors(objectModel: OptionalExceptFor<PageObjectModel, 'colorReplacement'>): Set<string> {
	return new Set(
		objectModel?.colorReplacement
			?.map((vectorColor) => (
				vectorColor.replace?.real.toLocaleLowerCase()
				|| vectorColor.color.toLocaleLowerCase()
			))
			.filter((color) => color !== 'transparent'),
	);
}
