import './defines';
import GoogleIcon from '@root/svg/google-icon.svg';
import connector from 'controllers/connector';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		GoogleIcon,
	},
})
export default class GoogleButtonView extends Vue.extend(Template) {
	private disabled = true;

	protected mounted() {
		connector
			.setup('gplus')
			.then(() => connector.init('gplus'))
			.then(() => {
				this.disabled = false;
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	protected clickButton() {
		if (!this.disabled) {
			this.$emit('buttonClicked');
		}
	}
}
