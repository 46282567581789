import './defines';
import {
	HeaderAccountNavigate,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import ClickOutside from 'vue-click-outside';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	directives: {
		ClickOutside,
	},
})
export default class AccountHeaderView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates if the menu must show a login option or not',
		type: Boolean,
	})
	public readonly hasLogin!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the menu should show the "my account" link',
		type: Boolean,
	})
	public readonly showAccount!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the menu should show the "inbox" link',
		type: Boolean,
	})
	public readonly showInbox!: boolean;

	@Prop({
		default: 0,
		description: 'Number of unread messages in the user\'s inbox',
		type: Number,
	})
	public readonly unreadMessageCount!: number;

	@Prop({
		description: 'Defines the user model for the active user',
		required: true,
		schema: 'UserModel',
		type: Object,
	})
	public readonly user!: DB.UserModel;

	private open = false;

	protected clickOutside() {
		if (this.open) {
			this.open = false;
		}
	}

	protected goToAccount() {
		this.open = false;
		const event: HeaderAccountNavigate = 'account';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToAccountPhotos() {
		this.open = false;
		const event: HeaderAccountNavigate = 'account-photos';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToInbox() {
		this.open = false;
		const event: HeaderAccountNavigate = 'inbox';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToCreate() {
		this.open = false;
		const event: HeaderAccountNavigate = 'create';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToLogin() {
		this.open = false;
		const event: HeaderAccountNavigate = 'login';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToLogout() {
		this.open = false;
		const event: HeaderAccountNavigate = 'logout';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToShelf() {
		this.open = false;
		const event: HeaderAccountNavigate = 'shelf';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected goToSignup() {
		this.open = false;
		const event: HeaderAccountNavigate = 'signup';
		this.$emit(
			'navigate',
			event,
		);
	}

	protected toggleOpen() {
		this.open = !this.open;
	}

	protected userSettings() {
		this.open = false;
		this.$emit('show-settings');
	}
}
