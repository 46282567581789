import './defines';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import { ButtonToggleComponentVariant } from 'interfaces/app';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ButtonToggleComponent',
})
export default class ButtonToggleComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: false,
			description: 'Indicates the checked state of the button toggle',
			type: Boolean,
		},
	)
	public readonly checked!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the button toggle can be toggle on/off by the user',
		type: Boolean,
	})
	public readonly canToggle!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the button toggle layout (in case it contains both the slot content and the label) should be inverted',
		type: Boolean,
	})
	public readonly inverseLayout?: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the name of the button toggle (used for button toggle groups)',
		type: String,
	})
	public readonly name?: string;

	@Prop({
		default: false,
		description: 'Indicates if the button toggle small variant should be used',
		type: Boolean,
	})
	public readonly small!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the value of the button toggle (used for button toggle groups)',
		type: [Number, String],
	})
	public readonly value?: number | string;

	@Prop({
		acceptedValues: [
			'default',
			'big',
			'big-filled',
		],
		default: 'default',
		description: 'Defines the button toggle variant (color)',
		type: String,
	})
	public readonly variant!: ButtonToggleComponentVariant;

	protected get computedClasses(): Record<string, boolean> {
		return {
			'button-toggle-component-big': (
				this.variant === 'big'
				|| this.variant === 'big-filled'
			),
			'button-toggle-component-checked': this.internalChecked,
			'button-toggle-component-filled': this.variant === 'big-filled',
			'button-toggle-component-inverse-layout': !!this.inverseLayout,
			'button-toggle-component-small': this.small,
		};
	}

	@Ref('input')
	protected readonly inputElement!: HTMLInputElement;

	protected internalChecked = false;

	@Watch(
		'checked',
		{
			immediate: true,
		},
	)
	protected onCheckedChange(): void {
		this.$nextTick(() => {
			this.internalChecked = this.checked;
		});
	}

	@Watch('internalChecked')
	protected onInternalCheckedChange(): void {
		if (this.internalChecked !== this.checked) {
			this.$emit(
				'change',
				this.internalChecked,
				{
					preventDefault: () => {
						this.internalChecked = this.checked;
					},
				},
			);
		}

		if (
			this.internalChecked
			&& this.name
		) {
			document
				.querySelectorAll<HTMLInputElement>(`input[name="${this.name}"][type="radio"]`)
				.forEach((inputElement: HTMLInputElement) => {
					if (inputElement !== this.inputElement) {
						inputElement.checked = false;
						inputElement.dispatchEvent(new Event('change'));
					}
				});
		}
	}

	@Watch('value')
	protected onValueChange(): void {
		/**
		 * In case the parent is a button toggle group,
		 * we need to force a value sync to make sure the
		 * child check state is in sync with the parent
		 * value.
		 */
		if (this.$parent instanceof ButtonToggleGroupComponent) {
			this.$parent.childrenSyncValues();
		}
	}

	protected onChange(event: InputEvent): void {
		this.internalChecked = (event.target as HTMLInputElement).checked;
	}

	protected onClick(): void {
		if (!this.canToggle) {
			this.internalChecked = true;
		} else {
			this.internalChecked = !this.internalChecked;
		}

		this.$emit('click');
	}
}
