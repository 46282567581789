import PageObject from 'classes/pageobject';
import TemplatePosition from 'classes/templateposition';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import touch from 'controllers/touch';
import FontFaceObserver from 'fontfaceobserver';
import { InteractionMapModel, TemplatePhotoPosition, TemplateTextPosition } from 'interfaces/app';
import * as PI from 'interfaces/project';
import changeObjectPhoto from 'mutations/pageobject/change-photo';
import { ERRORS_LOAD_FONT } from 'settings/errors';
import store, {
	AppStateModule,
	FontModule,
	PhotosModule,
	ProductStateModule,
} from 'store';
import getColorInverse from 'tools/get-color-inverse';
import intersect from 'tools/intersect';
import maxObjectSize from 'tools/max-object-size';
import pointRectangleCollision from 'tools/point-rectangle-collision';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';

interface Rect {
	minx: number;
	miny: number;
	maxx: number;
	maxy: number;
}

interface Warning extends Rect {
	text: string;
}

@Component({
	watch: {
		activeDragging: 'drawOnCanvas',
		map: 'drawOnCanvas',
		objectLock: 'drawOnCanvas',
		hoveredModel: 'drawOnCanvas',
		scaling: 'scaleChange',
		pageModel: 'deselectObjects',
	},
})
export default class PageInteractionView extends Vue {
	@Prop({ type: Boolean, default: false }) readonly draggable!: boolean;

	@Prop({ type: Boolean, default: false }) readonly editable!: boolean;

	@Prop({ required: true, type: Object }) readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Array }) readonly pageObjects!: PI.PageObjectModel[];

	@Prop({ required: true, type: Number }) readonly scaling!: number;

	@Prop({ type: Boolean, default: false }) readonly showBleed!: boolean;

	@Prop({ type: Boolean, default: false }) readonly showDepth!: boolean;

	@Prop({ type: Boolean, default: false }) readonly resizable!: boolean;

	@Prop({ type: Boolean, default: false }) readonly rotatable!: boolean;

	@Prop({ required: true, type: Array }) readonly templatePositionsAvailable!: (TemplatePhotoPosition | TemplateTextPosition)[];

	private activeDragging = false;

	private hoveredModel: InteractionMapModel | null = null;

	private iconFontsLoadAttempts = 0;

	private iconFontsLoaded = false;

	private iconFonts = [
		'Material Icons Outlined',
		'Material Icons',
	];

	private iconSize = 20;

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset ? this.pageModel.offset : 0;
	}

	get canvasHeight() {
		const pageHeight = this.pageModel.height + 2 * this.bleedMargin;
		return Math.round(pageHeight * this.scaling);
	}

	get canvasWidth() {
		const pageWidth = this.pageModel.width + 2 * this.bleedMargin;
		return Math.round(pageWidth * this.scaling);
	}

	get depth() {
		return this.offeringModel?.depth || 0;
	}

	get fillColor() {
		return this.locked
			? store.state.config['editor.frameColors.locked.fill']
			: store.state.config['editor.frameColors.unlocked.fill'];
	}

	get iconDrag() {
		return 'open_with';
	}

	get iconDragFont() {
		return `normal normal 400 ${this.iconSize}px "Material Icons"`;
	}

	get iconLocked() {
		return 'lock';
	}

	get iconLockedFont() {
		return `normal normal 400 ${this.iconSize}px "Material Icons"`;
	}

	get iconPhoto() {
		return 'add_photo_alternate';
	}

	get iconPhotoFont() {
		return (size: number) => `normal normal 400 ${size}px "Material Icons Outlined"`;
	}

	get iconResize() {
		return 'open_in_full';
	}

	get iconResizeFont() {
		return `normal normal 700 ${this.iconSize}px "Material Icons Outlined"`;
	}

	get iconRotate() {
		return 'rotate_right';
	}

	get iconRotateFont() {
		return `normal normal 700 ${this.iconSize}px "Material Icons Outlined"`;
	}

	get iconText() {
		return 'post_add';
	}

	get iconTextFont() {
		return (size: number) => `normal normal 400 ${size}px "Material Icons Outlined"`;
	}

	get iconTrash() {
		return 'delete';
	}

	get iconTrashFont() {
		return `normal normal 700 ${this.iconSize}px "Material Icons Outlined"`;
	}

	get iconUnlocked() {
		return 'lock_open';
	}

	get iconUnlockedFont() {
		return `normal normal 400 ${this.iconSize}px "Material Icons Outlined"`;
	}

	get iconWarning() {
		return 'report_problem';
	}

	get iconWarningFont() {
		return 'normal normal 700 28px "Material Icons Outlined"';
	}

	get iconWarningBackground() {
		return 'warning';
	}

	get iconWarningBackgroundFont() {
		return 'normal normal 700 28px "Material Icons"';
	}

	get strokeColor() {
		return this.locked
			? store.state.config['editor.frameColors.locked.stroke']
			: store.state.config['editor.frameColors.unlocked.stroke'];
	}

	get lineColor() {
		return this.locked
			? store.state.config['editor.frameColors.locked.line']
			: store.state.config['editor.frameColors.unlocked.line'];
	}

	get locked() {
		return !this.selectedObjectModel
			|| !this.selectedObjectModel.transformable
			|| this.hoveredModel
			|| (this.objectLock && this.selectedObjectModel.type == 'photo');
	}

	get map() {
		let map: InteractionMapModel[] = [];
		if (this.pageModel.editable && this.editable) {
			if (this.mapModelLock) map.push(this.mapModelLock);
			if (this.mapModelDrag) map.push(this.mapModelDrag);
			if (this.mapModelResizer) map.push(this.mapModelResizer);
			if (this.mapModelRotator) map.push(this.mapModelRotator);
			if (this.mapModelCropLeft) map.push(this.mapModelCropLeft);
			if (this.mapModelCropRight) map.push(this.mapModelCropRight);
			map = map.concat(
				this.mapModelsObjects,
				this.mapModelsTemplatePositions,
			);
		}

		return map;
	}

	get mapModelCropLeft() {
		if (this.selectedObjectModel && this.selectedObjectModel._crop) {
			const { scaling } = this;
			const vars = PageObject.calculatePosition(
				{
					x_axis: this.selectedObjectModel.x_axis,
					y_axis: this.selectedObjectModel.y_axis,
					width: this.selectedObjectModel.width,
					height: this.selectedObjectModel.height,
					borderwidth: this.selectedObjectModel.borderwidth,
					bordercolor: this.selectedObjectModel.bordercolor,
					cropx: this.selectedObjectModel.cropx,
					cropy: this.selectedObjectModel.cropy,
					cropwidth: this.selectedObjectModel.cropwidth,
					cropheight: this.selectedObjectModel.cropheight,
					angle: this.selectedObjectModel.rotate,
					flop: Boolean(this.selectedObjectModel.flop),
					flip: Boolean(this.selectedObjectModel.flip),
					type: this.selectedObjectModel.type,
					maxwidth: this.selectedObjectModel.maxwidth,
					photoid: this.selectedObjectModel.photoid || undefined,
				},
				scaling,
				null,
				this.bleedMargin,
			);
			const map: InteractionMapModel = {
				type: 'cropleft',
				topleft: {
					x: Math.min(
						Math.round(this.pageModel.width * scaling) - 16,
						vars.topleft.x * scaling,
					) - 16,
					y: Math.min(
						Math.round(this.pageModel.height * scaling) - 16,
						vars.topleft.y * scaling,
					) - 16,
				},
				topright: {
					x: Math.min(
						Math.round(this.pageModel.width * scaling) - 16,
						vars.topleft.x * scaling,
					) + 16,
					y: Math.min(
						Math.round(this.pageModel.height * scaling) - 16,
						vars.topleft.y * scaling,
					) - 16,
				},
				bottomright: {
					x: Math.min(
						Math.round(this.pageModel.width * scaling) - 16,
						vars.topleft.x * scaling,
					) + 16,
					y: Math.min(
						Math.round(this.pageModel.height * scaling) - 16,
						vars.topleft.y * scaling,
					) + 16,
				},
				bottomleft: {
					x: Math.min(
						Math.round(this.pageModel.width * scaling) - 16,
						vars.topleft.x * scaling,
					) - 16,
					y: Math.min(
						Math.round(this.pageModel.height * scaling) - 16,
						vars.topleft.y * scaling,
					) + 16,
				},
			};
			return map;
		} return null;
	}

	get mapModelCropRight() {
		if (this.selectedObjectModel && this.selectedObjectModel._crop) {
			const vars = PageObject.calculatePosition(
				{
					x_axis: this.selectedObjectModel.x_axis,
					y_axis: this.selectedObjectModel.y_axis,
					width: this.selectedObjectModel.width,
					height: this.selectedObjectModel.height,
					borderwidth: this.selectedObjectModel.borderwidth,
					bordercolor: this.selectedObjectModel.bordercolor,
					cropx: this.selectedObjectModel.cropx,
					cropy: this.selectedObjectModel.cropy,
					cropwidth: this.selectedObjectModel.cropwidth,
					cropheight: this.selectedObjectModel.cropheight,
					angle: this.selectedObjectModel.rotate,
					flop: Boolean(this.selectedObjectModel.flop),
					flip: Boolean(this.selectedObjectModel.flip),
					type: this.selectedObjectModel.type,
					maxwidth: this.selectedObjectModel.maxwidth,
					photoid: this.selectedObjectModel.photoid || undefined,
				},
				this.scaling,
				null,
				this.bleedMargin,
			);
			const map: InteractionMapModel = {
				type: 'cropright',
				topleft: {
					x: Math.min(
						Math.round(this.pageModel.width * this.scaling) - 16,
						vars.bottomright.x * this.scaling,
					) - 16,
					y: Math.min(
						Math.round(this.pageModel.height * this.scaling) - 16,
						vars.bottomright.y * this.scaling,
					) - 16,
				},
				topright: {
					x: Math.min(
						Math.round(this.pageModel.width * this.scaling) - 16,
						vars.bottomright.x * this.scaling,
					) + 16,
					y: Math.min(
						Math.round(this.pageModel.height * this.scaling) - 16,
						vars.bottomright.y * this.scaling,
					) - 16,
				},
				bottomright: {
					x: Math.min(
						Math.round(this.pageModel.width * this.scaling) - 16,
						vars.bottomright.x * this.scaling,
					) + 16,
					y: Math.min(
						Math.round(this.pageModel.height * this.scaling) - 16,
						vars.bottomright.y * this.scaling,
					) + 16,
				},
				bottomleft: {
					x: Math.min(
						Math.round(this.pageModel.width * this.scaling) - 16,
						vars.bottomright.x * this.scaling,
					) - 16,
					y: Math.min(
						Math.round(this.pageModel.height * this.scaling) - 16,
						vars.bottomright.y * this.scaling,
					) + 16,
				},
			};
			return map;
		} return null;
	}

	get mapModelDrag() {
		if (this.selectedObjectModel
			&& this.selectedObjectModel.transformable
			&& this.selectedObjectModel.type === 'text'
		) {
			const map: InteractionMapModel = {
				id: this.selectedObjectModel.id,
				type: 'dragger',
				topleft: {
					x: this.selectionBox.rectCenterX - 16,
					y: this.selectionBox.rectCenterY - 16,
				},
				topright: {
					x: this.selectionBox.rectCenterX + 16,
					y: this.selectionBox.rectCenterY - 16,
				},
				bottomright: {
					x: this.selectionBox.rectCenterX + 16,
					y: this.selectionBox.rectCenterY + 16,
				},
				bottomleft: {
					x: this.selectionBox.rectCenterX - 16,
					y: this.selectionBox.rectCenterY + 16,
				},
			};
			return map;
		} return null;
	}

	get mapModelLock() {
		if (!this.activeDragging
			&& this.selectedObjectModel
			&& this.selectedObjectModel.transformable
			&& this.selectedObjectModel.type === 'photo'
			&& (this.objectLock
				|| (this.rect.maxx - this.rect.minx > 32 && this.rect.maxy - this.rect.miny > 32)
			)
		) {
			const map: InteractionMapModel = {
				type: 'lock',
				topleft: {
					x: this.selectionBox.rectCenterX - 16,
					y: this.selectionBox.rectCenterY - 16,
				},
				topright: {
					x: this.selectionBox.rectCenterX + 16,
					y: this.selectionBox.rectCenterY - 16,
				},
				bottomright: {
					x: this.selectionBox.rectCenterX + 16,
					y: this.selectionBox.rectCenterY + 16,
				},
				bottomleft: {
					x: this.selectionBox.rectCenterX - 16,
					y: this.selectionBox.rectCenterY + 16,
				},
			};
			return map;
		}

		return null;
	}

	get mapModelsObjects() {
		const models: InteractionMapModel[] = [];
		this.pageObjects.forEach(
			(objectModel) => {
				if (objectModel.editable) {
					const vars = PageObject.calculatePosition(
						{
							x_axis: objectModel.x_axis,
							y_axis: objectModel.y_axis,
							width: objectModel.width,
							height: objectModel.height,
							borderwidth: objectModel.borderwidth,
							bordercolor: objectModel.bordercolor,
							cropx: objectModel.cropx,
							cropy: objectModel.cropy,
							cropwidth: objectModel.cropwidth,
							cropheight: objectModel.cropheight,
							angle: objectModel.rotate,
							flop: Boolean(objectModel.flop),
							flip: Boolean(objectModel.flip),
							type: objectModel.type,
							maxwidth: objectModel.maxwidth,
							photoid: objectModel.photoid || undefined,
						},
						this.scaling,
						null,
						this.bleedMargin,
					);
					models.unshift(_.extend(
						vars.imagemap,
						{
							id: objectModel.id,
							type: `${objectModel.type}object`,
						},
					));
				}
			},
		);

		return models;
	}

	get mapModelsTemplatePositions() {
		const map: InteractionMapModel[] = [];

		// Photo positions
		const photoPositions = this.templatePositionsVisible.filter(
			(m) => m.type == 'photo',
		) as TemplatePhotoPosition[];
		photoPositions.forEach((photoPosition) => {
			const vars = PageObject.calculatePosition(
				{
					x_axis: photoPosition.x,
					y_axis: photoPosition.y,
					width: photoPosition.width,
					height: photoPosition.height,
					borderwidth: photoPosition.borderwidth,
					bordercolor: photoPosition.bordercolor,
					angle: photoPosition.angle,
					type: 'photo',
				},
				this.scaling,
				null,
				this.bleedMargin,
			);
			const mapModel: InteractionMapModel = _.extend(
				vars.imagemap,
				{
					type: 'photoposition',
					positionstateid: photoPosition.id,
				},
			);
			map.push(mapModel);
		});

		// Draw available text positions that do not overlap with photo positions from the current carousel
		const textPositions = this.templatePositionsVisible.filter(
			(m) => m.type == 'text',
		) as TemplateTextPosition[];
		textPositions.forEach((templateTextPosition) => {
			const overlap = photoPositions.find(
				(photoPosition) => intersect(
					{
						x: photoPosition.x + photoPosition.overlap_left + 1,
						y: photoPosition.y + photoPosition.overlap_top + 1,
						width: photoPosition.width - photoPosition.overlap_left - photoPosition.overlap_right - 2,
						height: photoPosition.height - photoPosition.overlap_top - photoPosition.overlap_bottom - 2,
						borderWidth: photoPosition.borderwidth,
						rotation: photoPosition.angle,
					},
					{
						x: templateTextPosition.x + templateTextPosition.overlap_left + 1,
						y: templateTextPosition.y + templateTextPosition.overlap_top + 1,
						width: templateTextPosition.width - templateTextPosition.overlap_left - templateTextPosition.overlap_right - 2,
						height: templateTextPosition.height - templateTextPosition.overlap_top - templateTextPosition.overlap_bottom - 2,
						borderWidth: templateTextPosition.borderwidth,
						rotation: templateTextPosition.angle,
					},
				),
			);
			if (!overlap) {
				const vars = PageObject.calculatePosition(
					{
						x_axis: templateTextPosition.x,
						y_axis: templateTextPosition.y,
						width: templateTextPosition.width,
						height: templateTextPosition.height,
						borderwidth: templateTextPosition.borderwidth,
						bordercolor: templateTextPosition.bordercolor,
						angle: templateTextPosition.angle,
						type: 'text',
					},
					this.scaling,
					null,
					this.bleedMargin,
				);
				map.push(_.extend(
					vars.imagemap,
					{
						type: 'textposition',
						positionstateid: templateTextPosition.id,
					},
				));
			}
		});

		return map;
	}

	get mapModelResizer() {
		if (this.activeDragging) {
			// When actively dragging an object over the canvas, we do not show the resizer tool
			return null;
		}
		if (!this.resizable) {
			// If the resizable option is disabled, we do not show the resizer tool
			return null;
		}
		if (!this.selectedObjectModel || !this.selectedObjectModel.transformable) {
			// If there is no selected object, or the selected object is not transformable,
			// we do not need to show the resizer tool
			return null;
		}
		if (this.mapModelCropLeft) {
			// When cropping is active, we do not show the resizer tool
			return null;
		}

		if (!AppStateModule.objectLock
			|| (this.selectedObjectModel.type != 'photo' || !this.selectedObjectModel.photoid)
		) {
			const map: InteractionMapModel = {
				type: 'resizer',
				topleft: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW - 16,
					y: this.selectionBox.strokeRectY + this.selectionBox.strokeRectH - 16,
				},
				topright: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW + 16,
					y: this.selectionBox.strokeRectY + this.selectionBox.strokeRectH - 16,
				},
				bottomright: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW + 16,
					y: this.selectionBox.strokeRectY + this.selectionBox.strokeRectH + 16,
				},
				bottomleft: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW - 16,
					y: this.selectionBox.strokeRectY + this.selectionBox.strokeRectH + 16,
				},
			};
			return map;
		}

		return null;
	}

	get mapModelRotator() {
		if (this.activeDragging) {
			// When actively dragging an object over the canvas, we do not show the rotate tool
			return null;
		}
		if (!this.rotatable) {
			// If the rotatable option is disabled, we do not show the rotate tool
			return null;
		}
		if (!this.selectedObjectModel || !this.selectedObjectModel.transformable) {
			// If there is no selected object, or the selected object is not transformable,
			// we do not need to show the rotate tool
			return null;
		}
		if (this.mapModelCropLeft) {
			// When cropping is active, we do not show the rotate tool
			return null;
		}

		if (!AppStateModule.objectLock
			// || (ProductStateModule.getProduct && ProductStateModule.getProduct.group == 102)
			|| (this.selectedObjectModel.type != 'photo' || !this.selectedObjectModel.photoid)
		) {
			const map: InteractionMapModel = {
				type: 'rotator',
				topleft: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW - 16,
					y: this.selectionBox.strokeRectY - 16,
				},
				topright: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW + 16,
					y: this.selectionBox.strokeRectY - 16,
				},
				bottomright: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW + 16,
					y: this.selectionBox.strokeRectY + 16,
				},
				bottomleft: {
					x: this.selectionBox.strokeRectX + this.selectionBox.strokeRectW - 16,
					y: this.selectionBox.strokeRectY + 16,
				},
				centerx: this.rect.minx + (this.rect.maxx - this.rect.minx) / 2,
				centery: this.rect.miny + (this.rect.maxy - this.rect.miny) / 2,
			};
			return map;
		}

		return null;
	}

	get mapModelSelection() {
		if (this.selectedObjectModel) {
			const vars = PageObject.calculatePosition(
				{
					x_axis: this.selectedObjectModel.x_axis,
					y_axis: this.selectedObjectModel.y_axis,
					width: this.selectedObjectModel.width,
					height: this.selectedObjectModel.height,
					borderwidth: this.selectedObjectModel.borderwidth,
					bordercolor: this.selectedObjectModel.bordercolor,
					cropx: this.selectedObjectModel.cropx,
					cropy: this.selectedObjectModel.cropy,
					cropwidth: this.selectedObjectModel.cropwidth,
					cropheight: this.selectedObjectModel.cropheight,
					angle: this.selectedObjectModel.rotate,
					flop: Boolean(this.selectedObjectModel.flop),
					flip: Boolean(this.selectedObjectModel.flip),
					type: this.selectedObjectModel.type,
					maxwidth: this.selectedObjectModel.maxwidth,
					photoid: this.selectedObjectModel.photoid || undefined,
				},
				this.scaling,
				null,
				this.bleedMargin,
			);
			return vars.imagemap;
		} return null;
	}

	get objectLock() {
		return AppStateModule.objectLock;
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get rect() {
		const rect: Rect = {
			minx: this.pageModel.width + 2 * this.bleedMargin,
			miny: this.pageModel.height + 2 * this.bleedMargin,
			maxx: 0,
			maxy: 0,
		};

		const mapModels = [this.mapModelSelection, this.hoveredModel];
		mapModels.forEach(
			(mapItemModel) => {
				if (mapItemModel && mapItemModel.topleft && mapItemModel.topright && mapItemModel.bottomright && mapItemModel.bottomleft) {
					const minx = Math.min(
						mapItemModel.topleft.x,
						mapItemModel.topright.x,
						mapItemModel.bottomright.x,
						mapItemModel.bottomleft.x,
					);
					if (minx < rect.minx) rect.minx = minx;

					const maxx = Math.max(
						mapItemModel.topleft.x,
						mapItemModel.topright.x,
						mapItemModel.bottomright.x,
						mapItemModel.bottomleft.x,
					);
					if (maxx > rect.maxx) rect.maxx = maxx;

					const miny = Math.min(
						mapItemModel.topleft.y,
						mapItemModel.topright.y,
						mapItemModel.bottomright.y,
						mapItemModel.bottomleft.y,
					);
					if (miny < rect.miny) rect.miny = miny;

					const maxy = Math.max(
						mapItemModel.topleft.y,
						mapItemModel.topright.y,
						mapItemModel.bottomright.y,
						mapItemModel.bottomleft.y,
					);

					if (maxy > rect.maxy) rect.maxy = maxy;
				}
			},
		);

		return rect;
	}

	get selectedObjectModel() {
		return ProductStateModule.getSelectedPageObject;
	}

	get selectionBox() {
		const maxRightValue = Math.round((this.pageModel.width + 2 * this.bleedMargin) * this.scaling);
		const maxBottomValue = Math.round(this.pageModel.height + 2 * this.bleedMargin) * this.scaling;
		const strokeRectX = Math.max(
			1,
			this.rect.minx,
		);
		const strokeRectY = Math.max(
			1,
			this.rect.miny,
		);
		const strokeRectW = Math.min(
			this.rect.maxx - strokeRectX,
			maxRightValue - strokeRectX,
		) - 2;
		const strokeRectH = Math.min(
			this.rect.maxy - strokeRectY,
			maxBottomValue - strokeRectY,
		) - 2;
		const rectCenterX = strokeRectX + strokeRectW / 2;
		const rectCenterY = strokeRectY + strokeRectH / 2;

		return {
			strokeRectX,
			strokeRectY,
			strokeRectW,
			strokeRectH,
			rectCenterX,
			rectCenterY,
		};
	}

	get templatePositionsVisible() {
		if (this.editable) {
			return this.templatePositionsAvailable;
		}

		return [];
	}

	get warning(): null|Warning {
		// Check if the object is bigger than the recommended dpi setting for the product
		if (this.selectedObjectModel
			&& this.selectedObjectModel.photoid
		) {
			const photoModel = PhotosModule.getById(this.selectedObjectModel.photoid);
			const photoData = photoModel
				? {
					width: photoModel.full_width,
					height: photoModel.full_height,
				}
				: undefined;
			const maxsize = maxObjectSize(
				{
					maxwidth: this.selectedObjectModel.maxwidth,
					maxheight: this.selectedObjectModel.maxheight,
					cropwidth: this.selectedObjectModel.cropwidth,
					cropheight: this.selectedObjectModel.cropheight,
					type: this.selectedObjectModel.type,
				},
				photoData,
				this.offeringModel,
			);
			if (Math.round(this.selectedObjectModel.width) >= Math.round(maxsize.cropWidth)
				|| Math.round(this.selectedObjectModel.height) >= Math.round(maxsize.cropHeight)
			) {
				return _.extend(
					this.rect,
					{
						text: this.$t('editorMaxPhotoSize'),
					},
				);
			}

			if (Math.round(this.selectedObjectModel.width) > Math.round(maxsize.croppedQualityWidth)
				|| Math.round(this.selectedObjectModel.height) > Math.round(maxsize.croppedQualityHeight)
			) {
				return _.extend(
					this.rect,
					{
						text: this.$t('editorPhotoQualityWarning'),
					},
				);
			}

			return null;
		}
		return null;
	}

	mounted() {
		EventBus.$on(
			'mouse:down',
			this.deselectObjects,
		);
		EventBus.$on(
			'mouse:move',
			this.dragEvent,
		);
		EventBus.$on(
			'mouse:up',
			this.endDragEvent,
		);

		this.loadIconFonts();
	}

	updated() {
		this.drawOnCanvas();
	}

	beforeDestroy() {
		EventBus.$off(
			'mouse:down',
			this.deselectObjects,
		);
		EventBus.$off(
			'mouse:move',
			this.dragEvent,
		);
		EventBus.$off(
			'mouse:up',
			this.endDragEvent,
		);
	}

	checkPinch(hammerEvent: HammerInput) {
		if (this.resizable) {
			touch.pinchEvent(hammerEvent);
		}
	}

	checkSelect(hammerEvent: HammerInput) {
		const elOffset = this.$el.getBoundingClientRect();
		const offsetLeft = elOffset.left;
		const offsetTop = elOffset.top;

		const x = hammerEvent.center.x - offsetLeft;
		const y = hammerEvent.center.y - offsetTop;
		const mapModel = this.map.find(
			(model) => pointRectangleCollision(
				{ x, y },
				model,
			),
		);

		if (mapModel) {
			const evt = hammerEvent.srcEvent;
			if (mapModel.id && (
				mapModel.type == 'photoobject'
				|| mapModel.type == 'textobject'
				|| mapModel.type == 'dragger'
			)) {
				// Select the object
				PageObject.select(
					this.pageModel,
					mapModel.id,
					{
						evt,
						draggable: this.draggable,
						resizable: this.resizable,
						rotatable: this.rotatable,
					},
				);
			} else if (mapModel.type == 'resizer') {
				if (this.resizable) {
					touch.startResize(evt);
				}
			} else if (mapModel.type == 'rotator') {
				if (this.rotatable) {
					const rotateX = (mapModel.centerx || 0) + offsetLeft;
					const rotateY = (mapModel.centery || 0) + offsetTop;
					touch.startRotate(
						rotateX,
						rotateY,
					);
				}
			} else if (mapModel.type == 'cropleft') {
				touch.startCropLeft(evt);
			} else if (mapModel.type == 'cropright') {
				touch.startCropRight(evt);
			} else if (mapModel.type == 'photoposition') {
				const photoPositionState = this.templatePositionsVisible.find(
					(m) => m.id === mapModel.positionstateid,
				);
				ProductStateModule.deselectPageObjects();
				EventBus.$emit(
					'select:photo:position',
					photoPositionState,
				);
			} else if (mapModel.type == 'textposition') {
				const textTemplatePosition = this.templatePositionsVisible.find(
					(m) => m.id === mapModel.positionstateid,
				) as TemplateTextPosition | undefined;
				if (textTemplatePosition) {
					// Add text object position
					TemplatePosition
						.fillTextPosition(
							this.pageModel,
							textTemplatePosition,
							'',
							{
								force: true,
							},
						)
						.then((newObjectModel) => {
							PageObject.select(
								this.pageModel,
								newObjectModel.id,
								{
									draggable: false,
									editText: true,
								},
							);
						})
						.catch((err: Error) => {
							if (err.message === ERRORS_LOAD_FONT) {
								const closeError = this.$openErrorDialog({
									body: {
										content: this.$t('dialogTextLoadError'),
									},
									footer: {
										buttons: [
											{
												id: 'accept',
												text: this.$t('dialogButtonOk'),
												click: () => {
													closeError();
												},
											},
										],
									},
								});
							}

							// No further action required
						});

					analytics.trackEvent(
						'Add text',
						{
							category: 'Template',
						},
					);
				}
			} else if (mapModel.type == 'lock') {
				AppStateModule.toggleObjectLock();
			}
		} else {
			ProductStateModule.deselectPageObjects();
		}
	}

	dragEvent(e: MouseEvent | TouchEvent) {
		const activePageModel = ProductStateModule.getActivePage;
		if (activePageModel && activePageModel.id == this.pageModel.id) {
			if (this.selectedObjectModel && touch.drag.enabled) {
				this.activeDragging = true;
			}

			const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
				? e.changedTouches.item(0)
				: e;

			if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
				// Check if we are hovering an object
				if (touch.drop.enabled) {
					// Get offset
					const elOffset = this.$el.getBoundingClientRect();
					const offsetLeft = elOffset.left;
					const offsetTop = elOffset.top;

					if (this.hoveredModel) {
						const collision = pointRectangleCollision(
							{ x: evt.pageX - offsetLeft, y: evt.pageY - offsetTop },
							this.hoveredModel,
						);
						if (!collision) {
							this.hoveredModel = null;
						}
					}

					if (!this.hoveredModel) {
						// Check intersection with objects
						this.mapModelsObjects.forEach((mapItemModel) => {
							const collision = pointRectangleCollision(
								{ x: evt.pageX - offsetLeft, y: evt.pageY - offsetTop },
								mapItemModel,
							);
							if (collision) {
								// User just entered hovering the item
								// Set hover state to both the imagemap and (optionally) template position
								if (touch.drop.photoid && mapItemModel.type == 'photoobject') {
									this.hoveredModel = mapItemModel;
								} else if (touch.drop.fontid && mapItemModel.type == 'textobject') {
									this.hoveredModel = mapItemModel;
								}
							}
						});

						// Check intersection with template positions
						this.mapModelsTemplatePositions.forEach((mapItemModel) => {
							const collision = pointRectangleCollision(
								{ x: evt.pageX - offsetLeft, y: evt.pageY - offsetTop },
								mapItemModel,
							);
							if (collision) {
								// User just entered hovering the item
								// Set hover state to both the imagemap and (optionally) template position
								if (mapItemModel.positionstateid
									&& (
										(touch.drop.photoid && mapItemModel.type == 'photoposition')
										|| (touch.drop.fontid && mapItemModel.type == 'textposition')
									)
								) {
									this.hoveredModel = mapItemModel;
								}
							}
						});
					}
				}

				touch.dragEvent(
					e,
					this.scaling,
				);
			}
		}
	}

	@Watch('iconFontsLoaded')
	drawOnCanvas() {
		const canvasRef = this.$refs.canvas as Vue;
		if (!canvasRef) return;

		const canvasEl = canvasRef.$el as HTMLCanvasElement;
		if (!canvasEl) return;

		const context = canvasEl.getContext('2d');
		if (!context) return;

		const { scaling } = this;

		// Clear canvas
		context.clearRect(
			0,
			0,
			context.canvas.width,
			context.canvas.height,
		);

		if (this.depth > 1) {
			// Show depth overlay (if product has depth)
			context.save();
			context.fillStyle = 'rgba(0,0,0,0.2)';

			const pageWidth = (this.pageModel.width + 2 * this.bleedMargin);
			const pageHeight = (this.pageModel.height + 2 * this.bleedMargin);

			context.fillRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);
			context.scale(
				scaling,
				scaling,
			);
			context.clearRect(
				this.depth + this.bleedMargin,
				this.depth + this.bleedMargin,
				pageWidth - 2 * this.depth - 2 * this.bleedMargin,
				pageHeight - 2 * this.depth - 2 * this.bleedMargin,
			);

			if (this.bleedMargin) {
				// Draw bleed margin overlay (if product has bleed margin)
				context.fillStyle = 'rgba(255,0,0,0.2)';
				context.fillRect(
					0,
					0,
					this.bleedMargin,
					this.pageModel.height + 2 * this.bleedMargin,
				);
				context.fillRect(
					this.pageModel.width + this.bleedMargin,
					0,
					this.bleedMargin,
					this.pageModel.height + 2 * this.bleedMargin,
				);
				context.fillRect(
					this.bleedMargin,
					0,
					this.pageModel.width,
					this.bleedMargin,
				);
				context.fillRect(
					this.bleedMargin,
					this.pageModel.height + this.bleedMargin,
					this.pageModel.width,
					this.bleedMargin,
				);
			}

			context.clearRect(
				0,
				0,
				this.depth + this.bleedMargin,
				this.depth + this.bleedMargin,
			);
			context.clearRect(
				pageWidth - this.depth - this.bleedMargin,
				0,
				this.depth + this.bleedMargin,
				this.depth + this.bleedMargin,
			);
			context.clearRect(
				0,
				pageHeight - this.depth - this.bleedMargin,
				this.depth + this.bleedMargin,
				this.depth + this.bleedMargin,
			);
			context.clearRect(
				pageWidth - this.depth - this.bleedMargin,
				pageHeight - this.depth - this.bleedMargin,
				this.depth + this.bleedMargin,
				this.depth + this.bleedMargin,
			);

			context.restore();
		} else if (this.bleedMargin) {
			// Draw bleed margin overlay (if product has bleed margin)
			context.save();
			context.fillStyle = 'rgba(255,0,0,0.2)';
			context.fillRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);
			context.scale(
				scaling,
				scaling,
			);
			context.clearRect(
				this.bleedMargin,
				this.bleedMargin,
				this.pageModel.width,
				this.pageModel.height,
			);
			context.restore();
		}

		// Set line and color properties
		context.lineWidth = 5;
		context.strokeStyle = this.strokeColor;
		context.fillStyle = this.fillColor;

		// Draw cropping handles
		if (this.mapModelCropLeft && this.mapModelCropRight && this.selectedObjectModel) {
			const vars = PageObject.calculatePosition(
				{
					x_axis: this.selectedObjectModel.x_axis,
					y_axis: this.selectedObjectModel.y_axis,
					width: this.selectedObjectModel.width,
					height: this.selectedObjectModel.height,
					borderwidth: this.selectedObjectModel.borderwidth,
					bordercolor: this.selectedObjectModel.bordercolor,
					cropx: this.selectedObjectModel.cropx,
					cropy: this.selectedObjectModel.cropy,
					cropwidth: this.selectedObjectModel.cropwidth,
					cropheight: this.selectedObjectModel.cropheight,
					angle: this.selectedObjectModel.rotate,
					flop: Boolean(this.selectedObjectModel.flop),
					flip: Boolean(this.selectedObjectModel.flip),
					type: this.selectedObjectModel.type,
					maxwidth: this.selectedObjectModel.maxwidth,
					photoid: this.selectedObjectModel.photoid || undefined,
				},
				scaling,
				null,
				this.bleedMargin,
			);

			// Draw black page overlay
			context.fillStyle = 'rgba(0, 0, 0, 0.7)';
			context.fillRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);

			// Scale canvas so we can use normalized values
			context.scale(
				scaling,
				scaling,
			);

			// Clear box where original photo will be drawn
			context.translate(
				vars.max.placement.x,
				vars.max.placement.y,
			);
			context.rotate(vars.rotate);
			context.clearRect(
				0,
				0,
				vars.max.width,
				vars.max.height,
			);
			context.rotate(-vars.rotate);
			context.translate(
				-vars.max.placement.x,
				-vars.max.placement.y,
			);

			// Move to position of cropped photo
			context.translate(
				vars.placement.x,
				vars.placement.y,
			);
			context.rotate(vars.rotate);

			// Limit drawing to area of cropped photo
			context.save();
			context.beginPath();
			context.rect(
				0,
				0,
				vars.width,
				vars.height,
			);
			context.clip();

			// Draw cropped photo
			context.drawImage(
				this.selectedObjectModel._image,
				vars.max.x_axis - vars.x_axis,
				vars.max.y_axis - vars.y_axis,
				vars.max.width,
				vars.max.height,
			);

			// Apply mask to cropped photo
			if (this.selectedObjectModel._mask) {
				context.globalCompositeOperation = 'destination-in';
				context.drawImage(
					this.selectedObjectModel._mask,
					0,
					0,
					vars.width,
					vars.height,
				);
			}

			// Restore from current clipping area
			context.restore();

			// Move back from position of cropped photo
			context.rotate(-vars.rotate);
			context.translate(
				-vars.placement.x,
				-vars.placement.y,
			);

			// Move over to position of full uncropped photo
			context.translate(
				vars.max.placement.x,
				vars.max.placement.y,
			);
			context.rotate(vars.rotate);

			// Limit drawing to area of full uncropped photo
			context.save();
			context.beginPath();
			context.rect(
				0,
				0,
				vars.max.width,
				vars.max.height,
			);
			context.clip();

			// Draw white overlay over full uncropped photo
			context.globalCompositeOperation = 'source-out';
			context.fillStyle = 'rgba(255,255,255,0.7)';
			context.fillRect(
				0,
				0,
				vars.max.width,
				vars.max.height,
			);

			// Draw full uncropped photo
			context.globalCompositeOperation = 'destination-over';
			context.drawImage(
				this.selectedObjectModel._image,
				0,
				0,
				vars.max.width,
				vars.max.height,
			);

			// Restore from current clipping area
			context.restore();

			// Move back from position of full uncropped photo
			context.rotate(-vars.rotate);
			context.translate(
				-vars.max.placement.x,
				-vars.max.placement.y,
			);

			// Move to position of cropped photo
			context.translate(
				vars.placement.x,
				vars.placement.y,
			);
			context.rotate(vars.rotate);

			// Draw circle shapped handle
			context.fillStyle = this.fillColor;
			context.beginPath();
			context.arc(
				0,
				0,
				16 / scaling,
				0,
				2 * Math.PI,
				false,
			);
			context.fill();

			// Draw crop line on handle
			context.strokeStyle = this.lineColor;
			context.beginPath();
			context.moveTo(
				32,
				-2.5,
			);
			context.lineTo(
				-2.5,
				-2.5,
			);
			context.lineTo(
				-2.5,
				32,
			);
			context.stroke();

			// Draw circle shapped handle
			context.fillStyle = this.fillColor;
			context.beginPath();
			context.arc(
				vars.width,
				vars.height,
				16 / scaling,
				0,
				2 * Math.PI,
				false,
			);
			context.fill();

			// Draw crop line on handle
			context.strokeStyle = this.lineColor;
			context.beginPath();
			context.moveTo(
				vars.width - 32,
				vars.height + 2.5,
			);
			context.lineTo(
				vars.width + 2.5,
				vars.height + 2.5,
			);
			context.lineTo(
				vars.width + 2.5,
				vars.height - 32,
			);
			context.stroke();

			context.rotate(-vars.rotate);
			context.translate(
				-vars.placement.x,
				-vars.placement.y,
			);

			context.scale(
				1 / scaling,
				1 / scaling,
			);
		} else if (this.selectedObjectModel) {
			// Draw border around selected object
			context.strokeRect(
				this.selectionBox.strokeRectX,
				this.selectionBox.strokeRectY,
				this.selectionBox.strokeRectW,
				this.selectionBox.strokeRectH,
			);

			// Draw resize handle
			if (this.mapModelResizer) {
				const resizeCenterX = this.selectionBox.strokeRectX + this.selectionBox.strokeRectW;
				const resizeCenterY = this.selectionBox.strokeRectY + this.selectionBox.strokeRectH;

				// Resize handle
				context.fillStyle = this.fillColor;
				context.beginPath();
				context.arc(
					resizeCenterX,
					resizeCenterY,
					16,
					0,
					2 * Math.PI,
					false,
				);
				context.fill();

				// Write icon
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = '#fff';
				context.font = this.iconResizeFont;

				context.translate(
					resizeCenterX,
					resizeCenterY,
				);
				context.rotate(Math.PI / 180 * 90);
				context.fillText(
					this.iconResize,
					0,
					0,
				);
				context.rotate(-Math.PI / 180 * 90);
				context.translate(
					-resizeCenterX,
					-resizeCenterY,
				);
			}

			// Draw rotate handle
			if (this.mapModelRotator) {
				const rotateCenterX = this.selectionBox.strokeRectX + this.selectionBox.strokeRectW;
				const rotateCenterY = this.selectionBox.strokeRectY;

				context.fillStyle = this.fillColor;
				context.beginPath();
				context.arc(
					rotateCenterX,
					rotateCenterY,
					16,
					0,
					2 * Math.PI,
					false,
				);
				context.fill();

				// Write icon
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = '#fff';

				context.font = this.iconRotateFont;
				context.fillText(
					this.iconRotate,
					rotateCenterX,
					rotateCenterY,
				);
			}

			/* if(this.mapModelDestroyer) {
				let destroyCenterX = this.selectionBox.strokeRectX;
				let destroyCenterY = this.selectionBox.strokeRectY;

				context.fillStyle = this.fillColor;
				context.beginPath();
				context.arc(destroyCenterX, destroyCenterY, 16, 0, 2 * Math.PI, false);
				context.fill();

				// Write icon
				context.font = this.iconTrashFont;
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = '#fff';

				context.translate(destroyCenterX, destroyCenterY);
				context.fillText(
					this.iconTrash,
					0,
					0,
				);
				context.translate(-destroyCenterX, -destroyCenterY);
			} */

			// Draw lock icon in center of object
			if (this.mapModelLock) {
				context.fillStyle = this.fillColor;
				context.beginPath();
				context.arc(
					this.selectionBox.rectCenterX,
					this.selectionBox.rectCenterY,
					16,
					0,
					2 * Math.PI,
					false,
				);
				context.fill();

				// Write icon
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = '#fff';

				if (this.objectLock) {
					// Locked icon
					context.font = this.iconLockedFont;
					context.fillText(
						this.iconLocked,
						this.selectionBox.rectCenterX,
						this.selectionBox.rectCenterY,
					);
				} else {
					// Unlocked icon
					context.font = this.iconUnlockedFont;
					context.fillText(
						this.iconUnlocked,
						this.selectionBox.rectCenterX,
						this.selectionBox.rectCenterY,
					);
				}
			} else if (this.mapModelDrag) {
				context.fillStyle = this.fillColor;
				context.beginPath();
				context.arc(
					this.selectionBox.rectCenterX,
					this.selectionBox.rectCenterY,
					16,
					0,
					2 * Math.PI,
					false,
				);
				context.fill();

				// Write icon
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = '#fff';

				// Drag handle
				context.font = this.iconDragFont;
				context.fillText(
					this.iconDrag,
					this.selectionBox.rectCenterX,
					this.selectionBox.rectCenterY,
				);
			}
		} else if (this.hoveredModel) {
			const x = Math.max(
				1,
				this.rect.minx,
			);
			const y = Math.max(
				1,
				this.rect.miny,
			);
			const w = Math.min(
				Math.round((this.pageModel.width + 2 * this.bleedMargin) * scaling) - Math.max(
					0,
					this.rect.minx,
				),
				this.rect.maxx - Math.max(
					0,
					this.rect.minx,
				),
			);
			const h = Math.min(
				Math.round((this.pageModel.height + 2 * this.bleedMargin) * scaling) - Math.max(
					0,
					this.rect.miny,
				),
				this.rect.maxy - Math.max(
					0,
					this.rect.miny,
				),
			);

			if (this.hoveredModel.type == 'photoposition' || this.hoveredModel.type == 'textposition') {
				const inverseColor = getColorInverse(this.pageModel.bgcolor || '#FFFFFF');
				if (this.pageModel.bgcolor == 'transparent') {
					context.fillStyle = 'rgba(0, 0, 0, 0.5)';
				} else if (inverseColor == '#000000') {
					context.fillStyle = 'rgba(125,125,125,0.5)';
				} else {
					context.fillStyle = 'rgba(200,200,200,0.5)';
				}
				context.fillRect(
					x,
					y,
					w,
					h,
				);
			} else {
				context.strokeRect(
					x,
					y,
					w,
					h,
				);
			}
		}

		context.save();
		this.mapModelsTemplatePositions.forEach((mapModel) => {
			context.lineWidth = 5;
			if (context.setLineDash) {
				context.setLineDash([20, 5]);
			}

			const templatePosition = this.templatePositionsVisible.find(
				(m) => m.id == mapModel.positionstateid,
			);
			if (templatePosition) {
				const vars = PageObject.calculatePosition(
					{
						x_axis: templatePosition.x,
						y_axis: templatePosition.y,
						width: templatePosition.width,
						height: templatePosition.height,
						borderwidth: templatePosition.borderwidth,
						bordercolor: templatePosition.bordercolor,
						angle: templatePosition.angle,
						type: templatePosition.type,
					},
					scaling,
					null,
					this.bleedMargin,
				);

				// Transform canvas
				context.scale(
					scaling,
					scaling,
				);
				context.translate(
					vars.placement.x,
					vars.placement.y,
				);
				context.rotate(vars.rotate);

				// Draw (dashed) border around template position
				const pagecolor = this.pageModel.bgcolor;
				const inverseColor = getColorInverse(pagecolor || '#FFFFFF');

				if (pagecolor == 'transparent') {
					context.strokeStyle = 'rgba(0, 0, 0, 0.75)';
					context.fillStyle = 'rgba(0, 0, 0, 0.75)';
				} else if (inverseColor == '#000000') {
					context.strokeStyle = 'rgba(125,125,125,1)';
					context.fillStyle = 'rgba(125,125,125,0.75)';
				} else {
					context.strokeStyle = 'rgba(200,200,200,1)';
					context.fillStyle = 'rgba(200,200,200,0.75)';
				}
				context.strokeRect(
					0,
					0,
					vars.width,
					vars.height,
				);

				const iconsize = Math.min(
					vars.width * 0.75,
					vars.height * 0.5,
				);
				context.textAlign = 'center';
				context.textBaseline = 'middle';

				let iconString: string|null = null;
				let helpText: string|null = null;
				if (templatePosition.type == 'photo') {
					helpText = this.offeringModel?.type === 'logo'
						? this.$t('templateHelperAddLogo')
						: this.$t('templateHelperAddPhoto');
					iconString = this.iconPhoto;
					context.font = this.iconPhotoFont(iconsize);
				} else if (templatePosition.type == 'text') {
					helpText = this.$t('templateHelperAddText');
					iconString = this.iconText;
					context.font = this.iconTextFont(iconsize);
				}

				if (iconString) {
					// Add icon to center of position
					context.fillText(
						iconString,
						vars.width / 2,
						vars.height / 2,
					);
				}

				if (helpText) {
					// Add help text below icon
					const fontsize = Math.min(
						vars.height * 0.25,
						vars.width / helpText.length,
					);
					context.font = `${fontsize}px Arial`;
					context.textAlign = 'center';
					context.textBaseline = 'middle';
					context.fillText(
						helpText,
						vars.width / 2,
						vars.height / 2 + iconsize / 2 + fontsize / 2,
					);
				}

				context.rotate(-vars.rotate);
				context.translate(
					-vars.placement.x,
					-vars.placement.y,
				);

				context.scale(
					1 / scaling,
					1 / scaling,
				);
			}
		});
		context.restore();

		if (this.warning) {
			// Show warning
			const x = this.warning.minx + 20;
			const y = this.warning.miny + 20;

			/* Add warning icon */
			context.font = this.iconWarningBackgroundFont;
			context.textAlign = 'center';
			context.textBaseline = 'middle';
			context.fillStyle = '#FFFF00';
			context.fillText(
				this.iconWarningBackground,
				x,
				y,
			);

			context.font = this.iconWarningFont;
			context.fillStyle = '#000000';
			context.fillText(
				this.iconWarning,
				x,
				y,
			);

			if (!this.activeDragging) {
				const boxX = x - 10;
				const boxY = y + 20;

				/* Add warning box */
				context.beginPath();
				context.fillStyle = 'rgba(255,255,255,0.7)';
				context.rect(
					boxX,
					boxY,
					200,
					35,
				);
				context.fill();

				context.beginPath();
				context.strokeStyle = '#FFFF00';
				context.rect(
					boxX,
					boxY,
					200,
					35,
				);
				context.stroke();

				/* Add warning text */
				context.fillStyle = '#000000';
				context.textAlign = 'center';
				context.font = '12px Arial, Helvetica, sans-serif'; // reset fontface and size
				const lineheight = 12;
				this.warning.text.split('\n').forEach((line, linenr) => {
					context.fillText(
						line,
						boxX + 100,
						boxY + 5 + (0.5 + linenr) * lineheight,
					);
				});
			}

			// Set colors back to default
			context.fillStyle = this.fillColor;
		}
	}

	deselectObjects() {
		if (
			ProductStateModule.getActivePage
			&& ProductStateModule.getActivePage.id == this.pageModel.id
		) {
			ProductStateModule.deselectPageObjects();
		}
	}

	endDragEvent(e: MouseEvent | TouchEvent) {
		const activePageModel = ProductStateModule.getActivePage;
		if (activePageModel && activePageModel.id == this.pageModel.id) {
			const evt = window.TouchEvent && e instanceof TouchEvent && e.changedTouches
				? e.changedTouches.item(0)
				: e;

			if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
				if (touch.drop.enabled) {
					const templateModel = ProductStateModule.getPageTemplate(this.pageModel);

					// Get offset of overlay image (imagemap holder)
					const elOffset = this.$el.getBoundingClientRect();
					const offsetLeft = elOffset.left;
					const offsetTop = elOffset.top;
					const xAxis = (evt.pageX - offsetLeft) / this.scaling;
					const yAxis = (evt.pageY - offsetTop) / this.scaling;

					// Check if mouse/touch release is within the page area
					if (xAxis > 0 && yAxis > 0 && xAxis < this.pageModel.width && yAxis < this.pageModel.height) {
						const maxz = ProductStateModule.getPageMaxZ(activePageModel);

						// Find imagemap or position models that have the hovering state
						const mapItemModel = this.hoveredModel;

						if (touch.drop.photoid) {
							const photoModel = PhotosModule.getById(touch.drop.photoid);
							if (mapItemModel) {
								if (photoModel) {
									if (mapItemModel.type == 'photoobject' && mapItemModel.id) {
										const objectModel = ProductStateModule.getPageObject(mapItemModel.id);
										const templatePositionId = objectModel && objectModel.templatestateid
											? objectModel.templatestateid
											: null;
										const templatePosition = this.templatePositionsVisible.find(
											(m) => m.id == templatePositionId,
										) as TemplatePhotoPosition | undefined;

										changeObjectPhoto(
											objectModel,
											photoModel,
											templatePosition,
											false,
										)
											.then(() => {
												analytics.trackEvent(
													'Swap photo',
													{
														category: 'Page object',
														label: 'Drop',
													},
												);
												ProductStateModule.pushHistory();

												// We need an extra trigger to drag-end event here because we've used a promise at this point
												touch.endDragEvent();

												PageObject.select(
													this.pageModel,
													objectModel.id,
													{
														showToolbar: true,
													},
												);
											});
									} else if (mapItemModel.type == 'photoposition' && mapItemModel.positionstateid) {
										const templatePosition = this.templatePositionsVisible.find(
											(m) => m.id === mapItemModel.positionstateid,
										) as TemplatePhotoPosition;
										if (templatePosition) {
											TemplatePosition.fillPhotoPosition(
												this.pageModel,
												templatePosition,
												photoModel,
												{},
											)
												.then((newObjectModel) => {
													if (newObjectModel) {
														ProductStateModule.pushHistory();
														PageObject.select(
															this.pageModel,
															newObjectModel.id,
															{
																showToolbar: true,
															},
														);
													}
												});

											// Save user action to analytics
											analytics.trackEvent(
												'Add photo',
												{
													category: 'Template',
													label: 'Drop',
												},
											);
										}
									}
								}
							} else if (templateModel && templateModel.transformable) {
								if (photoModel) {
									const scale = Math.max(
										1,
										photoModel.full_width / (this.pageModel.width / 4),
										photoModel.full_height / (this.pageModel.height / 4),
									);
									const objectWidth = photoModel.full_width / scale;
									const objectHeight = photoModel.full_height / scale;

									// create object
									ProductStateModule
										.addPageObject({
											pageId: this.pageModel.id,
											data: {
												x_axis: xAxis - objectWidth / 2,
												y_axis: yAxis - objectHeight / 2,
												z_axis: maxz + 1,
												width: objectWidth,
												height: objectHeight,
												maxwidth: photoModel.full_width,
												maxheight: photoModel.full_height,
												type: 'photo',
												cropwidth: photoModel.full_width,
												cropheight: photoModel.full_height,
												cropx: 0,
												cropy: 0,
												rotate: 0,
												photoid: photoModel.id,
											},
										})
										.then((newObjectModel) => {
											ProductStateModule.pushHistory();
											PageObject.select(
												this.pageModel,
												newObjectModel.id,
												{
													showToolbar: true,
													draggable: true,
												},
											);
										})
										.catch((err) => {
											if (err.message === ERRORS_LOAD_FONT) {
												const closeError = this.$openErrorDialog({
													body: {
														content: this.$t('dialogTextLoadError'),
													},
													footer: {
														buttons: [
															{
																id: 'accept',
																text: this.$t('dialogButtonOk'),
																click: () => {
																	closeError();
																},
															},
														],
													},
												});
											}
										});

									analytics.trackEvent(
										'Add photo',
										{
											category: 'Product page',
											label: 'Drop',
										},
									);
								}
							} else {
								this.$openErrorDialog({
									header: {
										title: this.$t('dialogHeaderInvalidDrop'),
									},
									body: {
										content: this.$t('dialogTextInvalidDrop'),
									},
								});
							}
						} else if (touch.drop.fontid) {
							const fontModel = FontModule.getById(touch.drop.fontid);
							if (fontModel) {
								if (mapItemModel) {
									if (mapItemModel.type == 'textobject' && mapItemModel.id) {
										const objectModel = ProductStateModule.getPageObject(mapItemModel.id);

										// now set fontface
										if (fontModel._loaded) {
											ProductStateModule.changePageObject({
												id: objectModel.id,
												fontface: fontModel.id,
												fontbold: objectModel.fontbold && fontModel.bold ? 1 : 0,
												fontitalic: objectModel.fontitalic && fontModel.italic ? 1 : 0,
											});
											ProductStateModule.pushHistory();
										} else {
											const closeLoader = this.$openLoaderDialog();

											FontModule
												.loadModel(fontModel.id)
												.then(() => {
													closeLoader();

													ProductStateModule.changePageObject({
														id: objectModel.id,
														fontface: fontModel.id,
														fontbold: objectModel.fontbold && fontModel.bold ? 1 : 0,
														fontitalic: objectModel.fontitalic && fontModel.italic ? 1 : 0,
													});
													ProductStateModule.pushHistory();
												})
												.catch(() => {
													closeLoader();

													this.$openErrorDialog({
														body: {
															content: this.$t('dialogTextLoadError'),
														},
													});
												});
										}

										analytics.trackEvent(
											'Change font',
											{
												category: 'Page object',
												label: 'Drop',
											},
										);
									} else if (mapItemModel.type == 'textposition') {
										const templatePositionModel = this.templatePositionsVisible.find(
											(m) => m.id === mapItemModel.positionstateid,
										) as TemplateTextPosition;
										if (templatePositionModel) {
											ProductStateModule
												.addPageObject({
													pageId: this.pageModel.id,
													data: {
														x_axis: templatePositionModel.x,
														y_axis: templatePositionModel.y,
														z_axis: maxz + 1,
														width: templatePositionModel.width,
														height: templatePositionModel.height,
														type: 'text',
														rotate: templatePositionModel.angle,
														transformable: templatePositionModel.transformable ? 1 : 0,
														fontface: fontModel.id,
														pointsize: templatePositionModel.pointsize || 42,
														fontcolor: templatePositionModel.fontcolor,
														bgcolor: templatePositionModel.bgcolor,
														align: templatePositionModel.align,
													},
												})
												.then((newObjectModel) => {
													PageObject.select(
														this.pageModel,
														newObjectModel.id,
														{
															draggable: false,
															editText: true,
														},
													);
												})
												.catch(() => {
													this.$closeLoaderDialog();

													this.$openErrorDialog({
														body: {
															content: this.$t('dialogTextLoadError'),
														},
													});
												});

											// Save user action to analytics
											analytics.trackEvent(
												'Add text',
												{
													category: 'Template',
													label: 'Drop',
												},
											);
										}
									}
								} else if (templateModel && templateModel.transformable) {
									const textObjectWidth = 300;
									const textObjectHeight = 200;

									ProductStateModule
										.addPageObject({
											pageId: this.pageModel.id,
											data: {
												x_axis: xAxis - textObjectWidth / 2,
												y_axis: yAxis - textObjectHeight / 2,
												z_axis: maxz + 1,
												width: textObjectWidth,
												height: textObjectHeight,
												type: 'text',
												fontface: fontModel.id,
												fontcolor: getColorInverse(this.pageModel.bgcolor || '#FFFFFF'),
												pointsize: 42,
											},
										})
										.then((newObjectModel) => {
											PageObject.select(
												this.pageModel,
												newObjectModel.id,
												{
													editText: true,
													draggable: false,
												},
											);
										})
										.catch((err) => {
											if (err.message === ERRORS_LOAD_FONT) {
												const closeError = this.$openErrorDialog({
													body: {
														content: this.$t('dialogTextLoadError'),
													},
													footer: {
														buttons: [
															{
																id: 'accept',
																text: this.$t('dialogButtonOk'),
																click: () => {
																	closeError();
																},
															},
														],
													},
												});
											}
										});

									analytics.trackEvent(
										'Add text',
										{
											category: 'Product page',
											label: 'Drop',
										},
									);
								} else {
									this.$openErrorDialog({
										header: {
											title: this.$t('dialogHeaderInvalidDrop'),
										},
										body: {
											content: this.$t('dialogTextInvalidDrop'),
										},
									});
								}
							}
						}
					} else if (
						touch.drop.photoid
						|| touch.drop.fontid
					) {
						this.$openErrorDialog({
							header: {
								title: this.$t('dialogHeaderInvalidDrop'),
							},
							body: {
								content: this.$t('dialogTextInvalidDrop'),
							},
						});
					}
				} else {
					const selectedObjectModel = ProductStateModule.getSelectedPageObject;
					if (selectedObjectModel
						&& (selectedObjectModel.type != 'text' || (selectedObjectModel.text && selectedObjectModel.text.length))
					) {
						ProductStateModule.pushHistory();
					}
				}
			}

			touch.endDragEvent();

			this.hoveredModel = null;
			this.activeDragging = false;
		}
	}

	loadIconFonts() {
		this.iconFontsLoadAttempts += 1;

		const observers: Promise<void>[] = [];
		this.iconFonts.forEach((iconFont) => {
			const observer = new FontFaceObserver(iconFont);
			observers.push(
				observer.load(this.iconPhoto),
			);
		});

		Promise.all(observers).then(() => {
			this.iconFontsLoaded = true;
		}).catch(() => {
			if (this.iconFontsLoadAttempts < 3) {
				this.loadIconFonts();
			} else if (typeof window.glBugsnagClient !== 'undefined') {
				const error = new Error('Could not load icon font');
				window.glBugsnagClient.notify(
					error,
					(event) => { event.severity = 'info'; },
				);
			}
		});
	}

	scaleChange() {
		this.$nextTick(this.drawOnCanvas);
	}
}
