var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup__dialog"},[_c('div',{staticClass:"first line"},[_c('div',{staticClass:"title pull__up"},[_c('h2',{staticClass:"new__to__text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('views.authDialog.newTo', { label: _vm.labelName }))}})])]),_vm._v(" "),_c('div',{staticClass:"dialogTopLinks"},[_c('div',[_c('span',{staticClass:"text subHeader"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('views.signup.noAccountYet', { label: _vm.labelName }))+"\n\t\t\t\t")])])]),_vm._v(" "),(_vm.showForm)?_c('div',{staticClass:"loginForm"},[(_vm.showFirstName)?_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"fname"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('userProfileFields.first_name'))+"\n\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputData.firstName),expression:"inputData.firstName",modifiers:{"trim":true}}],class:[
						'privateData',
						{ invalid: _vm.invalidFirstName }
					],attrs:{"type":"text","name":"fname","size":"15","data-private":"lipsum","required":""},domProps:{"value":(_vm.inputData.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputData, "firstName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_vm._v(" "),(_vm.showLastName)?_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"lname"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('userProfileFields.last_name'))+"\n\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputData.lastName),expression:"inputData.lastName",modifiers:{"trim":true}}],class:[
						'privateData',
						{ invalid: _vm.invalidLastName }
					],attrs:{"type":"text","name":"lname","size":"15","data-private":"lipsum","required":""},domProps:{"value":(_vm.inputData.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputData, "lastName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('userProfileFields.email'))+"\n\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputData.username),expression:"inputData.username",modifiers:{"trim":true}}],class:[
						'privateData',
						{ invalid: _vm.invalidUserName }
					],attrs:{"type":"text","name":"email","placeholder":_vm.$t('views.authDialog.emailPlaceholder'),"size":"15","data-private":"lipsum","required":""},domProps:{"value":(_vm.inputData.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputData, "username", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('password'))+"\n\t\t\t\t")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputData.password),expression:"inputData.password",modifiers:{"trim":true}}],class:{ invalid: _vm.invalidPassword },attrs:{"type":_vm.showPassword ? 'text' : 'password',"name":"password","placeholder":_vm.$t('password'),"size":"15","required":""},domProps:{"value":(_vm.inputData.password)},on:{"keyup":_vm.checkKey,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputData, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('button',{class:[
						'togglePassword',
						{ show: _vm.showPassword }
					],attrs:{"type":"button"},on:{"click":_vm.togglePasswordVisiblity}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.showPassword ? _vm.$t('passwordHide') : _vm.$t('passwordShow'))+"\n\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputData.cpassword),expression:"inputData.cpassword",modifiers:{"trim":true}}],class:{ invalid: _vm.invalidCPassword },attrs:{"type":_vm.showCPassword ? 'text' : 'password',"name":"cpassword","placeholder":_vm.$t('password'),"size":"15","required":""},domProps:{"value":(_vm.inputData.cpassword)},on:{"keyup":_vm.checkKey,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputData, "cpassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('button',{class:[
						'togglePassword',
						{ show: _vm.showCPassword }
					],attrs:{"type":"button"},on:{"click":_vm.toggleCPasswordVisiblity}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.showCPassword ? _vm.$t('passwordHide') : _vm.$t('passwordShow'))+"\n\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"instructions"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('views.signup.instructionsText'))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"buttonContainer"},[_c('button',{staticClass:"actionbutton alt3Color",on:{"click":_vm.submit}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('buttonSignup'))+"\n\t\t\t\t")])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"second"},[_c('div',{staticClass:"check__text__wrapper"},[_c('div',{staticClass:"check__text"},[_vm._m(0),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('views.authDialog.benefit_1'))+"\n\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"check__text"},[_vm._m(1),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('views.authDialog.benefit_2'))+"\n\n\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"check__text"},[_vm._m(2),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('views.authDialog.benefit_3'))+"\n\t\t\t\t")])])]),_vm._v(" "),_c('div',[_c('p',{staticClass:"existingUserLink"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('views.signup.existingUser', { label: _vm.labelName }))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"buttonContainer"},[_c('button',{staticClass:"actionbutton alt2Color",on:{"click":_vm.login}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('login'))+"\n\t\t\t\t")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"material-icons check"},[_vm._v("check")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"material-icons check"},[_vm._v("check")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"material-icons check"},[_vm._v("check")])])}]

export { render, staticRenderFns }