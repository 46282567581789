import Template from 'classes/template';
import {
	TemplatePhotoPosition,
	TemplateSet,
	TemplateStateModel,
	ThemeTemplateSet,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import {
	AppDataModule,
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import getFixedTemplateSets from 'tools/get-fixed-template-sets';
import _ from 'underscore';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'themestate' })
export default class ThemeState extends VuexModule {
	public borderImageCollection: DB.BorderImageModel[] = [];

	public layoutCollection: DB.LayoutModel[] = [];

	public layoutTemplateLinkCollection: DB.LayoutTemplateLinkModel[] = [];

	public pageStateCollection: PI.PageStateModel[] = [];

	public themeLayoutLinkCollection: DB.ThemeLayoutLinkModel[] = [];

	/**
	 * If the theme model changes, reset the theme template sets
	 */
	public themeModel: DB.ThemeModel | null = null;

	public themeTemplateSets: ThemeTemplateSet[] = [];

	public get getTemplateSets() {
		return (
			templateId: DB.TemplateModel['id'],
			options?: {
				photoCount?: number,
				marginAroundEdge?: number,
				marginBetweenPositions?: number,
			},
		): TemplateSet[] => {
			const templateModel = ThemeDataModule.getTemplate(templateId);

			if (!templateModel) {
				throw new Error('Could not find template model');
			}

			const sets: TemplateSet[] = getFixedTemplateSets(templateId);

			if (!templateModel.dynamic) {
				return sets;
			}

			if (
				!templateModel.width
				|| !templateModel.height
			) {
				throw new Error('Template model has no width or height');
			}

			const themeTemplateSetsFound = this.themeTemplateSets.find((themeTemplateSet) => {
				if (
					themeTemplateSet.templateId === templateModel.id
					&& themeTemplateSet.photoCount === options?.photoCount
					&& themeTemplateSet.marginAroundEdge === options?.marginAroundEdge
					&& themeTemplateSet.marginBetweenPositions === options?.marginBetweenPositions
				) {
					return true;
				}

				return false;
			});

			if (themeTemplateSetsFound) {
				return themeTemplateSetsFound.templateSets;
			}

			const photoQuantities: number[] = (
				options?.photoCount
					? [options?.photoCount]
					: [1, 2, 3, 4, 5]
			);
			const dynamicTemplates = Template.getDynamicTemplates(
				{
					id: templateModel.id,
					width: templateModel.width,
					height: templateModel.height,
					bleedMargin: templateModel.bleedmargin ?? 0,
					marginAroundEdge: options?.marginAroundEdge ?? templateModel.dynamicPageMargin ?? 0,
					marginBetweenPositions: options?.marginBetweenPositions ?? templateModel.dynamicPagePadding ?? 0,
				},
				photoQuantities,
			);

			dynamicTemplates.forEach((dynamicTemplate) => {
				const positions = dynamicTemplate.positions.map(
					(p) => {
						const photoPosition: TemplatePhotoPosition = {
							id: p.id,
							x: p.x,
							y: p.y,
							z: p.z,
							width: p.width,
							height: p.height,
							angle: 0,
							borderwidth: 0,
							bordercolor: '#000000',
							transformable: true,
							available: true,
							type: 'photo',
							mask: null,
							flag: true,
							autorotate: false,
							overlap_top: 0,
							overlap_right: 0,
							overlap_bottom: 0,
							overlap_left: 0,
							fillMethod: 'cover',
						};

						return photoPosition;
					},
				);
				sets.push({
					id: positions.map((position) => position.id).join('_'),
					templateId: templateModel.id,
					positions,
				});
			});

			this.themeTemplateSets.push({
				templateId: templateModel.id,
				photoCount: options?.photoCount,
				marginAroundEdge: options?.marginAroundEdge,
				marginBetweenPositions: options?.marginBetweenPositions,
				templateSets: sets,
			});

			return sets;
		};
	}

	public get getTemplateSet() {
		return (
			templateId: DB.TemplateModel['id'],
			photoModels?: PI.PhotoModel[],
			options?: {
				marginAroundEdge?: number;
				marginBetweenPositions?: number;
			},
		): {
			templateSet: TemplateSet;
			photoModels: PI.PhotoModel[] | undefined;
		} => {
			const templateModel = ThemeDataModule.getTemplate(
				templateId,
			);
			if (!templateModel) {
				throw new Error('Could not find template model');
			}

			if (templateModel.dynamic
				&& photoModels?.length
			) {
				if (!templateModel.width || !templateModel.height) {
					throw new Error('Template model has no width or height');
				}
				const photoData = photoModels.map(
					(photoModel) => ({
						id: photoModel.id,
						width: photoModel.full_width,
						height: photoModel.full_height,
					}),
				);
				const dynamicTemplate = Template.getTemplatePositions(
					{
						id: templateModel.id,
						width: templateModel.width,
						height: templateModel.height,
						bleedMargin: templateModel.bleedmargin ?? 0,
						marginAroundEdge: options?.marginAroundEdge ?? (templateModel.dynamicPageMargin || 0),
						marginBetweenPositions: options?.marginBetweenPositions ?? (templateModel.dynamicPagePadding || 0),
					},
					photoData,
					0.1,
				);

				const positions = dynamicTemplate.positions.map(
					(p) => {
						const photoPosition: TemplatePhotoPosition = {
							id: p.id,
							x: p.x,
							y: p.y,
							z: p.z,
							width: p.width,
							height: p.height,
							angle: 0,
							borderwidth: 0,
							bordercolor: '#000000',
							transformable: true,
							available: true,
							type: 'photo',
							mask: null,
							flag: true,
							autorotate: false,
							overlap_top: 0,
							overlap_right: 0,
							overlap_bottom: 0,
							overlap_left: 0,
							fillMethod: 'cover',
						};

						return photoPosition;
					},
				);
				const photos = dynamicTemplate.orderedPhotoModels.map(
					(m) => photoModels.find((p) => p.id === m.id),
				).filter((photo): photo is PI.PhotoModel => !!photo);

				return {
					templateSet: {
						id: positions.map((position) => position.id).join('_'),
						templateId: templateModel.id,
						positions,
					},
					photoModels: photos,
				};
			}

			const templateSets = this.getTemplateSets(
				templateId,
				{
					marginAroundEdge: options?.marginAroundEdge,
					marginBetweenPositions: options?.marginBetweenPositions,
				},
			);
			if (!templateSets.length) {
				return {
					templateSet: {
						id: 'empty',
						templateId,
						positions: [],
					},
					photoModels,
				};
			}

			const templateSetProperties = templateSets[0];
			return {
				templateSet: {
					...templateSetProperties,
					id: templateSetProperties.positions.map(
						(position) => position.id,
					).join('_'),
				},
				photoModels,
			};
		};
	}

	public get getFixedPages() {
		const pages = _.filter(
			this.pageStateCollection,
			(pageStateModel) => pageStateModel.serialnumber < 0,
		);

		return _.sortBy(
			pages,
			'serialnumber',
		);
	}

	public get getVirtualPageStateData() {
		return (
			offeringId: DB.OfferingModel['id'],
			pageOrientation?: PI.PageModel['orientation'],
		) => {
			const activeOfferingModel = AppDataModule.getOffering(
				offeringId,
			);

			if (pageOrientation === 'p') {
				return this.pageStateCollection.find(
					(pageStateModel) => Boolean(
						activeOfferingModel
						&& pageStateModel.pageModel.width == activeOfferingModel.height
						&& pageStateModel.pageModel.height == activeOfferingModel.width,
					),
				);
			}

			return this.pageStateCollection.find(
				(pageStateModel) => Boolean(
					activeOfferingModel
					&& pageStateModel.pageModel.width == activeOfferingModel.width
					&& pageStateModel.pageModel.height == activeOfferingModel.height,
				),
			);
		};
	}

	public get getVirtualLayoutTemplateModels() {
		return (
			offeringId: DB.OfferingModel['id'],
			pageOrientation?: PI.PageModel['orientation'],
		) => {
			const activeOfferingModel = AppDataModule.getOffering(
				offeringId,
			);

			const layoutTemplateLinkModels = this.layoutTemplateLinkCollection.filter(
				(m) => {
					const templateModel = ThemeDataModule.getTemplate(
						m.templateid,
					);
					if (!templateModel) {
						return false;
					}

					if (
						pageOrientation
						&& pageOrientation === 'p'
					) {
						return (
							activeOfferingModel
							&& templateModel.width == activeOfferingModel.height
							&& templateModel.height == activeOfferingModel.width
						);
					}

					return (
						activeOfferingModel
						&& templateModel.width == activeOfferingModel.width
						&& templateModel.height == activeOfferingModel.height
					);
				},
			);

			return layoutTemplateLinkModels;
		};
	}

	public get getPageStateData() {
		return (
			pageIndex: number,
			offset: number,
			options: {
				layoutId?: number;
				offeringId?: DB.OfferingModel['id'];
				photoOrientation?: PI.PageModel['orientation'];
			},
		) => {
			const allPages: PI.PageStateModel[] = [];
			const offeringModel = ProductStateModule.getOffering;
			let childOfferingModel: DB.OfferingModel | undefined;
			let pageOrientation: PI.PageModel['orientation'] | undefined;

			if (offeringModel?.virtual) {
				const childOfferingId = options.offeringId || ProductStateModule.getChildOfferingId;
				childOfferingModel = (
					childOfferingId
						? AppDataModule.getOffering(childOfferingId)
						: undefined
				);

				if (
					options.photoOrientation === 'p'
					&& childOfferingModel
					&& childOfferingModel.width > childOfferingModel.height
				) {
					pageOrientation = 'p';
				}
			}

			if (childOfferingModel) {
				const virtualPageState = this.getVirtualPageStateData(
					childOfferingModel.id,
					pageOrientation,
				);

				if (virtualPageState) {
					allPages.push(virtualPageState);
				}
			} else {
				const filteredPages = this.pageStateCollection.filter(
					(pageStateModel) => pageStateModel.serialnumber >= 0,
				);
				allPages.push(...filteredPages);
			}

			let pageStateModel = _.findWhere(
				allPages,
				{ serialnumber: pageIndex },
			);

			if (!pageStateModel) {
				const sortedPageStates = _.sortBy(
					allPages,
					'serialnumber',
				);
				const repeatPageStates = sortedPageStates.filter(
					(sortedPageStateModel) => Boolean(sortedPageStateModel.repeat),
				);
				const nr = offset - (sortedPageStates.length - repeatPageStates.length);
				const modulus = (nr + repeatPageStates.length) % repeatPageStates.length;
				pageStateModel = repeatPageStates[modulus];
			}

			const pageStateData: PI.PageStateModel = JSON.parse(JSON.stringify(pageStateModel));

			if (
				childOfferingModel
				&& pageOrientation === 'p'
			) {
				pageStateData.pageModel.orientation = 'p';
			}

			if (options.layoutId) {
				const allLayoutTemplateLinkModels: DB.LayoutTemplateLinkModel[] = [];

				if (
					offeringModel
					&& offeringModel.virtual
					&& childOfferingModel
				) {
					const virtualLayoutTemplates = this.getVirtualLayoutTemplateModels(
						childOfferingModel.id,
						pageOrientation,
					);
					allLayoutTemplateLinkModels.push(
						...virtualLayoutTemplates,
					);
				} else {
					allLayoutTemplateLinkModels.push(
						...this.layoutTemplateLinkCollection,
					);
				}

				const layoutTemplateLinkModels = _.where(
					allLayoutTemplateLinkModels,
					{ layoutid: options.layoutId },
				);
				let layoutTemplateModel = _.findWhere(
					layoutTemplateLinkModels,
					{ serialnumber: pageIndex },
				);

				if (
					!layoutTemplateModel
					&& pageStateModel.repeat
				) {
					const sortedLayoutTemplates = _.sortBy(
						layoutTemplateLinkModels,
						'serialnumber',
					);
					const repeatLayoutTemplates = sortedLayoutTemplates.filter(
						(sortedLayoutTemplateModel) => Boolean(sortedLayoutTemplateModel.repeat),
					);

					const nr = offset - (sortedLayoutTemplates.length - repeatLayoutTemplates.length);
					const modulus = (nr + repeatLayoutTemplates.length) % repeatLayoutTemplates.length;
					layoutTemplateModel = repeatLayoutTemplates[modulus];
				}

				if (layoutTemplateModel && layoutTemplateModel.templateid) {
					pageStateData.pageModel.template = layoutTemplateModel.templateid;
				}
			}

			return pageStateData;
		};
	}

	public get getRepeatPages(): PI.PageStateModel[] {
		const pages = this.pageStateCollection.filter(
			(pageStateModel) => pageStateModel.repeat,
		);

		return pages.sort(
			(a, b) => a.serialnumber - b.serialnumber,
		);
	}

	public get getTemplateSetById() {
		return (
			templateId: DB.TemplateModel['id'],
			setId: TemplateSet['id'],
			options?: {
				marginAroundEdge?: number;
				marginBetweenPositions?: number;
				photoCount?: number;
			},
		) => {
			const templateSets = this.getTemplateSets(
				templateId,
				{
					photoCount: options?.photoCount,
					marginAroundEdge: options?.marginAroundEdge,
					marginBetweenPositions: options?.marginBetweenPositions,
				},
			);
			return templateSets.find(
				(m) => m.id === setId,
			);
		};
	}

	public get themeHasDynamicLayout(): boolean {
		return Boolean(this.themeModel?.dynamicLayout);
	}

	public get themeHasLayoutSelect() {
		return this.themeModel && this.themeModel.layoutselect;
	}

	public get themeHasPhotoSelect() {
		return this.themeModel && this.themeModel.photoselect;
	}

	@Mutation
	private _addLayout(data: DB.LayoutModel) {
		this.layoutCollection.push(data);
	}

	@Mutation
	private _addLayoutTemplateLink(data: DB.LayoutTemplateLinkModel) {
		this.layoutTemplateLinkCollection.push(data);
	}

	@Mutation
	private _addPageState(data: PI.PageStateModel) {
		this.pageStateCollection.push(data);
	}

	@Mutation
	private _addThemeLayoutLink(data: DB.ThemeLayoutLinkModel) {
		this.themeLayoutLinkCollection.push(data);
	}

	@Mutation
	private _resetBorderImages() {
		this.borderImageCollection = [];
	}

	@Mutation
	private _resetLayouts() {
		this.layoutCollection = [];
	}

	@Mutation
	private _resetLayoutTemplateLinks() {
		this.layoutTemplateLinkCollection = [];
	}

	@Mutation
	private _resetPages() {
		this.pageStateCollection = [];
	}

	@Mutation
	private _resetTheme() {
		this.themeModel = null;
	}

	@Mutation
	private _resetThemeLayoutLinks() {
		this.themeLayoutLinkCollection = [];
	}

	@Mutation
	private resetThemeTemplateSets() {
		this.themeTemplateSets = [];
	}

	@Mutation
	private _setTheme(data: DB.ThemeModel) {
		this.themeModel = data;
	}

	@Action
	private _addTemplateState(data: TemplateStateModel) {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getTemplateSetById(data.id)) {
					commit(
						'_setTemplateState',
						data,
					);
				} else {
					commit(
						'_pushTemplateState',
						data,
					);
				}

				resolve(getters.getTemplateSetById(data.id));
			}
		});
	}

	@Action
	public reset({
		themeid,
	}: {
		themeid?: number;
	} = {}) {
		const { commit } = this.context;

		commit('_resetBorderImages');
		commit('_resetLayouts');
		commit('_resetPages');
		this._resetTheme();
		this.resetThemeTemplateSets();
		commit('_resetThemeLayoutLinks');
		commit('_resetLayoutTemplateLinks');

		if (themeid) {
			commit(
				'themedata/unloadTheme',
				themeid,
				{ root: true },
			);
		}
	}

	@Action
	public setupData({
		themeid,
	}: {
		themeid: DB.ThemeModel['id'];
	}) {
		const { commit, rootGetters, dispatch } = this.context;

		dispatch('reset');

		const themeModel = ThemeDataModule.getTheme(themeid);

		if (themeModel) {
			this._setTheme(themeModel);
		}

		const templateIds: Set<DB.TemplateModel['id']> = new Set();
		rootGetters['themedata/whereThemePageLink']({ themeid }).forEach(
			(themePageModel: DB.ThemePageLinkModel) => {
				const pageModel: PI.PageModel = rootGetters['themedata/getPage'](themePageModel.pageid);
				if (pageModel && pageModel.id) {
					const pageStateData: PI.PageStateModel = {
						id: pageModel.id,
						objects: [],
						pageModel,
						repeat: themePageModel.repeat,
						scaling: 0.5,
						serialnumber: pageModel.serialnumber || 0,
					};

					if (pageModel.template) {
						templateIds.add(pageModel.template);
					}

					rootGetters['themedata/whereObjects']({ pageid: pageModel.id }).forEach((objectModel: PI.PageObjectModel) => {
						let objectData = JSON.parse(JSON.stringify(objectModel));

						if (objectModel.type == 'photo') {
							const photoObjectModel = rootGetters['themedata/findWherePhotoObjects']({ objectid: objectModel.id });
							if (photoObjectModel) {
								objectData = _.extend(
									objectData,
									JSON.parse(JSON.stringify(photoObjectModel)),
									{ id: objectModel.id },
								);
							}
						} else if (objectModel.type == 'text') {
							const textObjectModel = rootGetters['themedata/findWhereTextObjects']({ objectid: objectModel.id });
							if (textObjectModel) {
								objectData = _.extend(
									objectData,
									JSON.parse(JSON.stringify(textObjectModel)),
									{ id: objectModel.id },
								);
							}
						}

						pageStateData.objects.push(objectData);
					});

					commit(
						'_addPageState',
						pageStateData,
					);
				}
			},
		);

		const themeLayoutLinks: DB.ThemeLayoutLinkModel[] = rootGetters['themedata/findThemeLayoutLinks']({ themeid });
		themeLayoutLinks.forEach((themeLayoutLink) => {
			commit(
				'_addThemeLayoutLink',
				themeLayoutLink,
			);

			const layout: DB.LayoutModel = rootGetters['themedata/getLayoutById'](themeLayoutLink.layoutid);
			if (layout) {
				commit(
					'_addLayout',
					layout,
				);
			}

			const layoutTemplateLinks: DB.LayoutTemplateLinkModel[] = rootGetters['themedata/findLayoutTemplateLinks']({
				layoutid: themeLayoutLink.layoutid,
			});
			layoutTemplateLinks.forEach((layoutTemplateLink) => {
				commit(
					'_addLayoutTemplateLink',
					layoutTemplateLink,
				);

				if (layoutTemplateLink.templateid) {
					templateIds.add(layoutTemplateLink.templateid);
				}
			});
		});
	}
}
