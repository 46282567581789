import PageObject from 'classes/pageobject';
import * as PI from 'interfaces/project';
import loadPhotoObject from 'mutations/pageobject/load';
import { FontModule } from 'store';
import detectBrowser from 'tools/detect-browser';
import getColorInverse from 'tools/get-color-inverse';
import {
	Component,
	Prop,
	Provide,
	Vue,
	Watch,
} from 'vue-property-decorator';
import DrawView from './draw';

@Component({
	components: {
		DrawView,
	},
})
export default class PageObjectView extends Vue {
	@Prop({ required: true, type: Object })
	readonly objectModel!: PI.PageObjectModel;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: false, type: Boolean })
	readonly showBleed!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showMask!: boolean;

	// Allows any child component to `inject: ['canvas']` and have access to it.
	// This is the CanvasRenderingContext that children will draw to.
	@Provide('canvas')
		canvas: {
		context: CanvasRenderingContext2D|null;
	} = {
				context: null,
			};

	@Watch('objectModel._image')
	@Watch('objectModel._mask')
	@Watch('objectModel._borderimage')
	@Watch('objectModel.fontface')
	@Watch('objectModel.borderimage')
	@Watch('objectModel.mask')
	@Watch('objectModel.effect')
	@Watch('objectModel.photoid')
	@Watch('objectModel.source')
	onPageObjectViewPropsChange() {
		this.loadAssets();
	}

	data() {
		return {
			browser: detectBrowser(),
		};
	}

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset ? this.pageModel.offset : 0;
	}

	get height() {
		const vars = PageObject.calculatePosition(
			{
				x_axis: this.objectModel.x_axis,
				y_axis: this.objectModel.y_axis,
				width: this.objectModel.width,
				height: this.objectModel.height,
				borderwidth: this.objectModel.borderwidth,
				bordercolor: this.objectModel.bordercolor,
				cropx: this.objectModel.cropx,
				cropy: this.objectModel.cropy,
				cropwidth: this.objectModel.cropwidth,
				cropheight: this.objectModel.cropheight,
				angle: this.objectModel.rotate,
				flop: Boolean(this.objectModel.flop),
				flip: Boolean(this.objectModel.flip),
				type: this.objectModel.type,
				maxwidth: this.objectModel.maxwidth,
				photoid: this.objectModel.photoid || undefined,
			},
			this.scaling,
			this.pageModel,
			this.bleedMargin,
		);
		return Math.round(vars.canvas.height);
	}

	get inverseColor() {
		return getColorInverse(this.pageModel.bgcolor || '#FFFFFF');
	}

	get styleObject() {
		const vars = PageObject.calculatePosition(
			{
				x_axis: this.objectModel.x_axis,
				y_axis: this.objectModel.y_axis,
				width: this.objectModel.width,
				height: this.objectModel.height,
				borderwidth: this.objectModel.borderwidth,
				bordercolor: this.objectModel.bordercolor,
				cropx: this.objectModel.cropx,
				cropy: this.objectModel.cropy,
				cropwidth: this.objectModel.cropwidth,
				cropheight: this.objectModel.cropheight,
				angle: this.objectModel.rotate,
				flop: Boolean(this.objectModel.flop),
				flip: Boolean(this.objectModel.flip),
				type: this.objectModel.type,
				maxwidth: this.objectModel.maxwidth,
				photoid: this.objectModel.photoid || undefined,
			},
			this.scaling,
			this.pageModel,
			this.bleedMargin,
		);
		return {
			top: `${vars.canvas.top}px`,
			left: `${vars.canvas.left}px`,
		};
	}

	get width() {
		const vars = PageObject.calculatePosition(
			{
				x_axis: this.objectModel.x_axis,
				y_axis: this.objectModel.y_axis,
				width: this.objectModel.width,
				height: this.objectModel.height,
				borderwidth: this.objectModel.borderwidth,
				bordercolor: this.objectModel.bordercolor,
				cropx: this.objectModel.cropx,
				cropy: this.objectModel.cropy,
				cropwidth: this.objectModel.cropwidth,
				cropheight: this.objectModel.cropheight,
				angle: this.objectModel.rotate,
				flop: Boolean(this.objectModel.flop),
				flip: Boolean(this.objectModel.flip),
				type: this.objectModel.type,
				maxwidth: this.objectModel.maxwidth,
				photoid: this.objectModel.photoid || undefined,
			},
			this.scaling,
			this.pageModel,
			this.bleedMargin,
		);
		return Math.round(vars.canvas.width);
	}

	created() {
		this.loadAssets();
	}

	mounted() {
		// We can't access the rendering context until the canvas is mounted to the DOM.
		// Once we have it, provide it to all child components.
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		this.canvas.context = canvas.getContext('2d');
	}

	loadAssets() {
		if (this.objectModel.type == 'text' && this.objectModel.fontface) {
			const fontModel = FontModule.getById(this.objectModel.fontface);
			if (fontModel && !fontModel._loaded) {
				FontModule
					.loadModel(fontModel.id)
					.catch(() => {
						const closeError = this.$openErrorDialog({
							body: {
								content: this.$t('dialogTextLoadError'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogButtonOk'),
										click: () => {
											this.loadAssets();
											closeError();
										},
									},
								],
							},
						});
					});
			}
		} else if (this.objectModel.type == 'photo') {
			if (!this.objectModel._image) {
				loadPhotoObject(
					this.objectModel,
					{
						mask: true,
						scaling: this.scaling,
					},
				);
			} else if (this.objectModel.mask && !this.objectModel._mask) {
				loadPhotoObject(
					this.objectModel,
					{
						mask: true,
						scaling: this.scaling,
					},
				);
			} else if (this.objectModel.borderimage && !this.objectModel._borderimage) {
				loadPhotoObject(
					this.objectModel,
					{
						mask: true,
						scaling: this.scaling,
					},
				);
			}
		}
	}
}
