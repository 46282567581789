import merge from 'deepmerge';

export default (
	message: string,
	properties?: Record<string, any>,
	opts?: {
		console: boolean;
	},
) => {
	const defaultProps = {};
	const defaultOptions = {
		console: window.glStack == 'local' || window.glStack == 'staging', // Display error with console.log?
	};
	properties = properties ? merge(
		defaultProps,
		properties,
	) : defaultProps;
	const options = opts ? merge(
		defaultOptions,
		opts,
	) : defaultOptions;

	if (options.console) {
		console.log(
			message,
			properties,
		);
	}
};
