const setCookie = (
	name: string,
	value: string | number | null,
	exdays: number | null = null,
) => {
	const exdate = new Date();
	exdate.setDate(exdate.getDate() + (exdays || 0));
	const cTime = (exdays === null)
		? ''
		: `; expires=${exdate.toUTCString()}`;
	const cValue = value === null
		? undefined
		: encodeURIComponent(`${value}`);
	const cPath = ';path=/';
	document.cookie = `${name}=${cValue}${cTime}${cPath}`;
};

export default setCookie;
