export const discountModelAttributes = {
	code: null,
	id: null,
	name: null,
	absolute: null,
	relative: null,
	freehandling: 0,
};

export const voucherModelAttributes = {
	code: null,
	credit_absolute: 0,
};
