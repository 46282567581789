import * as DB from 'interfaces/database';
import { AppDataModule, AppStateModule, ConfigModule } from '../store/index';
import ajax from '../controllers/ajax';
import { ERRORS_INVALID_RETURN_DATA, ERRORS_OFFLINE, ERRORS_UNSUPPORTED_FEATURE } from '../settings/errors';

export default (): Promise<DB.CountryModel> => {
	if (!ConfigModule['features.countryDetection']) {
		return Promise.reject(
			new Error(ERRORS_UNSUPPORTED_FEATURE),
		);
	}

	if (!AppStateModule.online) {
		return Promise.reject(
			new Error(ERRORS_OFFLINE),
		);
	}

	// Fetch geoIP from Maxmind API
	return ajax
		.request(
			{
				method: 'get',
				url: 'https://geoip-js.com/geoip/v2.1/country/me',
				params: {
					referrer: window.glAppUrl,
				},
			},
			{
				auth: false,
				debug: {
					log: !(window.glStack == 'local'), // Log error?
				},
			},
		)
		.then((response) => {
			const responseData = response.data;
			if (!responseData.country || !responseData.country.iso_code) {
				throw new Error(ERRORS_INVALID_RETURN_DATA);
			}

			const isoCode = responseData.country.iso_code;
			const countryModel = AppDataModule.findCountryWhere({ iso: isoCode });
			if (!countryModel) {
				throw new Error('Could not find country model');
			}

			return countryModel;
		});
};
