import _ from 'underscore';
import { UserAgentApplication } from 'msal';
import { MSALAuthenticationProvider } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProvider';
import { DriveItem } from '@microsoft/microsoft-graph-types-beta'; // eslint-disable-line import/no-unresolved
// import { Client } from '@microsoft/microsoft-graph-client';
import {
	ChannelModel,
	ChannelProfileModel,
} from 'interfaces/app';
import { ERRORS_OAUTH_FAILED } from '../settings/errors';
import ajax from '../controllers/ajax';
import {
	ConnectorBridge,
	ConnectorBridgeLoginResponse,
	ConnectorBridgeLoginOptions,
	ConvertedPhotoData,
} from '../controllers/connector';

interface MSALAuthResponse {
	uniqueId: string;
	tenantId: string;
	tokenType: string;
	// idToken: IdToken;
	// idTokenClaims: StringDict;
	accessToken: string;
	scopes: string[];
	expiresOn: Date;
	// account: Account;
	accountState: string;
}

interface MicrosoftGraphResponse {
	id: string;
	value?: DriveItem[];
	'@odata.nextLink'?: string;
}

class MicrosoftClass implements ConnectorBridge {
	private accessToken: string | undefined = undefined;

	private authProvider: MSALAuthenticationProvider | undefined = undefined;

	private channel = 'microsoft';

	public contentFilters: string[] = [];

	private endpoint = 'https://graph.microsoft.com/v1.0';

	public grantedScopes: string[] = [];

	public isSupported = true;

	private mimeTypes: string[] = [
		'image/jpeg',
		'image/jpg',
		'image/png',
		'image/gif',
		'image/svg',
	];

	private network: ChannelModel['id'] = 'microsoft';

	private scopes: string[] = [
		'user.read',
		'Files.read',
	];

	private userAgentApplication: UserAgentApplication | undefined = undefined;

	private version = 'beta';

	public setup() {
		return Promise.resolve();
	}

	public init(channelModel: ChannelModel) {
		if (!this.userAgentApplication && channelModel.apikey) {
			this.userAgentApplication = new UserAgentApplication({
				auth: {
					authority: 'https://login.microsoftonline.com/consumers',
					clientId: channelModel.apikey,
					redirectUri: `${window.glAppUrl}app/oauth/microsoft`,
				},
				cache: {
					cacheLocation: 'sessionStorage',
					storeAuthStateInCookie: true,
				},
			});
		}

		if (!this.authProvider && this.userAgentApplication) {
			this.authProvider = new MSALAuthenticationProvider(
				this.userAgentApplication,
				this.scopes,
			);
		}

		return Promise.resolve();
	}

	public login(
		scope: string[],
		options: ConnectorBridgeLoginOptions,
	): Promise<ConnectorBridgeLoginResponse> {
		return new Promise((resolve, reject) => {
			if (this.authProvider) {
				/* this.client = Client.initWithMiddleware({
					authProvider: this.authProvider,
					defaultVersion: this.version,
				}); */

				const processToken = (accessTokenResponse: MSALAuthResponse) => {
					this.accessToken = accessTokenResponse.accessToken;
					this.me()
						.then(
							(response) => {
								resolve({
									accessToken: this.accessToken,
									userid: response.id || undefined,
								});
							},
							() => {
								reject(new Error(ERRORS_OAUTH_FAILED));
							},
						);
				};

				const accessTokenRequest = {
					scopes: this.scopes,
				};

				if (options.display == 'none') {
					this.authProvider.userAgentApplication
						.acquireTokenSilent(accessTokenRequest)
						.then(
							(resp) => {
								processToken(resp);
							},
							() => {
								reject(new Error(ERRORS_OAUTH_FAILED));
							},
						);
				} else if (options.display == 'page') {
					this.authProvider.userAgentApplication.handleRedirectCallback(() => {
						/*
						The following is obsolete when using the page redirect flow

						this.authProvider.userAgentApplication
							.acquireTokenSilent(accessTokenRequest)
							.then(processToken, (err) => {
								reject(new Error(ERRORS_OAUTH_FAILED));
						});
						*/
					});

					this.authProvider.userAgentApplication.loginRedirect({
						scopes: this.scopes,
					});
				} else {
					this.authProvider.userAgentApplication.loginPopup({
						scopes: this.scopes,
					}).then(
						processToken,
						() => {
							reject(new Error(ERRORS_OAUTH_FAILED));
						},
					);
				}
			} else {
				reject(new Error('SDK is unavailable'));
			}
		});
	}

	public logout() {
		/*
		The logout method will redirect the user to the postLogoutRedirectUri
		Since we do not want any navigation in this method, this is disabled for now

		if (this.userAgentApplication) {
			this.userAgentApplication.logout();
		}
		*/
		return Promise.resolve();
	}

	private api(route: string): Promise<MicrosoftGraphResponse> {
		/*
		We would like to use the microsoft-graph-client library for this
		But since the current version of this library will create a login popup,
		we disabled it for now

		return this.client
			.api(route)
			.get();
		*/
		return ajax
			.request(
				{
					url: route.substring(
						0,
						4,
					) == 'http' ? route : `${this.endpoint}/${route}`,
					headers: {
						Authorization: `Bearer ${this.accessToken}`,
					},
				},
				{
					auth: false,
				},
			)
			.then((response) => response.data)
			.catch((err) => {
				if (err.axiosResponse.data.error.code != 'InvalidAuthenticationToken') {
					throw err;
				}

				return this.login(
					[],
					{ display: 'none' },
				).then(
					() => this.api(route),
				);
			});
	}

	public me(): Promise<ChannelProfileModel> {
		return new Promise((resolve, reject) => {
			this
				.api('me')
				.then(
					(resp: MicrosoftGraphResponse) => {
						resolve({
							id: resp.id,
						});
					},
					reject,
				);
		});
	}

	public folders(
		folderid: string | null,
		options: any,
	) {
		let path = folderid ? `me/drive/items/${folderid}/children` : 'me/drive/root/children';
		path += '?$expand=thumbnails';
		return this.api(options.nextPage || path).then((response: MicrosoftGraphResponse) => ({
			data: response.value,
			// paging: null,
			paging: {
				nextPage: response.hasOwnProperty('@odata.nextLink')
					? response['@odata.nextLink']
					: undefined,
			},
		}));
	}

	public albums() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albumPhotos() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public photos() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public convertAlbumData() {
		throw new Error('Bridge function not implemented');
	}

	public convertPhotoData(photoData: DriveItem) {
		if (!photoData.file || !photoData.file.mimeType || _.indexOf(
			this.mimeTypes,
			photoData.file.mimeType,
		) === -1) {
			return undefined;
		}

		const objPhoto: ConvertedPhotoData = {
			source: this.network,
			externalId: String(photoData.id),
			full_url: `https://graph.microsoft.com/beta/me/drive/items/${photoData.id}/content`,
		};
		if (photoData.image) {
			if (photoData.image.width) {
				objPhoto.full_width = photoData.image.width;
			}
			if (photoData.image.height) {
				objPhoto.full_height = photoData.image.height;
			}
		}
		if (photoData.thumbnails) {
			const thumbnailEntry = photoData.thumbnails[0];
			if (thumbnailEntry && thumbnailEntry.medium) {
				objPhoto.thumb_url = thumbnailEntry.medium.url;
				objPhoto.thumb_width = thumbnailEntry.medium.width;
				objPhoto.thumb_height = thumbnailEntry.medium.height;
			}
			if (thumbnailEntry && thumbnailEntry.large) {
				objPhoto.url = thumbnailEntry.large.url;
				objPhoto.width = thumbnailEntry.large.width;
				objPhoto.height = thumbnailEntry.large.height;
			}
		}

		if (photoData.photo && photoData.photo.takenDateTime) {
			objPhoto.photodate = photoData.photo.takenDateTime;
		}

		return objPhoto;
	}

	public convertFolderData(folderData: DriveItem) {
		const objFolder = {
			id: folderData.id,
			name: folderData.name,
		};

		return objFolder;
	}

	public getFileType(fileData: DriveItem) {
		if (fileData.folder) {
			return 'folder';
		}
		if (fileData.file && fileData.file.mimeType && _.indexOf(
			this.mimeTypes,
			fileData.file.mimeType,
		) >= 0) {
			return 'photo';
		}
		return null;
	}

	public share() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}
}

export default MicrosoftClass;
