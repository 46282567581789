import './defines';
import {
	EditorPreviewPageObjectModels,
	EditorPreviewScalingBase,
	OfferingFrameModel,
} from 'interfaces/app';
import {
	Model2DModel,
	OfferingModel,
	PageModel,
} from 'interfaces/database';
import { PageObjectModel } from 'interfaces/project';
import { AppDataModule } from 'store';
import getCanvasSize from 'tools/get-canvas-size';
import EditorDrawView from 'views/editor-draw';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorPreview2DView',
	components: {
		EditorDrawView,
	},
})
export default class EditorPreview2DView extends Vue.extend(Template) {
	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		default: undefined,
		schema: 'OfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		event: 'page-objects-change',
		required: true,
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects!: EditorPreviewPageObjectModels;

	@Prop({
		acceptedValues: [
			'auto',
			'height',
			'width',
		],
		default: 'auto',
		schema: 'EditorPreviewScalingBase',
		type: String,
	})
	public readonly scalingBase!: EditorPreviewScalingBase;

	@Prop({
		required: true,
		schema: 'Model2DModel',
		type: Object,
	})
	public readonly value!: Model2DModel;

	protected get computedFullScaling(): number {
		const canvasHeight = Math.round(
			this.pageModel.height + (this.bleedMargin * 2),
		);
		const canvasWidth = Math.round(
			this.pageModel.width + (this.bleedMargin * 2),
		);

		const canvasSize = getCanvasSize(
			canvasWidth,
			canvasHeight,
		);

		return canvasSize.width / canvasWidth;
	}

	private get drawHeight(): number {
		if (this.value.ppi) {
			const heightInInch = this.offeringModel.height / this.offeringModel.configdpi;
			const heightInPixels = heightInInch * this.value.ppi;

			return heightInPixels * this.scale;
		}

		if (this.value.frameHeight) {
			return this.value.frameHeight * this.scale;
		}

		throw new Error('No ppi or frameHeight');
	}

	private get drawWidth(): number {
		if (this.value.ppi) {
			const widthInInch = this.offeringModel.width / this.offeringModel.configdpi;
			const widthInPixels = widthInInch * this.value.ppi;

			return widthInPixels * this.scale;
		}

		if (this.value.frameWidth) {
			return this.value.frameWidth * this.scale;
		}

		throw new Error('No ppi or frameWidth');
	}

	private get drawX(): number {
		if (this.value.centerHorizontal) {
			return (this.value.centerHorizontal * this.scale) - (this.drawWidth / 2);
		}

		if (this.value.frameLeft) {
			return this.value.frameLeft * this.scale;
		}

		throw new Error('No centerHorizontal or frameLeft');
	}

	private get drawY(): number {
		if (this.value.centerVertical) {
			return (this.value.centerVertical * this.scale) - (this.drawHeight / 2);
		}

		if (this.value.frameTop) {
			return this.value.frameTop * this.scale;
		}

		throw new Error('No centerVertical or frameTop');
	}

	private get imageHeight(): number {
		return Math.round(this.value.imageHeight * this.scale);
	}

	private get imageWidth(): number {
		return Math.round(this.value.imageWidth * this.scale);
	}

	/**
	 * Since we are cropping the depth pixels in the 2D view, we need to compensate for this loss
	 */
	private get offeringFrameImageDepthCompensation(): number {
		if (!this.offeringModel) {
			return 0;
		}

		return this.offeringModel.depth * (this.drawWidth / this.offeringWidthFlat);
	}

	private get offeringFrameImageHeight(): number {
		if (!this.offeringFrameModel) {
			return 1;
		}

		return this.offeringFrameModel.imageModel.height * this.offeringFrameScale;
	}

	private get offeringFrameImageLeft(): number {
		if (!this.offeringFrameModel || !this.offeringModel) {
			return 0;
		}

		return (this.offeringFrameModel.templateModel.x * this.offeringFrameScale) + this.offeringFrameImageDepthCompensation;
	}

	private get offeringFrameImageTop(): number {
		if (!this.offeringFrameModel || !this.offeringModel) {
			return 1;
		}

		return (this.offeringFrameModel.templateModel.y * this.offeringFrameScale) + this.offeringFrameImageDepthCompensation;
	}

	private get offeringFrameImageWidth(): number {
		if (!this.offeringFrameModel) {
			return 1;
		}

		return this.offeringFrameModel.imageModel.width * this.offeringFrameScale;
	}

	private get offeringFrameScale(): number {
		if (
			!this.offeringFrameModel
			|| !this.offeringModel
		) {
			return 1;
		}

		return (this.drawWidth / this.offeringFrameModel.templateModel.width) * (this.offeringModel.width / this.offeringWidthFlat);
	}

	private get offeringMaskImage(): HTMLImageElement | undefined {
		if (!this.offeringModel.mask) {
			return undefined;
		}

		AppDataModule.getAndSetOfferingMaskImage(this.offeringModel);

		return AppDataModule.findOfferingMaskImage(this.offeringModel);
	}

	/**
	 * Get the width of the offering without the depth
	 */
	private get offeringWidthFlat(): number {
		if (!this.offeringModel) {
			return 0;
		}

		return this.offeringModel.width - (2 * this.offeringModel.depth);
	}

	protected get isReady(): boolean {
		return !!(
			this.modelImage
			&& (
				!this.offeringFrameModel
				|| this.offeringFrameImage
			)
			&& this.editorDrawCanvasElement
		);
	}

	private get scale(): number {
		if (this.$el) {
			if (this.scalingBase === 'height') {
				return this.$el.clientHeight / this.value.imageHeight;
			}
			if (this.scalingBase === 'width') {
				return this.$el.clientWidth / this.value.imageWidth;
			}

			return Math.min(
				this.$el.clientWidth / this.value.imageWidth,
				this.$el.clientHeight / this.value.imageHeight,
			);
		}

		return 0;
	}

	@Ref('canvas')
	private readonly canvasElement!: HTMLCanvasElement;

	@Ref('editorDraw')
	private readonly editorDrawView!: EditorDrawView;

	private editorDrawCanvasElement: HTMLCanvasElement | null = null;

	private intersectionObserver?: IntersectionObserver;

	private modelImage: HTMLImageElement | null = null;

	private offeringFrameImage: HTMLImageElement | null = null;

	private resizeObserver?: ResizeObserver;

	protected beforeDestroy(): void {
		if (this.offeringModel.mask) {
			AppDataModule.unsetOfferingMaskImage(this.offeringModel);
		}

		this.intersectionObserver?.disconnect();
		this.modelImage?.remove();
		this.offeringFrameImage?.remove();
		this.resizeObserver?.disconnect();
	}

	protected mounted(): void {
		this.updateScaling();
		this.intersectionObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					this.updateScaling();
					this.$nextTick(() => this.editorDrawView.$forceUpdate());
				}
			},
			{
				root: null,
				rootMargin: '0px',
				threshold: 0.9,
			},
		);
		this.intersectionObserver.observe(this.$el);
		this.resizeObserver = new ResizeObserver(() => this.updateScaling());
		this.resizeObserver.observe(this.$el);
		this.loadModelImage();
		this.loadOfferingFrameImage();
	}

	private updateScaling(): void {
		this.$forceCompute('scale');
		this.$forceCompute('drawHeight');
		this.$forceCompute('drawWidth');
		this.$forceCompute('drawX');
		this.$forceCompute('drawY');
		this.$forceCompute('imageHeight');
		this.$forceCompute('imageWidth');
	}

	@Watch('isReady')
	protected onIsReadyChange(): void {
		if (this.isReady) {
			this.paintPreview();
		}
	}

	@Watch('offeringFrameModel')
	protected onOfferingFrameModelChange(): void {
		this.loadOfferingFrameImage();
	}

	@Watch('scale')
	protected onScaleChange(): void {
		this.paintPreview();
	}

	@Watch('value')
	protected onValueChange(): void {
		this.loadModelImage();
	}

	private loadModelImage(): void {
		const modelImage = new Image();
		modelImage.onload = () => {
			this.modelImage = modelImage;
		};
		modelImage.onerror = () => {
			// Something went wrong, handle situation
		};
		modelImage.crossOrigin = 'anonymous';
		modelImage.src = `${this.value.imageUrl}?noCorsHeader`;
	}

	private loadOfferingFrameImage(): void {
		if (this.offeringFrameModel) {
			const offeringFrameImage = new Image();
			offeringFrameImage.onload = () => {
				this.offeringFrameImage = offeringFrameImage;
			};
			offeringFrameImage.onerror = () => {
				// Something went wrong, handle situation
			};
			offeringFrameImage.crossOrigin = 'anonymous';
			offeringFrameImage.src = `${this.offeringFrameModel.imageModel.url}?noCorsHeader`;
		} else {
			this.offeringFrameImage = null;
		}
	}

	protected onCanvasDrawn(canvasElement: HTMLCanvasElement): void {
		if (canvasElement) {
			this.editorDrawCanvasElement = canvasElement;

			if (this.isReady) {
				this.paintPreview();
			}
		}
	}

	protected onDrawObjectsChange(
		pageObjects: EditorPreviewPageObjectModels,
		objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>>,
	): void {
		this.$emit(
			'page-objects-change',
			pageObjects,
			objectsDifferences,
		);
	}

	private paintModel(): void {
		if (this.modelImage) {
			const context = this.canvasElement.getContext('2d');
			context?.drawImage(
				this.modelImage,
				0,
				0,
				this.imageWidth,
				this.imageHeight,
			);
		}
	}

	private paintOfferingFrame(): void {
		if (this.offeringFrameImage) {
			const context = this.canvasElement.getContext('2d');
			context?.drawImage(
				this.offeringFrameImage,
				this.drawX - this.offeringFrameImageLeft,
				this.drawY - this.offeringFrameImageTop,
				this.offeringFrameImageWidth,
				this.offeringFrameImageHeight,
			);
		}
	}

	private paintPreview(): void {
		const canvasContext = this.canvasElement.getContext('2d');

		if (canvasContext) {
			canvasContext.restore();
			canvasContext.clearRect(
				0,
				0,
				canvasContext.canvas.width,
				canvasContext.canvas.height,
			);

			// Save default context
			canvasContext.save();
			this.canvasElement.height = this.value.imageHeight * this.scale;
			this.canvasElement.width = this.value.imageWidth * this.scale;
		}

		if (this.value.stackOnTop) {
			this.paintModel();
			this.paintProject();
		} else {
			this.paintProject();
			this.paintModel();
		}
	}

	private paintProject(): void {
		// Get the canvas that holds the painting of the pageModel (the page in the user's project)
		const { editorDrawCanvasElement } = this;

		if (editorDrawCanvasElement) {
			// Calculate the original cropping of the canvas that we want to use
			let canvasCropWidth = editorDrawCanvasElement.width;
			let canvasCropHeight = editorDrawCanvasElement.height;
			const canvasScale = editorDrawCanvasElement.width / this.offeringModel.width;
			let cropX = 0;
			let cropY = 0;

			if (
				this.offeringModel
				&& this.offeringModel.depth
			) {
				canvasCropWidth -= 2 * this.offeringModel.depth * canvasScale;
				canvasCropHeight -= 2 * this.offeringModel.depth * canvasScale;
				cropX += this.offeringModel.depth * canvasScale;
				cropY += this.offeringModel.depth * canvasScale;
			}

			// In case where we're showing a preview of the user's project projected on a different offering,
			// we will need to crop the painting on the canvas
			let cropWidth = canvasCropWidth;
			let cropHeight = canvasCropHeight;

			// In case the user is currently working on a 20x20 wall decoration, but we're showing a preview
			// of a 30x20 wall decoration, we are dealing with different ratios and thus we'll need to crop the user's project
			const offeringRatio = this.offeringModel.width / this.offeringModel.height;
			const projectRatio = canvasCropWidth / canvasCropHeight;

			// We compare ratios with rounding precision of one digit
			if ((Math.round(offeringRatio * 10) / 10) !== (Math.round(projectRatio * 10) / 10)) {
				if (offeringRatio > projectRatio) {
					cropHeight = (projectRatio / offeringRatio) * canvasCropHeight;
					cropY += (canvasCropHeight - cropHeight) / 2;
				} else {
					cropWidth = (offeringRatio / projectRatio) * canvasCropWidth;
					cropX += (canvasCropWidth - cropWidth) / 2;
				}
			}

			if (editorDrawCanvasElement) {
				const context = this.canvasElement.getContext('2d');

				if (context) {
					if (
						this.offeringFrameModel
						&& !this.offeringFrameModel.overpage
					) {
						this.paintOfferingFrame();
					}

					context.save();

					if (
						!this.offeringFrameModel
						|| (
							this.offeringFrameModel.templateModel.x === 0
							&& this.offeringFrameModel.templateModel.y === 0
						)
					) {
						// Draw shadow to show some depth
						// Note: only for offerings that do not have a frame drawn around them
						context.shadowColor = 'rgba(0,0,0,0.5)';
						context.shadowBlur = 5;
					}

					if (!this.offeringModel.mask) {
						context.fillStyle = this.pageModel.bgcolor || 'transparent';
						context.fillRect(
							this.drawX,
							this.drawY,
							this.drawWidth,
							this.drawHeight,
						);
					} else if (this.offeringMaskImage) {
						context.drawImage(
							this.offeringMaskImage,
							this.drawX,
							this.drawY,
							this.drawWidth,
							this.drawHeight,
						);
					}
					context.restore();
					context.drawImage(
						editorDrawCanvasElement,
						cropX,
						cropY,
						cropWidth,
						cropHeight,
						this.drawX,
						this.drawY,
						this.drawWidth,
						this.drawHeight,
					);

					if (
						this.offeringFrameModel
						&& this.offeringFrameModel.overpage
					) {
						this.paintOfferingFrame();
					}
				}
			}
		}
	}
}
