import {
	Vue,
	Component,
	Prop,
	Ref,
} from 'vue-property-decorator';
import { Scrollbar } from 'interfaces/app';
import EventBus from '../components/event-bus';

@Component
export default class AppHeaderView extends Vue {
	@Prop({ default: 0, type: Number })
	readonly barWidth!: number;

	@Prop({ required: true, type: Object })
	readonly scrollbarModel!: Scrollbar;

	@Ref('scrollSpace') readonly scrollSpace!: HTMLElement;

	drag: {
		enabled: boolean;
		x: number|null;
		startValue: number|string|null;
	} = {
			enabled: false,
			x: null,
			startValue: null,
		};

	get handleStyle() {
		if (this.scrollbarModel.type == 'colors') {
			return {
				background: this.scrollbarModel.value === null ? 'rgba(255,255,255,0.5)' : this.scrollbarModel.value,
			};
		}
		return null;
	}

	get handleText() {
		return this.scrollbarModel.type == 'size'
			? this.scrollbarModel.value
			: undefined;
	}

	get offset() {
		return this.scrollSpace.getBoundingClientRect().left;
	}

	get scrollPercentage() {
		const currentValue = this.scrollbarModel.value;
		const { values } = this.scrollbarModel;
		let index = values.indexOf(currentValue);

		if (index < 0) {
			let closest: null|number|string = null;
			values.forEach((value) => {
				if (closest === null || (
					typeof value === 'number'
					&& typeof currentValue === 'number'
					&& typeof closest === 'number'
					&& Math.abs(value - currentValue) < Math.abs(closest - currentValue)
				)) {
					closest = value;
				}
			});
			index = values.indexOf(closest);
		}
		const fraction = index / (values.length - 1);

		return Math.round(fraction * 100);
	}

	get styleWidth() {
		return this.scrollbarModel.minBarWidth ? Math.max(
			this.scrollbarModel.minBarWidth,
			this.barWidth,
		) : this.barWidth;
	}

	get subscript() {
		return this.scrollbarModel.text || ' ';
	}

	beforeDestroy() {
		if (this.scrollbarModel.onClose) {
			this.scrollbarModel.onClose();
		}

		this.unbindEvents();
	}

	startDrag(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.targetTouches
			? e.targetTouches.item(0)
			: e;

		if ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent) {
			this.drag.enabled = true;
			this.drag.x = evt.pageX;
			this.drag.startValue = this.scrollbarModel.value;

			this.bindEvents();
		}
	}

	dragEvent(e: MouseEvent|TouchEvent|PointerEvent) {
		const evt = window.TouchEvent && e instanceof TouchEvent && e.targetTouches
			? e.targetTouches.item(0)
			: e;

		if (this.drag.enabled
			&& ((window.Touch && evt instanceof Touch) || evt instanceof MouseEvent)
			&& (evt.pageX - (this.drag.x || 0) !== 0)
		) {
			const { values } = this.scrollbarModel;
			const position = evt.pageX;
			const fraction = Math.max(
				0,
				Math.min(
					1,
					(position - this.offset) / this.styleWidth,
				),
			);
			const nr = Math.min(
				values.length - 1,
				Math.round(fraction * values.length),
			);
			const value = values[nr];

			if (this.scrollbarModel.onChange) {
				this.scrollbarModel.onChange(
					value,
					this.scrollbarModel,
				);
			}

			this.drag.x = evt.pageX;
			this.scrollbarModel.value = value;
		}
	}

	endDragEvent() {
		this.drag.enabled = false;
		this.unbindEvents();
		if (this.scrollbarModel.onDragEnd) {
			this.scrollbarModel.onDragEnd();
		}
	}

	bindEvents() {
		EventBus.$on(
			'mouse:move',
			this.dragEvent,
		);
		EventBus.$on(
			'mouse:up',
			this.endDragEvent,
		);
	}

	unbindEvents() {
		EventBus.$off(
			'mouse:move',
			this.dragEvent,
		);
		EventBus.$off(
			'mouse:up',
			this.endDragEvent,
		);
	}
}
