import * as PI from 'interfaces/project';
import deleteObject from 'mutations/pageobject/delete';
import { ProductStateModule } from 'store';
import _ from 'underscore';

class Page {
	/**
	 * Copies all objects as defined by the theme to the user's project page
	 *
	 * @param themePageState The theme's page state model
	 * @param pageModel The page model to copy the objects to
	 */
	static copyObjects(
		themePageState: PI.PageStateModel,
		pageModel: PI.PageModel,
	) {
		themePageState.objects.forEach(
			(objectModel) => {
				const objectData = JSON.parse(JSON.stringify(objectModel));

				delete objectData.pageid;
				delete objectData.productid;
				delete objectData.id;

				ProductStateModule.addPageObject({
					pageId: pageModel.id,
					data: objectData,
				});
			},
		);
	}

	/**
	 * Clear the content of a page in a project
	 *
	 * @param pageModel The page model you want to remove the content from
	 * @param options Configuration options to use
	 * @param options.objectTypes The type of objects to remove from the page
	 */
	static deleteObjects(
		pageModel: PI.PageModel,
		options: {
			objectTypes: PI.PageObjectModel['type'][];
		},
	): Promise<void> {
		const objectModels = ProductStateModule.getPageObjects(pageModel).filter(
			(objectModel) => options.objectTypes.indexOf(objectModel.type) >= 0,
		);
		if (!objectModels.length) {
			return Promise.resolve();
		}

		return new Promise((resolve) => {
			objectModels.forEach(
				(objectModel, i) => {
					// Remove objects one-by-one
					// We use defer to make sure the UI doesn't freeze while iterating
					_.defer(
						(
							deffObjectModel: PI.PageObjectModel,
							isLastItem: boolean,
						) => {
							if (options.objectTypes.indexOf(deffObjectModel.type) >= 0) {
								deleteObject(
									deffObjectModel,
									{
										propagate: false,
									},
								);
							}

							if (isLastItem) {
								resolve();
							}
						},
						objectModel,
						i == objectModels.length - 1,
					);
				},
			);
		});
	}
}

export default Page;
