const brush: string = require('../../img/masks/brush.svg');
const circle: string = require('../../img/masks/circle.svg');
const cloud: string = require('../../img/masks/cloud.svg');
const clover: string = require('../../img/masks/clover.svg');
const diamond: string = require('../../img/masks/diamond.svg');
const flower: string = require('../../img/masks/flower.svg');
const heart: string = require('../../img/masks/heart.svg');
const octagon: string = require('../../img/masks/octagon.svg');
const square: string = require('../../img/masks/square.svg');
const stamp: string = require('../../img/masks/stamp.svg');
const star: string = require('../../img/masks/star.svg');

/*
IMPORTANT: When adding SVG masks, make sure the SVG has the properties
"preserveAspectRatio: none", "width", and "height" defined
Otherwise Firefox will run into problems displaying them
*/

const maskPresets: {
	[key: string]: {
		src: string;
		lockAspectRatio: boolean;
	};
} = {
	brush: {
		src: brush,
		lockAspectRatio: false,
	},
	circle: {
		src: circle,
		lockAspectRatio: false,
	},
	circleLocked: {
		src: circle,
		lockAspectRatio: true,
	},
	cloud: {
		src: cloud,
		lockAspectRatio: false,
	},
	clover: {
		src: clover,
		lockAspectRatio: true,
	},
	diamond: {
		src: diamond,
		lockAspectRatio: false,
	},
	flower: {
		src: flower,
		lockAspectRatio: true,
	},
	heart: {
		src: heart,
		lockAspectRatio: true,
	},
	octagon: {
		src: octagon,
		lockAspectRatio: false,
	},
	squareLocked: {
		src: square,
		lockAspectRatio: true,
	},
	stamp: {
		src: stamp,
		lockAspectRatio: false,
	},
	star: {
		src: star,
		lockAspectRatio: true,
	},
};

export default maskPresets;
