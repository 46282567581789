import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import merge from 'deepmerge';
import ObjectToolbarView from 'views/toolbar-object';
import EditTextView from 'views/edit-text.vue';
import EventBus from 'components/event-bus';
import * as PI from 'interfaces/project';

@Component({
	components: {
		EditTextView,
		ObjectToolbarView,
	},
})
export default class EditorToolbarView extends Vue {
	@Prop({ default: null, type: Object })
	readonly pageModel!: PI.PageModel|null;

	private collapsed = true;

	private showEditTextToolbar = false;

	private showObjectToolbar = false;

	mounted() {
		// Open object toolbar when object is selected
		EventBus.$on(
			'selectObject',
			this.objectSelected,
		);

		// When all objects on the page are deselected, automatically remove the object toolbar
		EventBus.$on(
			'deselect',
			this.hideToolbars,
		);
	}

	beforeDestroy() {
		EventBus.$off(
			'selectObject',
			this.objectSelected,
		);
		EventBus.$off(
			'deselect',
			this.hideToolbars,
		);
	}

	editText() {
		this.showObjectToolbar = false;
		this.showEditTextToolbar = true;
	}

	hideToolbars() {
		// Collapse toolbar
		this.collapsed = true;

		this.showEditTextToolbar = false;
		this.showObjectToolbar = false;
	}

	objectSelected(
		pageModel: PI.PageModel,
		objectModelId: string,
		options: {
			editText: boolean;
			showToolbar: boolean;
		},
	) {
		// Make toolbar visible
		this.collapsed = false;

		const defaults = {
			showToolbar: true,
			editText: false,
		};

		// Merge default properties with setup parameters
		options = options ? merge(
			defaults,
			options,
		) : defaults;

		if (options.showToolbar) {
			// Hide other bottom toolbars in the editor
			this.hideToolbars();

			if (options.editText) {
				this.showEditTextToolbar = true;
			} else {
				this.showObjectToolbar = true;
			}
		}
	}
}
