import './defines';
import zipCodeValidation from 'components/vee-validation/zipcode';
import * as DB from 'interfaces/database';
import {
	AppDataModule,
	ConfigModule,
	ProductStateModule,
} from 'store';
import filterText from 'tools/filter-text';
import _ from 'underscore';
import {
	extend,
	localize,
	ValidationObserver,
	ValidationProvider,
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import nl from 'vee-validate/dist/locale/nl.json';
import {
	email,
	max,
	required,
} from 'vee-validate/dist/rules';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

extend(
	'zipcode',
	zipCodeValidation,
);
extend(
	'required',
	required,
);
extend(
	'email',
	email,
);
extend(
	'max',
	max,
);
localize({
	en,
	es,
	fr,
	nl,
});

interface countryData {
	iso: string;
	name: string;
}

@Component({
	components: {
		ValidationObserver,
		ValidationProvider, // activate vee-validate plugin for this component
	},
})
export default class EditAddressView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly productModel!: DB.ProductModel;

	private get addressModel() {
		return ProductStateModule.getAddress;
	}

	protected get addressCity() {
		return this.addressModel
			? this.addressModel.city
			: '';
	}

	protected set addressCity(value) {
		ProductStateModule.changeAddress({
			city: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressCountry() {
		return this.addressModel
			? this.addressModel.country
			: '';
	}

	protected set addressCountry(value) {
		ProductStateModule.changeAddress({
			country: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressName() {
		return this.addressModel
			? this.addressModel.name
			: '';
	}

	protected set addressName(value) {
		ProductStateModule.changeAddress({
			name: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressState() {
		return this.addressModel
			? this.addressModel.state
			: '';
	}

	protected set addressState(value) {
		ProductStateModule.changeAddress({
			state: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressStreet1() {
		return this.addressModel
			? this.addressModel.address1
			: '';
	}

	protected set addressStreet1(value) {
		ProductStateModule.changeAddress({
			address1: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressStreet2() {
		return this.addressModel
			? this.addressModel.address2
			: '';
	}

	protected set addressStreet2(value) {
		ProductStateModule.changeAddress({
			address2: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressZipCode() {
		return this.addressModel
			? this.addressModel.zipcode
			: '';
	}

	protected set addressZipCode(value) {
		ProductStateModule.changeAddress({
			zipcode: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get countryStateprovs() {
		if (this.addressModel && this.addressModel.country) {
			const countryModel = AppDataModule.findCountryWhere({
				iso: this.addressModel.country,
			});
			if (countryModel) {
				return AppDataModule.findStateProv({ countryid: countryModel.id });
			}
		}

		return [];
	}

	protected get countries() {
		const groupid = this.productModel.group;
		const { typeid } = this.productModel;
		const countries: countryData[] = [];

		// Get all countries that have access to this product, and can thus be a destination to send the card to
		const regionOfferingModels = AppDataModule.findRegionOfferingLink({
			groupid,
			typeid,
		});
		regionOfferingModels.forEach((regionOfferingModel) => {
			AppDataModule.countries.forEach((countryModel) => {
				if (countryModel.regionid == regionOfferingModel.regionid) {
					countries.push({
						iso: countryModel.iso.trim(),
						name: AppDataModule.getCountryName(countryModel.id),
					});
				}
			});
		});

		return _.sortBy(
			countries,
			'name',
		);
	}

	protected mounted() {
		localize(window.locale);

		this.autoFocus();
	}

	private autoFocus() {
		if (this.$refs.nameInput) {
			const inputField = this.$refs.nameInput as HTMLInputElement;

			// Set focus to text field
			inputField.focus();

			if (this.addressModel && this.addressModel.name && this.addressModel.name.length) {
				// Set cursor at the end of the text
				inputField.setSelectionRange(
					this.addressModel.name.length,
					this.addressModel.name.length,
				);
			}
		}
	}

	protected save() {
		// @ts-ignore
		this.$refs.observer.validate().then((isValid) => {
			if (isValid) {
				this.$emit('closeDialog');
			} else {
				// do stuff if not valid.
			}
		});
	}
}
