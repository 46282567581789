import './defines';
import { PhotoModel } from 'interfaces/project';
import { ProductStateModule } from 'store';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class UploadErrorsView extends Vue.extend(Template) {
	protected get items(): PhotoModel[] {
		return ProductStateModule.getPhotosFailed;
	}
}
