import Dialog from 'classes/dialog';
import { DialogModel } from 'interfaces/app';
import { DialogModule } from 'store/index';
import AuthDialogView from 'views/dialog-auth.vue';
import DefaultDialogView from 'views/dialog-default.vue';
import LoaderDialogView from 'views/dialog-loader.vue';
import { Vue, Component } from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		AuthDialogView,
		DefaultDialogView,
		LoaderDialogView,
	},
})
export default class DialogsView extends Vue.extend(Template) {
	get auth() {
		return DialogModule.auth;
	}

	get error() {
		return DialogModule.error;
	}

	get loader() {
		return DialogModule.loader;
	}

	get main() {
		return DialogModule.main;
	}

	closeDialog(dialogModel: DialogModel) {
		Dialog.closeDialog(dialogModel);
	}
}
