import { callSafe } from '../utils/functions';

export type DevicePixelRatioWatchCallback = (devicePixelRatio: number) => void;

// eslint-disable-next-line import/no-mutable-exports
let { devicePixelRatio } = window;
let devicePixelRatioMediaQueryList = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
const watchers: DevicePixelRatioWatchCallback[] = [];

function onDevicePixelRatioMediaQueryListChange(): void {
	const currentDevicePixelRatio = window.devicePixelRatio;
	devicePixelRatio = window.devicePixelRatio;
	devicePixelRatioMediaQueryList.removeEventListener(
		'change',
		onDevicePixelRatioMediaQueryListChange,
	);
	devicePixelRatioMediaQueryList = window.matchMedia(`(resolution: ${devicePixelRatio}dppx)`);
	devicePixelRatioMediaQueryList.addEventListener(
		'change',
		onDevicePixelRatioMediaQueryListChange,
	);

	// eslint-disable-next-line no-restricted-syntax
	for (const watcher of watchers) {
		callSafe(() => watcher(currentDevicePixelRatio));
	}
}

function watch(callback: DevicePixelRatioWatchCallback): () => void {
	watchers.push(callback);

	return () => {
		const index = watchers.indexOf(callback);

		if (index > -1) {
			watchers.splice(
				index,
				1,
			);
		}
	};
}

devicePixelRatioMediaQueryList.addEventListener(
	'change',
	onDevicePixelRatioMediaQueryListChange,
);

export {
	devicePixelRatio,
	watch,
};
