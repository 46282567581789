import { ProductStateModule } from 'store';
import {
	Vue,
	Component,
	Prop,
	Ref,
} from 'vue-property-decorator';
import { SelectMenuItem, TopToolbarButton } from 'interfaces/app';
import SelectMenuView from '../select-menu';

@Component({
	components: {
		SelectMenuView,
	},
})
export default class TopToolbarView extends Vue {
	@Ref('moreButton') readonly moreButton!: HTMLDivElement;

	private collapsed = true;

	@Prop({ default: false, type: Boolean })
	readonly hasActionButton!: boolean;

	@Prop({ default: true, type: Boolean })
	readonly hasBackButton!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly hasProceedButton!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly hasUndoRedoButton!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly inverseColors!: boolean;

	@Prop({ default: () => [], type: Array })
	readonly menuItems!: SelectMenuItem[];

	@Prop({ default: false, type: Boolean })
	readonly fixedPosition!: boolean;

	// @ts-ignore Variable used in template
	private menuPosition: ClientRect | DOMRect = {
		width: 0,
		height: 0,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	};

	buttonClick(button: TopToolbarButton) {
		this.$emit(
			'buttonClick',
			button,
		);
	}

	clickMoreButton() {
		this.menuPosition = this.moreButton.getBoundingClientRect();

		this.toggleCollapse();
	}

	get hasRedo() {
		return ProductStateModule.hasFuture;
	}

	get hasUndo() {
		return ProductStateModule.hasHistory;
	}

	toggleCollapse() {
		this.collapsed = !this.collapsed;
	}
}
