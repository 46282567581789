import './defines';
import {
	EditorPaginationOfferingModel,
	EditorPaginationPageType,
} from 'interfaces/app';
import { ProductStateModule } from 'store';
import { project as projectTools } from 'tools';
import { Public } from 'utils/decorators';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorPaginationView',
})
export default class EditorPaginationView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the current selected page id',
			required: true,
			type: String,
		},
	)
	public readonly value!: string;

	@Prop({
		default: false,
		description: 'Indicates if the pagination is disabled',
		type: Boolean,
	})
	public readonly disabled!: boolean;

	@Prop({
		description: 'Defines the current offering model, used to get the page info labels',
		required: true,
		schema: 'EditorPaginationOfferingModel',
		type: Object,
	})
	public readonly offeringModel!: EditorPaginationOfferingModel;

	@Prop({
		description: 'Defines the map of page ids with its corresponding page index available for the pagination to switch between',
		required: true,
		type: Map,
	})
	public readonly pageList!: Map<string, number>;

	@Prop({
		description: 'Defines the total number of pages of the pagination',
		required: true,
		type: Number,
	})
	public readonly totalPages!: number;

	protected get isFirstPage(): boolean {
		return this.internalValue === this.pageListIds[0];
	}

	protected get isLastPage(): boolean {
		return this.internalValue === this.pageListIds[this.pageListIds.length - 1];
	}

	private get isPageCoverBack(): boolean {
		return projectTools.isPageCoverBack(
			this.offeringModel,
			this.internalValue,
			this.pageListIds.indexOf(this.internalValue),
		);
	}

	private get isPageCoverFront(): boolean {
		return projectTools.isPageCoverFront(
			this.offeringModel,
			this.internalValue,
			this.pageListIds.indexOf(this.internalValue),
		);
	}

	private get isPageCoverInside(): boolean {
		return projectTools.isPageCoverInside(
			this.offeringModel,
			this.internalValue,
		);
	}

	protected get pageInfoClasses(): (pageType: EditorPaginationPageType) => Record<string, boolean> {
		return (pageType: EditorPaginationPageType) => {
			const pageInfoClasses: Record<string, boolean> = {};

			if (pageType === 'cover_front') {
				pageInfoClasses['pagination-view-page-info-hidden'] = !this.isPageCoverFront;
			} else if (pageType === 'coverInside') {
				pageInfoClasses['pagination-view-page-info-hidden'] = !this.isPageCoverInside;
			} else if (pageType === 'cover_back') {
				pageInfoClasses['pagination-view-page-info-hidden'] = !this.isPageCoverBack;
			} else if (pageType === 'pageNumber') {
				pageInfoClasses['pagination-view-page-info-hidden'] = (
					this.isPageCoverFront
					|| this.isPageCoverInside
					|| this.isPageCoverBack
				);
			}

			return pageInfoClasses;
		};
	}

	private get pageListIds(): string[] {
		const pageIds: string[] = [];
		this.pageList.forEach((pageNumber, pageId) => {
			pageIds[pageNumber] = pageId;
		});

		return pageIds.filter((pageId) => pageId !== undefined);
	}

	protected get selectedPageNumber(): number {
		const pageNumberFound = this.pageList.get(this.internalValue);
		const pageLabel = ProductStateModule.getPageLabel(
			{
				id: this.internalValue,
			},
			pageNumberFound,
			this.offeringModel,
			false,
		);

		if (typeof pageLabel === 'number') {
			return pageLabel;
		}
		if (!Number.isNaN(Number(pageLabel))) {
			return Number(pageLabel);
		}
		if (pageNumberFound === undefined) {
			return 1;
		}

		return pageNumberFound + 1;
	}

	private internalValue = '';

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.value !== this.internalValue) {
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = this.value;
		});
	}

	@Public()
	public getNextPage(): string | null {
		if (this.isLastPage) {
			return null;
		}

		return this.pageListIds[this.pageListIds.indexOf(this.internalValue) + 1];
	}

	@Public()
	public getPreviousPage(): string | null {
		if (this.isFirstPage) {
			return null;
		}

		return this.pageListIds[this.pageListIds.indexOf(this.internalValue) - 1];
	}

	protected onNextPageClick(): void {
		const nextPageIndex = this.pageListIds.indexOf(this.internalValue) + 1;

		if (nextPageIndex < this.pageList.size) {
			this.internalValue = this.pageListIds[nextPageIndex];
		}
	}

	protected onPreviousPageClick(): void {
		const previousPageIndex = this.pageListIds.indexOf(this.internalValue) - 1;

		if (previousPageIndex >= 0) {
			this.internalValue = this.pageListIds[previousPageIndex];
		}
	}
}
