import './defines';
import PriceCalculator from 'classes/price-calculator';
import { compareVersions } from 'compare-versions';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import navigate from 'controllers/navigate';
import Support from 'controllers/support';
import {
	HeaderAccountNavigate,
} from 'interfaces/app';
import {
	ProductCategoryModel,
	ShoppingCartItemModel,
} from 'interfaces/database';
import clickLogo from 'ops/click-logo';
import logoutUser from 'ops/logout-user';
import openWindow from 'ops/open-window';
import showSettings from 'ops/show-settings';
import store, {
	AppDataModule,
	AppStateModule,
	CartItemsModule,
	ChannelsModule,
	ConfigModule,
	UserModule,
} from 'store';
import ClickOutside from 'vue-click-outside';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import AccountHeaderView from '../header-account';
import CartWidgetView from '../header-cart';
import HeaderMenuView from '../header-menu';
import Template from './template.vue';

@Component({
	components: {
		AccountHeaderView,
		CartWidgetView,
		HeaderMenuView,
	},
	directives: {
		ClickOutside,
	},
})
export default class AppHeaderView extends Vue.extend(Template) {
	@Prop({
		description: 'Indicates if the header should be in a collapsed state',
		event: 'collapse',
		required: true,
		type: Boolean,
	})
	public readonly collapsed!: boolean;

	@Prop({
		description: "Indicates if the header collapse should be changed only by the user's action",
		default: false,
		type: Boolean,
	})
	public readonly lazyCollapse!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the component should show the Cart component',
		type: Boolean,
	})
	public readonly showCart!: boolean;

	protected get cartHasEdit() {
		return window.glPlatform === 'native';
	}

	protected get cartItems() {
		const items: Record<string, any>[] = [];
		CartItemsModule.collection.forEach((cartItem) => {
			const offeringModel = AppDataModule.getOffering(cartItem.offeringid);

			if (offeringModel) {
				const priceData = PriceCalculator.productPrice(
					offeringModel,
					cartItem.printpages,
					{
						cartItemModel: cartItem,
					},
				);
				const subTotal = (priceData.subTotal - priceData.bulkDiscountValue) * cartItem.quantity;
				items.push({
					id: cartItem.id,
					name: offeringModel
						? AppDataModule.getOfferingName(
							offeringModel.id,
							true,
						)
						: '',
					price: subTotal,
					quantity: cartItem.quantity,
				});
			}
		});
		AppStateModule.externalCartItems.forEach((externalItem) => {
			items.push(externalItem);
		});

		return items;
	}

	protected get countries() {
		return AppDataModule.countries;
	}

	protected get currencies() {
		return AppDataModule.currencies;
	}

	protected get hasAccountModule() {
		return ConfigModule.hasAccountModule;
	}

	protected get hasCartModule() {
		return ConfigModule.hasCartModule;
	}

	protected get hasInboxModule(): boolean {
		if (window.glPlatform !== 'native') {
			return false;
		}

		if (window.nativeDeviceDetails.platform === 'Android'
			&& compareVersions(
				window.nativeDeviceDetails.appVersion,
				'1.3.0-staging',
			) < 0
		) {
			return false;
		}

		if (window.nativeDeviceDetails.platform === 'iOS'
			&& compareVersions(
				window.nativeDeviceDetails.appVersion,
				'1.1.0',
			) < 0
		) {
			return false;
		}

		return true;
	}

	protected get hasSettings() {
		return this.countries.length > 1
			|| this.languages.length > 1
			|| this.currencies.length > 1;
	}

	protected get languages() {
		return AppDataModule.languages;
	}

	protected get menuAboutAppLink() {
		return AppDataModule.findHyperlink({ tag: 'aboutApp' })?.url;
	}

	protected get menuHasAppReview() {
		return window.glPlatform === 'native' && store.state.config['header.menu.appReview'];
	}

	protected get menuHasLiveChat() {
		return Support.active.zopim;
	}

	protected get menuHasProductCategories() {
		return store.state.config['header.menu.categories'];
	}

	protected get menuPricingLink() {
		return AppDataModule.findHyperlink({ tag: 'pricing' })?.url;
	}

	protected get menuPromotionsLink() {
		return AppDataModule.findHyperlink({ tag: 'promotions' })?.url;
	}

	protected get menuShippingLink() {
		return AppDataModule.findHyperlink({ tag: 'shipping' })?.url;
	}

	protected get menuSupportLink() {
		return AppDataModule.findHyperlink({ tag: 'support' })?.url;
	}

	protected get menuProductCategories() {
		const items: Pick<ProductCategoryModel, 'id' | 'name'>[] = [];

		AppDataModule.productcategories.forEach((categoryModel) => {
			if (!categoryModel.parentid) {
				if (categoryModel.pdpid) {
					const areAvailablePDPOfferings = AppDataModule.getAvailableOfferings.find(
						(offeringModel) => offeringModel.pdpid == categoryModel.pdpid,
					);

					if (areAvailablePDPOfferings) {
						// The category has a PDP linked to it, so we can show the category in the list
						items.push({
							id: categoryModel.id,
							name: AppDataModule.getProductCategoryName(categoryModel.id),
						});
					}
				} else if (AppDataModule.findListerItems({ parentid: categoryModel.id }).length) {
					// The category has children, we can show the category in the list
					items.push({
						id: categoryModel.id,
						name: AppDataModule.getProductCategoryName(categoryModel.id),
					});
				}
			}
		});

		return items;
	}

	protected get showAccountHeader() {
		return ConfigModule['header.account'];
	}

	protected get showMenu() {
		return ConfigModule['header.menu.show'] && ConfigModule.hasStoreModule;
	}

	protected get unreadMessageCount() {
		return AppStateModule.unreadMessageCount;
	}

	protected get userIsLogged() {
		return !!ChannelsModule.findWhere({ login: 1 });
	}

	protected get userModel() {
		return UserModule.getState;
	}

	private internalCollapsed = false;

	private timeout?: NodeJS.Timeout;

	protected mounted() {
		if (!this.lazyCollapse) {
			this.timeout = setTimeout(
				() => {
					this.internalCollapsed = true;
					this.$emit(
						'collapse',
						this.internalCollapsed,
					);
				},
				3000,
			);
		}
	}

	@Watch(
		'collapsed',
		{
			immediate: true,
		},
	)
	protected onCollapsedChange() {
		this.internalCollapsed = this.collapsed;
	}

	protected clickOutside() {
		if (!this.internalCollapsed) {
			this.internalCollapsed = true;
			this.$emit(
				'collapse',
				this.internalCollapsed,
			);
		}
	}

	protected onNavigate(moduleToNavigate: HeaderAccountNavigate) {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		analytics.trackEvent(
			'Select account menu item',
			{
				item: moduleToNavigate,
			},
		);

		if (moduleToNavigate === 'account') {
			navigate.toUserAccount();
		} else if (moduleToNavigate === 'account-photos') {
			navigate.toUserAccountPhotos();
		} else if (moduleToNavigate === 'create') {
			navigate.toCreate({});
		} else if (moduleToNavigate === 'login') {
			auth.showLogin({
				hasclose: true,
			});
		} else if (moduleToNavigate === 'logout') {
			logoutUser();
		} else if (moduleToNavigate === 'shelf') {
			navigate.toStart();
		} else if (moduleToNavigate === 'signup') {
			auth.showSignup({
				hasclose: true,
			});
		} else if (moduleToNavigate === 'inbox') {
			if (window.glPlatform !== 'native') {
				throw new Error('Inbox is not supported on web');
			}
			if (!window.webToNative) {
				throw new Error('Missing WebToNative on window');
			}

			window.webToNative.openNotificationCenter();
		}
	}

	protected onShowSettings() {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		showSettings();
	}

	protected onOpenAboutApp() {
		if (this.menuAboutAppLink) {
			// TODO: check this, as this should be in the parent, in here an event should be emitted
			openWindow(
				this.menuAboutAppLink,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		}
	}

	protected onOpenCategory(category: ProductCategoryModel) {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		navigate.toCreate({ listeritemid: category.id });
	}

	protected onOpenChat() {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		analytics.trackEvent(
			'Open chat',
			{
				category: 'Menu',
			},
		);
		Support.open();
	}

	protected onOpenCreate() {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		navigate.toCreate({});
	}

	protected onOpenPricing() {
		if (this.menuPricingLink) {
			// TODO: check this, as this should be in the parent, in here an event should be emitted
			openWindow(
				this.menuPricingLink,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		}
	}

	protected onOpenPromotions() {
		if (this.menuPromotionsLink) {
			// TODO: check this, as this should be in the parent, in here an event should be emitted
			openWindow(
				this.menuPromotionsLink,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		}
	}

	protected onOpenReviewApp() {
		if (!window.webToNative) {
			throw new Error('Missing WebToNative on window');
		}

		window.webToNative.requestAppReview();
	}

	protected onOpenShipping() {
		if (this.menuShippingLink) {
			openWindow(
				this.menuShippingLink,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		}
	}

	protected onOpenSupport() {
		if (this.menuSupportLink) {
			openWindow(
				this.menuSupportLink,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		}
	}

	protected onOpenWebsite() {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		clickLogo();
	}

	protected onRemoveCartItem(item: ShoppingCartItemModel) {
		CartItemsModule.destroyModel({
			id: item.id,
		});
		this.$emit(
			'cart-item-removed',
			item,
		);
	}

	protected onViewCart() {
		// TODO: check this, as this should be in the parent, in here an event should be emitted
		navigate.openCart();
	}

	protected removeTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	protected toggleVisibility() {
		this.internalCollapsed = !this.internalCollapsed;
		this.$emit(
			'collapse',
			this.internalCollapsed,
		);
	}
}
