import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import ColorPickerComponent from 'components/color-picker';
import TabSwitchComponent from 'components/tab-switch';
import {
	EditorBorderSelectorBorder,
	EditorBorderSelectorBorders,
	EditorBorderSelectorModel,
	TabSwitchTabs,
} from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import {
	Component,
	Prop,
	Model,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorBorderSelectorView',
	components: {
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		ColorPickerComponent,
		TabSwitchComponent,
	},
})
export default class EditorBorderSelectorView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the page object model of the editor border selector in which the values are stored',
			required: true,
			schema: 'EditorBorderSelectorModel',
			type: Object,
		},
	)
	public readonly value!: EditorBorderSelectorModel;

	@Prop({
		default: (): EditorBorderSelectorBorders => [
			{
				value: 0,
			},
			{
				cssBorder: 1,
				value: 1,
			},
			{
				cssBorder: 2,
				value: 2,
			},
			{
				cssBorder: 6,
				value: 6,
			},
			{
				cssBorder: 8,
				value: 8,
			},
			{
				cssBorder: 12,
				value: 12,
			},
			{
				cssBorder: 20,
				value: 24,
			},
			{
				cssBorder: 28,
				value: 48,
			},
			{
				cssBorder: 32,
				value: 64,
			},
			{
				cssBorder: 40,
				value: 96,
			},
		],
		schema: 'EditorBorderSelectorBorders',
		type: Array,
	})
	public readonly borders!: EditorBorderSelectorBorders;

	@Prop({
		description: 'Defines the canvas element that is going to be passed to the ColorPicker (for the border color)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	protected get borderButtonClasses(): (border: EditorBorderSelectorBorder) => Record<string, boolean> {
		return (border) => ({
			'editor-border-selector-view-width-button-big': !!(
				border.cssBorder
				&& border.cssBorder > 0
			),
		});
	}

	protected get borderStyles(): (border: EditorBorderSelectorBorder) => Partial<CSSStyleDeclaration> {
		return (border) => ({
			height: `${border.cssBorder}px`,
		});
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get hasBorder(): boolean {
		return (
			typeof this.internalValue.borderwidth === 'number'
			&& this.internalValue.borderwidth > 0
		);
	}

	protected get shouldHideTabs(): boolean {
		if (!this.isMobile) {
			return true;
		}

		return !this.hasBorder;
	}

	protected get shouldShowColorPicker(): boolean {
		if (!this.isMobile) {
			return this.hasBorder;
		}

		return this.activeTab === 'color';
	}

	protected get shouldShowWidthSelector(): boolean {
		if (!this.isMobile) {
			return true;
		}

		return this.activeTab === 'width';
	}

	protected get tabSwitchComponentClasses(): Record<string, boolean> {
		return {
			'editor-border-selector-view-container': !this.isMobile,
			'editor-border-selector-view-tabs-container': this.isMobile,
		};
	}

	protected activeTab = 'width';

	protected readonly borderTypeTabs: TabSwitchTabs = [
		{
			id: 'width',
			text: this.$t('views.editorBorderSelector.width'),
		},
		{
			id: 'color',
			text: this.$t('views.editorBorderSelector.color'),
		},
	];

	private internalValue: EditorBorderSelectorModel = {} as EditorBorderSelectorModel;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = {
			...this.value,
		};
	}

	protected onBorderColorChange(value: string): void {
		this.internalValue.bordercolor = value;
		this.internalValue.borderimage = 0;
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onBorderWidthChange(value: number): void {
		this.internalValue.borderwidth = value;
		this.$emit(
			'change',
			this.internalValue,
		);
	}
}
