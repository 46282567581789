import translationNativeEN from 'i18n/en/native.json';
import translationNativeNL from 'i18n/nl/native.json';
import translationNativeFR from 'i18n/fr/native.json';

const setNativeLanguage = (locale: string) => {
	if (!window.webToNative) {
		throw new Error('Missing WebToNative on window');
	}

	if (locale == 'en' || locale == 'en-gb') {
		window.webToNative.setTranslation({
			locale: 'en',
			labels: translationNativeEN,
		});
	} else if (locale == 'nl') {
		window.webToNative.setTranslation({
			locale: 'nl',
			labels: translationNativeNL,
		});
	} else if (locale == 'fr') {
		window.webToNative.setTranslation({
			locale: 'fr',
			labels: translationNativeFR,
		});
	}
};

export default setNativeLanguage;
