import merge from 'deepmerge';
import loadjs from 'loadjs';
import * as DB from 'interfaces/database';
import {
	PricingObject,
	AnalyticsSubController,
	AnalyticsUserProperties,
	DeviceDetails,
	AnalyticsProjectReadyProperties,
	AnalyticsCreateProjectProperties,
	AnalyticsOpenProjectProperties,
} from 'interfaces/app';
import {
	AppDataModule,
	ConfigModule,
	ExternalUsersModule,
	UserModule,
} from 'store/index';
import logBreadcrumb from 'services/log-breadcrumb';

interface GTM_Ecommerce_Item {
	item_id: string;
	item_name?: string;
	affiliation?: string;
	coupon?: string;
	currency?: string;
	discount?: number;
	index?: number;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name ?: string;
	item_variant?: string;
	location_id?: string;
	price?: number;
	quantity?: number;
}

export default class GTMController implements AnalyticsSubController {
	private sendEcommerce = false;

	private sendEvents = false;

	private userProperties: AnalyticsUserProperties = {};

	public init(
		deviceDetails: DeviceDetails,
	) {
		if (deviceDetails.platform === 'Native' && deviceDetails.os === 'iOS') {
			// We can't use (3rd party) cookies in the iOS app or it will be rejected in the store
			return false;
		}

		if (ConfigModule['analytics.gtm.enabled']) {
			this.sendEcommerce = ConfigModule['analytics.gtm.ecommerce'];
			this.sendEvents = ConfigModule['analytics.gtm.events'];

			window.dataLayer = window.dataLayer || [];

			// Set any persistent dataLayer properties before calling 'gtm.start'
			// See: https://developers.google.com/tag-platform/tag-manager/web/datalayer#persist_data_layer_variables
			const persistentDataLayerProperties: Record<string, any> = {};

			if (ConfigModule.partnerID === 2) {
				persistentDataLayerProperties.pageType = 'editor';
				persistentDataLayerProperties.editorType = 'sosocio';
				persistentDataLayerProperties.shop = 'photo';
				persistentDataLayerProperties.environment = window.glStack === 'live'
					? 'production'
					: 'acc';
				persistentDataLayerProperties.in_app = window.glPlatform === 'native';

				// Note: although 'country' will also be set in the 'identifyUser' function,
				// the HEMA requires this property to be set on initalization of the GTM container
				if (UserModule.countryid) {
					const countryModel = AppDataModule.getCountry(UserModule.countryid);
					if (countryModel) {
						const userLanguage = UserModule.language || window.locale;
						persistentDataLayerProperties.country = `${userLanguage.toLocaleLowerCase()}-${countryModel.iso}`;
					}
				}
			}

			if (Object.keys(persistentDataLayerProperties).length) {
				window.dataLayer.push(
					persistentDataLayerProperties,
				);
			}

			// Now that any persistent properties have been set, call the GTM start event
			window.dataLayer.push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js',
			});

			// Use the default gtm id
			const id = ConfigModule['analytics.gtm.id'];
			if (!id) {
				throw new Error('Could not find GTM id');
			}

			loadjs(
				`https://www.googletagmanager.com/gtm.js?id=${id}`,
				() => {
					logBreadcrumb(
						`GTM with id ${id} loaded`,
						persistentDataLayerProperties,
						{
							console: ConfigModule['analytics.gtm.debug'],
						},
					);
				},
			);

			return true;
		}

		return false;
	}

	public setExperimentFlags(
		flags: Record<string, string | number | boolean | null>,
	) {
		if (!window.dataLayer) {
			throw new Error('GTM dataLayer not setup');
		}

		window.dataLayer.push(
			flags,
		);

		// Create breadcrumb for debugging
		logBreadcrumb(
			'GTM Analytics: Set Experiment Flag',
			flags,
			{
				console: ConfigModule['analytics.gtm.debug'],
			},
		);
	}

	public setUserProperties(
		objProperties: AnalyticsUserProperties,
	) {
		this.userProperties = merge(
			this.userProperties,
			objProperties,
		);

		this.identifyUser();
	}

	public logout() {
		// No such thing in GTM
	}

	private track(
		event: string,
		properties: Record<string, any>,
	) {
		if (!window.dataLayer) {
			throw new Error('GTM dataLayer not setup');
		}

		if (properties.ecommerce) {
			// It's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event
			// to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other.
			window.dataLayer.push({ ecommerce: null });
		}

		window.dataLayer.push({
			event,
			...properties,
		});

		// Create breadcrumb for debugging
		logBreadcrumb(
			'GTM Analytics: Track',
			{
				event,
				...properties,
			},
			{
				console: ConfigModule['analytics.gtm.debug'],
			},
		);
	}

	public aliasUser() {
		// No such thing in GTM
	}

	public registerUser() {
		this.identifyUser();
	}

	public identifyUser() {
		if (!window.dataLayer) {
			throw new Error('GTM dataLayer not setup');
		}

		const trackProperties: Record<string, any> = {};

		if (this.userProperties.ISO && this.userProperties.Language) {
			trackProperties.country = `${this.userProperties.Language.toLocaleLowerCase()}-${this.userProperties.ISO}`;
		}

		if (ConfigModule['analytics.gtm.externalUserId']) {
			const externalUser = ExternalUsersModule.findWhere({
				source: 'app',
			});
			if (externalUser
				&& externalUser.externalId
			) {
				trackProperties.userId = externalUser.externalId;
			}
		} else if (UserModule.id) {
			trackProperties.userId = UserModule.id;
		}

		if (ConfigModule['analytics.gtm.email'] && UserModule.email) {
			trackProperties.userEmail = UserModule.email;
		}

		window.dataLayer.push(
			trackProperties,
		);

		// Create breadcrumb for debugging
		logBreadcrumb(
			'GTM Analytics: Identify User',
			trackProperties,
			{
				console: ConfigModule['analytics.gtm.debug'],
			},
		);
	}

	public trackPageView(
		route: string,
		title: string,
		objProperties: Record<string, any>,
	) {
		const trackProperties = {
			...objProperties,
			pagePath: route,
			pageTitle: title,
		};

		this.track(
			'Pageview',
			trackProperties,
		);
	}

	public trackEvent(
		action: string,
		objProperties: Record<string, any>,
	) {
		if (this.sendEvents) {
			this.track(
				action,
				objProperties,
			);
		}
	}

	public trackProductReady(
		productModel: DB.ProductModel | null,
		objPrice: PricingObject | null,
		objProperties: AnalyticsProjectReadyProperties,
	) {
		if (this.sendEvents) {
			const properties = {
				page_count: objProperties.pageCount,
				photo_count: objProperties.photoCount,
				groupid: objProperties.groupid,
				typeid: objProperties.typeid,
				variantid: objProperties.variantid,
				product_origin: objProperties.product_origin,
				type: objProperties.type,
				price: objPrice ? Math.round(objPrice.subTotal) / 100 : undefined,
				theme_id: productModel ? productModel.themeid : undefined,
				layout_id: productModel ? productModel.layoutid : undefined,
			};

			this.track(
				'Product ready',
				properties,
			);
		}
	}

	public trackAddToCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
	) {
		if (this.sendEcommerce) {
			const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
			if (offeringModel) {
				const item: GTM_Ecommerce_Item = {
					item_id: offeringModel.id.toString(),
					item_name: AppDataModule.getOfferingName(offeringModel.id),
					item_variant: offeringModel.variantid.toString(),
					item_category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					price: Math.round(objPrice.subTotal) / 100,
					quantity: cartItemModel.quantity,
				};

				const currencyModel = UserModule.currency
					? AppDataModule.getCurrency(UserModule.currency)
					: undefined;
				if (currencyModel) {
					item.currency = currencyModel.id;
				}

				this.track(
					'add_to_cart',
					{
						ecommerce: {
							items: [item],
						},
					},
				);
			}
		}
	}

	public trackRemoveFromCart(
		cartItemModel: DB.ShoppingCartItemModel,
	) {
		if (this.sendEcommerce) {
			const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
			if (offeringModel) {
				const item: GTM_Ecommerce_Item = {
					item_id: offeringModel.id.toString(),
					item_name: AppDataModule.getOfferingName(offeringModel.id),
					item_variant: offeringModel.variantid.toString(),
					item_category: AppDataModule.getProductCategoryName(offeringModel.groupid),
				};

				const currencyModel = UserModule.currency
					? AppDataModule.getCurrency(UserModule.currency)
					: undefined;
				if (currencyModel) {
					item.currency = currencyModel.id;
				}

				this.track(
					'remove_from_cart',
					{
						ecommerce: {
							items: [item],
						},
					},
				);
			}
		}
	}

	public trackPerformance(
		action: string,
		value: number,
		objProperties: Record<string, any>,
	) {
		if (this.sendEvents) {
			this.track(
				`Performance: ${action}`,
				{
					...objProperties,
					performanceValue: value,
				},
			);
		}
	}

	public trackCreateProject(
		productModel: DB.ProductModel,
		objProperties: AnalyticsCreateProjectProperties,
	) {
		if (this.sendEvents) {
			this.track(
				'Create Product',
				objProperties,
			);
		}
	}

	public trackOpenProduct(
		productModel: DB.ProductModel,
		objProperties: AnalyticsOpenProjectProperties,
	) {
		if (this.sendEvents) {
			this.track(
				'Open Product',
				objProperties,
			);
		}
	}

	public trackTransaction(
		orderModel: DB.OrderModel,
		orderItems: DB.OrderItemModel[],
	) {
		if (this.sendEcommerce) {
			const arrProducts: GTM_Ecommerce_Item[] = [];
			orderItems.forEach((orderItemModel, i) => {
				const offeringModel = AppDataModule.getOffering(orderItemModel.offeringid);
				if (offeringModel) {
					arrProducts.push({
						item_id: offeringModel.id.toString(),
						item_name: AppDataModule.getOfferingName(offeringModel.id),
						currency: orderModel.currency,
						discount: Math.round(orderItemModel.discount_bulk + orderItemModel.discount_voucher) / 100,
						index: i,
						item_variant: offeringModel.variantid.toString(),
						price: Math.round(orderItemModel.salesvalue) / 100,
						quantity: orderItemModel.quantity,
						item_category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					});
				}
			});

			this.track(
				'purchase',
				{
					ecommerce: {
						transaction_id: orderModel.id.toString(),
						value: Math.round(orderModel.salesvalue) / 100,
						tax: Math.round(orderModel.total_tax) / 100,
						shipping: Math.round(orderModel.price_shipping) / 100,
						currency: orderModel.currency,
						coupon: orderModel.voucherid ? `${orderModel.voucherid}` : null,
						items: arrProducts,
					},
				},
			);
		}
	}
}
