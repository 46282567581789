import _ from 'underscore';
import * as PI from 'interfaces/project';
import { OfferingGroups } from '../../settings/offerings';
import { ProductStateModule } from '../../store/index';

const removePhotoObjects = (photoId: number | string) => {
	const offeringModel = ProductStateModule.getOffering;

	let deleteObjects: PI.PageObjectModel[] = [];
	const deletePages: PI.PageModel[] = [];
	const affectedPages = [];
	_.each(
		ProductStateModule.getEditablePages,
		(pageModel) => {
			const objects = ProductStateModule.getPageObjects(pageModel);
			const relatedObjects = _.where(
				objects,
				{ photoid: photoId },
			);
			if (relatedObjects.length) {
			// For prints, we might also need to destroy the page
				if (
					offeringModel
				&& OfferingGroups(
					offeringModel.groupid,
					['PrintTypes'],
				)
				&& offeringModel.minprintpages < offeringModel.maxpages
				) {
					deletePages.push(pageModel);
				} else {
					affectedPages.push(pageModel);
				}

				deleteObjects = deleteObjects.concat(relatedObjects);
			}
		},
	);

	while (deleteObjects.length) {
		const objectModel = deleteObjects[0];
		ProductStateModule.removePageObject(objectModel.id);
		// Remove item from array so we can iterate over next item
		deleteObjects.shift();
	}

	while (deletePages.length) {
		const pageModel = deletePages[0];
		ProductStateModule.removePage({ pageId: pageModel.id });
		// Remove item from array so we can iterate over next item
		deletePages.shift();
	}
};

export default removePhotoObjects;
