export const ERRORS_LOAD_FONT = 'Failed loading webfont';
export const ERRORS_FILE_STORAGE_DISABLED = 'File storage not enabled';
export const ERRORS_DATA_STORAGE_DISABLED = 'Data storage not enabled';
export const ERRORS_UNSUPPORTED_FEATURE = 'Unsupported feature';
export const ERRORS_OAUTH_FAILED = 'Oauth request failed';
export const ERRORS_INVALID_REQUEST_DATA = 'Invalid request data';
export const ERRORS_INVALID_RETURN_DATA = 'Invalid return data';
export const ERRORS_OFFLINE = 'Not supported in offline modus';
export const ERRORS_NO_RESPONSE = 'No response';
export const ERRORS_UPLOAD_REJECTED_TOO_MANY = 'Upload cannot be added, too many photos selected';
