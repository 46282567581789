import {
	CartItemsModule,
	ProductsModule,
	ProductStateModule,
} from '../../store/index';
import ProductState from '../../classes/productstate';

export default function deleteProduct(productId: number): Promise<void> {
	// remove product from shopping cart
	const toRemove = CartItemsModule.where({ productid: productId });

	// Remove cartitem with this product
	const promises = toRemove.map(
		(cartItemModel) => CartItemsModule.destroyModel({ id: cartItemModel.id }),
	);
	return Promise.all(promises)
		.then(() => {
			// reset product under construction
			if (ProductStateModule.productId == productId) {
				return ProductState.reset();
			}

			return Promise.resolve();
		})
		.then(() => {
			const productModel = ProductsModule.getById(productId);
			if (productModel) {
				// Remove from local storage (if exists)
				return ProductState
					.removeFromStorage(productModel.read_token)
					// Swallow error: no action required
					.catch(() => Promise.resolve());
			}

			if (typeof window.glBugsnagClient !== 'undefined') {
				const e = new Error('Could not find product to remove');
				window.glBugsnagClient.notify(
					e,
					(event) => { event.severity = 'warning'; },
				);
			}

			return Promise.resolve();
		})
		.then(() => ProductsModule.destroyModel(productId));
}
