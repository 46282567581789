import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import {
	EditorToolbarPageObjectModel,
	EditorToolbarPageObjectsModel,
	EditorToolbarPhotos,
} from 'interfaces/app';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorObjectSelectorView',
	components: {
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
	},
})
export default class EditorObjectSelectorView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the selected object model',
			schema: 'EditorToolbarPageObjectModel',
			type: Object,
		},
	)
	public readonly value?: EditorToolbarPageObjectModel;

	@Prop({
		default: 2,
		description: 'Defines the number of columns in the editor photo selector',
		type: Number,
	})
	public readonly columns!: number;

	@Prop({
		description: 'Defines the available objects to select from',
		event: 'objects-change',
		required: true,
		schema: 'EditorToolbarPageObjectsModel',
		type: Array,
	})
	public readonly objects!: EditorToolbarPageObjectsModel;

	@Prop({
		default: undefined,
		description: 'Defines the available photo models (used to get the object photo url, if any)',
		schema: 'EditorToolbarPhotos',
		type: Array,
	})
	public readonly photos?: EditorToolbarPhotos;

	@Prop({
		default: 'auto',
		description: "Defines the size (width/height) of the object photo image, if set to auto it's going to use the available width divided by the number of columns",
		type: [Number, String],
	})
	public readonly objectPhotoSize!: string | number;

	protected get computedClasses(): Record<string, boolean> {
		const objectsArePhotos = this.internalObjects.every((object) => object.type === 'photo');

		return {
			'editor-object-selector-view-photos': objectsArePhotos,
		};
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, number | string> {
		return {
			'--columns': this.columns,
		};
	}

	protected get computedObjectPhotoStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			this.objectPhotoSize
			&& this.objectPhotoSize !== 'auto'
		) {
			if (typeof this.objectPhotoSize === 'number') {
				styles.maxHeight = `${this.objectPhotoSize}px`;
				styles.maxWidth = `${this.objectPhotoSize}px`;
			} else {
				styles.maxHeight = this.objectPhotoSize;
				styles.maxWidth = this.objectPhotoSize;
			}
		}

		return styles;
	}

	protected get getObjectPhotoUrl(): (object: EditorToolbarPageObjectModel) => string | undefined {
		return (object) => {
			const photoFound = this.photos?.find((photo) => photo.id === object.photoid);

			if (photoFound) {
				return `${photoFound.thumb_url}?noCorsHeader`;
			}

			return undefined;
		};
	}

	private internalObjects: EditorToolbarPageObjectsModel = [];

	private internalValue: EditorToolbarPageObjectModel | null = null;

	@Watch(
		'objects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onObjectsChange(): void {
		this.internalObjects = this.objects.map((object) => ({
			...object,
		}));
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.value?.id !== this.internalValue?.id) {
			if (this.value) {
				this.internalValue = {
					...this.value,
				};
			} else {
				this.internalValue = null;
			}
		}
	}

	protected onChange(objectId: EditorToolbarPageObjectModel['id']): void {
		const internalObjectFound = this.internalObjects.find((object) => object.id === objectId);

		if (
			internalObjectFound
			&& internalObjectFound.id !== this.value?.id
		) {
			const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
				[internalObjectFound.id]: [
					'_hovered',
					'_selected',
				],
			};
			internalObjectFound._hovered = false;
			internalObjectFound._selected = true;
			this.internalValue = internalObjectFound;

			this.$emit(
				'change',
				this.internalValue,
				objectsDifferences,
			);
		}
	}

	protected onMouseEnter(object: EditorToolbarPageObjectModel): void {
		const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
			[object.id]: ['_hovered'],
		};
		object._hovered = true;
		this.$emit(
			'objects-change',
			this.internalObjects,
			objectsDifferences,
		);
	}

	protected onMouseLeave(object: EditorToolbarPageObjectModel): void {
		const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
			[object.id]: ['_hovered'],
		};
		object._hovered = false;
		this.$emit(
			'objects-change',
			this.internalObjects,
			objectsDifferences,
		);
	}
}
