import './defines';
import analytics from 'controllers/analytics';
import connector, {
	CloudAlbumModel,
	ConnectorOptions,
} from 'controllers/connector';
import { ChannelModel } from 'interfaces/app';
import * as PI from 'interfaces/project';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import CloudAlbumView from '../cloud-album';
import CloudPhotoSelectorView from '../cloud-photo-selector';
import Template from './template.vue';

@Component({
	components: {
		CloudAlbumView,
		CloudPhotoSelectorView,
	},
})
export default class CloudAlbumsSelectorView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly channelModel!: ChannelModel;

	// Import mode is activated in the import module, which means a user can select multiple
	// photos, and we visually show if the photo is already selected
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly importMode!: boolean;

	private albumid: string | null = null;

	private albums: {
		nextPage: string | null;
		nextOptions: string | null;
		fetched: boolean;
		collection: CloudAlbumModel[];
	} = {
			nextPage: null,
			nextOptions: null,
			fetched: false,
			collection: [],
		};

	protected byCategory = false;

	protected byDate = false;

	private destroyed = false;

	private loading = false;

	protected mounted() {
		this.fetchAlbumCollection();
	}

	protected beforeDestroy() {
		this.destroyed = true;
	}

	protected browseByAlbum() {
		analytics.trackEvent(
			'Browse by album',
			{
				category: 'Button',
				label: 'Network',
				value: this.channelModel.id,
			},
		);

		this.byCategory = false;
		this.byDate = false;
	}

	protected browseByCategory() {
		analytics.trackEvent(
			'Browse by category',
			{
				category: 'Button',
				label: 'Network',
				value: this.channelModel.id,
			},
		);

		this.byCategory = true;
		this.byDate = false;
	}

	protected browseByDate() {
		if (this.albumid) {
			this.albumid = null;
		}

		analytics.trackEvent(
			'Browse by date',
			{
				category: 'Button',
				label: 'Network',
				value: this.channelModel.id,
			},
		);

		this.byCategory = false;
		this.byDate = true;
	}

	protected disableLoadingState() {
		if (!this.destroyed) {
			this.loading = false;
		}
	}

	protected selectPhoto(photoModel: PI.PhotoModel) {
		this.$emit(
			'selectPhoto',
			photoModel,
		);
	}

	private fetchAlbumCollection() {
		if (!this.loading && (!this.albums.fetched || this.albums.nextPage || this.albums.nextOptions)) {
			this.loading = true;

			const options: ConnectorOptions = {};
			if (this.albums.nextPage) {
				options.nextPage = this.albums.nextPage;
			}
			if (this.albums.nextOptions) {
				options.nextOptions = this.albums.nextOptions;
			}

			connector.albums(
				this.channelModel.id,
				options,
			)
				.then(
					({ data, paging }) => {
						this.disableLoadingState();

						this.albums.nextPage = paging && paging.nextPage ? paging.nextPage : null;
						this.albums.nextOptions = paging && paging.nextOptions ? paging.nextOptions : null;
						this.albums.fetched = true;
						this.albums.collection = this.albums.collection.concat(data);
					},
					(err: Error) => {
						this.disableLoadingState();

						this.$openErrorDialog({
							body: {
								content: err.message,
							},
						});
					},
				);
		}
	}

	protected levelUp() {
		if (this.albumid) {
			this.albumid = null;
		}
	}

	protected loadMore() {
		this.fetchAlbumCollection();
	}

	protected selectAlbum(albumid: string) {
		this.albumid = albumid;
	}
}
