import './defines';
import transparentSVG from '@root/img/colors/transparent.svg';
import ColorConverter from '@sosocio/color-converter';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import ButtonComponent from 'components/button';
import type DialogComponent from 'components/dialog';
import PantoneMatchingSystemHeaderComponent from 'components/pantone-matching-system-header';
import PmsColorPickerComponent from 'components/pms-color-picker';
import SwitchBoxComponent from 'components/switch-box';
import type TooltipComponent from 'components/tooltip';
import analytics from 'controllers/analytics';
import {
	EditorVectorEditColorsImageVectorColor,
	EditorVectorEditColorsImageVectorColors,
	PmsColorPickerMode,
} from 'interfaces/app';
import { PageObjectColorReplacementReplaceModel } from 'interfaces/project';
import { COLOR_FULL } from 'settings/offerings';
import { ConfigModule } from 'store';
import {
	logoColors as logoColorsTools,
	mobile as mobileTools,
} from 'tools';
import { type VueConstructor } from 'vue';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorVectorEditColorsView',
	components: {
		ButtonComponent,
		SwitchBoxComponent,
	},
})
export default class EditorVectorEditColorsView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the colors (foreground and background) with their replacements for the image vector',
			required: true,
			schema: 'EditorVectorEditColorsImageVectorColors',
			type: Object,
		},
	)
	public readonly value!: EditorVectorEditColorsImageVectorColors;

	@Prop({
		description: 'Defines the color limit for the image vector',
		required: true,
		type: Number,
	})
	public readonly colorLimit!: number;

	@Prop({
		description: 'Defines the svg content of the image vector',
		required: true,
		type: String,
	})
	public readonly vectorSvg!: string;

	protected get areColorsDifferentFromOriginals(): boolean {
		const foregroundMismatch = this.internalValue.foreground.some((vectorColor) => {
			if (this.pmsPickerMode === 'full') {
				return (
					vectorColor.replace
					&& vectorColor.replace.real.toLowerCase() !== vectorColor.color
				);
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: vectorColor.color.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			return `#${colorConverter.hex6.value}` !== vectorColor.replace?.real.toLowerCase();
		});

		if (foregroundMismatch) {
			return true;
		}

		if (!this.internalValue.background?.replace) {
			return false;
		}

		if (this.pmsPickerMode === 'full') {
			return this.internalValue.background.replace.real.toLowerCase() !== this.internalValue.background.color;
		}

		const colorConverter = new ColorConverter();
		colorConverter.hex6 = {
			value: this.internalValue.background.color.slice(1),
		};
		colorConverter.pantone = {
			name: colorConverter.pantone.name,
		};

		return `#${colorConverter.hex6.value}` !== this.internalValue.background.replace.real.toLowerCase();
	}

	protected get areColorsMergedToColorLimit(): boolean {
		return (
			this.uniqueForegroundColors.size === this.colorLimit
			&& this.internalValue.foreground.length !== this.uniqueForegroundColors.size
		);
	}

	protected get hasBackgroundColor(): boolean {
		return !!this.internalValue.background?.matches.length;
	}

	protected get imageVectorColors(): EditorVectorEditColorsImageVectorColor[] {
		if (
			!this.areColorsDifferentFromOriginals
			|| !this.areColorsMergedToColorLimit
			|| !this.showColorsMerged
		) {
			return this.internalValue.foreground;
		}

		return Array
			.from(this.uniqueForegroundColors)
			.reduce(
				(imageVectorColors, uniqueColor) => {
					const vectorColorFound = this.internalValue.foreground.find((vectorColor) => (
						vectorColor.replace?.real === uniqueColor
						|| vectorColor.color === uniqueColor
					));

					if (vectorColorFound) {
						imageVectorColors.push(vectorColorFound);
					}

					return imageVectorColors;
				},
				[] as EditorVectorEditColorsImageVectorColor[],
			);
	}

	protected get isBackgroundNonTransparent(): boolean {
		return (
			this.internalValue.background?.color !== 'transparent'
			&& this.internalValue.background?.replace?.real !== 'transparent'
		);
	}

	protected get isFullColor(): boolean {
		return this.colorLimit === COLOR_FULL;
	}

	private get pmsColorPickerFromColors(): string[] {
		return this.vectorColors.map((vectorColor) => {
			let { color } = vectorColor;

			if (this.pmsPickerMode !== 'full') {
				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: color.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};

				if (!colorConverter.hex6.showAs) {
					color = `#${colorConverter.hex6.value}`;
				} else {
					color = `#${colorConverter.hex6.showAs}`;
				}
			}

			return color;
		});
	}

	protected get pmsColorPickerValue(): string | undefined {
		const internalValueColorFound = this.vectorColors.find((vectorColor) => (
			vectorColor.color === this.selectedColor
			|| vectorColor.replace?.real === this.selectedColor
		));
		const colorToUse = (
			internalValueColorFound?.replace?.real
			|| internalValueColorFound?.color
		);

		if (
			internalValueColorFound
			&& colorToUse
			&& this.pmsPickerMode !== 'full'
			&& colorToUse.toLowerCase() !== 'transparent'
		) {
			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: colorToUse.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			if (colorConverter.hex6.forceAs) {
				return `#${colorConverter.hex6.forceAs}`;
			}
		}

		return colorToUse;
	}

	protected get selectedOriginalColor(): string | null {
		if (this.selectedColor) {
			if (this.pmsPickerMode === 'full') {
				return this.selectedColor;
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: this.selectedColor.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			return `#${(
				colorConverter.hex6.forceAs
				?? colorConverter.hex6.value
			)}`;
		}

		return null;
	}

	protected get showPantoneRegisteredMark(): boolean {
		return ConfigModule['features.pms'];
	}

	protected get totalColorCount(): number {
		return logoColorsTools
			.check(
				{
					color: this.colorLimit,
				},
				{
					colorReplacement: this.vectorColors.map((vectorColor) => ({
						color: vectorColor.color,
						replace: (
							vectorColor.replace
								? vectorColor.replace
								: {
									real: vectorColor.color,
								}
						),
					})),
				},
			).logoColors;
	}

	private get uniqueForegroundColors(): Set<string> {
		return new Set(
			this.internalValue.foreground.map((vectorColor) => (
				vectorColor.replace?.real
				|| vectorColor.color
			)),
		);
	}

	private get vectorColors(): EditorVectorEditColorsImageVectorColor[] {
		const fromColors: EditorVectorEditColorsImageVectorColor[] = [
			...this.internalValue.foreground,
		];

		if (this.internalValue.background?.matches.length) {
			fromColors.push(this.internalValue.background);
		}

		return fromColors;
	}

	protected imageUrlColorLimit = '';

	private internalValue: EditorVectorEditColorsImageVectorColors = {
		foreground: [],
	};

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private pmsColorPickerDialogTooltipClose?: () => void;

	private previousColorsBeforeMergingColors?: Record<string, PageObjectColorReplacementReplaceModel>;

	protected selectedColor: string | null = null;

	private showColorsMerged = false;

	private get pmsPickerMode(): PmsColorPickerMode {
		if (this.colorLimit !== COLOR_FULL) {
			return 'pms';
		}

		return 'full';
	}

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.pmsColorPickerDialogTooltipClose?.();
		this.pmsColorPickerDialogTooltipClose = undefined;
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	@Watch(
		'areColorsMergedToColorLimit',
		{
			immediate: true,
		},
	)
	protected onAreColorsMergedToColorLimitChange(): void {
		if (this.areColorsMergedToColorLimit) {
			this.showColorsMerged = true;

			/**
			 * Since now the colors are merged together, the selected color in which
			 * the the user has the selection active might not be available anymore,
			 * so we need to reset the selected color to the first ocurrence of the
			 * matched color (the one that is going to be shown in the UI as merged).
			 */
			if (this.selectedColor) {
				const selectedVectorColor = this.internalValue.foreground.find((vectorColor) => vectorColor.color === this.selectedColor);

				if (selectedVectorColor) {
					this.selectedColor = (
						this.internalValue.foreground.find((vectorColor) => vectorColor.replace?.real === selectedVectorColor.replace?.real)?.color
						|| null
					);
				}
			}
		} else {
			this.showColorsMerged = false;
		}

		if (
			this.areColorsMergedToColorLimit
			&& !this.previousColorsBeforeMergingColors
		) {
			this.previousColorsBeforeMergingColors = this.internalValue.foreground.reduce(
				(previousColors, vectorColor) => {
					if (vectorColor.replace) {
						previousColors[vectorColor.color] = {
							...vectorColor.replace,
						};
					}

					return previousColors;
				},
				{} as Record<string, PageObjectColorReplacementReplaceModel>,
			);
		}
	}

	@Watch(
		'colorLimit',
		{
			immediate: true,
		},
	)
	protected onColorLimitChange(): void {
		if (this.colorLimit) {
			let colorLimitFilename = 'full';

			if (this.colorLimit === 1) {
				colorLimitFilename = 'one';
			} else if (this.colorLimit === 2) {
				colorLimitFilename = 'two';
			} else if (this.colorLimit === 3) {
				colorLimitFilename = 'three';
			} else if (this.colorLimit === 4) {
				colorLimitFilename = 'four';
			}

			import(/* webpackMode: "eager" */ `@root/img/colors/${colorLimitFilename}.svg`)
				.then((imageUrl) => {
					this.imageUrlColorLimit = imageUrl.default;
				});
		}
	}

	@Watch('selectedColor')
	protected onSelectedColorChange(): void {
		const { selectedColor } = this;
		this.pmsColorPickerDialogTooltipClose?.();

		if (selectedColor) {
			let colorSourceType: keyof EditorVectorEditColorsImageVectorColors = 'foreground';

			if (this.internalValue.background?.color === this.selectedColor) {
				colorSourceType = 'background';
			}

			type BodyComponent = typeof PmsColorPickerComponent;
			const dialogTooltipBody: (
				DialogServiceOptionsBody<BodyComponent>
				| ToolbarServiceOptionsBody<BodyComponent>
			) = {
				component: PmsColorPickerComponent,
				props: {
					fromColors: this.pmsColorPickerFromColors,
					originalColor: this.selectedOriginalColor || undefined,
					pickerMode: this.pmsPickerMode,
					transparentToggle: colorSourceType === 'foreground',
					value: this.pmsColorPickerValue,
				},
				listeners: {
					change: this.onPmsColorChange,
				},
			};
			/* eslint-disable @typescript-eslint/indent */
			let pmsColorPickerDialogTooltipApi: NonVuePublicProps<
				DialogComponent<
					typeof PmsColorPickerComponent,
					VueConstructor,
					VueConstructor
				>
				| TooltipComponent<typeof PmsColorPickerComponent>
			>;
			/* eslint-enable @typescript-eslint/indent */

			if (!this.isMobile) {
				const tooltipAnchor = this.$el?.querySelector<HTMLButtonElement>(`.editor-vector-edit-colors-view-color[data-color="${selectedColor}"]`);

				if (!tooltipAnchor) {
					return;
				}

				const {
					api: pmsColorPickerTooltipApi,
					close: pmsColorPickerTooltipClose,
				} = this.$openTooltip({
					body: dialogTooltipBody,
					listeners: {
						close: () => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							pmsColorPickerValueUnwatch?.();
							this.pmsColorPickerDialogTooltipClose = undefined;

							if (this.selectedColor === selectedColor) {
								this.selectedColor = null;
							}
						},
					},
					anchor: tooltipAnchor,
					distance: 12,
					hasCloseButton: false,
					initialPosition: 'bottom left',
					isModal: false,
					noArrow: true,
					tooltipStyles: {
						border: '1px solid var(--neutral1-borders-and-stroke)',
						boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.16)',
					},
				});
				pmsColorPickerDialogTooltipApi = pmsColorPickerTooltipApi;
				this.pmsColorPickerDialogTooltipClose = pmsColorPickerTooltipClose;
			} else {
				const {
					api: pmsColorPickerDialogApi,
					close: pmsColorPickerDialogClose,
				} = this.$openDialogNew({
					header: (
						this.showPantoneRegisteredMark
							? {
								component: PantoneMatchingSystemHeaderComponent,
								listeners: {
									close: () => {
										this.pmsColorPickerDialogTooltipClose?.();
									},
								},
							}
							: {
								title: this.$t('components.pmsColorPicker.pickYourColor'),
								styles: {
									fontFamily: 'var(--font-family-demi-bold)',
									fontSize: 'var(--font-size-s)',
									fontWeight: 'var(--font-weight-demi-bold)',
								},
							}
					),
					body: dialogTooltipBody,
					listeners: {
						close: () => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							pmsColorPickerValueUnwatch?.();
							this.pmsColorPickerDialogTooltipClose = undefined;

							if (this.selectedColor === selectedColor) {
								this.selectedColor = null;
							}
						},
					},
					styles: {
						'--dialog-component-row-gap': '20px',
					},
				});
				pmsColorPickerDialogTooltipApi = pmsColorPickerDialogApi;
				this.pmsColorPickerDialogTooltipClose = pmsColorPickerDialogClose;
			}

			const pmsColorPickerValueUnwatch = this.$watch(
				'pmsColorPickerValue',
				() => {
					const pmsColorPickerComponent = pmsColorPickerDialogTooltipApi.bodyComponent();

					if (
						pmsColorPickerComponent
						&& this.pmsColorPickerValue
					) {
						pmsColorPickerComponent.value = this.pmsColorPickerValue;
					}
				},
			);
		}
	}

	@Watch(
		'value',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = {
			foreground: this.value.foreground.map((vectorColor) => ({
				color: vectorColor.color,
				matches: [
					...vectorColor.matches,
				],
				replace: (
					vectorColor.replace
						? {
							...vectorColor.replace,
						}
						: undefined
				),
			})),
			background: (
				this.value.background
					? {
						color: this.value.background.color,
						matches: [
							...this.value.background.matches,
						],
						replace: (
							this.value.background.replace
								? {
									...this.value.background.replace,
								}
								: undefined
						),
					}
					: undefined
			),
		};
	}

	protected getVectorColorClass(vectorColor: EditorVectorEditColorsImageVectorColor): Record<string, boolean> {
		return {
			'editor-vector-edit-colors-view-color-selected': vectorColor.color === this.selectedColor,
		};
	}

	protected getVectorColorStyles(vectorColor: EditorVectorEditColorsImageVectorColor): Partial<CSSStyleDeclaration> & Record<string, string> {
		const colorReplace = (
			vectorColor.replace?.visual
			|| vectorColor.replace?.real
			|| vectorColor.color
		);
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (colorReplace.toLowerCase() !== 'transparent') {
			styles['--vector-color'] = colorReplace;
		} else {
			styles['--vector-color-image'] = `url("${transparentSVG}")`;
			styles.backgroundSize = '10px';
		}

		if (vectorColor.color === this.selectedColor) {
			if (colorReplace.toLowerCase() !== 'transparent') {
				styles['--editor-vector-colors-view-color-box-shadow-color'] = colorReplace;
			} else {
				styles['--editor-vector-colors-view-color-box-shadow-color'] = 'var(--neutral1-borders-and-stroke)';
			}
		}

		return styles;
	}

	protected onBackgroundEnabledChange(value: boolean): void {
		if (this.internalValue.background) {
			if (
				value
				&& this.pmsPickerMode === 'full'
			) {
				Vue.delete(
					this.internalValue.background,
					'replace',
				);
			} else if (
				value
				&& this.pmsPickerMode === 'pms'
			) {
				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: this.internalValue.background.color.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};
				Vue.set(
					this.internalValue.background,
					'replace',
					{
						real: `#${colorConverter.hex6.value}`,
					},
				);

				if (
					colorConverter.hex6.showAs
					&& this.internalValue.background.replace
				) {
					this.internalValue.background.replace.visual = `#${colorConverter.hex6.showAs}`;
				}
			} else {
				this.internalValue.background.replace = {
					real: 'transparent',
				};
			}

			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	protected onColorClick(vectorColor: EditorVectorEditColorsImageVectorColor): void {
		if (this.selectedColor !== vectorColor.color) {
			this.selectedColor = vectorColor.color;
		} else {
			this.selectedColor = null;
		}
	}

	protected async onColorMergeChange(value: boolean): Promise<void> {
		analytics.trackEvent(
			'Color merge toggled',
			{
				enabled: value,
				colorsAvailable: this.colorLimit,
				colorsInLogo: this.internalValue.foreground.length + (this.internalValue.background?.matches.length ? 1 : 0),
			},
		);

		this.selectedColor = null;

		if (value) {
			this.showColorsMerged = true;
			this.previousColorsBeforeMergingColors = this.internalValue.foreground.reduce(
				(previousColors, vectorColor) => {
					if (vectorColor.replace) {
						previousColors[vectorColor.color] = {
							...vectorColor.replace,
						};
					}

					return previousColors;
				},
				{} as Record<string, PageObjectColorReplacementReplaceModel>,
			);

			if (!this.areColorsMergedToColorLimit) {
				const mergedColorGroups = await svgUtils.limitColorsToTopN(
					this.vectorSvg,
					{
						background: this.internalValue.background,
						foreground: new Map(
							this.internalValue.foreground.map((vectorColor) => [
								vectorColor.color,
								vectorColor,
							]),
						),
					},
					{
						topN: this.colorLimit,
						threshold: ConfigModule['logoColors.convertToNColorsThreshold'],
					},
				);
				const colorConverter = new ColorConverter();

				// eslint-disable-next-line no-restricted-syntax
				for (const mergedColorGroup of mergedColorGroups.foreground) {
					colorConverter.hex6 = {
						value: mergedColorGroup.color.slice(1),
					};

					if (this.pmsPickerMode !== 'full') {
						try {
							colorConverter.pantone = {
								name: colorConverter.pantone.name,
							};
						} catch {
							// Swallow error: no action required
						}
					}

					const color = `#${colorConverter.hex6.value}`;
					let visualColor: string | undefined;

					if (colorConverter.hex6.showAs) {
						visualColor = `#${colorConverter.hex6.showAs}`;
					}

					// eslint-disable-next-line no-restricted-syntax
					for (const colorToBeReplaced of mergedColorGroup.colors) {
						const vectorColorFound = this.internalValue.foreground.find((vectorColor) => vectorColor.color === colorToBeReplaced);

						if (vectorColorFound) {
							Vue.set(
								vectorColorFound,
								'replace',
								{
									real: color,
								},
							);

							if (
								visualColor
								&& vectorColorFound.replace
							) {
								vectorColorFound.replace.visual = visualColor;
							}
						}
					}
				}
			}
		} else {
			// eslint-disable-next-line no-restricted-syntax
			for (const vectorColor of this.internalValue.foreground) {
				const previousColorFound = (
					this.previousColorsBeforeMergingColors
					&& this.previousColorsBeforeMergingColors[vectorColor.color]
				);

				if (previousColorFound) {
					Vue.set(
						vectorColor,
						'replace',
						{
							...previousColorFound,
						},
					);
				} else if (this.pmsPickerMode === 'full') {
					Vue.delete(
						vectorColor,
						'replace',
					);
				} else {
					const colorConverter = new ColorConverter();
					colorConverter.hex6 = {
						value: vectorColor.color.slice(1),
					};
					colorConverter.pantone = {
						name: colorConverter.pantone.name,
					};
					Vue.set(
						vectorColor,
						'replace',
						{
							real: `#${colorConverter.hex6.value}`,
						},
					);

					if (
						colorConverter.hex6.showAs
						&& vectorColor.replace
					) {
						vectorColor.replace.visual = `#${colorConverter.hex6.showAs}`;
					}
				}
			}

			this.previousColorsBeforeMergingColors = undefined;
			this.showColorsMerged = false;
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}

	private onPmsColorChange(color: string): void {
		let colorsToUpdate: EditorVectorEditColorsImageVectorColor[] = [];
		let colorSourceType: keyof EditorVectorEditColorsImageVectorColors = 'foreground';

		if (this.internalValue.background?.color === this.selectedColor) {
			colorSourceType = 'background';
			colorsToUpdate = [this.internalValue.background];
		} else {
			colorsToUpdate = this.internalValue.foreground.filter((vectorColor) => vectorColor.color === this.selectedColor);
		}

		if (
			this.areColorsMergedToColorLimit
			&& this.showColorsMerged
			&& colorSourceType === 'foreground'
		) {
			colorsToUpdate = this.internalValue.foreground.filter((vectorColor) => (
				vectorColor.replace?.real === colorsToUpdate[0].replace?.real
				|| vectorColor.color === colorsToUpdate[0].replace?.real
			));
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const colorToUpdate of colorsToUpdate) {
			Vue.set(
				colorToUpdate,
				'replace',
				{
					real: color,
				},
			);

			if (
				this.pmsPickerMode === 'pms'
				&& color.toLowerCase() !== 'transparent'
			) {
				let normalizedColor = color;

				if (normalizedColor.at(0) === '#') {
					normalizedColor = normalizedColor.slice(1);
				}

				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: normalizedColor,
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};

				if (
					colorConverter.pantone.forceAs
					&& colorConverter.hex6.value !== normalizedColor
					&& colorToUpdate.replace
				) {
					colorToUpdate.replace.real = `#${colorConverter.hex6.value}`;
				}

				if (
					colorConverter.pantone.showAs
					&& colorToUpdate.replace
				) {
					colorConverter.rgb = {
						...colorConverter.pantone.showAs,
					};
					colorToUpdate.replace.visual = `#${colorConverter.hex6.value}`;
				}
			}

			if (
				this.previousColorsBeforeMergingColors?.[colorToUpdate.color]
				&& colorToUpdate.replace
			) {
				this.previousColorsBeforeMergingColors[colorToUpdate.color] = {
					...colorToUpdate.replace,
				};
			}
		}

		this.$emit(
			'change',
			this.internalValue,
		);

		if (this.isMobile) {
			this.pmsColorPickerDialogTooltipClose?.();
		}
	}

	protected onResetColorsClick(): void {
		analytics.trackEvent(
			'Logo colors reset',
			{},
		);

		// eslint-disable-next-line no-restricted-syntax
		for (const vectorColor of this.internalValue.foreground) {
			if (this.pmsPickerMode === 'full') {
				Vue.delete(
					vectorColor,
					'replace',
				);
				// eslint-disable-next-line no-continue
				continue;
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: vectorColor.color.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};
			Vue.set(
				vectorColor,
				'replace',
				{
					real: `#${colorConverter.hex6.value}`,
				},
			);

			if (
				colorConverter.hex6.showAs
				&& vectorColor.replace
			) {
				vectorColor.replace.visual = `#${colorConverter.hex6.showAs}`;
			}
		}

		if (this.internalValue.background?.matches.length) {
			if (this.pmsPickerMode === 'full') {
				Vue.delete(
					this.internalValue.background,
					'replace',
				);
			} else {
				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: this.internalValue.background.color.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};
				Vue.set(
					this.internalValue.background,
					'replace',
					{
						real: `#${colorConverter.hex6.value}`,
					},
				);

				if (
					colorConverter.hex6.showAs
					&& this.internalValue.background.replace
				) {
					this.internalValue.background.replace.visual = `#${colorConverter.hex6.showAs}`;
				}
			}
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}
}
