import _ from 'underscore';
import merge from 'deepmerge';
import LogRocket from 'logrocket';
import SegmentController from 'controllers/analytics/segment';
import MoengageController from 'controllers/analytics/moengage';
import GTMController from 'controllers/analytics/gtm';
import AmplitudeController from 'controllers/analytics/amplitude';
import {
	AppDataModule,
	ConfigModule,
	ExternalUsersModule,
	OrderItemsModule,
	UserModule,
} from 'store/index';
import * as DB from 'interfaces/database';
import {
	PricingObject,
	AnalyticsSessionProperties,
	AnalyticsUserProperties,
	AnalyticsProjectProperties,
	AnalyticsProjectReadyProperties,
	AnalyticsCreateProjectProperties,
	AnalyticsOpenProjectProperties,
} from 'interfaces/app';
import getDeviceDetails from 'ops/device-details';

interface TrackerOptions {
	// Amplitude
	amplitude?: boolean;
	// Google Tag Manager tracking
	gtm?: boolean;
	// Moengage tracking
	moengage?: boolean;
	// Segment.com tracking
	segment?: boolean;
}

class AnalyticsController {
	private active: TrackerOptions = {
		amplitude: false,
		gtm: false,
		moengage: false,
		segment: false,
	};

	private controllers: {
		amplitude: AmplitudeController;
		gtm: GTMController;
		moengage: MoengageController;
		segment: SegmentController;
	};

	private sessionProperties: AnalyticsSessionProperties = {};

	constructor() {
		this.controllers = {
			amplitude: new AmplitudeController(),
			gtm: new GTMController(),
			moengage: new MoengageController(),
			segment: new SegmentController(),
		};
	}

	public setup(): Promise<void> {
		this.setSessionProperties();

		return getDeviceDetails().then((deviceDetails) => {
			// Initialize Segment integration
			if (this.controllers.segment.init()) {
				// Flag activation
				this.active.segment = true;
			}

			// Initialize Moengage integration
			if (this.controllers.moengage.init()) {
				// Flag activation
				this.active.moengage = true;
			}

			// Initialize GTM integration
			if (this.controllers.gtm.init(deviceDetails)) {
				// Flag activation
				this.active.gtm = true;
			}

			// Initialize Amplitude integration
			if (this.controllers.amplitude.init(deviceDetails)) {
				// Flag activation
				this.active.amplitude = true;
			}
		});
	}

	// We set the session properties that we want to be send with every analytics event
	public setSessionProperties() {
		this.sessionProperties = {
			appVersion: VERSION,
			platform: window.glPlatform,
			trackerId: window.affiliateID,
		};
	}

	public setExperimentFlags(
		flags: Record<string, string | number | boolean | null>,
	) {
		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].setExperimentFlags(
					flags,
				);
			}
		});
	}

	public setUserProperties() {
		const userProps: AnalyticsUserProperties = {
			Affiliateid: UserModule.affiliateid,
			Language: UserModule.language || window.locale,
		};

		if (UserModule.currency) {
			userProps.Currency = UserModule.currency;
		}
		if (UserModule.countryid) {
			const countryModel = AppDataModule.getCountry(UserModule.countryid);
			if (countryModel) {
				userProps.ISO = countryModel.iso;
			}
		}
		if (UserModule.first_name.length) {
			userProps.First_name = UserModule.first_name;
		}
		if (UserModule.last_name.length) {
			userProps.Last_name = UserModule.last_name;
		}
		if (UserModule.email.length) {
			userProps.Email = UserModule.email;
		}
		if (UserModule.timestamp) {
			userProps['Account Created'] = UserModule.timestamp;
		}

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].setUserProperties(userProps);
			}
		});
	}

	public aliasUser(
		oldUserId: DB.UserModel['id'],
		newUserId: DB.UserModel['id'],
	) {
		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].aliasUser(
					oldUserId,
					newUserId,
				);
			}
		});
	}

	public registerUser() {
		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].registerUser();
			}
		});
	}

	private getStandardProjectProperties(
		projectModel: DB.ProductModel,
	): AnalyticsProjectProperties {
		const trackProperties: AnalyticsProjectProperties = {
			groupid: projectModel.group,
			typeid: projectModel.typeid,
			variantid: projectModel.variantid,
			product_origin: projectModel.origin,
		};

		const offeringId = parseInt(
			`${projectModel.group}${projectModel.typeid}${projectModel.variantid}`,
			10,
		);
		const offeringModel = AppDataModule.getOffering(offeringId);
		if (offeringModel) {
			trackProperties.type = offeringModel.type;
		}

		return trackProperties;
	}

	public identifyUser() {
		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].identifyUser();
			}
		});

		if (UserModule.id) {
			if (LogRocket && LogRocket.sessionURL) {
				let userIdentifier: string | undefined;

				if (ConfigModule['LogRocket.externalUserId']) {
					const externalUser = ExternalUsersModule.findWhere({
						source: 'app',
					});
					if (externalUser
						&& externalUser.externalId
					) {
						userIdentifier = externalUser.externalId;
					}
				} else {
					userIdentifier = UserModule.id.toString();
				}

				if (!userIdentifier) {
					throw new Error(
						'Missing user identifier for LogRocket',
					);
				}

				LogRocket.identify(
					userIdentifier,
				);
			}

			if (window.glPlatform == 'native'
				&& ConfigModule['analytics.nativeApp.enabled']
			) {
				if (!window.webToNative) {
					throw new Error('Missing WebToNative on window');
				}

				let userIdentifier: string | undefined;

				if (ConfigModule['analytics.nativeApp.externalUserId']) {
					const externalUser = ExternalUsersModule.findWhere({
						source: 'app',
					});
					if (externalUser
						&& externalUser.externalId
					) {
						userIdentifier = externalUser.externalId;
					}
				} else {
					userIdentifier = UserModule.id.toString();
				}

				if (!userIdentifier) {
					throw new Error(
						'Missing user identifier for native app analytics',
					);
				}

				window.webToNative.identifyUser(
					userIdentifier,
				);
			}
		}
	}

	public logout() {
		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].logout();
			}
		});
	}

	public trackPageView(
		urlFragment: string,
		pageTitle: string,
		objProperties?: Record<string, any>,
		opts?: TrackerOptions,
	) {
		const route = `/app/${urlFragment}`;

		// Support custom pageview tracker
		if (typeof window.customPageViewTracker !== 'undefined' && _.isFunction(window.customPageViewTracker)) {
			window.customPageViewTracker(
				route,
				pageTitle,
			);
		}

		const trackProperties = merge(
			this.sessionProperties,
			objProperties || {},
		);

		const defaults: TrackerOptions = {
			amplitude: false,
			gtm: true,
			moengage: false,
			segment: true,
		};
		const options = opts ? merge(
			defaults,
			opts,
		) : defaults;

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (options[key] && this.active[key]) {
				this.controllers[key].trackPageView(
					route,
					pageTitle,
					trackProperties,
				);
			}
		});
	}

	public trackEvent(
		action: string,
		objProperties: Record<string, any>,
		opts?: TrackerOptions,
	) {
		const defaults: TrackerOptions = {
			amplitude: true,
			gtm: true,
			moengage: true,
			segment: true,
		};
		const options = opts ? merge(
			defaults,
			opts,
		) : defaults;
		const trackProperties = merge(
			this.sessionProperties,
			objProperties,
		);

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (options[key] && this.active[key]) {
				this.controllers[key].trackEvent(
					action,
					trackProperties,
				);
			}
		});
	}

	public trackProjectReady(
		productModel: DB.ProductModel,
		objPrice: PricingObject | null,
		properties: {
			photoCount: number;
			pageCount: number;
		},
	) {
		const trackProperties: AnalyticsProjectReadyProperties = {
			...this.sessionProperties,
			...this.getStandardProjectProperties(productModel),
			...properties,
		};

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackProductReady(
					productModel,
					objPrice,
					trackProperties,
				);
			}
		});
	}

	public trackPerformance(
		action: string,
		timing: number,
		properties?: Record<string, any>,
	) {
		const trackProperties = merge(
			this.sessionProperties,
			properties || {},
		);

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackPerformance(
					action,
					timing,
					trackProperties,
				);
			}
		});
	}

	public trackCreateProject(
		productModel: DB.ProductModel,
	) {
		const trackProperties: AnalyticsCreateProjectProperties = {
			...this.sessionProperties,
			...this.getStandardProjectProperties(productModel),
		};

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackCreateProject(
					productModel,
					trackProperties,
				);
			}
		});
	}

	public trackOpenProject(
		productModel: DB.ProductModel,
	) {
		const trackProperties: AnalyticsOpenProjectProperties = {
			...this.sessionProperties,
			...this.getStandardProjectProperties(productModel),
		};

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackOpenProduct(
					productModel,
					trackProperties,
				);
			}
		});
	}

	public trackAddToCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
		properties?: Record<string, any>,
	) {
		const trackProperties = merge(
			this.sessionProperties,
			properties || {},
		);

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackAddToCart(
					cartItemModel,
					objPrice,
					trackProperties,
				);
			}
		});
	}

	public trackRemoveFromCart(
		cartItem: DB.ShoppingCartItemModel,
		properties?: Record<string, any>,
	) {
		const trackProperties = merge(
			this.sessionProperties,
			properties || {},
		);

		(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
			if (this.active[key]) {
				this.controllers[key].trackRemoveFromCart(
					cartItem,
					trackProperties,
				);
			}
		});
	}

	public trackTransaction(
		orderModel: DB.OrderModel,
		properties?: Record<string, any>,
	) {
		const trackProperties = merge(
			this.sessionProperties,
			properties || {},
		);

		OrderItemsModule.fetch({
			orderId: orderModel.id,
		}).then(() => {
			(Object.keys(this.active) as (keyof TrackerOptions)[]).forEach((key) => {
				if (this.active[key]) {
					const orderItems = OrderItemsModule.where({ orderid: orderModel.id });
					this.controllers[key].trackTransaction(
						orderModel,
						orderItems,
						trackProperties,
					);
				}
			});
		}).catch(() => {
			// Swallow error: no action required
		});
	}
}

export default new AnalyticsController();
