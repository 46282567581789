import './defines';
import PageObject from 'classes/pageobject';
import TemplatePosition from 'classes/templateposition';
import * as PI from 'interfaces/project';
import { AppDataModule, ProductStateModule } from 'store';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import {
	OfferingFrameModel,
	TemplateTextPosition,
} from 'interfaces/app';
import EditAddressView from '../edit-address';
import PageAddressView from '../page-address.vue';
import PageBackgroundView from '../page-background.vue';
import PageInteractionView from '../page-interaction.vue';
import PageObjectView from '../page-object.vue';
import PageOverlayView from '../page-overlay.vue';
import Template from './template.vue';

@Component({
	components: {
		PageAddressView,
		PageBackgroundView,
		PageInteractionView,
		PageObjectView,
		PageOverlayView,
	},
})
export default class PageEditorView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Number,
	})
	public readonly boundingBoxHeight!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly boundingBoxWidth!: number;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly draggable!: boolean;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly scaling!: number;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showAddress!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showBleed!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showImagemap!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showMessage!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showTemplate!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly smartZoom!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly resizable!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly rotatable!: boolean;

	protected get addressModel() {
		if (this.showAddress) {
			return ProductStateModule.getAddress;
		} return null;
	}

	private get bleedMargin() {
		return this.showBleed && this.pageModel.offset
			? this.pageModel.offset
			: 0;
	}

	protected get boxHeight() {
		const pageHeight = this.pageModel.height + 2 * this.bleedMargin;
		return Math.round(pageHeight * this.scaling);
	}

	protected get boxWidth() {
		const pageWidth = this.pageModel.width + 2 * this.bleedMargin;
		return Math.round(pageWidth * this.scaling);
	}

	private get offeringFrame(): OfferingFrameModel | undefined {
		if (!this.offeringModel) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(
			this.offeringModel.id,
			this.pageIndex,
		);
	}

	private get offeringModel() {
		return ProductStateModule.getOffering;
	}

	private get pageIndex() {
		return ProductStateModule.getPageIndex(this.pageModel);
	}

	private get pageObjects() {
		return ProductStateModule.getPageObjects(
			this.pageModel,
		);
	}

	protected get visiblePageObjects() {
		return this.pageObjects.filter(
			(objectModel) => {
				const vars = PageObject.calculatePosition(
					{
						x_axis: objectModel.x_axis,
						y_axis: objectModel.y_axis,
						width: objectModel.width,
						height: objectModel.height,
						borderwidth: objectModel.borderwidth,
						bordercolor: objectModel.bordercolor,
						cropx: objectModel.cropx,
						cropy: objectModel.cropy,
						cropwidth: objectModel.cropwidth,
						cropheight: objectModel.cropheight,
						angle: objectModel.rotate,
						flop: Boolean(objectModel.flop),
						flip: Boolean(objectModel.flip),
						type: objectModel.type,
						maxwidth: objectModel.maxwidth,
						photoid: objectModel.photoid || undefined,
					},
					this.scaling,
					this.pageModel,
					this.bleedMargin,
				);
				return vars.canvas.top + vars.canvas.height > -this.scaleOffsetTop
					&& vars.canvas.top < -this.scaleOffsetTop + this.boundingBoxHeight
					&& vars.canvas.left + vars.canvas.width > -this.scaleOffsetLeft
					&& vars.canvas.left < -this.scaleOffsetLeft + this.boundingBoxWidth;
			},
		);
	}

	private get scaleOffsetLeft() {
		const selectedObject = ProductStateModule.getSelectedPageObject;
		if (this.smartZoom && selectedObject && selectedObject.transformable === 0) {
			const left = -selectedObject.x_axis * this.scaling
				// add margin to center object in screen
				+ (this.boundingBoxWidth - selectedObject.width * this.scaling) / 2;
			return Math.min(
				0,
				Math.max(
					this.boundingBoxWidth - this.pageModel.width * this.scaling,
					left,
				),
			);
		}

		return 0;
	}

	private get scaleOffsetTop() {
		const selectedObject = ProductStateModule.getSelectedPageObject;
		if (this.smartZoom && selectedObject && selectedObject.transformable === 0) {
			const top = -selectedObject.y_axis * this.scaling
				// add margin to center object in screen
				+ (this.boundingBoxHeight - selectedObject.height * this.scaling) / 2;
			return Math.min(
				0,
				Math.max(
					this.boundingBoxHeight - this.pageModel.height * this.scaling,
					top,
				),
			);
		}

		return 0;
	}

	protected get showMask() {
		return Boolean(this.offeringModel && this.offeringModel.mask);
	}

	protected get showOverlay() {
		if (this.offeringModel?.overlay) {
			return true;
		}

		if (this.offeringFrame?.required
			&& this.offeringFrame?.overpage
			&& !this.offeringModel?.mask
		) {
			return true;
		}

		return false;
	}

	private get templatePositionsAvailable() {
		if (!this.pageModel.editable) {
			return [];
		}

		return ProductStateModule.getPageTemplatePositionsAvailable(
			this.pageModel,
		);
	}

	protected get Translation() {
		const hasTextObject = this.pageObjects.find(
			(m) => m.type === 'text',
		);

		return {
			addressButtonText: this.showAddress && ProductStateModule.getAddress
				? this.$t('buttonCardEditAddress')
				: this.$t('buttonCardAddAddress'),
			messageButtonText: this.showMessage && hasTextObject
				? this.$t('buttonCardEditMessage')
				: this.$t('buttonCardAddMessage'),
		};
	}

	private editAddress() {
		// Deselect first so that empty text objects are automatically removed
		ProductStateModule.deselectPageObjects();

		const addressModel = ProductStateModule.getAddress;

		if (!addressModel) {
			ProductStateModule
				.addAddress()
				.then(() => {
					this.editAddress();
				})
				.catch(() => {
					// Swallow error: no action required
				});
		} else {
			const { close: closeDialog } = this.$openDialog({
				header: {
					title: this.$t('dialogHeaderCardSingle'),
				},
				body: {
					component: EditAddressView,
					props: {
						productModel: ProductStateModule.getProduct || undefined,
					},
					listeners: {
						closeDialog: () => {
							closeDialog();
						},
					},
				},
				width: 400,
			});
		}
	}

	protected editMessage() {
		// Deselect first so that empty text objects are automatically removed
		ProductStateModule.deselectPageObjects();

		const objectModel = this.pageObjects.find(
			(m) => m.type === 'text',
		);
		if (objectModel) {
			// edit existing object
			PageObject.select(
				this.pageModel,
				objectModel.id,
				{ editText: true },
			);
		} else {
			// create new object
			const textPosition = this.templatePositionsAvailable.find(
				(m) => m.type === 'text',
			) as TemplateTextPosition;

			if (textPosition) {
				TemplatePosition
					.fillTextPosition(
						this.pageModel,
						textPosition,
						'',
						{
							force: true,
						},
					)
					.then((newObjectModel) => {
						PageObject.select(
							this.pageModel,
							newObjectModel.id,
							{ editText: true },
						);
					})
					.catch(() => {
						// Swallow error: no action required
					});
			}
		}
	}
}
