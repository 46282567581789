import {
	Vue,
	Component,
	Prop,
	Watch,
} from 'vue-property-decorator';
import { OfferingFrameModel } from 'interfaces/app';
import * as PI from 'interfaces/project';
import maxCanvasSize from 'tools/max-canvas-size';
import store, {
	ProductStateModule,
	AppDataModule,
	AppStateModule,
} from 'store/index';
import getViewport from 'tools/get-viewport';
import PageEditorView from 'views/page-editor';
import { OfferingGroups } from 'settings/offerings';

@Component({
	components: {
		PageEditorView,
	},
})
export default class EditorBoxView extends Vue {
	@Prop({ required: true, type: Number }) readonly maxHeight!: number;

	@Prop({ required: true, type: Object }) readonly pageModel!: PI.PageModel;

	private viewportSize = getViewport();

	get maxCanvasSize() {
		return maxCanvasSize();
	}

	get bleedMargin() {
		return this.offeringModel && this.offeringModel.showbleed > 0 && this.pageModel.offset
			? this.pageModel.offset
			: 0;
	}

	get boxStyle() {
		return {
			width: `${Math.round(this.frameWidth)}px`,
			height: `${Math.round(this.frameHeight)}px`,
		};
	}

	get boxWidth() {
		const boxWidth = this.viewportSize.width - 40;
		if (boxWidth > 767 && boxWidth < 1280) {
			return Math.min(
				1024,
				boxWidth,
			);
		}

		return Math.min(
			1240,
			boxWidth,
		);
	}

	get canvasSize() {
		return (this.pageModel.width + 2 * this.bleedMargin) * (this.pageModel.height + 2 * this.bleedMargin);
	}

	get frameHeight() {
		if (this.showOfferingFrame && this.offeringFrame) {
			return this.offeringFrame.imageModel.height * this.frameScale;
		}
		return this.fullPageHeight;
	}

	get frameLeft() {
		return (this.boxWidth - this.frameWidth) / 2;
	}

	get frameScale() {
		if (this.showOfferingFrame && this.offeringFrame) {
			const maxFrameWidth = Math.min(
				this.boxWidth,
				this.offeringFrame.imageModel.width,
				(this.pageModel.width + 2 * this.bleedMargin) * (this.offeringFrame.imageModel.width / this.offeringFrame.templateModel.width),
			);
			const maxFrameHeight = Math.min(
				this.maxHeight,
				this.offeringFrame.imageModel.height,
				(this.pageModel.height + 2 * this.bleedMargin) * (this.offeringFrame.imageModel.height / this.offeringFrame.templateModel.height),
			);
			return Math.min(
				maxFrameHeight / this.offeringFrame.imageModel.height,
				maxFrameWidth / this.offeringFrame.imageModel.width,
			);
		}
		return 1;
	}

	get frameWidth() {
		if (this.showOfferingFrame && this.offeringFrame) {
			return this.offeringFrame.imageModel.width * this.frameScale;
		}
		return this.fullPageWidth;
	}

	get fullPageHeight() {
		if (this.pageModel.width / this.boxWidth > this.pageModel.height / this.maxHeight) {
			return (this.pageModel.height + 2 * this.bleedMargin) / (this.pageModel.width + 2 * this.bleedMargin) * this.fullPageWidth;
		}

		const fullPageHeight = Math.min(
			this.maxCanvasHeight,
			this.maxHeight,
			this.pageModel.height + 2 * this.bleedMargin,
		);

		if (this.offeringFrame) {
			return Math.min(
				fullPageHeight,
				this.offeringFrame.templateModel.height * this.frameScale,
			);
		}
		return fullPageHeight;
	}

	get fullPageWidth() {
		const fullPageWidth = Math.min(
			this.maxCanvasWidth,
			this.boxWidth,
			this.pageModel.width + 2 * this.bleedMargin,
		);

		if (this.offeringFrame) {
			return Math.min(
				fullPageWidth,
				this.offeringFrame.templateModel.width * this.frameScale,
			);
		}
		return fullPageWidth;
	}

	get maxCanvasWidth() {
		return Math.max(
			1,
			this.maxCanvasSize / this.canvasSize,
		) * (this.pageModel.width + 2 * this.bleedMargin);
	}

	get maxCanvasHeight() {
		return Math.max(
			1,
			this.maxCanvasSize / this.canvasSize,
		) * (this.pageModel.height + 2 * this.bleedMargin);
	}

	get offeringFrame(): OfferingFrameModel | undefined {
		if (!this.offeringModel) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(
			this.offeringModel.id,
			this.pageIndex,
		);
	}

	get offeringFrameImageUrl(): string | undefined {
		if (!this.offeringFrame?.imageModel) {
			return undefined;
		}

		return this.offeringFrame.imageModel.url;
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get pageDisplayHeight() {
		const bleed = 2 * this.visualBleed;
		return Math.round(
			Math.min(
				this.pageHeight,
				this.pageModel.height * this.scaling,
			) + bleed * this.scaling,
		);
	}

	get pageDisplayWidth() {
		const bleed = 2 * this.visualBleed;
		return Math.min(
			this.pageWidth,
			this.pageModel.width * this.scaling,
		) + bleed * this.scaling;
	}

	get pageHeight() {
		if (this.showOfferingFrame) {
			return this.frameHeight;
		}
		return this.fullPageHeight - 2 * (this.bleedMargin - this.visualBleed) * this.scaling;
	}

	get pageIndex() {
		return ProductStateModule.getPageIndex(this.pageModel);
	}

	get pageStyle() {
		const left = this.showOfferingFrame && this.offeringFrame
			? this.offeringFrame.templateModel.x * this.frameScale
			: (this.fullPageWidth - this.pageDisplayWidth) / 2;
		const top = this.showOfferingFrame && this.offeringFrame
			? this.offeringFrame.templateModel.y * this.frameScale
			: (this.fullPageHeight - this.pageDisplayHeight) / 2;

		return {
			width: `${Math.round(this.pageDisplayWidth + 2)}px`,
			height: `${Math.round(this.pageDisplayHeight + 2)}px`,
			left: `${Math.round(left)}px`,
			top: `${Math.round(top)}px`,
		};
	}

	get pageWidth() {
		if (this.showOfferingFrame) {
			return this.frameWidth;
		}
		return this.fullPageWidth - 2 * (this.bleedMargin - this.visualBleed) * this.scaling;
	}

	get productModel() {
		return ProductStateModule.getProduct;
	}

	get scaling() {
		const scale = Math.min(
			this.fullPageWidth / (this.pageModel.width + 2 * this.bleedMargin),
			this.fullPageHeight / (this.pageModel.height + 2 * this.bleedMargin),
		);

		const selectedObject = ProductStateModule.getSelectedPageObject;
		if (this.smartZoom && selectedObject && selectedObject.transformable === 0) {
			const width = Math.min(
				this.fullPageWidth,
				selectedObject.width + 60,
			);
			const height = Math.min(
				this.fullPageHeight,
				selectedObject.height + 60,
			);
			const smartZoomScale = Math.min(
				width / (selectedObject.width + 60),
				height / (selectedObject.height + 60),
			);

			return Math.max(
				smartZoomScale,
				scale,
			);
		}

		return scale;
	}

	get showBleed() {
		return AppStateModule.showBleed;
	}

	get showOfferingFrame() {
		return this.offeringFrame
			&& this.offeringFrame.required;
	}

	get showUI() {
		return !AppStateModule.heavyLoad;
	}

	get smartZoom() {
		const templateModel = ProductStateModule.getPageTemplate(this.pageModel);
		return !this.showOfferingFrame
			&& templateModel
			&& store.state.config['features.smartZoom']
			&& (templateModel.transformable === 0 || (this.offeringModel && OfferingGroups(
				this.offeringModel.groupid,
				['WallDecoration'],
			)));
	}

	get visualBleed() {
		return this.showBleed ? this.bleedMargin : 0;
	}

	mounted() {
		window.addEventListener(
			'resize',
			this.resize,
		);
		this.emitWidth();
	}

	beforeDestroy() {
		window.removeEventListener(
			'resize',
			this.resize,
		);
	}

	@Watch('pageDisplayWidth')
	emitWidth() {
		// This is kind of an ugly hack, but for now it seems the only way to share the display width
		// which is used in EditorView to set the width of the page browser buttons
		this.$emit(
			'displayWidth',
			Math.round(this.pageDisplayWidth),
		);
	}

	resize() {
		this.viewportSize = getViewport();
	}
}
