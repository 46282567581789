import * as PI from 'interfaces/project';
import { TemplateTextPosition, TemplatePhotoPosition } from 'interfaces/app';
import {
	FontModule,
	ProductStateModule,
} from 'store/index';
import filterText from 'tools/filter-text';
import breakText from 'tools/break-text';
import getColorInverse from 'tools/get-color-inverse';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import intersect from 'tools/intersect';
import Template, { PhotoData } from './template';

export default class TemplatePosition {
	/**
	 * Create a page text object by applying a text string to a template position
	 *
	 * @param pageModel The page model to add the page object to
	 * @param templatePositionModel The template position that the text will be applied to
	 * @param text The text string to add to the page
	 * @param options Configuration object
	 * @param options.force Also add object when text string is empty
	 * @param options.photoObjectModel The photo object that the text is a caption for
	 */
	public static fillTextPosition(
		pageModel: PI.PageModel,
		templatePositionModel: TemplateTextPosition,
		text: string,
		options?: {
			force?: boolean,
			photoObjectModel?: PI.PageObjectModel,
		},
	): Promise<PI.PageObjectModel> {
		const fontModel = FontModule.getById(templatePositionModel.fontface);
		const subset = (
			fontModel
				? fontModel.subset.split(',')
				: ['latin']
		);
		text = filterText(
			text,
			subset,
		);

		if (text.length === 0
			&& !options?.force
		) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const offeringModel = ProductStateModule.getOffering;
		const response = breakText({
			phrase: text,
			maxPxLength: templatePositionModel.width,
			maxPxHeight: templatePositionModel.height,
			fontface: templatePositionModel.fontface,
			bold: templatePositionModel.fontbold,
			italic: templatePositionModel.fontitalic,
			pointsize: templatePositionModel.pointsize,
			resize: {
				up: 80,
				down: (
					offeringModel
						? offeringModel.minfontsize
						: 10
				),
			},
			subset,
		});

		// If snap parameter is set: snap title to photo bottom
		const yAxis = (
			options?.photoObjectModel
				? options.photoObjectModel.y_axis + options.photoObjectModel.height
				: templatePositionModel.y
		);
		const defaultPointSize = (
			templatePositionModel.pointsize > 0
				? templatePositionModel.pointsize
				: 12
		);
		const fontColor = (
			(
				pageModel.bgcolor !== 'transparent'
				&& getColorInverse(templatePositionModel.fontcolor) == getColorInverse(pageModel.bgcolor || '#FFFFFF')
			)
				? getColorInverse(templatePositionModel.fontcolor) // Page color and font color are too similar, so get inverse
				: templatePositionModel.fontcolor
		);

		return ProductStateModule
			.addPageObject({
				pageId: pageModel.id,
				data: {
					templatestateid: templatePositionModel.id,
					x_axis: templatePositionModel.x,
					y_axis: yAxis,
					z_axis: Math.max(
						1,
						templatePositionModel.z,
					),
					width: templatePositionModel.width,
					height: templatePositionModel.height,
					cropy: (
						(
							response
							&& response.textheight
						)
							? Math.max(
								0,
								(templatePositionModel.height - response.textheight) / 2,
							)
							: 0
					),
					type: 'text',
					rotate: templatePositionModel.angle,
					transformable: (
						templatePositionModel.transformable
							? 1
							: 0
					),
					text,
					text_formatted: (
						response
							? response.text_formatted
							: ''
					),
					text_formatted_for_canvas: (
						response
							? response.text_formatted_for_canvas
							: ''
					),
					pointsize: (
						response
							? response.pointsize
							: defaultPointSize
					),
					fontface: templatePositionModel.fontface,
					fontcolor: fontColor,
					fontbold: (
						templatePositionModel.fontbold
							? 1
							: 0
					),
					fontitalic: (
						templatePositionModel.fontitalic
							? 1
							: 0
					),
					bgcolor: templatePositionModel.bgcolor,
					align: templatePositionModel.align,
				},
			});
	}

	/**
	 * Create a page photo object by applying a photo model to a template position
	 *
	 * @param pageModel The page model to add the page object to
	 * @param templatePositionModel The template position that the photo model will be applied to
	 * @param photoModel The photo model to add to the project's page
	 * @param options Configuration object
	 * @param options.fillTitles Automatically add the photo model's "title" property as a text object to the page?
	 * @param options.fit Use the "fit" method (instead of "fill") to make sure photos will be fully visible (no cropping)
	 */
	public static fillPhotoPosition(
		pageModel: PI.PageModel,
		templatePositionModel: TemplatePhotoPosition,
		photoModel: PI.PhotoModel,
		options: {
			fillTitles?: boolean,
			fit?: boolean,
		},
	): Promise<PI.PageObjectModel> {
		if (templatePositionModel.type != 'photo') {
			return Promise.reject(
				new Error('Wrong template position model'),
			);
		}

		const offeringModel = ProductStateModule.getOffering;
		const photoData: Omit<PhotoData, 'url'> = {
			id: photoModel.id,
			width: photoModel.full_width,
			height: photoModel.full_height,
			caption: photoModel.title || undefined,
		};
		if (typeof photoModel.fcx !== 'undefined'
			&& photoModel.fcx !== null
			&& typeof photoModel.fcy !== 'undefined'
			&& photoModel.fcy !== null
			&& typeof photoModel.fcw !== 'undefined'
			&& photoModel.fcw !== null
			&& typeof photoModel.fch !== 'undefined'
			&& photoModel.fch !== null
		) {
			photoData.facebox = {
				x: photoModel.fcx,
				y: photoModel.fcy,
				width: photoModel.fcw,
				height: photoModel.fch,
			};
		}

		const props = Template.fitPhotoInRectangle(
			{
				x: templatePositionModel.x,
				y: templatePositionModel.y,
				width: templatePositionModel.width,
				height: templatePositionModel.height,
				angle: templatePositionModel.angle,
				borderwidth: templatePositionModel.borderwidth,
				autoRotate: Boolean(templatePositionModel.autorotate),
			},
			photoData,
			undefined,
			{
				// fillTitles: options.fillTitles,
				fit: options.hasOwnProperty('fit')
					? options.fit
					: (templatePositionModel.fillMethod === 'contain'),
				resizing: {
					maxScale: offeringModel
						? offeringModel.configdpi / offeringModel.minimumdpi
						: 1000,
					recommendedMaxScale: offeringModel
						? offeringModel.configdpi / offeringModel.qualitydpi
						: 1000,
				},
			},
		);

		return ProductStateModule
			.addPageObject({
				pageId: pageModel.id,
				data: {
					templatestateid: templatePositionModel.id,
					x_axis: props.x,
					y_axis: props.y,
					z_axis: Math.max(
						1,
						templatePositionModel.z,
					),
					width: props.width,
					height: props.height,
					maxwidth: photoModel.full_width,
					maxheight: photoModel.full_height,
					type: 'photo',
					cropwidth: props.cropWidth,
					cropheight: props.cropHeight,
					cropx: props.cropX,
					cropy: props.cropY,
					rotate: props.rotation,
					borderwidth: templatePositionModel.borderwidth,
					bordercolor: templatePositionModel.bordercolor,
					transformable: templatePositionModel.transformable ? 1 : 0,
					borderimage: undefined,
					photoid: photoModel.id,
					mask: templatePositionModel.mask ? templatePositionModel.mask : null,
				},
			});
		/* .then((objectModel) => {
				if (options.fillTitles
					&& photoModel.title
					&& photoModel.title.length > 0
					&& positionStateModel.phototitle
				) {
					TemplatePosition
						.fillTextPosition(
							pageModel,
							positionStateModel,
							photoModel.title,
							false,
							objectModel,
						)
						.catch(() => {
							// Swallow error: no action required
						});
				}

				return objectModel;
			}); */
	}

	/**
	 * Check availability of a template position on a page (no overlap with existing objects)
	 *
	 * @param positionModel Template position model we want to check the availability of
	 * @param pageObjectModels Page object models that are already on the page
	 */
	public static getAvailability(
		positionModel: TemplatePhotoPosition | TemplateTextPosition,
		pageObjectModels: PI.PageObjectModel[],
	): boolean {
		const hasOverlappingObject = pageObjectModels.find((objectModel) => {
			if (positionModel.type != objectModel.type) {
				return false;
			}

			if (objectModel.z_axis >= 900) {
				return false;
			}

			return intersect(
				{
					x: objectModel.x_axis + 1,
					y: objectModel.y_axis + 1,
					width: objectModel.width - 2,
					height: objectModel.height - 2,
					borderWidth: objectModel.borderwidth,
					rotation: objectModel.rotate,
				},
				{
					x: positionModel.x + positionModel.overlap_left + 1,
					y: positionModel.y + positionModel.overlap_top + 1,
					width: positionModel.width - positionModel.overlap_left - positionModel.overlap_right - 2,
					height: positionModel.height - positionModel.overlap_top - positionModel.overlap_bottom - 2,
					borderWidth: positionModel.borderwidth,
					rotation: positionModel.angle,
				},
			);
		});

		return Boolean(!hasOverlappingObject);
	}
}
