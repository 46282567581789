import SAT from 'sat';

/*
* Point is object with properties 'x' and 'y'
* Rectangle is object with properties 'topleft', 'topright', 'bottomright', and 'bottomleft'
* Each rectangle property has properties 'x' and 'y'
*/
const pointRectangleCollision = (
	point: {
		x: number;
		y: number;
	},
	rectangle: {
		topleft: {
			x: number;
			y: number;
		};
		topright: {
			x: number;
			y: number;
		};
		bottomright: {
			x: number;
			y: number;
		};
		bottomleft: {
			x: number;
			y: number;
		};
	},
) => {
	const poly = new SAT.Polygon(
		new SAT.Vector(),
		[
			new SAT.Vector(
				rectangle.topleft.x,
				rectangle.topleft.y,
			),
			new SAT.Vector(
				rectangle.bottomleft.x,
				rectangle.bottomleft.y,
			),
			new SAT.Vector(
				rectangle.bottomright.x,
				rectangle.bottomright.y,
			),
			new SAT.Vector(
				rectangle.topright.x,
				rectangle.topright.y,
			),
		],
	);
	const p = new SAT.Vector(
		point.x,
		point.y,
	);

	return SAT.pointInPolygon(
		p,
		poly,
	);
};

export default pointRectangleCollision;
