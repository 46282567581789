import './defines';
import SwitchBoxComponent from 'components/switch-box';
import { EditorProductSettingsModel } from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import BleedMarginView from 'views/bleed-margin';
import SmartEnhancementView from 'views/smart-enhancement';
import {
	Component,
	Model,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorProductSettingsView',
	components: {
		SwitchBoxComponent,
	},
})
export default class EditorProductSettingsView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the settings model',
			required: true,
			schema: 'EditorProductSettingsModel',
			type: Object,
		},
	)
	public readonly settings!: EditorProductSettingsModel;

	protected get productSettingIcon(): (setting: keyof EditorProductSettingsModel) => string {
		return (setting) => {
			if (setting === 'applyEnhancement') {
				return 'image_enhancement_single';
			}
			if (setting === 'showBleedMargin') {
				return 'bleed_margin';
			}

			return '';
		};
	}

	protected get isProductSettingChecked(): (setting: keyof EditorProductSettingsModel) => boolean {
		return (setting) => {
			if (setting === 'applyEnhancement') {
				return this.internalSettings[setting] ?? true;
			}

			return !!(this.internalSettings[setting]);
		};
	}

	private internalSettings: EditorProductSettingsModel = {};

	@Watch(
		'settings',
		{
			immediate: true,
		},
	)
	protected onSettingsChange(): void {
		this.internalSettings = {
			...this.settings,
		};
	}

	protected onProductSettingChange(
		setting: keyof EditorProductSettingsModel,
		value: boolean,
	): void {
		this.internalSettings[setting] = value;
		this.$emit(
			'change',
			this.internalSettings,
		);
	}

	protected onProductSettingInfoClick(setting: keyof EditorProductSettingsModel): void {
		let vueComponent!: typeof Vue;
		let dialogTitle = '';
		let dialogButtonText = '';

		if (setting === 'applyEnhancement') {
			vueComponent = SmartEnhancementView;
			dialogTitle = this.$t('views.smartEnhancement.title');
			dialogButtonText = this.$t('views.smartEnhancement.acceptButton');
		} else if (setting === 'showBleedMargin') {
			vueComponent = BleedMarginView;
			dialogTitle = this.$t('views.bleedMargin.title');
			dialogButtonText = this.$t('views.bleedMargin.buttons.accept');
		}

		if (
			setting === 'applyEnhancement'
			|| setting === 'showBleedMargin'
		) {
			const { close: closeDialog } = this.$openDialogNew({
				header: {
					title: dialogTitle,
					styles: {
						fontSize: (
							mobileTools.isMobile
								? 'var(--font-size-m)'
								: 'var(--font-size-l)'
						),
					},
				},
				body: {
					component: vueComponent,
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: dialogButtonText,
							click: () => {
								closeDialog();
							},
						},
					],
				},
				padding: (
					mobileTools.isMobile
						? [16, 20]
						: [24, 32]
				),
				styles: {
					rowGap: '24px',
				},
				theme: 'light',
				width: (
					mobileTools.isMobile
						? 350
						: 460
				),
			});
		}
	}
}
