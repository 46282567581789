var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"virtualOfferingSelect"},[_c('div',{staticClass:"boxOne"},[_c('div',{staticClass:"header mobileOnly"},[_vm._v("\n\t\t\t"+_vm._s(_vm.headerText)+"\n\t\t")]),_vm._v(" "),_c('div',{staticClass:"imageNode"},[(_vm.img)?_c('img',{attrs:{"src":_vm.img}}):_c('svg',{staticClass:"loadIconSvg",attrs:{"viewBox":"0 0 32 32","width":"64","height":"64"}},[_c('circle',{staticClass:"loadIcon",attrs:{"cx":"16","cy":"16","r":"14","fill":"none"}})])])]),_vm._v(" "),_c('div',{staticClass:"boxTwo"},[_c('div',{staticClass:"header desktopOnly"},[_vm._v("\n\t\t\t"+_vm._s(_vm.headerText)+"\n\t\t")]),_vm._v(" "),_c('div',{staticClass:"selectBox"},[(_vm.step === 'size' || _vm.step === 'finish')?_c('ul',{staticClass:"list"},[(_vm.showBestFit)?_c('li',{class:[
						{ selected: _vm.isOfferingSelected(null) }
					],on:{"click":function($event){return _vm.selectOffering('bestFit')}}},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"offeringLabel"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('bestFit'))+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"priceLabel"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('bestFitCaption'))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"icon"},[(_vm.isOfferingSelected(null))?_c('i',{staticClass:"material-icons"},[_vm._v("\n\t\t\t\t\t\t\tcheckmark\n\t\t\t\t\t\t")]):_vm._e()])]):_vm._e(),_vm._v(" "),_vm._l((_vm.offeringModels),function(offeringModel){return _c('li',{key:offeringModel.id,class:[
						{ selected: _vm.isOfferingSelected(offeringModel) }
					],on:{"click":function($event){return _vm.selectOffering(offeringModel.id)}}},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"offeringLabel"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.getOfferingLabel(offeringModel))+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"priceLabel"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.getPrice(offeringModel) ? _vm.$t('priceLabelPerPiece', {
								price: _vm.getPrice(offeringModel)
							}) : '')+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_vm._l((_vm.getOfferingBadges(offeringModel.id)),function(badge){return _c('div',{key:badge.id,staticClass:"badge"},[_c('img',{attrs:{"src":badge.url}})])}),_vm._v(" "),_c('div',{staticClass:"icon"},[(_vm.isOfferingSelected(offeringModel))?_c('i',{staticClass:"material-icons"},[_vm._v("\n\t\t\t\t\t\t\tcheckmark\n\t\t\t\t\t\t")]):_vm._e()])],2)})],2):_c('ul',{staticClass:"list"},_vm._l((_vm.layoutModels),function(layoutModel){return _c('li',{key:layoutModel.id,class:[
						{ selected: _vm.isLayoutSelected(layoutModel.id) }
					],on:{"click":function($event){return _vm.selectLayoutId(layoutModel.id)}}},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"offeringLabel"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.getLayoutLabel(layoutModel))+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"priceLabel"})]),_vm._v(" "),_c('div',{staticClass:"icon"},[(_vm.isLayoutSelected(layoutModel.id))?_c('i',{staticClass:"material-icons"},[_vm._v("\n\t\t\t\t\t\t\tcheckmark\n\t\t\t\t\t\t")]):_vm._e()])])}),0)]),_vm._v(" "),_c('button',{staticClass:"actionbutton full altColor desktopOnly",on:{"click":_vm.clickSelect}},[_vm._v("\n\t\t\t"+_vm._s(_vm.buttonText)+"\n\t\t")])]),_vm._v(" "),_c('div',{staticClass:"boxThree mobileOnly"},[_c('button',{staticClass:"actionbutton full altColor",on:{"click":_vm.clickSelect}},[_vm._v("\n\t\t\t"+_vm._s(_vm.buttonText)+"\n\t\t")])])])}
var staticRenderFns = []

export { render, staticRenderFns }