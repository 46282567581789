import { ValidationRuleFunction, RuleParamSchema } from 'vee-validate/dist/types/types';

const validate: ValidationRuleFunction = (
	zipcode: string,
	{ countryIso }: Record<string, any>,
): Promise<string|boolean> => {
	if (!countryIso || countryIso.length < 2) {
		return Promise.resolve(true);
	}

	// Normalize iso code
	countryIso = countryIso.toLowerCase();

	// Get regex test for country
	let regEx;

	// This check uses regular expressions from http://html5pattern.com/Postal_Codes
	if (countryIso == 'ca') {
		regEx = /[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]/;
	} else if (countryIso == 'gb') {
		regEx = /[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}/;
	} else if (countryIso == 'nl') {
		regEx = /[1-9][0-9]{3}\s?[a-zA-Z]{2}/;
	} else if (countryIso == 'de') {
		regEx = /[0-9]{5}/;
	} else if (countryIso == 'dk') {
		regEx = /[0-9]{3,4}/;
	} else if (countryIso == 'es') {
		regEx = /((0[1-9]|5[0-2])|[1-4][0-9])[0-9]{3}/;
	} else if (countryIso == 'hu') {
		regEx = /[0-9]{4}/;
	} else if (countryIso == 'it') {
		regEx = /[0-9]{5}/;
	} else if (countryIso == 'jp') {
		regEx = /\d{3}-\d{4}/;
	} else if (countryIso == 'lu') {
		regEx = /(L\s*(-|—|–))\s*?[\d]{4}/;
	} else if (countryIso == 'pl') {
		regEx = /[0-9]{2}-[0-9]{3}/;
	} else if (countryIso == 'se') {
		regEx = /\d{3}\s?\d{2}/;
	} else if (countryIso == 'us') {
		regEx = /(\d{5}([-]\d{4})?)/;
	} else {
		// No zip code check available, always return true
		return Promise.resolve(true);
	}

	if (regEx.test(zipcode)) {
		return Promise.resolve(true);
	}

	return Promise.resolve(false);
};

const params: RuleParamSchema[] = [{
	name: 'countryIso',
	default: '',
	isTarget: true,
}];

const computesRequired = true;

export default {
	computesRequired,
	validate,
	params,
};
