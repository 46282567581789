import './defines';
import _ from 'underscore';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
	HeaderCartItems,
} from 'interfaces/app';
import PriceView from '../price';
import Template from './template.vue';

@Component({
	components: {
		PriceView,
	},
})
export default class CartWidgetView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates if the cart module in the app is enabled (for showing the cart content)',
		type: Boolean,
	})
	public readonly hasCartModule!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the content of the cart can be edited from this widget',
		type: Boolean,
	})
	public readonly hasEdit!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the parent component is in a collapsed state',
		type: Boolean,
	})
	public readonly headerCollapsed!: boolean;

	@Prop({
		default: [],
		description: 'Defines a set of cart items to show in the list',
		schema: 'HeaderCartItems',
		type: Array,
	})
	public readonly items!: HeaderCartItems;

	private collapsed = true;

	protected get itemCount() {
		return _.reduce(
			this.items,
			(memo, item) => memo + item.quantity,
			0,
		);
	}

	protected get subTotal() {
		let subTotal = 0;
		this.items.forEach((cartItem) => {
			subTotal += cartItem.price;
		});
		return subTotal;
	}

	protected get showCartPanel() {
		return !this.collapsed && !this.headerCollapsed;
	}

	protected clickWidget() {
		if (!this.hasCartModule) {
			this.collapsed = !this.collapsed;
		} else {
			this.viewCart();
		}
	}

	protected removeItem(item: HeaderCartItems[number]) {
		this.$emit(
			'remove-item',
			item,
		);
	}

	protected viewCart() {
		this.$emit('view-cart');
	}
}
