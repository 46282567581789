import {
	AxiosRequestConfig,
} from 'axios';
import ajax from 'controllers/ajax';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import connector from 'controllers/connector';
import storage from 'controllers/storage';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as DialogService from 'services/dialog';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import { AppDataModule } from 'store';
import setCookie from 'tools/set-cookie';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import getDeviceDetails from '@root/js/ops/device-details';
import defaults from './defaults';

@Module({ namespaced: true, name: 'user' })
export default class Model extends VuexModule implements DB.UserModel {
	id: number | null = null;

	affiliateid = 0;

	loyaltygroupid: number | null = null;

	first_name = ''; // eslint-disable-line camelcase

	last_name = ''; // eslint-disable-line camelcase

	language: string|null = null;

	currency: string | null = null;

	timestamp = 0;

	email = '';

	gender = '';

	session_nr = 0; // eslint-disable-line camelcase

	session_time = 0; // eslint-disable-line camelcase

	temporary: number | null = null;

	countryid: number | null = null;

	unsubscribed: string | null = null;

	deleted: number | null = null;

	public get getState(): DB.UserModel {
		return JSON.parse(JSON.stringify(this.context.state));
	}

	public get urlRoot() {
		return '/api/user';
	}

	public get userId() {
		return this.id;
	}

	@Mutation
	private _reset(): void {
		Object.assign(
			this,
			JSON.parse(JSON.stringify(defaults.userModel)),
		);
	}

	@Mutation
	public set(payload: Partial<DB.UserModel>): void {
		let shouldFetchProductCategories = false;

		if (
			payload.countryid
			&& this.countryid !== payload.countryid
		) {
			shouldFetchProductCategories = true;
		} else if (
			payload.currency
			&& this.currency !== payload.currency
		) {
			shouldFetchProductCategories = true;
		} else if (
			payload.language
			&& this.language !== payload.language
		) {
			shouldFetchProductCategories = true;
		}

		Object.assign(
			this,
			payload,
		);

		if (shouldFetchProductCategories) {
			AppDataModule.fetchProductCategoriesData();
		}
	}

	@Mutation
	public setWithoutFetchProductCategoriesData(payload: Partial<DB.UserModel>): void {
		Object.assign(
			this,
			payload,
		);
	}

	@Action({ rawError: true })
	public fetch(options: {
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = {}): Promise<DB.UserModel> {
		return new Promise((resolve, reject) => {
			const { getters, commit } = this.context;
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'get',
				url: `${getters.urlRoot}/${this.id}`,
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				debug: {
					offline: true,
					dialog: true,
					abort: false,
				},
			};

			const requestOptions = options && options.requestOptions
				? merge(
					defaultRequestOptions,
					options.requestOptions,
				)
				: defaultRequestOptions;
			const methodOptions = options && options.methodOptions
				? merge(
					defaultMethodOptions,
					options.methodOptions,
				)
				: defaultMethodOptions;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					commit(
						'set',
						response.data,
					);
					resolve(getters.getState);
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	public async put({
		data,
		methodOptions,
		requestOptions,
	}: {
		data: Record<string, any> | null;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = {
		data: null,
	}): Promise<DB.UserModel> {
		if (!this.id) {
			return Promise.reject(new Error(ERRORS_INVALID_REQUEST_DATA));
		}

		if (data) {
			await this.setAndWaitForProductCategoriesData(data);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `${this.urlRoot}/${this.id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: JSON.parse(JSON.stringify(this.context.state)),
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.set(response.data);

				// User data payload in JWT is no longer up-to-date.
				// Get new JWT token from server
				return auth.refreshBearerToken();
			})
			.then(() => this.getState);
	}

	@Action({ rawError: true })
	public post({
		data,
		methodOptions,
		requestOptions,
	}: {
		data: Record<string, any> | null;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = { data: null }): Promise<DB.UserModel> {
		return new Promise((resolve, reject) => {
			const { commit, getters } = this.context;

			if (data) {
				commit(
					'set',
					data,
				);
			}

			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'post',
				url: getters.urlRoot,
				headers: {
					'content-type': 'application/json; charset=utf-8',
				},
				data: JSON.parse(JSON.stringify(this.context.state)),
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				retry: 1,
				debug: {
					offline: true,
					dialog: true,
					abort: false,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					commit(
						'set',
						response.data,
					);
					resolve(getters.getState);
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	public save(options: {
		data: Record<string, any> | null;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = {
		data: null,
	}): Promise<DB.UserModel> {
		const { dispatch } = this.context;

		if (this.id) {
			return dispatch(
				'put',
				options,
			);
		}
		return dispatch(
			'post',
			options,
		);
	}

	@Action({ rawError: true })
	public fetchLoyalty(): Promise<{
		discount_producttypes: DB.DiscountOfferingModel[]; // eslint-disable-line camelcase
		discount_country: DB.DiscountShippingModel[]; // eslint-disable-line camelcase
		discount: DB.DiscountModel;
		voucher: DB.DiscountVoucherModel;
	}> {
		const requestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${this.urlRoot}/${this.id}/loyaltydiscount`,
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		return ajax.request(
			requestOptions,
			methodOptions,
		).then((response) => response.data);
	}

	@Action({ rawError: true })
	public logout(): Promise<void> {
		const { commit, dispatch } = this.context;
		let deviceId: string | undefined;

		return getDeviceDetails()
			.then((deviceDetails) => {
				if (deviceDetails.deviceUUID) {
					deviceId = deviceDetails.deviceUUID;
				}
			})
			// Clear credentials in storage controller
			.finally(() => ajax.request(
				{
					url: '/api/auth/logout',
					params: {
						deviceid: deviceId,
					},
				},
				{
					auth: true,
				},
			))
			// Clear credentials in storage controller
			.finally(() => storage.clear(['Authorization']))
			.finally(() => {
				// Unset cookies
				if (storage.enabled.cookie) {
					setCookie(
						'userid',
						null,
						-1,
					);
					setCookie(
						'token',
						null,
						-1,
					);
				}

				// Clear sessions with external login systems
				connector.logout();

				// Unregister user identifier with analytics tracking
				analytics.logout();

				// Clear session storage
				window.sessionStorage.clear();

				// Reset user data
				return dispatch(
					'cartItems/reset',
					[],
					{ root: true },
				);
			})
			.finally(() => {
				commit(
					'userdata/reset',
					null,
					{ root: true },
				);
				commit('_reset');

				// Make the native app forget the refresh token
				if (window.glPlatform == 'native') {
					if (!window.webToNative) {
						throw new Error('Missing WebToNative on window');
					}

					window.webToNative.setRefreshToken('');
				}
			});
	}

	@Action({ rawError: true })
	public setAndWaitForProductCategoriesData(payload: Partial<DB.UserModel>): Promise<void> {
		let shouldFetchProductCategories = false;

		if (
			payload.countryid
			&& this.countryid !== payload.countryid
		) {
			shouldFetchProductCategories = true;
		} else if (
			payload.currency
			&& this.currency !== payload.currency
		) {
			shouldFetchProductCategories = true;
		} else if (
			payload.language
			&& this.language !== payload.language
		) {
			shouldFetchProductCategories = true;
		}

		this.setWithoutFetchProductCategoriesData(payload);

		if (shouldFetchProductCategories) {
			const closeLoader = DialogService.openLoaderDialog();

			return AppDataModule
				.fetchProductCategoriesData()
				.then(closeLoader);
		}

		return Promise.resolve();
	}
}
