import {
	callSafe,
	debounce,
} from '../utils/functions';

export type GetViewportWatchCallback = (viewport: Viewport) => void;

export interface Viewport {
	height: number;
	width: number;
}

export type ViewportType = 'full' | 'visible';

let preventVirtualKeyboardScroll = false;
// eslint-disable-next-line import/no-mutable-exports
let visibleViewport: Viewport = {
	height: Math.floor(window.visualViewport?.height || 0),
	width: Math.floor(window.visualViewport?.width || 0),
};
// eslint-disable-next-line import/no-mutable-exports
let fullViewport: Viewport = {
	width: Math.floor(
		Math.max(
			document.documentElement.clientWidth,
			window.innerWidth || 0,
		),
	),
	height: Math.floor(
		Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0,
		),
	),
};
const watchers: Record<ViewportType, GetViewportWatchCallback[]> = {
	full: [],
	visible: [],
};
const onViewportChangeDebounce = debounce(
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	onViewportChange,
	100,
);
// eslint-disable-next-line @typescript-eslint/no-use-before-define
const resizeObserver = new ResizeObserver(() => onViewportChange());
resizeObserver.observe(document.documentElement);

window.visualViewport?.addEventListener(
	'resize',
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	() => onViewportChangeDebounce(),
);
window.visualViewport?.addEventListener(
	'scroll',
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	() => onViewportChangeDebounce(),
);

function onViewportChange(): void {
	const currentFullViewport: Viewport = {
		width: Math.floor(
			Math.max(
				document.documentElement.clientWidth,
				window.innerWidth || 0,
			),
		),
		height: Math.floor(
			Math.max(
				document.documentElement.clientHeight,
				window.innerHeight || 0,
			),
		),
	};
	const currentVisibleViewport: Viewport = {
		height: Math.floor(window.visualViewport?.height || 0),
		width: Math.floor(window.visualViewport?.width || 0),
	};

	if (
		currentFullViewport.height !== fullViewport.height
		|| currentFullViewport.width !== fullViewport.width
	) {
		fullViewport = currentFullViewport;

		// eslint-disable-next-line no-restricted-syntax
		for (const watcher of watchers.full) {
			callSafe(() => watcher(currentFullViewport));
		}
	}

	if (
		currentVisibleViewport.height !== visibleViewport.height
		|| currentVisibleViewport.width !== visibleViewport.width
	) {
		visibleViewport = currentVisibleViewport;

		// eslint-disable-next-line no-restricted-syntax
		for (const watcher of watchers.visible) {
			callSafe(() => watcher(currentVisibleViewport));
		}
	}
}

function onWebAppScroll(event: TouchEvent): void {
	if (
		visibleViewport.height !== window.innerHeight
		|| visibleViewport.width !== window.innerWidth
	) {
		event.preventDefault();
	}
}

export function configureAppHeight(): void {
	if (document.documentElement.style.getPropertyValue('--app-height')) {
		return;
	}

	if (visibleViewport.height) {
		document.documentElement.style.setProperty(
			'--app-height',
			`${visibleViewport.height}px`,
		);
	} else {
		document.documentElement.style.removeProperty('--app-height');
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	watch(
		() => {
			if (visibleViewport.height) {
				document.documentElement.style.setProperty(
					'--app-height',
					`${visibleViewport.height}px`,
				);
			} else {
				document.documentElement.style.removeProperty('--app-height');
			}
		},
		'visible',
	);
}

export function preventScrollOnVirtualKeyboard(prevent = true): void {
	if (preventVirtualKeyboardScroll === prevent) {
		return;
	}

	preventVirtualKeyboardScroll = prevent;
	const webAppElement = document.getElementById('webapp');

	if (preventVirtualKeyboardScroll) {
		webAppElement?.addEventListener(
			'touchmove',
			onWebAppScroll,
		);
	} else {
		webAppElement?.removeEventListener(
			'touchmove',
			onWebAppScroll,
		);
	}
}

export function watch(
	callback: GetViewportWatchCallback,
	type: ViewportType = 'full',
): () => void {
	watchers[type].push(callback);

	return () => {
		const index = watchers[type].indexOf(callback);

		if (index > -1) {
			watchers[type].splice(
				index,
				1,
			);
		}
	};
}

export {
	fullViewport,
	visibleViewport,
};
