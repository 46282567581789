import './defines';
import { SelectMenuItem } from 'interfaces/app';
import {
	Vue,
	Component,
	Prop,
	Ref,
	Watch,
} from 'vue-property-decorator';
import { createPopper, Instance, Placement } from '@popperjs/core';
import Template from './template.vue';

@Component
export default class SelectMenuView extends Vue.extend(Template) {
	@Ref('menuDesktop')
	protected readonly menuDesktop!: HTMLDivElement;

	@Prop({ default: false, type: Boolean })
	public readonly open!: boolean;

	@Prop({ default: [], type: Array })
	public readonly menuItems!: SelectMenuItem[];

	@Prop({ required: true, type: Object })
	public readonly position!: ClientRect | DOMRect;

	@Prop({ default: 'auto', type: String })
	public readonly placement!: Placement;

	private popper: Instance | undefined;

	clickItem(item: SelectMenuItem) {
		if (item.target) {
			item.target();
		}
	}

	beforeDestroy() {
		if (this.popper) {
			this.popper.destroy();
		}
	}

	mounted() {
		const virtualElement = {
			getBoundingClientRect: () => this.position,
		};

		this.popper = createPopper(
			virtualElement,
			this.menuDesktop,
			{ placement: this.placement },
		);
	}

	@Watch('position')
	@Watch('show')
	updatePosition() {
		if (this.popper) {
			this.popper.update();
		}
	}
}
