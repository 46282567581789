import ProductState from 'classes/productstate';
import User from 'classes/user';
import navigate from 'controllers/navigate';
import * as DialogService from 'services/dialog';
import { UserModule } from 'store';

const performLogout = () => {
	ProductState.finalize(
		true,
		true,
	).finally(() => {
		UserModule
			.logout()
			.finally(() => {
				if (window.glPlatform == 'native') {
					if (!window.webToNative) {
						throw new Error('Missing WebToNative on window');
					}

					window.webToNative.identifyUser(
						null,
					);

					User.validate()
						.then(() => {
							navigate.toStart();
						});
				} else if (window.glPlatform == 'web') {
					// On web we do a nice clean restart to make sure all garbage disposal by Javascript was run
					const appUrl = `${window.location.protocol}//${window.location.host}`;
					window.location.href = appUrl;
				}
			});
	});
};

const logoutUser = () => {
	if (UserModule.temporary) {
		// Ask user for confirmation
		const closeConfirm = DialogService.openConfirmDialog({
			header: {
				title: window.App.router.$t('dialogHeaderLogout'),
			},
			body: {
				content: window.App.router.$t('dialogTextLogout'),
			},
			footer: {
				buttons: [
					{
						id: 'cancel',
						text: window.App.router.$t('dialogButtonCancel'),
						click: () => {
							closeConfirm();
						},
					},
					{
						id: 'confirm',
						text: window.App.router.$t('dialogButtonOk'),
						click: () => {
							performLogout();
							closeConfirm();
						},
					},
				],
			},
		});
	} else {
		performLogout();
	}
};

export default logoutUser;
