import {
	Vue,
	Component,
	Prop,
	Provide,
	Watch,
} from 'vue-property-decorator';
import * as PI from 'interfaces/project';
import getBucketUrl from '../tools/get-bucket-url';
import loadImage from '../services/load-image';
import DrawView from './draw';
import { ProductStateModule } from '../store/index';

@Component({
	components: {
		DrawView,
	},
})
export default class PageBackgroundView extends Vue {
	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: false, type: Boolean })
	readonly showBleed!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showMask!: boolean;

	// Allows any child component to `inject: ['canvas']` and have access to it.
	// This is the CanvasRenderingContext that children will draw to.
	@Provide('canvas')
		canvas: {
		context: CanvasRenderingContext2D|null;
	} = {
				context: null,
			};

	componentKey = 0;

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset ? this.pageModel.offset : 0;
	}

	get canvasHeight() {
		return Math.round((this.pageModel.height + 2 * this.bleedMargin) * this.scaling);
	}

	get canvasWidth() {
		return Math.round((this.pageModel.width + 2 * this.bleedMargin) * this.scaling);
	}

	@Watch('canvasWidth')
	@Watch('canvasHeight')
	onPageBackgroundViewCanvasSizeChanged() {
		// We need to manually trigger an update of the canvas drawing by changing the key
		// otherwise the drawing won't update when changing the canvas size (toggle bleed margin)
		this.componentKey += 1;
	}

	@Watch(
		'pageModel',
		{ deep: true },
	)
	onPageBackgroundViewPropsChange() {
		this.loadAssets();
	}

	created() {
		this.loadAssets();
	}

	mounted() {
		// We can't access the rendering context until the canvas is mounted to the DOM.
		// Once we have it, provide it to all child components.
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		this.canvas.context = canvas.getContext('2d');
	}

	loadAssets() {
		if (
			this.pageModel.bgimage
			&& this.pageModel.bgimage.length > 0
			&& !this.pageModel._bgimage
		) {
			this.loadAsset('bgimage');
		} else if (!this.pageModel.bgimage) {
			ProductStateModule.changePage({
				id: this.pageModel.id,
				_bgimage: null,
			});
		}

		if (
			this.pageModel.bgpattern
			&& this.pageModel.bgpattern.length > 0
			&& !this.pageModel._bgpattern
		) {
			this.loadAsset('bgpattern');
		} else if (!this.pageModel.bgpattern) {
			ProductStateModule.changePage({
				id: this.pageModel.id,
				_bgpattern: null,
			});
		}
	}

	loadAsset(type: 'bgimage'|'bgpattern') {
		let src = '';

		// if photo has not been downloaded, load from source
		const propertyValue = this.pageModel[type];
		if (propertyValue && propertyValue.substring(
			0,
			4,
		) == 'http') {
			src = propertyValue;
		} else if (propertyValue) {
			const bucketURL = getBucketUrl('appfiles');
			src = bucketURL + propertyValue;
		}

		const k = `_${type}` as '_bgimage'|'_bgpattern';
		const objChange: OptionalExceptFor<PI.PageModel, 'id'> = {
			id: this.pageModel.id,
		};

		loadImage(src).then(
			({ image }) => {
				objChange[k] = image;
				ProductStateModule.changePage(objChange);
			},
			() => {
			// Check if page background image reference has not already been removed
				if (this.pageModel[k]) {
				// Reset page background image reference
					objChange[k] = null;
					ProductStateModule.changePage(objChange);
				}
			},
		);
	}
}
