import ToolbarComponent from 'components/toolbar';
import {
	object as objectUtils,
	service as serviceUtils,
	vue as vueUtils,
} from 'utils';
import Vue, {
	VueConstructor,
	type VueConstructorExtended,
	VueExtended,
} from 'vue';

export { ServiceEvent } from 'services/service-event';

/* eslint-disable @typescript-eslint/indent */

export function openToolbar<
	BodyComponent extends VueConstructor<Vue>,
>(
	options: ServiceOptions<
		ToolbarComponent<BodyComponent>
	>,
): ServiceOpenReturn<
	ToolbarComponent<BodyComponent>
> {
	type ToolbarComponentType = ToolbarComponent<BodyComponent>;
	type ToolbarComponentTypeVue = VueExtended<ToolbarComponentType>;
	const defaultOptions: Omit<
		ServiceOptions<ToolbarComponentType>,
		'anchor' | 'distance'
	> = {
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: ToolbarComponentTypeVue;
	let fromCloseListener = false;
	const toolbarAPI: ServiceOpenReturn<ToolbarComponentTypeVue> = {
		api: {} as any,
		close() {
			if (!fromCloseListener) {
				instance.close(true);
			} else {
				fromCloseListener = false;
				toolbarAPI.destroy(false);
			}
		},
		destroy(force = true) {
			instance.$destroy(force);

			if (instance._isDestroyed) {
				instance.$el.remove();
			}
		},
		isOpen: false,
	};
	Object.defineProperty(
		toolbarAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return !instance._isDestroyed;
			},
		},
	);

	const finalOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeWithTransitionHandler = (immediate?: boolean) => {
		if (!immediate) {
			(instance.$el as HTMLElement).addEventListener(
				'transitionend',
				() => {
					toolbarAPI.close();
				},
				{
					once: true,
				},
			);
		}

		toolbarAPI.api.isOpen = false;

		if (immediate) {
			toolbarAPI.close();
		}
	};
	const closeListener = (event: ServiceEvent<boolean | undefined>) => {
		fromCloseListener = true;
		closeWithTransitionHandler(event.payload);
	};
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);

	finalOptions.listeners = listeners;

	instance = new ToolbarComponent({
		parent: options.parent || window.App.router,
		_parentListeners: listeners,
		propsData: finalOptions,
	});

	toolbarAPI.api = vueUtils.getInstanceAPI(instance);

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		vueUtils.createInstanceSlot(
			options.body.component as unknown as VueConstructorExtended,
			instance as Vue,
			'default',
			{
				props: options.body.props,
				listeners: options.body.listeners,
			},
		);
	}

	const instanceElement = document.createElement('div');
	const dialogsElement = document.getElementById('dialogs');
	const webAppElement = document.getElementById('webapp');

	if (dialogsElement?.parentElement) {
		dialogsElement.parentElement.insertBefore(
			instanceElement,
			dialogsElement,
		);
	} else if (webAppElement) {
		webAppElement.append(instanceElement);
	}

	instance.$mount(instanceElement);

	requestAnimationFrame(() => {
		toolbarAPI.api.isOpen = true;
	});

	return toolbarAPI;
}

/* eslint-enable @typescript-eslint/indent */

const ToolbarService = {
	openToolbar,
};

export default ToolbarService;
