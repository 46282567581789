import './defines';
import ButtonComponent from 'components/button';
import InputComponent from 'components/input';
import {
	EditorProductSettingsModel,
	EditorTopToolbarCurrencyModel,
} from 'interfaces/app';
import { ProductModel } from 'interfaces/database';
import { OfferingGroups } from 'settings/offerings';
import { mobile as mobileTools } from 'tools';
import EditorProductSettingsView from 'views/editor-product-settings';
import PriceView from 'views/price';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorTopToolbarView',
	components: {
		ButtonComponent,
		PriceView,
	},
})
export default class EditorTopToolbarView extends Vue.extend(Template) {
	@Model(
		'save',
		{
			description: 'Defines the product model to be edited',
			required: true,
			schema: 'ProductModel',
			type: Object,
		},
	)
	public readonly projectModel!: ProductModel;

	@Prop({
		required: true,
		description: 'Defines the currency model, used for the price view when the preview mode is active',
		schema: 'EditorTopToolbarCurrencyModel',
		type: Object,
	})
	public readonly currencyModel?: EditorTopToolbarCurrencyModel;

	@Prop({
		description: 'Defines the editor product settings model',
		schema: 'EditorProductSettingsModel',
		type: Object,
	})
	public readonly editorProductSettingsModel?: EditorProductSettingsModel;

	@Prop({
		description: 'Indicates if the active product supports multiple photos in the page',
		required: true,
		type: Boolean,
	})
	public readonly hasMultiplePhotoSupport!: boolean;

	@Prop({
		description: 'Indicates if the editor has a photo in the selected state',
		required: true,
		type: Boolean,
	})
	public readonly hasPhotoSelected!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the toolbar should show the compact and filled style',
		type: Boolean,
	})
	public readonly isCompactAndFilled?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the editor preview mode is active in which case some buttons are hidden and the back button text is different',
		type: Boolean,
	})
	public readonly isPreviewModeActive!: boolean;

	@Prop({
		description: 'Indicates if the redo button is enabled',
		required: true,
		type: Boolean,
	})
	public readonly isRedoEnabled!: boolean;

	@Prop({
		description: 'Indicates if the undo button is enabled',
		required: true,
		type: Boolean,
	})
	public readonly isUndoEnabled!: boolean;

	@Prop({
		default: 0,
		description: 'Defines the offering model price, used for the price view when the preview mode is active',
		type: Number,
	})
	public readonly offeringModelPrice?: number;

	@Prop({
		description: 'Indicates if the price view should be shown, used when the preview mode is active',
		required: true,
		type: Boolean,
	})
	public readonly showPricing!: boolean;

	@Prop({
		description: 'Indicates if the additional menu button should be shown',
		required: true,
		type: Boolean,
	})
	public readonly showAdditionalMenu!: boolean;

	protected get isProjectSinglePage(): boolean {
		if (
			this.projectModel
			&& OfferingGroups(
				this.projectModel.group,
				['BasicProducts'],
			)
		) {
			return true;
		}

		return false;
	}

	protected get projectModelHasOverview(): boolean {
		if (
			this.projectModel?.group
			&& OfferingGroups(
				this.projectModel?.group,
				[
					'BookTypes',
					'PageSets',
				],
			)
		) {
			return true;
		}

		return false;
	}

	@Ref('additionalMenuButton')
	private readonly additionalMenuButtonComponent!: ButtonComponent;

	private closeToolbarTooltip?: (() => void);

	private internalProjectModel: ProductModel = {} as ProductModel;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.closeToolbarTooltip?.();
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;

			if (this.closeToolbarTooltip) {
				this.closeToolbarTooltip();
				this.onAdditionalMenuClick();
			}
		});
	}

	@Watch(
		'projectModel',
		{
			immediate: true,
		},
	)
	protected onProjectModelChanged(): void {
		this.$nextTick(() => {
			this.internalProjectModel = this.projectModel;
		});
	}

	protected onAdditionalMenuClick(): void {
		type BodyComponent = typeof EditorProductSettingsView;
		const tooltipToolbarBody: (
			TooltipServiceOptionsBody<BodyComponent>
			| ToolbarServiceOptionsBody<BodyComponent>
		) = {
			component: EditorProductSettingsView,
			props: {
				settings: this.editorProductSettingsModel,
			},
			listeners: {
				change: (value: EditorProductSettingsModel) => {
					this.$emit(
						'product-settings-change',
						value,
					);
				},
			},
		};

		if (!this.isMobile) {
			this.closeToolbarTooltip = this.$openTooltip({
				anchor: this.additionalMenuButtonComponent.$el as HTMLElement,
				body: tooltipToolbarBody,
				borderRadius: 24,
				closeOnModalClick: true,
				distance: 16,
				hasCloseButton: false,
				initialPosition: 'bottom right',
				isModal: true,
				noArrow: true,
				theme: this.internalTheme,
				tooltipStyles: {
					backgroundColor: 'var(--background-primary)',
					padding: '24px 20px',
					width: '350px',
				},
			}).close;
		} else {
			this.closeToolbarTooltip = this.$openToolbar({
				body: tooltipToolbarBody,
				listeners: {
					close: () => {
						this.closeToolbarTooltip = undefined;
					},
				},
				theme: this.internalTheme,
			}).close;
		}
	}

	protected onBackClick(): void {
		this.$emit('back');
	}

	protected onCancelClick(): void {
		this.$emit('cancel');
	}

	protected onDoneAdjustingClick(): void {
		this.$emit('done-adjusting');
	}

	protected onDoneEditingClick(): void {
		this.$emit('done-editing');
	}

	protected onRedoClick(): void {
		this.$emit('redo');
	}

	protected onSaveClick(): void {
		let projectName = this.internalProjectModel.title;
		const {
			close: closeDialog,
			api: apiDialog,
		} = this.$openDialogNew({
			header: {
				title: this.$t('views.editorTopToolbar.saveProject.title'),
			},
			body: {
				component: InputComponent,
				props: {
					label: this.$t('views.editorTopToolbar.saveProject.inputLabel'),
					value: this.internalProjectModel.title || undefined,
				},
				listeners: {
					change: (value: string) => {
						projectName = value;
						const bodyComponent = apiDialog.bodyComponent();

						if (bodyComponent) {
							bodyComponent.value = projectName || undefined;
						}
					},
				},
			},
			footer: {
				buttons: [
					{
						id: 'save',
						text: this.$t('views.editorTopToolbar.saveProject.buttons.save'),
						click: () => {
							this.internalProjectModel.title = projectName;
							this.$emit(
								'save',
								this.internalProjectModel,
							);
							closeDialog();
						},
					},
				],
			},
			theme: 'light',
		});
	}

	protected onUndoClick(): void {
		this.$emit('undo');
	}
}
