import './defines';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorTextCTAButtonsView',
})
export default class EditorTextCTAButtonsView extends Vue.extend(Template) {
	@Prop({
		type: Boolean,
		default: false,
		description: 'Indicates if the done button is disabled',
	})
	public readonly doneDisabled!: boolean;

	protected onCancelClick(): void {
		this.$emit('cancel');
	}

	protected onDoneClick(): void {
		this.$emit('done');
	}
}
