import './defines';
import ProductState from 'classes/productstate';
import Theme from 'classes/theme';
import analytics from 'controllers/analytics';
import * as DB from 'interfaces/database';
import { OfferingGroups } from 'settings/offerings';
import {
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import ThemePickerItemView from '../picker-theme-item.vue';
import Template from './template.vue';

@Component({
	components: {
		ThemePickerItemView,
	},
})
export default class ThemePickerView extends Vue.extend(Template) {
	protected get collection() {
		const offeringModel = ProductStateModule.getOffering;

		if (!this.isLoading && offeringModel) {
			if (OfferingGroups(
				offeringModel.groupid,
				['Cards', 'Agendas'],
			)) {
				// Return themes from the same theme category
				const productModel = ProductStateModule.getProduct;
				if (productModel && productModel.themeid) {
					return ThemeDataModule.getThemesRelated(productModel.themeid);
				}
			}

			return _.sortBy(
				ThemeDataModule.getThemesByOfferingId(offeringModel.id),
				'categoryid',
			);
		}

		return [];
	}

	private get isLoading() {
		return !ThemeDataModule.getFetched;
	}

	protected created() {
		Theme.fetchData(false).then(() => {
			this.$emit('updateDialog');
		});
	}

	protected updated() {
		this.$emit('updateDialog');
	}

	private changeTheme(themeModel: DB.ThemeModel) {
		analytics.trackEvent(
			'Change Theme',
			{
				category: 'Button',
			},
		);

		const closeLoader = this.$openLoaderDialog();

		ProductState
			.changeTheme(
				themeModel.id,
				true,
				true,
			)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.finally(() => {
				closeLoader();
				this.close();
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	private close() {
		this.$emit('closeDialog');
	}

	protected imageLoaded() {
		this.$emit('updateDialog');
	}

	protected selectTheme(themeModel: DB.ThemeModel) {
		const productModel = ProductStateModule.getProduct;

		if (
			// Cards always change immediately, no need to ask for confirmation
			// Same for single pagers
			productModel
			&& OfferingGroups(
				productModel.group,
				['Cards', 'BasicProducts', 'PhotoFrameBox'],
			)
		) {
			this.changeTheme(themeModel);
		} else {
			const closeConfirm = this.$openConfirmDialog({
				header: {
					title: this.$t('dialogHeaderChangeTheme'),
				},
				body: {
					content: this.$t('dialogTextChangeTheme'),
				},
				footer: {
					buttons: [
						{
							id: 'confirm',
							text: this.$t('dialogButtonChangeThemeOk'),
							click: () => {
								this.changeTheme(themeModel);
								closeConfirm();
							},
						},
						{
							id: 'cancel',
							text: this.$t('dialogButtonChangeThemeCancel'),
							click: () => {
								closeConfirm();
							},
						},
					],
				},
			});
		}
	}
}
