import './defines';
import EventBus from 'components/event-bus';
import upload from 'controllers/upload';
import {
	ChannelModel,
	ImportLibraryPageObjects,
	ImportLibraryProductPhotos,
	ToolbarPhotoActiveView,
	ToolbarPhotoChannel,
	ToolbarPhotoPhotosQueued,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { MIME_TYPES_BROWSER_SUPPORTED } from 'settings/filetypes';
import { AppStateModule, ChannelsModule } from 'store';
import ImportLibraryView from 'views/import-library';
import ImportSelectView from 'views/import-select';
import CloudSelectorView from 'views/cloud-selector';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import connector, { ConnectorOptions } from 'controllers/connector';
import Template from './template.vue';

@Component({
	name: 'ToolbarPhotoView',
	components: {
		CloudSelectorView,
		ImportLibraryView,
		ImportSelectView,
	},
})
export default class ToolbarPhotoView extends Vue.extend(Template) {
	@Prop({
		acceptedValues: [
			'channels',
			'library',
		],
		default: undefined,
		description: 'Defines the active view of the toolbar, if not set, the toolbar will determine the active view based on if the library is set to show and if there are any product photos set.',
		event: 'active-view-change',
		schema: 'ToolbarPhotoActiveView',
		type: String,
	})
	public readonly activeView!: ToolbarPhotoActiveView;

	@Prop({
		acceptedValues: [
			null,
			'app',
			'dropbox',
			'facebook',
			'gplus',
			'instagram',
			'microsoft',
			'upload',
		],
		default: null,
		description: 'Defines the active channel of the toolbar, if any.',
		event: 'channel-change',
		schema: 'ToolbarPhotoChannel',
		type: String,
	})
	public readonly channelId!: ToolbarPhotoChannel | null;

	@Prop({
		required: true,
		description: 'The channels available to import photos from',
		type: Array,
	})
	public readonly importChannels!: ChannelModel[];

	@Prop({
		default: false,
		description: 'Indicates if the user can select multiple photos.',
		type: Boolean,
	})
	public readonly importMode!: boolean;

	@Prop({
		description: 'Indicates if the toolbar is visible.',
		required: true,
		type: Boolean,
	})
	public readonly isVisible!: boolean;

	@Prop({
		description: 'Type of product the user is creating',
		type: String,
		required: true,
	})
	public readonly offeringType!: DB.OfferingModel['type'];

	@Prop({
		description: 'Defines the objects that are associated with the current page.',
		required: true,
		schema: 'ImportLibraryPageObjects',
		type: Array,
	})
	public readonly pageObjects!: ImportLibraryPageObjects;

	@Prop({
		description: 'Defines the photos being queued for upload.',
		required: true,
		schema: 'ToolbarPhotoPhotosQueued',
		type: Array,
	})
	public readonly photosQueued!: ToolbarPhotoPhotosQueued;

	@Prop({
		description: 'Defines the maximum number of photos that can be uploaded (only for the native app) and indicates if the multiple photos can be selected for upload (only for the web app).',
		required: true,
		type: Number,
	})
	public readonly pickerMax!: number;

	@Prop({
		description: 'Indicates the state of the removeUnselected flag for the native app picker.',
		required: true,
		type: Boolean,
	})
	public readonly pickerRemoveUnselected!: boolean;

	@Prop({
		description: 'Indicates the state of the showSelection flag for the native app picker.',
		required: true,
		type: Boolean,
	})
	public readonly pickerShowSelection!: boolean;

	@Prop({
		description: 'Defines the photos that are associated with the current product.',
		required: true,
		schema: 'ImportLibraryProductPhotos',
		type: Array,
	})
	public readonly productPhotos!: ImportLibraryProductPhotos;

	@Prop({
		default: true,
		description: 'Indicates if the library is visible, the library will be shown only if there are product photos available, if not the channels view will be set as the active view.',
		type: Boolean,
	})
	public readonly showLibrary!: boolean;

	@Prop({
		description: 'Show the selected photo in a lightbox before submitting',
		required: true,
		type: Boolean,
	})
	public readonly showLightBox!: boolean;

	@Prop({
		description: 'Only show vector images',
		default: false,
		type: Boolean,
	})
	public readonly vectorOnly!: boolean;

	private depthLevel = 0;

	protected internalActiveView: ToolbarPhotoActiveView = 'library';

	private internalChannelId: string | null = null;

	protected beforeDestroy(): void {
		upload.changeCheckMaxPhotos(true);

		EventBus.$off(
			'upload submit',
			this.uploadSubmit,
		);
		EventBus.$off(
			'upload submitted',
			this.uploadSubmitted,
		);
		EventBus.$off(
			'external upload completed',
			this.externalUploadCompleted,
		);
	}

	protected created(): void {
		upload.changeCheckMaxPhotos(false);
		this.checkActiveView();

		if (this.offeringType === 'logo') {
			this.fetchLogos();
		}

		EventBus.$on(
			'upload submit',
			this.uploadSubmit,
		);
		EventBus.$on(
			'upload submitted',
			this.uploadSubmitted,
		);
		EventBus.$on(
			'external upload completed',
			this.externalUploadCompleted,
		);
	}

	@Watch(
		'activeView',
		{
			immediate: true,
		},
	)
	protected onActiveViewChange(): void {
		if (this.activeView) {
			this.$nextTick(() => {
				this.internalActiveView = this.activeView;
			});
		} else {
			this.checkActiveView();
		}
	}

	@Watch(
		'channelId',
		{
			immediate: true,
		},
	)
	protected onChannelIdChange(): void {
		this.$nextTick(() => {
			this.internalChannelId = this.channelId;
		});
	}

	@Watch('internalActiveView')
	protected onInternalActiveViewChange(): void {
		this.$emit(
			'active-view-change',
			this.internalActiveView,
		);
	}

	@Watch('internalChannelId')
	protected onInternalChannelIdChange(): void {
		this.$emit(
			'channel-change',
			this.internalChannelId,
		);
	}

	@Watch('photosQueued')
	protected onPhotosQueuedChange(
		newPhotosQueued: ToolbarPhotoPhotosQueued,
		oldPhotosQueued: ToolbarPhotoPhotosQueued,
	): void {
		if (newPhotosQueued.length !== oldPhotosQueued.length) {
			const newPhotoModelsAdded = newPhotosQueued
				.filter((newModel) => !oldPhotosQueued.find((oldModel) => oldModel.id === newModel.id));
			const [newPhotoModelAdded] = newPhotoModelsAdded;

			if (
				newPhotoModelAdded
				&& (
					newPhotoModelAdded.source === 'upload'
					|| window.glPlatform === 'native'
				)
				&& (
					typeof newPhotoModelAdded.id === 'number'
					|| (!newPhotoModelAdded._vectorize && newPhotoModelAdded._type && MIME_TYPES_BROWSER_SUPPORTED.includes(newPhotoModelAdded._type))
				)
			) {
				this.onPhotoSelect(
					newPhotoModelAdded,
					!this.showLightBox,
				);
			}
		}
	}

	@Watch('isVisible')
	protected onVisibilyChange(): void {
		if (!this.isVisible) {
			this.$nextTick(() => {
				this.internalChannelId = null;
				this.checkActiveView();
			});
		}
	}

	private checkActiveView(): void {
		if (
			!this.showLibrary
			|| (
				this.productPhotos.length === 0
				&& this.offeringType === 'photo'
				&& window.glPlatform !== 'native'
			)
		) {
			this.internalActiveView = 'channels';
		} else if (
			this.showLibrary
			&& !this.internalChannelId
		) {
			this.internalActiveView = 'library';
		}
	}

	private externalUploadCompleted(photoIds: PI.PhotoModel['id'][]): void {
		if (this.showLibrary) {
			this.internalActiveView = 'library';
		}

		this.$emit(
			'externalUploadCompleted',
			photoIds,
		);
	}

	private fetchLogos(
		nextPage: string | null = null,
	): void {
		const channelModel = ChannelsModule.getById('app');
		if (channelModel) {
			const options: ConnectorOptions = {
				category: 'logo',
			};
			if (nextPage) {
				options.nextPage = nextPage;
			}

			// Fetch the photos of this user
			connector.photos(
				'app',
				options,
			).then(
				({ paging }) => {
					if (!this._isDestroyed
						&& paging?.nextPage
					) {
						this.fetchLogos(paging.nextPage);
					}
				},
			);
		}
	}

	protected onAddMorePhotosClick(): void {
		if (this.importChannels.length === 0) {
			this.openFilePicker();
		} else {
			this.internalActiveView = 'channels';
		}
	}

	protected onBackButtonClick(): void {
		if (!this.internalChannelId) {
			this.onCloseButtonClick();
		} else if (this.depthLevel === 0) {
			this.internalChannelId = null;
		} else {
			this.depthLevel -= 1;
		}
	}

	protected onChannelClick(channelId: ToolbarPhotoChannel): void {
		if (channelId === 'upload') {
			this.openFilePicker();
		} else if (channelId === 'qr') {
			upload.showQRCode();
		} else {
			this.internalChannelId = channelId;
		}
	}

	private onCloseButtonClick(): void {
		this.$emit('close');
	}

	protected onIncreaseLevel(): void {
		this.depthLevel += 1;
	}

	protected onLibraryViewItemClick(photoModel: PI.PhotoModel): void {
		this.$emit(
			'openLightBox',
			photoModel,
		);
	}

	private onPhotoSelect(
		photoModel: PI.PhotoModel,
		skipLightBox?: boolean,
	): void {
		this.$emit(
			'selectPhoto',
			photoModel,
			skipLightBox,
		);
	}

	private openFilePicker(): void {
		if (window.glPlatform === 'native') {
			if (window.nativeToWeb) {
				window.nativeToWeb.pickerDismissed = () => {
					console.log('No action required on native picker dismiss');
				};
			}

			if (AppStateModule.nativeObjectLocation.location) {
				upload.filePicker(
					this.pickerMax,
					{
						objectLocation: AppStateModule.nativeObjectLocation,
						removeUnselected: this.pickerRemoveUnselected,
						showSelection: this.pickerShowSelection,
					},
				);
			} else {
				upload.filePicker(
					this.pickerMax,
					{
						removeUnselected: this.pickerRemoveUnselected,
						showSelection: this.pickerShowSelection,
					},
				);
			}
		} else {
			upload.filePicker(
				this.pickerMax,
				{
					removeUnselected: this.pickerRemoveUnselected,
					showSelection: this.pickerShowSelection,
				},
			);
		}
	}

	protected onResetLevel(): void {
		this.depthLevel = 0;
	}

	private uploadSubmit(batchSize: number): void {
		if (batchSize === 1) {
			this.$openLoaderDialog();
		}

		if (this.showLibrary) {
			this.internalActiveView = 'library';
		}
	}

	private uploadSubmitted(): void {
		this.$closeLoaderDialog();

		if (!this.showLibrary) {
			this.$emit('uploadSubmitted');
		}
	}
}
