import TooltipComponent from 'components/tooltip';
import {
	closeAuthDialog,
	closeLoaderDialog,
	openAlertDialog,
	openAuthDialog,
	openConfirmDialog,
	openDialog,
	openDialogNew,
	openErrorDialog,
	openLoaderDialog,
	openProgressDialog,
	openPromptDialog,
} from 'services/dialog';
import { openToolbar } from 'services/toolbar';
import { openTooltip } from 'services/tooltip';
import {
	VueConstructorExtended,
	VNode,
} from 'vue';
import { PropOptions } from 'vue/types/options';
import {
	Vue,
	VueExtended,
} from 'vue/types/vue';
import { callSafe } from './functions';

const destroySymbol = Symbol('destroy-symbol');

export function bindComponentProps(
	component: VueExtended<any> | VNode,
	props: Record<string, any>,
): void {
	const propsKeys = Object.keys(props);

	// eslint-disable-next-line no-restricted-syntax
	for (const prop of propsKeys) {
		const descriptor = Object.getOwnPropertyDescriptor(
			props,
			prop,
		);

		if (descriptor?.set) {
			const originalSet = descriptor.set;
			Object.defineProperty(
				props,
				prop,
				{
					...descriptor,
					set(value) {
						originalSet.call(
							this,
							value,
						);

						if (
							'componentInstance' in component
							&& component.componentInstance
						) {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							setComponentProp(
								component.componentInstance,
								prop,
								value,
							);
						} else {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							setComponentProp(
								component as VueExtended<any>,
								prop,
								value,
							);
						}
					},
				},
			);
		}
	}
}

/**
 * Configure/create .onDestroy	() hook for Vue components
 * to be able to listen on Vue instance when it's going to be destroyed.
 * @param vue The Vue constructor
 */
export function configureDestroy(
	vue: typeof Vue,
): void {
	if (vue.prototype.onDestroy) {
		return;
	}

	vue.prototype._destroyPrevented = false;
	const originalDestroy = vue.prototype.$destroy;
	vue.prototype.$destroy = function $destroy(
		this: Vue,
		force?: boolean,
		...args: any[]
	) {
		if (
			this._destroyPrevented
			&& !force
		) {
			this._destroyPrevented = false;
			return;
		}

		const result = this[destroySymbol];

		if (result) {
			if (typeof result === 'function') {
				callSafe(result);
			} else {
				result.forEach(callSafe);
			}
		}

		originalDestroy.apply(
			this,
			...args,
		);
	};
	vue.prototype.onDestroy = function onDestroy(this: Vue, fn: (...args: any[]) => any) {
		if (this._isDestroyed) {
			callSafe(fn);
			return;
		}

		const result = this[destroySymbol];

		if (result) {
			if (typeof result === 'function') {
				this[destroySymbol] = [this[destroySymbol], fn];
			} else {
				this[destroySymbol].push(fn);
			}
		} else {
			this[destroySymbol] = fn;
		}
	};
}

export function configureDialogService(
	vue: typeof Vue,
): void {
	if (
		'$openDialog' in vue.prototype
		&& '$openDialogNew' in vue.prototype
		&& '$openLoaderDialog' in vue.prototype
		&& '$closeLoaderDialog' in vue.prototype
	) {
		return;
	}

	if (!vue.prototype.$closeAuthDialog) {
		vue.prototype.$closeAuthDialog = function $closeAuthDialog(
			this: Vue,
		) {
			return closeAuthDialog();
		} as typeof closeAuthDialog;
	}
	if (!vue.prototype.$closeLoaderDialog) {
		vue.prototype.$closeLoaderDialog = function $closeLoaderDialog(
			this: Vue,
		) {
			return closeLoaderDialog();
		} as typeof closeLoaderDialog;
	}
	if (!vue.prototype.$openAlertDialog) {
		vue.prototype.$openAlertDialog = function $openAlertDialog(
			this: Vue,
			options,
		) {
			return openAlertDialog({
				...(options || {}),
				parent: this,
			});
		} as typeof openAlertDialog;
	}
	if (!vue.prototype.$openAuthDialog) {
		vue.prototype.$openAuthDialog = function $openAuthDialog(
			this: Vue,
			options,
		) {
			return openAuthDialog({
				...options,
				parent: this,
			});
		} as typeof openAuthDialog;
	}
	if (!vue.prototype.$openConfirmDialog) {
		vue.prototype.$openConfirmDialog = function $openConfirmDialog(
			this: Vue,
			options,
		) {
			return openConfirmDialog({
				...(options || {}),
				parent: this,
			});
		} as typeof openConfirmDialog;
	}
	if (!vue.prototype.$openDialog) {
		vue.prototype.$openDialog = function $openDialog(
			this: Vue,
			options,
		) {
			return openDialog({
				...options,
				parent: this,
			});
		} as typeof openDialog;
	}
	if (!vue.prototype.$openDialogNew) {
		vue.prototype.$openDialogNew = function $openDialogNew(
			this: Vue,
			options,
		) {
			return openDialogNew({
				...options,
				parent: this,
			});
		} as typeof openDialogNew;
	}
	if (!vue.prototype.$openErrorDialog) {
		vue.prototype.$openErrorDialog = function $openErrorDialog(
			this: Vue,
			options,
		) {
			return openErrorDialog({
				...(options || {}),
				parent: this,
			});
		} as typeof openErrorDialog;
	}
	if (!vue.prototype.$openLoaderDialog) {
		vue.prototype.$openLoaderDialog = function $openLoaderDialog(
			this: Vue,
			options,
		) {
			return openLoaderDialog({
				...(options || {}),
				parent: this,
			});
		} as typeof openLoaderDialog;
	}
	if (!vue.prototype.$openProgressDialog) {
		vue.prototype.$openProgressDialog = function $openProgressDialog(
			this: Vue,
			options,
		) {
			return openProgressDialog({
				...options,
				parent: this,
			});
		} as typeof openProgressDialog;
	}
	if (!vue.prototype.$openPromptDialog) {
		vue.prototype.$openPromptDialog = function $openPromptDialog(
			this: Vue,
			options,
		) {
			return openPromptDialog({
				...options,
				parent: this,
			});
		} as typeof openPromptDialog;
	}
}

export function configureToolbarService(
	vue: typeof Vue,
): void {
	if ('$openToolbar' in vue.prototype) {
		return;
	}

	vue.prototype.$openToolbar = function $openToolbar(
		this: Vue,
		options,
	) {
		return openToolbar({
			...options,
			parent: this,
		});
	} as typeof openToolbar;
}

export function configureTooltipService(
	vue: typeof Vue,
): void {
	if ('$openTooltip' in vue.prototype) {
		return;
	}

	vue.prototype.$openTooltip = function $openTooltip(
		this: Vue,
		options,
	) {
		return openTooltip({
			...options,
			parent: this,
		});
	} as typeof openTooltip;
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	configureTooptipInstance(vue);
}

/**
 * Configure/create _isVueClass flag to determine if the class is Vue class or not.
 * @param vue The Vue constructor
 */
export function configureFlagPrototype(
	vue: typeof Vue,
): void {
	if ('_isVueClass' in vue.prototype) {
		return;
	}

	Object.defineProperty(
		vue.prototype,
		'_isVueClass',
		{
			get() {
				return true;
			},
		},
	);
}

export function configureForceCompute(
	vue: typeof Vue,
): void {
	if ('$forceCompute' in vue.prototype) {
		return;
	}

	vue.prototype.$forceCompute = function $forceCompute(
		this: Vue,
		computedName: string,
		forceUpdate = true,
	) {
		if (this._computedWatchers[computedName]) {
			this._computedWatchers[computedName].run();

			if (forceUpdate) {
				this.$forceUpdate();
			}
		}
	};
}

export function configureTheme(
	vue: typeof Vue,
): void {
	if ('theme' in vue.prototype) {
		return;
	}

	vue.mixin({
		props: {
			theme: {
				acceptedValues: [
					'auto',
					'dark',
					'light',
				],
				default: 'auto',
				description: 'Defines the theme of the component',
				event: 'theme-change',
				type: String,
			},
		},
		data() {
			return {
				internalTheme: 'auto',
				parentThemeWatch: undefined,
			};
		},
		beforeDestroy(this: Vue): void {
			this.parentThemeUnwatch?.();
		},
		created(this: Vue): void {
			if (this.theme === 'auto') {
				if (
					!this.$parent
					|| this.$parent.internalTheme === 'auto'
				) {
					this.internalTheme = (
						window.matchMedia('(prefers-color-scheme: dark)').matches
							? 'dark'
							: 'light'
					);
				}

				if (this.$parent) {
					this.parentThemeUnwatch = this.$parent.$watch(
						'internalTheme',
						() => {
							if (this.$parent
								&& this.$parent.internalTheme !== 'auto'
							) {
								this.internalTheme = this.$parent.internalTheme;
							}
						},
						{
							immediate: true,
						},
					);
				}
			}
		},
		updated(this: Vue): void {
			if (
				this.$el
				&& 'classList' in this.$el
			) {
				if (
					this.internalTheme === 'dark'
					&& !this.$el.classList.contains('dark')
				) {
					this.$el.classList.add('dark');
				} else if (
					this.internalTheme === 'light'
					&& !this.$el.classList.contains('light')
				) {
					this.$el.classList.add('light');
				}
			}
		},
		methods: {
			toggleTheme(): void {
				this.internalTheme = (
					this.internalTheme === 'dark'
						? 'light'
						: 'dark'
				);
			},
		},
		// eslint-disable-next-line vue/order-in-components
		watch: {
			internalTheme: {
				handler: function onInternalThemeChange(
					this: Vue,
					_,
					oldValue,
				): void {
					if (this.internalTheme !== this.theme) {
						this.$emit(
							'theme-change',
							this.internalTheme,
						);
					}

					const executionTime = Date.now();
					const changeThemeInterval = setInterval(
						() => {
							if (
								this.$el
								&& 'classList' in this.$el
							) {
								if (oldValue === 'dark') {
									this.$el.classList.remove('dark');
								} else if (oldValue === 'light') {
									this.$el.classList.remove('light');
								}

								if (this.internalTheme === 'light') {
									this.$el.classList.add('light');
								} else {
									this.$el.classList.add('dark');
								}

								clearInterval(changeThemeInterval);
							} else if ((Date.now() - executionTime) > (10 * 1000)) {
								clearInterval(changeThemeInterval);
							}
						},
					);
				},
			},
			theme: {
				immediate: true,
				handler: function onThemeChange(
					this: Vue,
					newVaValue: 'auto' | 'dark' | 'light',
					oldValue?: 'auto' | 'dark' | 'light',
				): void {
					this.internalTheme = this.theme;

					if (
						oldValue
						&& oldValue !== 'auto'
					) {
						this.$nextTick(() => {
							this.parentThemeUnwatch?.();
						});
					}
				},
			},
		},
	});
}

export function configureTooptipInstance(
	vue: typeof Vue,
): void {
	if ('$tooltipInstance' in vue.prototype) {
		return;
	}

	vue.mixin({
		beforeCreate(this: Vue) {
			if (this.$parent instanceof TooltipComponent) {
				this.$tooltipInstance = this.$parent;
			}
		},
	});
}

export function createElement<
	Component extends VueConstructorExtended<Vue>,
	Props extends PublicOptionalNonFunctionProps<InstanceType<Component>>,
>(
	options: VueUtilsCreateElementOptions<Component, Props>,
): VNode {
	/* eslint-disable @typescript-eslint/indent, @typescript-eslint/ban-types */
	let listeners: (
		Record<
			string,
			Function[] | Function
		>
		| undefined
	);
	let elementVNode!: VNode;

	if (options.data?.props) {
		const componentProps = options.component.options.props as Record<string, PropOptions> | undefined;
		const componentModel = options.component.options.model;

		if (componentProps) {
			listeners = Object
				.keys(componentProps)
				.reduce(
					(returnListeners, propKey) => {
						const componentProp = componentProps?.[propKey];
						const propListener = (propValue: any) => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							setComponentProp(
								elementVNode.componentInstance as InstanceType<Component>,
								propKey as keyof InstanceType<Component>,
								propValue,
							);
						};

						if (componentProp?.event) {
							returnListeners[componentProp.event] = propListener;
						} else if (
							componentModel?.prop === propKey
							&& componentModel.event
						) {
							returnListeners[componentModel.event] = propListener;
						}

						return returnListeners;
					},
					{} as Record<string, Function[] | Function>,
				);
		}
	}
	if (options.data?.on) {
		if (
			listeners
			&& Object.keys(listeners).length > 0
		) {
			const dataListeners = options.data.on;
			listeners = Object
				.keys(dataListeners)
				.reduce(
					(returnListeners, listenerKey) => {
						let dataListener: Function[] | Function | undefined = dataListeners[listenerKey];
						const currentListener = listeners?.[listenerKey];

						if (
							dataListener
							&& !Array.isArray(dataListener)
						) {
							dataListener = [dataListener];
						} else if (!dataListener) {
							dataListener = [];
						}

						if (
							currentListener
							&& !Array.isArray(currentListener)
						) {
							returnListeners[listenerKey] = [
								currentListener,
								...dataListener,
							];
						} else if (currentListener) {
							returnListeners[listenerKey] = [
								...currentListener,
								...dataListener,
							];
						} else if (dataListener.length > 0) {
							returnListeners[listenerKey] = dataListener;
						}

						return returnListeners;
					},
					listeners,
				);
		} else {
			listeners = options.data.on;
		}
	}

	elementVNode = options.parent.$createElement(
		options.component,
		{
			props: options.data?.props,
			on: listeners,
			hook: options.data?.hook,
		},
		options.children,
	);

	return elementVNode;
	/* eslint-enable @typescript-eslint/indent, @typescript-eslint/ban-types */
}

export function createInstance<
	Component extends VueConstructorExtended<Vue>,
	Props extends PublicOptionalNonFunctionProps<InstanceType<Component>>,
>(
	options: {
		component: Component,
		parent?: Vue,
		props?: Props,
	},
): InstanceType<Component> {
	let listeners: Record<string, (...args: any[]) => any> | undefined;
	let instance!: InstanceType<Component>;

	if (options.props) {
		const componentProps = options.component.options.props as Record<string, PropOptions> | undefined;
		const componentModel = options.component.options.model;

		if (componentProps) {
			listeners = Object
				.keys(componentProps)
				.reduce(
					(returnListeners, propKey) => {
						const componentProp = componentProps?.[propKey];
						const propListener = (propValue: any) => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							setComponentProp(
								instance,
								propKey as keyof InstanceType<Component>,
								propValue,
							);
						};

						if (componentProp?.event) {
							returnListeners[componentProp.event] = propListener;
						} else if (
							componentModel?.prop === propKey
							&& componentModel.event
						) {
							returnListeners[componentModel.event] = propListener;
						}

						return returnListeners;
					},
					{} as Record<string, (...args: any[]) => any>,
				);
		}
	}

	// eslint-disable-next-line new-cap
	instance = new options.component({
		propsData: options.props,
		parent: options.parent,
		_parentListeners: listeners,
	}) as InstanceType<Component>;

	return instance;
}

/* eslint-disable @typescript-eslint/indent */

export function createInstanceSlot<
	Component extends VueConstructorExtended<Vue>,
	Props extends PublicOptionalNonFunctionObservableProps<InstanceType<Component>>,
>(
	component: Component,
	parent: Vue,
	slotName: string,
	options?: {
		props?: Props,
		listeners?: Record<
			string,
			((...args: any[]) => any)
			| ((...args: any[]) => any)[]
		>,
	},
): VNode {
	const elementOptions: VueUtilsCreateElementOptions<Component, Props> = {
		component,
		parent,
	};

	if (options?.props) {
		elementOptions.data = {
			props: options.props,
		};
	}
	if (options?.listeners) {
		elementOptions.data = elementOptions.data || {};
		elementOptions.data.on = options.listeners;
	}

	const vNode = createElement(elementOptions);
	parent.$slots[slotName] = [vNode];

	if (options?.props?.__ob__) {
		bindComponentProps(
			vNode,
			options.props,
		);
	}

	return vNode;
}

export function getComponentProps<Component extends VueConstructorExtended<Vue>>(component: Component): Record<string, PropOptions<any>> | undefined {
	let props: Record<string, PropOptions<any>> | undefined;

	if (component.options.props) {
		props = component.options.props as Record<string, PropOptions> | undefined;
	}

	if (component.super) {
		const superProps = getComponentProps(component.super);

		if (superProps) {
			props = {
				...superProps,
				...props,
			};
		}
	}

	return props;
}

/* eslint-enable @typescript-eslint/indent */

export function getInstanceAPI<Component extends VueExtended<any>>(
	instance: Component,
): NonVuePublicProps<Component> {
	const instanceProps = Object.keys(instance.$options.props || {}) as (keyof PublicNonFunctionProps<Component>)[];
	const instanceMethods = Object.keys(instance.$options.methods || {}) as (keyof PublicFunctionProps<Component>)[];

	return ([] as (keyof NonVuePublicProps<Component>)[])
		.concat(instanceProps)
		.concat(instanceMethods)
		.reduce(
			(api, key) => {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				const keyType = getTypeOfMember(
					instance,
					key,
				);

				if (keyType === 'prop') {
					Object.defineProperty(
						api,
						key,
						{
							enumerable: true,
							get() {
								return instance[key];
							},
							set(value) {
								if (process.env.NODE_ENV === 'production') {
									// @ts-ignore
									instance[key] = value;
								} else {
									// eslint-disable-next-line @typescript-eslint/no-use-before-define
									setComponentProp(
										instance,
										key,
										value,
									);
								}
							},
						},
					);
				} else if (
					keyType === 'method'
					&& instance.$options.methods?.[key].public
				) {
					const instanceMethod = instance[key] as (...args: any[]) => any;
					Object.defineProperty(
						api,
						key,
						{
							enumerable: true,
							value(...args: any[]) {
								return instanceMethod.apply(
									instance,
									args,
								);
							},
						},
					);
				}

				return api;
			},
			{} as NonVuePublicProps<Component>,
		);
}

export function getTypeOfMember<Component extends VueExtended<any>>(
	component: Component,
	key: keyof Component,
): 'method' | 'prop' | false {
	if (
		component.$options.props
		&& key in component.$options.props
	) {
		return 'prop';
	}
	if (
		component.$options.methods
		&& key in component.$options.methods
	) {
		return 'method';
	}

	return false;
}

/**
 * Set a component prop without triggering Vue warnings.
 * This is intended for when the component instance where created
 * programatically and the parent component is the one actually
 * setting the prop and not the component itself.
 * @param component The Vue component instance to set the prop to.
 * @param prop The prop to set.
 * @param value The value to set the prop to.
 */
export function setComponentProp<
	Component extends VueExtended<any>,
	Prop extends keyof Component,
	Value extends Component[Prop],
>(
	component: Component,
	prop: Prop,
	value: Value,
): void {
	/**
	 * Save the original console.error() function
	 */
	const originalConsoleError = console.error;
	/**
	 * Override console.error() to avoid Vue warnings shown in the console
	 */
	console.error = function error(errorData: any, ...args: any[]) {
		if (
			typeof errorData === 'string'
			&& errorData.startsWith('[Vue warn]: Avoid mutating')
		) {
			return;
		}

		/**
		 * Call the original console.error() function if the error is not a Vue warning
		 */
		originalConsoleError.call(
			console,
			errorData,
			...(args || []),
		);
	};

	/**
	 * Set the prop
	 */
	component[prop] = value;
	/**
	 * Restore the original console.error() function
	 */
	console.error = originalConsoleError;
}
