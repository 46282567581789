var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'subNavigation',
		{
			fixed: _vm.fixedPosition,
			expanded: _vm.showProjectContent,
		}
	]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"containerContent"},[_c('div',{staticClass:"leftHalf"},[_c('div',{staticClass:"logo",on:{"click":_vm.onLogoClick}}),_vm._v(" "),(_vm.showStatus && _vm.showStatusHeader)?_c('StatusView',{staticClass:"statusContainer",attrs:{"is-online":_vm.isOnline,"offering-model":_vm.offeringModel,"product-model":_vm.productModel,"show-save-options":_vm.statusShowSaveOptions,"show-send-email":_vm.statusShowProjectSendEmail,"status-error":_vm.projectStatusError,"status-saved":_vm.projectStatusSaved,"status-syncing":_vm.projectStatusSyncing,"user-model":_vm.userModel},on:{"close-save-options":_vm.onCloseSaveOptions,"project-email-sent":_vm.onProjectEmailSent,"project-save":_vm.onProjectSave,"send-project-email":_vm.onProjectSendEmail,"project-finalize":_vm.onProjectFinalize}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"rightHalf"},[(_vm.hasNext)?_c('div',{class:[
						'next',
						'noUserSelect',
						{ hideMobile: _vm.hideNextMobile }
					],on:{"click":_vm.onNextClick}},[_c('button',{staticClass:"nextButton"},[_vm._t("default")],2)]):(_vm.hasPrice && _vm.isAvailableInRegion)?_c('div',{class:[
						'price',
						'noUserSelect' ]},[_c('div',{staticClass:"priceWrapper"},[(_vm.productPrice.productPriceFrom && _vm.productPrice.productPriceFrom != _vm.productPrice.productPrice)?_c('span',{staticClass:"discountInfo"},[_c('PriceView',{attrs:{"cents":_vm.productPrice.productPriceFrom}})],1):_vm._e(),_vm._v(" "),_c('span',{class:['priceFromValue', { discounted: Boolean(_vm.productPrice.productPriceFrom) }],on:{"click":function($event){$event.stopPropagation();return _vm.onPriceClick.apply(null, arguments)}}},[_c('PriceView',{attrs:{"cents":_vm.productPrice.productPrice}}),_vm._v(" "),_c('sub',{class:[
									'material-icons',
									{ expanded: _vm.showProjectContent }
								]},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.showProjectContent ? 'expand_less' : 'expand_more')+"\n\t\t\t\t\t\t\t")])],1)])]):_vm._e()])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProjectContent),expression:"showProjectContent"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.onHideProjectContent),expression:"onHideProjectContent"}],staticClass:"projectContent"},[_c('div',{staticClass:"header"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('inThisProject'))+"\n\t\t\t")]),_vm._v(" "),_vm._l((_vm.projectContent),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_vm._v(" "),_c('div',{staticClass:"text"},[_c('div',{staticClass:"primaryLine"},[_c('span',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"quantity"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.quantity)+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"secondaryLine"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.subscript)+"\n\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"price"},[(item.priceFrom)?_c('span',{staticClass:"discountInfo"},[_c('PriceView',{attrs:{"cents":item.priceFrom}})],1):_vm._e(),_vm._v(" "),(item.price)?_c('span',{class:['currentPrice', { discounted: Boolean(item.priceFrom) }]},[_c('PriceView',{attrs:{"cents":item.price}})],1):_vm._e()])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }