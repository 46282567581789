import { render, staticRenderFns } from "./template.vue?vue&type=template&id=3b313134&scoped=true&"
var script = {}
import style0 from "./template.vue?vue&type=style&index=0&id=3b313134&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b313134",
  null
  
)

export default component.exports