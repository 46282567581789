import './defines';
import * as colorUtils from '@sosocio/frontend-utils/color';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import PageObject from 'classes/pageobject';
import {
	EditorCanvasOfferingDepthModel,
	EditorCanvasPageObjectsModel,
	EditorInteractiveInteractionTemplatePhotoModel,
	EditorInteractiveInteractionTemplateTextModel,
	EditorInteractivePageObjectModel,
	EditorModulePageTemplatePositions,
	EditorTextOptionsActiveMode,
	OfferingFrameModel,
	SVGColorReplacement,
} from 'interfaces/app';
import {
	AddressModel,
	OfferingModel,
} from 'interfaces/database';
import {
	PageModel,
	PageObjectModel,
} from 'interfaces/project';
import { OfferingGroups } from 'settings/offerings';
import {
	COVER_BACK,
	COVER_BACK_INSIDE,
	COVER_FRONT,
	COVER_FRONT_INSIDE,
} from 'settings/values';
import { PhotosModule } from 'store';
import * as svgTextHelper from 'store/modules/productstate/helpers/svg-text';
import { mobile as mobileTools } from 'tools';
import getColorInverse from 'tools/get-color-inverse';
import { object as objectUtils } from 'utils';
import EditorDrawView from 'views/editor-draw';
import EditorInteractiveView from 'views/editor-interactive-view';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorCanvasView',
	components: {
		EditorDrawView,
		EditorInteractiveView,
	},
})
export default class EditorCanvasView extends Vue.extend(Template) {
	@Prop({
		default: undefined,
		schema: 'AddressModel',
		type: Object,
	})
	public readonly addressModel?: AddressModel;

	@Prop({
		default: false,
		description: 'Indicates if the overlay is hidden, even if the offering has an overlay',
		type: Boolean,
	})
	public readonly hideOverlay!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the eye dropper is shown, in which case the pointer events for the interactive component are disabled',
		event: 'eye-dropper-shown-change',
		type: Boolean,
	})
	public readonly isEyeDropperShown?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the editor canvas is interactive, meaning that the interactive component is enabled and the user can interact with the objects in the canvas, adding, editing and deleting them',
		type: Boolean,
	})
	public readonly isInteractive!: boolean;

	@Prop({
		description: 'Indicates if the photo object is transformable (resizable and rotatable)',
		required: true,
		type: Boolean,
	})
	public readonly isPhotoObjectTransformable!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly isTextOverlayActive!: boolean;

	@Prop({
		description: 'Indicates if the active product supports multiple photos in the page',
		required: true,
		type: Boolean,
	})
	public readonly multiplePhotoSupport!: boolean;

	@Prop({
		description: 'Indicates if the active product supports multiple texts in the page',
		required: true,
		type: Boolean,
	})
	public readonly multipleTextSupport!: boolean;

	@Prop({
		default: '#webapp',
		type: [HTMLElement, String],
	})
	public readonly objectActionsAnchor!: HTMLElement | string;

	@Prop({
		default: undefined,
		schema: 'OfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly pageIndex!: number;

	@Prop({
		event: 'page-model-change',
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		default: () => ([]),
		event: 'page-objects-change',
		schema: 'EditorCanvasPageObjectsModel',
		type: Array,
	})
	public readonly pageObjects!: EditorCanvasPageObjectsModel;

	@Prop({
		default: () => ([]),
		schema: 'EditorModulePageTemplatePositions',
		type: Array,
	})
	public readonly pageTemplatePositions!: EditorModulePageTemplatePositions;

	@Prop({
		default: () => ([]),
		schema: 'EditorModulePageTemplatePositions',
		type: Array,
	})
	public readonly pageTemplatePositionsAvailable!: EditorModulePageTemplatePositions;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly scaling!: number;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showAddress!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showBleed!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showDepth!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showEditorOfferingFrame!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showMessage!: boolean;

	@Prop({
		default: 1,
		description: 'Defines the zoom level of the editor canvas',
		type: Number,
	})
	public readonly zoomLevel!: number;

	@Prop({
		default: 1,
		description: 'Defines the maximum zoom level of the editor canvas',
		type: Number,
	})
	public readonly zoomMaxLevel!: number;

	private get bleedMargin(): number {
		return (
			(
				this.showBleed
				&& this.pageModel.offset
			)
				? this.pageModel.offset
				: 0
		);
	}

	protected get bleedMarginStyles(): Partial<CSSStyleDeclaration> {
		const pageModelHeight = this.pageModel.height + (this.bleedMargin * 2);
		const pageModelWidth = this.pageModel.width + (this.bleedMargin * 2);

		return {
			boxShadow: `inset 0 0 0 ${this.bleedMargin * this.computedScaling}px rgba(255, 0, 0, 0.2)`,
			height: `${Math.round(pageModelHeight * this.computedScaling)}px`,
			width: `${Math.round(pageModelWidth * this.computedScaling)}px`,
		};
	}

	private get canvasHeight(): number {
		if (!this.offeringFrameHeight) {
			return this.pageModel.height + (this.bleedMargin * 2);
		}

		return this.offeringFrameHeight;
	}

	private get canvasWidth(): number {
		if (!this.offeringFrameWidth) {
			return this.pageModel.width + (this.bleedMargin * 2);
		}

		return this.offeringFrameWidth;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			'editor-canvas-view-is-eye-dropper-shown': this.internalIsEyeDropperShown,
			'editor-canvas-view-is-interactive': this.isInteractive,
		};
	}

	private get computedObjectActionsAnchor(): HTMLElement | null {
		if (typeof this.objectActionsAnchor === 'string') {
			return document.querySelector(this.objectActionsAnchor);
		}
		if (this.objectActionsAnchor) {
			return this.objectActionsAnchor;
		}

		return document.getElementById('webapp');
	}

	protected get computedScaling(): number {
		if (
			this.showEditorOfferingFrame
			&& this.offeringFrameModel?.required
			&& !this.hideOverlay
		) {
			const scaledFrameHeight = this.offeringFrameModel.templateModel.height * this.scaling;
			const scaledFrameWidth = this.offeringFrameModel.templateModel.width * this.scaling;

			return Math.min(
				scaledFrameWidth / this.pageModel.width,
				scaledFrameHeight / this.pageModel.height,
			);
		}

		return this.scaling;
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> {
		return {
			height: `${this.scaledCanvasHeight}px`,
			width: `${this.scaledCanvasWidth}px`,
		};
	}

	protected get drawViewsContainerStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			!this.showEditorOfferingFrame
			|| !this.offeringFrameModel
			|| this.hideOverlay
		) {
			styles.height = `${Math.round((this.pageModel.height + (this.bleedMargin * 2)) * this.scaling)}px`;
			styles.left = '0';
			styles.top = '0';
			styles.width = `${Math.round((this.pageModel.width + (this.bleedMargin * 2)) * this.scaling)}px`;
		} else {
			const { height } = this.offeringFrameModel.templateModel;
			const left = this.offeringFrameModel.templateModel.x;
			const top = this.offeringFrameModel.templateModel.y;
			const { width } = this.offeringFrameModel.templateModel;

			styles.height = `${Math.round(height * this.scaling)}px`;
			styles.left = `${Math.round(left * this.scaling)}px`;
			styles.top = `${Math.round(top * this.scaling)}px`;
			styles.width = `${Math.round(width * this.scaling)}px`;
		}

		return styles;
	}

	protected get editorDrawViewClasses(): (objectModel: PageObjectModel) => Record<string, boolean> {
		return (objectModel) => ({
			'editor-draw-page-object-view-cropping-modus': !!objectModel._crop,
			'editor-draw-page-object-view-photo': objectModel.type === 'photo',
			'editor-draw-page-object-view-selected-for-edition': !!objectModel._selectedForEdition,
			'editor-draw-page-object-view-selected': !!objectModel._selected,
			'editor-draw-page-object-view-text': objectModel.type === 'text',
		});
	}

	protected get editorDrawViewOverlayClasses(): Record<string, boolean> {
		return {
			'editor-draw-overlay-view-overpage': !!this.offeringFrameModel?.overpage,
		};
	}

	protected get flippedOverlay() {
		if (!this.offeringModel) {
			return false;
		}

		if (this.pageModel.id === COVER_BACK) {
			return true;
		}
		if (this.pageModel.id === COVER_FRONT) {
			return false;
		}
		if (this.pageModel.id === COVER_FRONT_INSIDE) {
			return true;
		}
		if (this.pageModel.id === COVER_BACK_INSIDE) {
			return false;
		}

		return !!(OfferingGroups(
			this.offeringModel.groupid,
			['BookTypes', 'PhotoSheets'],
		) && (this.pageIndex + this.offeringModel.startright) % 2 === 0);
	}

	protected get getDrawViewObjects(): (objectModel: PageObjectModel) => EditorCanvasPageObjectsModel {
		return (objectModel) => {
			if (
				objectModel.text_svg
				&& this.isInteractive
			) {
				return [];
			}
			if (
				objectModel._vectorSVG
				&& !!objectModel._selected
				&& this.isInteractive
			) {
				return [];
			}

			return [objectModel];
		};
	}

	protected get internalPageObjectsOrdered(): EditorCanvasPageObjectsModel {
		return this.internalPageObjects.sort((a, b) => {
			if (a.z_axis < b.z_axis) {
				return -1;
			}
			if (a.z_axis > b.z_axis) {
				return 1;
			}

			return 1;
		});
	}

	protected get internalPageObjectSVGAttributes(): (pageObject: PageObjectModel) => Partial<CSSStyleDeclaration> {
		return (pageObject) => {
			const styles: Partial<CSSStyleDeclaration> = {};

			if (
				!pageObject.text_svg
				&& !pageObject._vectorSVG
			) {
				return styles;
			}

			const tmpDivElement = document.createElement('div');
			tmpDivElement.innerHTML = (
				pageObject.text_svg
				|| pageObject._vectorSVG
				|| ''
			);
			const svgElement = tmpDivElement.firstChild as SVGElement;
			const svgAttributes = Array
				.from(svgElement.attributes)
				.reduce(
					(attributes, attribute) => {
						attributes[attribute.name] = attribute.value;

						return attributes;
					},
					{} as Record<string, string>,
				);

			if (
				pageObject._vectorSVG
				&& pageObject.photoid
			) {
				const photoModel = PhotosModule.getById(pageObject.photoid);

				if (
					pageObject.height
					&& pageObject.width
				) {
					svgAttributes.height = String(pageObject.height);
					svgAttributes.width = String(pageObject.width);
				}

				if (
					pageObject.x_axis
					|| pageObject.y_axis
				) {
					styles.transform = `translate(${pageObject.x_axis || 0}px, ${pageObject.y_axis || 0}px)`;
				}

				if (
					(
						typeof pageObject.cropx !== 'undefined'
						|| typeof pageObject.cropy !== 'undefined'
						|| typeof pageObject.cropwidth !== 'undefined'
						|| typeof pageObject.cropheight !== 'undefined'
					)
					&& photoModel
				) {
					const photoSizeFactor = (photoModel.width || photoModel.full_width) / photoModel.full_width;

					const viewBoxX = (
						pageObject.cropx
						|| 0
					) * photoSizeFactor;
					const viewBoxY = (
						pageObject.cropy
						|| 0
					) * photoSizeFactor;
					const viewBoxWidth = (
						pageObject.cropwidth
						|| pageObject.maxwidth
					) * photoSizeFactor;
					const viewBoxHeight = (
						pageObject.cropheight
						|| pageObject.maxheight
					) * photoSizeFactor;
					svgAttributes.viewBox = `${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`;
				}
			}

			if (svgAttributes.style) {
				svgAttributes.style
					.split(';')
					.reduce(
						(stylesObject, style) => {
							const [
								key,
								value,
							] = style
								.split(':')
								.map((part) => part.trim());

							(stylesObject as any)[key] = value;

							return stylesObject;
						},
						styles,
					);
			}

			styles.transform = `${styles.transform || ''} scale(${this.computedScaling})`;
			styles.transformOrigin = '0 0';

			svgAttributes.style = Object
				.entries(styles)
				.map(([key, value]) => `${key}: ${value};`)
				.join(' ');

			tmpDivElement.remove();

			return svgAttributes;
		};
	}

	protected get internalPageObjectSVGClasses(): (pageObject: PageObjectModel) => Record<string, boolean> {
		return (pageObject) => ({
			'editor-draw-page-object-view-photo-object-svg': pageObject.type === 'photo',
			'editor-draw-page-object-view-text-object-svg': pageObject.type === 'text',
		});
	}

	protected get internalPageObjectSVGContainerClasses(): (pageObject: PageObjectModel) => Record<string, boolean> {
		return (pageObject) => ({
			'editor-draw-page-object-view-photo-object-svg-container': pageObject.type === 'photo',
			'editor-draw-page-object-view-text-object-svg-container': pageObject.type === 'text',
		});
	}

	protected get internalPageObjectSVGContainerStyles(): (pageObject: PageObjectModel) => Partial<CSSStyleDeclaration> {
		return (pageObject) => {
			const styles: Partial<CSSStyleDeclaration> = {};

			if (
				!pageObject.text_svg
				&& !pageObject._vectorSVG
			) {
				return styles;
			}

			const bleedMargin = (
				this.showBleed
					? this.bleedMargin
					: 0
			);
			styles.height = `${Math.round(pageObject.height * this.computedScaling)}px`;
			styles.left = `${Math.round((bleedMargin + pageObject.x_axis) * this.computedScaling)}px`;
			styles.pointerEvents = 'none';
			styles.position = 'absolute';
			styles.top = `${Math.round((bleedMargin + pageObject.y_axis) * this.computedScaling)}px`;
			styles.transform = `rotate(${pageObject.rotate}deg)`;
			styles.width = `${Math.round(pageObject.width * this.computedScaling)}px`;

			return styles;
		};
	}

	protected get internalPageObjectSVGHTML(): (pageObject: PageObjectModel) => string {
		return (pageObject) => {
			const tmpDivElement = document.createElement('div');
			let svgElementInnerHTML = '';

			if (
				pageObject.text_formatted
				|| pageObject.text_formatted_for_canvas
			) {
				const normalizedTextFormatted = svgTextHelper.normalizeTextForSelection(pageObject.text_formatted || '');
				const normalizedTextFormattedForCanvas = svgTextHelper.normalizeTextForSelection(pageObject.text_formatted_for_canvas || '');

				/**
				 * Create a new SVG content without the hyphens
				 * and replacing the spaces with non-breaking spaces
				 * at the end of the text.
				 */
				tmpDivElement.innerHTML = svgTextHelper.createText({
					...pageObject,
					text_formatted: normalizedTextFormatted,
					text_formatted_for_canvas: normalizedTextFormatted,
				});
				let svgElement = tmpDivElement.firstChild as SVGElement;
				svgElementInnerHTML = svgElement.innerHTML;

				if (
					typeof pageObject._selectionEnd === 'number'
					&& typeof pageObject._selectionStart === 'number'
					&& typeof pageObject._caretLine === 'number'
					&& pageObject._selectionEnd !== -1
					&& pageObject._selectionStart !== -1
				) {
					tmpDivElement.setAttribute(
						'style',
						'left: 0; position: absolute; top: 0; visibility: hidden; pointer-events: none;',
					);
					tmpDivElement.innerHTML = svgTextHelper.createText({
						...pageObject,
						text_formatted: normalizedTextFormatted,
						text_formatted_for_canvas: normalizedTextFormattedForCanvas,
					});
					document.body.appendChild(tmpDivElement);
					svgElement = tmpDivElement.firstChild as SVGElement;

					try {
						svgTextHelper.setSelection(
							pageObject,
							svgElement,
							{
								caretLine: pageObject._caretLine,
								start: pageObject._selectionStart,
								end: pageObject._selectionEnd,
							},
							{
								renderCaret: true,
								renderSelection: true,
								scaling: this.computedScaling,
							},
						);
					} catch (error: any) {
						console.error(error);

						if (typeof window.glBugsnagClient !== 'undefined') {
							const clonedTextObjectModel = {
								...pageObject,
							};
							window.glBugsnagClient.notify(
								error,
								(event) => {
									event.addMetadata(
										'textObjectModel',
										clonedTextObjectModel,
									);
									event.severity = 'error';
								},
							);
						}
					}

					const svgSelectionElements = Array.from(svgElement.querySelectorAll('.svg-selection'));
					const svgCaretElement = svgElement.querySelector('.svg-caret');
					const svgStyleElement = svgElement.getElementsByTagName('style')[0];
					svgElement.innerHTML = svgElementInnerHTML;

					if (svgSelectionElements.length) {
						svgElement.prepend(...svgSelectionElements);
					}

					if (svgCaretElement) {
						svgElement.prepend(svgCaretElement);
					}

					if (svgStyleElement) {
						svgElement.prepend(svgStyleElement);
					}

					svgElementInnerHTML = svgElement.innerHTML;
				}
			} else if (
				pageObject._vectorSVG
				&& pageObject._vectorColors
			) {
				let svgContent = pageObject._vectorSVG;
				const colorReplacement: SVGColorReplacement[] = [];

				if (pageObject.colorReplacement) {
					// eslint-disable-next-line no-restricted-syntax
					for (const colorToReplace of pageObject.colorReplacement) {
						colorReplacement.push({
							color: colorToReplace.color,
							replace: (
								colorToReplace.replace.visual
								|| colorToReplace.replace.real
							),
						});
					}
				}

				if (colorReplacement.length) {
					svgContent = svgUtils.replaceColors(
						svgContent,
						pageObject._vectorColors,
						colorReplacement,
					);
				}

				tmpDivElement.innerHTML = svgContent;
				const svgElement = tmpDivElement.firstChild as SVGElement;
				svgElementInnerHTML = svgElement.innerHTML;
			}

			tmpDivElement.remove();

			return svgElementInnerHTML;
		};
	}

	protected get isInternalPageObjectSVGContainer(): (pageObject: PageObjectModel) => boolean {
		return (pageObject) => {
			if (
				pageObject.text_svg
				&& this.isInteractive
			) {
				return true;
			}

			if (
				pageObject.type === 'photo'
				&& !!pageObject._selected
				&& pageObject.ext === 'svg'
			) {
				return true;
			}

			return false;
		};
	}

	protected get objectStyle(): (objectModel: PageObjectModel) => Partial<CSSStyleDeclaration> {
		return (objectModel) => {
			const vars = PageObject.calculatePosition(
				{
					x_axis: objectModel.x_axis,
					y_axis: objectModel.y_axis,
					width: objectModel.width,
					height: objectModel.height,
					borderwidth: objectModel.borderwidth,
					bordercolor: objectModel.bordercolor,
					cropx: objectModel.cropx,
					cropy: objectModel.cropy,
					cropwidth: objectModel.cropwidth,
					cropheight: objectModel.cropheight,
					angle: objectModel.rotate,
					flop: Boolean(objectModel.flop),
					flip: Boolean(objectModel.flip),
					type: objectModel.type,
					maxwidth: objectModel.maxwidth,
					photoid: objectModel.photoid || undefined,
				},
				this.computedScaling,
				this.pageModel,
				this.bleedMargin,
				this.offeringModel,
			);
			const styles: Partial<CSSStyleDeclaration> = {
				left: `${vars.canvas.left}px`,
				top: `${vars.canvas.top}px`,
			};

			/**
			 * Check if the object is a offering overlay in which case
			 * it must be placed above the other objects.
			 */
			if (
				objectModel.type === 'photo'
				&& !objectModel.photoid
			) {
				styles.zIndex = '1';
			}

			return styles;
		};
	}

	private get offeringFrameHeight(): number {
		if (
			!this.showEditorOfferingFrame
			|| !this.offeringFrameModel
			|| this.hideOverlay
		) {
			return 0;
		}

		return this.offeringFrameModel.imageModel.height;
	}

	protected get offeringFrameImageUrl(): string | undefined {
		if (!this.offeringFrameModel?.imageModel) {
			return undefined;
		}

		return `${this.offeringFrameModel.imageModel.url}?noCorsHeader`;
	}

	protected get offeringFrameStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (this.offeringFrameModel?.overpage) {
			styles.zIndex = '2';
		}

		return styles;
	}

	private get offeringFrameWidth(): number {
		if (
			!this.showEditorOfferingFrame
			|| !this.offeringFrameModel
			|| this.hideOverlay
		) {
			return 0;
		}

		return this.offeringFrameModel.imageModel.width;
	}

	protected get offeringModelDepths(): EditorCanvasOfferingDepthModel[] {
		if (
			!this.offeringModel?.depth
			|| !this.showDepth
		) {
			return [];
		}

		const pageModelHeight = this.pageModel.height + (this.bleedMargin * 2);
		const pageModelWidth = this.pageModel.width + (this.bleedMargin * 2);
		const depthPlusBleedMargin = this.offeringModel.depth + this.bleedMargin;
		const scaledDepthPlusBleedMargin = Math.round(depthPlusBleedMargin * this.computedScaling);
		let depthColor = this.pageModel.bgcolor || '#ffffff';

		if (
			depthColor.toLowerCase() === '#ffffff'
			|| depthColor.toLowerCase() === '#fff'
		) {
			depthColor = '#c4c4c4';
		}

		return Array
			.from(Array(4))
			.reduce(
				(offeringModelDepths, _, depthNumber) => {
					const offeringModelDepth: EditorCanvasOfferingDepthModel = {} as EditorCanvasOfferingDepthModel;

					if (
						depthNumber === 0
						|| depthNumber === 2
					) {
						/**
						 * Top and bottom
						 */
						offeringModelDepth.height = scaledDepthPlusBleedMargin;
						offeringModelDepth.width = Math.round((pageModelWidth - (depthPlusBleedMargin * 2)) * this.computedScaling);
					} else if (
						depthNumber === 1
						|| depthNumber === 3
					) {
						/**
						 * Right and left
						 */
						offeringModelDepth.height = Math.round((pageModelHeight - (depthPlusBleedMargin * 2)) * this.computedScaling);
						offeringModelDepth.width = scaledDepthPlusBleedMargin;
					}

					if (depthNumber === 0) {
						/**
						 * Top
						 */
						offeringModelDepth.styles = {
							height: `${offeringModelDepth.height}px`,
							left: `${scaledDepthPlusBleedMargin}px`,
							top: '0px',
							width: `${offeringModelDepth.width}px`,
						};
						offeringModelDepth.cornerStartStyles = {
							left: `-${scaledDepthPlusBleedMargin}px`,
							top: '0px',
						};
						offeringModelDepth.cornerEndStyles = {
							right: `-${scaledDepthPlusBleedMargin}px`,
							top: '0px',
						};
					} else if (depthNumber === 1) {
						/**
						 * Right
						 */
						offeringModelDepth.styles = {
							height: `${offeringModelDepth.height}px`,
							right: '0px',
							top: `${scaledDepthPlusBleedMargin}px`,
							width: `${offeringModelDepth.width}px`,
						};
						offeringModelDepth.cornerStartStyles = {
							right: '0px',
							top: `-${scaledDepthPlusBleedMargin}px`,
						};
						offeringModelDepth.cornerEndStyles = {
							right: '0px',
							bottom: `-${scaledDepthPlusBleedMargin}px`,
						};
					} else if (depthNumber === 2) {
						/**
						 * Bottom
						 */
						offeringModelDepth.styles = {
							bottom: '0px',
							height: `${offeringModelDepth.height}px`,
							left: `${scaledDepthPlusBleedMargin}px`,
							width: `${offeringModelDepth.width}px`,
						};
						offeringModelDepth.cornerStartStyles = {
							left: `-${scaledDepthPlusBleedMargin}px`,
							bottom: '0px',
						};
						offeringModelDepth.cornerEndStyles = {
							right: `-${scaledDepthPlusBleedMargin}px`,
							bottom: '0px',
						};
					} else if (depthNumber === 3) {
						/**
						 * Left
						 */
						offeringModelDepth.styles = {
							height: `${offeringModelDepth.height}px`,
							left: '0px',
							top: `${scaledDepthPlusBleedMargin}px`,
							width: `${offeringModelDepth.width}px`,
						};
						offeringModelDepth.cornerStartStyles = {
							left: '0px',
							top: `-${scaledDepthPlusBleedMargin}px`,
						};
						offeringModelDepth.cornerEndStyles = {
							left: '0px',
							bottom: `-${scaledDepthPlusBleedMargin}px`,
						};
					}

					offeringModelDepth.styles['--depth-color'] = depthColor;
					offeringModelDepth.cornerEndStyles.height = `${scaledDepthPlusBleedMargin}px`;
					offeringModelDepth.cornerEndStyles.width = `${scaledDepthPlusBleedMargin}px`;
					offeringModelDepth.cornerStartStyles.height = `${scaledDepthPlusBleedMargin}px`;
					offeringModelDepth.cornerStartStyles.width = `${scaledDepthPlusBleedMargin}px`;
					offeringModelDepths.push(offeringModelDepth);

					return offeringModelDepths;
				},
				[] as EditorCanvasOfferingDepthModel[],
			);
	}

	protected get photoObjects(): EditorCanvasPageObjectsModel {
		return this.internalPageObjects.filter((objectModel) => objectModel.type === 'photo');
	}

	private get scaledCanvasHeight(): number {
		return Math.round(this.canvasHeight * this.scaling);
	}

	private get scaledCanvasWidth(): number {
		return Math.round(this.canvasWidth * this.scaling);
	}

	protected get scaledOfferingFrameHeight(): number {
		return Math.round(this.offeringFrameHeight * this.scaling);
	}

	protected get scaledOfferingFrameWidth(): number {
		return Math.round(this.offeringFrameWidth * this.scaling);
	}

	protected get selectedObjectActionsStyles(): Partial<CSSStyleDeclaration> {
		if (
			!this.$el
			|| !this.selectedObjectForEditionElement
			|| !this.selectedObjectActionsElement
		) {
			return {
				top: '0',
			};
		}

		const elementRect = this.$el.getBoundingClientRect();
		const selectedObjectForEditionElementRect = this.selectedObjectForEditionElement.getBoundingClientRect();
		const selectedObjectActionsElementRect = this.selectedObjectActionsElement.getBoundingClientRect();
		let distance: number;
		let left = 0;
		let top = 0;

		if (!mobileTools.isMobile) {
			distance = 16;
		} else {
			distance = 24;
		}

		if (!mobileTools.isMobile) {
			left = selectedObjectForEditionElementRect.left + (selectedObjectForEditionElementRect.width / 2) - (selectedObjectActionsElementRect.width / 2);
		} else {
			left = elementRect.left + (elementRect.width / 2) - (selectedObjectActionsElementRect.width / 2);
		}

		if (!this.objectActionsAnchor) {
			top = selectedObjectForEditionElementRect.top + selectedObjectForEditionElementRect.height + distance;
		} else if (this.computedObjectActionsAnchor) {
			const anchorRect = this.computedObjectActionsAnchor.getBoundingClientRect();
			top = anchorRect.bottom - selectedObjectActionsElementRect.height - distance;
		}

		return {
			top: `${top}px`,
			left: `${left}px`,
		};
	}

	private get selectedObjectForEdition(): EditorInteractivePageObjectModel | undefined {
		return this.internalPageObjects.find((object) => !!object._selectedForEdition);
	}

	protected get shouldShowSelectedObjectActions(): boolean {
		const { selectedObjectForEdition } = this;

		if (!selectedObjectForEdition) {
			return false;
		}

		if (this.offeringModel.type === 'logo') {
			return true;
		}

		if (
			selectedObjectForEdition.type === 'photo'
			&& this.multiplePhotoSupport
		) {
			return true;
		}

		if (
			selectedObjectForEdition.type === 'text'
			&& this.multipleTextSupport
		) {
			return true;
		}

		return false;
	}

	protected get showOverlay(): boolean {
		if (this.hideOverlay) {
			return false;
		}

		if (this.offeringModel?.overlay) {
			return true;
		}

		if (
			this.offeringFrameModel?.required
			&& this.offeringFrameModel?.overpage
			&& !this.offeringModel?.mask
		) {
			return true;
		}

		return false;
	}

	@Ref('interactiveView')
	protected readonly interactiveViewComponent!: EditorInteractiveView;

	@Ref('mainCanvas')
	protected readonly mainCanvasComponent!: EditorDrawView;

	@Ref('selectedObjectActions')
	protected readonly selectedObjectActionsElement!: HTMLDivElement;

	private canvasElement: HTMLCanvasElement = document.createElement('canvas');

	private internalIsEyeDropperShown = false;

	private internalPageObjects: EditorCanvasPageObjectsModel = [];

	protected inverseBackgroundColor = getColorInverse(this.pageModel?.bgcolor || '#FFFFFF');

	private resizeObserver?: ResizeObserver;

	protected selectedObjectBorderColor = 'var(--primary1)';

	private selectedObjectForEditionElement: HTMLElement | null = null;

	private selectedObjectForEditionElementMutationObserver?: MutationObserver;

	protected beforeDestroy(): void {
		this.resizeObserver?.disconnect();
		this.resizeObserver = undefined;
		this.selectedObjectActionsElement?.remove();
		this.selectedObjectForEditionElementMutationObserver?.disconnect();
		window.removeEventListener(
			'click',
			this.onWindowClick,
		);
	}

	protected mounted(): void {
		this.resizeObserver = new ResizeObserver(() => {
			if (this.selectedObjectForEditionElement) {
				this.$forceCompute('selectedObjectActionsStyles');
			}
		});
		this.onPageModelOfferingFrameModelChange();
		this.canvasElement = this.mainCanvasComponent.getCanvasElement();
		this.$emit(
			'canvas-ready',
			this.canvasElement,
		);
	}

	@Watch(
		'isEyeDropperShown',
		{
			immediate: true,
		},
	)
	protected onIsEyeDropperShownChange(): void {
		this.internalIsEyeDropperShown = this.isEyeDropperShown || false;
	}

	@Watch(
		'offeringFrameModel',
		{
			deep: true,
		},
	)
	@Watch(
		'pageModel',
		{
			deep: true,
		},
	)
	protected onPageModelOfferingFrameModelChange(): void {
		if (
			!this.offeringFrameModel?.required
			&& this.pageModel
			&& this.$el
		) {
			const backgroundColor = this.pageModel.bgcolor || '#FFFFFF';
			const neutral4GreyColor = getComputedStyle(this.$el).getPropertyValue('--neutral4-grey');
			const white1Color = getComputedStyle(this.$el).getPropertyValue('--white1');
			const contrastNeutral4GreyColor = colorUtils.getContrastRatio(
				neutral4GreyColor,
				backgroundColor,
			);
			const contrastWhite1Color = colorUtils.getContrastRatio(
				white1Color,
				backgroundColor,
			);

			if (contrastNeutral4GreyColor < contrastWhite1Color) {
				this.inverseBackgroundColor = white1Color;
			} else {
				this.inverseBackgroundColor = neutral4GreyColor;
			}

			const primary1Color = getComputedStyle(this.$el).getPropertyValue('--primary1');
			const primary2Color = getComputedStyle(this.$el).getPropertyValue('--primary2');
			const contrastPrimary1Color = colorUtils.getContrastRatio(
				primary1Color,
				backgroundColor,
			);
			const contrastPrimary2Color = colorUtils.getContrastRatio(
				primary2Color,
				backgroundColor,
			);

			if (contrastPrimary1Color < contrastPrimary2Color) {
				this.selectedObjectBorderColor = primary2Color;
			} else {
				this.selectedObjectBorderColor = primary1Color;
			}
		} else if (
			this.offeringFrameModel?.required
			&& this.pageModel
			&& this.$el
		) {
			const { offeringFrameModel } = this;
			const image = new Image();
			image.crossOrigin = 'Anonymous';
			image.onload = () => {
				const dominantColor = colorUtils.getDominant({
					backgroundColor: this.pageModel.bgcolor || '#FFFFFF',
					region: {
						x: offeringFrameModel.templateModel.x,
						y: offeringFrameModel.templateModel.y,
						width: offeringFrameModel.templateModel.width,
						height: offeringFrameModel.templateModel.height,
					},
					sourceImage: image,
				});
				const neutral4GreyColor = getComputedStyle(this.$el).getPropertyValue('--neutral4-grey');
				const white1Color = getComputedStyle(this.$el).getPropertyValue('--white1');
				const contrastNeutral4GreyColor = colorUtils.getContrastRatio(
					neutral4GreyColor,
					dominantColor,
				);
				const contrastWhite1Color = colorUtils.getContrastRatio(
					white1Color,
					dominantColor,
				);

				if (contrastNeutral4GreyColor < contrastWhite1Color) {
					this.inverseBackgroundColor = white1Color;
				} else {
					this.inverseBackgroundColor = neutral4GreyColor;
				}

				const primary1Color = getComputedStyle(this.$el).getPropertyValue('--primary1');
				const primary2Color = getComputedStyle(this.$el).getPropertyValue('--primary2');
				const contrastPrimary1Color = colorUtils.getContrastRatio(
					primary1Color,
					dominantColor,
				);
				const contrastPrimary2Color = colorUtils.getContrastRatio(
					primary2Color,
					dominantColor,
				);

				if (contrastPrimary1Color < contrastPrimary2Color) {
					this.selectedObjectBorderColor = primary2Color;
				} else {
					this.selectedObjectBorderColor = primary1Color;
				}
			};
			image.src = `${this.offeringFrameModel.imageModel.url}?noCorsHeader`;
		}
	}

	@Watch(
		'pageObjects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPageObjectsChange(): void {
		const newObjects = this.pageObjects;
		const oldObjects = this.internalPageObjects;
		const objectsToRemove: EditorCanvasPageObjectsModel = [];

		// eslint-disable-next-line no-restricted-syntax
		for (const newObject of newObjects) {
			const oldObjectFound = oldObjects.find((oldObject) => oldObject.id === newObject.id);

			if (!oldObjectFound) {
				oldObjects.push({
					...newObject,
				});
			} else {
				const objectDifferences = objectUtils.getObjectDifferences(
					newObject,
					oldObjectFound,
				);

				if (objectDifferences.length) {
					// eslint-disable-next-line no-restricted-syntax
					for (const objectDifference of objectDifferences) {
						(oldObjectFound as any)[objectDifference] = newObject[objectDifference];
					}
				}
			}
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const oldObject of oldObjects) {
			const newObjectFound = newObjects.find((newObject) => newObject.id === oldObject.id);

			if (!newObjectFound) {
				objectsToRemove.push(oldObject);
			}
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const objectToRemove of objectsToRemove) {
			oldObjects.splice(
				oldObjects.indexOf(objectToRemove),
				1,
			);
		}
	}

	@Watch('selectedObjectForEdition')
	protected onSelectedObjectForEditionChange(): void {
		const { selectedObjectForEdition } = this;

		if (!selectedObjectForEdition) {
			this.selectedObjectForEditionElementMutationObserver?.disconnect();
			this.selectedObjectForEditionElement = null;
			this.$forceCompute('selectedObjectActionsStyles');
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
			return;
		}

		this.$nextTick(() => {
			this.selectedObjectForEditionElement = this.interactiveViewComponent.$el.querySelector(`.editor-interactive-view-edit-object[data-object-id="${selectedObjectForEdition.id}"]`);
			this.$forceCompute('selectedObjectActionsStyles');

			if (this.selectedObjectForEditionElement) {
				this.selectedObjectForEditionElementMutationObserver?.disconnect();
				this.selectedObjectForEditionElementMutationObserver = new MutationObserver(() => {
					this.$forceCompute('selectedObjectActionsStyles');
				});
				this.selectedObjectForEditionElementMutationObserver.observe(
					this.selectedObjectForEditionElement,
					{
						attributes: true,
						attributeFilter: ['style'],
					},
				);
			}
		});
		window.addEventListener(
			'click',
			this.onWindowClick,
		);
	}

	@Watch('shouldShowSelectedObjectActions')
	protected onShouldShowSelectedObjectActionsChange(): void {
		if (this.shouldShowSelectedObjectActions) {
			this.computedObjectActionsAnchor?.append(this.selectedObjectActionsElement);
		}
	}

	protected isPhotoObject(pageObject: PageObjectModel): boolean {
		return pageObject.type === 'photo';
	}

	protected onAddPhoto(templatePosition: EditorInteractiveInteractionTemplatePhotoModel['templatePosition']): void {
		this.$emit(
			'add-photo',
			templatePosition,
		);
	}

	protected onAddText(templatePosition: EditorInteractiveInteractionTemplateTextModel['templatePosition']): void {
		this.$emit(
			'add-text',
			templatePosition,
		);
	}

	protected onDeleteObject(event: MouseEvent | TouchEvent): void {
		event.preventDefault();
		this.$emit(
			'delete-object',
			this.selectedObjectForEdition,
		);
		this.selectedObjectForEditionElement = null;
	}

	protected onDrawObjectsChange(
		pageObjects: EditorCanvasPageObjectsModel,
		objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>>,
	): void {
		const objectsChangedIds = Object.keys(objectsDifferences) as PageObjectModel['id'][];

		// eslint-disable-next-line no-restricted-syntax
		for (const objectId of objectsChangedIds) {
			const internalObjectFound = this.internalPageObjects.find((pageObject) => pageObject.id === objectId);
			const objectFound = pageObjects.find((pageObject) => pageObject.id === objectId);

			if (
				objectFound
				&& internalObjectFound
			) {
				const objectDifferences = objectsDifferences[objectId];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					(internalObjectFound as any)[objectDifference] = objectFound[objectDifference];
				}
			}
		}

		this.$emit(
			'page-objects-change',
			this.internalPageObjects,
			objectsDifferences,
		);
	}

	protected onEditorInteractiveChange(
		pageObjects: EditorCanvasPageObjectsModel,
		objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>>,
	): void {
		const objectsChangedIds = Object.keys(objectsDifferences) as PageObjectModel['id'][];

		// eslint-disable-next-line no-restricted-syntax
		for (const objectId of objectsChangedIds) {
			const internalObjectFound = this.internalPageObjects.find((pageObject) => pageObject.id === objectId);
			const objectFound = pageObjects.find((pageObject) => pageObject.id === objectId);

			if (
				objectFound
				&& internalObjectFound
			) {
				const objectDifferences = objectsDifferences[objectId];

				// eslint-disable-next-line no-restricted-syntax
				for (const objectDifference of objectDifferences) {
					(internalObjectFound as any)[objectDifference] = objectFound[objectDifference];
				}
			}
		}

		this.$emit(
			'page-objects-change',
			this.internalPageObjects,
			objectsDifferences,
		);
	}

	protected onEditorInteractiveDeleteObject(objectModel: EditorInteractivePageObjectModel): void {
		this.$emit(
			'delete-object',
			objectModel,
		);
		this.selectedObjectForEditionElement = null;
	}

	protected onEditorInteractiveTextOptionsActiveModeChange(activeMode: EditorTextOptionsActiveMode | null): void {
		this.$emit(
			'editor-text-options-active-mode-change',
			activeMode,
		);
	}

	protected onEditorInteractiveEyeDropperShownChange(isEyeDropperShown: boolean): void {
		this.internalIsEyeDropperShown = isEyeDropperShown;
		this.$emit(
			'eye-dropper-shown-change',
			this.internalIsEyeDropperShown,
		);
	}

	protected onEditorInteractivePushChanges(): void {
		this.$emit('push-changes');
	}

	protected onEditObject(event: MouseEvent | TouchEvent): void {
		event.preventDefault();
		const { selectedObjectForEdition } = this;

		if (selectedObjectForEdition) {
			const objectsDifferences: Record<EditorInteractivePageObjectModel['id'], Array<keyof EditorInteractivePageObjectModel>> = {
				[selectedObjectForEdition.id]: [
					'_selectedForEdition',
					'_selected',
				],
			};
			selectedObjectForEdition._selectedForEdition = false;
			selectedObjectForEdition._selected = true;

			if (selectedObjectForEdition.type === 'text') {
				selectedObjectForEdition._previousState = {
					...selectedObjectForEdition,
				};
				objectsDifferences[selectedObjectForEdition.id].push('_previousState');
			}

			this.$emit(
				'page-objects-change',
				this.internalPageObjects,
				objectsDifferences,
			);
		}
	}

	protected onPageModelChange(pageModel: PageModel): void {
		this.$emit(
			'page-model-change',
			pageModel,
		);
	}

	private onWindowClick(event: MouseEvent | TouchEvent): void {
		const eventTarget = event.target as HTMLElement;

		if (
			this.selectedObjectActionsElement?.isSameNode(eventTarget)
			|| this.selectedObjectActionsElement?.contains(eventTarget)
		) {
			event.preventDefault();
		}
	}
}
