import './defines';
import { EditorTooltipType } from 'interfaces/app';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorTooltipView',
})
export default class EditorTooltipView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the content of the editor tooltip',
		required: true,
		type: String,
	})
	public readonly content!: string;

	@Prop({
		default: true,
		description: 'Indicates if the editor tooltip has a close button',
		type: Boolean,
	})
	public readonly hasCloseButton!: boolean;

	@Prop({
		acceptedValues: [
			EditorTooltipType.INFO,
			EditorTooltipType.WARNING,
		],
		description: 'Defines the type of the editor tooltip',
		required: true,
		type: String,
	})
	public readonly type!: EditorTooltipType;

	protected get isInfo(): boolean {
		return this.type === EditorTooltipType.INFO;
	}

	protected get isWarning(): boolean {
		return this.type === EditorTooltipType.WARNING;
	}

	protected onCloseTooltipClick(event: MouseEvent): void {
		event.preventDefault();
		this.$emit('close');
	}
}
