var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"listItem",class:_vm.className,on:{"click":_vm.clickPhoto}},[(_vm.removing)?_c('div',{staticClass:"imageNode icon"},[_c('i',{staticClass:"fa fa-spinner-third animationSpin"})]):(_vm.img)?_c('div',{class:[
			'imageNode',
			'privateData',
			{
				icon: (
					_vm.trashMode
					&& _vm.cloudPhotoModel.id
				),
			} ],style:(_vm.styleObject),attrs:{"data-private":""}},[(_vm.trashMode && _vm.cloudPhotoModel.id)?_c('i',{staticClass:"removeIcon far fa-trash-alt",on:{"click":_vm.removePhoto}}):(!_vm.trashMode && _vm.isSelected)?_c('div',{staticClass:"statusIcon"},[_c('i',{staticClass:"material-icons"},[_vm._v("check")])]):(!_vm.trashMode && _vm.pressed)?_c('div',{staticClass:"statusIcon"},[_c('i',{staticClass:"fa fa-spinner-third animationSpin"})]):_vm._e()]):(_vm.error)?_c('div',{staticClass:"imageNode icon"},[(_vm.trashMode && _vm.cloudPhotoModel.id)?_c('i',{staticClass:"removeIcon far fa-trash-alt",on:{"click":_vm.removePhoto}}):_vm._e(),_c('i',{staticClass:"far fa-exclamation-triangle"})]):_c('div',{staticClass:"imageNode icon"},[_c('i',{staticClass:"fa fa-spinner-third animationSpin"})])])}
var staticRenderFns = []

export { render, staticRenderFns }