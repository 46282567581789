import ColorConverter from '@sosocio/color-converter';
import ColorPickerComponent from 'components/color-picker';
import TabSwitchComponent from 'components/tab-switch';
import {
	ColorPickerFromColor,
	ColorPickerFromColors,
	TabSwitchTabs,
} from 'interfaces/app';
import { PageObjectModel } from 'interfaces/project';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		ColorPickerComponent,
		TabSwitchComponent,
	},
})
export default class ColorPickerTabsComponent extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the canvas element that is going to be passed to the color-picker (for the background color)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		default: undefined,
		description: 'Defines the list of colors that the user can choose from',
		schema: 'ColorPickerFromColors',
		type: Array,
	})
	public readonly chooseFrom?: ColorPickerFromColors;

	@Prop({
		default: 'logo',
		description: 'Defines the type of the colors that the user can choose from (only visual information for the user)',
		type: String,
	})
	public readonly chooseFromType!: string;

	@Prop({
		default: true,
		description: 'Indicates if the ability to pick a background color is available',
		type: Boolean,
	})
	public readonly isBackgroundColorAvailable!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the color picker is in Pantone Matching System (PMS) mode',
		type: Boolean,
	})
	public readonly isPmsMode?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the user is limited to only choose a color from the list of colors',
		type: Boolean,
	})
	public readonly onlyFromColors!: boolean;

	@Prop(
		{
			description: 'This defines the color of the picker depending on the active tab',
			required: true,
			schema: 'PageObjectModel',
			type: Object,
		},
	)
	public readonly value!: PageObjectModel;

	protected get areChooseFromColorsAvailable(): boolean {
		return !!this.chooseFrom?.length;
	}

	protected get colorPickerFromColors(): ColorPickerFromColors {
		if (
			this.chooseFrom
			&& (
				this.onlyFromColors
				|| this.isFromColorsTabActive
			)
		) {
			return this.chooseFrom;
		}

		return [];
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			'color-picker-tabs-component-with-tabs': (
				!this.onlyFromColors
				&& (
					this.areChooseFromColorsAvailable
					|| this.isBackgroundColorAvailable
				)
			),
		};
	}

	protected get computedColor(): string {
		if (this.isBackgroundColorTabActive) {
			return this.internalValue?.bgcolor || '';
		}

		return this.internalValue?.fontcolor || '';
	}

	private get isBackgroundColorTabActive(): boolean {
		return this.activeTab === 'background';
	}

	private get isFromColorsTabActive(): boolean {
		return this.activeTab === 'from-colors';
	}

	private get isFontColorTabActive(): boolean {
		return this.activeTab === 'font';
	}

	protected get shouldHideTabs(): boolean {
		return (
			this.onlyFromColors
			|| (
				!this.areChooseFromColorsAvailable
				&& !this.isBackgroundColorAvailable
			)
		);
	}

	protected get tabs(): TabSwitchTabs {
		if (!this.areChooseFromColorsAvailable) {
			return [
				{
					id: 'font',
					text: this.$t('views.editorTextOptions.colorPickerTabs.font'),
				},
				{
					id: 'background',
					text: this.$t('views.editorTextOptions.colorPickerTabs.background'),
				},
			];
		}

		return [
			{
				id: 'from-colors',
				text: this.$t(
					'views.editorTextOptions.colorPickerTabs.chooseFrom',
					{
						from: this.chooseFromType,
					},
				),
			},
			{
				id: 'font',
				text: this.$t('views.editorTextOptions.colorPickerTabs.chooseYourOwn'),
			},
		];
	}

	protected activeTab: 'from-colors' | 'font' | 'background' = 'font';

	private internalValue: PageObjectModel = {} as PageObjectModel;

	@Watch(
		'onlyFromColors',
		{
			immediate: true,
		},
	)
	protected onOnlyFromColorsChange(): void {
		if (this.onlyFromColors) {
			this.activeTab = 'from-colors';
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = {
			...this.value,
		};
	}

	protected onColorChange(color: string): void {
		if (this.isFontColorTabActive) {
			this.internalValue.fontcolor = color;

			if (this.isPmsMode) {
				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: color.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};

				this.internalValue.fontcolor_visual = (
					colorConverter.hex6.showAs
					?? null
				);
			} else if (this.internalValue.fontcolor_visual) {
				this.internalValue.fontcolor_visual = null;
			}
		} else if (this.isBackgroundColorTabActive) {
			this.internalValue.bgcolor = color;
		} else {
			const fromColorsColor = this.chooseFrom?.find((fromColor) => fromColor.color === color);
			this.internalValue.fontcolor = color;

			if (fromColorsColor) {
				this.internalValue.fontcolor_visual = (
					fromColorsColor.visual
					?? null
				);
			} else if (this.internalValue.fontcolor_visual) {
				this.internalValue.fontcolor_visual = null;
			}
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onEyeDropperEnd(): void {
		this.$emit('eye-dropper-end');
	}

	protected onEyeDropperStart(): void {
		this.$emit('eye-dropper-start');
	}

	protected onFromColorClick(color: ColorPickerFromColor): void {
		this.internalValue.fontcolor = color.color;

		if (
			color.visual
			|| this.internalValue.fontcolor_visual
		) {
			this.internalValue.fontcolor_visual = (
				color.visual
				?? null
			);
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}
}
