import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import * as PI from 'interfaces/project';
import { FontModel } from 'interfaces/app';

@Component
export default class FontPickerItemView extends Vue {
	@Prop({ required: true, type: Object })
	readonly fontModel!: FontModel;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	imageUrl: string|null = null;

	isVisible = false;

	created() {
		const fontString = (this.fontModel.id.replace(
			/\s/g,
			'',
		)).toLowerCase();
		import(/* webpackMode: "eager" */ `../../img/fonts/${fontString}.png`).then((fontUrl) => {
			this.imageUrl = fontUrl.default;
		});
	}

	imageLoaded() {
		this.$emit('imageLoaded');
	}

	selectFont() {
		this.$emit(
			'selectFont',
			this.fontModel,
		);
	}

	visibilityChanged(isVisible: boolean) {
		this.isVisible = isVisible;
	}
}
