import merge from 'deepmerge';
import getDeviceDetails from 'ops/device-details';
import * as DialogService from 'services/dialog';
import { ConfigModule } from 'store';
import appendUrlParameter from 'tools/append-url-parameter';
import getViewport from 'tools/get-viewport';
import _ from 'underscore';

const openWindow = (
	url: string,
	opts?: {
		width?: number;
		height?: number;
		inApp?: boolean;
		title?: string;
		menubar?: boolean;
		resizable?: boolean;
		hashTag?: boolean;
		target?: string;
	},
	onClose?: () => void,
) => {
	const defaults = {
		width: 500,
		height: 500,
		inApp: true, // When in native app, open url with in-app browser?
		title: '',
		menubar: false,
		resizable: true,
		hashTag: false, // For oAuth flows, check the value of this hashtag in the redirect
		target: '_blank',
	};
	const options = opts ? merge(
		defaults,
		opts,
	) : defaults;

	// Fixes dual-screen position
	const dualScreenLeft = window.screenLeft !== undefined
		? window.screenLeft // Most browsers
		// @ts-ignore: Non standardized property left may exist
		: window.screen.left; // Firefox
	const dualScreenTop = window.screenTop !== undefined
		? window.screenTop // Most browsers
		// @ts-ignore: Non standardized property top may exist
		: window.screen.top; // Firefox

	let dialogOptions = '';

	if (options.width && options.height) {
		const viewport = getViewport();
		const left = ((viewport.width / 2) - (options.width / 2)) + dualScreenLeft;
		const top = ((viewport.height / 2) - (options.height / 2)) + dualScreenTop;
		dialogOptions += `menubar=${options.menubar ? 'true' : 'false'}`;
		dialogOptions += `, resizeable=${options.resizable ? 'true' : 'false'}`;
		dialogOptions += `, height=${options.height}, width=${options.width}, left=${left}, top=${top}`;
	}

	if (window.glPlatform == 'native') {
		if (!window.webToNative) {
			throw new Error('Missing WebToNative on window');
		}

		if (ConfigModule.partnerID === 2 && options.inApp) {
			getDeviceDetails().then((deviceDetails) => {
				if (deviceDetails.os === 'iOS') {
					// Due to 3rd party cookies on the HEMA website, we can't load this content within the iOS app (App Tracking Transparency rules)
					const closeAlert = DialogService.openAlertDialog({
						header: {
							title: window.App.router.$t('dialogs.nativeAppBreakout.title'),
						},
						body: {
							content: window.App.router.$t('dialogs.nativeAppBreakout.message'),
						},
						footer: {
							buttons: [
								{
									id: 'cancel',
									text: window.App.router.$t('dialogs.nativeAppBreakout.buttons.cancel'),
									click: () => {
										closeAlert();
									},
								},
								{
									id: 'accept',
									text: window.App.router.$t('dialogs.nativeAppBreakout.buttons.accept'),
									click: () => {
										window.webToNative?.openNativeBrowser(
											url,
											false,
										);
										closeAlert();
									},
								},
							],
						},
					});
				} else {
					const nativeUrl = appendUrlParameter(
						url,
						'hideheaderandfooter',
						'true',
					);
					window.webToNative?.openNativeBrowser(
						nativeUrl,
						true,
					);
				}
			});
		} else {
			window.webToNative.openNativeBrowser(
				url,
				options.inApp,
			);
		}

		return undefined;
	}

	// Trigger callback
	const popup = window.open(
		url,
		options.target,
		dialogOptions,
	);

	// Ensure popup window has focus upon reload, Fix for FF.
	if (popup) {
		popup.focus();

		if (onClose && _.isFunction(onClose)) {
			const timer = window.setInterval(
				() => {
					if (!popup || popup.closed) {
						window.clearInterval(timer);
						onClose();
					}
				},
				100,
			);
		}
	}

	return popup;
};

export default openWindow;
