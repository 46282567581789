import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { FontModule } from 'store';
import {
	Component,
	Prop,
	Provide,
	Vue,
	Watch,
} from 'vue-property-decorator';
import DrawView from './draw';

@Component({
	components: {
		DrawView,
	},
})
export default class PageAddressView extends Vue {
	@Prop({ required: true, type: Object })
	readonly addressModel!: DB.AddressModel;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: false, type: Boolean })
	readonly showBleed!: boolean;

	// Allows any child component to `inject: ['canvas']` and have access to it.
	// This is the CanvasRenderingContext that children will draw to.
	@Provide('canvas')
		canvas: {
		context: CanvasRenderingContext2D|null;
	} = {
				context: null,
			};

	@Watch('canvasWidth')
	@Watch('canvasHeight')
	onPageAddressViewCanvasSizeChange() {
		// We need to manually trigger an update of the canvas drawing by changing the key
		// otherwise the drawing won't update when changing the canvas size (toggle bleed margin)
		this.componentKey += 1;
	}

	componentKey = 0;

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset ? this.pageModel.offset : 0;
	}

	get canvasHeight() {
		return Math.round(this.pageModel.height * this.scaling + 2 * this.bleedMargin * this.scaling);
	}

	get canvasWidth() {
		return Math.round(this.pageModel.width * this.scaling + 2 * this.bleedMargin * this.scaling);
	}

	get fontLoaded() {
		return this.fontModel && this.fontModel._loaded;
	}

	get fontModel() {
		const fontface = 'Reenie Beanie';
		return FontModule.getById(fontface);
	}

	created() {
		this.loadFont();
	}

	mounted() {
		// We can't access the rendering context until the canvas is mounted to the DOM.
		// Once we have it, provide it to all child components.
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		this.canvas.context = canvas.getContext('2d');
	}

	loadFont() {
		if (!this.fontLoaded && this.fontModel) {
			FontModule
				.loadModel(this.fontModel.id)
				.catch(() => {
					const closeError = this.$openErrorDialog({
						body: {
							content: this.$t('dialogTextLoadError'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: this.$t('dialogButtonOk'),
									click: () => {
										this.loadFont();
										closeError();
									},
								},
							],
						},
					});
				});
		}
	}
}
