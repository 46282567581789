import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolbarButton } from 'interfaces/app';

@Component
export default class ObjectToolbarItemView extends Vue {
	@Prop({ required: true, type: Object })
	readonly buttonModel!: ToolbarButton;

	@Prop({ default: undefined, type: String })
	readonly buttonColor: string|undefined;

	pressButton() {
		if (this.buttonModel.target) {
			this.buttonModel.target({ ...this.buttonModel });
		}

		this.$emit(
			'pressButton',
			this.buttonModel,
		);
	}
}
