import './defines';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import ColorConverter from '@sosocio/color-converter';
import PageObject from 'classes/pageobject';
import experiment from 'controllers/experiment';
import {
	EditorDrawResolution,
	FontModel,
	OfferingFrameModel,
	PageObjectVars,
	SVGColorReplacement,
} from 'interfaces/app';
import {
	AddressModel,
	OfferingModel,
} from 'interfaces/database';
import {
	PageObjectColorReplacementModel,
	PageObjectModel,
	PageModel,
} from 'interfaces/project';
import loadPhotoObject from 'mutations/pageobject/load';
import loadObjectMask, { LoadObjectMaskOptions } from 'mutations/pageobject/load-mask';
import loadImage from 'services/load-image';
import { COLOR_FULL } from 'settings/offerings';
import {
	AppDataModule,
	FontModule,
	PhotosModule,
	ThemeDataModule,
} from 'store';
import { devicePixelRatio as devicePixelRatioTools } from 'tools';
import breakText from 'tools/break-text';
import formatAddress from 'tools/format-address';
import getBucketUrl from 'tools/get-bucket-url';
import maxObjectSize from 'tools/max-object-size';
import { object as objectUtils } from 'utils';
import { Public } from 'utils/decorators';
import { VueConstructorExtended } from 'vue';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorDrawView',
})
export default class EditorDrawView extends Vue.extend(Template) {
	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly addressModel?: AddressModel;

	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		default: '#000',
		type: String,
	})
	public readonly color!: string;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly drawMask!: boolean;

	@Prop({
		default: () => ([]),
		event: 'draw-objects-change',
		type: Array,
	})
	public readonly drawObjects!: PageObjectModel[];

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly drawOverlay!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly drawPage!: boolean;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly height!: number;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly isCanvasOverlayActive!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly isCropModeActive!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly mirrorOverlay!: boolean;

	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly objectStyle?: Partial<CSSStyleDeclaration>;

	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		type: Object,
	})
	public readonly offeringModel?: OfferingModel;

	@Prop({
		event: 'page-model-change',
		required: true,
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		acceptedValues: [
			'low',
			'high',
			'thumb',
		],
		default: 'low',
		schema: 'EditorDrawResolution',
		type: String,
	})
	public readonly resolution!: EditorDrawResolution;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly scaling!: number;

	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly showLoaders!: boolean;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly width!: number;

	@Prop({
		default: 1,
		description: 'Defines the maximum zoom level of the editor canvas',
		type: Number,
	})
	public readonly zoomMaxLevel!: number;

	protected get canvasContext(): CanvasRenderingContext2D {
		return this.canvasElement?.getContext('2d') as CanvasRenderingContext2D;
	}

	protected get canvasHeight(): number {
		return this.getCanvasHeight(this.scaling);
	}

	protected get canvasHeightFullSize(): number {
		return this.getCanvasHeight(this.scaling * this.zoomMaxLevel * this.devicePixelRatio);
	}

	protected get canvasWidth(): number {
		return this.getCanvasWidth(this.scaling);
	}

	protected get canvasWidthFullSize(): number {
		return this.getCanvasWidth(this.scaling * this.zoomMaxLevel * this.devicePixelRatio);
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		return {
			height: `${this.canvasHeight}px`,
			width: `${this.canvasWidth}px`,
			'--editor-canvas-scaling': `${this.scaling / (this.scaling * this.zoomMaxLevel * this.devicePixelRatio)}`,
		};
	}

	private get computedIsDrawing(): boolean {
		return this.isDrawing;
	}

	private set computedIsDrawing(value: boolean) {
		this.isDrawing = value;

		if (
			!value
			&& this.redraw
		) {
			this.redraw = false;
			this.drawOnCanvas();
		}
	}

	private get offeringFrameImage(): HTMLImageElement | undefined {
		if (
			!this.drawOverlay
			|| !this.offeringFrameModel?.imageModel
		) {
			return undefined;
		}

		AppDataModule.getAndSetOfferingFrameImage(this.offeringFrameModel.imageModel);

		return AppDataModule.findOfferingFrameImage(this.offeringFrameModel.imageModel);
	}

	private get offeringMaskImage(): HTMLImageElement | undefined {
		if (!this.offeringModel?.mask) {
			return undefined;
		}

		AppDataModule.getAndSetOfferingMaskImage(this.offeringModel);

		return AppDataModule.findOfferingMaskImage(this.offeringModel);
	}

	private get offeringOverlayImage(): HTMLImageElement | undefined {
		if (!this.offeringModel?.overlay) {
			return undefined;
		}

		AppDataModule.getAndSetOfferingOverlayImage(this.offeringModel);

		return AppDataModule.findOfferingOverlayImage(this.offeringModel);
	}

	private get svgTextObjectModels(): PageObjectModel[] {
		return this.internalDrawObjects.filter((objectModel) => (
			objectModel.type === 'text'
			&& objectModel.text_svg
		));
	}

	@Ref('canvas')
	private canvasElement!: HTMLCanvasElement;

	private devicePixelRatio = devicePixelRatioTools.devicePixelRatio;

	private devicePixelRatioUnwatch?: () => void;

	private drawOnCanvasPromises?: Promise<any>[];

	private firstDrawHasHappened?: boolean;

	private internalDrawObjects: PageObjectModel[] = [];

	private internalPageModel: PageModel = {} as PageModel;

	private isDrawing = false;

	private photoLoadErrorShown?: boolean;

	private redraw?: boolean;

	private resizeObserver?: ResizeObserver;

	private updateDrawObjectsAbortController?: AbortController;

	protected beforeDestroy(): void {
		if (this.offeringFrameModel) {
			AppDataModule.unsetOfferingFrameImage(this.offeringFrameModel);
		}

		if (this.offeringModel?.mask) {
			AppDataModule.unsetOfferingMaskImage(this.offeringModel);
		}

		if (this.offeringModel?.overlay) {
			AppDataModule.unsetOfferingOverlayImage(this.offeringModel);
		}

		this.devicePixelRatioUnwatch?.();
		this.resizeObserver?.disconnect();
		this.resizeObserver = undefined;
		this.updateDrawObjectsAbortController?.abort();
		this.updateDrawObjectsAbortController = undefined;
	}

	protected created(): void {
		this.devicePixelRatioUnwatch = devicePixelRatioTools.watch(() => {
			this.devicePixelRatio = devicePixelRatioTools.devicePixelRatio;
		});
	}

	protected mounted(): void {
		this.$forceCompute('canvasContext');
		this.resizeObserver = new ResizeObserver(() => this.$forceCompute('canvasContext'));
		this.resizeObserver.observe(this.canvasElement);
		const classConstructor = this.constructor as VueConstructorExtended;

		if (classConstructor.options.props) {
			const props = Object.keys(classConstructor.options.props) as Array<keyof PublicNonFunctionProps<EditorDrawView>>;

			// eslint-disable-next-line no-restricted-syntax
			for (const prop of props) {
				if (prop !== 'drawObjects') {
					this.$watch(
						prop,
						this.drawOnCanvas,
						{
							deep: true,
						},
					);
				}
			}
		}

		this.$watch(
			'offeringFrameImage',
			this.drawOnCanvas,
			{
				deep: true,
			},
		);
		this.$watch(
			'offeringMaskImage',
			this.drawOnCanvas,
			{
				deep: true,
			},
		);
		this.$watch(
			'offeringOverlayImage',
			this.drawOnCanvas,
			{
				deep: true,
			},
		);

		this.$nextTick(() => {
			if (!this.firstDrawHasHappened) {
				this.drawOnCanvas();
			}
		});
	}

	@Watch('devicePixelRatio')
	protected onDevicePixelRatioChange(): void {
		this.drawOnCanvas();
	}

	@Watch(
		'drawObjects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onDrawObjectsChange(): void {
		this.updateDrawObjects();
	}

	@Watch(
		'pageModel',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPageModelChange(): void {
		let changed = false;

		if (
			(
				!this.pageModel
				&& this.internalPageModel
			)
			|| (
				this.pageModel
				&& !this.internalPageModel
			)
		) {
			changed = true;
		} else {
			const pageModelDifferences = objectUtils.getObjectDifferences(
				this.pageModel,
				this.internalPageModel,
			);

			if (pageModelDifferences.length) {
				const partialPageModelToChange = pageModelDifferences.reduce(
					(partialPageModel, keyDifference) => {
						if (
							keyDifference.substring(
								0,
								1,
							) !== '_'
						) {
							partialPageModel[keyDifference] = this.pageModel[keyDifference] as any;
						}

						return partialPageModel;
					},
					{} as Partial<PageModel>,
				);

				if (Object.keys(partialPageModelToChange).length) {
					changed = true;
				}
			}
		}

		if (changed) {
			this.internalPageModel = {
				...this.pageModel,
				objectList: (
					this.pageModel.objectList
						? [...this.pageModel.objectList]
						: undefined
				),
			};
		}
	}

	@Watch('offeringFrameModel')
	protected onOfferingFrameModelChange(
		newValue: OfferingFrameModel | undefined,
		oldValue: OfferingFrameModel | undefined,
	): void {
		if (
			oldValue
			&& (
				!newValue
				|| newValue.id !== oldValue.id
			)
		) {
			AppDataModule.unsetOfferingFrameImage(oldValue);
		}
	}

	@Watch('offeringModel')
	protected onOfferingModelChange(
		newValue: OfferingModel,
		oldValue: OfferingModel,
	): void {
		if (
			oldValue.mask
			&& newValue.mask !== oldValue.mask
		) {
			AppDataModule.unsetOfferingMaskImage(oldValue);
		}

		if (
			oldValue.overlay
			&& newValue.overlay !== oldValue.overlay
		) {
			AppDataModule.unsetOfferingOverlayImage(oldValue);
		}
	}

	private calculatePosition(
		objectModel: PageObjectModel,
		scaling: number,
	): PageObjectVars {
		const photoModel = (
			objectModel.photoid
				? PhotosModule.getById(objectModel.photoid)
				: undefined
		);
		const photoData = (
			photoModel
				? {
					width: photoModel.full_width,
					height: photoModel.full_height,
				}
				: undefined
		);
		const maxsize = maxObjectSize(
			{
				maxwidth: objectModel.maxwidth,
				maxheight: objectModel.maxheight,
				cropwidth: objectModel.cropwidth,
				cropheight: objectModel.cropheight,
				type: objectModel.type,
			},
			photoData,
			this.offeringModel,
		);
		const dpiScale = objectModel.maxwidth / maxsize.width;

		const vars: PageObjectVars = {
			x_axis: objectModel.x_axis + this.bleedMargin,
			y_axis: objectModel.y_axis + this.bleedMargin,
			width: objectModel.width,
			height: objectModel.height,
			maxwidth: maxsize.width,
			maxheight: maxsize.height,
			cropx: objectModel.cropx,
			cropy: objectModel.cropy,
			cropwidth: objectModel.cropwidth,
			cropheight: objectModel.cropheight,
			borderwidth: objectModel.borderwidth,
			bordercolor: objectModel.bordercolor,
			flop: objectModel.flop,
			flip: objectModel.flip,
			angle: objectModel.rotate,
			topleft: {
				x: 0,
				y: 0,
			},
			topright: {
				x: 0,
				y: 0,
			},
			bottomright: {
				x: 0,
				y: 0,
			},
			bottomleft: {
				x: 0,
				y: 0,
			},
			imagemap: {
				topleft: {
					x: 0,
					y: 0,
				},
				topright: {
					x: 0,
					y: 0,
				},
				bottomright: {
					x: 0,
					y: 0,
				},
				bottomleft: {
					x: 0,
					y: 0,
				},
			},
			rotate: 0,
			cosinus: 0,
			sinus: 0,
			center: {
				x: 0,
				y: 0,
			},
			max: {
				x_axis: 0,
				y_axis: 0,
				width: 0,
				height: 0,
				cropx: 0,
				cropy: 0,
				topleft: {
					x: 0,
					y: 0,
				},
				placement: {
					x: 0,
					y: 0,
				},
			},
			placement: {
				x: 0,
				y: 0,
			},
			canvas: {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				width: 0,
				height: 0,
			},
			draw: {
				x: 0,
				y: 0,
			},
		};
		vars.center = {
			x: vars.x_axis + vars.width / 2,
			y: vars.y_axis + vars.height / 2,
		};

		const objectscale = vars.cropwidth / vars.width;

		vars.max = {
			x_axis: vars.x_axis - vars.cropx / objectscale,
			y_axis: vars.y_axis - vars.cropy / objectscale,
			width: vars.maxwidth / objectscale * dpiScale,
			height: vars.maxheight / objectscale * dpiScale,
			cropx: 0,
			cropy: 0,
			topleft: {
				x: 0,
				y: 0,
			},
			placement: {
				x: 0,
				y: 0,
			},
		};
		vars.max.center = {
			x: vars.max.x_axis + vars.max.width / 2,
			y: vars.max.y_axis + vars.max.height / 2,
		};

		if (vars.flop) {
			vars.angle = -vars.angle;
		}

		if (vars.flip) {
			vars.angle = -vars.angle;
		}

		vars.rotate = Math.PI / 180 * vars.angle;
		vars.cosinus = Math.cos(vars.rotate);
		vars.sinus = Math.sin(vars.rotate);

		vars.topleft.x = ((-(vars.width) / 2 * Math.cos(vars.rotate)) - (-(vars.height) / 2 * Math.sin(vars.rotate))) + vars.center.x;
		vars.imagemap.topleft.x = ((-(vars.width + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate)) - (-(vars.height + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate))) + vars.center.x;

		vars.topleft.y = ((-(vars.width) / 2 * Math.sin(vars.rotate)) + (-(vars.height) / 2 * Math.cos(vars.rotate))) + vars.center.y;
		vars.imagemap.topleft.y = ((-(vars.width + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate)) + (-(vars.height + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate))) + vars.center.y;

		vars.max.topleft.x = ((-(vars.max.width) / 2 * Math.cos(vars.rotate)) - (-(vars.max.height) / 2 * Math.sin(vars.rotate))) + vars.max.center.x;
		vars.max.topleft.y = ((-(vars.max.width) / 2 * Math.sin(vars.rotate)) + (-(vars.max.height) / 2 * Math.cos(vars.rotate))) + vars.max.center.y;

		vars.topright.x = vars.topleft.x + vars.width * vars.cosinus;
		vars.imagemap.topright.x = vars.imagemap.topleft.x + (vars.width + 2 * vars.borderwidth) * vars.cosinus;

		vars.topright.y = vars.topleft.y + vars.width * vars.sinus;
		vars.imagemap.topright.y = vars.imagemap.topleft.y + (vars.width + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.x = vars.topright.x - vars.height * vars.sinus;
		vars.imagemap.bottomright.x = vars.imagemap.topright.x - (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.y = vars.topright.y + vars.height * vars.cosinus;
		vars.imagemap.bottomright.y = vars.imagemap.topright.y + (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.bottomleft.x = vars.topleft.x - vars.height * vars.sinus;
		vars.imagemap.bottomleft.x = vars.imagemap.topleft.x - (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomleft.y = vars.topleft.y + vars.height * vars.cosinus;
		vars.imagemap.bottomleft.y = vars.imagemap.topleft.y + (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.placement = {
			x: vars.topleft.x,
			y: vars.topleft.y,
		};
		vars.max.placement = {
			x: vars.max.topleft.x,
			y: vars.max.topleft.y,
		};

		if (vars.flop) { // horizontal
			vars.placement.x = -vars.bottomright.x;
			const tempFlop = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = tempFlop.bottomleft.x;
			vars.topleft.y = tempFlop.topright.y;
			vars.topright.x = tempFlop.bottomright.x;
			vars.topright.y = tempFlop.topleft.y;
			vars.bottomright.x = tempFlop.topright.x;
			vars.bottomright.y = tempFlop.bottomleft.y;
			vars.bottomleft.x = tempFlop.topleft.x;
			vars.bottomleft.y = tempFlop.bottomright.y;
		}

		if (vars.flip) { // vertical
			if (!vars.flop) {
				vars.placement.y = -vars.bottomright.y;
			} else {
				vars.placement.y = -vars.bottomleft.y;
			}

			const tempFlip = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = tempFlip.bottomleft.x;
			vars.topleft.y = tempFlip.topright.y;
			vars.topright.x = tempFlip.bottomright.x;
			vars.topright.y = tempFlip.topleft.y;
			vars.bottomright.x = tempFlip.topright.x;
			vars.bottomright.y = tempFlip.bottomleft.y;
			vars.bottomleft.x = tempFlip.topleft.x;
			vars.bottomleft.y = tempFlip.bottomright.y;
		}

		vars.imagemap = {
			topleft: {
				x: (vars.imagemap.topleft.x) * scaling,
				y: (vars.imagemap.topleft.y) * scaling,
			},
			topright: {
				x: (vars.imagemap.topright.x) * scaling,
				y: (vars.imagemap.topright.y) * scaling,
			},
			bottomright: {
				x: (vars.imagemap.bottomright.x) * scaling,
				y: (vars.imagemap.bottomright.y) * scaling,
			},
			bottomleft: {
				x: (vars.imagemap.bottomleft.x) * scaling,
				y: (vars.imagemap.bottomleft.y) * scaling,
			},
		};

		const pagewidth = this.internalPageModel.width * scaling;
		const pageheight = this.internalPageModel.height * scaling;

		vars.canvas = {
			top: Math.max(
				0,
				Math.min(
					vars.imagemap.topleft.y,
					vars.imagemap.topright.y,
					vars.imagemap.bottomleft.y,
					vars.imagemap.bottomright.y,
				),
			),
			left: Math.max(
				0,
				Math.min(
					vars.imagemap.topleft.x,
					vars.imagemap.topright.x,
					vars.imagemap.bottomleft.x,
					vars.imagemap.bottomright.x,
				),
			),
			bottom: Math.min(
				pageheight + 2 * this.bleedMargin * scaling,
				Math.max(
					vars.imagemap.topleft.y,
					vars.imagemap.topright.y,
					vars.imagemap.bottomleft.y,
					vars.imagemap.bottomright.y,
				),
			),
			right: Math.min(
				pagewidth + 2 * this.bleedMargin * scaling,
				Math.max(
					vars.imagemap.topleft.x,
					vars.imagemap.topright.x,
					vars.imagemap.bottomleft.x,
					vars.imagemap.bottomright.x,
				),
			),
			width: 0,
			height: 0,
		};

		if (objectModel.type === 'text') {
			// The text of a text object can be bigger than the size of the object, therefore we make sure that nothing is cut off by extending the size of the canvas
			vars.canvas.left = 0;
			vars.canvas.right = pagewidth + 2 * this.bleedMargin * scaling;
			vars.canvas.bottom = pageheight + 2 * this.bleedMargin * scaling;
		} else if (
			objectModel.type === 'photo'
			&& objectModel.borderimage
		) {
			const borderImageModel = ThemeDataModule.getBorderImage(objectModel.borderimage);

			if (borderImageModel) {
				const borderImageInnerWidth = borderImageModel.width - borderImageModel.borderwidth_left - borderImageModel.borderwidth_right;
				const borderscale = vars.width / borderImageInnerWidth;

				const borderWidthTop = Math.round(borderImageModel.borderwidth_top * borderscale);
				const borderwidthRight = Math.round(borderImageModel.borderwidth_right * borderscale);
				const borderwidthBottom = Math.round(borderImageModel.borderwidth_bottom * borderscale);
				const borderwidthLeft = Math.round(borderImageModel.borderwidth_left * borderscale);

				vars.canvas.left = Math.max(
					0,
					vars.canvas.left - borderwidthLeft,
				);
				vars.canvas.right = Math.min(
					pagewidth + 2 * this.bleedMargin * scaling,
					vars.canvas.right + borderwidthRight,
				);
				vars.canvas.top = Math.max(
					0,
					vars.canvas.top - borderWidthTop,
				);
				vars.canvas.bottom = Math.min(
					pageheight + 2 * this.bleedMargin * scaling,
					vars.canvas.bottom + borderwidthBottom,
				);
			}
		}

		vars.canvas.width = vars.canvas.right - vars.canvas.left;
		vars.canvas.height = vars.canvas.bottom - vars.canvas.top;

		vars.canvas.width += 2;
		vars.canvas.height += 2;

		vars.draw = {
			x: this.drawPage ? vars.placement.x : (vars.placement.x - vars.canvas.left / scaling + 1),
			y: this.drawPage ? vars.placement.y : (vars.placement.y - vars.canvas.top / scaling + 1),
		};

		return vars;
	}

	private drawAddress(addressModel: AddressModel): void {
		const fontface = 'Reenie Beanie';
		const fontModel = FontModule.getById(fontface);

		if (fontModel) {
			const textWidth = this.internalPageModel.width / 2 - 80;
			const defaultPxSize = 18 * 31496 / 15120;
			const lineHeight = defaultPxSize * 1.25;

			this.canvasContext.save();
			this.canvasContext.translate(
				this.internalPageModel.width / 2 + 40,
				this.internalPageModel.height / 3,
			);

			if (!fontModel._loaded) {
				this.loadFont(
					fontModel,
					textWidth,
					lineHeight * 5,
				);
			} else if (fontModel._loaded) {
				this.canvasContext.strokeStyle = '#000000';
				this.canvasContext.fillStyle = '#003399';
				this.canvasContext.textBaseline = 'alphabetic';
				this.canvasContext.lineWidth = 1;
				this.canvasContext.textAlign = 'left';

				const arrLines = formatAddress(addressModel);
				arrLines.forEach((lineText, i) => {
					const response = breakText({
						phrase: lineText,
						maxPxLength: textWidth,
						maxPxHeight: lineHeight,
						fontface,
						bold: false,
						italic: false,
						pointsize: 18,
						resize: {
							up: 18,
							down: 12,
						},
						subset: ['latin'],
					});

					let pxsize = defaultPxSize;

					if (response && response.pointsize) {
						pxsize = response.pointsize * 31496 / 15120;
					}

					const y = lineHeight + i * 60;

					this.canvasContext.font = `${pxsize}px ${fontface}`;
					this.canvasContext.fillText(
						lineText,
						0,
						y,
					);
					this.canvasContext.beginPath();
					this.canvasContext.moveTo(
						0,
						y + 2.5,
					);
					this.canvasContext.lineTo(
						textWidth,
						y + 2.5,
					);
					this.canvasContext.closePath();
					this.canvasContext.stroke();
				});
			} else {
				this.drawLoader(
					textWidth,
					lineHeight * 5,
					0xf252,
				);
			}

			this.canvasContext.translate(
				-this.internalPageModel.width / 2 + 40,
				-this.internalPageModel.height / 3,
			);
			this.canvasContext.restore();
		} else {
			throw new Error('Could not find required font model');
		}
	}

	private drawLoader(
		width: number,
		height: number,
		charCode = 0xf252,
	): void {
		this.canvasContext.globalAlpha = 0.5;
		this.canvasContext.strokeStyle = this.color;
		this.canvasContext.fillStyle = this.color;
		this.canvasContext.lineWidth = Math.min(
			width,
			height,
		) / 10;

		this.canvasContext.strokeRect(
			0,
			0,
			width,
			height,
		);

		const fontsize = Math.min(
			width / 2,
			height / 2,
		);
		this.canvasContext.font = `${fontsize}px "Font Awesome 5 Pro"`;
		this.canvasContext.textAlign = 'center';
		this.canvasContext.textBaseline = 'middle';

		this.canvasContext.fillText(
			String.fromCharCode(charCode),
			Math.round(width / 2),
			Math.round(height / 2),
		);
		this.canvasContext.globalAlpha = 1;
	}

	private drawObject(objectModel: PageObjectModel): void {
		const vars = this.calculatePosition(
			objectModel,
			this.scaling * this.zoomMaxLevel * this.devicePixelRatio,
		);

		if (vars.flop) {
			// horizontal
			this.canvasContext.scale(
				-1,
				1,
			);
		}
		if (vars.flip) {
			// vertical
			this.canvasContext.scale(
				1,
				-1,
			);
		}

		this.canvasContext.translate(
			vars.draw.x,
			vars.draw.y,
		);
		this.canvasContext.rotate(vars.rotate);

		if (objectModel.type === 'photo') {
			if (!objectModel._image) {
				this.loadPhoto(
					objectModel,
					vars.width,
					vars.height,
				);
			} else if (
				objectModel.mask
				&& (
					!objectModel._mask
					|| !objectModel._canvas
				)
			) {
				this.loadPhoto(
					objectModel,
					vars.width,
					vars.height,
				);
			} else if (
				objectModel.borderimage
				&& !objectModel._borderimage
			) {
				this.loadPhoto(
					objectModel,
					vars.width,
					vars.height,
				);
			} else if (
				objectModel._image
				&& (
					!objectModel.borderimage
					|| objectModel._borderimage
				)
				&& (
					!objectModel.mask
					|| objectModel._mask
					|| objectModel._canvas
				)
			) {
				this.drawPhotoObject(
					objectModel,
					vars,
				);

				if (objectModel._resetImage) {
					this.loadPhoto(
						objectModel,
						vars.width,
						vars.height,
					);
				}
			} else if (this.showLoaders) {
				this.drawLoader(
					vars.width,
					vars.height,
					0xf03e,
				);
			}
		} else if (
			(
				objectModel.type === 'text'
				|| objectModel.type === 'message'
			)
			&& objectModel.fontface
		) {
			const fontModel = FontModule.getById(objectModel.fontface);

			if (
				fontModel
				&& fontModel._loaded
			) {
				this.drawTextObject(
					objectModel,
					vars,
				);
			} else if (fontModel) {
				this.loadFont(
					fontModel,
					vars.width,
					vars.height,
				);
			}
		} else if (
			this.drawMask
			&& this.offeringMaskImage
			&& !this.drawPage
		) {
			const offset = this.bleedMargin;
			const { width, height } = this.internalPageModel;

			this.canvasContext.save();
			this.canvasContext.globalCompositeOperation = 'destination-in';

			this.canvasContext.drawImage(
				this.offeringMaskImage,
				offset - vars.x_axis,
				offset - vars.y_axis,
				width,
				height,
			);

			this.canvasContext.restore();
		}

		this.canvasContext.rotate(-vars.rotate);
		this.canvasContext.translate(
			-vars.draw.x,
			-vars.draw.y,
		);

		if (vars.flip) {
			this.canvasContext.scale(
				1,
				-1,
			);
		}
		if (vars.flop) {
			this.canvasContext.scale(
				-1,
				1,
			);
		}
	}

	private async drawOnCanvas(): Promise<void> {
		// Since the parent canvas has to mount first, it's *possible* that the context may not be
		// injected by the time this render function runs the first time.
		if (!this.canvasContext) {
			this.$forceCompute('canvasContext');
			return;
		}

		if (this.computedIsDrawing) {
			this.redraw = true;
			return;
		}

		this.computedIsDrawing = true;
		this.firstDrawHasHappened = true;

		/**
		 * To prevent SVG text flickering we check if any SVG text is missing
		 * it's `_image` property and if so we load it before clearing the canvas
		 */
		// eslint-disable-next-line no-restricted-syntax
		for (const svgTextObjectModel of this.svgTextObjectModels) {
			if (
				!svgTextObjectModel._image
				&& svgTextObjectModel.fontface
			) {
				const fontModel = FontModule.getById(svgTextObjectModel.fontface);

				if (fontModel) {
					if (!fontModel._datauri) {
						// eslint-disable-next-line no-await-in-loop
						await this.loadFontDataUri(
							fontModel,
							0,
							0,
							{
								redraw: false,
								disableLoaders: true,
							},
						);
					}

					// eslint-disable-next-line no-await-in-loop
					await this.loadTextSVG(
						fontModel,
						svgTextObjectModel,
					);
				}
			}
		}

		try {
			this.drawOnCanvasPromises = [];
			// Reset and clear canvas
			this.canvasContext.restore();
			this.canvasContext.clearRect(
				0,
				0,
				this.canvasContext.canvas.width,
				this.canvasContext.canvas.height,
			);

			// Save default context
			this.canvasContext.save();

			if (
				this.drawPage
				&& !this.isCropModeActive
			) {
				// Draw page background color
				this.canvasContext.fillStyle = (
					(
						this.internalPageModel.bgcolor
						&& this.internalPageModel.bgcolor.length > 0
					)
						? this.internalPageModel.bgcolor
						: '#FFFFFF'
				);
				this.canvasContext.fillRect(
					0,
					0,
					this.canvasContext.canvas.width,
					this.canvasContext.canvas.height,
				);

				// Draw page background pattern
				if (this.internalPageModel._bgpattern) {
					this.canvasContext.save();

					const patternScale = (this.scaling * this.zoomMaxLevel * this.devicePixelRatio) / 2;
					const pattern = this.canvasContext.createPattern(
						this.internalPageModel._bgpattern,
						'repeat',
					);

					if (pattern) {
						this.canvasContext.scale(
							patternScale,
							patternScale,
						);
						this.canvasContext.fillStyle = pattern;
						this.canvasContext.fillRect(
							0,
							0,
							this.canvasContext.canvas.width / patternScale,
							this.canvasContext.canvas.height / patternScale,
						);
					} else {
						throw new Error('Could not draw pattern');
					}

					this.canvasContext.restore();
				}
			}

			// Scale canvas
			this.canvasContext.scale(
				this.scaling * this.zoomMaxLevel * this.devicePixelRatio,
				this.scaling * this.zoomMaxLevel * this.devicePixelRatio,
			);

			// Draw page background image
			if (
				this.drawPage
				&& this.internalPageModel._bgimage
				&& !this.isCropModeActive
			) {
				const bgOffset = this.bleedMargin - this.internalPageModel.offset;
				this.canvasContext.drawImage(
					this.internalPageModel._bgimage,
					bgOffset,
					bgOffset,
					this.internalPageModel.width + 2 * this.internalPageModel.offset,
					this.internalPageModel.height + 2 * this.internalPageModel.offset,
				);
			}

			if (
				this.drawPage
				&& !this.isCropModeActive
				&& (
					(
						this.internalPageModel.bgpattern
						&& !this.internalPageModel._bgpattern
					)
					|| (
						this.internalPageModel.bgimage
						&& !this.internalPageModel._bgimage
					)
				)
			) {
				// Show loader
				const charCode = 0xf251;
				const fontSize = Math.min(
					this.internalPageModel.width,
					this.internalPageModel.height,
				) * 0.25;

				// Draw blank background
				this.canvasContext.fillStyle = '#fff';
				this.canvasContext.fillRect(
					0,
					0,
					this.internalPageModel.width,
					this.internalPageModel.height,
				);

				// Set style for drawing spinner
				this.canvasContext.font = `${fontSize}px "Font Awesome 5 Pro"`;
				this.canvasContext.textAlign = 'center';
				this.canvasContext.textBaseline = 'middle';
				this.canvasContext.translate(
					this.internalPageModel.width / 2,
					this.internalPageModel.height / 2,
				);

				this.canvasContext.fillStyle = '#fff';
				this.canvasContext.fillRect(
					-fontSize / 2,
					-fontSize / 2,
					fontSize,
					fontSize,
				);

				this.canvasContext.fillStyle = 'rgba(0,0,0,0.5)';
				this.canvasContext.fillText(
					String.fromCharCode(charCode),
					0,
					0,
				);

				if (
					this.internalPageModel.bgpattern
					&& !this.internalPageModel._bgpattern
				) {
					this.loadAsset('bgpattern');
				} else {
					this.loadAsset('bgimage');
				}
			}

			// Draw objects on canvas
			// eslint-disable-next-line no-restricted-syntax
			for (const objectModel of this.internalDrawObjects) {
				this.drawObject(objectModel);
			}

			// Draw address
			if (this.addressModel) {
				this.drawAddress(this.addressModel);
			}

			if (this.drawOverlay) {
				const offset = this.bleedMargin;
				const {
					height,
					width,
				} = this.internalPageModel;

				if (this.offeringOverlayImage) {
					if (this.mirrorOverlay) {
						this.canvasContext.save();
						this.canvasContext.translate(
							width + 2 * offset,
							0,
						);
						this.canvasContext.scale(
							-1,
							1,
						);
					}

					this.canvasContext.drawImage(
						this.offeringOverlayImage,
						offset,
						offset,
						width,
						height,
					);

					if (this.mirrorOverlay) {
						this.canvasContext.restore();
					}
				} else if (
					this.offeringFrameImage
					&& this.offeringFrameModel
				) {
					const frameScale = this.offeringFrameModel.templateModel.width / (width + 2 * offset);
					this.canvasContext.drawImage(
						this.offeringFrameImage,
						0,
						0,
						this.offeringFrameModel.imageModel.width,
						this.offeringFrameModel.imageModel.height,
						-this.offeringFrameModel.templateModel.x / frameScale,
						-this.offeringFrameModel.templateModel.y / frameScale,
						this.offeringFrameModel.imageModel.width / frameScale,
						this.offeringFrameModel.imageModel.height / frameScale,
					);
				}
			}

			if (
				this.drawMask
				&& this.offeringMaskImage
				&& this.drawPage
			) {
				const offset = this.bleedMargin;
				const {
					height,
					width,
				} = this.internalPageModel;

				this.canvasContext.save();
				this.canvasContext.globalCompositeOperation = 'destination-in';

				this.canvasContext.drawImage(
					this.offeringMaskImage,
					offset,
					offset,
					width,
					height,
				);

				this.canvasContext.restore();
			}

			await Promise.all(this.drawOnCanvasPromises);

			if (!this.redraw) {
				this.$emit(
					'drawn',
					this.canvasElement,
				);
			}
		} finally {
			this.drawOnCanvasPromises = undefined;
			this.computedIsDrawing = false;
		}
	}

	private drawPhotoObject(
		objectModel: PageObjectModel,
		vars: PageObjectVars,
	): void {
		if (
			vars.borderwidth > 0
			&& !objectModel.borderimage
		) {
			this.canvasContext.fillStyle = vars.bordercolor && vars.bordercolor.length > 0 ? vars.bordercolor : '#000000';
			this.canvasContext.fillRect(
				-vars.borderwidth,
				-vars.borderwidth,
				(vars.width + 2 * vars.borderwidth),
				(vars.height + 2 * vars.borderwidth),
			);
		}

		if (vars.cropwidth > 0) {
			if (
				objectModel._canvas
				&& this.drawPage
			) {
				// Note: If we use clipping here we sometimes loose objects on the canvas (weird!)
				this.canvasContext.drawImage(
					objectModel._canvas,
					vars.max.x_axis - vars.x_axis,
					vars.max.y_axis - vars.y_axis,
					vars.max.width,
					vars.max.height,
				);
			} else if (objectModel._image) {
				// Note: iOS has serious issues with clipping within the drawImage method (results in squashed image) but works with this alternative method
				this.canvasContext.save();
				this.canvasContext.beginPath();
				this.canvasContext.rect(
					0,
					0,
					Math.round(vars.width),
					Math.round(vars.height),
				);
				this.canvasContext.clip();
				this.canvasContext.drawImage(
					objectModel._image,
					vars.max.x_axis - vars.x_axis,
					vars.max.y_axis - vars.y_axis,
					vars.max.width,
					vars.max.height,
				);
				this.canvasContext.restore();
			}
		} else if (objectModel._image) {
			this.canvasContext.drawImage(
				objectModel._image,
				0,
				0,
				Math.round(vars.width),
				Math.round(vars.height),
			);
		}

		if (
			objectModel.mask
			&& objectModel._mask
			&& !this.drawPage
			&& !this.isCropModeActive
		) {
			this.canvasContext.save();
			this.canvasContext.globalCompositeOperation = 'destination-in';
			this.canvasContext.drawImage(
				objectModel._mask,
				0,
				0,
				vars.width,
				vars.height,
			);

			if (!objectModel._canvas && vars.borderwidth > 0) {
				this.canvasContext.globalCompositeOperation = 'destination-over';
				this.canvasContext.fillStyle = vars.bordercolor && vars.bordercolor.length > 0 ? vars.bordercolor : '#000000';
				this.canvasContext.fillRect(
					-vars.borderwidth,
					-vars.borderwidth,
					(vars.width + 2 * vars.borderwidth),
					(vars.height + 2 * vars.borderwidth),
				);
			}

			this.canvasContext.restore();
		}

		if (
			objectModel.borderimage
			&& objectModel._borderimage
		) {
			const borderImageModel = ThemeDataModule.getBorderImage(objectModel.borderimage);

			if (borderImageModel) {
				const borderImageInnerWidth = borderImageModel.width - borderImageModel.borderwidth_left - borderImageModel.borderwidth_right;
				const borderscale = vars.width / borderImageInnerWidth;
				const borderWidthTop = Math.round(borderImageModel.borderwidth_top * borderscale);
				const borderWidthRight = Math.round(borderImageModel.borderwidth_right * borderscale);
				const borderWidthBottom = Math.round(borderImageModel.borderwidth_bottom * borderscale);
				const borderWidthLeft = Math.round(borderImageModel.borderwidth_left * borderscale);

				this.canvasContext.drawImage(
					objectModel._borderimage,
					-borderWidthLeft,
					-borderWidthTop,
					Math.round(vars.width) + borderWidthLeft + borderWidthRight,
					Math.round(vars.height) + borderWidthTop + borderWidthBottom,
				);
			}
		}
	}

	private drawTextObject(
		objectModel: PageObjectModel,
		vars: PageObjectVars,
	): void {
		if (
			objectModel.fontface
			&& objectModel.pointsize
			&& objectModel.text
		) {
			const fontModel = FontModule.getById(objectModel.fontface);

			if (fontModel) {
				const indent = 0; // indent to position text in relation to x_axis object
				let drawNormalText = true;

				if (!fontModel._loaded) {
					this.loadFont(
						fontModel,
						vars.width - indent,
						vars.height,
					);
				} else {
					let indentAlign = 0; // extra indent required to compensate for canvas alignment method
					const font = objectModel.fontface;
					const pxsize = objectModel.pointsize * 31496 / 15120;
					const lineheight = pxsize * 1.25;
					const {
						cropx,
						cropy,
					} = objectModel;

					if (
						objectModel.text_svg
						&& fontModel._fontUrl
					) {
						if (!fontModel._datauri) {
							drawNormalText = false;
							this.drawOnCanvasPromises?.push(
								this.loadFontDataUri(
									fontModel,
									vars.width - indent,
									vars.height,
								),
							);
						} else if (objectModel._image) {
							drawNormalText = false;
							this.canvasContext.drawImage(
								objectModel._image,
								cropx,
								cropy,
								objectModel._image.width,
								objectModel._image.height,
								cropx,
								cropy,
								vars.width,
								vars.height,
							);
						}
					}

					if (drawNormalText) {
						if (
							objectModel.bgcolor
							&& objectModel.bgcolor.length > 0
						) {
							this.canvasContext.fillStyle = objectModel.bgcolor;
						} else {
							this.canvasContext.fillStyle = 'rgba(0,0,0,0)';
						}

						this.canvasContext.strokeStyle = '#a9a9a9';
						this.canvasContext.fillRect(
							indent,
							0,
							vars.width - indent,
							vars.height,
						);

						if (fontModel.url) {
							if (
								objectModel.fontitalic
								&& objectModel.fontbold
								&& fontModel.bolditalic
							) {
								this.canvasContext.font = `${pxsize}px ${font} BoldItalic`;
							} else if (objectModel.fontitalic && fontModel.italic) {
								this.canvasContext.font = `${pxsize}px ${font} Italic`;
							} else if (objectModel.fontbold && fontModel.bold) {
								this.canvasContext.font = `${pxsize}px ${font} Bold`;
							} else {
								this.canvasContext.font = `${pxsize}px ${font}`;
							}
						} else if (
							objectModel.fontitalic
							&& objectModel.fontbold
							&& fontModel.bolditalic
						) {
							this.canvasContext.font = `italic bold ${pxsize}px ${font}`;
						} else if (
							objectModel.fontitalic
							&& fontModel.italic
						) {
							this.canvasContext.font = `italic ${pxsize}px ${font}`;
						} else if (
							objectModel.fontbold
							&& fontModel.bold
						) {
							this.canvasContext.font = `bold ${pxsize}px ${font}`;
						} else {
							this.canvasContext.font = `${pxsize}px ${font}`;
						}

						if (objectModel.fontcolor_visual?.length) {
							this.canvasContext.fillStyle = objectModel.fontcolor_visual;
						} else if (objectModel.fontcolor?.length) {
							this.canvasContext.fillStyle = objectModel.fontcolor;
						} else {
							this.canvasContext.fillStyle = '#000000';
						}

						this.canvasContext.textBaseline = 'middle';

						switch (objectModel.align) {
							case 'Right':
								this.canvasContext.textAlign = 'right';
								indentAlign = vars.width - indent;
								break;
							case 'Center':
								this.canvasContext.textAlign = 'center';
								indentAlign = (vars.width - indent) / 2;
								break;
							case 'Left':
							default:
								this.canvasContext.textAlign = 'left';
								break;
						}

						if (
							objectModel.text_formatted
							&& objectModel.text_formatted.length > 0
						) {
							if (objectModel.text_formatted_for_canvas) {
								objectModel.text_formatted_for_canvas
									.split('\n')
									.forEach((text, linenr) => {
										this.canvasContext.fillText(
											text,
											indent + indentAlign + cropx,
											(0.5 + linenr) * lineheight + cropy,
										);
									});
							} else {
								objectModel.text_formatted
									.split('\n')
									.forEach((text, linenr) => {
										this.canvasContext.fillText(
											text,
											indent + indentAlign + cropx,
											(0.5 + linenr) * lineheight + cropy,
										);
									});
							}
						} else {
							const patt = new RegExp('<% .+? %>');

							if (
								objectModel.text.length > 0
								&& !patt.test(objectModel.text)
							) {
								this.canvasContext.fillText(
									objectModel.text,
									indent + indentAlign + cropx,
									0.5 * lineheight + cropy,
								);
							}
						}

						if (vars.borderwidth > 0) {
							const rectangle = {
								x: -vars.borderwidth,
								y: -vars.borderwidth,
								width: (vars.width + 2 * vars.borderwidth),
								height: (vars.height + 2 * vars.borderwidth),
							};

							this.canvasContext.fillRect(
								rectangle.x,
								rectangle.y,
								rectangle.width,
								rectangle.height,
							);
						}
					}
				}
			} else {
				throw new Error('Could not find required font model');
			}
		}
	}

	private emitDrawObjectsChange(objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>>): void {
		this.$emit(
			'draw-objects-change',
			this.internalDrawObjects,
			objectsDifferences,
		);
		this.drawOnCanvas();
	}

	@Public()
	public getCanvasElement(): HTMLCanvasElement {
		return this.canvasElement;
	}

	private getCanvasHeight(scaling: number): number {
		let height: number;

		if (
			this.internalDrawObjects
			&& this.internalDrawObjects.length
			&& !this.drawPage
		) {
			const objectModel = this.internalDrawObjects[0];

			if (!this.isCropModeActive) {
				const vars = PageObject.calculatePosition(
					{
						x_axis: objectModel.x_axis,
						y_axis: objectModel.y_axis,
						width: objectModel.width,
						height: objectModel.height,
						borderwidth: objectModel.borderwidth,
						bordercolor: objectModel.bordercolor,
						cropx: objectModel.cropx,
						cropy: objectModel.cropy,
						cropwidth: objectModel.cropwidth,
						cropheight: objectModel.cropheight,
						angle: objectModel.rotate,
						flop: Boolean(objectModel.flop),
						flip: Boolean(objectModel.flip),
						type: objectModel.type,
						maxwidth: objectModel.maxwidth,
						photoid: objectModel.photoid || undefined,
					},
					scaling,
					this.internalPageModel,
					this.bleedMargin,
					this.offeringModel,
				);
				height = vars.canvas.height;
			} else {
				height = (this.height + (this.bleedMargin * 2)) * scaling;
			}
		} else {
			height = (this.height + (this.bleedMargin * 2)) * scaling;
		}

		return Math.round(height);
	}

	private getCanvasWidth(scaling: number): number {
		let width: number;

		if (
			this.internalDrawObjects
			&& this.internalDrawObjects.length
			&& !this.drawPage
		) {
			const objectModel = this.internalDrawObjects[0];

			if (!this.isCropModeActive) {
				const vars = PageObject.calculatePosition(
					{
						x_axis: objectModel.x_axis,
						y_axis: objectModel.y_axis,
						width: objectModel.width,
						height: objectModel.height,
						borderwidth: objectModel.borderwidth,
						bordercolor: objectModel.bordercolor,
						cropx: objectModel.cropx,
						cropy: objectModel.cropy,
						cropwidth: objectModel.cropwidth,
						cropheight: objectModel.cropheight,
						angle: objectModel.rotate,
						flop: Boolean(objectModel.flop),
						flip: Boolean(objectModel.flip),
						type: objectModel.type,
						maxwidth: objectModel.maxwidth,
						photoid: objectModel.photoid || undefined,
					},
					scaling,
					this.internalPageModel,
					this.bleedMargin,
					this.offeringModel,
				);
				width = vars.canvas.width;
			} else {
				width = (this.width + (this.bleedMargin * 2)) * scaling;
			}
		} else {
			width = (this.width + (this.bleedMargin * 2)) * scaling;
		}

		return Math.round(width);
	}

	private loadAsset(type: 'bgimage' | 'bgpattern'): void {
		let src = '';

		// if photo has not been downloaded, load from source
		const propertyValue = this.internalPageModel[type];

		if (this.internalPageModel._loading) {
			return;
		}

		this.internalPageModel._loading = true;

		if (
			propertyValue
			&& propertyValue.substring(
				0,
				4,
			) == 'http'
		) {
			src = propertyValue;
		} else if (propertyValue) {
			const bucketURL = getBucketUrl('appfiles');
			src = bucketURL + propertyValue;
		}

		const k = `_${type}` as '_bgimage' | '_bgpattern';

		this.drawOnCanvasPromises?.push(
			loadImage(src)
				.then(({ image }) => {
					this.internalPageModel[k] = image;
				})
				.catch(() => {
					// Check if page background image reference has not already been removed
					if (this.internalPageModel[k]) {
						// Reset page background image reference
						this.internalPageModel[k] = null;
					}
				})
				.then(() => {
					this.internalPageModel._loading = false;
					this.$emit(
						'page-model-change',
						this.internalPageModel,
					);
					this.drawOnCanvas();
				}),
		);
	}

	private loadFont(
		fontModel: FontModel,
		loaderWidth: number,
		loaderHeight: number,
	): void {
		if (
			this.showLoaders
			&& !fontModel._loaded
		) {
			this.drawLoader(
				loaderWidth,
				loaderHeight,
				0xf252,
			);
		}

		this.drawOnCanvasPromises?.push(
			FontModule
				.loadModel(fontModel.id)
				.then(this.drawOnCanvas)
				.catch(() => {
					const closeError = this.$openErrorDialog({
						body: {
							content: this.$t('dialogTextLoadError'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: this.$t('dialogButtonOk'),
									click: () => {
										this.loadFont(
											fontModel,
											loaderWidth,
											loaderHeight,
										);
										closeError();
									},
								},
							],
						},
					});
				}),
		);
	}

	private loadFontDataUri(
		fontModel: FontModel,
		loaderWidth: number,
		loaderHeight: number,
		options?: {
			redraw?: boolean;
			disableLoaders?: boolean;
		},
	): Promise<void> {
		const defaultOptions = {
			redraw: true,
			disableLoaders: false,
		};
		const finalOptions = {
			...defaultOptions,
			...options,
		};

		if (
			this.showLoaders
			&& !finalOptions.disableLoaders
			&& !fontModel._datauri
		) {
			this.drawLoader(
				loaderWidth,
				loaderHeight,
				0xf252,
			);
		}

		return FontModule
			.loadDataUri(fontModel.id)
			.then(() => {
				if (finalOptions.redraw) {
					return this.drawOnCanvas();
				}

				return undefined;
			})
			.catch(() => {
				// Shallow error: no action required
			});
	}

	private loadPhoto(
		objectModel: PageObjectModel,
		loaderWidth: number,
		loaderHeight: number,
	): void {
		if (
			this.showLoaders
			&& !objectModel._resetImage
		) {
			this.drawLoader(
				loaderWidth,
				loaderHeight,
				0xf03e,
			);
		}

		if (objectModel._error >= 3) {
			if (!this.photoLoadErrorShown) {
				this.photoLoadErrorShown = true;
				this.$openDialogNew({
					header: {
						title: this.$t('views.editorDraw.dialogs.errorLoadingPhoto.title'),
					},
					body: {
						content: this.$t('views.editorDraw.dialogs.errorLoadingPhoto.content'),
					},
					listeners: {
						close: () => {
							this.photoLoadErrorShown = false;
						},
					},
				});
			}
			return;
		}

		objectModel._loading = true;
		this.onInternalDrawObjectsChange();
		this.drawOnCanvasPromises?.push(
			loadPhotoObject(
				{
					...objectModel,
					_loading: false,
				},
				{
					mask: true,
					no_store: true,
					offeringModel: this.offeringModel,
					resolution: this.resolution,
					scaling: this.scaling * this.zoomMaxLevel,
				},
			)
				.then(async (updatedObjectModel) => {
					if (
						updatedObjectModel._vectorSVG
						&& updatedObjectModel._image
						&& updatedObjectModel._vectorColors
					) {
						const vectorColorsKeys = Array.from(updatedObjectModel._vectorColors.foreground.keys());
						const colorReplacement: SVGColorReplacement[] = [];

						if (
							this.offeringModel
							&& this.offeringModel.color !== COLOR_FULL
							&& !updatedObjectModel.colorReplacement
						) {
							updatedObjectModel.colorReplacement = [];
							const colorConverter = new ColorConverter();

							// eslint-disable-next-line no-restricted-syntax
							for (const vectorColorsKey of vectorColorsKeys) {
								colorConverter.hex6 = {
									value: vectorColorsKey.slice(1),
								};

								try {
									colorConverter.pantone = {
										name: colorConverter.pantone.name,
									};
									colorReplacement.push({
										color: vectorColorsKey,
										replace: (
											colorConverter.hex6.showAs
											|| colorConverter.hex6.value
										),
									});
									const objectModelColorReplacement: PageObjectColorReplacementModel = {
										color: vectorColorsKey,
										replace: {
											real: `#${colorConverter.hex6.value}`,
										},
									};

									if (colorConverter.hex6.showAs) {
										objectModelColorReplacement.replace.visual = `#${colorConverter.hex6.showAs}`;
									}

									updatedObjectModel.colorReplacement.push(objectModelColorReplacement);
								} catch {
									// Shallow error: no action required
								}
							}
						} else if (updatedObjectModel.colorReplacement) {
							// eslint-disable-next-line no-restricted-syntax
							for (const colorToReplace of updatedObjectModel.colorReplacement) {
								colorReplacement.push({
									color: colorToReplace.color,
									replace: (
										colorToReplace.replace.visual
										|| colorToReplace.replace.real
									),
								});
							}
						}

						const svgWithReplacedColors = svgUtils.replaceColors(
							updatedObjectModel._vectorSVG,
							updatedObjectModel._vectorColors,
							colorReplacement,
						);

						await new Promise<void>((resolve, reject) => {
							const svgBlob = new Blob(
								[svgWithReplacedColors],
								{
									type: 'image/svg+xml;charset=utf-8',
								},
							);
							const svgBase64URL = URL.createObjectURL(svgBlob);
							const img = document.createElement('img');
							img.crossOrigin = 'anonymous';
							img.onload = () => {
								updatedObjectModel._image = img;
								resolve();
								URL.revokeObjectURL(svgBase64URL);
							};
							img.onerror = () => {
								reject(new Error('Could not load the SVG image'));
								URL.revokeObjectURL(svgBase64URL);
							};
							img.src = svgBase64URL;
						});

						if (objectModel._resetImage) {
							updatedObjectModel._resetImage = false;
						}
					}

					if (
						updatedObjectModel.mask
						&& updatedObjectModel._image
						&& !updatedObjectModel._canvas
					) {
						const dynamicPhotoScaledUsed = experiment.getFlagValue('flag_dynamic_photo_scaling');
						const loadObjectMaskOptions: LoadObjectMaskOptions = {
							resolution: this.resolution,
							no_store: true,
						};

						if (dynamicPhotoScaledUsed) {
							loadObjectMaskOptions.dynamicWidth = objectModel._image.width;
						}

						return loadObjectMask(
							updatedObjectModel,
							loadObjectMaskOptions,
						);
					}

					return updatedObjectModel;
				})
				.then((updatedObjectModel) => {
					const objectDifferences = objectUtils.getObjectDifferences(
						updatedObjectModel,
						objectModel,
					);

					if (objectDifferences.includes('_resetImage')) {
						objectDifferences.push('_image');
					}

					if (objectDifferences.length) {
						// eslint-disable-next-line no-restricted-syntax
						for (const objectDifference of objectDifferences) {
							(objectModel as any)[objectDifference] = updatedObjectModel[objectDifference];
						}
					}

					this.onInternalDrawObjectsChange();
				})
				.catch((error: Error) => {
					objectModel._error += 1;
					objectModel._loading = false;
					this.onInternalDrawObjectsChange();

					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							error,
							(event) => {
								event.severity = 'error';
							},
						);
					}
				}),
		);
	}

	private loadTextSVG(
		fontModel: FontModel,
		objectModel: PageObjectModel,
	): Promise<void> {
		if (
			fontModel._datauri
			&& objectModel.text_svg
			&& !objectModel._image
		) {
			const tmpDivElement = document.createElement('div');
			tmpDivElement.innerHTML = objectModel.text_svg;
			const svgElement = tmpDivElement.firstChild as SVGElement;
			const styleElement = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'style',
			);
			styleElement.textContent = fontModel._datauri;
			svgElement.prepend(styleElement);
			const svgWithEmbeddedFont = new XMLSerializer().serializeToString(svgElement);
			const svgBlob = new Blob(
				[svgWithEmbeddedFont],
				{
					type: 'image/svg+xml;charset=utf-8',
				},
			);
			const svgBase64URL = URL.createObjectURL(svgBlob);
			const svgImage = new Image();
			svgImage.height = objectModel.height;
			svgImage.width = objectModel.width;

			return new Promise((resolve, reject) => {
				svgImage.crossOrigin = 'anonymous';
				svgImage.onload = resolve;
				svgImage.onerror = reject;
				svgImage.src = svgBase64URL;
			})
				.then(() => {
					objectModel._image = svgImage;
					this.onInternalDrawObjectsChange();
				})
				.catch(() => {
					// Shallow error: no action required
				})
				.finally(() => {
					tmpDivElement.remove();
					URL.revokeObjectURL(svgBase64URL);
				});
		}

		return Promise.resolve();
	}

	private onInternalDrawObjectsChange(): void {
		const newObjects = this.internalDrawObjects;
		const oldObjects = this.drawObjects;
		let changed = false;
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {};

		if (
			!oldObjects
			|| oldObjects.length !== newObjects.length
		) {
			changed = true;
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const newObject of newObjects) {
			const oldObjectFound = oldObjects.find((oldObject) => oldObject.id === newObject.id);

			if (!oldObjectFound) {
				changed = true;
			} else {
				const objectDifferences = objectUtils.getObjectDifferences(
					newObject,
					oldObjectFound,
				);

				if (objectDifferences.indexOf('photoid') > -1) {
					objectDifferences.splice(
						objectDifferences.indexOf('photoid'),
						1,
					);
				}

				if (objectDifferences.length) {
					objectsDifferences[newObject.id] = objectDifferences;
					changed = true;
				}
			}
		}

		if (changed) {
			this.emitDrawObjectsChange(objectsDifferences);
		}
	}

	private updateDrawObjects(): void {
		this.updateDrawObjectsAbortController?.abort();
		this.updateDrawObjectsAbortController = new AbortController();
		new Promise<boolean>((resolve, reject) => {
			const abortListener = () => {
				this.updateDrawObjectsAbortController?.signal.removeEventListener(
					'abort',
					abortListener,
				);
				reject();
			};
			this.updateDrawObjectsAbortController?.signal.addEventListener(
				'abort',
				abortListener,
			);

			const newObjects = this.drawObjects;
			const oldObjects = this.internalDrawObjects;
			const objectsToRemove: PageObjectModel[] = [];
			let changed = false;

			if (
				!oldObjects
				|| oldObjects.length < 1
			) {
				if (
					newObjects
					&& newObjects.length > 0
				) {
					this.internalDrawObjects = newObjects.map((drawObject) => ({
						...drawObject,
					}));
					changed = true;
				}
			} else {
				// eslint-disable-next-line no-restricted-syntax
				for (const newObject of newObjects) {
					const oldObjectFound = oldObjects.find((oldObject) => oldObject.id === newObject.id);

					if (!oldObjectFound) {
						changed = true;
						this.internalDrawObjects.push({
							...newObject,
						});
						// eslint-disable-next-line no-continue
						continue;
					}

					const objectDifferences = objectUtils.getObjectDifferences(
						newObject,
						oldObjectFound,
					);

					if (objectDifferences.length) {
						changed = true;

						// eslint-disable-next-line no-restricted-syntax
						for (const objectDifference of objectDifferences) {
							(oldObjectFound as any)[objectDifference] = newObject[objectDifference];
						}

						if (
							objectDifferences.includes('photoid')
							&& oldObjectFound._loading
						) {
							oldObjectFound._loading = false;
						}
					}
				}

				// eslint-disable-next-line no-restricted-syntax
				for (const oldObject of oldObjects) {
					const newObjectFound = newObjects.find((newObject) => newObject.id === oldObject.id);

					if (!newObjectFound) {
						changed = true;
						objectsToRemove.push(oldObject);
					}
				}

				// eslint-disable-next-line no-restricted-syntax
				for (const objectToRemove of objectsToRemove) {
					this.internalDrawObjects.splice(
						this.internalDrawObjects.indexOf(objectToRemove),
						1,
					);
				}
			}

			resolve(changed);
		})
			.then((changed) => {
				this.updateDrawObjectsAbortController = undefined;

				if (changed) {
					this.drawOnCanvas();
				}
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}
}
