import './defines';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorPreviewCubeView',
})
export default class EditorPreviewCubeView extends Vue.extend(Template) {

}
