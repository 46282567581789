import 'core-js/stable';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { Observable } from 'zen-observable-ts';
import { UploadProgressMessage } from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import navigate from 'controllers/navigate';
import Theme from 'classes/theme';
import upload from 'controllers/upload';
import Addresses from './modules/addresses/index';
import AppData from './modules/appdata/index';
import AppState from './modules/appstate/index';
import Cart from './modules/cart/index';
import CartItems from './modules/cartitems/index';
import Channels from './modules/channels/index';
import Config from './modules/config/index';
import Dialog from './modules/dialog/index';
import Discount from './modules/discount/index';
import ExternalUsers from './modules/externalusers/index';
import Font from './modules/font/index';
import Orders from './modules/orders/index';
import OrderItems from './modules/orderitems/index';
import Photos from './modules/photos/index';
import Products from './modules/products/index';
import ProductState from './modules/productstate/index';
import Progress from './modules/progress/index';
import RemoteUser from './modules/remoteuser/index';
import Shipping from './modules/shipping/index';
import Subscriptions from './modules/subscriptions/index';
import ThemeData from './modules/themedata/index';
import ThemeState from './modules/themestate/index';
import Upload from './modules/upload/index';
import User from './modules/user/index';
import UserData from './modules/userdata/index';

const NS_ADDRESS = 'address';
const NS_APPDATA = 'appdata';
const NS_APPSTATE = 'appstate';
const NS_CART = 'cart';
const NS_CARTITEMS = 'cartItems';
const NS_CHANNELS = 'channels';
const NS_CONFIG = 'config';
const NS_DIALOG = 'dialog';
const NS_DISCOUNT = 'discount';
const NS_EXTUSERS = 'externalusers';
const NS_FONT = 'font';
const NS_ORDERITEMS = 'orderitems';
const NS_ORDERS = 'orders';
const NS_PHOTOS = 'photos';
const NS_PRODUCTS = 'products';
const NS_PRODUCTSTATE = 'productstate';
const NS_PROGRESS = 'progress';
const NS_REMOTEUSER = 'remoteuser';
const NS_SHIPPING = 'shipping';
const NS_SUBSCRIPTIONS = 'subscriptions';
const NS_THEMEDATA = 'themedata';
const NS_UPLOAD = 'upload';
const NS_THEMESTATE = 'themestate';
const NS_USER = 'user';
const NS_USERDATA = 'userdata';

interface StoreType {
	[NS_ADDRESS]: Addresses;
	[NS_APPDATA]: AppData;
	[NS_APPSTATE]: AppState;
	[NS_CART]: Cart;
	[NS_CARTITEMS]: CartItems;
	[NS_CHANNELS]: Channels;
	[NS_CONFIG]: Config;
	[NS_DIALOG]: Dialog;
	[NS_DISCOUNT]: Discount;
	[NS_EXTUSERS]: ExternalUsers;
	[NS_FONT]: Font;
	[NS_ORDERITEMS]: OrderItems;
	[NS_ORDERS]: Orders;
	[NS_PHOTOS]: Photos;
	[NS_PRODUCTS]: Products;
	[NS_PRODUCTSTATE]: ProductState;
	[NS_PROGRESS]: Progress;
	[NS_REMOTEUSER]: RemoteUser;
	[NS_SHIPPING]: Shipping;
	[NS_SUBSCRIPTIONS]: Subscriptions;
	[NS_THEMEDATA]: ThemeData;
	[NS_THEMESTATE]: ThemeState;
	[NS_UPLOAD]: Upload;
	[NS_USER]: User;
	[NS_USERDATA]: UserData;
	[NS_UPLOAD]: Upload;
}

const storeOptions: StoreOptions<StoreType> = {
	// strict: process.env.NODE_ENV !== 'production',
	strict: false,
	modules: {
		[NS_ADDRESS]: Addresses,
		[NS_APPDATA]: AppData,
		[NS_APPSTATE]: AppState,
		[NS_CART]: Cart,
		[NS_CARTITEMS]: CartItems,
		[NS_CHANNELS]: Channels,
		[NS_CONFIG]: Config,
		[NS_DIALOG]: Dialog,
		[NS_DISCOUNT]: Discount,
		[NS_EXTUSERS]: ExternalUsers,
		[NS_FONT]: Font,
		[NS_ORDERS]: Orders,
		[NS_ORDERITEMS]: OrderItems,
		[NS_PHOTOS]: Photos,
		[NS_PRODUCTS]: Products,
		[NS_PRODUCTSTATE]: ProductState,
		[NS_PROGRESS]: Progress,
		[NS_REMOTEUSER]: RemoteUser,
		[NS_SHIPPING]: Shipping,
		[NS_SUBSCRIPTIONS]: Subscriptions,
		[NS_THEMEDATA]: ThemeData,
		[NS_THEMESTATE]: ThemeState,
		[NS_USER]: User,
		[NS_USERDATA]: UserData,
		[NS_UPLOAD]: Upload,
	},
};

Vue.use(Vuex);

if (process.env.NODE_ENV !== 'production') {
	Vue.config.devtools = true;
}

const store = new Vuex.Store<StoreType>(storeOptions);

export default store;
export const AddressesModule = getModule(
	Addresses,
	store,
);
export const AppDataModule = getModule(
	AppData,
	store,
);
export const AppStateModule = getModule(
	AppState,
	store,
);
export const CartItemsModule = getModule(
	CartItems,
	store,
);
export const CartModule = getModule(
	Cart,
	store,
);
export const ChannelsModule = getModule(
	Channels,
	store,
);
export const ConfigModule = getModule(
	Config,
	store,
);
export const DialogModule = getModule(
	Dialog,
	store,
);
export const DiscountModule = getModule(
	Discount,
	store,
);
export const ExternalUsersModule = getModule(
	ExternalUsers,
	store,
);
export const FontModule = getModule(
	Font,
	store,
);
export const OrderItemsModule = getModule(
	OrderItems,
	store,
);
export const OrdersModule = getModule(
	Orders,
	store,
);
export const PhotosModule = getModule(
	Photos,
	store,
);
export const ProductsModule = getModule(
	Products,
	store,
);
export const ProductStateModule = getModule(
	ProductState,
	store,
);
export const ProgressModule = getModule(
	Progress,
	store,
);
export const RemoteUserModule = getModule(
	RemoteUser,
	store,
);
export const ShippingModule = getModule(
	Shipping,
	store,
);
export const SubscriptionsModule = getModule(
	Subscriptions,
	store,
);
export const ThemeDataModule = getModule(
	ThemeData,
	store,
);
export const ThemeStateModule = getModule(
	ThemeState,
	store,
);
export const UploadModule = getModule(
	Upload,
	store,
);
export const UserModule = getModule(
	User,
	store,
);
export const UserDataModule = getModule(
	UserData,
	store,
);

// Listen to user country change
store.watch(
	(state, getters) => getters[`${NS_USER}/countryId`],
	async (countryid) => {
		if (countryid) {
			if (!UserModule.currency) {
				const countryModel = AppDataModule.getCountry(countryid);

				if (countryModel) {
					UserModule.set({
						currency: countryModel.currency,
					});

					if (UserModule.userId) {
						UserModule.put();
					}
				}
			}

			if (UserModule.countryid) {
				// Change currency if the currently selected currency is not available in the region
				const countryModel = AppDataModule.getCountry(UserModule.countryid);

				if (countryModel) {
					/**
					 * Search for possible missing offerings data
					 * linked to shopping cart items
					 */
					const offeringIdsToFetch: number[] = [];

					// eslint-disable-next-line no-restricted-syntax
					for (const cartItemModel of CartItemsModule.collection) {
						const offeringModelFound = AppDataModule.findOfferingWhere({
							id: cartItemModel.offeringid,
						});

						if (!offeringModelFound) {
							offeringIdsToFetch.push(cartItemModel.offeringid);
						}
					}

					/**
					 * If there are missing offerings data, fetch it
					 * from the server
					 */
					if (offeringIdsToFetch.length > 0) {
						await AppDataModule.fetchOfferingsData({
							offeringIds: offeringIdsToFetch,
						});
					}

					// Destroy shopping cart items not available in this region
					CartItemsModule.cleanByCountryId(UserModule.countryid);

					if (
						UserModule.currency
						&& !AppDataModule.findRegionCurrencyLinkWhere({
							regionid: countryModel.regionid,
							currencyid: UserModule.currency,
						})
					) {
						const currency = AppDataModule
							.findRegionCurrencyLinkWhere({
								regionid: countryModel.regionid,
							})
							?.currencyid;

						if (currency) {
							UserModule.set({
								currency,
							});

							if (UserModule.userId) {
								UserModule.put();
							}
						}
					}
				}
			}
		}
	},
);

// Listen to userID change
store.watch(
	(state, getters) => getters[`${NS_USER}/userId`],
	() => {
	// Reset meta data for user- products and photos collection (since the user could have more attached to this new userid)
		ProductsModule.resetMetaData();
	},
);

// listen to changes in the cart item collection
store.watch(
	(state) => state[NS_CARTITEMS].collection,
	() => {
	// Fetch new shipping data next time the data is requested
		ShippingModule.resetMetaData();
	},
);

if (!window.publicData) {
	window.publicData = {
		editor: 'FastEditor',
	};
}

// Listen to changes in the offering model of the project being worked on
store.watch(
	(state, getters) => getters[`${NS_PRODUCTSTATE}/getOffering`],
	(offeringModel) => {
		if (offeringModel) {
		// Update the publicly exposed parameters (for external integrations)
			window.publicData.offeringData = {
				internalId: offeringModel.id,
				externalId: offeringModel.externalid,
			};

			// Reset both the offering frame and the offering overlay images
			ProductStateModule.resetOverlayImage();
		} else {
			delete window.publicData.offeringData;
		}
	},
);

type AwsAppSyncType = typeof import('controllers/aws-appsync').default | null;

let awsAppSyncInstance: AwsAppSyncType = null;

async function importAwsAppSync() {
	if (awsAppSyncInstance === null) {
		awsAppSyncInstance = (await import('controllers/aws-appsync')).default;
	}
}

if (window.glPlatform !== 'server') {
	const subscribeToUserEvents = async () => {
		await importAwsAppSync();

		if (ConfigModule['appSync.url']) {
			if (UserModule.id) {
				// Subscribe to AWS AppSync for user data changes
				awsAppSyncInstance?.subscribe({
					type: 'user',
					url: ConfigModule['appSync.url'],
					channel: `user/${UserModule.id}`,
				}).then((userSubscriber: Observable<any>) => {
					userSubscriber.subscribe({
						next: (eventData) => {
							if (eventData.data.subscribe.event === 'updateCartItem') {
								const message = JSON.parse(eventData.data.subscribe.data) as DB.ShoppingCartItemModel;
								if (message && message.id && CartItemsModule.getById(message.id)) {
									CartItemsModule.updateModel(message);
								}
							}
							if (eventData.data.subscribe.event === 'addCartItem') {
								const message = JSON.parse(eventData.data.subscribe.data) as DB.ShoppingCartItemModel;
								if (message && message.id && !CartItemsModule.getById(message.id)) {
									CartItemsModule.addModel(message);
								}
							}

							if (eventData.data.subscribe.event === 'deleteCartItem') {
								const message = JSON.parse(eventData.data.subscribe.data) as OptionalExceptFor<DB.ShoppingCartItemModel, 'id'>;
								if (message && message.id && CartItemsModule.getById(message.id)) {
									try {
										CartItemsModule.removeModel(message.id);
									} catch (e) {
										// Swallow error: no action required
									}
								}
							}
						},
						error: () => {
							awsAppSyncInstance?.unsubscribe('user');
						},
					});
				});
			} else {
				awsAppSyncInstance?.unsubscribe('user');
			}
		}
	};

	const subscribeToProjectEvents = async () => {
		await importAwsAppSync();

		if (ConfigModule['appSync.url']) {
			if (ProductStateModule.getProduct) {
				// Subscribe to AWS AppSync for project data changes
				awsAppSyncInstance?.subscribe({
					type: 'project',
					channel: `product/${ProductStateModule.getProduct.id}`,
					url: ConfigModule['appSync.url'],
				}).then((projectSubscriber: Observable<any>) => {
					projectSubscriber.subscribe({
						next: (eventData) => {
							if (eventData?.data?.subscribe?.event === 'delete') {
								const message = JSON.parse(eventData.data.subscribe.data);
								const productData = message.product as OptionalExceptFor<DB.ProductModel, 'id'>;
								if (productData.deleted) {
									// Project is deleted by user (on another device)
									ProductStateModule.reset();

									if (AppStateModule.uploadOnly) {
										UserModule.logout();
									} else {
										navigate.toStart();
									}
								}
							}
							if (eventData?.data?.subscribe?.event === 'message') {
								const message = JSON.parse(eventData.data.subscribe.data) as PI.ProductDataModel;
								if (message && (!ProductStateModule.version || !message.version || ProductStateModule.version < message.version)) {
									// Update product data if the received version is newer than the local one

									ProductStateModule
										.replaceData({ data: message })
										.catch(() => {
											// Swallow error: no action required
										});
								}
							}
						},
						error: () => {
							awsAppSyncInstance?.unsubscribe('project');
						},
					});
				});
			} else {
				// Close the subscription if there is no active project to watch
				awsAppSyncInstance?.unsubscribe('project');
			}
		}
	};

	const subscribeToExternalUploadEvents = async () => {
		await importAwsAppSync();

		if (ConfigModule['appSync.url']) {
			if (UserModule.id
				&& ProductStateModule.getProduct
			) {
				awsAppSyncInstance?.subscribe({
					type: 'external_upload',
					channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
					url: ConfigModule['appSync.url'],
				}).then((externalUploadSubscriber: Observable<any>) => {
					externalUploadSubscriber.subscribe({
						next: (eventData) => {
							if (eventData?.data?.subscribe?.event === 'upload') {
								const message = JSON.parse(eventData.data.subscribe.data) as UploadProgressMessage;

								if (!AppStateModule.uploadOnly) {
									upload.updateProgress(
										message,
										true,
									);
								}
							} else if (eventData?.data?.subscribe?.event === 'abort'
								|| eventData?.data?.subscribe?.event === 'close'
							) {
								if (AppStateModule.uploadOnly) {
									UserModule.logout();
								}
							}
						},
						error: () => {
							awsAppSyncInstance?.unsubscribe('external_upload');
						},
					});
				});
			} else {
				// Close the subscription if there is no active user session or active project
				awsAppSyncInstance?.unsubscribe('external_upload');
			}
		}
	};

	// Listen to changes in the user id, so we can subscribe to server-side events
	store.watch(
		(state, getters) => getters[`${NS_USER}/userId`],
		() => {
			subscribeToUserEvents();
			subscribeToExternalUploadEvents();
		},
	);

	// Listen to changes in the project id, so we can subscribe to server-side events
	store.watch(
		(state, getters) => getters[`${NS_PRODUCTSTATE}/getProductId`],
		() => {
			subscribeToProjectEvents();
			subscribeToExternalUploadEvents();
		},
	);

	// Subscribe to server-side events once the endpoint for the subscription is known
	store.watch(
		(state) => state[NS_CONFIG]['appSync.url'],
		() => {
			subscribeToUserEvents();
			subscribeToProjectEvents();
			subscribeToExternalUploadEvents();
		},
	);
}

store.watch(
	(state, getters) => getters[`${NS_PRODUCTSTATE}/getThemeId`],
	(themeid) => {
		if (themeid
			&& ThemeStateModule.themeModel?.id
			&& ThemeStateModule.themeModel.id != themeid
		) {
			// The themeid was changed, but is now out of sync with the data in the ThemeState module
			// This is most likely due to a project data update recevied through SSE.
			// We will now update the ThemeState module
			Theme.setup(themeid);
		}
	},
);
