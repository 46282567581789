import * as PI from 'interfaces/project';
import { TemplatePhotoPosition } from 'interfaces/app';
import Template, { PhotoData } from 'classes/template';
import {
	PhotosModule, ProductStateModule,
} from 'store/index';

const changeObjectPhoto = (
	objectModel: PI.PageObjectModel,
	photoModel: PI.PhotoModel,
	templatePosition?: TemplatePhotoPosition,
	swap = false,
): Promise<void> => new Promise((resolve) => {
	const currentPhotoId = objectModel.photoid;

	const photoData: Omit<PhotoData, 'url'> = {
		id: photoModel.id,
		width: photoModel.full_width,
		height: photoModel.full_height,
		caption: photoModel.title || undefined,
	};
	if (typeof photoModel.fcx !== 'undefined'
		&& photoModel.fcx !== null
		&& typeof photoModel.fcy !== 'undefined'
		&& photoModel.fcy !== null
		&& typeof photoModel.fcw !== 'undefined'
		&& photoModel.fcw !== null
		&& typeof photoModel.fch !== 'undefined'
		&& photoModel.fch !== null
	) {
		photoData.facebox = {
			x: photoModel.fcx,
			y: photoModel.fcy,
			width: photoModel.fcw,
			height: photoModel.fch,
		};
	}

	const offeringModel = ProductStateModule.getOffering;
	const props = Template.fitPhotoInRectangle(
		templatePosition
			? {
				x: templatePosition.x,
				y: templatePosition.y,
				width: templatePosition.width,
				height: templatePosition.height,
				angle: templatePosition.angle,
				borderwidth: templatePosition.borderwidth,
				autoRotate: Boolean(templatePosition.autorotate),
			}
			: {
				x: objectModel.x_axis,
				y: objectModel.y_axis,
				width: objectModel.width,
				height: objectModel.height,
				angle: objectModel.rotate,
				borderwidth: objectModel.borderwidth,
				autoRotate: false,
			},
		photoData,
		undefined,
		{
			resizing: {
				maxScale: offeringModel
					? offeringModel.configdpi / offeringModel.minimumdpi
					: 1000,
				recommendedMaxScale: offeringModel
					? offeringModel.configdpi / offeringModel.qualitydpi
					: 1000,
			},
		},
	);

	ProductStateModule.changePageObject({
		id: objectModel.id,
		photoid: photoModel.id,
		effect: null,
		x_axis: props.x,
		y_axis: props.y,
		width: props.width,
		height: props.height,
		cropwidth: props.cropWidth,
		cropheight: props.cropHeight,
		cropx: props.cropX,
		cropy: props.cropY,
		maxwidth: photoModel.full_width,
		maxheight: photoModel.full_height,
	});

	if (swap && currentPhotoId) {
		const swaps: PI.PageObjectModel[] = [];
		const photomodelId = photoModel.id;

		ProductStateModule.getObjects.forEach((_objectModel) => {
			if (_objectModel.id != objectModel.id && _objectModel.photoid == photomodelId) {
				swaps.push(_objectModel);
			}
		});

		swaps.forEach((_objectModel) => {
			const currentPhotoModel = PhotosModule.getById(currentPhotoId);
			if (currentPhotoModel) {
				changeObjectPhoto(
					_objectModel,
					currentPhotoModel,
				);
			}
		});
	}

	resolve();
});

export default changeObjectPhoto;
