import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import EventBus from 'components/event-bus';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DialogService from 'services/dialog';
import {
	ERRORS_NO_RESPONSE,
	ERRORS_OFFLINE,
} from 'settings/errors';
import { UserModule } from 'store';
import _ from 'underscore';
import auth from './auth';

class AjaxError extends Error {
	response: AxiosResponse;

	constructor(
		response: AxiosResponse,
		...params: any
	) {
		// Pass remaining arguments (including vendor specific ones) to parent constructor
		super(...params);

		// Maintains proper stack trace for where our error was thrown
		if (Error.captureStackTrace) {
			Error.captureStackTrace(
				this,
				AjaxError,
			);
		}

		this.response = response;
	}
}

class AjaxClass {
	private readonly MAX_REQUESTS_COUNT = 5;

	private readonly INTERVAL_MS = 10;

	private PENDING_REQUESTS = 0;

	private active = 0;

	private axios: AxiosInstance;

	public online = true;

	constructor() {
		// create new axios instance
		this.axios = axios.create({
			baseURL: BASE_URL || window.glAppUrl,
			headers: {
				'Content-type': 'application/json',
			},
		});

		/**
		 * Axios Request Interceptor
		 */
		this.axios.interceptors.request.use((config) => new Promise((resolve) => {
			const interval = window.setInterval(
				() => {
					if (this.PENDING_REQUESTS < this.MAX_REQUESTS_COUNT) {
						this.PENDING_REQUESTS += 1;
						window.clearInterval(interval);
						if (config.url
						&& config.url.indexOf('session/shoppingcart/sosocio') >= 0
						&& config.method?.toLocaleLowerCase() === 'get'
						) {
						// This is a temporary hack to debug the problems with the Hema cart integration
							config.url = `${config.url}&et=${new Date().getTime()}`;
						}

						resolve(config);
					}
				},
				this.INTERVAL_MS,
			);
		}));

		/**
		 * Axios Response Interceptor
		 */
		this.axios.interceptors.response.use(
			(response) => {
				this.PENDING_REQUESTS = Math.max(
					0,
					this.PENDING_REQUESTS - 1,
				);
				return Promise.resolve(response);
			},
			(error) => {
				this.PENDING_REQUESTS = Math.max(
					0,
					this.PENDING_REQUESTS - 1,
				);
				return Promise.reject(error);
			},
		);
	}

	public request(
		requestOptions: AxiosRequestConfig,
		options: AjaxOptions,
	): Promise<AxiosResponse<any>> {
		const defaults: Required<AjaxOptions> = {
			auth: false,
			debug: {
				offline: false,
				dialog: false,
				abort: false,
				log: true,
			},
			retry: 0,
			wait: false,
		};
		const methodOptions = options ? merge(
			defaults,
			options,
		) : defaults;

		if (requestOptions.url && requestOptions.url.charAt(0) === '/') {
			requestOptions.headers = _.extend(
				{
					'X-Creator-Version': VERSION,
					'Accept-Language': window.locale,
				},
				requestOptions.headers,
			);
		}

		return this.waitForReady(
			methodOptions.wait,
		).then(() => {
			if (!methodOptions.auth) {
				return Promise.resolve();
			}

			return auth.getBearerToken()
				.then((bearerToken) => {
					requestOptions.headers = _.extend(
						requestOptions.headers || {},
						{
							Authorization: `Bearer ${bearerToken}`,
						},
					);

					return Promise.resolve();
				}).catch(() => Promise.resolve());
		}).then(() => this.performRequest(
			requestOptions,
			methodOptions,
		)).then((response) => {
			if (response.headers.hasOwnProperty('x-app-data-version')) {
				window.appDataVersion = response.headers['x-app-data-version'];
			}
			if (response.headers.hasOwnProperty('x-cdn-url')) {
				window.glDataUrl = response.headers['x-cdn-url'];
			}

			return response;
		});
	}

	private performRequest(
		requestOptions: AxiosRequestConfig,
		methodOptions: Required<AjaxOptions>,
	): Promise<AxiosResponse<any>> {
		return new Promise((resolve, reject) => {
			this.active += 1;

			this.axios.request(requestOptions)
				.then((value) => {
					this.active -= 1;
					resolve(value);
				})
				.catch((error) => {
					this.active -= 1;

					if (error.response
						&& error.response.status === 401
						&& requestOptions.url
						&& (requestOptions.url.charAt(0) == '/'
							|| requestOptions.url.indexOf(window.glAppUrl) >= 0
						)
						&& requestOptions.url.indexOf('/api/auth/login') < 0
					) {
						// User is no longer logged in, so reset all user details and permissions
						UserModule
							.logout()
							.finally(() => {
								const closeError = DialogService.openErrorDialog({
									header: {
										hasCloseButton: false,
										title: window.App.router.$t('dialogHeaderExpired'),
									},
									body: {
										content: window.App.router.$t('dialogTextExpired'),
									},
									footer: {
										buttons: [
											{
												id: 'accept',
												text: window.App.router.$t('dialogButtonExpiredOk'),
												click: () => {
													EventBus.$once(
														'auth:login',
														(success: boolean) => {
															if (success) {
																auth
																	.getBearerToken()
																	.then((bearerToken) => {
																		requestOptions.headers = _.extend(
																			requestOptions.headers || {},
																			{
																				Authorization: `Bearer ${bearerToken}`,
																			},
																		);

																		this.performRequest(
																			requestOptions,
																			methodOptions,
																		).then(
																			resolve,
																			reject,
																		);
																	})
																	.catch(() => {
																		reject(new AjaxError(
																			error.response,
																			'Unauthorized',
																		));
																	});
															} else {
																reject(new AjaxError(
																	error.response,
																	'Unauthorized',
																));
															}
														},
													);

													auth.showLogin({
														hasclose: false,
													});
													closeError();
												},
											},
										],
									},
								});
							});
					} else if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status === 0) {
							reject(new AjaxError(
								error.response,
								ERRORS_OFFLINE,
							));
						} else if (methodOptions.retry > 0) {
							methodOptions.retry -= 1;

							auth.getBearerToken().then((bearerToken) => {
								requestOptions.headers = _.extend(
									requestOptions.headers || {},
									{
										Authorization: `Bearer ${bearerToken}`,
									},
								);

								this.performRequest(
									requestOptions,
									methodOptions,
								).then(
									resolve,
									reject,
								);
							}).catch(() => {
								reject(new AjaxError(
									error.response,
									'Unauthorized',
								));
							});
						} else {
							const responseData = error.response.data;
							let errorMessage: string;
							if (_.isObject(responseData) && responseData.hasOwnProperty('error')) {
								errorMessage = responseData.error.message;
							} else {
								errorMessage = responseData && responseData.length
									? JSON.stringify(responseData)
									: '';
							}

							// Show error dialog (if allowed by options)
							if (methodOptions.debug.dialog) {
								let dialogErrorText = window.App.router.$t('dialogTextError');
								dialogErrorText += `\n\nError: ${errorMessage}`;
								let closeError!: () => void;

								const errorButtonCollection: DialogServiceButton[] = [
									{
										id: 'accept',
										text: window.App.router.$t('dialogButtonErrorRetry'),
										click: () => {
											auth
												.getBearerToken()
												.then((bearerToken) => {
													requestOptions.headers = _.extend(
														requestOptions.headers || {},
														{
															Authorization: `Bearer ${bearerToken}`,
														},
													);

													this.performRequest(
														requestOptions,
														methodOptions,
													).then(
														resolve,
														reject,
													);
												})
												.catch(() => {
													reject(new AjaxError(
														error.response,
														'Unauthorized',
													));
												});
											closeError();
										},
									},
								];

								if (methodOptions.debug.abort) {
									errorButtonCollection.unshift({
										id: 'abort',
										text: window.App.router.$t('dialogButtonErrorAbort'),
										click: () => {
											reject(new AjaxError(
												error.response,
												errorMessage,
											));
											closeError();
										},
									});
								} else {
									errorButtonCollection.unshift({
										id: 'reset',
										text: window.App.router.$t('dialogButtonErrorReset'),
										click: () => {
											DialogService.openLoaderDialog();
											window.location.reload();
											closeError();
										},
									});
								}

								closeError = DialogService.openErrorDialog({
									header: {
										hasCloseButton: false,
										title: window.App.router.$t('dialogHeaderError'),
									},
									body: {
										content: dialogErrorText,
									},
									footer: {
										buttons: errorButtonCollection,
									},
								});
							} else {
								reject(new AjaxError(
									error.response,
									errorMessage,
								));
							}
						}
					} else if (!this.online) {
						reject(new Error(ERRORS_OFFLINE));
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						reject(new Error(ERRORS_NO_RESPONSE));
					} else {
						// Something happened in setting up the request that triggered an Error
						reject(error);
					}
				});
		});
	}

	private waitForReady(
		wait: boolean,
	): Promise<void> {
		if (!wait || this.active === 0) {
			return Promise.resolve();
		}

		return new Promise(((resolve) => {
			// We need to wait to other ajax requests to finish before running this request
			// Postpone request with 100 ms
			window.setTimeout(
				() => {
					this.waitForReady(true).then(resolve);
				},
				100,
			);
		}));
	}
}

export default new AjaxClass();
