import EventBus from 'components/event-bus';
import AppRouterView from 'components/router';
import analytics from 'controllers/analytics';
import i18next from 'i18next';
import i18nextIntervalPlural from 'i18next-intervalplural-postprocessor';
import I18NextVue from 'i18next-vue';
// import HttpApi from 'i18next-http-backend';
// import BackendAdapter from 'i18next-multiload-backend-adapter';
import launch from 'ops/launch';
import store, { ProductStateModule, AppStateModule } from 'store';
import getLocale from 'tools/get-locale';
import { viewport as viewportTools } from 'tools';
import setNativeLanguage from 'tools/set-native-language';
import { vue as vueUtils } from 'utils';
import elementDoubleTap from 'utils/element-double-tap';
import Vue from 'vue';
import '../css/web.less';
// eslint-disable-next-line import/no-unresolved
import 'cssVars';

// Create application
export default class App {
	public vueRouter: AppRouterView | undefined;

	public standalone = false;

	private defaultLanguageNamespace = 'translation';

	constructor() {
		// Running as standalone app?
		this.standalone = window.navigator.userAgent.indexOf('FBAV') !== -1
			|| window.navigator.userAgent.indexOf('Instagram') !== -1
			|| (('standalone' in window.navigator) && window.navigator.standalone)
			|| window.matchMedia('(display-mode: standalone)').matches;

		// Set locale
		window.locale = getLocale();

		// Add translation strings for native interface
		if (window.glPlatform === 'native') {
			setNativeLanguage(window.locale);

			if (!window.webToNative) {
				throw new Error('Missing WebToNative on window');
			}

			// Store the native device details in a globally accessible window parameter
			// This way we don't have to call the promise everytime we need access to these
			// static details
			window.webToNative
				.getNativeDetails()
				.then((nativeDeviceDetails) => {
					window.nativeDeviceDetails = nativeDeviceDetails;
				})
				.catch((e) => {
					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							e,
							(event) => { event.severity = 'warning'; },
						);
					}
				});
		}
	}

	get router() {
		if (!this.vueRouter) {
			throw new Error('Router is not available yet. Did you call init on this class?');
		}

		return this.vueRouter;
	}

	init(): Promise<AppRouterView> {
		if (this.vueRouter) {
			return Promise.resolve(this.vueRouter);
		}

		i18next.on(
			'missingKey',
			(lngs, ns, key) => {
				if (ns === this.defaultLanguageNamespace) {
					const e = new Error(
						`Could not find translation value for key ${key}`,
					);

					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							e,
							(event) => { event.severity = 'warning'; },
						);
					} else {
						throw e;
					}
				}
			},
		);

		return i18next
			.use(i18nextIntervalPlural)
			// .use(HttpApi)
			// .use(BackendAdapter)
			.init({
				defaultNS: this.defaultLanguageNamespace,
				debug: window.glStack === 'local',
				/*
				backend: {
					loadPath: '/api/translation/multi?lng={{lng}}&ns={{ns}}',
					queryStringParams: {
						v: window.appDataVersion,
					},
					allowMultiLoading: true,
				},
				*/
				lng: window.locale,
				fallbackLng: 'en',
				supportedLngs: window.glLanguages,
				saveMissing: true,
			})
			.then(() => this.importLanguage('en'))
			.then(() => this.importLanguage('en-gb'))
			.then(() => this.importLanguage('nl'))
			.then(() => this.importLanguage('fr'))
			.then(() => this.importLanguage('it'))
			.then(() => this.importLanguage('es'))
			.then(() => {
				vueUtils.configureDestroy(Vue);
				vueUtils.configureFlagPrototype(Vue);
				vueUtils.configureForceCompute(Vue);
				vueUtils.configureDialogService(Vue);
				vueUtils.configureTheme(Vue);
				vueUtils.configureToolbarService(Vue);
				vueUtils.configureTooltipService(Vue);
				elementDoubleTap.setup();
				viewportTools.configureAppHeight();

				if (process.env.NODE_ENV !== 'production') {
					this.vueRouter = new AppRouterView({
						el: '#webapp',
						store,
					});
				} else {
					this.vueRouter = new AppRouterView({
						el: '#webapp',
					});
				}

				Vue.use(
					I18NextVue,
					{ i18next },
				);

				return this.vueRouter;
			});
	}

	start() {
		// Add event handlers for document events
		document.addEventListener(
			'mousemove',
			(e) => {
				EventBus.$emit(
					'mouse:move',
					e,
				);
			},
		);
		document.addEventListener(
			'touchmove',
			(e) => {
				EventBus.$emit(
					'mouse:move',
					e,
				);
			},
			{ passive: false },
		);

		document.addEventListener(
			'mousedown',
			(e) => {
				EventBus.$emit(
					'mouse:down',
					e,
				);
			},
		);
		document.addEventListener(
			'touchstart',
			(e) => {
				EventBus.$emit(
					'mouse:down',
					e,
				);
			},
		);

		document.addEventListener(
			'mouseup',
			(e) => {
				EventBus.$emit(
					'mouse:up',
					e,
				);
			},
		);
		document.addEventListener(
			'touchend',
			(e) => {
				EventBus.$emit(
					'mouse:up',
					e,
				);
			},
		);

		if (window.glPlatform === 'native') {
			if (!window.nativeToWeb) {
				throw new Error('Missing native bridge');
			}

			window.nativeToWeb.onApplicationResume = (payload) => {
				if (payload && payload.hasOwnProperty('unreadMessagesCount')) {
					AppStateModule.setUnreadMessageCount(
						payload.unreadMessagesCount || 0,
					);
				}

				EventBus.$emit('app:resume');
			};
		} else if (window.glPlatform === 'web') {
			document.addEventListener(
				'visibilitychange',
				() => {
					if (document.visibilityState !== 'hidden') {
						EventBus.$emit('app:resume');
					}
				},
			);

			window.addEventListener(
				'beforeinstallprompt',
				(e) => {
					// Prevent Chrome 67 and earlier from automatically showing the prompt
					e.preventDefault();

					// Stash the event so it can be triggered later.
					window.deferredPrompt = e;

					// @ts-ignore
					e.userChoice.then((choiceResult) => {
						analytics.trackEvent(
							'Install prompt',
							{
								category: 'Banner',
								label: 'Action',
								value: choiceResult.outcome,
							},
						);
					});
				},
			);

			window.addEventListener(
				'beforeunload',
				(e) => {
					if (!ProductStateModule.getSaved) {
						(e || window.event).returnValue = 'Not all changes have been saved yet.';
						return 'Not all changes have been saved yet.';
					}
					return null;
				},
			);
		}

		this.init().then(() => {
			// Launch app
			launch();
		});
	}

	importLanguage(ln: string): Promise<void> {
		// Import English translation files
		return import(`./i18n/${ln}/default.json`)
			.then((defaultTranslation) => {
				i18next.addResourceBundle(
					ln,
					this.defaultLanguageNamespace,
					defaultTranslation,
				);

				return import(`./i18n/${ln}/${LABEL}/custom.json`);
			})
			.then((labelTranslation) => {
				i18next.addResourceBundle(
					ln,
					this.defaultLanguageNamespace,
					labelTranslation,
					true,
					true,
				);
			})
			.catch((e) => {
				// No translation file available
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			});
	}
}
