import * as svgUtils from '@sosocio/frontend-utils/svg';
import PageObject from 'classes/pageobject';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import loadImage, { LoadImageOptions } from 'services/load-image';
import maskPresets from 'settings/masks';
import {
	ConfigModule,
	PhotosModule,
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import getScaledFilename from 'tools/get-scaled-filename';

const loadBorderImage = (
	objectModel: PI.PageObjectModel,
	options: {
		resolution: string;
	},
) => {
	if (objectModel._loading) {
		// Object is already loading, abort function
		return;
	}

	if (objectModel.borderimage) {
		ProductStateModule.changePageObject({
			id: objectModel.id,
			_loading: true,
		});

		const borderImageModel = ThemeDataModule.getBorderImage(objectModel.borderimage);
		if (!borderImageModel) {
			throw new Error('Could not find required border image model');
		}

		let src = borderImageModel.source;

		if (borderImageModel.scaling > 1 && options.resolution == 'high') {
			src = getScaledFilename(
				src,
				borderImageModel.scaling,
			);
		}

		loadImage(src).then(({ image }) => {
			ProductStateModule.changePageObject({
				id: objectModel.id,
				_borderimage: image,
				_loading: false,
			});
		}).catch(() => {
			ProductStateModule.changePageObject({
				id: objectModel.id,
				_borderimage: null,
				_loading: false,
			});
		});
	}
};

interface loadPhotoObjectOptions {
	/**
	 * Indicates whether the full source should be used when loading the photo.
	 * Only applies when `no_store` is `true`.
	 * Defaults to `false`.
	 */
	force_full_source: boolean;
	mask: boolean;
	/**
	 * Indicates whether the object mutation will be made through the
	 * `ProductStateModule` or not. If `true`, the mutation will be made
	 * directly to the object model, and not through the `ProductStateModule`.
	 * Defaults to `false`.
	 */
	no_store: boolean;
	/**
	 * The offering model to use when loading the photo. Defaults to the
	 * current offering model of the `ProductStateModule`.
	 */
	offeringModel: DB.OfferingModel | null;
	/**
	 * The resolution of the photo to load. Defaults to `'low'`.
	 */
	resolution: 'thumb' | 'low' | 'high';
	/**
	 * Scaling factor to apply to the photo. Defaults to `undefined`.
	*/
	scaling?: number;
}

export default function loadPhotoObject(
	objectModel: PI.PageObjectModel,
	opts: Partial<loadPhotoObjectOptions>,
): Promise<PI.PageObjectModel> {
	if (objectModel._loading) {
		// Object is already loading, abort function
		return Promise.resolve(objectModel);
	}

	const promises: Promise<void>[] = [];
	const defaultOptions: loadPhotoObjectOptions = {
		force_full_source: false,
		mask: false,
		no_store: false,
		offeringModel: ProductStateModule.getOffering,
		resolution: 'low',
	};
	const options: loadPhotoObjectOptions = {
		...defaultOptions,
		...opts,
	};

	const photoModel = (
		objectModel.photoid
			? PhotosModule.getById(objectModel.photoid)
			: undefined
	);

	if (!objectModel._image) {
		if (!options.no_store) {
			ProductStateModule.changePageObject({
				id: objectModel.id,
				_loading: true,
			});
		} else {
			objectModel._loading = true;
		}

		/**
		 * We only want to use photo scaling if:
		 *  - The request resolution is not high (otherwise we use the full size photo)
		 *  - The photo model exists (otherwise the scaling service doesn't work)
		 *  - We are not storing the image in the ProductStateModule (otherwise the loaded image will be reused in other places where we might want a different scaling factor)
		 */
		const usePhotoScaling = (
			photoModel
			&& options.no_store
			&& opts.resolution !== 'high'
		);

		promises.push(
			PageObject
				.getPhotoSource(
					objectModel,
					{
						resolution: options.resolution,
						maxWidth: usePhotoScaling && options.scaling
							? Math.ceil(photoModel.full_width * options.scaling)
							: undefined,
						maxHeight: usePhotoScaling && options.scaling
							? Math.ceil(photoModel.full_height * options.scaling)
							: undefined,
					},
				)
				.then((urlOrFile) => {
					// SVG's are loaded directly without image conversion
					if (objectModel.ext === 'svg') {
						return svgUtils
							.load(urlOrFile)
							.then(async (svgResult) => {
								const svgColors = await svgUtils.getColors(
									svgResult.content,
									ConfigModule['logoColors.mergeSimilarThreshold'],
								);

								if (!options.no_store) {
									ProductStateModule.changePageObject({
										id: objectModel.id,
										_vectorSVG: svgResult.content,
										_vectorColors: svgColors,
									});
								} else {
									objectModel._vectorSVG = svgResult.content;
									objectModel._vectorColors = svgColors;
								}

								return {
									image: svgResult.image,
								};
							});
					}

					let smoothing = true;

					if (
						options.resolution === 'high'
						&& photoModel
					) {
						const offeringScale = (
							options.offeringModel
								? options.offeringModel.qualitydpi / options.offeringModel.configdpi
								: 1
						);
						const scale = Math.max(
							1,
							Math.ceil((objectModel.width / objectModel.cropwidth) * offeringScale),
						);

						if (
							photoModel.full_width * scale > 32767
							|| photoModel.full_height * scale > 32767
							|| (photoModel.full_width * scale) * (photoModel.full_height * scale) > 268435456
						) {
							// We are exceeding the canvas size limit, so we can't use smoothing (as it will load the photo on a canvas)
							// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas
							smoothing = false;
						}
					}

					const loadImageOptions: Partial<LoadImageOptions> = {
						effect: objectModel.effect,
						smoothing,
						outputFormat: (
							objectModel.ext === 'png'
								? 'png'
								: 'jpeg'
						),
					};

					if (
						objectModel.photoid
						&& urlOrFile instanceof File
					) {
						if (options.resolution === 'thumb') {
							loadImageOptions.scale = 140;
						} else {
							loadImageOptions.scale = ConfigModule.photoPreviewSizeLocal;
						}

						if (photoModel) {
							if (
								photoModel._orientation
								&& photoModel._orientation > 1
							) {
								loadImageOptions.orientation = photoModel._orientation;
							}
						}
					}

					return loadImage(
						urlOrFile,
						loadImageOptions,
					);
				})
				.then(({ image }) => {
					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_image: image,
							_error: 0,
						});
					} else {
						objectModel._image = image;
						objectModel._error = 0;
					}
				})
				.catch((err) => {
					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_image: null,
						});
					} else {
						objectModel._image = null;
					}

					if (
						photoModel
						&& photoModel.url
						&& photoModel.full_url
						&& (
							!options.no_store
							|| (
								options.no_store
								&& options.force_full_source
							)
						)
					) {
						if (!options.no_store) {
							// Unset url property
							PhotosModule.updateModel({
								id: photoModel.id,
								url: null,
							});

							ProductStateModule.changePageObject({
								id: objectModel.id,
								_loading: false,
							});
						} else {
							objectModel._loading = false;
						}

						// Now try again with the full_url as source
						return loadPhotoObject(
							objectModel,
							{
								...options,
								force_full_source: true,
							},
						).then(() => undefined);
					}

					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_error: objectModel._error + 1,
						});
					} else {
						objectModel._error += 1;
					}

					throw err;
				})
				.finally(() => {
					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_loading: false,
						});
					} else {
						objectModel._loading = false;
					}
				}),
		);
	}

	if (
		options.mask
		&& !objectModel._mask
		&& objectModel.mask
	) {
		if (!options.no_store) {
			ProductStateModule.changePageObject({
				id: objectModel.id,
				_loading: true,
			});
		} else {
			objectModel._loading = true;
		}

		promises.push(
			loadImage(maskPresets[objectModel.mask].src)
				.then(({ image }) => {
					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_loading: false,
							_mask: image,
						});
					} else {
						objectModel._loading = false;
						objectModel._mask = image;
					}
				})
				.catch(() => {
					if (!options.no_store) {
						ProductStateModule.changePageObject({
							id: objectModel.id,
							_loading: false,
							_mask: null,
						});
					} else {
						objectModel._loading = false;
						objectModel._mask = null;
					}
				}),
		);
	}

	if (
		objectModel.borderimage
		&& !objectModel._borderimage
	) {
		if (!ThemeDataModule.getBorderImage(objectModel.borderimage)) {
			promises.push(
				ThemeDataModule
					.fetchBorderImage(objectModel.borderimage)
					.then(() => {
						loadBorderImage(
							objectModel,
							options,
						);
					}),
			);
		} else {
			loadBorderImage(
				objectModel,
				options,
			);
		}
	}

	return Promise
		.all(promises)
		.then(() => undefined)
		.finally(() => {
			if (!options.no_store) {
				ProductStateModule.changePageObject({
					id: objectModel.id,
					_loading: false,
				});
			} else {
				objectModel._loading = false;
			}
		})
		.then(() => objectModel);
}
