import ImageMaskClass from 'classes/mask';
import merge from 'deepmerge';
import * as PI from 'interfaces/project';
import maskPresets from 'settings/masks';
import {
	PhotosModule,
	ProductStateModule,
} from 'store';

export interface LoadObjectMaskOptions {
	dynamicWidth?: number;
	/**
	 * Indicates whether the object mutation will be made through the
	 * `ProductStateModule` or not. If `true`, the mutation will be made
	 * directly to the object model, and not through the `ProductStateModule`.
	 * Defaults to `false`.
	 */
	no_store?: boolean;
	resolution?: 'low' | 'thumb' | 'high';
}

export default function loadObjectMask(
	objectModel: PI.PageObjectModel,
	opts?: LoadObjectMaskOptions,
): Promise<PI.PageObjectModel> {
	const defaults: LoadObjectMaskOptions = {
		resolution: 'low',
		no_store: false,
	};
	const options = (
		opts
			? merge(
				defaults,
				opts,
			)
			: defaults
	);

	const {
		mask,
		photoid,
	} = objectModel;

	if (mask) {
		const photoModel = (
			photoid
				? PhotosModule.getById(photoid)
				: undefined
		);

		let photoScale = 1;

		if (
			photoModel
			&& options.dynamicWidth
			&& photoModel.full_width
		) {
			photoScale = options.dynamicWidth / photoModel.full_width;
		} else if (
			photoModel
			&& options.resolution == 'thumb'
			&& photoModel.thumb_width
		) {
			photoScale = photoModel.thumb_width / photoModel.full_width;
		} else if (
			photoModel
			&& options.resolution == 'low'
			&& photoModel.width
		) {
			photoScale = photoModel.width / photoModel.full_width;
		}

		let {
			cropx,
			cropy,
			cropwidth,
			cropheight,
		} = objectModel;

		if (
			options.resolution == 'high'
			&& photoModel
			&& objectModel._image.width != photoModel.full_width
		) {
			// In case the upscaling service has been applied to this photo (during order processing),
			// we need to auto-adjust the cropping paramaters
			const upscaleRatio = objectModel._image.width / photoModel.full_width;
			cropx *= upscaleRatio;
			cropy *= upscaleRatio;
			cropwidth *= upscaleRatio;
			cropheight *= upscaleRatio;
		}

		return ImageMaskClass(
			objectModel._image,
			maskPresets[mask],
			{
				cropx: cropx * photoScale,
				cropy: cropy * photoScale,
				cropwidth: cropwidth * photoScale,
				cropheight: cropheight * photoScale,
				correctRatio: false,
				photoScale,
			},
		)
			.then(([res]) => {
				if (!options.no_store) {
					ProductStateModule.changePageObject({
						id: objectModel.id,
						_canvas: res.getCanvas(),
					});
				} else {
					objectModel._canvas = res.getCanvas();
				}
			})
			.then(() => objectModel);
	}

	return Promise.resolve(objectModel);
}
