import './defines';
import CheckboxComponent from 'components/checkbox';
import {
	SelectComponentColor,
	SelectComponentOptions,
	SelectComponentOptionsClasses,
	SelectComponentOptionStylesCallback,
	TooltipPositionSide,
} from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import {
	functions as functionsUtils,
	dom as domUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import { VueConstructorExtended } from 'vue';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'SelectComponent',
	components: {
		CheckboxComponent,
	},
})
export default class SelectComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			type: [Array, Number, String],
		},
	)
	public readonly value?: number[] | string[] | number | string | undefined;

	@Prop({
		acceptedValues: [
			'primary',
			'secondary',
		],
		default: 'primary',
		description: 'Defines the color of the select',
		schema: 'SelectComponentColor',
		type: String,
	})
	public readonly color!: SelectComponentColor;

	@Prop({
		default: false,
		description: 'Indicates if the select options when open should cover the anchor element',
		type: Boolean,
	})
	public readonly coverAnchor?: boolean;

	@Prop({
		default: 36,
		description: 'Defines the height of the select',
		type: [Number, String],
	})
	public readonly height!: number | string;

	@Prop({
		default: 'title',
		description: 'Defines the key of the option (in case a list of object are set as options) to use as the item title',
		type: String,
	})
	public readonly itemTitle!: string;

	@Prop({
		default: 'value',
		description: 'Defines the key of the option (in case a list of object are set as options) to use as the item value',
		type: String,
	})
	public readonly itemValue!: string;

	@Prop({
		default: '#webapp',
		description: 'Defines the anchor element where the options will be appended (only for mobile)',
		type: [HTMLElement, String],
	})
	public readonly mobileOptionsAnchor?: HTMLElement | string;

	@Prop({
		default: false,
		description: 'Indicates if the select options should be displayed like desktop (only for mobile)',
		type: Boolean,
	})
	public readonly mobileOptionsLikeDesktop?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the select should allow multiple options to be selected',
		type: Boolean,
	})
	public readonly multiple!: boolean;

	@Prop({
		default: () => ([]),
		description: 'Defines the options of the select',
		type: Array,
	})
	public readonly options!: SelectComponentOptions;

	@Prop({
		default: undefined,
		description: 'Defines an array of classes, an object with class name as key and boolean as value or an individual class name to indicates classes to be added to the options container element',
		type: [Array, Object, String],
	})
	public readonly optionsClasses?: SelectComponentOptionsClasses;

	@Prop({
		default: undefined,
		description: 'Defines the distance between the select options and the select element',
		type: Number,
	})
	public readonly optionsDistance?: number | undefined;

	@Prop({
		default: undefined,
		description: 'Defines a function that returns the styles of each option element',
		type: Function,
	})
	public readonly optionStyles?: SelectComponentOptionStylesCallback;

	@Prop({
		default: () => ({}),
		description: 'Defines the styles of the options element',
		type: Object,
	})
	public readonly optionsStyles!: Partial<CSSStyleDeclaration> & Record<string, string>;

	@Prop({
		acceptedValues: [
			'auto',
			'dark',
			'light',
		],
		default: 'auto',
		description: 'Defines the theme of the select options',
		event: 'theme-change',
		type: String,
	})
	public readonly optionsTheme!: SelectComponent['theme'];

	@Prop({
		default: 8,
		description: 'Defines the padding of the select',
		type: [Array, Number, String],
	})
	public readonly padding?: number[] | number | string | null;

	@Prop({
		default: 139,
		description: 'Defines the width of the select',
		type: [Number, String],
	})
	public readonly width!: number | string;

	protected get computedAnchor(): HTMLElement | null {
		let optionsAnchorElement: HTMLElement | null = null;

		if (this.isMobileOptionsAnchor) {
			if (typeof this.mobileOptionsAnchor === 'string') {
				optionsAnchorElement = document.querySelector(this.mobileOptionsAnchor);
			} else {
				optionsAnchorElement = this.mobileOptionsAnchor || null;
			}
		}

		if (!optionsAnchorElement) {
			const classConstructor = this.constructor as VueConstructorExtended<SelectComponent>;

			if (
				classConstructor.options.props
				&& !Array.isArray(classConstructor.options.props)
				&& classConstructor.options.props.mobileOptionsAnchor
				&& 'default' in classConstructor.options.props.mobileOptionsAnchor
				&& typeof classConstructor.options.props.mobileOptionsAnchor.default === 'string'
			) {
				optionsAnchorElement = document.querySelector(classConstructor.options.props.mobileOptionsAnchor.default);
			}
		}

		return optionsAnchorElement;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			[this.color]: true,
			'select-component-open': this.isOpen,
		};
	}

	protected get computedInternalValue(): SelectComponentOptions[number] | null {
		if (this.internalValue) {
			return this.options.find((option) => this.getOptionValue(option) === this.internalValue) || null;
		}

		return null;
	}

	protected get computedInternalValueText(): string | null {
		if (
			this.multiple
			&& Array.isArray(this.internalValue)
		) {
			return this.internalValue
				.map((value) => {
					const optionFound = this.options.find((option) => this.getOptionValue(option) === value);

					if (optionFound) {
						return this.getOptionTitle(optionFound);
					}

					return null;
				})
				.join(', ');
		}
		if (this.computedInternalValue) {
			return this.getOptionTitle(this.computedInternalValue);
		}

		return null;
	}

	protected get computedOptionsClasses(): Record<string, boolean> {
		const themeName = (
			this.optionsTheme === 'auto'
				? this.internalTheme
				: this.optionsTheme
		);
		const classes: Record<string, boolean> = {
			[this.color]: true,
			[themeName]: true,
			'select-component-options-container-desktop': !!(
				!this.isMobile
				|| this.mobileOptionsLikeDesktop
			),
		};

		if (Array.isArray(this.optionsClasses)) {
			// eslint-disable-next-line no-restricted-syntax
			for (const className of this.optionsClasses) {
				classes[className] = true;
			}
		} else if (typeof this.optionsClasses === 'object') {
			const optionsClasses = Object.keys(this.optionsClasses);

			// eslint-disable-next-line no-restricted-syntax
			for (const className of optionsClasses) {
				classes[className] = this.optionsClasses[className];
			}
		} else if (this.optionsClasses) {
			classes[this.optionsClasses] = true;
		}

		return classes;
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (this.height) {
			styles.height = (
				typeof this.height === 'number'
					? `${this.height}px`
					: this.height
			);
		}

		if (this.width) {
			styles.width = (
				typeof this.width === 'number'
					? `${this.width}px`
					: this.width
			);
		}

		if (Array.isArray(this.padding)) {
			styles.padding = this.padding
				.map((value) => `${value}px`)
				.join(' ');
		} else if (this.padding) {
			if (typeof this.padding === 'number') {
				styles.padding = `${this.padding}px`;
			} else {
				styles.padding = this.padding;
			}
		}

		return styles;
	}

	protected get isAllSelected(): boolean {
		if (
			this.multiple
			&& Array.isArray(this.internalValue)
		) {
			return this.internalValue.length === this.options.length;
		}

		return false;
	}

	private get isMobileOptionsAnchor(): boolean {
		return !!(
			this.isMobile
			&& this.mobileOptionsAnchor
		);
	}

	@Ref('selectComponent')
	private selectComponentElement?: HTMLDivElement;

	@Ref('selectComponentOptions')
	private selectComponentOptionsElement!: HTMLDivElement;

	@Ref('selectComponentOptionsContainer')
	private selectComponentOptionsContainerElement!: HTMLDivElement;

	private checkVisibilityDebounce = functionsUtils.debounce(
		() => this.checkVisibility(),
		100,
	);

	private internalValue: number[] | string[] | number | string | null = null;

	private isCalculatingOptionsPosition?: boolean;

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private isOpen = false;

	private mutationObserver!: MutationObserver;

	private resizeObserver!: ResizeObserver;

	protected beforeDestroy(): void {
		window.removeEventListener(
			'click',
			this.onWindowClick,
		);
		this.isMobileUnwatch?.();
		this.resizeObserver?.disconnect();
		this.mutationObserver?.disconnect();
		this.selectComponentOptionsContainerElement?.remove();
	}

	protected created(): void {
		this.resizeObserver = new ResizeObserver(() => this.calculateOptionsPosition());
		this.mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
			if (
				mutations.length === 1
				&& mutations[0].type === 'attributes'
				&& mutations[0].attributeName === 'style'
				&& mutations[0].target.isSameNode(this.selectComponentOptionsElement)
			) {
				return;
			}

			this.calculateOptionsPosition();
		});
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
			this.$forceCompute('computedAnchor');

			if (this.isOpen) {
				requestAnimationFrame(() => this.calculateOptionsPosition());
			}
		});
	}

	protected mounted(): void {
		this.selectComponentOptionsElement.style.visibility = 'hidden';

		if (this.selectComponentElement) {
			this.resizeObserver.observe(this.selectComponentElement);
			this.addObserversToParent(this.selectComponentElement);
		}
	}

	@Watch('isOpen')
	protected onIsOpenChange(): void {
		if (!this.isOpen) {
			this.selectComponentOptionsElement.style.visibility = 'hidden';
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = this.value || null;
	}

	private addObserversToParent(element: HTMLElement): void {
		this.resizeObserver.observe(element);
		this.mutationObserver.observe(
			element,
			{
				attributeFilter: ['style'],
				attributes: true,
				childList: true,
				subtree: true,
			},
		);

		if (
			element !== window.document.body
			&& element.parentElement
		) {
			this.addObserversToParent(element.parentElement);
		}
	}

	private calculateOptionsPosition(skipCheckVisibility = false): Promise<void> {
		if (
			this.isCalculatingOptionsPosition
			|| !this.selectComponentElement
		) {
			return Promise.resolve();
		}

		this.isCalculatingOptionsPosition = true;

		if (this.isOpen) {
			if (this.$parent?.$tooltipInstance) {
				this.selectComponentOptionsElement.style.visibility = 'hidden';
			}

			return new Promise((resolve, reject) => {
				requestAnimationFrame(() => {
					try {
						if (!this.selectComponentElement) {
							resolve();
							return;
						}

						let selectComponentElementRect = this.selectComponentElement.getBoundingClientRect();

						if (
							!this.isMobile
							|| this.mobileOptionsLikeDesktop
						) {
							if (this.computedAnchor !== this.selectComponentOptionsContainerElement.parentElement) {
								document.getElementById('webapp')?.append(this.selectComponentOptionsContainerElement);
								selectComponentElementRect = this.selectComponentElement.getBoundingClientRect();
							}

							const anchorDistance = this.optionsDistance || 0;
							this.selectComponentOptionsElement.style.position = 'absolute';
							this.selectComponentOptionsElement.style.bottom = '0';
							this.selectComponentOptionsElement.style.left = `${selectComponentElementRect.left}px`;

							if (!this.coverAnchor) {
								this.selectComponentOptionsElement.style.top = `${selectComponentElementRect.bottom + anchorDistance}px`;
							} else {
								this.selectComponentOptionsElement.style.top = `${selectComponentElementRect.top}px`;
							}

							this.selectComponentOptionsElement.style.width = `${selectComponentElementRect.width}px`;
						} else if (!this.isMobileOptionsAnchor) {
							const anchorDistance = this.optionsDistance || 0;
							this.selectComponentOptionsElement.style.bottom = `${Math.round(window.innerHeight - selectComponentElementRect.top + anchorDistance)}px`;
							this.selectComponentOptionsElement.style.left = '0';
							this.selectComponentOptionsElement.style.top = '';
							this.selectComponentOptionsElement.style.width = '100%';
						}

						if (!skipCheckVisibility) {
							requestAnimationFrame(() => this.checkVisibilityDebounce());
						}

						this.isCalculatingOptionsPosition = false;
						requestAnimationFrame(() => resolve());
					} catch (error) {
						this.isCalculatingOptionsPosition = false;
						reject(error);
					}
				});
			});
		}

		this.isCalculatingOptionsPosition = false;

		return Promise.resolve();
	}

	private async checkVisibility(
		callback?: () => void,
		side: TooltipPositionSide = 'bottom',
	): Promise<void> {
		if (this.$parent?.$tooltipInstance) {
			if (side !== 'bottom') {
				await this.calculateOptionsPosition(true);
			}

			return new Promise((resolve, reject) => {
				requestAnimationFrame(async () => {
					try {
						const isFullyVisibleResult = domUtils.isFullyVisible(this.selectComponentOptionsElement);

						if (!isFullyVisibleResult.isFullyVisible) {
							let sideToCheck: TooltipPositionSide;

							if (side === 'bottom') {
								sideToCheck = 'right';
							} else if (side === 'right') {
								sideToCheck = 'top';
							} else if (side === 'top') {
								sideToCheck = 'left';
							} else {
								if (this.$parent?.$tooltipInstance) {
									const fixVisibilityCallback = await this.$parent.$tooltipInstance.fixVisibility(
										this.selectComponentOptionsElement,
										'bottom',
									);
									fixVisibilityCallback();
								}

								callback?.();
								return resolve();
							}

							const fixVisibilityCallback = await this.fixVisibility(
								this.selectComponentOptionsElement,
								sideToCheck,
							);
							this.checkVisibility(
								fixVisibilityCallback,
								sideToCheck,
							);
						} else if (callback) {
							callback();
						} else {
							this.selectComponentOptionsElement.style.visibility = '';
						}

						return resolve();
					} catch (error) {
						return reject(error);
					}
				});
			});
		}

		this.selectComponentOptionsElement.style.visibility = '';

		return Promise.resolve();
	}

	@Public()
	public async fixVisibility(
		target: HTMLElement,
		side: TooltipPositionSide,
	): Promise<() => void> {
		if (this.$parent?.$tooltipInstance) {
			this.selectComponentOptionsElement.style.visibility = 'hidden';
			const parentTooltipInstance = this.$parent.$tooltipInstance;
			const fixVisibilityCallback = await parentTooltipInstance.fixVisibility(
				target,
				side,
			);

			return () => {
				this.selectComponentOptionsElement.style.visibility = '';
				fixVisibilityCallback();
			};
		}

		return () => {
			this.selectComponentOptionsElement.style.visibility = '';
		};
	}

	protected getOptionStyles(option: SelectComponentOptions[number]): Partial<CSSStyleDeclaration> {
		let styles: Partial<CSSStyleDeclaration> = {};

		if (!this.isMobile) {
			if (Array.isArray(this.padding)) {
				if (this.padding.length === 2) {
					styles.padding = `${this.padding[0] / 2}px ${this.padding[1]}px`;
				} else {
					styles.padding = this.padding
						.map((value) => `${value}px`)
						.join(' ');
				}
			} else if (this.padding) {
				if (typeof this.padding === 'number') {
					styles.padding = `${this.padding / 2}px ${this.padding}px`;
				} else {
					styles.padding = this.padding;
				}
			}
		}

		if (this.optionStyles) {
			styles = {
				...styles,
				...this.optionStyles(option),
			};
		}

		return styles;
	}

	protected getOptionTitle(option: SelectComponentOptions[number]): string {
		if (typeof option === 'object') {
			return String(option[this.itemTitle]);
		}

		return option;
	}

	protected getOptionValue(option: SelectComponentOptions[number]): string | number | boolean {
		if (typeof option === 'object') {
			return option[this.itemValue];
		}

		return option;
	}

	protected getValueStyles(option: SelectComponentOptions[number]): Partial<CSSStyleDeclaration> {
		if (this.optionStyles) {
			const optionStyles: Partial<CSSStyleDeclaration> = {
				...this.optionStyles(option),
			};
			delete optionStyles.padding;

			return optionStyles;
		}

		return {};
	}

	protected isOptionSelected(option: SelectComponentOptions[number]): boolean {
		if (
			this.multiple
			&& Array.isArray(this.internalValue)
		) {
			return this.internalValue.includes(this.getOptionValue(option) as number & string);
		}

		return this.getOptionValue(option) === this.internalValue;
	}

	protected onClearAllClick(): void {
		this.internalValue = [];
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onOptionClick(option: SelectComponentOptions[number]): void {
		if (!this.multiple) {
			this.internalValue = this.getOptionValue(option) as number | string;
			this.$emit(
				'change',
				this.internalValue,
			);

			if (
				!this.isMobile
				|| this.mobileOptionsLikeDesktop
			) {
				this.isOpen = false;
			}
		}
	}

	protected onOptionChange(
		option: SelectComponentOptions[number],
		value: boolean,
	): void {
		if (this.multiple) {
			const optionValue = this.getOptionValue(option) as number & string;

			if (Array.isArray(this.internalValue)) {
				if (value) {
					this.internalValue.push(optionValue);
				} else {
					this.internalValue = (this.internalValue as any[]).filter((internalValue) => internalValue !== optionValue);
				}
			} else {
				this.internalValue = [optionValue];
			}

			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	protected onSelectClick(event: MouseEvent): void {
		if (!this.isOpen) {
			this.computedAnchor?.append(this.selectComponentOptionsContainerElement);

			if (
				!this.isMobileOptionsAnchor
				|| this.mobileOptionsLikeDesktop
			) {
				this.selectComponentOptionsElement.style.position = 'absolute';
			} else {
				this.selectComponentOptionsElement.style.position = '';
			}

			window.addEventListener(
				'click',
				this.onWindowClick,
			);
		} else {
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
		}

		this.isOpen = !this.isOpen;

		if (this.isOpen) {
			requestAnimationFrame(() => this.calculateOptionsPosition());
		}

		this.$emit(
			'click',
			event,
		);
	}

	protected onSelectOptionsClick(event: MouseEvent): void {
		event.preventDefault();
	}

	private onWindowClick(event: MouseEvent): void {
		const eventTarget = event.target as HTMLElement | undefined;

		if (
			eventTarget
			&& eventTarget !== this.selectComponentElement
			&& eventTarget !== this.selectComponentOptionsElement
			&& !this.selectComponentOptionsElement.contains(eventTarget)
			&& (
				!this.selectComponentElement
				|| !this.selectComponentElement.contains(eventTarget)
			)
		) {
			this.isOpen = false;
		}
	}
}
