import { ChannelProfileModel, ChannelModel } from 'interfaces/app';

export const profileModelAttributes: ChannelProfileModel = {
	id: null,
	picture: undefined,
	first_name: undefined,
	last_name: undefined,
	email: undefined,
};

export const channelModelAttributes: Partial<ChannelModel> = {
	pressed: false,
	place: 0,
	apikey: null,
	apisecret: null,
	albums: 0,
	date: 0,
	icon: [],
	download: 0,
	folders: 0,
	friends: 0,
	likes: 0,
	login: 0,
	photos: 0,
	switch: 0,
	categories: 0,
};
