import './defines';
import { FontModel } from 'interfaces/app';
import * as PI from 'interfaces/project';
import {
	FontModule,
	ProductStateModule,
} from 'store';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import FontPickerItemView from '../picker-font-item.vue';
import Template from './template.vue';

@Component({
	components: {
		FontPickerItemView,
	},
})
export default class FontPickerView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	protected get collection() {
		return FontModule.getPrintable;
	}

	protected updated() {
		this.$emit('updateDialog');
	}

	private close() {
		this.$emit('closeDialog');
	}

	protected imageLoaded() {
		this.$emit('updateDialog');
	}

	private selectFont(fontModel: FontModel) {
		const objectModel = ProductStateModule.getSelectedPageObject;

		if (objectModel) {
			if (!fontModel._loaded) {
				const closeLoader = this.$openLoaderDialog();

				FontModule
					.loadModel(fontModel.id)
					.then(() => {
						this.selectFont(fontModel);
					})
					.catch(() => {
						this.$openErrorDialog({
							body: {
								content: this.$t('dialogTextLoadError'),
							},
						});
					})
					.finally(() => {
						closeLoader();
						this.close();
					});
			} else {
				ProductStateModule.changePageObject({
					id: objectModel.id,
					fontface: fontModel.id,
					fontbold: objectModel.fontbold && fontModel.bold ? 1 : 0,
					fontitalic: objectModel.fontitalic && fontModel.italic ? 1 : 0,
				});
				ProductStateModule.pushHistory();
				this.close();
			}
		}
	}
}
