import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import { DialogModel } from 'interfaces/app';

@Component
export default class LoaderDialogView extends Vue {
	@Prop({ required: true, type: Object })
	readonly dialogModel!: DialogModel;

	clickModal() {
		if (this.dialogModel.modal) {
			this.checkClose();
		}
	}

	checkClose() {
		if (this.dialogModel.hasclose) {
			this.$emit(
				'closeDialog',
				this.dialogModel,
			);
		}
	}
}
