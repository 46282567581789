import ImageMaskClass from 'classes/mask';
import * as PI from 'interfaces/project';
import maskPresets from 'settings/masks';
import {
	AppStateModule,
	PhotosModule,
	ProductStateModule,
} from 'store';

export default function applyMask(
	objectModel: PI.PageObjectModel,
	maskToApply: string | null,
): void {
	let newObjectModel: PI.PageObjectModel = {
		...objectModel,
	};

	if (AppStateModule.mask) {
		// Reset object properties to initial settings
		// This is for when the user is switching between different masks
		// to make sure that the full potential size of the photo is used when applying the new mask
		const { mask } = AppStateModule;
		newObjectModel = {
			...newObjectModel,
			_mask: null,
			x_axis: mask.x_axis,
			y_axis: mask.y_axis,
			width: mask.width,
			height: mask.height,
			cropx: mask.cropx,
			cropy: mask.cropy,
			cropwidth: mask.cropwidth,
			cropheight: mask.cropheight,
			borderwidth: mask.borderwidth,
		};
	}

	if (maskToApply) {
		const photoModel = (
			newObjectModel.photoid
				? PhotosModule.getById(newObjectModel.photoid)
				: undefined
		);
		const photoScale = (
			(
				photoModel
				&& photoModel.width
			)
				? photoModel.width / photoModel.full_width
				: 1
		);

		const pageModel = ProductStateModule.findPageFromObject(newObjectModel.id);

		if (!pageModel) {
			throw new Error('Missing required pageModel');
		}

		// Make sure the new shape is within the bleedmargin
		if (newObjectModel.width > pageModel.width) {
			newObjectModel.x_axis += (newObjectModel.width - pageModel.width) / 2;
			newObjectModel.width = pageModel.width;
		}
		if (newObjectModel.height > pageModel.height) {
			newObjectModel.y_axis += (newObjectModel.height - pageModel.height) / 2;
			newObjectModel.height = pageModel.height;
		}

		const offeringModel = ProductStateModule.getOffering;

		if (!offeringModel) {
			throw new Error('Missing required offering model');
		}

		// Make sure the new shape is within the front face of the product
		if (offeringModel && offeringModel.depth) {
			const d = offeringModel.depth * 2.5; // depth on both sides plus a bit of margin

			if (newObjectModel.width > pageModel.width - d) {
				newObjectModel.x_axis += (newObjectModel.width - (pageModel.width - d)) / 2;
				newObjectModel.width = pageModel.width - d;
			}
			if (newObjectModel.height > pageModel.height - d) {
				newObjectModel.y_axis += (newObjectModel.height - (pageModel.height - d)) / 2;
				newObjectModel.height = pageModel.height - d;
			}
		}

		ImageMaskClass(
			newObjectModel._image,
			maskPresets[maskToApply],
			{
				cropx: newObjectModel.cropx * photoScale,
				cropy: newObjectModel.cropy * photoScale,
				cropwidth: newObjectModel.cropwidth * photoScale,
				cropheight: newObjectModel.cropheight * photoScale,
				correctRatio: true,
				photoScale,
			},
		).then(([, attrs]) => {
			const scale = Math.min(
				newObjectModel.width / attrs.width,
				newObjectModel.height / attrs.height,
			);
			const width = scale * attrs.width;
			const height = scale * attrs.height;
			const xAxis = newObjectModel.x_axis + (newObjectModel.width - width) / 2;
			const yAxis = newObjectModel.y_axis + (newObjectModel.height - height) / 2;
			const cropX = (
				photoModel
					? Math.max(
						0,
						Math.min(
							photoModel.full_width - width,
							attrs.x / photoScale,
						),
					)
					: attrs.x
			);
			const cropY = (
				photoModel
					? Math.max(
						0,
						Math.min(
							photoModel.full_height - height,
							attrs.y / photoScale,
						),
					)
					: attrs.y
			);
			const cropW = (
				photoModel
					? Math.min(
						photoModel.full_width,
						attrs.width / photoScale,
					)
					: attrs.width
			);
			const cropH = (
				photoModel
					? Math.min(
						photoModel.full_height,
						attrs.height / photoScale,
					)
					: attrs.height
			);

			ProductStateModule.changePageObject({
				id: newObjectModel.id,
				mask: maskToApply,
				width,
				height,
				x_axis: xAxis,
				y_axis: yAxis,
				cropx: cropX,
				cropy: cropY,
				cropwidth: cropW,
				cropheight: cropH,
				borderwidth: 0,
			});
			ProductStateModule.pushHistory();
		});
	} else {
		ProductStateModule.changePageObject({
			id: newObjectModel.id,
			mask: null,
		});
		ProductStateModule.pushHistory();
	}
}
