function toType<T extends Record<string, any>>(object: T): string {
	// Get fine type (object, array, function, null, error, date ...)
	return ({})
		.toString
		.call(object)
		.match(/([a-z]+)(:?\])/i)?.[1] as string;
}

function isDeepObject<T extends object>(object: T): boolean {
	return toType(object) === 'Object';
}

function compareObjects<
	T extends object,
	K extends keyof T,
	V = T[K],
>(
	object1: V,
	object2: V,
): boolean {
	if (typeof object1 !== typeof object2) {
		return false;
	}

	if (
		Array.isArray(object1)
		&& Array.isArray(object2)
	) {
		if (object1.length !== object2.length) {
			return false;
		}

		return object1.every(
			(value, index) => compareObjects(
				value,
				object2[index],
			),
		);
	}

	if (
		object1 instanceof Element
		&& object2 instanceof Element
	) {
		return object1.isEqualNode(object2);
	}

	if (
		typeof object1 === 'object'
		&& typeof object2 === 'object'
		&& !Array.isArray(object1)
		&& !Array.isArray(object2)
		&& object1 !== null
		&& object2 !== null
	) {
		const keys1 = Object.keys(object1) as (keyof V)[];
		return keys1.every(
			(key) => compareObjects(
				object1[key],
				object2[key],
			),
		);
	}

	return object1 === object2;
}

export function getObjectDifferences<
	T extends object,
	K extends keyof T,
>(
	object1: T,
	object2: T,
): K[] {
	const keys1 = Object.keys(object1) as K[];
	const keys2 = Object.keys(object2) as K[];

	const differences = keys1
		.filter(
			(key) => !compareObjects(
				object1[key],
				object2[key],
			),
		)
		.concat(
			keys2.filter(
				(key) => !compareObjects(
					object2[key],
					object1[key],
				),
			),
		);

	return Array.from(new Set(differences));
}

// eslint-disable-next-line import/prefer-default-export
export function deepAssign<T extends Record<string, any>, U>(target: T, source: U): T & U;
export function deepAssign<T extends Record<string, any>, U, V>(target: T, source1: U, source2: V): T & U & V;
export function deepAssign<T extends Record<string, any>, U, V, W>(target: T, source1: U, source2: V, source3: W): T & U & V & W;
export function deepAssign(
	target: Record<string, any>,
	...sources: any[]
): any {
	sources.forEach((source) => {
		if (!isDeepObject(source) || !isDeepObject(target)) {
			return;
		}

		// Copy source's own properties into target's own properties
		function copyProperty(property: string) {
			const descriptor = Object.getOwnPropertyDescriptor(
				source,
				property,
			);

			// default: omit non-enumerable properties
			if (descriptor?.enumerable) {
				// Copy in-depth first
				if (
					isDeepObject(source[property])
					&& isDeepObject(target[property as keyof typeof target])
				) {
					descriptor.value = deepAssign(
						target[property as keyof typeof target],
						source[property],
					);
				}

				Object.defineProperty(
					target,
					property,
					descriptor,
				); // shallow copy descriptor
			}
		}

		// Copy string-keyed properties
		Object
			.getOwnPropertyNames(source)
			.forEach(copyProperty);
	});

	return target;
}
