import AWS from 'aws-sdk';
import moment from 'moment';
import ajax from 'controllers/ajax';

class AwsCognito {
	private credentials: AWS.CognitoIdentity.Credentials | undefined = undefined;

	private identityId: AWS.CognitoIdentity.IdentityId | undefined = undefined;

	public checkCredentials(): Promise<boolean> {
		if (this.credentials?.Expiration
			&& moment(this.credentials?.Expiration).format('X') > moment().format('X')
		) {
			return Promise.resolve(
				false,
			);
		}

		return ajax.request(
			{
				url: '/api/user/cognito',
			},
			{
				auth: true,
			},
		).then((response) => {
			const responseData = response.data;
			if (responseData.token && responseData.identityId) {
				const Logins: Record<string, string> = {};
				Logins['cognito-identity.amazonaws.com'] = responseData.token;

				const cognitoidentity = new AWS.CognitoIdentity({
					region: AWS_DEFAULT_REGION,
				});
				return cognitoidentity.getCredentialsForIdentity({
					IdentityId: responseData.identityId,
					Logins,
				}).promise();
			}

			throw new Error('No Cognito data in response');
		}).then((data) => {
			this.credentials = data.Credentials;
			this.identityId = data.IdentityId;

			return true;
		});
	}

	public getCredentials(): AWS.CognitoIdentity.Credentials | undefined {
		return this.credentials;
	}

	public getIdentityId(): AWS.CognitoIdentity.IdentityId | undefined {
		return this.identityId;
	}
}

export default new AwsCognito();
