import moment from 'moment';
import {
	ChannelModel,
	ConnectorBridgeResponseData,
	ChannelProfileModel,
} from 'interfaces/app';
import { ERRORS_OAUTH_FAILED } from '../settings/errors';
import connector, {
	ConnectorBridge,
	ConnectorBridgeLoginResponse,
	ConnectorBridgeLoginOptions,
	ConvertedPhotoData,
} from '../controllers/connector';

class GooglePlusClass implements ConnectorBridge {
	public isSupported = true;

	private network: ChannelModel['id'] = 'gplus';

	private tokenClient: google.accounts.oauth2.TokenClient | undefined = undefined;

	private libraryUrlGsiCient = '//accounts.google.com/gsi/client';

	private libraryUrlGapi = 'https://apis.google.com/js/api.js';

	private userProfile: ChannelProfileModel | undefined = undefined;

	public contentFilters: string[] = [
		'NONE',
		'LANDSCAPES',
		'RECEIPTS',
		'CITYSCAPES',
		'LANDMARKS',
		'SELFIES',
		'PEOPLE',
		'PETS',
		'WEDDINGS',
		'BIRTHDAYS',
		'DOCUMENTS',
		'TRAVEL',
		'ANIMALS',
		'FOOD',
		'SPORT',
		'NIGHT',
		'PERFORMANCES',
		'WHITEBOARDS',
		'SCREENSHOTS',
		'UTILITY',
		'ARTS',
		'CRAFTS',
		'FASHION',
		'HOUSES',
		'GARDENS',
		'FLOWERS',
		'HOLIDAYS',
	];

	public grantedScopes: string[] = [];

	private requestScopes = 'https://www.googleapis.com/auth/photoslibrary.readonly https://www.googleapis.com/auth/userinfo.profile';

	private loadLibrary(
		URL: string,
	): Promise<void> {
		return new Promise((resolve) => {
			// adding the script tag to the head as suggested before
			const element = document.getElementsByTagName('script')[0];
			const js = document.createElement('script');
			js.type = 'text/javascript';
			js.src = URL;
			js.async = true;
			js.defer = true;

			// then bind the event to the callback function
			js.onload = () => {
				resolve();
			};

			// fire the loading
			element.parentNode?.insertBefore(
				js,
				element,
			);
		});
	}

	private loadGsiClient(): Promise<void> {
		if (typeof window.google !== 'undefined') {
			return Promise.resolve();
		}

		return this.loadLibrary(
			this.libraryUrlGsiCient,
		);
	}

	private loadGapiClient(): Promise<void> {
		if (typeof window.gapi !== 'undefined') {
			return Promise.resolve();
		}

		return this.loadLibrary(
			this.libraryUrlGapi,
		).then(() => new Promise((resolve) => {
			window.gapi.load(
				'client',
				resolve,
			);
		})).then(() => window.gapi.client.init({}));
	}

	public setup(): Promise<void> {
		return Promise.all([
			this.loadGsiClient(),
			this.loadGapiClient(),
		]).then(() => undefined);
	}

	public init(channelModel: ChannelModel): Promise<void> {
		if (!channelModel.apikey) {
			return Promise.reject(
				new Error('Missing api key'),
			);
		}

		if (!window.google) {
			return Promise.reject(
				new Error('GSI Client is unavailable'),
			);
		}

		if (!window.gapi) {
			return Promise.reject(
				new Error('GAPI Client is unavailable'),
			);
		}

		this.tokenClient = window.google.accounts.oauth2.initTokenClient({
			client_id: channelModel.apikey,
			scope: this.requestScopes,
			// @ts-ignore
			callback: () => {}, // eslint-disable-line
		});

		return Promise.resolve();
	}

	private getUserConnectDetails(): Promise<{ accessToken: string }> {
		if (!window.gapi) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		const cred = window.gapi.client.getToken();
		if (!cred || !cred.access_token) {
			return Promise.reject(
				new Error('User not connected'),
			);
		}

		if (!this.userProfile) {
			return this.getProfile().then(
				() => this.getUserConnectDetails(),
			);
		}

		return Promise.resolve({
			...this.userProfile,
			accessToken: cred.access_token,
		});
	}

	public login(
		scopes: string[],
		options: ConnectorBridgeLoginOptions,
	): Promise<ConnectorBridgeLoginResponse> {
		if (!this.tokenClient) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		if (options.display && options.display == 'none') {
			if (!connector.networks.gplus.accessToken) {
				return Promise.reject(
					new Error('No active access_token from Google'),
				);
			}

			return this.getUserConnectDetails();
		}

		const { tokenClient } = this;

		return new Promise((resolve, reject) => {
			// @ts-ignore
			tokenClient.callback = () => {
				this.getUserConnectDetails().then(
					resolve,
					reject,
				);
			};

			if (window.gapi.client.getToken() === null) {
				// Prompt the user to select a Google Account and asked for consent to share their data
				// when establishing a new session.
				tokenClient.requestAccessToken({
					prompt: 'consent',
				});
			} else {
				// Skip display of account chooser and consent dialog for an existing session.
				tokenClient.requestAccessToken({
					prompt: '',
				});
			}
		});
	}

	public logout() {
		return this.getUserConnectDetails()
			.then(
				(resp): Promise<void> => new Promise((resolve) => {
					window.google.accounts.oauth2.revoke(
						resp.accessToken,
						() => {
							console.log('Revoked Google accesstoken');
							resolve();
						},
					);
				}),
				() => Promise.resolve(),
			).then(() => {
				window.gapi.client.setToken(null);
			});
	}

	private getProfile() {
		const url = 'https://www.googleapis.com/oauth2/v1/userinfo';
		return window.gapi.client.request({
			path: url,
		}).then((resp) => {
			if (!resp.result || !resp.result.id) {
				throw new Error(ERRORS_OAUTH_FAILED);
			}

			this.userProfile = resp.result;
			return resp.result;
		});
	}

	public me(): Promise<ChannelProfileModel> {
		if (this.userProfile?.id) {
			return Promise.resolve(
				this.userProfile as ChannelProfileModel,
			);
		}

		return Promise.reject();
	}

	public folders() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albums(options: any) {
		if (!window.gapi.client) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		const limit = 20;

		let url = `https://photoslibrary.googleapis.com/v1/albums?pageSize=${limit}`;
		if (options.nextOptions) {
			url += `&pageToken=${options.nextOptions}`;
		}

		return window.gapi.client.request({
			path: url,
		}).then((resp) => {
			if (!resp.result) {
				throw new Error(ERRORS_OAUTH_FAILED);
			}

			const returnData: ConnectorBridgeResponseData = {
				data: resp.result.albums,
				paging: {},
			};

			if (resp.result.nextPageToken) {
				returnData.paging.nextOptions = resp.result.nextPageToken;
			}

			return returnData;
		});
	}

	public albumPhotos(
		albumid: string,
		options: any,
	) {
		if (!window.gapi.client) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		const limit = 20;

		const url = 'https://photoslibrary.googleapis.com/v1/mediaItems:search';
		const data: {
			pageSize: number;
			albumId: string;
			pageToken?: string;
		} = {
			pageSize: limit,
			albumId: albumid,
		};
		if (options.nextOptions) {
			data.pageToken = options.nextOptions;
		}

		return window.gapi.client.request({
			method: 'POST',
			path: url,
			body: data,
		}).then((resp) => {
			if (!resp.result) {
				throw new Error(ERRORS_OAUTH_FAILED);
			}

			const returnData: ConnectorBridgeResponseData = {
				data: resp.result.mediaItems.filter(
					(mediaItem: any) => mediaItem.mediaMetadata.hasOwnProperty('photo'),
				),
				paging: {},
			};

			if (resp.result.nextPageToken) {
				returnData.paging.nextOptions = resp.result.nextPageToken;
			}

			return returnData;
		});
	}

	public photos(options: any) {
		if (!window.gapi.client) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		const limit = 20;

		const url = 'https://photoslibrary.googleapis.com/v1/mediaItems:search';
		const data: {
			pageSize: number;
			pageToken?: string;
			filters: {
				mediaTypeFilter: {
					mediaTypes: string[];
				};
				contentFilter?: {
					includedContentCategories: string[];
				};
				dateFilter?: {
					ranges: [{
						startDate: {
							year: number;
							month: number;
							day: number;
						};
						endDate: {
							year: number;
							month: number;
							day: number;
						};
					}];
				};
			};
		} = {
			pageSize: limit,
			filters: {
				mediaTypeFilter: {
					mediaTypes: ['PHOTO'],
				},
			},
		};

		if (options.category) {
			data.filters.contentFilter = {
				includedContentCategories: [options.category],
			};
		}
		if (options.fromDate && options.toDate) {
			const fromDate = moment(options.fromDate);
			const toDate = moment(options.toDate);

			data.filters.dateFilter = {
				ranges: [{
					startDate: {
						year: parseInt(
							fromDate.format('YYYY'),
							10,
						),
						month: parseInt(
							fromDate.format('MM'),
							10,
						),
						day: parseInt(
							fromDate.format('DD'),
							10,
						),
					},
					endDate: {
						year: parseInt(
							toDate.format('YYYY'),
							10,
						),
						month: parseInt(
							toDate.format('MM'),
							10,
						),
						day: parseInt(
							toDate.format('DD'),
							10,
						),
					},
				}],
			};
		}
		if (options.nextOptions) {
			data.pageToken = options.nextOptions;
		}

		return window.gapi.client.request({
			method: 'POST',
			path: url,
			body: data,
		}).then((resp) => {
			if (!resp.result) {
				throw new Error(ERRORS_OAUTH_FAILED);
			}

			const returnData: ConnectorBridgeResponseData = {
				data: resp.result.mediaItems,
				paging: {},
			};

			if (resp.result.nextPageToken) {
				returnData.paging.nextOptions = resp.result.nextPageToken;
			}

			return returnData;
		});
	}

	public convertFolderData() {
		throw new Error('Bridge function not implemented');
	}

	public convertAlbumData(albumData: {
		id: string;
		title: string;
		coverPhotoBaseUrl: string;
	}) {
		const objAlbum = {
			id: albumData.id,
			name: albumData.title,
			thumbnail: albumData.coverPhotoBaseUrl,
		};

		return objAlbum;
	}

	public convertPhotoData(photoData: {
		id: string;
		baseUrl: string;
		mediaMetadata?: {
			creationTime?: string;
			width: number;
			height: number;
		};
	}) {
		const objPhoto: ConvertedPhotoData = {
			source: this.network,
			externalId: String(photoData.id),
			full_url: `${photoData.baseUrl}=d`,
			thumb_url: photoData.baseUrl,
		};

		if (photoData.mediaMetadata) {
			objPhoto.full_width = photoData.mediaMetadata.width;
			objPhoto.full_height = photoData.mediaMetadata.height;
			if (photoData.mediaMetadata.creationTime) {
				objPhoto.photodate = photoData.mediaMetadata.creationTime;
			}
		}

		return objPhoto;
	}

	public getFileType() {
		throw new Error('Bridge function not implemented');
	}

	public share() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}
}

export default GooglePlusClass;
