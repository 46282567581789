import * as PI from 'interfaces/project';

const photoModel: RequiredExceptFor<PI.PhotoModel, 'id' | 'userid'> = {
	_error: undefined,
	_orientation: undefined,
	_processing: undefined,
	_type: undefined,
	_localRef: undefined,
	removing: undefined,
	kbyte: undefined,
	source: null,
	full_url: null,
	full_width: 0,
	full_height: 0,
	url: null,
	width: null,
	height: null,
	thumb_url: null,
	thumb_width: null,
	thumb_height: null,
	fcx: null,
	fcy: null,
	fcw: null,
	fch: null,
	title: null,
	likes: null,
	externalId: null,
	token: '',
	photodate: null,
	type: 'photo',
};

export default {
	photoModel,
};
