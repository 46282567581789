export default function getColorInverse(hexcolor: string): string {
	if (
		typeof hexcolor !== 'string'
		|| hexcolor.at(0) !== '#'
	) {
		// This is not a hex color, so return black
		return '#000000';
	}

	// strip off any leading #
	const hexR = hexcolor.substring(
		1,
		3,
	);
	const hexG = hexcolor.substring(
		3,
		5,
	);
	const hexB = hexcolor.substring(
		5,
		7,
	);

	const decR = parseInt(
		(`${hexR}`).replace(
			/[^a-f0-9]/gi,
			'',
		),
		16,
	);
	const decG = parseInt(
		(`${hexG}`).replace(
			/[^a-f0-9]/gi,
			'',
		),
		16,
	);
	const decB = parseInt(
		(`${hexB}`).replace(
			/[^a-f0-9]/gi,
			'',
		),
		16,
	);

	const brightness = (decR * 299 + decG * 587 + decB * 114) / 1000;

	if (brightness < 150) {
		return '#FFFFFF';
	}

	return '#000000';
}
