import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import {
	EditorToolbarPageObjectModel,
	EditorToolbarPageObjectsModel,
	EditorToolbarPhotos,
} from 'interfaces/app';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import VueDraggable, { VueDraggableEvent } from 'vuedraggable';
import Template from './template.vue';

@Component({
	name: 'EditorLayerListView',
	components: {
		ButtonToggleComponent,
		VueDraggable,
	},
})
export default class EditorLayerListView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the object list model to re-order',
			required: true,
			schema: 'EditorToolbarPageObjectsModel',
			type: Array,
		},
	)
	public readonly objects!: EditorToolbarPageObjectsModel;

	@Prop({
		default: 1,
		description: 'Defines the number of columns in the editor layer list',
		type: Number,
	})
	public readonly columns!: number;

	@Prop({
		default: undefined,
		description: 'Defines the available photo models (used to get the object photo url, if any)',
		schema: 'EditorToolbarPhotos',
		type: Array,
	})
	public readonly photos?: EditorToolbarPhotos;

	@Prop({
		default: 'auto',
		description: "Defines the size (width/height) of the object photo image, if set to auto it's going to use the available width divided by the number of columns",
		type: [Number, String],
	})
	public readonly objectPhotoSize!: string | number;

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, number | string> {
		return {
			'--columns': this.columns,
		};
	}

	protected get computedObjectPhotoStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			this.objectPhotoSize
			&& this.objectPhotoSize !== 'auto'
		) {
			if (typeof this.objectPhotoSize === 'number') {
				styles.maxHeight = `${this.objectPhotoSize}px`;
				styles.maxWidth = `${this.objectPhotoSize}px`;
			} else {
				styles.maxHeight = this.objectPhotoSize;
				styles.maxWidth = this.objectPhotoSize;
			}
		}

		return styles;
	}

	protected get getObjectPhotoUrl(): (object: EditorToolbarPageObjectModel) => string | undefined {
		return (object) => {
			const photoFound = this.photos?.find((photo) => photo.id === object.photoid);

			if (photoFound) {
				return `${photoFound.thumb_url}?noCorsHeader`;
			}

			return undefined;
		};
	}

	protected get objectsOrdered(): EditorToolbarPageObjectsModel {
		return this.internalObjects.sort((a, b) => {
			if (a.z_axis < b.z_axis) {
				return 1;
			}
			if (a.z_axis > b.z_axis) {
				return -1;
			}

			return -1;
		});
	}

	private internalObjects: EditorToolbarPageObjectsModel = [];

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalObjects = this.objects.map((object) => ({
			...object,
		}));
	}

	protected onButtonToggleChange(
		_value: boolean,
		event: Event,
	): void {
		event.preventDefault();
	}

	protected onObjectsOrderChange(event: VueDraggableEvent): void {
		if (event.moved) {
			const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {};
			const { objectsOrdered } = this;
			const objectsQuantity = objectsOrdered.length;
			const newObjectsOrdered = [
				...objectsOrdered,
			];
			const [movedObject] = newObjectsOrdered.splice(
				event.moved.oldIndex,
				1,
			);
			newObjectsOrdered.splice(
				event.moved.newIndex,
				0,
				movedObject,
			);

			for (let index = 0; index < objectsQuantity; index += 1) {
				const realIndex = this.internalObjects.findIndex((object) => object.id === newObjectsOrdered[index].id);
				const newZAxis = objectsQuantity - index;

				if (this.internalObjects[realIndex].z_axis !== newZAxis) {
					const internalObject = this.internalObjects[realIndex];
					objectsDifferences[internalObject.id] = ['z_axis'];
					internalObject.z_axis = newZAxis;
				}
			}

			this.$emit(
				'change',
				this.internalObjects,
				objectsDifferences,
			);
		}
	}

	protected onMouseEnter(object: EditorToolbarPageObjectModel): void {
		const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
			[object.id]: ['_hovered'],
		};
		object._hovered = true;
		this.$emit(
			'change',
			this.internalObjects,
			objectsDifferences,
		);
	}

	protected onMouseLeave(object: EditorToolbarPageObjectModel): void {
		const objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
			[object.id]: ['_hovered'],
		};
		object._hovered = false;
		this.$emit(
			'change',
			this.internalObjects,
			objectsDifferences,
		);
	}
}
