var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"framePickerView"},[_c('div',{staticClass:"closer"},[_c('span',{staticClass:"icon",on:{"click":_vm.closePicker}},[_vm._v("\n\t\t\tclose\n\t\t")])]),_vm._v(" "),_c('div',{staticClass:"header"},[_c('span',[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('views.framePicker.header'))+"\n\t\t")])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{ref:"twodViewWrapper",staticClass:"twodViewWrapper"},[(_vm.model2D)?_c('TwoDView',{attrs:{"box-width":_vm.boxWidth,"box-height":_vm.boxHeight,"model2d-model":_vm.model2D,"offering-model":_vm.selectedOfferingModel,"page-model":_vm.pageModel}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"frameOptionsContainer"},[_c('ul',{staticClass:"frameOptions"},_vm._l((_vm.offeringOptionFrameValueModels),function(frameValueModel){return _c('li',{key:frameValueModel.id,class:[
						'frameOption',
						{ selected: _vm.selectedFrameValueModel && frameValueModel.id === _vm.selectedFrameValueModel.id }
					],on:{"click":function($event){return _vm.selectFrameValueModel(frameValueModel)}}},[_c('div',{staticClass:"variantImageHolder"},[(_vm.getValueModelPreviewImage(frameValueModel))?_c('img',{attrs:{"src":_vm.getValueModelPreviewImage(frameValueModel)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t(
								("offeringOptionValues:" + (frameValueModel.id)),
								frameValueModel.value
							))+"\n\t\t\t\t\t")])])}),0),_vm._v(" "),_c('div',{staticClass:"priceContainer"},[_c('PriceView',{attrs:{"cents":_vm.selectedOfferingPrice}})],1),_vm._v(" "),_c('div',{staticClass:"buttonContainer"},[_c('button',{staticClass:"actionbutton altColor full",on:{"click":_vm.saveChanges}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t(
							'buttonDoneEditing'
						))+"\n\t\t\t\t")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }