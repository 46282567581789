// @ts-ignore
import FilmImage from '../../img/effects/film.jpg';
// @ts-ignore
import VintageImage from '../../img/effects/vintage.jpg';

const vintagePresets: {
	[key: string]: Record<string, any>;
} = {
	vintage: {
		contrast: 15 / 100,
		curves: (function curves() {
			const k: {
				r: number[];
				g: number[];
				b: number[];
			} = {
				r: [],
				g: [],
				b: [],
			};
			for (
				let d = (a1: number) => -12 * Math.sin(2 * a1 * Math.PI / 255) + a1,
					g = (a2: number) => -0.2 * ((255 * a2) ** 0.5) * Math.sin(Math.PI * (-1.95E-5 * (a2 ** 2) + 0.0125 * a2)) + a2,
					a = (a3: number) => -0.001045244139166791 * (a3 ** 2) + 1.2665372554875318 * a3,
					h = (a4: number) => 0.57254902 * a4 + 53,
					f = 0; f <= 255; f += 1
			) {
				k.r[f] = g(d(f));
				k.g[f] = a(d(f));
				k.b[f] = h(d(f));
			}

			return k;
		}()),
		screen: {
			r: 227,
			g: 12,
			b: 169,
			a: 0.15,
		},
		vignette: 0.7,
		viewfinder: VintageImage,
	},
	sepia: {
		sepia: true,
	},
	grey: {
		gray: true,
	},
	contrast: {
		contrast: 0.75,
		vignette: 0.5,
	},
	negate: {
		curves: (function curves() {
			const d = (a: number) => 255 - a;
			const neg: {
				r: number[];
				g: number[];
				b: number[];
			} = {
				r: [],
				g: [],
				b: [],
			};

			for (let a = 0; a <= 255; a += 1) {
				neg.r[a] = d(a);
				neg.g[a] = d(a);
				neg.b[a] = d(a);
			}

			return neg;
		}()),
	},
	film: {
		brightness: 0.1,
		vignette: 0.3,
		viewfinder: FilmImage,
		screen: {
			r: 227,
			g: 12,
			b: 169,
			a: 0.15,
		},
	},
	boost: {
		brightness: -0.1,
		contrast: 0.15,
	},
};

export default vintagePresets;
