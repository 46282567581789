import './defines';
import Theme from 'classes/theme';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { OfferingGroups } from 'settings/offerings';
import {
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
} from 'store';
import appendUrlParameter from 'tools/append-url-parameter';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class BackgroundToolbarView extends Vue.extend(Template) {
	@Prop({ default: null, type: Object })
	public readonly pageModel!: PI.PageModel;

	protected get selectedBackground() {
		if (this.pageModel) {
			return this.pageModel.bgpattern || this.pageModel.bgcolor;
		}

		const productSettings = ProductStateModule.getProductSettings;
		if (productSettings && productSettings.bgpattern) {
			return productSettings.bgpattern;
		}
		if (productSettings && productSettings.bgcolor) {
			return productSettings.bgcolor;
		}

		return undefined;
	}

	protected get collection() {
		const colorCollection = JSON.parse(JSON.stringify(ThemeDataModule.getPageBackgrounds));

		if (this.pageModel) {
			if (this.pageModel.bgcolor
				&& this.pageModel.bgcolor.length
				&& !_.findWhere(
					colorCollection,
					{ color: this.pageModel.bgcolor },
				)
			) {
				colorCollection.push({ color: this.pageModel.bgcolor });
			}
			if (this.pageModel.bgpattern
				&& !_.findWhere(
					colorCollection,
					{ image: this.pageModel.bgpattern },
				)
			) {
				colorCollection.push({ image: this.pageModel.bgpattern });
			}
		} else {
			const { themeModel } = ThemeStateModule;
			if (themeModel
				&& !_.isNull(themeModel.color)
				&& !_.findWhere(
					colorCollection,
					{ color: themeModel.color },
				)
			) {
				colorCollection.push({ color: themeModel.color });
			}
		}

		return colorCollection;
	}

	protected get isLoading() {
		return !ThemeDataModule.getFetched;
	}

	protected created() {
		Theme.fetchData(false).then(() => {
			this.$emit('updateDialog');
		});
	}

	private close() {
		this.$emit('closeDialog');
	}

	protected convertUrl(url: string) {
		return appendUrlParameter(
			url,
			'noCorsHeader',
		);
	}

	protected selectBackground(bgModel: DB.PageBackgroundModel) {
		const bgcolor = bgModel.color;
		const bgpattern = bgModel.image;

		if (this.pageModel) {
			ProductStateModule.changePage({
				id: this.pageModel.id,
				bgcolor,
				bgpattern,
			});

			const productModel = ProductStateModule.getProduct;
			if (productModel && OfferingGroups(
				productModel.group,
				['BookTypes'],
			)) {
				// Set flag if the page is a back cover page
				const pageIndex = ProductStateModule.getPageIndex(this.pageModel);
				if (pageIndex === 0 || pageIndex === 1) {
					const otherHalfOfCoverPageModel = ProductStateModule.getPageByNumber(pageIndex === 0 ? 1 : 0);
					if (!otherHalfOfCoverPageModel) {
						throw new Error('Could not find required backCoverPageModel');
					}

					ProductStateModule.changePage({
						id: otherHalfOfCoverPageModel.id,
						bgcolor,
						bgpattern,
					});
				}
			}
		} else {
			ProductStateModule.changeProductSettings({
				bgcolor,
				bgpattern,
			});
		}

		ProductStateModule.pushHistory();
		this.close();
	}
}
