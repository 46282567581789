import FingerprintJS from '@fingerprintjs/fingerprintjs';
import detectBrowser from 'tools/detect-browser';
import { DeviceDetails } from 'interfaces/app';

const getDeviceDetails = (): Promise<DeviceDetails> => {
	if (window.glPlatform === 'native') {
		if (!window.webToNative) {
			throw new Error('Missing WebToNative on window');
		}

		return window.webToNative.getNativeDetails().then((nativeDeviceDetails) => ({
			platform: 'Native',
			os: nativeDeviceDetails.platform,
			deviceUUID: nativeDeviceDetails.deviceUUID,
			platformDetails: nativeDeviceDetails,
		}));
	}

	const browserDetails = detectBrowser();

	// Initialize fingerprint library
	const fpPromise = FingerprintJS.load();

	return fpPromise
		.then((fp) => fp.get())
		.then((result) => Promise.resolve({
			platform: 'Web',
			os: browserDetails.os,
			deviceUUID: result.visitorId,
			platformDetails: {
				browserName: browserDetails.name,
				browserVersion: browserDetails.version,
				browserMajorVersion: browserDetails.majorVersion,
			},
		}));
};

export default getDeviceDetails;
