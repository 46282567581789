import './defines';
import * as DB from 'interfaces/database';
import {
	AppDataModule,
	ConfigModule,
	UserModule,
} from 'store/index';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class PriceView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the currency id from where to get the currency model to get the prefix, separator and suffix',
		type: String,
	})
	public readonly currency!: DB.CurrencyModel['id'];

	@Prop({
		description: 'Defines the price to show in cents',
		required: true,
		type: Number,
	})
	public readonly cents!: number;

	private get currencyModel() {
		const currency = this.currency || UserModule.currency;

		return currency
			? AppDataModule.getCurrency(currency)
			: undefined;
	}

	protected get currencyPrefix() {
		if (this.currencyModel) {
			return this.currencyModel.prefix;
		}

		return '';
	}

	protected get currencySeparator() {
		if (this.currencyModel) {
			return this.currencyModel.separator;
		}

		return '';
	}

	protected get currencySuffix() {
		if (this.currencyModel) {
			return this.currencyModel.suffix;
		}

		return '';
	}

	protected get decimals() {
		if (
			this.useSup
			&& this.value % 1 === 0
		) {
			return '–';
		}

		return (this.value % 1)
			.toFixed(2)
			.substring(2);
	}

	protected get units() {
		return Math.trunc(this.value);
	}

	private get useSup() {
		return ConfigModule.partnerID === 2;
	}

	private get value() {
		return Math.ceil(this.cents) / 100;
	}
}
