import _ from 'underscore';
import merge from 'deepmerge';

const iconSrc = require('../../img/warning.svg');

function getImageNode(
	src: string,
	options: {
		error?: () => void;
	} = {},
) {
	const defaults = {};
	options = options ? merge(
		defaults,
		options,
	) : defaults;

	const imgNode = document.createElement('img');
	imgNode.onerror = function onImageError() {
		if (imgNode.src != iconSrc) {
			imgNode.src = iconSrc;
		}

		if (options.error && _.isFunction(options.error)) {
			options.error();
		}
	};
	imgNode.src = src;

	return imgNode;
}

export default getImageNode;
