import './defines';
import PriceCalculator from 'classes/price-calculator';
import ProductState from 'classes/productstate';
import {
	PricingObject,
	SubnavbarProjectItemModel,
	SubnavbarViewPrice,
} from 'interfaces/app';
import {
	CountryModel,
	OfferingModel,
	ProductModel,
	UserModel,
} from 'interfaces/database';
import StatusView from 'views/header-status';
import PriceView from 'views/price';
import ClickOutside from 'vue-click-outside';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import clickLogo from 'ops/click-logo';
import {
	AppDataModule,
	AppStateModule,
	ConfigModule,
	ProductStateModule,
	UserModule,
} from 'store/index';
import Template from './template.vue';

@Component({
	components: {
		PriceView,
		StatusView,
	},
	directives: {
		ClickOutside,
	},
})
export default class SubnavbarView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates if the subnavbar is in a fixed position',
		type: Boolean,
	})
	public readonly fixedPosition!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the subnavbar must show the next button, when yes a slot can be provided to show the desired text of the button',
		type: Boolean,
	})
	public readonly hasNext!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the subnavbar must show the price, price will be shown if the offering model is available in the current contry model as well',
		type: Boolean,
	})
	public readonly hasPrice!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the next button should be hidden, this only intended for mobile, it uses css `@media @mobile`, it will not hide it otherwise',
		type: Boolean,
	})
	public readonly hideNextMobile!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if it should show the header-status view component, the `ConfigModule` must have the `header.status` key set to true as well, if not the component will not be shown',
		type: Boolean,
	})
	public readonly showStatus!: boolean;

	private get countryModel(): CountryModel | undefined {
		if (UserModule.countryid) {
			return AppDataModule.getCountry(UserModule.countryid);
		}

		return undefined;
	}

	private get isAvailableInRegion(): boolean {
		if (
			this.countryModel
			&& this.offeringModel
		) {
			return !!AppDataModule.findRegionOfferingLinkWhere({
				regionid: this.countryModel.regionid,
				offeringid: this.offeringModel.id,
			});
		}

		return false;
	}

	protected get isOnline(): boolean {
		return AppStateModule.online;
	}

	private get offeringModel(): OfferingModel | null {
		return ProductStateModule.getOffering;
	}

	protected get projectStatusSaved(): boolean {
		return !!ProductStateModule.getSaved;
	}

	protected get projectStatusSyncing(): boolean {
		return AppStateModule.sync;
	}

	private get priceData(): PricingObject | false {
		if (
			this.isAvailableInRegion
			&& this.productModel
		) {
			return PriceCalculator.projectPrice({
				productid: this.productModel.id,
			});
		}

		return false;
	}

	private get productModel(): ProductModel | null {
		return ProductStateModule.getProduct;
	}

	protected get productPrice(): SubnavbarViewPrice {
		const objPrice = this.priceData;
		let productPrice = objPrice
			? objPrice.subTotal - objPrice.bulkDiscountValue
			: 0;

		const productPriceFrom = objPrice ? objPrice.subTotalFrom : 0;

		if (
			objPrice
			&& objPrice.offeringModel
		) {
			const upsellModels = AppDataModule.whereUpsell({
				offeringid: objPrice.offeringModel.id,
				autoinclude: 1,
				optional: 0,
			});

			upsellModels.forEach((upsellModel) => {
				if (this.productModel) {
					const price = PriceCalculator.projectPrice({
						offeringid: upsellModel.upsellid,
						productid: this.productModel.id,
					});
					if (price) {
						productPrice += price.subTotal;
					}
				}
			});
		}
		return {
			productPrice,
			productPriceFrom,
		};
	}

	protected get projectContent(): SubnavbarProjectItemModel[] {
		const items: SubnavbarProjectItemModel[] = [];

		if (
			!this.productModel
			|| !this.offeringModel
			|| !UserModule.id
		) {
			return items;
		}

		const objPrice = PriceCalculator.projectPrice({
			productid: this.productModel.id,
		});

		if (objPrice.subItems) {
			objPrice.subItems.forEach((subItem) => {
				items.push({
					icon: subItem.offeringModel.thumbnail,
					title: AppDataModule.getOfferingName(subItem.offeringModel.id),
					subscript: AppDataModule.getOfferingDescription(subItem.offeringModel.id),
					priceFrom: subItem.bulkDiscountValue > 0 ? subItem.subTotal : 0,
					price: subItem.subTotal - subItem.bulkDiscountValue,
					quantity: `(${subItem.printPageCount} ${this.$t(
						'photo',
						{ count: subItem.printPageCount },
					)})`,
				});
			});
		} else {
			items.push({
				icon: this.offeringModel.thumbnail,
				title: AppDataModule.getOfferingName(this.offeringModel.id),
				subscript: AppDataModule.getOfferingDescription(this.offeringModel.id),
				price: objPrice.subTotal - objPrice.bulkDiscountValue,
				priceFrom: objPrice.bulkDiscountValue > 0 ? objPrice.subTotal : 0,
			});
		}

		return items;
	}

	protected get showStatusHeader(): boolean {
		return ConfigModule['header.status'];
	}

	protected get userModel(): UserModel {
		return UserModule.getState;
	}

	protected projectStatusError: Partial<Error> | null = null;

	protected statusShowProjectSendEmail = false;

	protected statusShowSaveOptions = false;

	private showProjectContent = false;

	protected onCloseSaveOptions(): void {
		this.statusShowSaveOptions = false;
	}

	protected onHideProjectContent(): void {
		this.showProjectContent = false;
	}

	protected onLogoClick(): void {
		clickLogo();
	}

	protected onNextClick(): void {
		this.$emit(
			'go-forward',
			this.$vnode.key,
		);
	}

	protected onPriceClick(): void {
		this.showProjectContent = !this.showProjectContent;
	}

	protected onProjectSave(): Promise<void> {
		return ProductState
			.save()
			.then(() => {
				this.projectStatusError = null;
			})
			.catch(() => {
				this.projectStatusError = {};
			});
	}

	protected onProjectEmailSent(): void {
		this.statusShowProjectSendEmail = false;
	}

	protected onProjectFinalize(): Promise<void> {
		return ProductState
			.finalize(
				true,
				false,
			)
			.then((saved) => {
				this.projectStatusError = null;

				if (saved) {
					this.statusShowSaveOptions = true;
				}
			})
			.catch((error) => {
				this.projectStatusError = error;
			});
	}

	protected onProjectSendEmail(): void {
		this.statusShowSaveOptions = false;
		this.statusShowProjectSendEmail = true;
	}
}
