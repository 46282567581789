import * as urlTools from '@sosocio/frontend-utils/url';
import {
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import ajax from 'controllers/ajax';
import connector from 'controllers/connector';
import experiment from 'controllers/experiment';
import upload from 'controllers/upload';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as PI from 'interfaces/project';
import {
	ERRORS_INVALID_REQUEST_DATA,
	ERRORS_OFFLINE,
} from 'settings/errors';
import {
	FILE_EXTENSIONS_SHOWN_AS_SVG,
	MIME_TYPES_BROWSER_SUPPORTED,
} from 'settings/filetypes';
import {
	AppStateModule,
	ConfigModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import Vue from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import defaults from './defaults';
import queue from './queue';

@Module({ namespaced: true, name: 'photos' })
export default class Photos extends VuexModule {
	private collection: PI.PhotoModel[] = [];

	private fetched = false;

	private modelUrl = '/api/photo';

	private offset = 0;

	private totalRecords: number | null = null;

	private get collectionUrl(): string {
		return `/api/user/${this.context.rootState.user.id}/photos`;
	}

	public get findWhere() {
		return (properties: Partial<PI.PhotoModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get getById() {
		return (id: PI.PhotoModel['id']) => this.collection.find(
			(model) => model.id == id,
		);
	}

	public get getIds(): PI.PhotoModel['id'][] {
		return this.collection.map(
			(model) => model.id,
		);
	}

	public get models(): PI.PhotoModel[] {
		return _.sortBy(
			this.collection,
			'id',
		);
	}

	@Mutation
	private _addModel(data: PI.PhotoModel): void {
		this.collection.push(data);
	}

	@Mutation
	private _changeModelId([oldId, newId]: [string, number]): void {
		const i = this.collection.findIndex(
			(m) => m.id === oldId,
		);
		if (i >= 0) {
			const model = this.collection[i];
			Vue.set(
				this.collection,
				i,
				{
					...model,
					id: newId,
				},
			);
		}
	}

	@Mutation
	private _removeModel(id: PI.PhotoModel['id']): void {
		const i = this.collection.findIndex(
			(m) => m.id == id,
		);
		if (i >= 0) {
			this.collection.splice(
				i,
				1,
			);
			if (this.offset > 0) this.offset -= 1;
			if (this.totalRecords && this.totalRecords > 0) this.totalRecords -= 1;
		}
	}

	@Mutation
	private _resetCollection(data: PI.PhotoModel[]): void {
		this.collection = data || [];
	}

	@Mutation
	private _resetMetaData(): void {
		this.fetched = false;
		this.offset = 0;
		this.totalRecords = null;
	}

	@Mutation
	private _setModel(data: PI.PhotoModel): void {
		const i = this.collection.findIndex(
			(m) => m.id == data.id,
		);
		Vue.set(
			this.collection,
			i,
			data,
		);
	}

	@Mutation
	public updateModel(data: OptionalExceptFor<PI.PhotoModel, 'id'>): void {
		const i = this.collection.findIndex(
			(m) => m.id == data.id,
		);
		if (i >= 0) {
			const model = this.collection[i];
			Vue.set(
				this.collection,
				i,
				{
					...model,
					...data,
				},
			);
		}
	}

	@Mutation
	public updateModels(data: Partial<PI.PhotoModel>): void {
		this.collection.forEach(
			(model, i) => {
				Vue.set(
					this.collection,
					i,
					{
						...model,
						...data,
					},
				);
			},
		);
	}

	@Action({ rawError: true })
	public async addModel(data: OptionalExceptFor<PI.PhotoModel, 'id'>): Promise<PI.PhotoModel> {
		const { updateModel } = this;

		if (!data.id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		let photoModel = this.getById(data.id);

		if (photoModel) {
			updateModel(data);
		} else {
			if (!('removing' in data)) {
				Object.defineProperties(
					data,
					{
						_removing: {
							configurable: true,
							enumerable: false,
							value: false,
							writable: true,
						},
						removing: {
							configurable: true,
							enumerable: true,
							get(this: PI.PhotoModelWithRemoving) {
								return this._removing;
							},
							set(
								this: PI.PhotoModelWithRemoving,
								value: boolean,
							) {
								updateModel({
									id: this.id,
									_removing: value,
								} as PI.PhotoModelWithRemoving);
							},
						},
					},
				);
			}

			this._addModel({
				...JSON.parse(JSON.stringify(defaults.photoModel)),
				...data,
			});
		}

		photoModel = this.getById(data.id);

		if (
			photoModel
			&& typeof photoModel.id == 'string'
			&& photoModel.full_url
			&& !photoModel._processing
		) {
			queue.push(photoModel);

			// Important: We don't wait for the callback from the queue
			// This so the user can continue building the product
			// Photo processing will be resolved in the background
		}

		if (!photoModel) {
			throw new Error('Could not find photo model');
		}

		return Promise.resolve(photoModel);
	}

	@Action
	public addModels(arrData: PI.PhotoModel[]): Promise<PI.PhotoModel[]> {
		const arrPromises: Promise<PI.PhotoModel>[] = [];

		arrData.forEach((data) => {
			arrPromises.push(
				this.addModel(data),
			);
		});

		return Promise.all(arrPromises)
			.then((models) => models.filter(
				(model): model is PI.PhotoModel => !!model,
			));
	}

	@Action({ rawError: true })
	public createModel({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: OptionalExceptFor<PI.PhotoModel, 'id'>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<PI.PhotoModel> {
		if (!data) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (data.userid !== UserModule.id) {
			throw new Error('Invalid userid');
		}

		if (typeof data.id === 'number') {
			const photoModel = this.getById(data.id);
			if (!photoModel) {
				throw new Error(`Could not find photo model with id ${data.id}`);
			}

			return Promise.resolve(photoModel);
		}

		const objHeaders: {
			[key: string]: string;
		} = {
			'content-type': 'application/json; charset=utf-8',
		};

		if (data.source == 'dropbox' && connector.networks.dropbox.accessToken) {
			objHeaders['X-Dropbox-Token'] = connector.networks.dropbox.accessToken;
		}
		if (data.source == 'microsoft' && connector.networks.microsoft.accessToken) {
			objHeaders['X-Microsoft-Token'] = connector.networks.microsoft.accessToken;
		}

		// Filter out some properties for uploads
		// (these properties need to be included in modelAttributes, for matching after save)
		const ommitedkeys = [
			'_error',
			'_orientation',
			'_processing',
			'_type',
			'_localRef',
			'_vectorize',
			'removing',
			'id',
		];
		if (data.source === 'upload') {
			ommitedkeys.push(
				'externalId',
				'thumb_url',
			);
		}
		const postData = _.omit(
			data,
			ommitedkeys,
		);

		let requestUrl = this.modelUrl;
		if (data._vectorize) {
			requestUrl += `?vectorize=${JSON.stringify(data._vectorize)}`;
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			headers: objHeaders,
			method: 'post',
			url: requestUrl,
			data: postData,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 0,
			debug: {
				offline: true,
				dialog: false,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		const localId = data.id as string;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				if (!response.data.id) {
					throw new Error('No valid data returned');
				}

				if (localId) {
					this._changeModelId(
						[localId, response.data.id],
					);
					const newData = {
						...response.data,
						_error: undefined,
					};

					if (!data._localRef) {
						// We normalize the orientation for saved photos that are not loaded from the device
						newData._orientation = undefined;
					}

					if (data._vectorize) {
						// The image has been vectorized, so we need to reset the image type
						newData._type = 'image/svg+xml';
					}

					this.updateModel(
						newData,
					);

					const photoModel = this.getById(response.data.id);
					if (!photoModel) {
						throw new Error('Could not find photo model');
					}

					return photoModel;
				}

				return this.addModel(
					response.data,
				);
			});
	}

	@Action({ rawError: true })
	public destroyModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const model = this.getById(id);

		return this.removeModel(
			id,
		).then(() => {
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'delete',
				url: `${this.modelUrl}/${id}`,
				headers: {
					'content-type': 'application/json; charset=utf-8',
				},
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				retry: 1,
				debug: {
					offline: true,
					dialog: true,
					abort: false,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			return ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then(() => undefined);
		}).catch(async (err: Error) => {
			if (model) {
				try {
					await this.addModel(model);
				} catch {
					// Shallow error: no action required
				}
			}

			throw err;
		});
	}

	@Action({ rawError: true })
	public fetchModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: Omit<AjaxOptions, 'auth'>;
	}): Promise<PI.PhotoModel> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${this.modelUrl}/${id}`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions as AjaxOptions,
			)
			.then(async (response: AxiosResponse<PI.PhotoModel>) => {
				await this.addModel(response.data);
				const photoModel = this.getById(response.data.id);
				if (!photoModel) {
					throw new Error('Could not find photo model');
				}

				return photoModel;
			});
	}

	@Action({ rawError: true })
	public getModelUrl({
		id,
		resolution,
		maxWidth,
		maxHeight,
		forceRemote,
	}: {
		id: PI.PhotoModel['id'];
		resolution: 'thumb' | 'low' | 'high';
		maxWidth?: number;
		maxHeight?: number;
		forceRemote?: boolean;
	}): Promise<string | File> {
		const photoModel = this.getById(id);

		if (!photoModel) {
			if (typeof id !== 'number') {
				return Promise.reject(
					new Error('Could not find local photo model'),
				);
			}

			// Fetch model data from server
			if (!AppStateModule.online) {
				return Promise.reject(
					new Error(ERRORS_OFFLINE),
				);
			}

			return this.fetchModel({
				id,
				methodOptions: {
					debug: {
						dialog: false,
					},
				},
			})
				.then(() => this.getModelUrl({
					id,
					resolution,
					maxWidth,
					maxHeight,
				}));
		}

		if (!forceRemote
			&& photoModel._localRef
			&& window.glPlatform === 'native'
		) {
			return Promise.resolve(
				`${photoModel._localRef}?width=${ConfigModule.photoPreviewSizeLocal}&height=${ConfigModule.photoPreviewSizeLocal}`,
			);
		}

		if (!forceRemote
			&& photoModel._localRef
			&& photoModel._type
			&& MIME_TYPES_BROWSER_SUPPORTED.includes(photoModel._type)
			&& !photoModel._vectorize
		) {
			const localFile = upload.getLocalFile(
				photoModel._localRef,
			);

			if (localFile) {
				return Promise.resolve(localFile);
			}
		}

		if (experiment.getFlagValue('flag_dynamic_photo_scaling')) {
			if (!ConfigModule.photoScalingBaseUrl) {
				throw new Error('Missing photo scaling base url');
			}

			// This logic is temporarily behind a feature flag, but should become the default going forward
			const dynamicPhotoUrl = `${ConfigModule.photoScalingBaseUrl}/${id}?read_token=${photoModel.token}`;
			const quality = resolution === 'high' ? 100 : 72;

			/*
			We temporary disable the dynamic size loading feature when the photo-scaling function is used
			This because we need to implement the logic to handle different sizes, to make sure it's not
			storing a previous size in memory and then loading that image when a bigger size is needed
			if (maxWidth && maxHeight) {
				return Promise.resolve(
					`${dynamicPhotoUrl}&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quality=${quality}`,
				);
			}
			*/
			if (resolution === 'thumb') {
				return Promise.resolve(
					`${dynamicPhotoUrl}&maxWidth=600&maxHeight=600&quality=${quality}`,
				);
			}
			if (resolution === 'low') {
				return Promise.resolve(
					`${dynamicPhotoUrl}&maxWidth=${ConfigModule.photoPreviewSize}&maxHeight=${ConfigModule.photoPreviewSize}&quality=${quality}`,
				);
			}

			if (photoModel.full_url) {
				return Promise.resolve(
					photoModel.full_url,
				);
			}
		}

		// This logic will eventually be deprecated once the feature flag has been approved
		if (resolution === 'thumb') {
			if (
				photoModel.thumb_url
				&& photoModel.thumb_url.length
			) {
				return Promise.resolve(photoModel.thumb_url);
			}
			if (
				photoModel.url
				&& photoModel.url.length
			) {
				return Promise.resolve(photoModel.url);
			}
			if (photoModel.full_url) {
				return Promise.resolve(photoModel.full_url);
			}

			return Promise.reject(
				new Error('Could not find photo model url'),
			);
		}

		if (
			photoModel.url
			&& resolution === 'low'
		) { // scaled version available
			return Promise.resolve(photoModel.url);
		}

		if (photoModel.url) {
			const fileExtension = urlTools.parse(photoModel.url).extension;
			if (fileExtension === 'svg') {
				// This file is either originally a SVG vector file, or a file that has been converted by us to a SVG file
				// We use the SVG file to render the image at high resolution
				return Promise.resolve(photoModel.url);
			}
		}

		if (photoModel.full_url) {
			const fullFileExtension = urlTools.parse(photoModel.full_url).extension;
			if (FILE_EXTENSIONS_SHOWN_AS_SVG.includes(fullFileExtension)
				&& !photoModel.url
			) {
				return Promise.reject(
					new Error(`Photo model with file extension ${fullFileExtension} should have url property set`),
				);
			}

			return Promise.resolve(photoModel.full_url);
		}

		return Promise.reject(new Error('Could not find photo model url'));
	}

	@Action({ rawError: true })
	public putModel({
		id,
		data,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		data: Partial<PI.PhotoModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<PI.PhotoModel> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}
		if (!data) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}
		if (
			data.userid
			&& data.userid != UserModule.id
		) {
			throw new Error('Invalid userid');
		}

		const model = this.getById(id);

		if (!model) {
			throw new Error('Model does not exist');
		}

		const currentModelData: PI.PhotoModel = JSON.parse(JSON.stringify(model));
		const newModelData = {
			...data,
			id,
		};
		this.updateModel(newModelData);

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `${this.modelUrl}/${id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: newModelData,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then(
				(response: AxiosResponse<PI.PhotoModel>) => {
					this._setModel(response.data);

					return response.data;
				},
				() => {
					this._setModel(currentModelData);

					return currentModelData;
				},
			);
	}

	@Action({ rawError: true })
	public removeModel(id: PI.PhotoModel['id']): Promise<void> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}
		if (!this.getById(id)) {
			throw new Error('Model not found');
		}

		return Promise.resolve(
			this._removeModel(id),
		);
	}

	@Action
	public reset({
		data,
	}: {
		data: PI.PhotoModel[];
	} = {
		data: [],
	}): Promise<void> {
		this._resetMetaData();
		this._resetCollection(data);
		return Promise.resolve();
	}

	@Action({ rawError: true })
	public retryModel(id: PI.PhotoModel['id']): Promise<void> {
		const photoModel = this.getById(id);
		if (!photoModel) {
			throw new Error('Could not find photo model');
		}

		if (!photoModel.full_url) {
			throw new Error('Missing full_url for photo model');
		}

		this.updateModel({
			id: photoModel.id,
			_error: undefined,
		});

		return new Promise((resolve, reject) => {
			queue.push(
				photoModel,
				(err) => {
					if (err) {
						reject(err);
					} else {
						resolve();
					}
				},
			);
		});
	}

	@Action({ rawError: true })
	public setTemporaryUploadUrl({
		id,
		url,
	}: {
		id: string | number;
		url: string;
	}): Promise<PI.PhotoModel> {
		let photoModel = this.getById(id);
		if (!photoModel) {
			throw new Error('Could not find photo model');
		}

		if (typeof photoModel.id === 'number') {
			// The model is already saved to the server
			return Promise.resolve(photoModel);
		}

		if (url
			&& photoModel.full_url !== url
		) {
			this.updateModel({
				id,
				full_url: url,
			});

			// For unknown reasons, the model in the photoModel variable is not updated and full_url is still set to null,
			// therefore we need to re-request the model from the store to get the updated model (which does have full_url set to the new value)
			photoModel = this.getById(id);
		}

		if (!photoModel) {
			throw new Error('Could not find photo model');
		}

		if (photoModel._processing) {
			return new Promise((resolve, reject) => {
				window.setTimeout(
					() => {
						this.setTemporaryUploadUrl({
							id,
							url,
						}).then(
							resolve,
							reject,
						);
					},
					200,
				);
			});
		}

		return new Promise((resolve, reject) => {
			if (!photoModel) {
				throw new Error('Could not find photo model');
			}

			queue.push(
				photoModel,
				(err, savedPhotoModel) => {
					if (err) {
						reject(err);
					} else {
						resolve(savedPhotoModel);
					}
				},
			);
		});
	}
}
