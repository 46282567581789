import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import SpinnerComponent from 'components/spinner';
import ProgressBar from 'components/progress-bar';
import ButtonComponent from 'components/button';
import Template from './template.vue';

@Component({
	name: 'UploadProgressView',
	components: {
		SpinnerComponent,
		ProgressBar,
		ButtonComponent,
	},
})
export default class UploadProgressView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Number,
	})
	public progressPercentage!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public submittedCount!: number;

	@Prop({
		default: () => [],
		type: Array,
	})
	public photos!: string[];

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly standalone!: boolean;

	protected get uploadComplete(): boolean {
		return this.progressPercentage == 100;
	}

	protected get headerText(): string {
		let text = 'views.uploadProgress.header';

		if (this.progressPercentage == 100) {
			text = 'views.uploadProgress.header_complete';
		} else if (this.progressPercentage >= 90) {
			text = 'views.uploadProgress.header_almost';
		}

		return this.$t(
			text,
			{ count: this.submittedCount },
		);
	}

	protected get progressText(): string {
		if (this.progressPercentage >= 5) {
			return this.$t('views.uploadProgress.uploading');
		}

		return this.$t('views.uploadProgress.upload_starting');
	}

	protected onCancelClick() {
		this.$emit('cancel');
	}

	protected onCloseClick() {
		this.$emit('close');
	}

	protected onStartEditingClick = () => {
		this.$emit('startEditing');
	};
}
