import './defines';
import ButtonComponent from 'components/button';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import {
	EditorPreviewPageObjectModels,
	FullOfferingModels,
	FullOfferingOptionIntersectModel,
	FullOfferingOptionVirtualIntersectModel,
	OfferingFrameModel,
	OfferingOptionsToolbarCurrencyModel,
	OfferingOptionsToolbarFullOfferingModel,
	OfferingOptionsToolbarFullOfferingModels,
	OfferingOptionsToolbarFullOfferingOptionModel,
	OfferingOptionsToolbarProductOptionModel,
	OfferingOptionsToolbarProductOptionValueModel,
	OfferingOptionsToolbarProductOptionValueModels,
} from 'interfaces/app';
import {
	Model2DModel,
	PageModel,
} from 'interfaces/database';
import { AppDataModule } from 'store';
import { mobile as mobileTools } from 'tools';
import EditorPreview2DView from 'views/editor-preview-2d';
import PriceView from 'views/price';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorFrameSelectorView',
	components: {
		ButtonComponent,
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		EditorPreview2DView,
		PriceView,
	},
})
export default class EditorFrameSelectorView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the full offering model active for the current project',
			required: true,
			schema: 'OfferingOptionsToolbarFullOfferingModel',
			type: Object,
		},
	)
	public readonly value!: OfferingOptionsToolbarFullOfferingModel;

	@Prop({
		default: undefined,
		description: "Defines the bleed margin for the current project to be used to show the product's preview",
		type: Number,
	})
	public readonly bleedMargin?: number;

	@Prop({
		required: true,
		schema: 'OfferingOptionsToolbarCurrencyModel',
		type: Object,
	})
	public readonly currencyModel!: OfferingOptionsToolbarCurrencyModel;

	@Prop({
		description: 'Defines the full offering models available for the current project',
		required: true,
		schema: 'OfferingOptionsToolbarFullOfferingModels',
		type: Array,
	})
	public readonly fullOfferingModels!: OfferingOptionsToolbarFullOfferingModels;

	@Prop({
		default: undefined,
		description: "Defines the page model to be used to show the product's preview",
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel?: PageModel;

	@Prop({
		default: undefined,
		description: "Defines the page objects to be used to show the product's preview",
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects?: EditorPreviewPageObjectModels;

	@Prop({
		default: undefined,
		description: "Defines the 2D preview model to be use to show the product's preview",
		schema: 'Model2DModel',
		type: Object,
	})
	public readonly previewModel?: Model2DModel;

	protected get frameOption(): OfferingOptionsToolbarFullOfferingOptionModel | undefined {
		return this.internalValue.options?.find((option) => option.tag === 'frame');
	}

	protected getOfferingOptionValueLabel(productOptionValue: OfferingOptionsToolbarProductOptionValueModel): string {
		if (productOptionValue.id) {
			return this.$t(
				`offeringOptionValues:${productOptionValue.id}`,
				productOptionValue.value,
			);
		}

		if (productOptionValue.offeringid) {
			return (
				this.fullOfferingModels.find((offeringModel) => offeringModel.id === productOptionValue.offeringid)?.size
				|| productOptionValue.value
			);
		}

		return productOptionValue.value;
	}

	private get isVirtualOffering(): boolean {
		if (this.internalValue) {
			return !!AppDataModule.findOffering({
				flexgroupid: this.internalValue.flexgroupid,
				groupid: this.internalValue.groupid,
				// instock: 1,
				virtual: 1,
			}).length;
		}

		return false;
	}

	protected get offeringFrameModel(): OfferingFrameModel | undefined {
		if (!this.internalValue) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(this.internalValue.id);
	}

	protected get offeringModelPrice(): number {
		if (!this.currencyModel) {
			return 0;
		}

		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: this.internalValue.id,
			currency: this.currencyModel.id,
		});

		return pricingModel?.price_base || 0;
	}

	protected get offeringOptionClasses(): Record<string, boolean> {
		const classes: Record<string, boolean> = {};
		const offeringsWithImages = this.productOptionValuesToShow.every((productOptionValue) => !!productOptionValue.image);

		if (this.frameOption) {
			classes[`offering-option-${this.frameOption.name}`] = true;
		}
		if (offeringsWithImages) {
			classes['offering-options-with-images'] = true;
		}

		return classes;
	}

	protected get offeringOptionStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
		let offeringOptionButtonToggleElement: HTMLDivElement | null = null;

		if (this.frameOption) {
			offeringOptionButtonToggleElement = this.$el.querySelector<HTMLDivElement>(`.offering-option-value[data-offering-option-name="${this.frameOption.name}"] .button-toggle-component`);
		}

		if (offeringOptionButtonToggleElement) {
			const offeringOptionButtonToggleElementRect = offeringOptionButtonToggleElement.getBoundingClientRect();
			styles['--offering-option-value-height'] = `${offeringOptionButtonToggleElementRect.width}px`;
		}

		return styles;
	}

	protected get productOptions(): (FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel)[] {
		if (this.internalValue) {
			return AppDataModule.getFullOfferingOptionIntersectModels({
				offeringModels: this.fullOfferingModels as FullOfferingModels,
				variantid: (
					this.isVirtualOffering
						? this.internalValue.variantid
						: undefined
				),
			});
		}

		return [];
	}

	protected get productOptionValuesToShow(): OfferingOptionsToolbarProductOptionValueModels {
		if (!this.frameOption) {
			return [];
		}

		const { frameOption } = this;
		const productOption = this.productOptions
			.find((productOptionItem) => productOptionItem.id === frameOption.id) as OfferingOptionsToolbarProductOptionModel;

		return AppDataModule.getFullOfferingOptionValueIntersectModels(
			this.productOptions,
			productOption,
			this.internalValue?.id,
			(
				_productOptionValue,
				productOptionToFilter,
			) => productOptionToFilter.tag === 'frame',
		);
	}

	protected get showPricing(): boolean {
		return !!this.internalValue.showPricing;
	}

	private internalValue: OfferingOptionsToolbarFullOfferingModel = {} as OfferingOptionsToolbarFullOfferingModel;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.internalValue.id !== this.value.id) {
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = {
				...this.value,
			};
		});
	}

	protected onOfferingOptionChange(offerintOptionValue: OfferingOptionsToolbarProductOptionValueModel['id'] | OfferingOptionsToolbarProductOptionValueModel['offeringid']): void {
		if (this.frameOption) {
			const { frameOption } = this;
			const newOfferingOption = this.productOptions
				.find((productOption) => {
					if (frameOption.id) {
						return productOption.id === frameOption.id;
					}
					if ('variantid' in productOption) {
						return productOption.variantid === frameOption.variantid;
					}

					return false;
				}) as OfferingOptionsToolbarProductOptionModel;
			const newOfferingOptionValue = newOfferingOption.items
				.find((item) => {
					if (item.id) {
						return item.id === offerintOptionValue;
					}

					return item.offeringid === offerintOptionValue;
				}) as OfferingOptionsToolbarProductOptionValueModel;

			const matchingOfferingModel = AppDataModule.getMatchingFullOfferingModel({
				offeringOption: newOfferingOption,
				offeringOptionValue: newOfferingOptionValue,
				offeringModel: this.internalValue,
				offeringModels: this.fullOfferingModels,
			});

			if (matchingOfferingModel) {
				this.internalValue = matchingOfferingModel;
			}
		}
	}

	protected onApplyClick(): void {
		this.$emit('apply');
		this.$emit('close');
	}
}
