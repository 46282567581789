import _ from 'underscore';

const getCookie = (name: string) => {
	let x;
	const ARRcookies = document.cookie.split(';');
	const ck = _.find(
		ARRcookies,
		(cookie) => {
			x = cookie.substr(
				0,
				cookie.indexOf('='),
			);
			x = x.replace(
				/^\s+|\s+$/g,
				'',
			);

			return x == name;
		},
	);

	if (ck) {
		const cookievalue = ck.substr(ck.indexOf('=') + 1);
		return unescape(cookievalue);
	}
	return false;
};

export default getCookie;
