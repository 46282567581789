import shepherd from 'shepherd.js';
import { useShepherd } from 'vue-shepherd';
import analytics from 'controllers/analytics';
import * as DB from 'interfaces/database';
import { OfferingGroups } from 'settings/offerings';
import {
	AppStateModule,
	ConfigModule,
	ProductStateModule,
	UserDataModule,
} from 'store';
import EditorView from 'views/editor/template.vue';
import AppHeaderView from 'views/header';
import SubnavbarView from 'views/subnavbar';
import EditorToolbarView from 'views/toolbar-editor/template.vue';
import {
	Component,
	Vue,
	Watch,
} from 'vue-property-decorator';

@Component({
	components: {
		AppHeaderView,
		EditorView,
		EditorToolbarView,
		SubnavbarView,
	},
})
export default class EditorModuleView extends Vue {
	private isMobile = true;

	private mediaQueryList = window.matchMedia('(max-width: 767px)');

	private tour: shepherd.Tour | undefined;

	get hasNext() {
		return this.productModel && OfferingGroups(
			this.productModel.group,
			['BasicProducts'],
		);
	}

	protected get headerCollapsed(): boolean {
		return AppStateModule.headerCollapsed;
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get pageIndex() {
		return parseInt(
			this.$route.params.pagenr,
			10,
		);
	}

	get pageModel() {
		return ProductStateModule.getPageByNumber(this.pageIndex);
	}

	get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get showPricing(): boolean {
		return Boolean(this.offeringModel?.showPricing);
	}

	get standalone() {
		if (!this.offeringModel) {
			return false;
		}

		return OfferingGroups(
			this.offeringModel.groupid,
			['BasicProducts'],
		);
	}

	created() {
		this.moveSupportButton();
	}

	mounted() {
		analytics.trackPageView(
			'editor',
			'Page editor',
		);

		analytics.trackEvent(
			'Edit page',
			{},
			{
				amplitude: true,
				gtm: false,
				moengage: true,
				segment: false,
			},
		);

		this.isMobile = this.mediaQueryList.matches;

		if (this.mediaQueryList.addEventListener) {
			this.mediaQueryList.addEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.addListener(this.checkMobile);
		}

		const tourHandle: DB.ProductTours = 'editor_module';

		if (
			ConfigModule[`tours.${tourHandle}.show`]
			&& !UserDataModule.hasCompletedTour(tourHandle)
		) {
			this.$nextTick(() => {
				this.tour = useShepherd({
					tourName: tourHandle,
					useModalOverlay: true,
				});

				if (!this.tour) {
					throw new Error('Could not create product tour');
				}

				this.tour.once(
					'complete',
					() => {
						UserDataModule.addCompletedTour(tourHandle);
					},
				);

				// Add first step in tour
				const firstStepName = 'pagePreview';
				const firstStepEl = document.getElementById('tourHandleInteractionMap');
				if (firstStepEl) {
					this.tour.addStep({
						classes: 'myShepherdClass',
						attachTo: {
							element: firstStepEl,
							on: 'top',
						},
						title: this.$t(`productTours:${tourHandle}.${firstStepName}.title`),
						text: this.$t(`productTours:${tourHandle}.${firstStepName}.body`),
						buttons: [
							{
								text: this.$t(`productTours:${tourHandle}.${firstStepName}.buttonOk`),
								action: this.tour.next,
							},
						],
					});
				}

				// Add second step in tour
				const secondStepName = 'menuButton';
				const secondStepEl = document.getElementById('tourHandleMenuButton');
				if (secondStepEl) {
					this.tour.addStep({
						classes: 'myShepherdClass',
						attachTo: {
							element: secondStepEl,
							on: 'top',
						},
						title: this.$t(`productTours:${tourHandle}.${secondStepName}.title`),
						text: this.$t(`productTours:${tourHandle}.${secondStepName}.body`),
						buttons: [
							{
								text: this.$t(`productTours:${tourHandle}.${secondStepName}.buttonBack`),
								action: this.tour.back,
								secondary: true,
							},
							{
								text: this.$t(`productTours:${tourHandle}.${secondStepName}.buttonOk`),
								action: this.tour.next,
							},
						],
					});
				}

				// Add third step in tour
				const thirdStepName = 'undoButton';
				const thirdStepEl = document.getElementById('tourHandleUndoButton');
				if (thirdStepEl) {
					this.tour.addStep({
						classes: 'myShepherdClass',
						attachTo: {
							element: thirdStepEl,
							on: 'top',
						},
						title: this.$t(`productTours:${tourHandle}.${thirdStepName}.title`),
						text: this.$t(`productTours:${tourHandle}.${thirdStepName}.body`),
						buttons: [
							{
								text: this.$t(`productTours:${tourHandle}.${thirdStepName}.buttonBack`),
								action: this.tour.back,
								secondary: true,
							},
							{
								text: this.$t(`productTours:${tourHandle}.${thirdStepName}.buttonOk`),
								action: this.tour.next,
							},
						],
					});
				}

				// Add fourth step in tour
				if (!this.standalone) {
					const fourthStepName = 'closeButton';
					const fourthStepEl = document.getElementById('tourHandleBackButton');
					if (fourthStepEl) {
						this.tour.addStep({
							classes: 'myShepherdClass',
							attachTo: {
								element: fourthStepEl,
								on: 'top',
							},
							title: this.$t(`productTours:${tourHandle}.${fourthStepName}.title`),
							text: this.$t(`productTours:${tourHandle}.${fourthStepName}.body`),
							buttons: [
								{
									text: this.$t(`productTours:${tourHandle}.${fourthStepName}.buttonBack`),
									action: this.tour.back,
									secondary: true,
								},
								{
									text: this.$t(`productTours:${tourHandle}.${fourthStepName}.buttonOk`),
									action: this.tour.next,
								},
							],
						});
					}
				} else {
					const fourthStepName = 'proceedButton';
					const fourthStepEl = this.isMobile
						? document.getElementById('tourHandleProceedButtonMobile')
						: document.getElementById('tourHandleProceedButtonDesktop');
					if (fourthStepEl) {
						this.tour.addStep({
							beforeShowPromise: () => new Promise((resolve) => {
								// The user could have changed its viewport (i.e. by rotating the device)
								// so we re-evaluate the DOM element to attach this step to
								const step = this.tour?.getCurrentStep();
								if (step) {
									const el = this.isMobile
										? document.getElementById('tourHandleProceedButtonMobile')
										: document.getElementById('tourHandleProceedButtonDesktop');
									if (el) {
										step.updateStepOptions({
											attachTo: {
												element: el,
												on: 'top',
											},
										});
									}
								}

								resolve(undefined);
							}),
							classes: 'myShepherdClass',
							attachTo: {
								element: fourthStepEl,
								on: 'top',
							},
							title: this.$t(`productTours:${tourHandle}.${fourthStepName}.title`),
							text: this.$t(`productTours:${tourHandle}.${fourthStepName}.body`),
							buttons: [
								{
									text: this.$t(`productTours:${tourHandle}.${fourthStepName}.buttonBack`),
									action: this.tour.back,
									secondary: true,
								},
								{
									text: this.$t(`productTours:${tourHandle}.${fourthStepName}.buttonOk`),
									action: this.tour.next,
								},
							],
						});
					}
				}

				this.tour.start();
			});
		}
	}

	beforeDestroy() {
		AppStateModule.resetSupportButtonPosition();

		if (this.mediaQueryList.removeEventListener) {
			this.mediaQueryList.removeEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.removeListener(this.checkMobile);
		}

		if (this.tour) {
			this.tour.cancel();
		}
	}

	checkMobile(event: MediaQueryListEvent) {
		if (event.matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	@Watch('isMobile')
	moveSupportButton() {
		const bottom = this.isMobile ? 70 : 100;
		AppStateModule.changeSupportButtonPosition(bottom);
	}

	protected onHeaderCollapse(value: boolean): void {
		AppStateModule.setHeaderCollapse(value);
	}
}
