import './defines';
import {
	TooltipPosition,
	TooltipPositionSide,
} from 'interfaces/app';
import { ServiceEvent } from 'services/service-event';
import {
	dom as domUtils,
	functions as functionsUtils,
	vue as vueUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import { VueConstructor } from 'vue';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'TooltipComponent',
})
export default class TooltipComponent<BodyComponent extends VueConstructor<Vue>> extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the anchor element of the tooltip',
		required: true,
		type: [HTMLElement, Object],
	})
	public readonly anchor!: HTMLElement | Vue;

	@Prop({
		default: undefined,
		description: 'Defines the function that will be called before the tooltip is closed with the ability to prevent the close action',
		type: Function,
	})
	public readonly beforeClose?: ServiceEventHandler<any>;

	@Prop({
		description: "Defines the body component's options of the tooltip",
		required: true,
		type: Object,
	})
	public readonly body!: TooltipServiceOptionsBody<BodyComponent>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS classes for the tooltip body',
		type: [Array, Object],
	})
	public readonly bodyClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS styles for the tooltip body',
		type: Object,
	})
	public readonly bodyStyles!: Partial<CSSStyleDeclaration>;

	@Prop({
		default: 8,
		description: 'Defines the border radius of the tooltip',
		type: Number,
	})
	public readonly borderRadius?: number;

	@Prop({
		default: false,
		description: 'Indicates if the tooltip should be closed when the user clicks outside of it',
		type: Boolean,
	})
	public readonly closeOnModalClick!: boolean;

	@Prop({
		description: 'Defines the distance between the anchor and the tooltip',
		required: true,
		type: [Number, String],
	})
	public readonly distance!: number | string;

	@Prop({
		default: true,
		description: 'Indicates if the tooltip have a close button',
		type: Boolean,
	})
	public readonly hasCloseButton!: boolean;

	@Prop({
		acceptedValues: [
			'bottom center',
			'bottom left',
			'bottom right',
			'right',
			'top center',
			'top left',
			'top right',
			'left',
		],
		default: 'bottom center',
		schema: 'TooltipPosition',
		type: String,
	})
	public readonly initialPosition!: TooltipPosition;

	@Prop({
		default: true,
		description: 'Indicates if the tooltip should shown as a modal',
		type: Boolean,
	})
	public readonly isModal!: boolean;

	@Prop({
		default: () => ({}),
		description: 'Defines the listeners for the tooltip body',
		type: Object,
	})
	public readonly listeners!: Record<string, ServiceEventHandler<any>[] | ServiceEventHandler<any>>;

	@Prop({
		default: false,
		description: "Indicates if the tooltip's top arrow should not be shown",
		type: Boolean,
	})
	public readonly noArrow!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the tooltip should not have any styles',
		type: Boolean,
	})
	public readonly noStyles!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the title of the tooltip',
		type: String,
	})
	public readonly title?: string;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS classes for the tooltip element',
		type: [Array, Object],
	})
	public readonly tooltipClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		description: 'Defines the CSS styles for the tooltip element',
		type: Object,
	})
	public readonly tooltipStyles!: Partial<CSSStyleDeclaration>;

	protected get bodyComponentInstanceAPI(): NonVuePublicProps<InstanceType<BodyComponent>> | undefined {
		if (this.$slots.default?.[0].componentInstance) {
			return vueUtils.getInstanceAPI(this.$slots.default[0].componentInstance as InstanceType<BodyComponent>);
		}

		return undefined;
	}

	protected get computedAnchor(): HTMLElement {
		if (this.anchor instanceof Vue) {
			return (this.anchor as Vue).$el as HTMLElement;
		}

		return this.anchor as HTMLElement;
	}

	protected get computedTooltipContainerClasses(): Record<string, boolean> {
		return {
			'tooltip-modal': this.isModal,
			[this.internalTheme]: true,
		};
	}

	protected get computedTooltipHeaderClasses(): Record<string, boolean> {
		return {
			'tooltip-header-has-title': !!this.title,
		};
	}

	protected get computedTooltipWrapperClasses(): Record<string, boolean> {
		const classes: Record<string, boolean> = {};

		if (Array.isArray(this.tooltipClasses)) {
			// eslint-disable-next-line no-restricted-syntax
			for (const className of this.tooltipClasses) {
				classes[className] = true;
			}
		} else {
			Object.assign(
				classes,
				this.tooltipClasses,
			);
		}

		if (!this.noArrow) {
			if (this.tooltipPositionSide === 'bottom') {
				classes['tooltip-wrapper-arrow-top'] = true;
			} else if (this.tooltipPositionSide === 'right') {
				classes['tooltip-wrapper-arrow-left'] = true;
			} else if (this.tooltipPositionSide === 'top') {
				classes['tooltip-wrapper-arrow-bottom'] = true;
			} else if (this.tooltipPositionSide === 'left') {
				classes['tooltip-wrapper-arrow-right'] = true;
			}
		}

		return {
			'tooltip-wrapper-no-arrow': this.noArrow,
			'tooltip-wrapper-no-styles': this.noStyles,
			...classes,
		};
	}

	protected get computedTooltipWrapperStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			!this.noStyles
			&& this.borderRadius
		) {
			styles.borderRadius = `${this.borderRadius}px`;
		}

		return {
			...styles,
			...this.tooltipStyles,
		};
	}

	protected get hasHeader(): boolean {
		return !!(
			this.title
			|| this.hasCloseButton
		);
	}

	@Ref('tooltipBody')
	private tooltipBodyElement!: HTMLDivElement;

	@Ref('tooltipPositioner')
	private tooltipPositionerElement!: HTMLDivElement;

	@Ref('tooltipWrapper')
	private tooltipWrapperElement!: HTMLDivElement;

	private checkVisibilityDebounce = functionsUtils.debounce(
		() => this.checkVisibility(),
		100,
	);

	private isCalculatingPosition?: boolean;

	private mutationObserver?: MutationObserver;

	private resizeObserver?: ResizeObserver;

	protected tooltipPosition: Pick<CSSStyleDeclaration, 'left' | 'top' | 'visibility'> = {
		left: '0px',
		top: '0px',
		visibility: 'hidden',
	};

	private tooltipPositionSide: TooltipPositionSide | null = null;

	protected beforeDestroy(): void {
		document.body.classList.remove('tooltip-modal-no-scroll');
		document.documentElement.classList.remove('tooltip-modal-no-scroll');
		this.resizeObserver?.disconnect();
		this.mutationObserver?.disconnect();

		const bodyComponent = this.$slots.default?.[0].componentInstance;

		if (bodyComponent) {
			bodyComponent.$destroy();
		}
	}

	protected created(): void {
		this.resizeObserver = new ResizeObserver(() => this.calculatePosition());
		this.mutationObserver = new MutationObserver(() => this.calculatePosition());
		this.resizeObserver.observe(this.computedAnchor);
		this.addObserversToParent(this.computedAnchor);
	}

	protected mounted(): void {
		this.resizeObserver?.observe(this.tooltipWrapperElement);
		this.calculatePosition();
	}

	@Watch(
		'initialPosition',
		{
			immediate: true,
		},
	)
	protected onInitialPositionChange(): void {
		if (this.initialPosition.includes('bottom')) {
			this.tooltipPositionSide = 'bottom';
		} else if (this.initialPosition === 'right') {
			this.tooltipPositionSide = 'right';
		} else if (this.initialPosition.includes('top')) {
			this.tooltipPositionSide = 'top';
		} else if (this.initialPosition === 'left') {
			this.tooltipPositionSide = 'left';
		}
	}

	@Watch(
		'isModal',
		{
			immediate: true,
		},
	)
	protected onIsModalChange(): void {
		if (this.isModal) {
			document.body.classList.add('tooltip-modal-no-scroll');
			document.documentElement.classList.add('tooltip-modal-no-scroll');
		} else {
			document.documentElement.classList.remove('tooltip-modal-no-scroll');
			document.body.classList.remove('tooltip-modal-no-scroll');
		}
	}

	private addObserversToParent(element: HTMLElement): void {
		this.resizeObserver?.observe(element);
		this.mutationObserver?.observe(
			element,
			{
				attributes: true,
				childList: true,
				subtree: true,
			},
		);

		if (
			element !== window.document.body
			&& element.parentElement
		) {
			this.addObserversToParent(element.parentElement);
		}
	}

	@Public()
	public bodyComponent(): NonVuePublicProps<InstanceType<BodyComponent>> | undefined {
		return this.bodyComponentInstanceAPI;
	}

	@Public()
	public bodyElement(): HTMLDivElement {
		return this.tooltipBodyElement;
	}

	@Public()
	public calculatePosition(
		side: TooltipPositionSide = this.tooltipPositionSide || 'bottom',
		skipCheckVisibility = false,
	): Promise<void> {
		if (this.isCalculatingPosition) {
			return Promise.resolve();
		}

		this.isCalculatingPosition = true;

		if (this.$el) {
			return new Promise((resolve, reject) => {
				requestAnimationFrame(() => {
					try {
						if (
							this.computedAnchor
							&& this.tooltipWrapperElement
							&& this.tooltipPositionerElement
						) {
							const anchorRect = this.computedAnchor.getBoundingClientRect();
							const tooltipRect = this.tooltipWrapperElement.getBoundingClientRect();
							const tooltipComputedStyles = getComputedStyle(this.tooltipWrapperElement);
							let tooltipWrapperElementWidth = this.tooltipWrapperElement.clientWidth;

							if (tooltipComputedStyles.marginLeft) {
								tooltipWrapperElementWidth += parseInt(
									tooltipComputedStyles.marginLeft,
									10,
								);
							}
							if (tooltipComputedStyles.marginRight) {
								tooltipWrapperElementWidth += parseInt(
									tooltipComputedStyles.marginRight,
									10,
								);
							}

							let leftPosition = 0;
							let topPosition = 0;

							if (side === 'bottom') {
								if (this.initialPosition.includes('center')) {
									leftPosition = Math.round(anchorRect.left + (anchorRect.width / 2) - (tooltipWrapperElementWidth / 2));
								} else if (this.initialPosition.includes('left')) {
									leftPosition = Math.round(anchorRect.left);
								} else if (this.initialPosition.includes('right')) {
									leftPosition = Math.round(anchorRect.left + anchorRect.width - tooltipWrapperElementWidth);
								}

								if (typeof this.distance === 'number') {
									topPosition = Math.round(anchorRect.top + anchorRect.height + this.distance);
								} else if (this.distance.endsWith('%')) {
									const distance = parseInt(
										this.distance,
										10,
									);
									topPosition = Math.round(anchorRect.top + (anchorRect.height * (distance / 100)) - (tooltipRect.height / 2));
								}
							} else if (side === 'right') {
								topPosition = Math.round(anchorRect.top + (anchorRect.height / 2) - (tooltipRect.height / 2));

								if (typeof this.distance === 'number') {
									leftPosition = Math.round(anchorRect.left + anchorRect.width + this.distance);
								} else if (this.distance.endsWith('%')) {
									const distance = parseInt(
										this.distance,
										10,
									);
									leftPosition = Math.round(anchorRect.left + (anchorRect.width * (distance / 100)) - (tooltipRect.width / 2));
								}
							} else if (side === 'top') {
								if (this.initialPosition.includes('center')) {
									leftPosition = Math.round(anchorRect.left + (anchorRect.width / 2) - (tooltipWrapperElementWidth / 2));
								} else if (this.initialPosition.includes('left')) {
									leftPosition = Math.round(anchorRect.left);
								} else if (this.initialPosition.includes('right')) {
									leftPosition = Math.round(anchorRect.left + anchorRect.width - tooltipWrapperElementWidth);
								}

								if (typeof this.distance === 'number') {
									topPosition = Math.round(anchorRect.top - this.distance - tooltipRect.height);
								} else if (this.distance.endsWith('%')) {
									const distance = parseInt(
										this.distance,
										10,
									);
									topPosition = Math.round(anchorRect.top - (anchorRect.height * (distance / 100)) - (tooltipRect.height / 2));
								}
							} else if (side === 'left') {
								topPosition = Math.round(anchorRect.top + (anchorRect.height / 2) - (tooltipRect.height / 2));

								if (typeof this.distance === 'number') {
									leftPosition = Math.round(anchorRect.left - this.distance - tooltipRect.width);
								} else if (this.distance.endsWith('%')) {
									const distance = parseInt(
										this.distance,
										10,
									);
									leftPosition = Math.round(anchorRect.left - (anchorRect.width * (distance / 100)) - (tooltipRect.width / 2));
								}
							}

							this.tooltipPositionSide = side;
							const newLeftPosition = `${leftPosition}px`;
							const newTopPosition = `${topPosition}px`;

							if (
								newLeftPosition !== this.tooltipPosition.left
								|| newTopPosition !== this.tooltipPosition.top
							) {
								this.tooltipPosition.left = `${Math.max(
									0,
									leftPosition,
								)}px`;
								this.tooltipPosition.top = `${Math.max(
									0,
									topPosition,
								)}px`;
							}

							if (
								this.initialPosition.includes(side)
								&& !skipCheckVisibility
							) {
								requestAnimationFrame(() => this.checkVisibilityDebounce());
							}

							this.isCalculatingPosition = false;
							requestAnimationFrame(() => resolve());
						}
					} catch (error) {
						this.isCalculatingPosition = false;
						reject(error);
					}
				});
			});
		}

		this.isCalculatingPosition = false;

		return Promise.resolve();
	}

	private async checkVisibility(
		callback?: () => void,
		side: TooltipPositionSide = 'bottom',
	): Promise<void> {
		if (this.tooltipPositionerElement) {
			if (side !== 'bottom') {
				if (!this.$parent?.$tooltipInstance) {
					await this.calculatePosition(side);
				} else {
					await this.calculatePosition(
						'bottom',
						true,
					);
				}
			}

			return new Promise((resolve, reject) => {
				requestAnimationFrame(async () => {
					try {
						const isFullyVisibleResult = domUtils.isFullyVisible(this.tooltipPositionerElement);

						if (!isFullyVisibleResult.isFullyVisible) {
							let sideToCheck: TooltipPositionSide;

							if (side === 'bottom') {
								sideToCheck = 'right';
							} else if (side === 'right') {
								sideToCheck = 'top';
							} else if (side === 'top') {
								sideToCheck = 'left';
							} else {
								if (this.$parent?.$tooltipInstance) {
									const fixVisibilityCallback = await this.$parent.$tooltipInstance.fixVisibility(
										this.$el as HTMLElement,
										'bottom',
									);
									fixVisibilityCallback();
								}

								callback?.();
								return resolve();
							}

							const fixVisibilityCallback = await this.fixVisibility(
								this.$el as HTMLElement,
								sideToCheck,
							);

							this.checkVisibility(
								fixVisibilityCallback,
								sideToCheck,
							);
						} else if (callback) {
							callback();
						} else {
							this.tooltipPosition.visibility = '';
						}

						return resolve();
					} catch (error) {
						return reject(error);
					}
				});
			});
		}

		return Promise.resolve();
	}

	@Public()
	public async fixVisibility(
		target: HTMLElement,
		side: TooltipPositionSide,
	): Promise<() => void> {
		this.tooltipPosition.visibility = 'hidden';

		if (this.$parent?.$tooltipInstance) {
			const parentTooltipInstance = this.$parent.$tooltipInstance;
			const fixVisibilityCallback = await parentTooltipInstance.fixVisibility(
				target,
				side,
			);

			return () => {
				this.tooltipPosition.visibility = '';
				fixVisibilityCallback();
			};
		}

		await this.calculatePosition(side);

		return () => {
			this.tooltipPosition.visibility = '';
		};
	}

	@Public()
	public onCloseClick(
		preventDestroy?: boolean,
		originalEvent?: Event,
	): void {
		const event = new ServiceEvent({
			type: 'close',
			payload: originalEvent,
		});

		if (this.beforeClose) {
			this.beforeClose(event);
		}

		if (!event.defaultPrevented) {
			this.$emit('close');
		} else if (
			event.defaultPrevented
			&& preventDestroy
		) {
			this._destroyPrevented = true;
		}
	}

	protected onTooltipModalClick(event: TouchEvent): void {
		const target = event.target as HTMLElement;

		if (
			!this._isDestroyed
			&& (
				(
					this.hasCloseButton
					|| this.closeOnModalClick
				)
				&& target !== this.tooltipWrapperElement
				&& !this.tooltipWrapperElement.contains(target)
			)
			&& !event.defaultPrevented
		) {
			if (this.isModal) {
				event.preventDefault();
			}

			const closeEvent = new ServiceEvent({
				type: 'close',
				payload: false,
			});
			this.$emit(
				'close',
				closeEvent,
			);
		}
	}

	protected onTooltipPositionerClick(event: MouseEvent): void {
		event.preventDefault();
	}
}
