import TooltipComponent from 'components/tooltip';
import {
	object as objectUtils,
	service as serviceUtils,
	vue as vueUtils,
} from 'utils';
import Vue, {
	VueConstructor,
	type VueConstructorExtended,
	VueExtended,
} from 'vue';

export { ServiceEvent } from 'services/service-event';

/* eslint-disable @typescript-eslint/indent */

export function openTooltip<BodyComponent extends VueConstructor<Vue>>(
	options: ServiceOptions<
		TooltipComponent<BodyComponent>
	>,
): ServiceOpenReturn<
	TooltipComponent<BodyComponent>
> {
	type TooltipComponentType = TooltipComponent<BodyComponent>;
	type TooltipComponentTypeVue = VueExtended<TooltipComponentType>;
	const defaultOptions: Omit<
		ServiceOptions<TooltipComponentType>,
		'anchor' | 'distance'
	> = {
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: TooltipComponentTypeVue;
	let fromCloseListener = false;
	const tooltipAPI: ServiceOpenReturn<TooltipComponentTypeVue> = {
		api: {} as any,
		close(event?: Event) {
			if (!fromCloseListener) {
				instance.onCloseClick(
					true,
					event,
				);
			} else {
				fromCloseListener = false;
			}

			tooltipAPI.destroy(false);
		},
		destroy(force = true) {
			instance.$destroy(force);

			if (instance._isDestroyed) {
				instance.$el.remove();
			}
		},
		isOpen: false,
	};
	Object.defineProperty(
		tooltipAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return !instance._isDestroyed;
			},
		},
	);

	const finalOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeListener = () => {
		fromCloseListener = true;
		tooltipAPI.close();
	};
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);

	finalOptions.listeners = listeners;

	instance = new TooltipComponent({
		parent: options.parent || window.App.router,
		_parentListeners: listeners,
		propsData: finalOptions,
	});

	tooltipAPI.api = vueUtils.getInstanceAPI(instance);

	requestAnimationFrame(() => {
		if (!finalOptions.isModal) {
			const onWindowClick = (event: Event) => {
				const target = event.target as HTMLElement;

				if (
					target !== instance.$el
					&& !instance.$el.contains(target)
					&& !event.defaultPrevented
				) {
					if (instance.anchor) {
						let { anchor } = instance;

						if ('$el' in anchor) {
							anchor = anchor.$el as HTMLElement;
						}

						if (
							anchor !== target
							&& !anchor.contains(target)
						) {
							tooltipAPI.close(event);
						}
					} else {
						tooltipAPI.close(event);
					}
				}
			};
			window.document.body.addEventListener(
				'click',
				onWindowClick,
			);
			instance.onDestroy(() => {
				window.document.body.removeEventListener(
					'click',
					onWindowClick,
				);
			});
		}
	});

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		vueUtils.createInstanceSlot(
			options.body.component as unknown as VueConstructorExtended,
			instance as Vue,
			'default',
			{
				props: options.body.props,
				listeners: options.body.listeners,
			},
		);
	}

	const instanceElement = document.createElement('div');
	const dialogsElement = document.getElementById('dialogs');
	const webAppElement = document.getElementById('webapp');

	if (dialogsElement?.parentElement) {
		dialogsElement.parentElement.insertBefore(
			instanceElement,
			dialogsElement,
		);
	} else if (webAppElement) {
		webAppElement.append(instanceElement);
	}

	instance.$mount(instanceElement);

	return tooltipAPI;
}

/* eslint-enable @typescript-eslint/indent */

const TooltipService = {
	openTooltip,
};

export default TooltipService;
