import './defines';
import * as faceapi from 'face-api.js';
import {
	AppStateModule,
	ProductStateModule,
} from 'store';
import {
	Component,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class FaceDetectionView extends Vue.extend(Template) {
	protected showLoader = true;

	private get detections() {
		return AppStateModule.faceDetection.detections;
	}

	private get height() {
		return AppStateModule.faceDetection.height;
	}

	private get image() {
		return AppStateModule.faceDetection.image;
	}

	private get width() {
		return AppStateModule.faceDetection.width;
	}

	protected skip() {
		ProductStateModule.changeProductSettings({
			faceDetect: false,
		});

		this.$emit('closeDialog');
	}

	@Watch('image')
	@Watch('detections')
	protected draw() {
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		const context = canvas.getContext('2d');

		if (context && this.image) {
			this.showLoader = false;

			context.drawImage(
				this.image,
				0,
				0,
				this.width,
				this.height,
			);

			if (this.detections.length) {
				faceapi.draw.drawDetections(
					canvas,
					this.detections,
				);
			}
		}
	}

	@Watch('width')
	@Watch('height')
	protected resizeCanvas() {
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		faceapi.matchDimensions(
			canvas,
			{
				width: this.width,
				height: this.height,
			},
		);
	}
}
