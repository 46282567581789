import * as PI from 'interfaces/project';
import { OfferingGroups } from 'settings/offerings';
import {
	PhotosModule,
	ProductStateModule,
} from 'store';
import _ from 'underscore';

export default function deleteObject(
	objectModel: PI.PageObjectModel,
	opts?: {
		propagate?: boolean;
		corrupt?: boolean;
	},
) {
	const defaults = {
		propagate: true,
		corrupt: false,
	};
	const options = _.extend(
		defaults,
		opts,
	);

	if (
		!options.propagate
		// Only propagate to page/photo level when this is a photo object
		|| !objectModel.photoid
	) {
		// Just remove the object from the page
		return ProductStateModule.removePageObject(objectModel.id);
	}

	const offeringModel = ProductStateModule.getOffering;

	// Never propogate to page/photo level when it's not a prints product or when it's a photo sheet
	// When the (prints) product has a fixed number of pages, don't propagate to page/photo
	if (
		!offeringModel
		|| !OfferingGroups(
			offeringModel.groupid,
			['PrintTypes'],
		)
		|| OfferingGroups(
			offeringModel.groupid,
			['PhotoSheets'],
		)
		|| offeringModel.minprintpages === offeringModel.maxpages
	) {
		// Just remove the object from the page
		return ProductStateModule.removePageObject(objectModel.id);
	}

	// Count number of photo objects in product that reference this photoid
	const photoId = objectModel.photoid;
	const relatedPhotoObjects = ProductStateModule.getObjects.filter(
		(pageObjectModel) => pageObjectModel.photoid === photoId,
	);
	const matches = relatedPhotoObjects.length;

	if (matches <= 1) {
		// There are no more photo objects referencing the photo, so we can also remove the photo from the product
		const photoModel = PhotosModule.getById(photoId);

		if (photoModel) {
			return ProductStateModule.removePhoto(photoModel.id);
		}

		if (!options.corrupt) {
			// Object not yet marked as corrupt, so throw the error for logging
			if (typeof window.glBugsnagClient !== 'undefined') {
				const err = new Error('Could not find photomodel to remove from collection');
				window.glBugsnagClient.notify(
					err,
					(event) => { event.severity = 'warning'; },
				);
			}
		}
	}

	// As there are still photo objects connected to that photo, we will keep it in the product collection,
	// and just remove the object from the page
	return ProductStateModule.removePageObject(
		objectModel.id,
	);
}
