import * as DB from 'interfaces/database';

const userModel: DB.UserModel = {
	id: null,
	affiliateid: 0,
	loyaltygroupid: null,
	first_name: '',
	last_name: '',
	language: null,
	currency: null,
	timestamp: 0,
	email: '',
	gender: '',
	session_nr: 0,
	session_time: 0,
	temporary: null,
	countryid: null,
	unsubscribed: null,
	deleted: null,
};

export default {
	userModel,
};
