import {
	Vue,
	Component,
	Prop,
	Provide,
	Watch,
} from 'vue-property-decorator';
import { OfferingFrameModel } from 'interfaces/app';
import * as PI from 'interfaces/project';
import { OfferingGroups } from 'settings/offerings';
import {
	COVER_BACK, COVER_BACK_INSIDE, COVER_FRONT, COVER_FRONT_INSIDE,
} from 'settings/values';
import { AppDataModule, ProductStateModule } from 'store/index';
import DrawView from 'views/draw';

@Component({
	components: {
		DrawView,
	},
})
export default class PageOverlayView extends Vue {
	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: false, type: Boolean })
	readonly showBleed!: boolean;

	// Allows any child component to `inject: ['canvas']` and have access to it.
	// This is the CanvasRenderingContext that children will draw to.
	@Provide('canvas')
		canvas: {
		context: CanvasRenderingContext2D|null;
	} = {
				context: null,
			};

	@Watch('canvasWidth')
	@Watch('canvasHeight')
	onPageOverlayViewCanvasSizeChanged() {
		// We need to manually trigger an update of the canvas drawing by changing the key
		// otherwise the drawing won't update when changing the canvas size (toggle bleed margin)
		this.componentKey += 1;
	}

	private componentKey = 0;

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset
			? this.pageModel.offset
			: 0;
	}

	get canvasHeight() {
		return Math.round(
			this.pageModel.height * this.scaling + 2 * this.bleedMargin * this.scaling,
		);
	}

	get canvasWidth() {
		return Math.round(
			this.pageModel.width * this.scaling + 2 * this.bleedMargin * this.scaling,
		);
	}

	protected get flippedOverlay() {
		if (!this.offeringModel) {
			return false;
		}

		if (this.pageModel.id === COVER_BACK) {
			return true;
		}
		if (this.pageModel.id === COVER_FRONT) {
			return false;
		}
		if (this.pageModel.id === COVER_FRONT_INSIDE) {
			return true;
		}
		if (this.pageModel.id === COVER_BACK_INSIDE) {
			return false;
		}

		return !!(OfferingGroups(
			this.offeringModel.groupid,
			['BookTypes', 'PhotoSheets'],
		) && (this.pageIndex + this.offeringModel.startright) % 2 === 0);
	}

	get offeringFrameModel(): OfferingFrameModel | undefined {
		if (!this.offeringModel) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(
			this.offeringModel.id,
			this.pageIndex,
		);
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get overlayImage() {
		return this.offeringModel
			? this.offeringModel.overlay
			: undefined;
	}

	get pageIndex() {
		return ProductStateModule.getPageIndex(this.pageModel);
	}

	created() {
		if (!ProductStateModule.getOverlayImage) {
			ProductStateModule.loadOverlay();
		}

		this.loadOfferingFrameImage();
	}

	beforeDestroy() {
		if (this.offeringFrameModel) {
			AppDataModule.unsetOfferingFrameImage(this.offeringFrameModel);
		}
	}

	mounted() {
		// We can't access the rendering context until the canvas is mounted to the DOM.
		// Once we have it, provide it to all child components.
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		this.canvas.context = canvas.getContext('2d');
	}

	@Watch('pageIndex')
	loadOfferingFrameImage() {
		if (this.offeringFrameModel?.overpage
			&& this.offeringFrameModel?.required
			&& this.offeringFrameModel?.imageModel
		) {
			AppDataModule.getAndSetOfferingFrameImage(this.offeringFrameModel.imageModel);
		}
	}
}
