import _ from 'underscore';
import merge from 'deepmerge';
import {
	VuexModule,
	Module,
	Mutation,
} from 'vuex-module-decorators';
import { DialogGroup, DialogModel, DialogOptions } from 'interfaces/app';
import { authState, mainState } from './defaults';

@Module({ namespaced: true, name: 'dialog' })
export default class Dialog extends VuexModule {
	auth: DialogModel = JSON.parse(JSON.stringify(authState));

	error: DialogModel = JSON.parse(JSON.stringify(mainState));

	loader: DialogModel = JSON.parse(JSON.stringify(mainState));

	main: DialogModel = JSON.parse(JSON.stringify(mainState));

	get getByType() {
		return (id: DialogGroup) => this[id];
	}

	get hasOpen() {
		return this.main.show
			|| this.loader.show
			|| this.auth.show
			|| this.error.show;
	}

	@Mutation
	close(cid: string) {
		if (this.loader.cid == cid) {
			this.loader.show = false;
		} else if (this.main.cid == cid) {
			this.main.show = false;
		} else if (this.error.cid == cid) {
			this.error.show = false;
		} else if (this.auth.cid == cid) {
			this.auth.show = false;
		}
	}

	@Mutation
	closeAuth() {
		this.auth.show = false;
	}

	@Mutation
	closeLoader() {
		this.loader.show = false;
	}

	@Mutation
	openAuth(data: Partial<DialogModel>) {
		data.cid = _.uniqueId('dialog_');
		data.show = true;
		Object.assign(
			this.auth,
			merge(
				authState,
				data,
			),
		);
	}

	@Mutation
	open({
		groupName,
		data,
	}: {
		groupName: DialogGroup;
		data: DialogOptions;
	}) {
		const modelData: DialogModel = _.extend(
			data,
			{
				cid: _.uniqueId('dialog_'),
				show: true,
			},
		);
		Object.assign(
			this[groupName],
			merge(
				JSON.parse(JSON.stringify(mainState)),
				modelData,
			),
		);
	}

	@Mutation
	removeHooks(dialogModel: DialogModel) {
		if (dialogModel.beforeClose) {
			if (this.loader.cid == dialogModel.cid) {
				this.loader.beforeClose = undefined;
			} else if (this.main.cid == dialogModel.cid) {
				this.main.beforeClose = undefined;
			} else if (this.error.cid == dialogModel.cid) {
				this.error.beforeClose = undefined;
			} else if (this.auth.cid == dialogModel.cid) {
				this.auth.beforeClose = undefined;
			}
		}
	}
}
