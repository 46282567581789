import _Vue, { PluginFunction } from 'vue';
import VueRouter, { Position } from 'vue-router';

export type ScrollBehaviorHistory = {
	path: string;
	position: Position;
}

export type ScrollBehavior = {
	delay: number;
	history: ScrollBehaviorHistory[];
	maxHistory: number;
	install: PluginFunction<ScrollBehaviorOptions>;
}

export type ScrollBehaviorOptions = {
	delay?: ScrollBehavior['delay'];
	maxHistory?: ScrollBehavior['maxHistory'];
	router: VueRouter;
}

const scrollBehavior: ScrollBehavior = {
	delay: 0,
	history: [],
	maxHistory: 50,
	install: () => ({}),
};

scrollBehavior.install = (Vue: typeof _Vue, options?: ScrollBehaviorOptions): void => {
	if (
		typeof options?.router === 'object'
		&& typeof options?.router.beforeEach === 'function'
	) {
		const {
			router,
			delay,
			maxHistory,
		} = options;

		if (delay) {
			scrollBehavior.delay = delay;
		}
		if (maxHistory) {
			scrollBehavior.maxHistory = maxHistory;
		}

		router.beforeEach((to, from, next) => {
			if (!from.meta?.keepScrollPosition) {
				next();
			} else {
				if (scrollBehavior.history.length >= scrollBehavior.maxHistory) {
					scrollBehavior.history.shift();
				}

				const prevHistory = scrollBehavior.history.find((history) => history.path === from.fullPath);
				const prevHistoriesAlsoKnownAs = scrollBehavior.history.filter((history) => from.meta?.alsoKnownAs?.includes(history.path));

				if (prevHistory) {
					prevHistory.position = {
						x: 0,
						y: window.scrollY,
					};
				} else {
					scrollBehavior.history.push({
						path: from.fullPath,
						position: {
							x: 0,
							y: window.scrollY,
						},
					});
				}
				if (prevHistoriesAlsoKnownAs.length > 0) {
					// eslint-disable-next-line no-restricted-syntax
					for (const prevHistoryAlsoKnownAs of prevHistoriesAlsoKnownAs) {
						prevHistoryAlsoKnownAs.position = {
							x: 0,
							y: window.scrollY,
						};
					}
				} else if (
					to.meta?.alsoKnownAs
					&& to.meta.alsoKnownAs.length > 0
				) {
					// eslint-disable-next-line no-restricted-syntax
					for (const currAlsoKnownAs of to.meta.alsoKnownAs) {
						scrollBehavior.history.push({
							path: currAlsoKnownAs,
							position: {
								x: 0,
								y: window.scrollY,
							},
						});
					}
				}

				next();
			}
		});
		router.afterEach((to) => {
			if (to.meta?.keepScrollPosition) {
				const prevHistory = scrollBehavior.history.find((history) => history.path === to.fullPath);
				const prevHistoryAlsoKnownAs = scrollBehavior.history.find((history) => to.meta?.alsoKnownAs?.includes(history.path));

				if (
					prevHistory
					|| prevHistoryAlsoKnownAs
				) {
					const scrollTo = () => Vue.nextTick(() => {
						const { position } = (prevHistory || prevHistoryAlsoKnownAs) as ScrollBehaviorHistory;
						window.scrollTo(
							position.x,
							position.y,
						);
					});

					if (scrollBehavior.delay > 0) {
						scrollTo();
						setTimeout(
							scrollTo,
							scrollBehavior.delay,
						);
						setTimeout(
							scrollTo,
							scrollBehavior.delay + 50,
						);
						setTimeout(
							scrollTo,
							scrollBehavior.delay + 100,
						);
					} else {
						scrollTo();
					}
				} else {
					window.scrollTo(
						0,
						0,
					);
				}
			}
		});
	} else {
		console.warn('Scroll behavior depends on vue-router');
	}
};

export default scrollBehavior;
