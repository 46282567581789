import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'progress' })
export default class Progress extends VuexModule {
	completed = 0;

	label: string | null = null;

	startLabel: string | null = null;

	total = 0; // total size of uncompleted + completed

	get getLabel() {
		return this.getPercentage == 0 && this.startLabel
			? this.startLabel
			: this.label || window.App.router.$i18next.t('progress');
	}

	get getPercentage() {
		return Math.floor((1 - (this.total - this.completed) / this.total) * 100);
	}

	@Mutation
	public increaseCompleted(q: number) {
		this.completed += q;
	}

	@Mutation
	public increaseTotal(q: number) {
		this.total += q;
	}

	@Mutation
	public setCompleted(value: number) {
		if (value > this.total) {
			// Completed cannot be higher than total
			this.total = value;
		}

		this.completed = value;
	}

	@Mutation
	public setTotal(value: number) {
		this.total = value;
	}

	@Action
	public complete() {
		const { commit } = this.context;
		commit(
			'setCompleted',
			this.total,
		);
	}

	@Action
	public start(value: number) {
		const { commit } = this.context;
		commit(
			'setCompleted',
			0,
		);
		commit(
			'setTotal',
			value,
		);
	}
}
