import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import { GroupComponentListener } from 'interfaces/app';
import { vue as vueUtils } from 'utils';
import { Public } from 'utils/decorators';
import {
	Component,
	Model,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ButtonToggleGroupComponent',
})
export default class ButtonToggleGroupComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the value of the button toggle group (value of the selected button toggle)',
			type: [Number, String],
		},
	)
	public readonly value?: number | string;

	private internalValue: number | string | null = null;

	private listeners!: GroupComponentListener<ButtonToggleComponent>[];

	private resizeObserver?: ResizeObserver;

	protected beforeDestroy(): void {
		this.removeButtonToggleChildListeners();
		this.resizeObserver?.disconnect();
		this.resizeObserver = undefined;
	}

	protected beforeUpdate(): void {
		this.removeButtonToggleChildListeners();
	}

	protected created(): void {
		this.listeners = [];
	}

	protected mounted(): void {
		const parentElement = (
			this.$el.closest('.horizontal-arrow-scroll-component-container')
			|| this.$el.parentElement
		);

		if (parentElement) {
			this.resizeObserver = new ResizeObserver(this.checkChildrenScroll);
			this.resizeObserver.observe(parentElement);
		}

		this.addButtonToggleChildListeners();
	}

	protected updated(): void {
		this.addButtonToggleChildListeners();
		/**
		 * Update could be triggered by a child component,
		 * in that case we need to perform a check on the
		 * internal value to make sure it is in sync with
		 * the checked state of the child components.
		 */
		this.$nextTick(this.onInternalValueChange);
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		this.childrenSyncValues();

		if (this.internalValue !== this.value) {
			requestAnimationFrame(this.checkChildrenScroll);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = (
			this.value
			?? null
		);
		requestAnimationFrame(this.checkChildrenScroll);
	}

	@Public()
	public checkChildrenScroll(): void {
		const selectedChild = this.childrenSyncValues();

		if (
			selectedChild?.$el
			&& this.$el.parentElement
		) {
			const parentElement = (
				this.$el.closest('.horizontal-arrow-scroll-component-container')
				|| this.$el.parentElement
			);
			const parentElementComputedStyle = getComputedStyle(parentElement);

			if (
				parentElementComputedStyle.overflowX
				|| parentElementComputedStyle.overflowY
			) {
				const selectedChildElement = selectedChild.$el as HTMLElement;
				selectedChildElement.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}
	}

	@Public()
	public childrenSyncValues(): ButtonToggleComponent | undefined {
		/**
		* A manual update could be triggered by a child component,
		* in that case we need to perform a check on the
		* internal value to make sure it is in sync with
		* the checked state of the child components.
		*/
		const children = this.$children || [];
		let selectedChild: ButtonToggleComponent | undefined;

		// eslint-disable-next-line no-restricted-syntax
		for (const child of children) {
			if (child instanceof ButtonToggleComponent) {
				if (child.value === this.internalValue) {
					selectedChild = child;
					vueUtils.setComponentProp(
						child,
						'checked',
						true,
					);
				} else {
					vueUtils.setComponentProp(
						child,
						'checked',
						false,
					);
				}
			}
		}

		return selectedChild;
	}

	private addButtonToggleChildListeners(): void {
		const children = this.$children || [];

		// eslint-disable-next-line no-restricted-syntax
		for (const child of children) {
			if (child instanceof ButtonToggleComponent) {
				const listener: GroupComponentListener<ButtonToggleComponent> = {
					component: child,
					listener: (value: boolean) => {
						this.onButtonToggleChange(
							child,
							value,
						);
					},
				};
				listener.component.$on(
					'change',
					listener.listener,
				);
				this.listeners.push(listener);
			}
		}
	}

	private emitChange(): void {
		if (this.internalValue !== this.value) {
			this.$emit(
				'change',
				this.internalValue,
			);
			requestAnimationFrame(this.checkChildrenScroll);
		}
	}

	private removeButtonToggleChildListeners(): void {
		// eslint-disable-next-line no-restricted-syntax
		for (const listeners of this.listeners) {
			listeners.component.$off(
				'change',
				listeners.listener,
			);
		}

		this.listeners = [];
	}

	private onButtonToggleChange(
		component: ButtonToggleComponent,
		value: boolean,
	): void {
		if (value) {
			this.internalValue = component.value || null;
			this.emitChange();
		}
	}
}
