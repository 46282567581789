import _ from 'underscore';
import DropboxSDK, { Dropbox } from 'dropbox';
import {
	ChannelModel,
	ConnectFolderData,
	ChannelProfileModel,
	ConnectorBridgeResponseData,
	DropboxType,
	DropboxFile,
} from 'interfaces/app';
import parseUrl from '../tools/parse-url';
import openWindow from '../ops/open-window';
import connector, {
	ConnectorBridge,
	ConnectorBridgeLoginResponse,
	ConnectorBridgeLoginOptions,
	ConvertedPhotoData,
} from '../controllers/connector';
import { ERRORS_OAUTH_FAILED } from '../settings/errors';

function determineIfIsFileMetadataReference(
	toBeDetermined: DropboxSDK.files.FolderMetadataReference | DropboxSDK.files.FileMetadataReference | DropboxSDK.files.DeletedMetadataReference,
): toBeDetermined is DropboxSDK.files.FileMetadataReference {
	if ((toBeDetermined as DropboxSDK.files.FileMetadataReference)) {
		return true;
	}
	return false;
}

class DropboxClass implements ConnectorBridge {
	public contentFilters: string[] = [];

	public grantedScopes: string[] = [];

	public isSupported = true;

	private network: ChannelModel['id'] = 'dropbox';

	private channel = 'dropbox';

	private display = 'popup';

	private sdk: DropboxType | undefined;

	public setup() {
		return Promise.resolve();
	}

	public init(channelModel: ChannelModel) {
		if (!channelModel.apikey) {
			throw new Error('Missing api key');
		}
		this.sdk = new Dropbox({
			clientId: channelModel.apikey,
		});

		return Promise.resolve();
	}

	public login(
		scopes: string[],
		options: ConnectorBridgeLoginOptions,
	): Promise<ConnectorBridgeLoginResponse> {
		const { sdk } = this;

		return new Promise((resolve, reject) => {
			const display = options && options.display
				? options.display
				: this.display;

			if (sdk) {
				if (display == 'none') {
					if (!connector.networks.dropbox.accessToken) {
						reject(new Error('No active access_token from Dropbox'));
					} else {
						resolve({
							accessToken: connector.networks.dropbox.accessToken || undefined,
						});
					}
				} else {
					sdk.auth?.getAuthenticationUrl(`${window.glAppUrl}auth/wait.html`)
						.then((authUrl) => {
							const popup = openWindow(
								authUrl as string,
								{},
							);
							if (popup) {
								let accessToken: string;
								let userid: string;

								const timer = window.setInterval(
									() => {
										try {
											const parsedUrl = parseUrl(popup.location.href);
											const hash = parsedUrl.hash.substring(1);
											if (hash) {
												_.each(
													hash.split('&'),
													(param) => {
														const parts = param.replace(
															/\+/g,
															' ',
														).split('=');
														let key = parts.shift();
														let val: string | undefined | null = parts.length > 0 ? parts.join('=') : undefined;

														if (key) {
															key = decodeURIComponent(key);
														}

														// missing `=` should be `null`:
														// http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
														val = (typeof val === 'undefined') ? null : decodeURIComponent(val);

														if (key == 'access_token' && val) {
															accessToken = val;
														} else if (key == 'account_id' && val) {
															userid = val;
														}
													},
												);

												popup.close();
											}
										} catch (e) {
										// Continue regardless of error
										}

										if (!popup || popup.closed) { // popup closed
											window.clearInterval(timer);

											if (accessToken) {
												sdk.auth?.setAccessToken(accessToken);
												resolve({
													accessToken,
													userid,
												});
											} else {
												reject(new Error(ERRORS_OAUTH_FAILED));
											}
										}
									},
									100,
								);
							} else {
								reject(new Error('Could not open authorization popup'));
							}
						});
				}
			} else {
				reject(new Error('SDK is unavailable'));
			}
		});
	}

	public logout() {
		return Promise.resolve();
	}

	public me(): Promise<ChannelProfileModel> {
		return new Promise((resolve, reject) => {
			if (this.sdk) {
				this.sdk.usersGetCurrentAccount().then(({ result }) => {
					resolve({
						id: result.account_id,
						email: result.email,
						first_name: result.name.given_name,
						last_name: result.name.surname,
						display_name: result.name.display_name,
						picture: result.profile_photo_url,
					});
				}).catch(reject);
			} else {
				reject(new Error('SDK is unavailable'));
			}
		});
	}

	public folders(
		folderid: string | null,
		options: any,
	): Promise<ConnectorBridgeResponseData> {
		return new Promise((resolve, reject) => {
			const successHandler = (
				result: DropboxSDK.files.ListFolderResult,
			) => {
				const requestData: DropboxSDK.files.GetThumbnailBatchArg = {
					entries: [],
				};
				result.entries.forEach((entry) => {
					if (
						entry['.tag'] === 'file'
						&& this.isPhoto(entry.name)
						&& entry.path_display
					) {
						requestData.entries.push({
							path: entry.path_display,
							format: {
								'.tag': 'jpeg',
							},
							size: {
								'.tag': 'w128h128',
							},
						});
					}
				});

				if (requestData.entries.length && this.sdk) {
					const returnData: Record<string, any>[] = [];
					this.sdk.filesGetThumbnailBatch(requestData).then((resp) => {
						result.entries.forEach((entry) => {
							const clonedEntry = JSON.parse(JSON.stringify(entry));

							resp.result.entries.forEach((thumbEntry) => {
								if (
									thumbEntry['.tag'] == 'success'
									&& determineIfIsFileMetadataReference(entry)
									&& thumbEntry.metadata && thumbEntry.metadata.id
									&& entry.id && entry.id == thumbEntry.metadata.id
								) {
									clonedEntry.thumb_url = thumbEntry.thumbnail;
								}
							});

							returnData.push(clonedEntry);
						});

						resolve({
							data: returnData,
							paging: {
								nextPage: result.has_more ? result.cursor : undefined,
							},
						});
					});
				} else {
					resolve({
						data: result.entries,
						paging: {
							nextPage: result.has_more ? result.cursor : undefined,
						},
					});
				}
			};

			function errorHandler(err: any) {
				if (err.status == 400) {
					connector.logoutNetwork('dropbox');
				}

				const error = err instanceof Error ? err : new Error(err);
				reject(error);
			}

			if (this.sdk) {
				if (options.nextPage) {
					this.sdk
						.filesListFolderContinue({
							cursor: options.nextPage,
						})
						.then((resp) => {
							successHandler(resp.result);
						})
						.catch((err) => {
							errorHandler(err);
						});
				} else {
					this.sdk
						.filesListFolder({
							include_media_info: true,
							limit: 20,
							path: folderid || '',
						})
						.then((resp) => {
							successHandler(resp.result);
						})
						.catch((err) => {
							errorHandler(err);
						});
				}
			} else {
				reject(new Error('SDK is unavailable'));
			}
		});
	}

	public albums() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albumPhotos() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public photos() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public convertAlbumData() {
		throw new Error('Bridge function not implemented');
	}

	public convertPhotoData(photoData: DropboxFile) {
		const objPhoto: ConvertedPhotoData = {
			source: this.network,
			externalId: String(photoData.id),
			thumb_url: `data:image/jpeg;base64,${photoData.thumb_url}`,
			full_url: photoData.path_display || null,
		};

		if (photoData.media_info && photoData.media_info['.tag'] == 'metadata') {
			const metaData = photoData.media_info.metadata as DropboxSDK.files.PhotoMetadataReference;
			if (metaData.dimensions && metaData.dimensions.width) {
				objPhoto.full_width = metaData.dimensions.width;
			}
			if (metaData.dimensions && metaData.dimensions.height) {
				objPhoto.full_height = metaData.dimensions.height;
			}
			if (metaData.time_taken) {
				objPhoto.photodate = metaData.time_taken;
			}
		}

		return objPhoto;
	}

	public convertFolderData(folderData: DropboxSDK.files.FolderMetadataReference) {
		const objFolder: ConnectFolderData = {
			id: folderData.id,
			name: folderData.name,
		};

		return objFolder;
	}

	public getFileType(fileData: {
		'.tag': string;
		name: string;
	}) {
		if (fileData['.tag'] == 'folder') {
			return 'folder';
		} if (fileData['.tag'] == 'file') {
			if (this.isPhoto(fileData.name)) {
				return 'photo';
			}
			return 'file';
		}
		return fileData['.tag'];
	}

	public getFullUrl(path: string) {
		if (!this.sdk) {
			return Promise.reject(
				new Error('SDK is unavailable'),
			);
		}

		return this.sdk.filesGetTemporaryLink({
			path,
		}).then((resp) => resp.result.link);
	}

	private isPhoto(fileName: string) {
		const ext = ['.png', '.jpg', '.jpeg', 'gif'];
		return ext.indexOf(fileName.substr(-4).toLowerCase()) >= 0
			|| ext.indexOf(fileName.substr(-5).toLowerCase()) >= 0;
	}

	public share() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}
}

export default DropboxClass;
