import _ from 'underscore';
import merge from 'deepmerge';
import Vue from 'vue';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import * as DB from 'interfaces/database';
import { AjaxOptions } from 'interfaces/app';
import { ERRORS_INVALID_REQUEST_DATA } from '../../../settings/errors';
import ajax from '../../../controllers/ajax';

@Module({ namespaced: true, name: 'externalusers' })
export default class ExternalUsers extends VuexModule {
	collection: DB.ExternalUserModel[] = [];

	fetched = false;

	modelUrl = '/api/userexternal';

	offset = 0;

	totalRecords: number | null = null;

	private get collectionUrl() {
		return `/api/user/${this.context.rootState.user.id}/external`;
	}

	public get findWhere() {
		return (properties: Partial<DB.ExternalUserModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	@Mutation
	private _addModel(data: DB.ExternalUserModel) {
		this.collection.push(data);
	}

	@Mutation
	private _setModel(data: DB.ExternalUserModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		Vue.set(
			this.collection,
			i,
			data,
		);
	}

	@Mutation
	private _updateModel(data: DB.ExternalUserModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		Vue.set(
			this.collection,
			i,
			_.extend(
				model,
				data,
			),
		);
	}

	@Mutation
	public updateModels(data: Partial<DB.ExternalUserModel>) {
		_.each(
			this.collection,
			(model, i) => {
				Vue.set(
					this.collection,
					i,
					_.extend(
						model,
						data,
					),
				);
			},
		);
	}

	@Action({ rawError: true })
	public addModel(data: DB.ExternalUserModel): Promise<DB.ExternalUserModel> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getById(data.id)) {
					commit(
						'_updateModel',
						data,
					);
				} else {
					commit(
						'_addModel',
						data,
					);
				}

				resolve(getters.getById(data.id));
			}
		});
	}

	@Action
	public addModels(arrData: DB.ExternalUserModel[]) {
		const { dispatch } = this.context;

		_.each(
			arrData,
			(data) => {
				dispatch(
					'addModel',
					data,
				);
			},
		);
	}

	@Action({ rawError: true })
	public putModel({
		id,
		data,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		data: Partial<DB.ExternalUserModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else if (!data) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				const model = getters.getById(id);
				if (model) {
					const currentModelData = JSON.parse(JSON.stringify(model));
					const newModelData = _.extend(
						{ id },
						data,
					);

					commit(
						'_updateModel',
						newModelData,
					);

					const defaultRequestOptions: AxiosRequestConfig = {
						method: 'put',
						url: `${this.modelUrl}/${id}`,
						headers: {
							'content-type': 'application/json; charset=utf-8',
						},
						data: newModelData,
					};
					const defaultMethodOptions: AjaxOptions = {
						auth: true,
						retry: 1,
						debug: {
							offline: true,
							dialog: true,
							abort: false,
						},
					};

					requestOptions = requestOptions
						? merge(
							defaultRequestOptions,
							requestOptions,
						)
						: defaultRequestOptions;
					methodOptions = methodOptions
						? merge(
							defaultMethodOptions,
							methodOptions,
						)
						: defaultMethodOptions;

					ajax
						.request(
							requestOptions,
							methodOptions,
						)
						.then((response) => {
							commit(
								'_setModel',
								response.data,
							);
							resolve();
						})
						.catch((err) => {
							commit(
								'_setModel',
								currentModelData,
							);
							reject(err);
						});
				} else {
					reject(new Error('Model does not exist'));
				}
			}
		});
	}
}
