// eslint-disable-next-line import/prefer-default-export
export function addListener<T extends ServiceEventHandler<any>>(
	eventName: string,
	listener: T,
	listeners?: Record<string, T[] | T>,
): Record<string, T[] | T> {
	let newListeners: Record<string, T[] | T> = {};

	if (listeners) {
		newListeners = { ...listeners };
	}

	if (newListeners[eventName]) {
		let currentListeners = newListeners[eventName];

		if (Array.isArray(currentListeners)) {
			currentListeners = [
				...currentListeners,
				listener,
			];
		} else {
			currentListeners = [
				currentListeners,
				listener,
			];
		}

		newListeners[eventName] = currentListeners;
	} else {
		newListeners[eventName] = [listener];
	}

	return newListeners;
}
