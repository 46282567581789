import {
	Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import { ConfigModule, DialogModule } from 'store/index';
import { DialogModel } from 'interfaces/app';
import AuthView from './auth.vue';
import LoginView from './login';
import SignupView from './signup';

@Component({
	components: {
		AuthView,
		LoginView,
		SignupView,
	},
})
export default class AuthDialogView extends Vue {
	@Prop({ required: true, type: Object })
	readonly dialogModel!: DialogModel;

	@Ref('dialog') readonly dialog!: HTMLDivElement;

	@Ref('dialogBody') readonly dialogBody!: HTMLDivElement;

	@Ref('dialogHeader') readonly dialogHeader!: HTMLDivElement;

	private maxBodyHeight = 0;

	private maxWidth = window.innerWidth;

	private posY = 0;

	get maxHeight() {
		return window.innerHeight - 20;
	}

	get bodyStyle() {
		return this.dialogModel.bodyStyle || {};
	}

	get componentListeners() {
		return this.dialogModel.vueListeners || {};
	}

	get labelName() {
		return ConfigModule.labelTagName;
	}

	get posX() {
		return -this.width / 2;
	}

	get styleObject() {
		return {
			width: `${this.width}px`,
			'max-height': `${this.maxHeight}px`,
			'margin-top': `${this.posY}px`,
			'margin-left': `${this.posX}px`,
		};
	}

	get width() {
		return Math.min(
			this.dialogModel.width,
			this.maxWidth - 20,
		);
	}

	created() {
		this.posY = this.dialogModel.posy;
	}

	mounted() {
		this.$nextTick(this.centerScreen);

		window.addEventListener(
			'resize',
			this.resize,
		);
	}

	updated() {
		this.centerScreen();
	}

	beforeDestroy() {
		window.removeEventListener(
			'resize',
			this.resize,
		);
	}

	clickModal() {
		if (this.dialogModel.modal) {
			this.checkClose();
		}
	}

	checkClose() {
		if (this.dialogModel.hasclose) {
			this.closeDialog();
		}
	}

	closeDialog() {
		// Execute any custom handlers
		if (this.dialogModel.beforeClose && typeof this.dialogModel.beforeClose === 'function') {
			this.dialogModel.beforeClose();
			DialogModule.removeHooks(this.dialogModel);
		}

		// Emit close event to parent view
		this.$emit(
			'closeDialog',
			this.dialogModel,
		);
	}

	centerScreen() {
		let maxBodyHeight = this.maxHeight;
		let dialogHeight = 0;

		if (this.dialog) {
			const style = window.getComputedStyle(this.dialog);
			if (style) {
				dialogHeight += parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
				maxBodyHeight -= (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom));
			}
		}

		if (this.dialogHeader) {
			let headerHeight = this.dialogHeader.clientHeight;

			const style = window.getComputedStyle(this.dialogHeader);
			if (style) {
				// clientHeight includes padding, but not margins, so add this manually
				headerHeight += parseFloat(style.marginTop);
				headerHeight += parseFloat(style.marginBottom);
			}

			maxBodyHeight -= headerHeight;
			dialogHeight += headerHeight;
		}

		if (this.dialogBody) {
			dialogHeight += this.dialogBody.clientHeight;

			const style = window.getComputedStyle(this.dialogBody);
			if (style) {
				maxBodyHeight -= parseFloat(style.marginTop);
				maxBodyHeight -= parseFloat(style.marginBottom);
			}
		}

		// Dialog may not be bigger than viewport size
		dialogHeight = Math.min(
			this.maxHeight,
			dialogHeight,
		);

		this.maxBodyHeight = maxBodyHeight;
		this.posY = -dialogHeight / 2;
	}

	resize() {
		this.maxWidth = window.innerWidth;
	}

	stopDelegation(e: Event) {
		e.stopPropagation();
	}
}
