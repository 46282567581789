import {
	Vue, Component,
} from 'vue-property-decorator';

@Component
export default class WebPushSoftAskView extends Vue {
	accept() {
		this.closeDialog();
	}

	closeDialog() {
		this.$emit('closeDialog');
	}

	decline() {
		this.closeDialog();
	}
}
