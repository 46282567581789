import _ from 'underscore';
import merge from 'deepmerge';
import * as DB from 'interfaces/database';
import {
	AnalyticsSubController,
	PricingObject,
	AnalyticsEcommerceProduct,
	AnalyticsUserProperties,
	AnalyticsProjectReadyProperties,
	AnalyticsCreateProjectProperties,
	AnalyticsOpenProjectProperties,
} from 'interfaces/app';
import {
	UserModule,
	AppDataModule,
	ProductsModule,
	ConfigModule,
	AppStateModule,
	ExternalUsersModule,
} from '../../store/index';

export default class MoengageController implements AnalyticsSubController {
	private sdk: any;

	private shownWebPushSoftAsk = false;

	private userProperties: AnalyticsUserProperties = {};

	public init() {
		if (ConfigModule['analytics.moengage.id']
			&& ConfigModule['analytics.moengage.enabled']
			&& typeof window.moe !== 'undefined'
		) {
			const setupProperties = {
				app_id: ConfigModule['analytics.moengage.id'],
				debug_logs: ConfigModule['analytics.moengage.debug'] ? 1 : 0,
				cluster: ConfigModule['analytics.moengage.cluster'],
				swPath: '/sw.js',
			};
			this.sdk = window.moe(
				setupProperties,
			);

			if (window.glPlatform === 'web') {
				AppStateModule.enableWebPushAvailability();
			} else if (window.glPlatform === 'native') {
				if (!window.webToNative) {
					throw new Error('Missing WebToNative on window');
				}

				// Note: Android app does not have this function yet
				if (window.webToNative.getPermissions) {
					window.webToNative.getPermissions().then((permissionDetails) => {
						if (!permissionDetails.pushEnabled) {
							window.webToNative?.requestPushPermission();
						}
					});
				}
			}

			this.identifyUser();

			return true;
		}

		return false;
	}

	private logFailure() {
		if (typeof window.glBugsnagClient !== 'undefined') {
			window.glBugsnagClient.notify(
				new Error('Moengage Web SDK not loaded (possibly due to adblocker)'),
				(event) => {
					event.severity = 'info';
				},
			);
		}

		return undefined;
	}

	public setExperimentFlags(
		flags: Record<string, string | number | boolean | null>,
	) {
		if (!this.sdk) {
			return this.logFailure();
		}

		Object.entries(flags).forEach(([flagName, flagValue]) => {
			this.sdk.add_user_attribute(
				flagName,
				flagValue,
			);
		});

		return undefined;
	}

	public setUserProperties(
		objProperties: AnalyticsUserProperties,
	) {
		if (!this.sdk) {
			return this.logFailure();
		}

		this.userProperties = merge(
			this.userProperties,
			objProperties,
		);

		_.pairs(objProperties).forEach((pair) => {
			const [k, v] = pair;
			if (k == 'First_name') {
				this.sdk.add_first_name(v);
			} else if (k == 'Last_name') {
				this.sdk.add_last_name(v);
			} else if (k == 'Email') {
				this.sdk.add_email(v);
			} else {
				this.sdk.add_user_attribute(
					k,
					v,
				);
			}
		});

		return undefined;
	}

	public logout() {
		if (!this.sdk) {
			return this.logFailure();
		}

		this.sdk.destroy_session();

		return undefined;
	}

	private track(
		event: string,
		properties: Record<string, any>,
	) {
		if (!this.sdk) {
			return this.logFailure();
		}

		this.sdk.track_event(
			event,
			properties,
		);

		return undefined;
	}

	public aliasUser(
		oldUserId: DB.UserModel['id'],
		newUserId: DB.UserModel['id'],
	) {
		if (!this.sdk) {
			return this.logFailure();
		}

		if (newUserId) {
			this.sdk.update_unique_user_id(
				newUserId.toString(),
			);
		}

		return undefined;
	}

	public registerUser() {
		this.identifyUser();
	}

	public identifyUser() {
		if (!this.sdk) {
			return this.logFailure();
		}

		if (UserModule.id) {
			if (ConfigModule['analytics.moengage.externalUserId']) {
				const externalUser = ExternalUsersModule.findWhere({
					source: 'app',
				});
				if (externalUser
					&& externalUser.externalId
				) {
					this.sdk.add_unique_user_id(
						externalUser.externalId,
					);
				}

				this.sdk.add_user_attribute(
					'FastEditor_Id',
					UserModule.id,
				);
			} else {
				this.sdk.add_unique_user_id(
					UserModule.id.toString(),
				);
			}

			if (ConfigModule['features.webPush']
				&& !this.shownWebPushSoftAsk
				&& window.glPlatform == 'web'
				&& (
					UserModule.session_nr === 1
					|| UserModule.session_nr % 10 === 0
				)
			) {
				this.sdk.call_web_push({
					soft_ask: true,
					main_class: 'webPushSoftAsk',
					allow_class: 'acceptButton',
					block_class: 'declineButton',
				});

				this.shownWebPushSoftAsk = true;
			}
		}

		return undefined;
	}

	public trackPageView() {
		if (!this.sdk) {
			return this.logFailure();
		}

		this.sdk.track_page_view();

		return undefined;
	}

	public trackEvent(
		action: string,
		objProperties: Record<string, any>,
	) {
		this.track(
			action,
			objProperties,
		);
	}

	public trackProductReady(
		productModel: DB.ProductModel | null,
		objPrice: PricingObject | null,
		objProperties: AnalyticsProjectReadyProperties,
	) {
		const properties = {
			page_count: objProperties.pageCount,
			photo_count: objProperties.photoCount,
			groupid: objProperties.groupid,
			typeid: objProperties.typeid,
			variantid: objProperties.variantid,
			product_origin: objProperties.product_origin,
			type: objProperties.type,
			price: objPrice ? Math.round(objPrice.subTotal) / 100 : undefined,
			theme_id: productModel ? productModel.themeid : undefined,
			layout_id: productModel ? productModel.layoutid : undefined,
		};

		this.track(
			'Product ready',
			properties,
		);
	}

	public trackAddToCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			const productModel = ProductsModule.getById(cartItemModel.productid);

			const baseUrl = `${window.location.protocol}//${window.location.host}`;
			const productUrl = `${baseUrl}/app/${cartItemModel.productid}/open`;

			this.track(
				'Product Added',
				{
					...objProperties,
					// Semantic properties
					cart_id: cartItemModel.cartid,
					product_id: cartItemModel.productid,
					sku: offeringModel.id,
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid,
					price: Math.round(objPrice.subTotal) / 100,
					quantity: cartItemModel.quantity,
					url: productUrl,
					// Custom properties
					groupid: cartItemModel.groupid,
					product_origin: productModel
						? productModel.origin
						: undefined,
				},
			);
		}
	}

	public trackRemoveFromCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			this.track(
				'Removed Product',
				{
					...objProperties,
					cart_id: cartItemModel.cartid,
					product_id: cartItemModel.productid,
					sku: offeringModel.id,
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid,
					quantity: cartItemModel.quantity,
				},
			);
		}
	}

	public trackPerformance(
		action: string,
		value: number,
		objProperties: Record<string, any>,
	) {
		this.track(
			`Performance: ${action}`,
			{
				...objProperties,
				performanceValue: value,
			},
		);
	}

	public trackCreateProject(
		productModel: DB.ProductModel,
		objProperties: AnalyticsCreateProjectProperties,
	) {
		this.track(
			'Create Product',
			objProperties,
		);
	}

	public trackOpenProduct(
		productModel: DB.ProductModel,
		objProperties: AnalyticsOpenProjectProperties,
	) {
		this.track(
			'Open Product',
			objProperties,
		);
	}

	public trackTransaction(
		orderModel: DB.OrderModel,
		orderItems: DB.OrderItemModel[],
		objProperties: Record<string, any>,
	) {
		const arrProducts: AnalyticsEcommerceProduct[] = [];
		orderItems.forEach((orderItemModel) => {
			const offeringModel = AppDataModule.getOffering(orderItemModel.offeringid);
			if (offeringModel) {
				arrProducts.push({
					...objProperties,
					product_id: orderItemModel.productid.toString(),
					sku: offeringModel.id.toString(),
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid.toString(),
					price: Math.round(orderItemModel.salesvalue) / 100,
					quantity: orderItemModel.quantity,
				});
			}
		});

		this.track(
			'Order Completed',
			{
				...objProperties,
				order_id: orderModel.id,
				total: Math.round(orderModel.salesvalue) / 100,
				shipping: Math.round(orderModel.price_shipping) / 100,
				tax: Math.round(orderModel.total_tax) / 100,
				discount: Math.round(orderModel.discount_voucher) / 100,
				coupon: orderModel.voucherid ? `${orderModel.voucherid}` : null,
				currency: orderModel.currency,
				products: arrProducts,
			},
		);
	}
}
