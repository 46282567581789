import * as DB from 'interfaces/database';

export const textPositionModelAttributes: DB.TemplatePositionTextModel = {
	id: null,
	templatestateid: null,
	templategroupid: null,
	x_axis: 0,
	y_axis: 0,
	z_axis: 1,
	width: 10,
	height: 10,
	angle: 0,
	borderwidth: 0,
	bordercolor: '#FFFFFF',
	fontface: '',
	fontcolor: '#000000',
	fontbold: 0,
	fontitalic: 0,
	snap: 0,
	overlay: 0,
	bgcolor: null,
	align: 'Left',
	pointsize: 12,
	transformable: 1,
	productattribute: null,
	overlap_top: 0, // to allow a fixed amount of pixels overlap with another object on the top side
	overlap_right: 0, // to allow a fixed amount of pixels overlap with another object on the right side
	overlap_bottom: 0, // to allow a fixed amount of pixels overlap with another object on the bottom side
	overlap_left: 0, // to allow a fixed amount of pixels overlap with another object on the left side
};

export const photoPositionModelAttributes: DB.TemplatePositionPhotoModel = {
	id: null,
	templatestateid: null,
	templategroupid: null,
	borderimageid: null,
	x_axis: 0,
	y_axis: 0,
	z_axis: 1,
	width: 10,
	height: 10,
	angle: 0,
	borderwidth: 0,
	bordercolor: '#FFFFFF',
	autorotate: 0,
	flag: 1, // When photo fills this position, flag it to avoid photo being used in other positions when auto filling?
	ratio: 0, // is ratio fixed?
	overlap_top: 0, // to allow a fixed amount of pixels overlap with another object on the top side
	overlap_right: 0, // to allow a fixed amount of pixels overlap with another object on the right side
	overlap_bottom: 0, // to allow a fixed amount of pixels overlap with another object on the bottom side
	overlap_left: 0, // to allow a fixed amount of pixels overlap with another object on the left side
	matrix: 0,
	transformable: 1,
	mask: null,
	fillMethod: 'cover',
};
