import './defines';
import {
	Component,
	Model,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'SwitchBoxComponent',
})
export default class SwitchBoxComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			acceptedValues: [
				true,
				false,
			],
			description: 'Defines the value of the switch box',
			default: false,
			type: Boolean,
		},
	)
	public readonly value!: boolean;

	protected internalValue = false;

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.value !== this.internalValue) {
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.internalValue !== this.value) {
			this.internalValue = this.value;
		}
	}

	protected onClick(event: MouseEvent): void {
		this.internalValue = !this.internalValue;
		this.$emit(
			'click',
			event,
		);
	}
}
