import navigate from 'controllers/navigate';
import { AppDataModule } from 'store/index';
import analytics from 'controllers/analytics';
import openUrl from './open-url';

const clickLogo = () => {
	if (window.glPlatform === 'native') {
		navigate.toStart();
	} else {
		const hyperlinkModel = AppDataModule.findHyperlink({ tag: 'home' });
		if (hyperlinkModel) {
			analytics.trackEvent(
				'Open Hyperlink',
				{
					origin: 'logo',
					url: hyperlinkModel.url,
				},
			);

			openUrl(
				hyperlinkModel.url,
				Boolean(hyperlinkModel.delegate),
			);
		} else {
			navigate.toStart();
		}
	}
};

export default clickLogo;
