const formatMoney = (
	cents: number,
	decimalSeparator: ',' | '.',
) => {
	const decimals = 2;
	cents = decimals > 2 ? cents : Math.ceil(cents);

	const value = (cents / 100).toFixed(decimals);
	return value.replace(
		'.',
		decimalSeparator,
	);
};

export default formatMoney;
