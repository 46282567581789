import './defines';
import { ServiceEvent } from 'services/service-event';
import { mobile as mobileTools } from 'tools';
import {
	dom as domUtils,
	vue as vueUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import Vue, { VueConstructor } from 'vue';
import {
	Component,
	Prop,
	Ref,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogComponent',
})
export default class DialogComponent<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
> extends Vue.extend(Template) {
	@Prop({
		default: undefined,
		type: Function,
	})
	public readonly beforeClose?: ServiceEventHandler<any>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly body!: DialogServiceOptionsBody<BodyComponent>;

	@Prop({
		default: () => [],
		type: [Array, Object, String],
	})
	public readonly classes!: string[] | string | Record<string, boolean>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly footer!: DialogServiceOptionsFooter<FooterComponent>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly header!: DialogServiceOptionsHeader<HeaderComponent>;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly lightOverlay!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly maxScreenSize!: boolean;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly listeners!: Record<string, ServiceEventHandler<any>[] | ServiceEventHandler<any>>;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly styles!: Partial<CSSStyleRule['style']>;

	@Prop({
		default: 320,
		type: [Number, String],
	})
	public readonly width?: number | string | null;

	protected get computedStyles(): Partial<CSSStyleRule['style']> {
		const styles: Partial<CSSStyleRule['style']> = {
			...this.styles,
		};
		if (!styles.maxHeight) {
			styles.maxHeight = `${this.maxHeight - this.spacing}px`;
		}
		if (!styles.maxWidth) {
			styles.maxWidth = `${this.maxWidth - this.spacing}px`;
		}
		if (
			!styles.height
			&& this.maxScreenSize
			&& this.isMobile
		) {
			styles.height = styles.maxHeight;
		}

		if (this.width) {
			let { width } = this;

			if (typeof width === 'string') {
				const widthNumber = parseFloat(width);
				const widthUnit = width.replace(
					widthNumber.toString(),
					'',
				);

				if (widthUnit === '%') {
					width = Math.round((widthNumber / 100) * this.maxWidth);
				} else {
					width = widthNumber;
				}
			}

			width = Math.min(
				width,
				this.maxWidth,
			);
			styles.width = typeof this.width === 'number'
				? `${this.width}px`
				: this.width;
		}

		return styles;
	}

	protected get bodyClasses(): Record<string, boolean> {
		return {
			body: true,
			...this.getClasses(this.body.classes),
		};
	}

	protected get bodyComponentInstanceAPI(): NonVuePublicProps<InstanceType<BodyComponent>> | undefined {
		if (this.$slots.body?.[0].componentInstance) {
			return vueUtils.getInstanceAPI(this.$slots.body[0].componentInstance as InstanceType<BodyComponent>);
		}

		return undefined;
	}

	protected get dialogClasses(): Record<string, boolean> {
		return {
			chrome: true,
			dialog: true,
			...this.getClasses(this.classes),
		};
	}

	protected get footerClasses(): Record<string, boolean> {
		const classes: Record<string, boolean> = {
			buttons: true,
			...this.getClasses(this.footer.classes),
		};

		if (
			'buttons' in this.footer
			&& this.footer.buttons
		) {
			if (
				!('singleButton' in classes)
				&& !('doubleButtons' in classes)
				&& !('multipleButtons' in classes)
			) {
				if (this.footer.buttons.length === 1) {
					classes.singleButton = true;
				} else if (this.footer.buttons.length === 2) {
					classes.doubleButtons = true;
				} else if (this.footer.buttons.length > 2) {
					classes.multipleButtons = true;
				}
			}

			if (this.footer.wrapButtons) {
				classes.wrap = true;
			}
		}

		return classes;
	}

	protected get footerComponentInstanceAPI(): NonVuePublicProps<InstanceType<FooterComponent>> | undefined {
		if (this.$slots.footer?.[0].componentInstance) {
			return vueUtils.getInstanceAPI(this.$slots.footer[0].componentInstance as InstanceType<FooterComponent>);
		}

		return undefined;
	}

	protected get hasHeader(): boolean {
		return !!(
			'component' in this.header
			|| 'title' in this.header
			|| (
				'hasCloseButton' in this.header
				&& this.header.hasCloseButton
			)
		);
	}

	protected get hasFooter(): boolean {
		return !!(
			'component' in this.footer
			|| 'buttons' in this.footer
		);
	}

	protected get headerClasses(): Record<string, boolean> {
		return {
			header: true,
			...this.getClasses(this.header.classes),
		};
	}

	protected get headerComponentInstanceAPI(): NonVuePublicProps<InstanceType<HeaderComponent>> | undefined {
		if (this.$slots.header?.[0].componentInstance) {
			return vueUtils.getInstanceAPI(this.$slots.header[0].componentInstance as InstanceType<HeaderComponent>);
		}

		return undefined;
	}

	protected get modalClasses(): Record<string, boolean> {
		return {
			modal: !this.lightOverlay,
			lightModal: this.lightOverlay,
		};
	}

	@Ref('dialog')
	private dialogElement!: HTMLDivElement;

	@Ref('dialogBody')
	private dialogBodyElement!: HTMLDivElement;

	@Ref('dialogFooter')
	private dialogFooterElement?: HTMLDivElement;

	@Ref('dialogHeader')
	private dialogHeaderElement?: HTMLDivElement;

	private bodyChildrenChangeListenerDisconnect?: () => void;

	protected bodyStyles: Partial<CSSStyleRule['style']> = {};

	private calculatingBodyStyles!: boolean;

	private isMobile = mobileTools.isMobile;

	private maxHeight = window.innerHeight;

	private maxWidth = window.innerWidth;

	private isMobileUnwatch?: () => void;

	private resizeObserver?: ResizeObserver;

	private spacing = 20;

	protected beforeDestroy(): void {
		this.bodyChildrenChangeListenerDisconnect?.();
		this.bodyChildrenChangeListenerDisconnect = undefined;
		this.isMobileUnwatch?.();
		this.isMobileUnwatch = undefined;
		this.resizeObserver?.disconnect();
		this.resizeObserver = undefined;

		const headerComponent = this.$slots.header?.[0].componentInstance;
		const bodyComponent = this.$slots.body?.[0].componentInstance;
		const footerComponent = this.$slots.footer?.[0].componentInstance;

		if (headerComponent) {
			headerComponent.$destroy();
		}

		if (bodyComponent) {
			bodyComponent.$destroy();
		}

		if (footerComponent) {
			footerComponent.$destroy();
		}
	}

	protected created(): void {
		if (this.maxScreenSize) {
			this.spacing = 0;
		}

		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected mounted(): void {
		const bodyComponent = this.$slots.body?.[0].componentInstance;
		requestAnimationFrame(() => {
			this.bodyChildrenChangeListenerDisconnect = domUtils.onElementChildrenChange(
				this.dialogBodyElement,
				() => {
					if (!this.calculatingBodyStyles) {
						requestAnimationFrame(this.calculateBodyComputedStyles);
					}
				},
			);
		});
		this.resizeObserver = new ResizeObserver(() => {
			requestAnimationFrame(() => {
				this.maxHeight = window.innerHeight;
				this.maxWidth = window.innerWidth;
				this.$nextTick(() => this.calculateBodyComputedStyles());
			});
		});
		this.resizeObserver.observe(document.body);
		this.resizeObserver.observe(this.dialogBodyElement);

		if (bodyComponent?.$el) {
			this.resizeObserver.observe(bodyComponent.$el);
		}

		this.calculateBodyComputedStyles();
	}

	@Public()
	public bodyComponent(): NonVuePublicProps<InstanceType<BodyComponent>> | undefined {
		return this.bodyComponentInstanceAPI;
	}

	private calculateBodyComputedStyles(): void {
		if (
			this.calculatingBodyStyles
			|| this._isDestroyed
			|| !this.bodyChildrenChangeListenerDisconnect
		) {
			return;
		}

		this.calculatingBodyStyles = true;
		const styles: Partial<CSSStyleRule['style']> = {
			...(this.body.styles || {}),
		};

		if (!styles.maxHeight) {
			this.bodyStyles.maxHeight = 'initial';
			const calculateHeight = (tries = 0): void => {
				this.$nextTick(() => {
					if (
						this._isDestroyed
						|| !this.bodyChildrenChangeListenerDisconnect
					) {
						return;
					}

					let dialogHeight = this.dialogElement?.clientHeight || 0;

					if (
						dialogHeight === 0
						&& tries < 3
					) {
						calculateHeight(tries + 1);
					} else if (dialogHeight === 0) {
						return;
					}

					const dialogStyles = getComputedStyle(this.dialogElement);
					let dialogHeaderHeight = this.dialogHeaderElement?.clientHeight || 0;
					const dialogHeaderStyles = (
						this.dialogHeaderElement
							? getComputedStyle(this.dialogHeaderElement)
							: {} as CSSStyleDeclaration
					);

					if (
						dialogStyles.paddingTop
						|| dialogStyles.paddingBottom
					) {
						dialogHeight -= parseInt(
							dialogStyles.paddingTop,
							10,
						);
						dialogHeight -= parseInt(
							dialogStyles.paddingBottom,
							10,
						);
					}

					if (
						this.dialogHeaderElement
						&& (
							dialogHeaderStyles.paddingTop
							|| dialogHeaderStyles.paddingBottom
							|| dialogHeaderStyles.marginTop
							|| dialogHeaderStyles.marginBottom
						)
					) {
						dialogHeaderHeight += parseInt(
							dialogHeaderStyles.paddingTop,
							10,
						);
						dialogHeaderHeight += parseInt(
							dialogHeaderStyles.paddingBottom,
							10,
						);
						dialogHeaderHeight += parseInt(
							dialogHeaderStyles.marginTop,
							10,
						);
						dialogHeaderHeight += parseInt(
							dialogHeaderStyles.marginBottom,
							10,
						);
					}

					let dialogFooterHeight = this.dialogFooterElement?.clientHeight || 0;
					const dialogFooterStyles = (
						this.dialogFooterElement
							? getComputedStyle(this.dialogFooterElement)
							: {} as CSSStyleDeclaration
					);

					if (
						this.dialogFooterElement
						&& (
							dialogFooterStyles.paddingTop
							|| dialogFooterStyles.paddingBottom
							|| dialogFooterStyles.marginTop
							|| dialogFooterStyles.marginBottom
						)
					) {
						dialogFooterHeight += parseInt(
							dialogFooterStyles.paddingTop,
							10,
						);
						dialogFooterHeight += parseInt(
							dialogFooterStyles.paddingBottom,
							10,
						);
						dialogFooterHeight += parseInt(
							dialogFooterStyles.marginTop,
							10,
						);
						dialogFooterHeight += parseInt(
							dialogFooterStyles.marginBottom,
							10,
						);
					}

					styles.maxHeight = `${dialogHeight - dialogHeaderHeight - dialogFooterHeight}px`;
					this.bodyStyles = styles;

					setTimeout(() => {
						this.$nextTick(() => {
							this.calculatingBodyStyles = false;
						});
					});
				});
			};
			calculateHeight();
			return;
		}

		this.bodyStyles = styles;
		this.calculatingBodyStyles = false;
	}

	@Public()
	public footerComponent(): NonVuePublicProps<InstanceType<FooterComponent>> | undefined {
		return this.footerComponentInstanceAPI;
	}

	private getClasses(
		classes?: string[] | string | Record<string, boolean>,
	): Record<string, boolean> {
		const newClasses: Record<string, boolean> = {};

		if (classes) {
			if (Array.isArray(classes)) {
				// eslint-disable-next-line no-restricted-syntax
				for (const footerClass of classes) {
					newClasses[footerClass] = true;
				}
			} else if (typeof classes === 'string') {
				newClasses[classes] = true;
			} else {
				Object.assign(
					newClasses,
					classes,
				);
			}
		}

		return newClasses;
	}

	@Public()
	public headerComponent(): NonVuePublicProps<InstanceType<HeaderComponent>> | undefined {
		return this.headerComponentInstanceAPI;
	}

	@Public()
	public onCloseClick(preventDestroy?: boolean): void {
		const event = new ServiceEvent({
			type: 'close',
		});

		if (this.beforeClose) {
			this.beforeClose(event);
		}

		if (!event.defaultPrevented) {
			this.$emit('close');
		} else if (
			event.defaultPrevented
			&& preventDestroy
		) {
			this._destroyPrevented = true;
		}
	}

	protected onFooterButtonClick(
		buttonId: string,
		event: MouseEvent,
	): void {
		if ('buttons' in this.footer) {
			const footerButton = this.footer.buttons?.find((button) => button.id === buttonId);
			footerButton?.click?.call(
				this,
				event as any,
			);
		}
	}
}
