import * as DialogService from 'services/dialog';
import UserSettingsView from 'views/user-settings';

export default () => {
	// Show user settings dialog
	const { close: closeDialog } = DialogService.openDialog({
		header: {
			hasCloseButton: false,
			title: window.App.router.$t('dialogHeaderUserSettings'),
		},
		body: {
			component: UserSettingsView,
		},
		footer: {
			buttons: [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonUserSettingsOk'),
					click: () => {
						closeDialog();
					},
				},
			],
		},
		width: 325,
	});
};
