import PageObject from 'classes/pageobject';
import ProductState from 'classes/productstate';
import TemplatePosition from 'classes/templateposition';
import Theme from 'classes/theme';
import DialogCheckboxComponent from 'components/dialog-checkbox';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import navigate from 'controllers/navigate';
import upload from 'controllers/upload';
import {
	ChannelModel,
	DialogClosePayloadVirtalOfferingSelect,
	ImportLibraryPageObjects,
	ImportLibraryProductPhotos,
	SelectMenuItem,
	TemplatePhotoPosition,
	ToolbarPhotoPhotosQueued,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import changeObjectPhoto from 'mutations/pageobject/change-photo';
import { OfferingGroups } from 'settings/offerings';
import {
	AppDataModule,
	AppStateModule,
	ChannelsModule,
	FontModule,
	PhotosModule,
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
	UserModule,
} from 'store';
import { mobile as mobileTools } from 'tools';
import getColorInverse from 'tools/get-color-inverse';
import getViewport from 'tools/get-viewport';
import _ from 'underscore';
import BackgroundPickerView from 'views/background-picker';
import FramePickerView from 'views/frame-picker';
import ImportLightBoxView from 'views/import-lightbox';
import OfferingOptionsView from 'views/offering-options';
import SizePickerView from 'views/size-picker';
import SmartEnhancementView from 'views/smart-enhancement';
import TemplatePickerView from 'views/template-picker';
import ThemePickerView from 'views/theme-picker';
import PhotoToolbarView from 'views/toolbar-photo';
import VirtualOfferingSelectView from 'views/virtual-offering-select';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import EditorBoxView from '../editor-box.vue';
import TopToolbarView from '../toolbar-top/template.vue';

@Component({
	components: {
		EditorBoxView,
		ImportLightBoxView,
		TopToolbarView,
		PhotoToolbarView,
	},
})
export default class EditorView extends Vue {
	@Ref('floatButton') readonly floatButton!: HTMLDivElement;

	@Ref('pageBrowserBox') readonly pageBrowserBox!: HTMLDivElement;

	@Ref('viewWrapper') readonly viewWrapper!: HTMLDivElement;

	@Prop({ default: false, type: Boolean }) readonly standalone!: boolean;

	@Prop({ required: true, type: Number }) readonly pageIndex!: number;

	private activePhotoModel: PI.PhotoModel | null = null;

	private displayWidth = 0;

	private interfaceBottomFixedHeight = 0;

	private interfaceTopHeight = 0;

	private isMobile = true;

	private mediaQueryList = window.matchMedia('(max-width: 767px)');

	private showPhotoToolbar = false;

	private templatePhotoPosition: TemplatePhotoPosition | null = null;

	private viewportSize = getViewport();

	get countryModel() {
		return UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
	}

	get editorBoxHeight() {
		let boxHeight = this.viewportSize.height;

		boxHeight -= this.interfaceTopHeight;
		boxHeight -= this.interfaceBottomHeight;

		if (this.pageBrowserBox) {
			boxHeight -= this.pageBrowserBox.getBoundingClientRect().height;
		}

		// Deduct height for some padding to make sure the page won't be glued to the top bar
		boxHeight -= 20;

		return boxHeight;
	}

	get editorBoxStyle() {
		return {
			top: `${this.offsetVerticalCenter}px`,
		};
	}

	get hasRedo() {
		return ProductStateModule.hasFuture;
	}

	get hasUndo() {
		return ProductStateModule.hasHistory;
	}

	protected get importChannels(): ChannelModel[] {
		return ChannelsModule.photoImportChannels;
	}

	get interfaceBottomHeight() {
		let height = this.interfaceBottomFixedHeight;

		if (this.isObjectToolbarShown) {
			height += this.isMobile ? 65 : 100;
		}

		return height;
	}

	get isObjectToolbarShown() {
		return !!(ProductStateModule.getSelectedPageObject);
	}

	get menuItems() {
		const items: SelectMenuItem[] = [];

		if (this.offeringModel?.virtual) {
			items.push({
				icon: 'auto_fix_normal',
				text: this.$t('selectMenu.items.editPhoto'),
				target: this.editPhoto,
			});
		}

		if (
			this.offeringModel?.flexgroupid
			&& this.offeringModel.virtual
		) {
			items.push({
				icon: 'photo_size_select_large',
				text: this.$t('selectMenu.items.changeSize'),
				target: () => {
					if (!this.offeringModel) {
						throw new Error('Missing required offeringModel');
					}
					if (!this.pageModel) {
						throw new Error('Missing required pageModel');
					}

					const offeringModels = AppDataModule.findOffering({
						flexgroupid: this.offeringModel.flexgroupid,
					});

					if (offeringModels.length === 0) {
						throw new Error('There are no offeringModels linked to this virtual one');
					}

					const { close: closeDialog } = this.$openDialog({
						body: {
							component: VirtualOfferingSelectView,
							props: {
								count: 1,
								modus: 'change',
								offeringModel: this.offeringModel,
								preSelectedOfferingId: this.pageModel.offeringId,
								regionId: this.countryModel?.regionid || 0,
								selectSize: true,
								selectFinish: false,
								selectLayout: false,
							},
							listeners: {
								closeDialog: (event: ServiceEvent<DialogClosePayloadVirtalOfferingSelect>) => {
									if (event.payload?.typeid) {
										if (!this.pageModel) {
											throw new Error('Missing required pageModel');
										}

										ProductState.changePageOfferingTypeId(
											this.pageModel,
											event.payload.typeid,
										);
									}

									closeDialog();
								},
							},
							styles: {
								padding: '0',
							},
						},
						width: 800,
					});
				},
			});
		}

		if (this.showPhotoButton) {
			items.push({
				icon: 'add_photo_alternate',
				text: this.$t(
					'selectMenu.items.addPhoto',
					{ count: 1 },
				),
				target: this.openPhotoToolbar,
			});
		}

		if (this.showTextButton) {
			items.push({
				icon: 'post_add',
				text: this.$t('selectMenu.items.addText'),
				target: this.addTextToPage,
			});
		}

		if (this.showShuffleButton) {
			items.push({
				icon: 'shuffle',
				text: this.$t('selectMenu.items.shufflePhotos'),
				target: this.shufflePhotos,
			});
		}

		if (this.showBackgroundButton) {
			items.push({
				icon: 'color_lens',
				text: this.$t('selectMenu.items.changeBackgroundPage'),
				target: this.selectBackground,
			});
		}

		if (this.showTemplateButton) {
			items.push({
				icon: 'dashboard',
				text: this.$t('selectMenu.items.changeLayoutPage'),
				target: this.selectTemplate,
			});
		}

		if (this.showSizeButton) {
			items.push({
				icon: 'photo_size_select_small',
				text: this.$t('selectMenu.items.changeSize'),
				target: this.selectSize,
			});
		}

		if (this.showFrameButton) {
			items.push({
				icon: 'wallpaper',
				text: this.$t('selectMenu.items.changeFrame'),
				target: this.selectFrame,
			});
		}

		if (this.showThemeButton) {
			items.push({
				icon: 'wallpaper',
				text: this.$t('selectMenu.items.changeTheme'),
				target: this.selectTheme,
			});
		}

		if (this.showRotateButton) {
			items.push({
				icon: 'screen_rotation',
				text: this.$t('selectMenu.items.changeOrientation'),
				target: this.changeOrientation,
			});
		}

		if (this.showProductOptionsButton) {
			items.push({
				icon: 'miscellaneous_services',
				text: this.$t('selectMenu.items.changeProductOptions'),
				target: this.selectProductOptions,
			});
		}

		if (this.showBleedToggle) {
			items.push({
				icon: this.showBleed ? 'visibility' : 'visibility_off',
				text: this.$t('buttonBleed'),
				target: this.toggleBleed,
			});
		}

		if (this.showSmartEnhancement) {
			items.push({
				icon: 'auto_awesome',
				text: this.$t('selectMenu.items.smartEnhancement'),
				target: this.changeSmartEnhance,
			});
		}

		if (this.showDeletePage) {
			const deleteText = this.offeringModel && OfferingGroups(
				this.offeringModel.groupid,
				['BookTypes'],
			)
				? this.$t('selectMenu.items.deletePage')
				: this.$t('selectMenu.items.deletePrint');

			items.push({
				icon: 'delete',
				text: deleteText,
				target: this.removePage,
			});
		}

		return items;
	}

	get nextPageModel() {
		const pageModels = ProductStateModule.getPages;
		let nextPageModel = null;
		for (let x = this.pageIndex + 1; x < pageModels.length; x += 1) {
			const pageModel = ProductStateModule.getPageByNumber(x);
			if (pageModel && pageModel.editable) {
				nextPageModel = pageModel;
				break;
			}
		}
		return nextPageModel;
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get offeringOptionModels(): DB.OfferingOptionModel[] {
		if (!this.offeringModel) {
			return [];
		}

		if (OfferingGroups(
			this.offeringModel.groupid,
			['PageSets', 'BookTypes'],
		)) {
			return [];
		}

		const models = AppDataModule.getOfferingOptionValueModels(
			this.offeringModel.id,
			this.countryModel?.regionid,
		);

		// Get all ids of the relevant offering options
		const options = _.pluck(
			models,
			'offeringoptionid',
		);

		// Get all offering options with multiple values, so user can pick
		const optionIds = _.uniq(options).filter(
			(optionId) => options.filter((x) => x == optionId).length > 1,
		);

		if (optionIds.length === 0) {
			return [];
		}

		// Check if the options should be shown in editor
		const mapping = optionIds.map(
			(optionId) => _.findWhere(
				AppDataModule.offeringoptions,
				{
					id: optionId,
				},
			),
		);
		return _.compact(mapping);
	}

	protected get offeringType(): DB.OfferingModel['type'] {
		if (!this.offeringModel) {
			return 'photo';
		}

		return this.offeringModel.type;
	}

	get offsetVerticalCenter() {
		return Math.round((this.interfaceTopHeight - this.interfaceBottomHeight) / 2);
	}

	get pageBrowserBoxStyle() {
		return {
			width: this.displayWidth ? `${this.displayWidth}px` : '100%',
			top: `${this.offsetVerticalCenter}px`,
		};
	}

	get pageCountLabel() {
		const pageCountLabel = ProductStateModule.getPageCount;
		if (!this.pageLabel || !pageCountLabel) {
			return null;
		}

		return pageCountLabel < 10
			? `0${pageCountLabel}`
			: pageCountLabel;
	}

	get pageModel() {
		return ProductStateModule.getPageByNumber(this.pageIndex);
	}

	get pageLabel() {
		return this.pageModel
			? ProductStateModule.getPageLabel(this.pageModel)
			: null;
	}

	get pickerMax() {
		if (window.glPlatform === 'native') {
			return 1;
		}

		return Math.max(
			1,
			(this.themeModel?.maxphotos || 1) - ProductStateModule.getPhotosSelected.length,
		);
	}

	get photoModels() {
		if (!this.pageModel) {
			return [];
		}

		return ProductStateModule.getPagePhotos(
			this.pageModel,
		);
	}

	get previousPageModel() {
		let previousPageModel = null;
		for (let x = this.pageIndex - 1; x >= 0; x -= 1) {
			const pageModel = ProductStateModule.getPageByNumber(x);
			if (this.pageIndex === 2
				&& this.offeringModel?.fixedcover
			) {
				return null;
			}

			if (pageModel && pageModel.editable) {
				previousPageModel = pageModel;
				break;
			}
		}
		return previousPageModel;
	}

	get productModel() {
		return ProductStateModule.getProduct;
	}

	get rotatedOfferingModel() {
		if (!this.offeringModel) {
			return undefined;
		}

		if (!this.offeringModel.flexgroupid) {
			// The offering needs to be linked to a flexgroup in order to be able to
			// search for a similar (rotated) one
			return false;
		}

		if (this.offeringModel.width === this.offeringModel.height) {
			// This is a quare offering, so there's no landscape/portrait offering equivalent
			return undefined;
		}

		return AppDataModule.findOfferingWhere({
			flexgroupid: this.offeringModel.flexgroupid,
			variantid: this.offeringModel.variantid,
			width: this.offeringModel.height,
			height: this.offeringModel.width,
		});
	}

	get showBackgroundButton(): boolean {
		if (this.pageModel && this.offeringModel) {
			return Boolean(this.offeringModel.groupid != 102
				&& this.pageModel.bgcolor != 'transparent'
				&& (!this.pageModel.bgimage || this.pageModel.bgimage.length === 0)
				&& (
					!OfferingGroups(
						this.offeringModel.groupid,
						['BasicProducts'],
					)
					|| (this.themeModel && this.themeModel.color)
				));
		}

		return false;
	}

	get showBleed() {
		return AppStateModule.showBleed;
	}

	get showBleedToggle(): boolean {
		return Boolean(this.pageModel
			&& this.pageModel.offset
			&& this.offeringModel
			&& (this.offeringModel.showbleed === 1 || this.offeringModel.showbleed === 3));
	}

	get showDeletePage(): boolean {
		if (this.pageModel && this.offeringModel) {
			if (OfferingGroups(
				this.offeringModel.groupid,
				['PrintTypes'],
			)
				&& this.offeringModel.pagequantity
			) {
				return true;
			}
			if (OfferingGroups(
				this.offeringModel.groupid,
				['BookTypes'],
			)
				&& this.offeringModel.spread === 0
				&& this.pageModel.editable
				&& ProductStateModule.getPageIndex(this.pageModel) > 1
				&& (
					!this.offeringModel.maxpages
					|| !this.offeringModel.minprintpages
					|| this.offeringModel.maxpages > this.offeringModel.minprintpages
				)
			) {
				return true;
			}
		}
		return false;
	}

	get showLeftFlip(): boolean {
		return !!this.previousPageModel;
	}

	protected get showLightBox(): boolean {
		return Boolean(
			window.glPlatform !== 'native'
			&& this.offeringModel?.type !== 'logo',
		);
	}

	get showPhotoButton(): boolean {
		if (!this.pageModel
			|| !this.offeringModel
		) {
			return false;
		}

		if (OfferingGroups(
			this.offeringModel.groupid,
			['PageSets'],
		)) {
			// These offerings consists of one print per photo
			// so users cannot add more photos to the page
			return false;
		}

		if (this.themeModel
			&& this.themeModel.maxphotos === 0
		) {
			return false;
		}

		const templateModel = ProductStateModule.getPageTemplate(
			this.pageModel,
		);
		if (!templateModel?.transformable) {
			return false;
		}

		const templatePositions = ProductStateModule.getPageTemplatePositions(
			this.pageModel,
		);
		const photoPosition = templatePositions.find(
			(m) => m.type === 'photo',
		);
		const photoObject = ProductStateModule.getPageObjects(this.pageModel).find(
			(m) => m.type === 'photo',
		);
		if (!photoPosition
			&& !photoObject
		) {
			return false;
		}

		return true;
	}

	get showProductOptionsButton(): boolean {
		return this.offeringOptionModels.filter(
			(optionModel) => optionModel.showduringedit && !optionModel.tag,
		).length > 0;
	}

	get showRightFlip(): boolean {
		return !!this.nextPageModel;
	}

	get showRotateButton(): boolean {
		if (this.offeringModel?.virtual
			&& this.pageModel
			&& this.pageModel.width != this.pageModel.height
		) {
			// Virtual offerings can always have their pages rotated
			return true;
		}

		return !!(this.rotatedOfferingModel);
	}

	get showSizeButton(): boolean {
		return this.offeringOptionModels.filter(
			(optionModel) => optionModel.tag === 'size' && optionModel.showduringedit,
		).length > 0;
	}

	get showFrameButton(): boolean {
		return this.offeringOptionModels.filter(
			(optionModel) => optionModel.tag === 'frame' && optionModel.showduringedit,
		).length > 0;
	}

	get showShuffleButton(): boolean {
		if (this.pageModel && this.offeringModel && this.offeringModel.maxpages === 1) {
			const templatePositions = _.where(
				ProductStateModule.getPageTemplatePositions(this.pageModel),
				{ type: 'photo' },
			);
			if (templatePositions.length > 1) {
				return true;
			}
		}
		return false;
	}

	get showSmartEnhancement(): boolean {
		if (!this.offeringModel || !this.standalone) {
			return false;
		}

		if (this.offeringModel.applyEnhancement || this.offeringModel.applyUpscaling) {
			return true;
		}

		return false;
	}

	get showTemplateButton(): boolean {
		if (!this.templateModel) {
			return false;
		}

		if (this.templateModel.dynamic) {
			return true;
		}

		const templateSets = ThemeStateModule.getTemplateSets(
			this.templateModel.id,
		);
		return templateSets && templateSets.length > 1;
	}

	get showTextButton(): boolean {
		if (this.pageModel) {
			const templatePositions = ProductStateModule.getPageTemplatePositions(this.pageModel);
			return Boolean(this.templateModel
				&& (
					this.templateModel.transformable
					|| _.findWhere(
						templatePositions,
						{ type: 'text' },
					)
					|| _.findWhere(
						ProductStateModule.getPageObjects(this.pageModel),
						{ type: 'text' },
					)
				));
		}

		return false;
	}

	get showThemeButton(): boolean {
		if (this.offeringModel && OfferingGroups(
			this.offeringModel.groupid,
			['BasicProducts'],
		)) {
			const themeModels = ThemeDataModule.getThemesByOfferingId(this.offeringModel.id);
			return themeModels.length > 1;
		}

		return false;
	}

	get templateModel() {
		if (this.pageModel && this.pageModel.template) {
			return ThemeDataModule.getTemplate(this.pageModel.template);
		}

		return null;
	}

	get templateSet() {
		if (this.pageModel) {
			return ProductStateModule.getPageTemplateSet(
				this.pageModel,
				this.pageModel.templateSetId || undefined,
				this.photoModels,
			).templateSet;
		}

		return [];
	}

	get themeModel() {
		return ThemeStateModule.themeModel;
	}

	/* get title() {
		if (this.standalone && this.offeringModel) {
			return AppDataModule.getOfferingName(this.offeringModel.id);
		}

		if (this.offeringModel && OfferingGroups(this.offeringModel.groupid, ['BookTypes'])) {
			return this.$t('buttonEditPage');
		}

		return this.$t('buttonEditPrint');
	} */

	protected get toolbarPhotoPageObjects(): ImportLibraryPageObjects {
		return ProductStateModule.getObjects;
	}

	protected get toolbarPhotoPhotosQueued(): ToolbarPhotoPhotosQueued {
		return ProductStateModule.getPhotosQueued;
	}

	protected get toolbarPhotoProductPhotos(): ImportLibraryProductPhotos {
		return ProductStateModule.getPhotosSelected;
	}

	@Watch('pageModel')
	onEditorViewPageModelChange(newPageModel: PI.PageModel) {
		if (newPageModel) {
			// Activate this page for editing
			ProductStateModule.setActivePage(newPageModel.id);
		} else if (this.offeringModel && !OfferingGroups(
			this.offeringModel.groupid,
			['BasicProducts'],
		)) {
			this.closeEditor();
		}
	}

	@Watch('showPhotoToolbar')
	onEditorShowPhotoToolbarChange(
		newValue: boolean,
		oldValue: boolean,
	) {
		if (oldValue && !newValue) {
			upload.changeCheckMaxPhotos(true);
		}
	}

	created() {
		if (this.pageModel) {
			ProductStateModule.resetHistory();

			// Activate this page for editing
			ProductStateModule.setActivePage(this.pageModel.id);
		}

		Theme.fetchData(true).then(() => {
			if (this.pageModel) {
				const templateid = this.pageModel.template;
				if (!templateid || !ThemeDataModule.getTemplate(templateid)) {
					// Template model not available
					// Could be no longer available => get replacement
					const pageIndex = ProductStateModule.getPageIndex(this.pageModel);
					const pageModels = ProductStateModule.getPages;
					const themePageStateData = ThemeStateModule.getPageStateData(
						pageIndex,
						pageModels.length,
						{
							offeringId: this.pageModel.offeringId,
						},
					);
					if (themePageStateData) {
						const { pageModel } = themePageStateData;
						if (pageModel) {
							ProductStateModule.changePage({
								id: this.pageModel.id,
								template: pageModel.template,
							});
						}
					}
				}
			}
		}).catch(() => {
			// Swallow error: no action required
		});

		// Check to see if we need to show the user one of the product configuration select UIs
		if (ProductStateModule.productSettings.startWithOfferingOptionUI === 'size'
			&& this.showSizeButton
		) {
			this.selectSize();
		} else if (ProductStateModule.productSettings.startWithOfferingOptionUI === 'other'
			&& this.showProductOptionsButton
		) {
			this.selectProductOptions();
		}
	}

	mounted() {
		window.addEventListener(
			'resize',
			this.resize,
		);

		this.interfaceTopHeight = 0;
		if (this.viewWrapper) {
			// This adds subnavigation bar height to the top offset
			this.interfaceTopHeight += this.viewWrapper.getBoundingClientRect().top;
		}
		// This add top toolbar height to the top offset
		this.interfaceTopHeight += 70;

		this.interfaceBottomFixedHeight = 0;
		if (this.floatButton) {
			let floatButtonHeight = this.floatButton.getBoundingClientRect().height;
			if (floatButtonHeight > 0) {
				// The button is visible. Add the size of the padding as well
				floatButtonHeight += 30;
			}

			this.interfaceBottomFixedHeight += floatButtonHeight;
		}

		if (ThemeStateModule.themeHasLayoutSelect
			&& this.pageModel
			&& !this.pageModel.templateSetId
			&& this.productModel
			&& OfferingGroups(
				this.productModel.group,
				['BasicProducts'],
			)
		) {
			// Show template picker
			this.selectTemplate();
		}

		EventBus.$on(
			'select:photo:position',
			this.onSelectPhotoPosition,
		);

		this.isMobile = this.mediaQueryList.matches;

		if (this.mediaQueryList.addEventListener) {
			this.mediaQueryList.addEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.addListener(this.checkMobile);
		}
	}

	beforeDestroy() {
		window.removeEventListener(
			'resize',
			this.resize,
		);

		// Make sure the flag is enabled (the photo toolbar could still be opened)
		upload.changeCheckMaxPhotos(true);

		if (this.mediaQueryList.removeEventListener) {
			this.mediaQueryList.removeEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.removeListener(this.checkMobile);
		}

		EventBus.$off(
			'select:photo:position',
			this.onSelectPhotoPosition,
		);
	}

	addPhotoToPage(
		selectedPhotoModel: PI.PhotoModel,
	) {
		this.$openLoaderDialog();
		AppStateModule.setHeavyLoad();

		// We use defer to make the UI more responsive
		// Otherwise the loader is not really visible
		_.defer(() => {
			ProductStateModule.selectPhoto(selectedPhotoModel).then((photoModel) => {
				if (!this.pageModel) {
					throw new Error('Missing required page model');
				}

				const templateModel = ProductStateModule.getPageTemplate(
					this.pageModel,
				);
				let templatePosition: TemplatePhotoPosition | undefined;

				if (this.templatePhotoPosition) {
					templatePosition = this.templatePhotoPosition;
				} else {
					const templatePositions = ProductStateModule.getPageTemplatePositionsAvailable(
						this.pageModel,
					);
					const photoPositions = templatePositions.filter(
						(m) => m.type == 'photo',
					) as TemplatePhotoPosition[];

					if (photoPositions.length) {
						([templatePosition] = photoPositions);
					}
				}

				if (!templatePosition
					&& templateModel?.dynamic
					&& this.photoModels.length <= 3
					&& !this.pageModel.customLayout
				) {
					const { templateSet } = ProductStateModule.getPageTemplateSet(
						this.pageModel,
						undefined,
						[
							...this.photoModels,
							selectedPhotoModel,
						],
					);
					if (templateSet) {
						return ProductState.changeTemplate(
							this.pageModel,
							templateSet.id,
							{
								objectTypes: ['photo'],
								photoModels: [
									selectedPhotoModel,
								],
							},
						);
					}
				}

				const pageObjectModel = ProductStateModule.getSelectedPageObject;
				if (pageObjectModel && pageObjectModel.type == 'photo') {
					const swap = Boolean(this.productModel && !OfferingGroups(
						this.productModel.group,
						['BookTypes', 'PhotoSheets'],
					));
					return changeObjectPhoto(
						pageObjectModel,
						selectedPhotoModel,
						templatePosition,
						swap,
					).then(() => {
						analytics.trackEvent(
							'Swap photo',
							{
								category: 'Page object',
								label: 'Button',
							},
						);
						ProductStateModule.pushHistory();

						if (this.pageModel) {
							PageObject.select(
								this.pageModel,
								pageObjectModel.id,
								{
									showToolbar: true,
								},
							);
						}
					}).catch((err) => {
						this.$openErrorDialog({
							body: {
								content: err.message,
							},
						});
					});
				}
				if (templatePosition) {
				// Save user action to analytics
					analytics.trackEvent(
						'Add photo',
						{
							category: 'Product page',
							label: 'Toolbar',
						},
					);

					return TemplatePosition.fillPhotoPosition(
						this.pageModel,
						templatePosition,
						photoModel,
						{},
					)
						.then((newObjectModel) => {
							if (newObjectModel && this.pageModel) {
								ProductStateModule.pushHistory();
								PageObject.select(
									this.pageModel,
									newObjectModel.id,
									{
										showToolbar: true,
									},
								);
							}
						});
				}
				if (templateModel && templateModel.transformable) {
					const scale = Math.max(
						1,
						photoModel.full_width / (this.pageModel.width / 2),
						photoModel.full_height / (this.pageModel.height / 2),
					);
					const objectWidth = photoModel.full_width / scale;
					const objectHeight = photoModel.full_height / scale;
					const maxz = ProductStateModule.getPageMaxZ(this.pageModel);

					analytics.trackEvent(
						'Add photo',
						{
							category: 'Product page',
							label: 'Toolbar',
						},
					);

					return ProductStateModule
						.addPageObject({
							pageId: this.pageModel.id,
							data: {
								x_axis: (this.pageModel.width - objectWidth) / 2,
								y_axis: (this.pageModel.height - objectHeight) / 2,
								z_axis: maxz + 1,
								width: objectWidth,
								height: objectHeight,
								maxwidth: photoModel.full_width,
								maxheight: photoModel.full_height,
								type: 'photo',
								cropwidth: photoModel.full_width,
								cropheight: photoModel.full_height,
								cropx: 0,
								cropy: 0,
								rotate: 0,
								photoid: photoModel.id,
							},
						})
						.then((newObjectModel) => {
							if (this.pageModel) {
								ProductStateModule.pushHistory();
								PageObject.select(
									this.pageModel,
									newObjectModel.id,
									{
										showToolbar: true,
										draggable: true,
									},
								);
							}
						});
				}

				throw new Error('No room for photo');
			}).catch(() => {
				// Swallow error: no action required
			}).finally(() => {
				AppStateModule.unsetHeavyLoad();
				this.$closeLoaderDialog();

				// Important to close the lightbox at the end of the process
				// otherwise some internal parameters of this component are reset too early
				this.closeLightBox();
				this.showPhotoToolbar = false;
			});
		});
	}

	addTextToPage() {
		if (!this.pageModel) {
			throw new Error('Required page model to add text object to is missing');
		}

		const templateModel = ProductStateModule.getPageTemplate(this.pageModel);
		if (templateModel && templateModel.transformable) {
			const fontModel = FontModule.getDefault;

			let maxz = 0;
			ProductStateModule.getPageObjects(this.pageModel).forEach((objectModel) => {
				if (objectModel.editable) {
					maxz = Math.max(
						maxz,
						objectModel.z_axis,
					);
				}
			});

			const xAxis = this.pageModel.width / 2;
			const yAxis = this.pageModel.height / 2;
			const textObjectWidth = this.pageModel.width / 2;
			const textObjectHeight = Math.min(
				this.pageModel.width / 4,
				this.pageModel.height / 4,
			);

			ProductStateModule
				.addPageObject({
					pageId: this.pageModel.id,
					data: {
						x_axis: xAxis - textObjectWidth / 2,
						y_axis: yAxis - textObjectHeight / 2,
						z_axis: maxz + 1,
						width: textObjectWidth,
						height: textObjectHeight,
						type: 'text',
						fontface: fontModel.id,
						fontcolor: getColorInverse(this.pageModel.bgcolor || '#FFFFFF'),
						pointsize: Math.max(
							16,
							Math.min(
								200,
								Math.round(this.pageModel.height / 20),
							),
						),
					},
				})
				.then((objectModel) => {
					if (!this.pageModel) {
						throw new Error('Required page model to select new text object from is missing');
					}

					PageObject.select(
						this.pageModel,
						objectModel.id,
						{
							draggable: false,
							editText: true,
						},
					);

					analytics.trackEvent(
						'Add text',
						{
							category: 'Product page',
							label: 'Click',
						},
					);

					ProductStateModule.pushHistory();
				})
				.catch(() => {
					// Swallow error: no action required
				});
		}
	}

	changeOrientation() {
		if (this.pageModel) {
			ProductState.changePageOrientation(
				this.pageModel,
			);
		}
	}

	changeSmartEnhance() {
		const settings = ProductStateModule.getProductSettings;
		let smartEnhancement = true;

		if (typeof settings.applyEnhancement !== 'undefined') {
			smartEnhancement = settings.applyEnhancement;
		}

		const { close: closeDialog } = this.$openDialogNew({
			header: {
				hasCloseButton: false,
				title: this.$t('views.smartEnhancement.title'),
				styles: {
					fontSize: (
						mobileTools.isMobile
							? 'var(--font-size-m)'
							: 'var(--font-size-l)'
					),
				},
			},
			body: {
				component: SmartEnhancementView,
				props: {
					showSwitchBox: true,
					value: smartEnhancement,
				},
				listeners: {
					change: (value: boolean) => {
						smartEnhancement = value;
					},
				},
			},
			footer: {
				buttons: [
					{
						id: 'save',
						text: this.$t('views.smartEnhancement.saveButton'),
						click: () => {
							const enhancedData: Partial<PI.ProductSettings> = {
								applyEnhancement: smartEnhancement,
							};
							ProductStateModule.changeProductSettings(enhancedData);

							analytics.trackEvent(
								'Save enhancement setting',
								{
									enabled: smartEnhancement,
								},
							);

							closeDialog();
						},
					},
				],
			},
			padding: (
				mobileTools.isMobile
					? [16, 20]
					: [24, 32]
			),
			styles: {
				rowGap: '24px',
			},
			theme: 'light',
			width: (
				mobileTools.isMobile
					? 350
					: 460
			),
		});
	}

	checkMobile(event: MediaQueryListEvent) {
		if (event.matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	closeEditor() {
		ProductStateModule.deselectPageObjects();
		ProductStateModule.resetHistory();

		navigate.back(
			{},
			() => {
				window.App.router.openProduct(
					ProductStateModule.productId as number,
					true,
				);
			},
		);
	}

	closeLightBox() {
		this.activePhotoModel = null;
		this.templatePhotoPosition = null;
	}

	closePhotoToolbar() {
		this.showPhotoToolbar = false;
	}

	editPhoto() {
		if (this.pageModel) {
			const photoObjectModel = ProductStateModule.getPageObjects(this.pageModel).find(
				(model) => model.type == 'photo',
			);
			if (photoObjectModel) {
				PageObject.select(
					this.pageModel,
					photoObjectModel.id,
					{
						showToolbar: true,
					},
				);
			}
		}
	}

	externalUploadCompleted(photoIds: PI.PhotoModel['id'][]) {
		if (photoIds.length === 1) {
			const photoModel = PhotosModule.getById(photoIds[0]);
			if (photoModel) {
				this.selectPhoto(
					photoModel,
					!this.showLightBox,
				);
			}
		}
	}

	goBack() {
		if (
			this.productModel
			&& OfferingGroups(
				this.productModel.group,
				['BasicProducts'],
			)
		) {
			navigate.goBack('editProduct');
		} else {
			this.closeEditor();
		}
	}

	goForward() {
		ProductStateModule.deselectPageObjects();
		ProductStateModule.resetHistory();

		navigate.goForward('editor');
	}

	goToPreviousPage() {
		if (this.previousPageModel && ProductStateModule.productId) {
			ProductStateModule.resetHistory();

			navigate.toEditor(
				ProductStateModule.productId,
				ProductStateModule.getPageIndex(this.previousPageModel).toString(),
				{ trigger: true, replace: true },
			);
		} else {
			this.closeEditor();
		}
	}

	goToNextPage() {
		if (this.nextPageModel && ProductStateModule.productId) {
			ProductStateModule.resetHistory();

			navigate.toEditor(
				ProductStateModule.productId,
				ProductStateModule.getPageIndex(this.nextPageModel).toString(),
				{ trigger: true, replace: true },
			);
		} else {
			this.closeEditor();
		}
	}

	onSelectPhotoPosition(templatePosition: TemplatePhotoPosition) {
		this.templatePhotoPosition = templatePosition;
		this.openPhotoToolbar();
	}

	openPhotoToolbar() {
		this.showPhotoToolbar = true;

		analytics.trackEvent(
			'Open toolbar',
			{
				category: 'Button',
				label: 'Photo',
			},
		);

		upload.changeCheckMaxPhotos(false);

		if (window.glPlatform == 'native' && ProductStateModule.getPhotosSelected.length === 0) {
			if (window.nativeToWeb) {
				window.nativeToWeb.pickerDismissed = () => {
					console.log('No action required on native picker dismiss');
				};
			}

			upload.filePicker(
				Math.max(
					1,
					(this.themeModel?.maxphotos || 1) - ProductStateModule.getPhotosSelected.length,
				),
				{
					removeUnselected: false,
					showSelection: false,
				},
			);
		}
	}

	openLightBox(photoModel: PI.PhotoModel) {
		if (this.showLightBox) {
			this.activePhotoModel = photoModel;
		} else {
			this.addPhotoToPage(photoModel);
		}
	}

	redo() {
		if (this.hasRedo) {
			analytics.trackEvent(
				'Redo',
				{
					category: 'Button',
					label: 'Page',
					value: 'Editor',
				},
			);
			ProductStateModule.redoHistory();
		}
	}

	removePage() {
		if (this.offeringModel) {
			const offeringModels = AppDataModule.findOffering({
				groupid: this.offeringModel.groupid,
				typeid: this.offeringModel.typeid,
			});
			const minOfferingModel = _.min(
				offeringModels,
				(offeringModel) => offeringModel.minpages,
			);
			if (typeof minOfferingModel === 'number') {
				throw new Error(`Could not find required offeringModel with groupid ${this.offeringModel.groupid} and typeid ${this.offeringModel.typeid}`);
			}

			const pageCount = ProductStateModule.getPageCount || 0;

			if (!this.pageModel) {
				throw new Error('Missing required page model');
			} else if (pageCount > minOfferingModel.minpages) {
				const { pageModel } = this;
				const closeConfirm = this.$openConfirmDialog({
					header: {
						title: this.$t('dialogHeaderRemovePage'),
					},
					body: {
						content: (
							OfferingGroups(
								this.offeringModel.groupid,
								['PrintTypes'],
							)
								? this.$t('dialogTextRemovePrint')
								: this.$t('dialogTextRemovePage')
						),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: this.$t('dialogButtonCancel'),
								click: () => {
									closeConfirm();
								},
							},
							{
								id: 'accept',
								text: this.$t('dialogButtonRemovePageOk'),
								click: () => {
									ProductStateModule.removePage({
										pageId: pageModel.id,
									});
									closeConfirm();
								},
							},
						],
					},
				});
			} else {
				// Show refusal dialog
				const closeAlert = this.$openAlertDialog({
					header: {
						title: this.$t('dialogHeaderMinPages'),
					},
					body: {
						content: this.$t('dialogTextMinPages'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: this.$t('dialogButtonMinPagesOk'),
								click: () => {
									closeAlert();
								},
							},
						],
					},
				});
			}
		} else {
			throw new Error('Missing required offering model');
		}
	}

	/** Show the dialog with the view to change the frame around the wall decoration product */
	selectFrame() {
		analytics.trackEvent(
			'Open Picker',
			{
				category: 'Button',
				label: 'Frame',
			},
		);

		const { close: closeDialog } = this.$openDialog({
			header: {
				hasCloseButton: false,
			},
			body: {
				component: FramePickerView,
				props: {
					pageModel: this.pageModel,
				},
				listeners: {
					closeDialog: () => {
						closeDialog();
					},
				},
				styles: {
					padding: '0',
				},
			},
			maxScreenSize: true,
			styles: {
				padding: '0',
			},
			width: 750,
		});
	}

	/** Show the dialog with the view to change the size of the product */
	selectSize() {
		analytics.trackEvent(
			'Open Picker',
			{
				category: 'Button',
				label: 'Size',
			},
		);

		const { close: closeDialog } = this.$openDialog({
			header: {
				hasCloseButton: false,
			},
			body: {
				component: SizePickerView,
				props: {
					pageModel: this.pageModel,
				},
				listeners: {
					closeDialog: () => {
						closeDialog();
					},
				},
				styles: {
					padding: '0',
				},
			},
			maxScreenSize: true,
			styles: {
				padding: '0',
			},
			width: 750,
		});
	}

	resize() {
		this.viewportSize = getViewport();
	}

	selectBackground() {
		analytics.trackEvent(
			'Open Picker',
			{
				category: 'Button',
				label: 'Background',
			},
		);

		const { close: closeDialog } = this.$openDialog({
			header: {
				classes: 'picker',
				title: this.$t('pickerBackground'),
			},
			body: {
				component: BackgroundPickerView,
				props: {
					pageModel: this.pageModel,
				},
				listeners: {
					closeDialog: () => {
						closeDialog();
					},
				},
			},
			width: 1000,
		});
	}

	selectPhoto(
		photoModel: PI.PhotoModel,
		skipLightBox?: boolean,
	) {
		if (skipLightBox) {
			this.addPhotoToPage(photoModel);
		} else {
			this.activePhotoModel = photoModel;
		}
	}

	selectProductOptions() {
		analytics.trackEvent(
			'Show product options',
			{
				category: 'Button',
				label: 'ProductOptions',
			},
		);

		const { close: closeDialog } = this.$openDialog({
			header: {
				hasCloseButton: false,
				title: this.$t('dialogHeaderProductOptions'),
			},
			body: {
				component: OfferingOptionsView,
				props: {
					filterTags: ['size', 'orientation', 'frame'],
					showAfterEdit: false,
					showDuringEdit: true,
				},
			},
			footer: {
				buttons: [
					{
						id: 'accept',
						text: this.$t('buttonSave'),
						click: () => {
							closeDialog();
						},
					},
				],
			},
			width: 350,
		});
	}

	selectTemplate() {
		analytics.trackEvent(
			'Open Picker',
			{
				category: 'Button',
				label: 'Template',
			},
		);

		Theme.fetchData(
			true,
		).then(() => {
			if (!this.pageModel) {
				throw new Error('Missing required pageModel');
			}
			if (!this.pageModel.template) {
				throw new Error('Missing required template on pageModel');
			}

			const { close: closeDialog } = this.$openDialog({
				header: {
					classes: 'picker',
					hasCloseButton: Boolean(this.pageModel.templateSetId),
					title: this.$t('pickerTemplate'),
				},
				body: {
					component: TemplatePickerView,
					props: {
						pageWidth: this.pageModel.width,
						pageHeight: this.pageModel.height,
						templateSets: ThemeStateModule.getTemplateSets(
							this.pageModel.template,
							{
								marginAroundEdge: this.pageModel.templateMarginAroundEdge,
								marginBetweenPositions: this.pageModel.templateMarginBetweenPositions,
							},
						),
						templateSetId: this.pageModel.templateSetId,
					},
					listeners: {
						closeDialog: () => {
							closeDialog();
						},
						changeTemplate: (templateSetId) => {
							closeDialog();

							if (this.pageModel) {
								AppStateModule.setHeavyLoad();
								this.$openLoaderDialog();

								if (this.pageModel.customLayout) {
									ProductStateModule.changePage({
										id: this.pageModel.id,
										customLayout: false,
									});
								}

								ProductState
									.changeTemplate(
										this.pageModel,
										templateSetId,
										{
											objectTypes: ['photo'],
										},
									)
									.then(() => {
										ProductStateModule.pushHistory();
									})
									.catch(() => {
										// Swallow error: no action required
									})
									.finally(() => {
										AppStateModule.unsetHeavyLoad();
										this.$closeLoaderDialog();
									});
							}
						},
					},
				},
				width: 500,
			});
		});
	}

	selectTheme() {
		analytics.trackEvent(
			'Open Picker',
			{
				category: 'Button',
				label: 'Theme',
			},
		);

		const { close: closeDialog } = this.$openDialog({
			header: {
				classes: 'picker',
				title: this.$t('pickerTheme'),
			},
			body: {
				component: ThemePickerView,
				listeners: {
					closeDialog: () => {
						closeDialog();
					},
				},
			},
			width: 500,
		});
	}

	setPageBrowserWidth(width: number) {
		this.displayWidth = width;
	}

	shufflePhotos() {
		if (!this.pageModel) {
			throw new Error('Missing required page model');
		}

		analytics.trackEvent(
			'Shuffle photos',
			{
				category: 'Button',
			},
		);

		ProductStateModule.shufflePhotos();

		this.$openLoaderDialog();
		AppStateModule.setHeavyLoad();

		ProductState
			.changeTemplate(
				this.pageModel,
				this.pageModel.templateSetId || undefined,
				{ objectTypes: ['photo'] },
			)
			.then(
				() => {
					ProductStateModule.pushHistory();
				},
				(err) => {
					this.$openErrorDialog({
						body: {
							content: err.message,
						},
					});
				},
			)
			.finally(() => {
				AppStateModule.unsetHeavyLoad();
				this.$closeLoaderDialog();
			});
	}

	toggleBleed() {
		AppStateModule.toggleBleed();

		analytics.trackEvent(
			'Toggle bleed',
			{
				category: 'Button',
				label: this.showBleed ? 'On' : 'Off',
			},
		);

		if (AppStateModule.showBleedDialog) {
			const {
				close: closeDialog,
				api: dialogApi,
			} = this.$openDialog({
				body: {
					component: DialogCheckboxComponent,
					props: {
						checkboxes: {
							id: 'showBleedInfo',
							text: this.$t('dialogCheckBoxBleedMargin'),
						},
						content: this.$t('dialogTextBleedMargin'),
					},
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: this.$t('dialogButtonOk'),
							click: () => {
								closeDialog();
							},
						},
					],
				},
				listeners: {
					close: () => {
						const isChecked = dialogApi.bodyComponent()?.getValue('showBleedInfo');

						if (isChecked) {
							AppStateModule.disableBleedDialog();
						}
					},
				},
			});
		}
	}

	undo() {
		if (this.hasUndo) {
			analytics.trackEvent(
				'Undo',
				{
					category: 'Button',
					label: 'Page',
					value: 'Editor',
				},
			);
			ProductStateModule.undoHistory();
		}
	}
}
