var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sizePickerView"},[_c('div',{staticClass:"closer"},[_c('span',{staticClass:"icon",on:{"click":_vm.closePicker}},[_vm._v("\n\t\t\tclose\n\t\t")])]),_vm._v(" "),_c('div',{staticClass:"header"},[_c('span',[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('views.sizePicker.header'))+"\n\t\t")])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{ref:"twodViewWrapper",staticClass:"twodViewWrapper"},[(_vm.model2D)?_c('TwoDView',{staticClass:"TwoDViewStyle",attrs:{"box-width":_vm.boxWidth,"box-height":_vm.boxHeight,"model2d-model":_vm.model2D,"offering-model":_vm.selectedOfferingModel,"page-model":_vm.pageModel}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"orientationAndSizeOptions"},[_c('div',{staticClass:"orientationText"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t(
						'orientation'
					))+"\n\t\t\t")]),_vm._v(" "),_c('ul',{staticClass:"orientationOptions"},_vm._l((_vm.offeringOptionOrientationValueModels),function(orientationValueModel){return _c('li',{key:orientationValueModel.id,class:[
						'orientationOption',
						{ selected: _vm.selectedOrientationValueModel && orientationValueModel.id === _vm.selectedOrientationValueModel.id }
					],on:{"click":function($event){return _vm.selectOrientationValueModel(orientationValueModel)}}},[_c('span',{staticClass:"label"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t(
								("offeringOptionValues:" + (orientationValueModel.id)),
								orientationValueModel.value
							))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('div',{class:((orientationValueModel.tag) + '-style')})])}),0),_vm._v(" "),_c('div',{staticClass:"sizeText"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t(
						'size'
					))+"\n\t\t\t")]),_vm._v(" "),_c('ul',{staticClass:"sizeOptions"},_vm._l((_vm.offeringOptionSizeValueModels),function(sizeValueModel){return _c('li',{key:sizeValueModel.id,class:[
						'sizeOption',
						{ selected: _vm.selectedSizeValueModel && sizeValueModel.id === _vm.selectedSizeValueModel.id }
					],on:{"click":function($event){return _vm.selectSizeValueModel(sizeValueModel)}}},[_c('span',{staticClass:"label"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t(
								("offeringOptionValues:" + (sizeValueModel.id)),
								sizeValueModel.value
							))+"\n\t\t\t\t\t")])])}),0),_vm._v(" "),_c('div',{staticClass:"priceContainer"},[_c('PriceView',{attrs:{"cents":_vm.selectedOfferingPrice}})],1),_vm._v(" "),_c('div',{staticClass:"buttonContainer"},[_c('button',{staticClass:"actionbutton altColor full",on:{"click":_vm.saveChanges}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('views.sizePicker.saveButton'))+"\n\t\t\t\t")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }