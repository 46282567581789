var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:['objectToolbarItem', {
		dropdown: _vm.buttonModel.dropdown,
		photoEffect: _vm.buttonModel.type == 'photoeffect',
		pressed: _vm.buttonModel.pressed,
		inverse: _vm.buttonModel.inverse,
	}],attrs:{"v-bind":_vm.buttonModel.attributes},on:{"click":function($event){$event.stopPropagation();return _vm.pressButton.apply(null, arguments)}}},[(_vm.buttonModel.type == 'objecttoolbar')?_c('span',[(_vm.buttonModel.faClass || _vm.buttonModel.miClass)?_c('span',{staticClass:"icon-stack"},[_c('i',{staticClass:"material-icons circle",style:({ color: _vm.buttonColor })},[_vm._v("\n\t\t\t\tlens\n\t\t\t")]),_vm._v(" "),(_vm.buttonModel.faClass)?_c('i',{class:[
					_vm.buttonModel.faClass,
					'icon' ]}):(_vm.buttonModel.miClass)?_c('i',{class:[
					'material-icons',
					'icon' ]},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.buttonModel.miClass)+"\n\t\t\t")]):_vm._e()]):_c('img',{attrs:{"src":_vm.buttonModel.icon}}),_vm._v(" "),(_vm.buttonModel.subscript)?_c('br'):_vm._e(),_vm._v(" "),(_vm.buttonModel.subscript)?_c('span',{staticClass:"subscript"},[_vm._v("\n\t\t\t"+_vm._s(_vm.buttonModel.subscript)+"\n\t\t")]):_vm._e()]):(_vm.buttonModel.type == 'color')?_c('span',[_c('i',{staticClass:"fas fa-circle",style:({ color: _vm.buttonModel.color })})]):(_vm.buttonModel.type == 'photoeffect')?_c('span',[_c('img',{attrs:{"src":_vm.buttonModel.thumbnail}}),_vm._v(" "),(_vm.buttonModel.subscript)?_c('br'):_vm._e(),_vm._v(" "),(_vm.buttonModel.subscript)?_c('span',{staticClass:"subscript"},[_vm._v("\n\t\t\t"+_vm._s(_vm.buttonModel.subscript)+"\n\t\t")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }