import './defines';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import { LogoBackgroundDetectedPageObjectModel } from 'interfaces/app';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'LogoBackgroundDetectedView',
})
export default class LogoBackgroundDetectedView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the object model in which the logo background was detected and could be removed',
		required: true,
		schema: 'LogoBackgroundDetectedPageObjectModel',
		type: Object,
	})
	public readonly objectModel!: LogoBackgroundDetectedPageObjectModel;

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		return {
			'--svg-background-color': this.objectModel._vectorColors?.background?.color || '#ffffff',
		};
	}

	protected get svgContentAttributes(): Record<string, string> {
		if (!this.objectModel._vectorSVG) {
			return {};
		}

		const temporaryDivElement = document.createElement('div');
		temporaryDivElement.innerHTML = this.objectModel._vectorSVG;
		const svgElement = temporaryDivElement.firstChild as SVGElement;

		return Object
			.keys(svgElement.attributes)
			.reduce(
				(attributes, key) => {
					const attribute = svgElement.attributes.item(Number(key));

					if (attribute) {
						attributes[attribute.name] = attribute.value;
					}

					return attributes;
				},
				{} as Record<string, string>,
			);
	}

	protected get svgContentWithBackgroundHTML(): string {
		if (!this.objectModel._vectorSVG) {
			return '';
		}

		const temporaryDivElement = document.createElement('div');
		temporaryDivElement.innerHTML = this.objectModel._vectorSVG;

		return (temporaryDivElement.firstChild as SVGElement).innerHTML;
	}

	protected get svgContentWithoutBackgroundHTML(): string {
		if (
			!this.objectModel._vectorSVG
			|| !this.objectModel._vectorColors
		) {
			return '';
		}

		const { _vectorColors } = this.objectModel;
		const temporaryDivElement = document.createElement('div');

		if (!_vectorColors.background) {
			temporaryDivElement.innerHTML = this.objectModel._vectorSVG;
		} else {
			temporaryDivElement.innerHTML = svgUtils.replaceColors(
				this.objectModel._vectorSVG,
				_vectorColors,
				{
					color: _vectorColors.background.color,
					replace: 'transparent',
				},
			);
		}

		return (temporaryDivElement.firstChild as SVGElement).innerHTML;
	}
}
