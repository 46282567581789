import {
	Vue,
	Component,
	Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { ThemeDataModule, ProductStateModule } from '../store/index';

@Component
export default class ThemePickerItemView extends Vue {
	@Prop({ required: true, type: Object })
	readonly themeModel!: DB.ThemeModel;

	private isVisible = false;

	get selectedTheme() {
		const productModel = ProductStateModule.getProduct;
		return productModel
			? productModel.themeid
			: null;
	}

	get themeName() {
		return ThemeDataModule.themeDescription(this.themeModel.id);
	}

	imageLoaded() {
		this.$emit('imageLoaded');
	}

	selectTheme() {
		this.$emit(
			'selectTheme',
			this.themeModel,
		);
	}

	visibilityChanged(isVisible: boolean) {
		this.isVisible = isVisible;
	}
}
