import {
	fullViewport,
	Viewport,
} from './viewport';

export {
	type GetViewportWatchCallback,
	type Viewport,
	watch,
} from './viewport';

export default function getViewport(): Viewport {
	return fullViewport;
}
