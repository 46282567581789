import './defines';
import PantoneMatchingSystemComponent from 'components/pantone-matching-system';
import { mobile as mobileTools } from 'tools';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'PantoneMatchingSystemHeaderComponent',
})
export default class PantoneMatchingSystemHeaderComponent extends Vue.extend(Template) {
	private infoDialogClose?: () => void;

	protected beforeDestroy(): void {
		this.infoDialogClose?.();
	}

	protected onCloseClick(): void {
		this.$emit('close');
	}

	protected onInfoClick(): void {
		this.infoDialogClose = this.$openDialogNew({
			header: {
				title: this.$t(`components.pantoneMatchingSystemHeader.infoDialog.title.${!mobileTools.isMobile ? 'desktop' : 'mobile'}`),
			},
			body: {
				component: PantoneMatchingSystemComponent,
			},
			footer: {
				buttons: [
					{
						id: 'accept',
						text: this.$t('components.pantoneMatchingSystemHeader.infoDialog.buttons.accept'),
						fontSize: (
							!mobileTools.isMobile
								? undefined
								: '--font-size-xs'
						),
						click: () => this.infoDialogClose?.(),
					},
				],
			},
			listeners: {
				close: () => {
					this.infoDialogClose = undefined;
				},
			},
			padding: (
				!mobileTools.isMobile
					? [24, 32]
					: [16, 20]
			),
			width: (
				!mobileTools.isMobile
					? 624
					: undefined
			),
		}).close;
	}
}
