import * as DB from 'interfaces/database';
import * as DialogService from 'services/dialog';
import { ERRORS_OFFLINE } from 'settings/errors';
import {
	AppStateModule,
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
} from 'store';
import _ from 'underscore';

export default class Theme {
	static setup(
		themeid: DB.ThemeModel['id'],
	): Promise<void> {
		const { themeModel } = ThemeStateModule;
		if (!themeid) {
			return Promise.reject(
				new Error('Missing theme id'),
			);
		}

		if (themeModel?.id === themeid) {
			return Promise.resolve();
		}

		ThemeStateModule.reset();

		if (ThemeDataModule.whereThemePageLink({ themeid }).length > 0
			&& ThemeDataModule.themeLoaded(themeid)
		) {
			ThemeStateModule.setupData({ themeid });
			return Promise.resolve();
		}

		return Theme
			.fetch(themeid)
			.then(() => {
				ThemeStateModule.setupData({ themeid });
			});
	}

	static replaceTags(
		model: {
			[key: string]: any;
		},
		objReplace?: {
			property: string;
			value: string;
		},
	) {
		let patt: RegExp;
		if (objReplace) {
			patt = new RegExp(`<% ${objReplace.property} %>`);
		} else {
			patt = new RegExp('<% .+? %>');
		}

		// Search and replace smartTags
		const pairs = _.pairs(model);
		_.each(
			pairs,
			(pair) => {
				const pairkey = pair[0];
				let pairvalue = pair[1];
				if (pair.length == 2 && _.isString(pairvalue)) {
					if (patt.test(pairvalue)) {
						if (!objReplace) {
							const productModel = ProductStateModule.getProduct;
							if (productModel) {
								const themevariant = ThemeDataModule.findWhereVariant({
									themeid: productModel.themeid,
									tag: pairvalue.substr(
										2,
										pairvalue.length - 4,
									).trim(),
								});

								if (themevariant) {
									pairvalue = pairvalue.replace(
										patt,
										themevariant.value,
									);
									model[pairkey] = pairvalue;
								} else {
									model[pairkey] = null;
								}
							} else {
								model[pairkey] = null;
							}
						} else if (objReplace.value) {
							model[pairkey] = objReplace.value;
						}
					}
				}
			},
		);
	}

	static fetchData(showLoader: boolean): Promise<void> {
		if (ThemeDataModule.getFetched) {
			return Promise.resolve();
		}

		if (!AppStateModule.online) {
			return Promise.reject(
				new Error(ERRORS_OFFLINE),
			);
		}

		if (showLoader) {
			DialogService.openLoaderDialog();
		}

		// Fetch theme data
		return ThemeDataModule
			.fetch()
			.finally(() => {
				if (showLoader) {
					DialogService.closeLoaderDialog();
				}
			});
	}

	static fetch(
		themeid: DB.ThemeModel['id'],
	): Promise<void> {
		if (!AppStateModule.online) {
			// Theme not available
			return Promise.reject(
				new Error(ERRORS_OFFLINE),
			);
		}

		// We are online, fetch fresh data from server
		const closeLoader = DialogService.openLoaderDialog();

		return ThemeDataModule.fetchModel({
			themeid,
		}).then(() => {
			closeLoader();
			return Promise.resolve();
		}).catch((err) => {
			closeLoader();
			ThemeStateModule.reset();

			return Promise.reject(err);
		});
	}
}
