import maxCanvasSize from './max-canvas-size';

export interface CanvasSize {
	height: number;
	width: number;
}

export default function getCanvasSize(
	width: number,
	height: number,
): CanvasSize {
	const maximumPixels = maxCanvasSize();
	const requiredPixels = width * height;

	if (requiredPixels <= maximumPixels) {
		return {
			height,
			width,
		};
	}

	const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);

	return {
		height: Math.floor(height * scalar),
		width: Math.floor(width * scalar),
	};
}
