import './defines';
import connector from 'controllers/connector';
import navigate from 'controllers/navigate';
import { ChannelModel } from 'interfaces/app';
import { OfferingGroups } from 'settings/offerings';
import { mobile as mobileTools } from 'tools';
import {
	ChannelsModule,
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class ImportSelectView extends Vue.extend(Template) {
	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly showSkipButton!: boolean;

	protected isMobile = mobileTools.isMobile;

	protected get isLogoProduct(): boolean {
		if (!this.offeringModel) {
			return false;
		}

		return this.offeringModel.type === 'logo';
	}

	protected get skipButtonText(): string | null {
		if (!this.showSkipButton) {
			return null;
		}

		if (this.productModel) {
			if (OfferingGroups(
				this.productModel.group,
				['BookTypes'],
			)) {
				if (this.photoCount === 0 && this.minPhotos === 0) {
					return this.$t('buttonStartEmptyBook');
				}
			}

			if (this.photoCount > 0 && this.photoCount >= this.minPhotos) {
				return this.$t('buttonEditProceed');
			}
		}

		return null;
	}

	protected get maxPhotos() {
		return !this.themeModel || _.isNull(this.themeModel.maxphotos)
			? 200
			: this.themeModel.maxphotos;
	}

	private get minPhotos() {
		return this.themeModel && this.themeModel.minphotos !== null
			? this.themeModel.minphotos
			: 1;
	}

	protected get offeringModel() {
		return ProductStateModule.getOffering;
	}

	private get photoCount() {
		return ProductStateModule.getPhotosSelected.length;
	}

	protected get photoSources() {
		if (window.glPlatform == 'native') {
			return [];
		}

		return ChannelsModule.collection.filter(
			(channelModel) => (channelModel.id === 'app' || connector.isSupported(channelModel.id))
				&& Boolean(channelModel.albums || channelModel.photos || channelModel.folders)
				&& (channelModel.id !== 'qr' || !this.isMobile),
		);
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get selectionCount() {
		return ProductStateModule.getPhotosSelected.length;
	}

	private get themeModel() {
		return this.productModel
			? ThemeDataModule.getTheme(this.productModel.themeid)
			: undefined;
	}

	protected clickChannel(channelId: ChannelModel['id']) {
		this.$emit(
			'clickChannel',
			channelId,
		);
	}

	protected skipSelection() {
		if (!this.productModel) {
			throw new Error('Missing required product model');
		}

		if (this.photoCount > 0 && window.glPlatform != 'native') {
			navigate.toLibrary(this.productModel.id);
		} else {
			navigate.goForward('selection');
		}
	}
}
