import objectHash from 'object-hash';
import { GridCell } from 'interfaces/app';
import intersect from 'tools/intersect';
import GridCreator, { Grid } from 'classes/grid-creator';
import getPermutations from 'tools/get-permutations';

interface ExtendedGrid extends Grid {
	totalCropArea: number;
	insertPhotoOrder: PhotoData[];
}

interface DynamicTemplateData {
	id: string;
	positions: GeneratedPositionData[],
}

interface ExtendedDynamicTemplateData extends DynamicTemplateData {
	orderedPhotoModels: PhotoData[],
}

export const DEFAULT_DYNAMIC_TEMPLATE_ID = 'default-dynamic-template';

export interface GeneratedPositionData {
	id: string;
	x: number;
	y: number;
	z: number;
	width: number;
	height: number;
}

interface CropData {
	lostArea: number;
	cropX: number;
	cropY: number;
	cropWidth: number;
	cropHeight: number;
}

interface PhotoAreaData {
	x: number;
	y: number;
	width: number;
	height: number;
	angle: number;
	borderwidth: number;
	autoRotate: boolean;
}

interface TextAreaData {
	x: number;
	y: number;
	width: number;
	height: number;
	angle: number;
	borderwidth: number;
}

export interface PhotoData {
	id: number | string;
	// Width of the photo in pixels
	width: number;
	// Height of the photo in pixels
	height: number;
	// Bounding box for detected faces in the photo
	facebox?: {
		// Column number of the beginning of the bounding face box
		x: number;
		// Row number of the beginning of the bounding face box
		y: number;
		// Width of the bounding face box
		width: number;
		// Height of the bounding face box
		height: number;
	};
	// Caption for the photo
	caption?: string;
}

export interface TemplateInputData {
	// Unique id for the template model in the database
	id: number;
	// Width of the template in pixels
	width: number;
	// Height of the template in pixels
	height: number;
	// Bleed margin of the template in pixels
	bleedMargin: number;
	// Margin around the grid in pixels
	marginAroundEdge?: number,
	// Number of pixels between different grid positions
	marginBetweenPositions?: number,
}

export default class Template {
	/**
	 * Helper function to calculate the lost area of a photo when applying a crop
	 * based on the cell dimensions
	 *
	 * @param gridCell Grid cell the photo should be placed inside
	 * @param photoData Properties of the photo that will be placed inside the grid cell
	 */
	private static calculateCropArea(
		gridCell: GridCell,
		photoData: PhotoData,
	): CropData {
		const photoRatio = photoData.width / photoData.height;
		const cellRatio = gridCell.w / gridCell.h;
		let cropWidth: number;
		let cropHeight: number;
		let cropX: number;
		let cropY: number;

		if (photoRatio > cellRatio) {
			cropWidth = gridCell.w;
			cropHeight = gridCell.w / photoRatio;
			cropX = 0;
			cropY = (gridCell.h - cropHeight) / 2;
		} else {
			cropWidth = gridCell.h * photoRatio;
			cropHeight = gridCell.h;
			cropX = (gridCell.w - cropWidth) / 2;
			cropY = 0;
		}

		return {
			lostArea: 2 * cropX * gridCell.h + 2 * cropY * gridCell.w,
			cropWidth,
			cropHeight,
			cropX,
			cropY,
		};
	}

	/**
	 * Helper function to turn the grid into a template
	 *
	 * @param grid Grid that needs to be turned into a template
	 */
	private static convertGridToTemplate(
		grid: Grid,
	): DynamicTemplateData {
		const positions = grid.cells.map(
			(gridCell, i) => {
				const properties = {
					x: gridCell.x,
					y: gridCell.y,
					width: gridCell.w,
					height: gridCell.h,
				};
				return {
					...properties,
					id: grid.id === DEFAULT_DYNAMIC_TEMPLATE_ID
						? DEFAULT_DYNAMIC_TEMPLATE_ID
						: objectHash(properties),
					z: i,
				};
			},
		);

		const data = {
			positions,
		};

		return {
			id: data.positions.map((position) => position.id).join('_'),
			...data,
		};
	}

	/**
	 * Calculate all the properties needed to add a photo object to a project's page
	 *
	 * @param photoAreaData Object with the position data where the photo should be placed
	 * @param photoData Object with data for the photo to be placed inside the photoArea
	 * @param textAreaData Can be used to indicate an area where the photo caption will be placed
	 * @param options Configuration object to use
	 * @param options.fit (Optional) Flag to set filling method to use "fit" (instead of "fill") so that photo is placed uncropped
	 * @param options.forceRotate (Optional) Force rotating the photo 90 degrees when placing inside the photoArea
	 * @param options.resizing Configuration object for resizing the photo
	 * @param options.resizing.maxScale Maximum times the photo is allowed to be enlarged (without upscaling)
	 * @param options.resizing.recommendedMaxScale Recommended maximum times the photo can be enlarged (without upscaling)
	 * @param options.upscaling (Optional) Configuration object for upscaling the photo
	 * @param options.upscaling.allowed Flag to indicate if upscaling is allowed
	 * @param options.upscaling.maxOutputSize Maximum output size of the upscaling service (in pixels) - default 128 megapixels
	 * @param options.upscaling.recommendedMaxScale Recommended maximum times the photo can be upscaled - default 8
	 */
	public static fitPhotoInRectangle(
		photoAreaData: PhotoAreaData,
		photoData: PhotoData,
		textAreaData: TextAreaData | null | undefined,
		options: {
			fit?: boolean;
			forceRotate?: boolean;
			resizing: {
				// Value should be set to offeringModel.configdpi / minimumDpi
				maxScale: number;
				// Value should be set to (offeringModel.configdpi / offeringModel.qualitydpi)
				recommendedMaxScale: number;
			}
			upscaling?: {
				allowed?: boolean;
				maxOutputSize?: number;
				recommendedMaxScale?: number;
			}
		},
	) {
		// Clone data (so we do not mutate the original object)
		const newPositionData: PhotoAreaData = JSON.parse(
			JSON.stringify(photoAreaData),
		);

		let positionWidth = newPositionData.width;
		let positionHeight = newPositionData.height;
		let positionY = newPositionData.y;
		let rotation = 0;

		const isPortrait = photoData.height > photoData.width;

		let maxPhotoSizeFactor = options.resizing.maxScale;

		if (options.upscaling?.allowed) {
			// When upscaling is allowed,
			// the maximum output will be defined by the maximum output size of the upscaling service
			// Adjust dpiScale based on maximum output upscaling service
			// Defaults to 128 megapixels
			const maxSize = options.upscaling?.maxOutputSize || 128 * 1000000;
			const maxUpscalingFactor = Math.sqrt(
				maxSize / (photoData.width * photoData.height),
			);
			maxPhotoSizeFactor = Math.min(
				maxUpscalingFactor,
				options.upscaling?.recommendedMaxScale || 8,
			) * options.resizing.recommendedMaxScale;
		}

		const maxPhotoWidth = photoData.width * maxPhotoSizeFactor;
		const maxPhotoHeight = photoData.height * maxPhotoSizeFactor;

		/**
		 * If position supports 'auto rotate',
		 * and photo is portrait,
		 * and height of photo exceeds height of position:
		 *
		 * Rotate position with 90 degrees
		 */

		if (options.forceRotate) {
			newPositionData.angle = (
				newPositionData.angle === 270
					? 0
					: newPositionData.angle + 90
			);
		} else if (
			newPositionData.autoRotate
			&& (
				(positionWidth > positionHeight && maxPhotoHeight > maxPhotoWidth
					&& maxPhotoHeight > positionHeight
				)
				|| (positionHeight > positionWidth
					&& maxPhotoWidth > maxPhotoHeight
					&& maxPhotoWidth > positionWidth
				)
			)
		) {
			newPositionData.angle = 90;
		}

		rotation = newPositionData.angle;

		// When the option 'fit' is flagged,
		// we need to make sure the photo fits completely inside the position (no crop)
		if (
			options.fit
			&& (
				Math.max(
					positionWidth,
					positionHeight,
				) / Math.min(
					positionWidth,
					positionHeight,
				)
			) !== (
				Math.max(
					photoData.width,
					photoData.height,
				) / Math.min(
					photoData.width,
					photoData.height,
				)
			)
		) {
			if (rotation === 90 || rotation === 270) {
				// We compare photo width with position height
				if (positionWidth / positionHeight > photoData.height / photoData.width) {
					positionWidth = (photoData.height / photoData.width) * positionHeight;
				} else {
					positionHeight = (photoData.width / photoData.height) * positionWidth;
				}
			} else if (positionWidth / positionHeight > photoData.width / photoData.height) {
				positionWidth = (photoData.width / photoData.height) * positionHeight;
			} else {
				positionHeight = (photoData.height / photoData.width) * positionWidth;
			}
		}

		if (newPositionData.borderwidth && newPositionData.borderwidth > 0) {
			positionWidth -= 2 * newPositionData.borderwidth;
			positionHeight -= 2 * newPositionData.borderwidth;
			positionY += newPositionData.borderwidth;
		}

		let photoScaleW = maxPhotoWidth / positionWidth;
		let photoScaleH = maxPhotoHeight / positionHeight;

		if (
			rotation === 90
			|| rotation === 270
		) {
			photoScaleW = maxPhotoWidth / positionHeight;
			photoScaleH = maxPhotoHeight / positionWidth;
		}

		const photoScale = Math.max(
			1,
			Math.min(
				photoScaleW,
				photoScaleH,
			),
		);
		const photoWidth = maxPhotoWidth / photoScale;
		const photoHeight = maxPhotoHeight / photoScale;

		/**
		 * If fillTitle is set,
		 * and stock item has title,
		 * and position has title:
		 *
		 * Fill template title
		 */

		// store y-axis compensation for photo caption, so we can apply it to the photo position later
		let captionCompensationYaxis = 0;

		if (
			textAreaData
			&& photoData.caption
			&& photoData.caption.length > 0
		) {
			// If title position overlaps with photo position: cut photo position accordingly
			if (intersect(
				{
					x: textAreaData.x,
					y: textAreaData.y,
					width: textAreaData.width,
					height: textAreaData.height,
					rotation: textAreaData.angle,
					borderWidth: textAreaData.borderwidth,
				},
				{
					x: photoAreaData.x,
					y: photoAreaData.y,
					width: photoAreaData.width,
					height: photoAreaData.height,
					rotation: photoAreaData.angle,
					borderWidth: photoAreaData.borderwidth,
				},
			)) {
				captionCompensationYaxis = (positionY + positionHeight)
					- textAreaData.y;
				positionHeight -= captionCompensationYaxis;
			}
		}

		/**
		 * Calculate photo properties:
		 * width
		 * height
		 * x_axis
		 * y_axis
		 * cropwidth
		 * cropheight
		 * cropx
		 * cropy
		 */

		let objectWidth = Math.min(
			positionWidth,
			photoWidth,
		);
		let objectHeight = Math.min(
			positionHeight,
			photoHeight,
		);

		if (
			rotation === 90
			|| rotation === 270
		) {
			objectWidth = Math.min(
				positionHeight,
				photoWidth,
			);
			objectHeight = Math.min(
				positionWidth,
				photoHeight,
			);
		}

		const objectX = newPositionData.x
			+ (newPositionData.width - objectWidth) / 2;
		const objectY = newPositionData.y
			+ ((newPositionData.height - objectHeight - captionCompensationYaxis) / 2);

		let cropWidth = Math.min(
			photoData.width,
			(maxPhotoWidth - (photoWidth - positionWidth) * photoScale) / maxPhotoSizeFactor,
		);
		let cropHeight = Math.min(
			photoData.height,
			(maxPhotoHeight - (photoHeight - positionHeight) * photoScale) / maxPhotoSizeFactor,
		);

		if (
			rotation === 90
			|| rotation === 270
		) {
			cropWidth = Math.min(
				photoData.width,
				(maxPhotoWidth - (photoWidth - positionHeight) * photoScale) / maxPhotoSizeFactor,
			);
			cropHeight = Math.min(
				photoData.height,
				(maxPhotoHeight - (photoHeight - positionWidth) * photoScale) / maxPhotoSizeFactor,
			);
		}

		let cropX = (photoData.width - cropWidth) / 2;
		// Portrait photos are cropped above the middle to avoid cutting of faces at the top
		let cropY = isPortrait
			? (photoData.height - cropHeight) / 4
			: (photoData.height - cropHeight) / 2;

		if (
			photoData.facebox
			&& typeof photoData.facebox.x === 'number'
			&& typeof photoData.facebox.y === 'number'
			&& typeof photoData.facebox.width === 'number'
			&& typeof photoData.facebox.height === 'number'
		) {
			// Faces bounding box is set, calculate adjusted cropping to include all faces in crop

			// Add 10% slack to all sides of faces bounding box
			const fcx = photoData.facebox.x - 0.1 * photoData.facebox.width;
			const fcy = photoData.facebox.y - 0.1 * photoData.facebox.height;
			const fcw = Math.min(
				photoData.width,
				photoData.facebox.width * 1.2,
			);
			const fch = Math.min(
				photoData.height,
				photoData.facebox.height * 1.2,
			);

			if (
				fcx < cropX
				|| (fcx + fcw) > (cropX + cropWidth)
			) {
				// Faces box is outside of current cropping, so adjust

				// Calculate the adjustement
				const x1delta = fcx - cropX;
				const x2delta = (fcx + fcw) - (cropX + cropWidth);

				let xdelta = 0;

				if (
					(x1delta < 0 && x2delta > 0)
					|| (x1delta > 0 && x2delta < 0)
				) {
					// / One is negative, other is positive
					xdelta = (x1delta + x2delta) / 2;
				} else {
					xdelta = Math.abs(x1delta) < Math.abs(x2delta) ? x1delta : x2delta;
				}

				xdelta = Math.min(
					photoData.width - cropWidth,
					cropX,
					Math.max(
						-cropX,
						xdelta,
					),
				);

				cropX += xdelta;
			}

			if (
				fcy < cropY
				|| (fcy + fch) > (cropY + cropHeight)
			) {
				// Faces box is outside of current cropping, so adjust

				// Calculate the adjustement
				const y1delta = fcy - cropY;
				const y2delta = (fcy + fch) - (cropY + cropHeight);

				let ydelta = 0;

				if ((y1delta < 0 && y2delta > 0) || (y1delta > 0 && y2delta < 0)) {
					// / One is negative, other is positive
					ydelta = (y1delta + y2delta) / 2;
				} else {
					ydelta = Math.abs(y1delta) < Math.abs(y2delta) ? y1delta : y2delta;
				}

				ydelta = Math.min(
					photoData.height - cropHeight - cropY,
					Math.max(
						-cropY,
						ydelta,
					),
				);

				cropY += ydelta;
			}
		}

		// Correct for illegal negative values
		cropX = Math.max(
			0,
			cropX,
		);
		cropY = Math.max(
			0,
			cropY,
		);

		/**
		 * Check to make sure the values are correct
		 * cropping values could be corrupt because of rounding issues
		 */

		cropWidth = parseFloat(cropWidth.toFixed(2));
		cropHeight = parseFloat(cropHeight.toFixed(2));
		cropX = parseFloat(cropX.toFixed(2));
		cropY = parseFloat(cropY.toFixed(2));

		cropX = Math.min(
			cropX,
			photoData.width - cropX,
		);
		cropY = Math.min(
			cropY,
			photoData.height - cropY,
		);

		return {
			x: parseFloat(objectX.toFixed(2)),
			y: parseFloat(objectY.toFixed(2)),
			width: parseFloat(objectWidth.toFixed(2)),
			height: parseFloat(objectHeight.toFixed(2)),
			cropWidth,
			cropHeight,
			cropX,
			cropY,
			rotation,
			photoScale: photoWidth / photoData.width,
		};
	}

	/**
	 * Generate a list of templates with positions for the given template data and position quantities
	 *
	 * @param templateData Properties of the template we will generate the positions for
	 * @param positionQuantities Array with the number of positions we want to generate
	 */
	public static getDynamicTemplates(
		templateData: TemplateInputData,
		positionQuantities: number[] = [1, 2, 3, 4, 5],
	): DynamicTemplateData[] {
		const gridCollections: Grid[][] = [];

		if (positionQuantities.indexOf(1) >= 0) {
			// Add one position that covers the entire page, including the bleed margin
			gridCollections.push([{
				id: DEFAULT_DYNAMIC_TEMPLATE_ID,
				cells: [{
					x: -templateData.bleedMargin,
					y: -templateData.bleedMargin,
					w: templateData.width + (templateData.bleedMargin * 2),
					h: templateData.height + (templateData.bleedMargin * 2),
				}],
			}]);
		}

		const gridCreator = new GridCreator(
			templateData.width,
			templateData.height,
			{
				marginAroundEdge: templateData.marginAroundEdge,
				marginBetweenPositions: templateData.marginBetweenPositions,
			},
		);

		positionQuantities.forEach((quantity) => {
			const gridCollection: Grid[] = gridCreator.generateGridCollection(
				quantity,
			);
			gridCollections.push(
				gridCollection,
			);
		});

		const returnData: DynamicTemplateData[] = [];
		gridCollections.forEach((gridCollection) => {
			const dynamicTemplates = gridCollection.map((grid) => this.convertGridToTemplate(
				grid,
			));
			returnData.push(
				...dynamicTemplates,
			);
		});

		return returnData;
	}

	/**
	 * Get an automatically generated template that best fits the submitted photos
	 * @param templateData Properties for the template to be generated
	 * @param photoData Array of photos to be placed inside the template
	 * @param randomness The amount of randomness to apply to finding the best fitting grid
	 * 0 = no randomness, 1 = full randomness
	 * @return GeneratedTemplateData - Returned data about the generated template
	 * @return GeneratedTemplateData.positions - Collection of returned template positions
	 * @return GeneratedTemplateData.orderedPhotoModels - Collection of photo models in the order they should be inserted
	 */
	public static getTemplatePositions(
		templateData: TemplateInputData,
		photoData: PhotoData[],
		randomness = 0,
	): ExtendedDynamicTemplateData {
		const gridCreator = new GridCreator(
			templateData.width,
			templateData.height,
			{
				marginAroundEdge: templateData.marginAroundEdge,
				marginBetweenPositions: templateData.marginBetweenPositions,
			},
		);
		const gridCollection: ExtendedGrid[] = gridCreator.generateGridCollection(
			Math.max(
				1,
				photoData.length,
			),
		).map((grid) => ({
			...grid,
			totalCropArea: 0,
			insertPhotoOrder: [],
		}));

		if (photoData.length > 0) {
			const photoModelsPermutations = getPermutations(photoData);
			gridCollection.forEach((grid, gridIndex) => {
				let smallestTotalCropArea = 100000000000000000;
				let permutationIndex = 0;

				photoModelsPermutations.forEach((permutation, p) => {
					const arrCropData: CropData[] = [];
					grid.cells.forEach((cell, index) => {
						const cropData = Template.calculateCropArea(
							cell,
							permutation[index],
						);
						arrCropData.push(cropData);
					});

					const totalCropArea = arrCropData.reduce(
						(total, cropData) => total + cropData.lostArea,
						0,
					);
					if (totalCropArea < smallestTotalCropArea) {
						smallestTotalCropArea = totalCropArea;
						permutationIndex = p;
					}
				});

				gridCollection[gridIndex].totalCropArea = Math.round(smallestTotalCropArea);
				gridCollection[gridIndex].insertPhotoOrder = photoModelsPermutations[permutationIndex];
			});

			gridCollection.sort(
				(a, b) => a.totalCropArea - b.totalCropArea,
			);
		}

		const selectIndex = Math.floor(
			Math.random() * (gridCollection.length * randomness),
		);
		const dynamicTempateData = Template.convertGridToTemplate(
			gridCollection[selectIndex],
		);

		return {
			...dynamicTempateData,
			orderedPhotoModels: gridCollection[selectIndex].insertPhotoOrder,
		};
	}
}
