import './defines';
import {
	TabSwitchTab,
	TabSwitchTabs,
} from 'interfaces/app';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'TabSwitchComponent',
})
export default class TabSwitchComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the value of the tab switch (value of the selected tab)',
			type: [Number, String],
		},
	)
	public readonly value?: number | string;

	@Prop({
		default: false,
		description: 'Indicates if the tabs should hidden (only the content would be visible)',
		type: Boolean,
	})
	public readonly hideTabs?: boolean;

	@Prop({
		description: 'Defines the tabs of the tab switch',
		required: true,
		schema: 'TabSwitchTabs',
	})
	public readonly tabs!: TabSwitchTabs;

	protected get tabClasses(): (tab: TabSwitchTab) => Record<string, boolean> {
		return (tab) => ({
			'tab-switch-component-tab-active': this.isActive(tab),
		});
	}

	private internalValue: number | string | null = null;

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = (
			this.value
			?? null
		);
	}

	private isActive(tab: TabSwitchTab): boolean {
		return this.internalValue === tab.id;
	}

	protected onTabClick(tab: TabSwitchTab): void {
		if (this.internalValue !== tab.id) {
			this.internalValue = tab.id;
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}
}
