import merge from 'deepmerge';
import * as amplitude from '@amplitude/analytics-browser';
import * as DB from 'interfaces/database';
import {
	PricingObject,
	AnalyticsSubController,
	// AnalyticsEcommerceProduct,
	AnalyticsUserProperties,
	DeviceDetails,
	AnalyticsProjectReadyProperties,
	AnalyticsCreateProjectProperties,
	AnalyticsOpenProjectProperties,
} from 'interfaces/app';
import {
	UserModule,
	AppDataModule,
	ProductsModule,
	ConfigModule,
	ExternalUsersModule,
} from 'store/index';

export default class AmplitudeController implements AnalyticsSubController {
	private userProperties: AnalyticsUserProperties = {};

	public init(
		deviceDetails: DeviceDetails,
	) {
		if (ConfigModule['analytics.amplitude.id']
			&& ConfigModule['analytics.amplitude.enabled']
		) {
			amplitude.init(
				ConfigModule['analytics.amplitude.id'],
				UserModule.id ? UserModule.id.toString() : undefined,
				{
					identityStorage: deviceDetails.platform === 'Native' && deviceDetails.os === 'iOS' ? 'localStorage' : 'cookie',
					minIdLength: 1,
					defaultTracking: {
						attribution: true,
						pageViews: false,
						sessions: true,
						formInteractions: false,
						fileDownloads: false,
					},
					logLevel: ConfigModule['analytics.amplitude.debug']
						? amplitude.Types.LogLevel.Debug
						: undefined,
				},
			);

			this.identifyUser();

			return true;
		}

		return false;
	}

	public setExperimentFlags(
		flags: Record<string, string | number | boolean | null>,
	) {
		this.identify(flags);

		return undefined;
	}

	private identify(properties: Record<string, string | number | boolean | null | undefined>) {
		const identify = new amplitude.Identify();
		(Object.keys(properties)).forEach((key) => {
			const val = properties[key];
			if (val !== null && val !== undefined) {
				identify
					.set(
						key,
						val,
					);
			}
		});
		amplitude.identify(identify);
	}

	public setUserProperties(
		objProperties: AnalyticsUserProperties,
	) {
		this.userProperties = merge(
			this.userProperties,
			objProperties,
		);

		this.identifyUser();
	}

	public logout() {
		amplitude.reset();

		return undefined;
	}

	private track(
		event: string,
		properties: Record<string, any>,
	) {
		amplitude.track(
			event,
			properties,
		);

		return undefined;
	}

	public aliasUser() {
		// Amplitude does not have a method for that
	}

	public registerUser() {
		this.identifyUser();
	}

	public identifyUser() {
		// Standardize user properties
		const stdUserProperties: {
			[key: string]: string | number | undefined | null;
		} = {};
		(Object.keys(this.userProperties) as (keyof AnalyticsUserProperties)[]).forEach((key) => {
			const val = this.userProperties[key];

			if (key.toLowerCase() == 'account created') {
				stdUserProperties.createdAt = val;
			} else if (key.toLowerCase() == 'first_name') {
				stdUserProperties.firstName = val;
			} else if (key.toLowerCase() == 'last_name') {
				stdUserProperties.lastName = val;
			} else {
				stdUserProperties[key.toLowerCase()] = val;
			}
		});

		if (UserModule.id) {
			if (ConfigModule['analytics.amplitude.externalUserId']) {
				const externalUser = ExternalUsersModule.findWhere({
					source: 'app',
				});
				if (externalUser
					&& externalUser.externalId
				) {
					amplitude.setUserId(
						externalUser.externalId,
					);
				}
			} else {
				amplitude.setUserId(
					UserModule.id.toString(),
				);
			}
		}

		this.identify(stdUserProperties);

		return undefined;
	}

	public trackPageView(
		route: string,
		title: string,
		objProperties: Record<string, any>,
	) {
		const trackProperties = {
			...objProperties,
			title,
			path: route,
		};
		this.track(
			'Loaded a Page',
			trackProperties,
		);
	}

	public trackEvent(
		action: string,
		objProperties: Record<string, any>,
	) {
		this.track(
			action,
			objProperties,
		);
	}

	public trackProductReady(
		productModel: DB.ProductModel | null,
		objPrice: PricingObject | null,
		objProperties: AnalyticsProjectReadyProperties,
	) {
		const properties = {
			page_count: objProperties.pageCount,
			photo_count: objProperties.photoCount,
			platform: objProperties.platform,
			groupid: objProperties.groupid,
			typeid: objProperties.typeid,
			variantid: objProperties.variantid,
			product_origin: objProperties.product_origin,
			type: objProperties.type,
			price: objPrice ? Math.round(objPrice.subTotal) / 100 : undefined,
			theme_id: productModel ? productModel.themeid : undefined,
			layout_id: productModel ? productModel.layoutid : undefined,
		};

		this.track(
			'Product ready',
			properties,
		);
	}

	public trackAddToCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			const productModel = ProductsModule.getById(cartItemModel.productid);

			const baseUrl = `${window.location.protocol}//${window.location.host}`;
			const productUrl = `${baseUrl}/app/${cartItemModel.productid}/open`;

			this.track(
				'Product Added',
				{
					...objProperties,
					// Semantic properties
					cart_id: cartItemModel.cartid,
					product_id: cartItemModel.productid,
					sku: offeringModel.id,
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid,
					price: Math.round(objPrice.subTotal) / 100,
					quantity: cartItemModel.quantity,
					url: productUrl,
					// Custom properties
					groupid: cartItemModel.groupid,
					product_origin: productModel
						? productModel.origin
						: undefined,
					type: offeringModel.type,
				},
			);
		}
	}

	public trackRemoveFromCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			this.track(
				'Removed Product',
				{
					...objProperties,
					cart_id: cartItemModel.cartid,
					product_id: cartItemModel.productid,
					sku: offeringModel.id,
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid,
					quantity: cartItemModel.quantity,
					type: offeringModel.type,
				},
			);
		}
	}

	public trackPerformance(
		action: string,
		value: number,
		objProperties: Record<string, any>,
	) {
		this.track(
			`Performance: ${action}`,
			{
				...objProperties,
				performanceValue: value,
			},
		);
	}

	public trackCreateProject(
		productModel: DB.ProductModel,
		objProperties: AnalyticsCreateProjectProperties,
	) {
		this.track(
			'Create Product',
			objProperties,
		);
	}

	public trackOpenProduct(
		productModel: DB.ProductModel,
		objProperties: AnalyticsOpenProjectProperties,
	) {
		this.track(
			'Open Product',
			objProperties,
		);
	}

	public trackTransaction() {
		// To avoid duplicate transaction event in Amplitude (as the PHP integration will already sent a server-side event)
		// we have disabled this event for the Amplitude integration
	}

	/* public trackTransaction(
		orderModel: DB.OrderModel,
		orderItems: DB.OrderItemModel[],
		objProperties: Record<string, any>,
	) {
		const arrProducts: AnalyticsEcommerceProduct[] = [];
		orderItems.forEach((orderItemModel) => {
			const offeringModel = AppDataModule.getOffering(orderItemModel.offeringid);
			if (offeringModel) {
				const linkModel = AppDataModule.findProductCategoryOfferingWhere({ offeringid: offeringModel.id });

				arrProducts.push({
					...objProperties,
					product_id: orderItemModel.productid.toString(),
					sku: offeringModel.id.toString(),
					category: linkModel
						? AppDataModule.getProductCategoryName(linkModel.productcategoryid)
						: undefined,
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid.toString(),
					price: Math.round(orderItemModel.salesvalue) / 100,
					quantity: orderItemModel.quantity,
				});
			}
		});

		this.track('Order Completed', {
			...objProperties,
			order_id: orderModel.id,
			total: Math.round(orderModel.salesvalue) / 100,
			shipping: Math.round(orderModel.price_shipping) / 100,
			tax: Math.round(orderModel.total_tax) / 100,
			discount: Math.round(orderModel.discount_voucher) / 100,
			coupon: orderModel.voucherid ? `${orderModel.voucherid}` : null,
			currency: orderModel.currency,
			products: arrProducts,
		});
	} */
}
