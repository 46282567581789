import _ from 'underscore';
import ProductState from './productstate';
import detectCookieSupport from '../tools/detect-cookie-support';
import {
	ERRORS_DATA_STORAGE_DISABLED,
	ERRORS_UNSUPPORTED_FEATURE,
} from '../settings/errors';

export default class Storage {
	public enabled: {
		data: boolean;
		file: boolean;
		cookie: boolean;
	};

	constructor() {
		this.enabled = {
			data: false,
			file: false,
			cookie: detectCookieSupport(),
		};

		const test = 'test';
		try {
			localStorage.setItem(
				test,
				test,
			);
			localStorage.removeItem(test);

			this.enabled.data = true;
		} catch (e) {
			this.enabled.data = false;
		}
	}

	get(key: string) {
		if (this.enabled.data) {
			try {
				// Retrieve the stored value from the local storage
				const data = localStorage.getItem(key);
				// Check if the key existed
				if (data) {
					// The retrieved data can either be a JSON string or a plain value
					try {
						// Try to parse as JSON and return the object
						return JSON.parse(data);
					} catch (e) {
						// Retrieved data is not JSON, so return plain value
						return data;
					}
				} else {
					// Key not stored in local storage, return false
					return false;
				}
			} catch (e) {
				// Some exception occured on the device, return false
				return false;
			}
		} else {
			// Data storage not enabled, return false
			return false;
		}
	}

	set(
		key: string,
		data: any,
	) {
		if (this.enabled.data) {
			const saveData = _.isObject(data) ? JSON.stringify(data) : data;

			try {
				localStorage.setItem(
					key,
					saveData,
				);
				return true;
			} catch (e) {
				return false;
			}
		} else {
			return false;
		}
	}

	clear(arrKeys: string[]): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.enabled.data) {
				if (typeof arrKeys === 'undefined') {
					arrKeys = [];
				}

				if (arrKeys.length > 1) {
					const objStorage = this.get(arrKeys[0]);
					if (objStorage) {
						let levelStorage = objStorage;
						for (let x = 1; x < arrKeys.length - 1; x += 1) {
							levelStorage = levelStorage[arrKeys[x]];
						}
						delete levelStorage[arrKeys[arrKeys.length - 1]];

						if (this.set(
							arrKeys[0],
							objStorage,
						)) {
							resolve();
						} else {
							reject(new Error(`Could not clear key ${arrKeys[0]} from storage`));
						}
					} else {
						reject(new Error(`Could not get key ${arrKeys[0]} from storage`));
					}
				} else if (arrKeys.length == 1) {
					localStorage.removeItem(arrKeys[0]);
					resolve();
				} else {
					localStorage.clear();
					resolve();
				}
			} else {
				reject(new Error(ERRORS_DATA_STORAGE_DISABLED));
			}
		});
	}

	merge(
		key: string,
		itemIndex: number,
		objData: Record<string, any>,
	): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.enabled.data) {
				let objStorage = this.get(key);
				if (!objStorage) {
					objStorage = {};
				}
				objStorage[itemIndex] = objData;

				if (this.set(
					key,
					objStorage,
				)) {
					resolve();
				} else {
					reject();
				}
			} else {
				reject(new Error(ERRORS_DATA_STORAGE_DISABLED));
			}
		});
	}

	getFileUrl(
		path: string,
		permanent: boolean,
	) {
		return new Promise((resolve, reject) => {
			if (path.substring(
				0,
				5,
			) == 'data:') {
				resolve(path);
			} else {
				console.log(
					path,
					permanent,
				);
				reject(new Error(ERRORS_UNSUPPORTED_FEATURE));
			}
		});
	}

	garbageCleaner() {
		// Remove corrupt data
		ProductState.removeFromStorage('undefined').catch(() => {
			// Swallow error: no action required
		});
		this.clear(['productData', 'undefined']).catch(() => {
			// Swallow error: no action required
		});
	}
}
