const holidays: {
	[key: string]: string[];
} = {
	gb: [
		'2021-01-01', // New Year's Day
		'2021-04-02', // Good Friday
		'2021-04-05', // Easter Monday
		'2021-05-03', // Early May Bank Holiday
		'2021-05-31', // Spring Bank Holiday
		'2021-08-30', // Summer Bank Holiday
		'2021-12-27', // Christmas (substitute)
		'2021-12-28', // Boxing Day (substitute)
	],
	us: [
		'2021-01-01', // New Year's Day
		'2021-01-18', // Martin Luther King Jr. Day
		'2021-05-31', // Memorial Day
		'2021-07-05', // Independence Day
		'2021-09-06', // Labor Day
		'2021-11-11', // Veterans Day
		'2021-11-25', // Thanksgiving
		'2021-12-24', // Christmas Day
		'2021-12-31', // New Year's Day
	],
	au: [
		'2021-01-01', // New Year's Day
		'2021-04-02', // Good Friday
		'2021-04-05', // Easter Monday
		'2021-04-26', // Anzac Day (substitute)
		'2021-05-31', // Reconciliation Day
		'2021-06-14', // Queen's Birthday
		'2021-10-04', // Labour Day
		'2021-12-27', // Christmas (substitute)
		'2021-12-28', // Boxing Day (substitute)
	],
};

export default holidays;
