import './defines';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import upload from 'controllers/upload';
import { UploadModel } from 'interfaces/app';
import { PhotoModel } from 'interfaces/project';
import loadImage from 'services/load-image';
import {
	MIME_TYPES_BROWSER_SUPPORTED,
	MIME_TYPES_SVG,
} from 'settings/filetypes';
import {
	PhotosModule,
	ProductStateModule,
	UploadModule,
} from 'store';
import {
	viewport as viewportTools,
} from 'tools';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ImportLightboxView',
})
export default class ImportLightboxView extends Vue.extend(Template) {
	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly isModal!: boolean;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly photoModel!: PhotoModel;

	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly showCloseButton!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showRemoveButton!: boolean;

	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly showToolsButtons!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showSelectButton!: boolean;

	protected get computedStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (this.url) {
			styles.backgroundImage = `url(${this.url})`;
		}

		let {
			posX,
			posY,
		} = this;

		if (this.displayWidth > this.viewport.width) {
			posX += this.movementX;
		}
		if (this.displayHeight > this.viewport.height) {
			posY += this.movementY;
		}

		styles.backgroundSize = `${this.displayWidth}px ${this.displayHeight}px`;
		styles.backgroundPositionX = `${Math.round(posX)}px`;
		styles.backgroundPositionY = `${Math.round(posY)}px`;

		return styles;
	}

	private get displayHeight(): number {
		return this.photoHeight * this.displayScale;
	}

	private get displayScale(): number {
		return (this.scaleCustom || this.scaleFull) * this.scaleMultiplier;
	}

	private get displayWidth(): number {
		return this.photoWidth * this.displayScale;
	}

	protected get isLoading(): boolean {
		return !this.url;
	}

	private get posX(): number {
		return (this.viewport.width - this.displayWidth) / 2;
	}

	private get posY(): number {
		return (this.viewport.height - this.displayHeight) / 2;
	}

	private get scaleFull(): number {
		return Math.min(
			this.viewport.width / this.photoWidth,
			this.viewport.height / this.photoHeight,
		);
	}

	protected get showRetryButton(): boolean {
		if (
			this.showRemoveButton
			&& this.photoModel._error
		) {
			return true;
		}

		return false;
	}

	private get uploadModel(): UploadModel | undefined {
		if (this.photoModel?.externalId) {
			return UploadModule.find(this.photoModel.externalId);
		}

		return undefined;
	}

	private url: string | null = null;

	private movementX = 0;

	private movementY = 0;

	private objectUrl: string | undefined = undefined;

	private photoHeight = 0;

	private photoWidth = 0;

	private scaleCustom = 0;

	private scaleMultiplier = 1;

	private viewport = viewportTools.fullViewport;

	private viewportUnwatch?: () => void;

	protected beforeDestroy() {
		if (this.objectUrl) {
			URL.revokeObjectURL(this.objectUrl);
		}

		this.viewportUnwatch?.();
	}

	protected created(): void {
		this.viewportUnwatch = viewportTools.watch(
			this.onViewportChange,
			'full',
		);
	}

	protected mounted() {
		this.setUrl();
	}

	@Watch('photoModel')
	private setUrl() {
		if (this.photoModel) {
			PhotosModule
				.getModelUrl({
					id: this.photoModel.id,
					resolution: 'low',
				})
				.then((urlOrFile) => {
					if (
						this.photoModel._type
						&& MIME_TYPES_SVG.includes(this.photoModel._type)
						&& urlOrFile instanceof File
					) {
						return svgUtils
							.load(
								urlOrFile,
								true,
							)
							.then(({ image: svg }) => {
								this.photoHeight = svg.height;
								this.photoWidth = svg.width;

								if (this.objectUrl) {
									// Cleanup the previous object URL
									URL.revokeObjectURL(this.objectUrl);
								}

								this.objectUrl = URL.createObjectURL(urlOrFile);

								return this.objectUrl;
							});
					}

					if (
						typeof this.photoModel.id === 'number'
						|| (
							this.photoModel._type
							&& MIME_TYPES_BROWSER_SUPPORTED.includes(this.photoModel._type)
						)
					) {
						return loadImage(
							urlOrFile,
							{
								forceCopy: true,
							},
						)
							.then(({ image }) => {
								this.photoHeight = image.height;
								this.photoWidth = image.width;

								return image.src;
							});
					}

					return undefined;
				})
				.then((url) => {
					this.url = url || null;
				});
		}
	}

	private closeLightBox() {
		this.$emit('closeLightBox');
	}

	protected onPan(hammerEvent: HammerInput) {
		this.movementX = Math.max(
			Math.min(
				(this.photoWidth * this.displayScale - this.viewport.width) / 2,
				this.movementX + hammerEvent.changedPointers[0].movementX,
			),
			(this.viewport.width - this.photoWidth * this.displayScale) / 2,
		);
		this.movementY = Math.max(
			Math.min(
				(this.photoHeight * this.displayScale - this.viewport.height) / 2,
				this.movementY + hammerEvent.changedPointers[0].movementY,
			),
			(this.viewport.height - this.photoHeight * this.displayScale) / 2,
		);
	}

	protected onPinch(hammerEvent: HammerInput) {
		const { scale } = hammerEvent;
		this.scaleMultiplier = scale;

		if (this.displayWidth > this.viewport.width) {
			this.movementX = Math.max(
				(this.viewport.width - this.displayWidth) / 2,
				Math.min(
					(this.displayWidth - this.viewport.width) / 2,
					this.movementX,
				),
			);
		}
		if (this.displayHeight > this.viewport.height) {
			this.movementY = Math.max(
				(this.viewport.height - this.displayHeight) / 2,
				Math.min(
					(this.displayHeight - this.viewport.height) / 2,
					this.movementY,
				),
			);
		}
	}

	protected onPinchEnd(hammerEvent: HammerInput) {
		this.scaleCustom = Math.max(
			this.scaleFull,
			(this.scaleCustom || this.scaleFull) * hammerEvent.scale,
		);
		this.scaleMultiplier = 1;
	}

	private onViewportChange() {
		this.viewport = viewportTools.fullViewport;
		this.movementX = 0;
		this.movementY = 0;
	}

	protected removePhoto() {
		ProductStateModule.removePhoto(this.photoModel.id);
		this.closeLightBox();
	}

	protected retryUpload() {
		if (this.photoModel.full_url) {
			PhotosModule
				.retryModel(this.photoModel.id)
				.catch(() => {
					// Swallow error: no action required
				});
		} else if (
			this.uploadModel
			&& UploadModule.findError(this.uploadModel.id)
		) {
			upload.retry(this.uploadModel);
		}
	}

	protected selectPhoto() {
		this.$emit(
			'selectPhoto',
			this.photoModel,
		);
	}
}
