import _ from 'underscore';
import * as DB from 'interfaces/database';
import {
	TemplateCarouselModel, TemplatePhotoPosition, TemplatePosition, TemplateSet, TemplateTextPosition,
	TemplatePositionStateModel,
} from 'interfaces/app';
import intersect from 'tools/intersect';
import { ThemeDataModule } from 'store/index';

interface resolveModel {
	[key: string]: (string|number)[];
}

interface deepResolveModel {
	[key: string]: deepResolveModel | resolveModel | false;
}

function resolveCombos(
	obj: resolveModel,
	canBeWith: resolveModel,
) {
	const objParent: any = {};

	const keys = Object.keys(obj);
	keys.forEach(
		(masterId) => {
			const arrOptionIds = obj[masterId];

			if (arrOptionIds.length === 0) {
			// This options set has reached the end
				objParent[masterId] = false;
			} else if (arrOptionIds.length == 1) {
			// This options set just needs to add one more option to the set
				objParent[masterId] = {};
				const firstOption = arrOptionIds[0].toString();
				objParent[masterId][firstOption] = false;
			} else {
			// Create new sets from the available options
				const objChild: resolveModel = {};

				arrOptionIds.forEach(
					(optionId, x) => {
						// Create available options array
						const arr: (string|number)[] = [];

						arrOptionIds.forEach(
							(optionId2, y) => {
								if (x != y) {
									if (canBeWith[optionId]
										&& canBeWith[optionId].indexOf(optionId2) >= 0
									) {
										// Add option to the available options array
										arr.push(optionId2);
									}
								}
							},
						);

						// Check if the available options array is not a duplicate of another set
						const duplicate = arr.find(
							(r) => objChild[r] && objChild[r].indexOf(optionId) >= 0,
						);

						if (!duplicate) {
							// Create new set
							objChild[optionId] = arr;
						}
					},
				);

				objParent[masterId] = resolveCombos(
					objChild,
					canBeWith,
				);
			}
		},
	);

	return objParent;
}

function returnKeys(obj: deepResolveModel) {
	const arr: string[][] = [];

	const pairs = _.pairs(obj);
	pairs.forEach(
		(pair) => {
			const pairKey = pair[0];
			const pairValue = pair[1];
			if (pairValue === false) {
				arr.push([pairKey]);
			} else {
			// @ts-ignore: pairValue is of type deepResolveModel
				const mapped = returnKeys(pairValue);
				mapped.forEach(
					(arrMap) => {
						arrMap.unshift(pairKey);
						arr.push(arrMap);
					},
				);
			}
		},
	);

	return arr;
}

function customSort(a: string, b: string) {
	return parseInt(
		a,
		10,
	) - parseInt(
		b,
		10,
	);
}

export default function getFixedTemplateSets(
	templateId: DB.TemplateModel['id'],
): TemplateSet[] {
	// Get all position states for this template
	const templatePositionStates: TemplatePositionStateModel[] = ThemeDataModule.templatePositionsStates.filter(
		(positionStateModel) => positionStateModel.templateid == templateId,
	).map((positionStateModel) => ({
		...positionStateModel,
		position: undefined,
		available: true,
	}));

	if (templatePositionStates.length === 0) {
		return [];
	}

	// Filter out all photo positions
	const rootPhotoPositionStates = templatePositionStates.filter(
		(positionStateModel) => positionStateModel.type == 'photo',
	);
	// Add position model to position state
	rootPhotoPositionStates.forEach(
		(rootPositionStateModel) => {
			const rootPositionModel = ThemeDataModule.photopositions.find(
				(m) => m.templatestateid === rootPositionStateModel.id,
			);
			if (rootPositionModel) {
				rootPositionStateModel.position = rootPositionModel;
			}
		},
	);

	// Filter out all text positions
	const rootTextPositionStates = templatePositionStates.filter(
		(positionStateModel) => positionStateModel.type == 'text',
	);
	// Add position model to position state
	rootTextPositionStates.forEach(
		(rootPositionStateModel) => {
			const rootPositionModel = ThemeDataModule.textpositions.find(
				(m) => m.templatestateid === rootPositionStateModel.id,
			);
			if (rootPositionModel) {
				rootPositionStateModel.position = rootPositionModel;
			}
		},
	);

	// Construct object in which we will save the possible combinations
	const canBeWith: resolveModel = {};

	// First we're going to check for each position with which other positions this could be combined
	rootPhotoPositionStates.forEach(
		(rootPositionStateModel) => {
			const noConflict = rootPhotoPositionStates.filter((model) => {
				if (!model.position || !rootPositionStateModel.position) {
					return false;
				}

				if (model == rootPositionStateModel) {
					// Do not compare with itself
					return false;
				}

				if (rootPositionStateModel.priority != model.priority
					&& (!rootPositionStateModel.combine || !model.combine)
				) {
					// Only combine positions with same priority value or ones that may be combined with other priority groups
					return false;
				}

				if (intersect(
					{
						x: rootPositionStateModel.position.x_axis + rootPositionStateModel.position.overlap_left + 1,
						y: rootPositionStateModel.position.y_axis + rootPositionStateModel.position.overlap_top + 1,
						width: rootPositionStateModel.position.width - rootPositionStateModel.position.overlap_left - rootPositionStateModel.position.overlap_right - 2,
						height: rootPositionStateModel.position.height - rootPositionStateModel.position.overlap_top - rootPositionStateModel.position.overlap_bottom - 2,
						borderWidth: rootPositionStateModel.position.borderwidth,
						rotation: rootPositionStateModel.position.angle,
					},
					{
						x: model.position.x_axis + model.position.overlap_left + 1,
						y: model.position.y_axis + model.position.overlap_top + 1,
						width: model.position.width - model.position.overlap_left - model.position.overlap_right - 2,
						height: model.position.height - model.position.overlap_top - model.position.overlap_bottom - 2,
						borderWidth: model.position.borderwidth,
						rotation: model.position.angle,
					},
				)) {
					// Check if positions don't intersect
					return false;
				}

				return true;
			});
			const k = rootPositionStateModel.id.toString();
			canBeWith[k] = noConflict.map(
				(model) => model.id,
			);
		},
	);

	// Check for template sets with only text positions
	rootTextPositionStates.forEach(
		(positionStateModel) => {
			const hasPhotoInGroup = rootPhotoPositionStates.find(
				(model) => model.priority == positionStateModel.priority,
			);
			if (hasPhotoInGroup) {
				return;
			}

			const noConflict = rootTextPositionStates.filter((model) => {
				if (!model.position || !positionStateModel.position) {
					return false;
				}

				if (model == positionStateModel) {
					// Do not compare with itself
					return false;
				}

				if (positionStateModel.priority != model.priority
					&& (!positionStateModel.combine || !model.combine)
				) {
					// Only combine positions with same priority value or ones that may be combined with other priority groups
					return false;
				}

				if (intersect(
					{
						x: positionStateModel.position.x_axis + positionStateModel.position.overlap_left + 1,
						y: positionStateModel.position.y_axis + positionStateModel.position.overlap_top + 1,
						width: positionStateModel.position.width - positionStateModel.position.overlap_left - positionStateModel.position.overlap_right - 2,
						height: positionStateModel.position.height - positionStateModel.position.overlap_top - positionStateModel.position.overlap_bottom - 2,
						borderWidth: positionStateModel.position.borderwidth,
						rotation: positionStateModel.position.angle,
					},
					{
						x: model.position.x_axis + model.position.overlap_left + 1,
						y: model.position.y_axis + model.position.overlap_top + 1,
						width: model.position.width - model.position.overlap_left - model.position.overlap_right - 2,
						height: model.position.height - model.position.overlap_top - model.position.overlap_bottom - 2,
						borderWidth: model.position.borderwidth,
						rotation: model.position.angle,
					},
				)) {
					// Check if positions don't intersect
					return false;
				}

				return true;
			});

			canBeWith[positionStateModel.id] = _.pluck(
				noConflict,
				'id',
			);
		},
	);

	// Now we have all optional partners for each position
	// We now need to check if these optional partners conflict which each other
	const objCopy: resolveModel = {};
	_.keys(canBeWith).forEach(
		(k) => {
			objCopy[k] = canBeWith[k].slice(0);
		},
	);
	const combinations = resolveCombos(
		objCopy,
		canBeWith,
	);

	// Now we create an array with all possible sets of positions
	const arrCombo = returnKeys(combinations);

	// Remove duplicate combinations by creating a collection with all unique sets
	const uniqueCombos: {
		[key: string]: string[];
	} = {};
	arrCombo.forEach(
		(combo) => {
			combo.sort(customSort);

			const id = combo.join('_');
			uniqueCombos[id] = combo;
		},
	);

	const templateCarousel: TemplateCarouselModel[] = [];

	if (_.keys(uniqueCombos).length > 0) {
		// Add combinations to template carousel
		const rootPositions = [
			...rootTextPositionStates,
			...rootPhotoPositionStates,
		];
		_.keys(uniqueCombos).forEach(
			(id) => {
				const collection: TemplatePositionStateModel[] = [];
				uniqueCombos[id].forEach(
					(positionstateid) => {
						const positionStateModel = rootPositions.find(
							(model) => model.id == parseInt(
								positionstateid,
								10,
							),
						);
						if (positionStateModel) {
							collection.push(positionStateModel);
						}
					},
				);

				// Now we add text positions to the combination sets
				rootTextPositionStates.forEach(
					(positionStateTextModel) => {
						// Check if positions don't intersect
						const conflict = collection.find(
							(positionStatePhotoModel) => {
								if (!positionStateTextModel.position) {
									return true;
								}
								if (!positionStatePhotoModel.position) {
									return true;
								}

								if (positionStateTextModel.priority != positionStatePhotoModel.priority
									&& (!positionStateTextModel.combine || !positionStatePhotoModel.combine)
								) {
									// Only combine positions with same priority value or ones that may be combined with other priority groups
									return true;
								}

								return intersect(
									{
										x: positionStateTextModel.position.x_axis + positionStateTextModel.position.overlap_left + 1,
										y: positionStateTextModel.position.y_axis + positionStateTextModel.position.overlap_top + 1,
										width: positionStateTextModel.position.width - positionStateTextModel.position.overlap_left - positionStateTextModel.position.overlap_right - 2,
										height: positionStateTextModel.position.height - positionStateTextModel.position.overlap_top - positionStateTextModel.position.overlap_bottom - 2,
										borderWidth: positionStateTextModel.position.borderwidth,
										rotation: positionStateTextModel.position.angle,
									},
									{
										x: positionStatePhotoModel.position.x_axis + positionStatePhotoModel.position.overlap_left + 1,
										y: positionStatePhotoModel.position.y_axis + positionStatePhotoModel.position.overlap_top + 1,
										width: positionStatePhotoModel.position.width - positionStatePhotoModel.position.overlap_left - positionStatePhotoModel.position.overlap_right - 2,
										height: positionStatePhotoModel.position.height - positionStatePhotoModel.position.overlap_top - positionStatePhotoModel.position.overlap_bottom - 2,
										borderWidth: positionStatePhotoModel.position.borderwidth,
										rotation: positionStatePhotoModel.position.angle,
									},
								);
							},
						);
						if (!conflict) {
							collection.push(positionStateTextModel);
						}
					},
				);

				templateCarousel.push({
					id: collection.map((model) => model.id).join('_'),
					positionStateCollection: collection,
					priority: _.reduce(
						collection,
						(memo, positionStateModel) => Math.max(
							positionStateModel.priority,
							memo,
						),
						0,
					),
				});
			},
		);
	}

	// Sort array based on priority settings
	templateCarousel.sort(
		(a, b) => a.priority - b.priority,
	);

	return templateCarousel.map((carouselModel) => {
		const positions = carouselModel.positionStateCollection.map(
			(positionStateModel) => {
				if (!positionStateModel.position) {
					return undefined;
				}

				const positionProps: TemplatePosition = {
					id: positionStateModel.id,
					x: positionStateModel.position.x_axis,
					y: positionStateModel.position.y_axis,
					z: positionStateModel.position.z_axis,
					width: positionStateModel.position.width,
					height: positionStateModel.position.height,
					angle: positionStateModel.position.angle,
					borderwidth: positionStateModel.position.borderwidth,
					bordercolor: positionStateModel.position.bordercolor,
					transformable: Boolean(positionStateModel.position.transformable),
					available: positionStateModel.available,
					overlap_top: positionStateModel.position.overlap_top,
					overlap_right: positionStateModel.position.overlap_right,
					overlap_bottom: positionStateModel.position.overlap_bottom,
					overlap_left: positionStateModel.position.overlap_left,
				};

				if (positionStateModel.type === 'text') {
					const positionModel = positionStateModel.position as DB.TemplatePositionTextModel;
					const textPosition: TemplateTextPosition = {
						...positionProps,
						type: 'text',
						fontface: positionModel.fontface,
						pointsize: positionModel.pointsize,
						fontcolor: positionModel.fontcolor,
						fontbold: Boolean(positionModel.fontbold),
						fontitalic: Boolean(positionModel.fontitalic),
						snap: Boolean(positionModel.snap),
						bgcolor: positionModel.bgcolor,
						align: positionModel.align,
						productattribute: positionModel.productattribute,
					};
					return textPosition;
				} if (positionStateModel.type === 'photo') {
					const positionModel = positionStateModel.position as DB.TemplatePositionPhotoModel;
					const photoPosition: TemplatePhotoPosition = {
						...positionProps,
						type: 'photo',
						mask: positionModel.mask,
						flag: Boolean(positionModel.flag),
						autorotate: Boolean(positionModel.autorotate),
						fillMethod: positionModel.fillMethod,
					};
					return photoPosition;
				}

				return undefined;
			},
		);

		const filteredPositions = positions.filter(
			(item): item is TemplatePhotoPosition | TemplateTextPosition => !!item,
		);

		const templateSet: TemplateSet = {
			id: carouselModel.id,
			templateId,
			positions: filteredPositions,
		};

		return templateSet;
	});
}
