import {
	OfferingModel,
	ProductModel,
	UserModel,
} from '@root/js/interfaces/database';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import checkConnection from 'services/check-connection';
import {
	ProductStateModule,
	AppDataModule,
	ConfigModule,
} from 'store/index';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class StatusView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates if the app is currently in an online state',
		type: Boolean,
	})
	public readonly isOnline!: boolean;

	@Prop({
		default: null,
		description: 'Defines the offering model from where get the localized offering name for the email sending',
		type: Object,
	})
	public readonly offeringModel!: Pick<OfferingModel, 'id' | 'name'>;

	@Prop({
		default: null,
		description: 'Defines the product model to know the current active product and get the id and read_token to generate the image for the email sending',
		type: Object,
	})
	public readonly productModel!: Pick<ProductModel, 'id' | 'read_token'>;

	@Prop({
		default: false,
		description: 'Indicates if a dialog with options to save the current state of the application should be displayed or not',
		type: Boolean,
	})
	public readonly showSaveOptions!: boolean;

	@Prop({
		default: false,
		description: "Indicates if a dialog with the confirmation of the current project's email sent should be displayed or not",
		type: Boolean,
	})
	public readonly showSendEmail!: boolean;

	@Prop({
		default: null,
		description: 'Defines information about an error related to the current project, if there was an error but there is no information about it, it will be an empty object',
		type: Object,
	})
	public readonly statusError!: Partial<Error> | null;

	@Prop({
		default: false,
		description: 'Indicates if the current project has been saved or not',
		type: Boolean,
	})
	public readonly statusSaved!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the current project is currently being synced or not',
		type: Boolean,
	})
	public readonly statusSyncing!: boolean;

	@Prop({
		description: 'Defines the user model for the active user to get the email and temporary state',
		required: true,
		type: Object,
	})
	public readonly userModel!: Pick<UserModel, 'email' | 'temporary'>;

	private dialogProjectEmailSentClose?: () => void;

	private dialogSaveOptionsClose?: () => void;

	protected get mainIcon() {
		if (!this.isOnline) {
			return 'portable_wifi_off';
		}
		if (this.statusSyncing) {
			return 'cached';
		}
		if (this.statusError) {
			return 'warning';
		}

		return 'save';
	}

	protected get text() {
		if (!this.isOnline) {
			return this.$t('offline');
		}
		if (this.productModel.id) {
			if (this.statusSyncing) {
				return this.$t('statusSaving');
			}
			if (this.statusError) {
				return this.$t('statusFailed');
			}
			if (this.statusSaved) {
				return this.$t('statusSaved');
			}
		}

		return '';
	}

	protected clickSave() {
		analytics.trackEvent(
			'Save project',
			{
				category: 'Button',
			},
		);

		if (this.productModel.id) {
			if (!this.isOnline) {
				checkConnection()
					.then(() => {
						this.$emit('project-save');
					})
					.catch(() => {
						this.$openAlertDialog({
							header: {
								title: this.$t('offline'),
							},
							body: {
								content: this.$t('offlineStatus'),
							},
						});
					});
			} else if (this.userModel.temporary) {
				auth.showSignup({
					hasclose: true,
				});
			} else {
				this.$emit('project-finalize');
			}
		}
	}

	private sendEmail() {
		const { email } = this.userModel;

		if (
			!email
			|| email.length === 0
			|| email.indexOf('@') === -1
			|| email.indexOf('@') >= email.length - 1
		) {
			const closeError = this.$openErrorDialog({
				body: {
					content: this.$t('dialogTextInvalidEmail'),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: this.$t('dialogButtonOk'),
							click: () => {
								closeError();
							},
						},
					],
				},
			});

			return;
		}

		if (!this.productModel.id) {
			throw new Error('Missing required productId');
		}

		if (!this.productModel) {
			throw new Error('Missing required productModel');
		}

		if (!this.offeringModel) {
			throw new Error('Missing required offeringModel');
		}

		const baseUrl = `${window.location.protocol}//${window.location.host}`;
		const productUrl = `${baseUrl}/app/${this.productModel.id}/open`;
		const { version } = ProductStateModule;

		const eventProps: Record<string, any> = {
			projectId: this.productModel.id,
			projectUrl: productUrl,
			imageUrl: `${ConfigModule.projectImageBaseUrl}/${this.productModel.id}/${this.productModel.read_token}/${version}`,
			offeringName: AppDataModule.getOfferingName(this.offeringModel.id),
		};

		analytics.trackEvent(
			'Email project link',
			eventProps,
		);
		this.$emit('send-project-email');
	}

	@Watch('statusError')
	protected onStatusErrorChange() {
		if (
			this.statusError
			&& 'message' in this.statusError
		) {
			this.$openErrorDialog({
				body: {
					content: this.statusError.message,
				},
			});
		}
	}

	@Watch('showSaveOptions')
	protected onShowSaveOptionsChange() {
		if (
			ConfigModule['features.offerProjectLink']
			&& this.userModel.email
		) {
			if (this.dialogSaveOptionsClose) {
				this.dialogSaveOptionsClose();
			}
			if (!this.showSaveOptions) {
				return;
			}

			this.dialogSaveOptionsClose = this.$openDialog({
				header: {
					title: this.$t('dialogHeaderSaveOptions'),
				},
				body: {
					content: this.$t('dialogTextSaveOptions'),
				},
				footer: {
					buttons: [
						{
							id: 'cancel',
							text: this.$t('dialogButtonSaveOptionsCancel'),
							click: () => {
								analytics.trackEvent(
									'offerProjectLinkDialog',
									{
										category: 'Dialog',
										label: 'Choice',
										value: 'No',
									},
								);
								this.$emit('close-save-options');
							},
						},
						{
							id: 'save',
							text: this.$t('dialogButtonSaveOptionsOk'),
							click: () => {
								analytics.trackEvent(
									'offerProjectLinkDialog',
									{
										category: 'Dialog',
										label: 'Choice',
										value: 'Yes',
									},
								);
								this.sendEmail();
							},
						},
					],
				},
			}).close;
		}
	}

	@Watch('showSendEmail')
	protected onShowSendEmailChange() {
		if (
			ConfigModule['features.offerProjectLink']
			&& this.userModel.email
		) {
			if (this.dialogProjectEmailSentClose) {
				this.dialogProjectEmailSentClose();
			}
			if (!this.showSendEmail) {
				return;
			}

			this.dialogProjectEmailSentClose = this.$openConfirmDialog({
				body: {
					content: this.$t(
						'dialogTextProjectEmailSent',
						{
							email: this.userModel.email,
						},
					),
				},
				footer: {
					buttons: [
						{
							id: 'confirm',
							text: this.$t('dialogButtonOk'),
							click: () => {
								this.$emit('project-email-sent');
							},
						},
					],
				},
			});
		}
	}
}
