import './defines';
import {
	ImportLibraryPageObjects,
	ImportLibraryProductPhotos,
} from 'interfaces/app';
import { OfferingModel } from 'interfaces/database';
import * as PI from 'interfaces/project';
import { PhotosModule, ProductStateModule } from 'store';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import VueDraggable from 'vuedraggable';
import ImportLibraryItemView from '../import-library-item';
import Template from './template.vue';

@Component({
	components: {
		ImportLibraryItemView,
		VueDraggable,
	},
})
export default class ImportLibraryView extends Vue.extend(Template) {
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly importModus!: boolean;

	@Prop({
		description: 'The type of product the user is creating',
		type: String,
		required: true,
	})
	public readonly offeringType!: OfferingModel['type'];

	@Prop({
		description: 'Defines the objects that are associated with the current page.',
		required: true,
		schema: 'ImportLibraryPageObjects',
		type: Array,
	})
	public readonly pageObjects!: ImportLibraryPageObjects;

	@Prop({
		description: 'Defines the photos that are associated with the current product.',
		required: true,
		schema: 'ImportLibraryProductPhotos',
		type: Array,
	})
	public readonly productPhotos!: ImportLibraryProductPhotos;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly sortActive!: boolean;

	@Prop({
		description: 'Only show vector images',
		default: false,
		type: Boolean,
	})
	public readonly vectorOnly!: boolean;

	protected get photoModels() {
		if (this.offeringType === 'logo') {
			const logos = PhotosModule.models.filter(
				(photoModel) => photoModel.type === 'logo',
			);

			if (this.vectorOnly) {
				return logos.filter(
					(photoModel) => photoModel.url?.endsWith('.svg'),
				);
			}

			return logos;
		}

		if (this.importModus) {
			return this.productPhotos;
		}

		return this.sortedCollection;
	}

	private set photoModels(value) {
		const ids = _.pluck(
			value,
			'id',
		);
		ProductStateModule.resetPhotos(_.compact(ids));
	}

	protected get productId() {
		if (ProductStateModule.productId) {
			return ProductStateModule.productId;
		}
		if (this.$route.params.productid) {
			return parseInt(
				this.$route.params.productid,
				10,
			);
		}

		return null;
	}

	private get sortedCollection() {
		const photoModels: PI.PhotoModel[] = [];
		this.productPhotos.forEach((photoModel) => {
			const isIncluded = this.pageObjects.find(
				(objectModel) => (
					objectModel.type === 'photo'
					&& Boolean(objectModel.photoid)
					&& objectModel.photoid === photoModel.id
				),
			);
			if (isIncluded) {
				photoModels.push(photoModel);
			} else {
				photoModels.unshift(photoModel);
			}
		});

		return photoModels;
	}

	protected addMorePhotos() {
		this.$emit('addMorePhotos');
	}

	protected clickItem(photoModel: PI.PhotoModel) {
		if (!this.sortActive) {
			this.$emit(
				'clickItem',
				photoModel,
			);
		}
	}

	protected getRandomArbitrary(min: number, max: number) {
		return Math.random() * (max - min) + min;
	}
}
