import './defines';
import PriceCalculator from 'classes/price-calculator';
import ProductState from 'classes/productstate';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import {
	AppDataModule,
	AppStateModule,
	ProductStateModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import TwoDView from 'views/2d/template.vue';
import PriceView from 'views/price';
import {
	Component,
	Prop,
	Ref,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		PriceView,
		TwoDView,
	},
})
export default class SizePickerView extends Vue.extend(Template) {
	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	private get countryModel() {
		return UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
	}

	private get currencyModel() {
		return UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
	}

	protected get currencyPrefix() {
		return this.currencyModel && this.currencyModel.prefix
			? this.currencyModel.prefix
			: '';
	}

	protected get currencySeparator() {
		return this.currencyModel && this.currencyModel.separator
			? this.currencyModel.separator
			: '.';
	}

	protected get currencySuffix() {
		return this.currencyModel && this.currencyModel.suffix
			? this.currencyModel.suffix
			: '';
	}

	private get isAvailableInRegion() {
		if (this.countryModel && this.selectedOfferingModel) {
			return !!AppDataModule.findRegionOfferingLinkWhere({
				regionid: this.countryModel.regionid,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	protected get model2D() {
		return this.selectedOfferingModel && this.selectedOfferingModel.model2did
			? _.findWhere(
				AppDataModule.models2d,
				{ id: this.selectedOfferingModel.model2did },
			)
			: undefined;
	}

	private get priceData() {
		if (this.isAvailableInRegion
			&& this.selectedOfferingModel
			&& this.productModel
		) {
			return PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get selectedOfferingPrice() {
		if (this.pageModel) {
			if (!this.currencyModel) {
				throw new Error('Missing required currency model');
			}
			if (!this.selectedOfferingModel) {
				throw new Error('Missing required offering model');
			}

			const pricingModel = AppDataModule.findPricingWhere({
				offeringid: this.selectedOfferingModel.id,
				currency: this.currencyModel.id,
			});

			if (!pricingModel) {
				return undefined;
			}

			return pricingModel.price_base;
		}

		let selectedOfferingPrice = this.priceData
			? this.priceData.subTotal
			: 0;

		if (this.priceData && this.priceData.offeringModel) {
			const upsellModels = AppDataModule.whereUpsell({
				offeringid: this.priceData.offeringModel.id,
				autoinclude: 1,
				optional: 0,
			});

			upsellModels.forEach((upsellModel) => {
				if (this.productModel) {
					const price = PriceCalculator.projectPrice({
						offeringid: upsellModel.upsellid,
						productid: this.productModel.id,
					});
					if (price) {
						selectedOfferingPrice += price.subTotal;
					}
				}
			});
		}

		return selectedOfferingPrice;
	}

	private get offeringOptionOrientationValueModels() {
		return this.offeringOptionValueModels.filter(
			(model) => model.offeringoptionid === this.offeringOptionOrientationModel?.id,
		);
	}

	private get offeringOptionSizeValueModels() {
		const sizeValueModels = this.offeringOptionValueModels.filter(
			(model) => model.offeringoptionid === this.offeringOptionSizeModel?.id,
		);

		// If there is no orientation selected, we return all size models
		if (!this.selectOrientationValueModel) {
			return sizeValueModels.sort((a, b) => a.serialnumber - b.serialnumber);
		}

		// Filter out all size models that overlap with the selected orientation
		// check for offering models that link both to the selected orientation and to the size model
		const offeringIds = _.compact(AppDataModule.offeringoptionvalueofferinglinks.map(
			(linkModel) => {
				if (linkModel.offeringoptionvalueid == this.selectedOrientationValueModel?.id) {
					return linkModel.offeringid;
				}

				return undefined;
			},
		));

		return sizeValueModels.filter((sizeValueModel) => AppDataModule.offeringoptionvalueofferinglinks.find(
			(linkModel) => linkModel.offeringoptionvalueid == sizeValueModel.id && offeringIds.indexOf(linkModel.offeringid) >= 0,
		)).sort((a, b) => a.serialnumber - b.serialnumber);
	}

	private get offeringOptionValueModels() {
		return this.selectedOfferingModel
			? AppDataModule.getOfferingOptionValueModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];
	}

	private get offeringOptionOrientationModel() {
		return this.offeringOptionModels.find(
			(model) => model.tag === 'orientation',
		);
	}

	private get offeringOptionSizeModel() {
		return this.offeringOptionModels.find(
			(model) => model.tag === 'size',
		);
	}

	private get offeringOptionModels() {
		return this.selectedOfferingModel
			? AppDataModule.getOfferingOptionModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];
	}

	@Ref('twodViewWrapper')
	private readonly twodViewWrapper!: HTMLDivElement;

	protected boxHeight = 500;

	protected boxWidth = 500;

	private selectedOfferingModel: DB.OfferingModel | null = null;

	private selectedOrientationValueModel: DB.OfferingOptionValueModel | null = null;

	private selectedSizeValueModel: DB.OfferingOptionValueModel | null = null;

	protected beforeDestroy() {
		window.removeEventListener(
			'resize',
			this.setBoxSize,
		);
	}

	protected created() {
		this.selectedOfferingModel = this.pageModel && this.pageModel.offeringId
			? AppDataModule.getOffering(this.pageModel.offeringId) || null
			: ProductStateModule.getOffering;

		this.setValueModels();
	}

	protected mounted() {
		this.setBoxSize();
		window.addEventListener(
			'resize',
			this.setBoxSize,
		);
	}

	private changeOffering() {
		if (!this.selectedOfferingModel) {
			return Promise.reject(
				new Error('Missing selected offering model'),
			);
		}
		if (!this.productModel) {
			return Promise.reject(
				new Error('Missing product model'),
			);
		}

		if (this.selectedOfferingModel.groupid == this.productModel.group
			&& this.selectedOfferingModel.typeid == this.productModel.typeid
			&& this.selectedOfferingModel.variantid == this.productModel.variantid
		) {
			return Promise.resolve();
		}

		AppStateModule.setHeavyLoad();
		const closeLoader = this.$openLoaderDialog();

		return ProductState
			.changeOffering(
				this.selectedOfferingModel.id,
			)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.catch((e: Error) => {
				// Swallow error: no action required
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			})
			.finally(() => {
				closeLoader();
				AppStateModule.unsetHeavyLoad();
			});
	}

	private closePicker() {
		this.$emit('closeDialog');
	}

	protected saveChanges() {
		this.changeOffering()?.finally(() => {
			this.closePicker();
		});
	}

	protected getOptionLabel(valueModel: DB.OfferingOptionValueModel) {
		return this.$t(
			`offeringOptionValues:${valueModel.id}`,
			valueModel.value,
		);
	}

	/* private getSizeValueModelPriceLabel(
		sizeValueModel: DB.OfferingOptionValueModel,
	): string {
		if (!this.productModel) {
			throw new Error('Missing required productModel');
		}

		let linkModels = AppDataModule.offeringoptionvalueofferinglinks.filter(
			(linkModel) => linkModel.offeringoptionvalueid == sizeValueModel?.id,
		);

		linkModels = _.sortBy(
			linkModels,
			(linkModel) => {
				const offeringModel = AppDataModule.getOffering(linkModel.offeringid);
				return offeringModel
					&& this.productModel
					&& Boolean(offeringModel.variantid == this.productModel.variantid)
					? -1
					: 1;
			},
		);

		let offeringModel: DB.OfferingModel | undefined;
		if (linkModels.length) {
			offeringModel = _.findWhere(AppDataModule.offerings, {
				id: linkModels[0].offeringid,
			});
		}

		if (offeringModel) {
			const objPriceData = PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: offeringModel.id,
			});

			return this.currencyPrefix + formatMoney(objPriceData.subTotal, this.currencySeparator) + this.currencySuffix;
		}

		return '';
	} */

	private selectOffering() {
		let linkModels = AppDataModule.offeringoptionvalueofferinglinks;

		if (this.selectedSizeValueModel) {
			linkModels = linkModels.filter(
				(linkModel) => linkModel.offeringoptionvalueid == this.selectedSizeValueModel?.id,
			);
		} else if (this.selectedOrientationValueModel) {
			linkModels = linkModels.filter(
				(linkModel) => linkModel.offeringoptionvalueid == this.selectedOrientationValueModel?.id,
			);
		}

		linkModels = _.sortBy(
			linkModels,
			(linkModel) => {
				const offeringModel = AppDataModule.getOffering(linkModel.offeringid);
				return offeringModel
					&& this.productModel
					&& Boolean(offeringModel.variantid == this.productModel.variantid)
					? -1
					: 1;
			},
		);

		let offeringModel: DB.OfferingModel | undefined;
		if (linkModels.length) {
			offeringModel = _.findWhere(
				AppDataModule.offerings,
				{
					id: linkModels[0].offeringid,
				},
			);
		}

		if (offeringModel) {
			this.selectedOfferingModel = offeringModel;
			this.setValueModels();
		}
	}

	private selectOrientationValueModel(
		valueModel: DB.OfferingOptionValueModel,
	) {
		if (this.selectedOrientationValueModel?.id != valueModel.id) {
			this.selectedSizeValueModel = null;
			this.selectedOrientationValueModel = valueModel;
			this.selectOffering();
		}
	}

	protected selectSizeValueModel(
		valueModel: DB.OfferingOptionValueModel,
	) {
		this.selectedSizeValueModel = valueModel;
		this.selectOffering();
	}

	private setBoxSize() {
		this.boxWidth = this.twodViewWrapper.clientWidth;
		this.boxHeight = this.twodViewWrapper.clientHeight;
	}

	private setValueModels() {
		if (this.selectedOfferingModel) {
			const linkedValueModels = AppDataModule.offeringoptionvalueofferinglinks.filter(
				(linkModel) => linkModel.offeringid === this.selectedOfferingModel?.id,
			);

			// Find the orientation value model that is linked to the selected offering model
			const linkedOrientationValueModel = linkedValueModels.find(
				(linkModel) => _.pluck(
					this.offeringOptionOrientationValueModels,
					'id',
				).indexOf(linkModel.offeringoptionvalueid) >= 0,
			);
			if (linkedOrientationValueModel) {
				this.selectedOrientationValueModel = this.offeringOptionOrientationValueModels.find(
					(valueModel) => valueModel.id === linkedOrientationValueModel.offeringoptionvalueid,
				) || null;
			}

			// Find the size value model that is linked to the selected offering model
			const linkedSizeValueModel = linkedValueModels.find(
				(linkModel) => _.pluck(
					this.offeringOptionSizeValueModels,
					'id',
				).indexOf(linkModel.offeringoptionvalueid) >= 0,
			);
			if (linkedSizeValueModel) {
				this.selectedSizeValueModel = this.offeringOptionSizeValueModels.find(
					(valueModel) => valueModel.id === linkedSizeValueModel.offeringoptionvalueid,
				) || null;
			}
		}
	}
}
