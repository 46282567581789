import _ from 'underscore';

const parseTag = (
	text: string,
	tags: {
		[key: string]: string | number;
	},
) => {
	let textClone = text; // copy text so that we don't edit the original
	const pairs = _.pairs(tags);
	pairs.forEach((replaceTag) => {
		const regexp = new RegExp(
			`<%= ${replaceTag[0]} %>`,
			'g',
		);
		textClone = textClone.replace(
			regexp,
			replaceTag[1].toString(),
		);
	});
	return textClone;
};

export default parseTag;
