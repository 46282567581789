import './defines';
import ImageMaskClass from 'classes/mask';
import PageObject from 'classes/pageobject';
import ProductState from 'classes/productstate';
import Template, { PhotoData } from 'classes/template';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import merge from 'deepmerge';
import {
	PhotoFilter,
	PhotoMask,
	Scrollbar,
	TemplatePhotoPosition,
	ToolbarButton,
} from 'interfaces/app';
import * as PI from 'interfaces/project';
import applyMask from 'mutations/pageobject/apply-mask';
import deleteObject from 'mutations/pageobject/delete';
import zoomObject from 'mutations/pageobject/zoom';
import loadImage from 'services/load-image';
import maskPresets from 'settings/masks';
import { OfferingGroups } from 'settings/offerings';
import store, {
	AppStateModule,
	ConfigModule,
	FontModule,
	PhotosModule,
	ProductStateModule,
} from 'store';
import appendUrlParameter from 'tools/append-url-parameter';
import getViewport from 'tools/get-viewport';
import maxObjectSize from 'tools/max-object-size';
import _ from 'underscore';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FontPickerView from '../font-picker';
import ScrollbarView from '../scrollbar.vue';
import ObjectToolbarItemView from '../toolbar-object-item.vue';
import spinnerGif from '../../../img/spinner.gif';
import VueTemplate from './template.vue';

const warningSvg = require('../../../img/warning.svg');

const renderedFilters: Record<PhotoFilter, string> = {
	none: spinnerGif,
	boost: spinnerGif,
	grey: spinnerGif,
	sepia: spinnerGif,
	negate: spinnerGif,
};

const renderedMasks: Record<Exclude<PhotoMask, 'squareLocked'>, string> = {
	none: spinnerGif,
	circle: spinnerGif,
	circleLocked: spinnerGif,
	heart: spinnerGif,
	diamond: spinnerGif,
	brush: spinnerGif,
	cloud: spinnerGif,
	star: spinnerGif,
	flower: spinnerGif,
	stamp: spinnerGif,
	octagon: spinnerGif,
	clover: spinnerGif,
};

@Component({
	components: {
		ObjectToolbarItemView,
		ScrollbarView,
	},
})
export default class ObjectToolbarView extends Vue.extend(VueTemplate) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	private get buttonBack(): ToolbarButton | undefined {
		if (this.objectModel) {
			return {
				id: 'back',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-chevron-left',
				inverse: true,
				subscript: this.$t('buttonBack'),
				target: () => {
					if (this.activeAlign) {
						this.activeAlign = false;
					} else if (this.activeFilter) {
						this.activeFilter = false;
					} else if (this.activeFit) {
						this.activeFit = false;
					} else if (this.activeMask) {
						this.activeMask = false;
					} else if (this.activeStack) {
						this.activeStack = false;
					} else if (this.objectModel && this.objectModel._crop) {
						ProductStateModule.disableCropping(this.pageModel.id);
					} else {
						ProductStateModule.deselectPageObjects();
					}
				},
			};
		}

		return undefined;
	}

	private get buttonBorder(): ToolbarButton | undefined {
		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.productModel
			&& OfferingGroups(
				this.productModel.group,
				['BookTypes', 'WallDecoration'],
			)
			&& this.objectModel.photoid
		) {
			return {
				id: 'border',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'far fa-square',
				subscript: this.$t('objectToolbar.border'),
				scrollbars: [
					{
						type: 'size',
						minBarWidth: 250,
						text: this.$t('objectToolbar.borderWidth'),
						value: this.objectModel.borderwidth
							? this.objectModel.borderwidth
							: null,
						values: [
							0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20,
							22, 24, 26, 28, 30, 32, 34, 38, 42, 46,
							50, 54, 58, 62, 66, 70, 75, 80, 85, 90,
							95, 100,
						],
						onChange: (value: number) => {
							if (!this.objectModel) {
								throw new Error('Missing required objectModel to perform operation');
							}

							if (value != this.objectModel.borderwidth) {
								ProductStateModule.changePageObject({
									id: this.objectModel.id,
									borderwidth: value,
									borderimage: 0,
								});
							}
						},
						onDragEnd: () => {
							ProductStateModule.pushHistory();
						},
					},
					{
						type: 'colors',
						minBarWidth: 250,
						text: this.$t('objectToolbar.borderColor'),
						value: this.objectModel.bordercolor
							? this.objectModel.bordercolor
							: null,
						values: [
							'#80F31F', '#8CE60B', '#99D501', '#A5C103', '#B1AA0F', '#BC9126', '#C77844', '#D15F68',
							'#DB478E', '#E332B3', '#EA1FD3', '#F111EC', '#F607FA', '#FA01FE', '#FD01F7', '#FE06E5',
							'#FE0FCA', '#FD1DA8', '#FB2F83', '#F8445D', '#F35C3A', '#ED751E', '#E68E0A', '#DEA701',
							'#D5BE03', '#CCD310', '#C1E427', '#B6F246', '#AAFA6A', '#9EFE90', '#91FDB5', '#85F7D5',
							'#78ECED', '#6BDDFB', '#5FCAFE', '#53B4F6', '#479CE4', '#3C83C8', '#3269A6', '#285181',
							'#1F3A5B', '#182739', '#11161D', '#0B0A0A', '#070301', '#030104', '#010311', '#000000',
							'#333333', '#666666', '#999999', '#CCCCCC', '#FFFFFF',
						],
						onChange: (value: string) => {
							if (!this.objectModel) {
								throw new Error('Missing required objectModel to perform operation');
							}

							if (value != this.objectModel.bordercolor) {
								ProductStateModule.changePageObject({
									id: this.objectModel.id,
									bordercolor: value,
									borderimage: 0,
								});
							}
						},
						onDragEnd: () => {
							ProductStateModule.pushHistory();
						},
					},
				],
			};
		}

		return undefined;
	}

	private get buttonCrop(): ToolbarButton | undefined {
		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.productModel
			&& OfferingGroups(
				this.productModel.group,
				['BookTypes', 'WallDecoration'],
			)
			&& this.objectModel.photoid
			&& this.objectModel.transformable
		) {
			return {
				id: 'crop',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-crop',
				subscript: this.$t('objectToolbar.crop'),
				pressed: this.objectModel._crop,
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					if (this.objectModel._crop) {
						// reset selected objects
						ProductStateModule.disableCropping(this.pageModel.id);
					} else {
						// Track event in analytics
						analytics.trackEvent(
							'Crop photo',
							{},
						);

						// select object for cropping
						ProductStateModule.changePageObject({
							id: this.objectModel.id,
							_crop: true,
						});
					}
				},
			};
		}

		return undefined;
	}

	private get buttonEditText(): ToolbarButton | undefined {
		if (this.objectModel && this.objectModel.type == 'text') {
			return {
				id: 'edittext',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-i-cursor',
				subscript: this.$t('objectToolbar.editText'),
				target: () => {
					this.$emit('editText');
				},
			};
		}

		return undefined;
	}

	private get buttonFilter(): ToolbarButton | undefined {
		if (this.offeringModel?.type === 'logo') {
			return undefined;
		}

		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.objectModel.photoid
			&& this.photoEffects.length > 1
		) {
			return {
				id: 'objecttoolbar_effect',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-magic',
				subscript: this.$t('objectToolbar.filter'),
				target: () => {
					this.toolbarSize = 'toolbarHigh';

					analytics.trackEvent(
						'Open toolbar',
						{
							category: 'Button',
							label: 'Photo effects',
						},
					);

					this.renderFilters();
					this.activeFilter = true;
				},
			};
		}

		return undefined;
	}

	private get buttonFit(): ToolbarButton | undefined {
		if (!this.objectModel
			|| !this.objectModel.photoid
			|| !this.objectModel.templatestateid
			|| !this.objectModel.fillMethod
		) {
			return undefined;
		}

		return {
			id: 'fit',
			type: 'objecttoolbar',
			class: 'button',
			miClass: 'aspect_ratio',
			subscript: this.$t('objectToolbar.fit'),
			target: () => {
				this.activeFit = true;
			},
		};
	}

	private get buttonFontAlign(): ToolbarButton | undefined {
		if (this.objectModel && this.objectModel.type == 'text') {
			return {
				id: 'fontalign',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-align-left',
				subscript: this.$t('objectToolbar.textAlign'),
				target: () => {
					this.activeAlign = true;
				},
			};
		}

		return undefined;
	}

	private get buttonFontBold(): ToolbarButton | undefined {
		if (this.objectModel && this.fontModel && this.fontModel.bold && (
			!this.objectModel.fontitalic
			|| this.fontModel.bolditalic
		)) {
			return {
				id: 'fontbold',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-bold',
				subscript: this.$t('objectToolbar.fontBold'),
				pressed: Boolean(this.objectModel.fontbold),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						fontbold: this.objectModel.fontbold == 0 ? 1 : 0,
					});

					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get buttonFontColor(): ToolbarButton | undefined {
		if (this.objectModel
			&& this.objectModel.type == 'text'
			&& this.offeringModel
			&& this.offeringModel.color > 1
		) {
			return {
				id: 'fontcolor',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-tint',
				subscript: this.$t('objectToolbar.color'),
				scrollbars: [
					{
						type: 'colors',
						text: this.$t('objectToolbar.fontColor'),
						value: this.objectModel.fontcolor
							? this.objectModel.fontcolor
							: null,
						values: [
							'#80F31F', '#8CE60B', '#99D501', '#A5C103', '#B1AA0F', '#BC9126', '#C77844', '#D15F68',
							'#DB478E', '#E332B3', '#EA1FD3', '#F111EC', '#F607FA', '#FA01FE', '#FD01F7', '#FE06E5',
							'#FE0FCA', '#FD1DA8', '#FB2F83', '#F8445D', '#F35C3A', '#ED751E', '#E68E0A', '#DEA701',
							'#D5BE03', '#CCD310', '#C1E427', '#B6F246', '#AAFA6A', '#9EFE90', '#91FDB5', '#85F7D5',
							'#78ECED', '#6BDDFB', '#5FCAFE', '#53B4F6', '#479CE4', '#3C83C8', '#3269A6', '#285181',
							'#1F3A5B', '#182739', '#11161D', '#0B0A0A', '#070301', '#030104', '#010311', '#000000',
							'#333333', '#666666', '#999999', '#CCCCCC', '#FFFFFF',
						],
						onChange: (value: string) => {
							if (!this.objectModel) {
								throw new Error('Missing required objectModel to perform operation');
							}

							if (value != this.objectModel.fontcolor) {
								ProductStateModule.changePageObject({
									id: this.objectModel.id,
									fontcolor: value,
								});
							}
						},
						onDragEnd: () => {
							ProductStateModule.pushHistory();
						},
					},
					{
						type: 'colors',
						text: this.$t('objectToolbar.bgColor'),
						value: this.objectModel.bgcolor
							? this.objectModel.bgcolor
							: null,
						values: [
							null, null, '#80F31F', '#8CE60B', '#99D501', '#A5C103', '#B1AA0F', '#BC9126', '#C77844', '#D15F68',
							'#DB478E', '#E332B3', '#EA1FD3', '#F111EC', '#F607FA', '#FA01FE', '#FD01F7', '#FE06E5',
							'#FE0FCA', '#FD1DA8', '#FB2F83', '#F8445D', '#F35C3A', '#ED751E', '#E68E0A', '#DEA701',
							'#D5BE03', '#CCD310', '#C1E427', '#B6F246', '#AAFA6A', '#9EFE90', '#91FDB5', '#85F7D5',
							'#78ECED', '#6BDDFB', '#5FCAFE', '#53B4F6', '#479CE4', '#3C83C8', '#3269A6', '#285181',
							'#1F3A5B', '#182739', '#11161D', '#0B0A0A', '#070301', '#030104', '#010311', '#000000',
							'#333333', '#666666', '#999999', '#CCCCCC', '#FFFFFF',
						],
						onChange: (value: string) => {
							if (!this.objectModel) {
								throw new Error('Missing required objectModel to perform operation');
							}

							if (value != this.objectModel.bgcolor) {
								ProductStateModule.changePageObject({
									id: this.objectModel.id,
									bgcolor: value,
								});
							}
						},
						onDragEnd: () => {
							ProductStateModule.pushHistory();
						},
					},
				],
			};
		}

		return undefined;
	}

	private get buttonFontItalic(): ToolbarButton | undefined {
		if (this.objectModel && this.fontModel && this.fontModel.italic && (
			!this.objectModel.fontbold
			|| this.fontModel.bolditalic
		)) {
			return {
				id: 'fontitalic',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-italic',
				subscript: this.$t('objectToolbar.fontItalic'),
				pressed: Boolean(this.objectModel.fontitalic),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						fontitalic: this.objectModel.fontitalic == 0 ? 1 : 0,
					});

					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get buttonFontFace(): ToolbarButton | undefined {
		if (this.objectModel && this.objectModel.type == 'text') {
			return {
				id: 'fontface',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-font',
				subscript: this.$t('objectToolbar.fontFace'),
				target: () => {
					analytics.trackEvent(
						'Open Picker',
						{
							category: 'Button',
							label: 'Font',
						},
					);

					const { close: closeDialog } = this.$openDialog({
						header: {
							classes: 'picker',
							title: this.$t('pickerFont'),
						},
						body: {
							component: FontPickerView,
							props: {
								pageModel: this.pageModel,
							},
							listeners: {
								closeDialog: () => {
									closeDialog();
								},
							},
						},
					});
				},
			};
		}

		return undefined;
	}

	private get buttonFontSize(): ToolbarButton | undefined {
		if (this.objectModel && this.objectModel.type == 'text') {
			const arrFontSize = _.filter(
				[
					8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 38, 42, 46,
					50, 54, 58, 62, 66, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130, 140, 150,
					175, 200, 225, 250, 275, 300,
				],
				(size) => size >= (this.offeringModel ? this.offeringModel.minfontsize : 0),
			);

			return {
				id: 'fontsize',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-text-height',
				subscript: this.objectModel.pointsize
					? `${Math.round(this.objectModel.pointsize)}pt`
					: '0pt',
				scrollbars: [
					{
						type: 'size',
						value: this.objectModel.pointsize
							? this.objectModel.pointsize
							: null,
						values: arrFontSize,
						onChange: (value: number) => {
							if (!this.objectModel) {
								throw new Error('Missing required objectModel to perform operation');
							}

							if (value != this.objectModel.pointsize) {
								ProductStateModule.changePageObject({
									id: this.objectModel.id,
									pointsize: value,
								});
							}
						},
						onDragEnd: () => {
							ProductStateModule.pushHistory();
						},
					},
				],
			};
		}

		return undefined;
	}

	private get buttonMask(): ToolbarButton | undefined {
		if (this.offeringModel?.type === 'logo') {
			return undefined;
		}

		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.objectModel.photoid
			&& store.state.config['features.imageMasks']
		) {
			return {
				id: 'objectToolbarMask',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-heart',
				subscript: this.$t('objectToolbar.mask'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					this.toolbarSize = 'toolbarHigh';

					AppStateModule.setMask(JSON.parse(JSON.stringify(this.objectModel)));

					analytics.trackEvent(
						'Open toolbar',
						{
							category: 'Button',
							label: 'Photo masks',
						},
					);

					this.renderMasks();
					this.activeMask = true;
				},
			};
		}

		return undefined;
	}

	private get buttonRemove(): ToolbarButton | undefined {
		if (this.objectModel) {
			return {
				id: 'remove',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-trash-alt',
				subscript: this.$t('objectToolbar.remove'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.deselectPageObjects();
					deleteObject(this.objectModel);
					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get buttonRotate(): ToolbarButton | undefined {
		if (this.objectModel && this.objectModel.type == 'photo') {
			return {
				id: 'objectToolbarRotate',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-sync',
				subscript: this.$t('objectToolbar.rotate'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					let changeProperties: OptionalExceptFor<PI.PageObjectModel, 'id'> = {
						id: this.objectModel.id,
						rotate: this.objectModel.rotate == 270
							? 0
							: this.objectModel.rotate + 90,
					};

					if (this.objectModel.type == 'photo' && this.objectModel.photoid) {
						const photoModel = PhotosModule.getById(this.objectModel.photoid);
						if (!photoModel) {
							throw new Error('Missing required photo model');
						}

						const { templatestateid } = this.objectModel;
						if (templatestateid) {
							const templatePositions = ProductStateModule.getPageTemplatePositions(
								this.pageModel,
							);
							const positionModel = templatePositions.find(
								(position) => position.id == templatestateid,
							) as TemplatePhotoPosition | undefined;

							if (
								positionModel
								&& positionModel.transformable
							) {
								const offeringModel = ProductStateModule.getOffering;
								const photoData: Omit<PhotoData, 'url'> = {
									id: photoModel.id,
									width: photoModel.full_width,
									height: photoModel.full_height,
									caption: photoModel.title || undefined,
								};
								if (typeof photoModel.fcx !== 'undefined'
									&& photoModel.fcx !== null
									&& typeof photoModel.fcy !== 'undefined'
									&& photoModel.fcy !== null
									&& typeof photoModel.fcw !== 'undefined'
									&& photoModel.fcw !== null
									&& typeof photoModel.fch !== 'undefined'
									&& photoModel.fch !== null
								) {
									photoData.facebox = {
										x: photoModel.fcx,
										y: photoModel.fcy,
										width: photoModel.fcw,
										height: photoModel.fch,
									};
								}
								const props = Template.fitPhotoInRectangle(
									{
										x: positionModel.x,
										y: positionModel.y,
										width: positionModel.width,
										height: positionModel.height,
										angle: this.objectModel.rotate,
										borderwidth: positionModel.borderwidth,
										autoRotate: Boolean(positionModel.autorotate),
									},
									photoData,
									undefined,
									{
										forceRotate: true,
										resizing: {
											maxScale: offeringModel
												? offeringModel.configdpi / offeringModel.minimumdpi
												: 1000,
											recommendedMaxScale: offeringModel
												? offeringModel.configdpi / offeringModel.qualitydpi
												: 1000,
										},
									},
								);
								changeProperties = {
									id: this.objectModel.id,
									x_axis: props.x,
									y_axis: props.y,
									width: props.width,
									height: props.height,
									cropwidth: props.cropWidth,
									cropheight: props.cropHeight,
									cropx: props.cropX,
									cropy: props.cropY,
									rotate: props.rotation,
								};
							}
						}
					}

					ProductStateModule.changePageObject(changeProperties);
					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get buttonStack(): ToolbarButton | undefined {
		const isCardBack = this.productModel && this.productModel.group == 102
			&& ProductStateModule.getPageIndex(this.pageModel) == 0;

		if (this.objectModel
			&& this.objectModel.transformable
			&& ProductStateModule.getPageObjects(this.pageModel).length > 1
			&& !isCardBack
		) {
			return {
				id: 'stack',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-clone',
				subscript: this.$t('objectToolbar.stack'),
				target: () => {
					this.activeStack = true;
				},
			};
		}

		return undefined;
	}

	private get buttonSwapPhoto(): ToolbarButton | undefined {
		if (this.offeringModel?.type === 'logo') {
			return undefined;
		}

		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.objectModel.photoid
			&& ProductStateModule.getPhotosSelected.length > 1
		) {
			const button = {
				id: 'objectToolbarSwapPhoto',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-exchange-alt',
				subscript: this.$t('objectToolbar.swapPhoto'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					const templateStateId = this.objectModel.templatestateid
						? this.objectModel.templatestateid
						: null;
					const templatePositions = ProductStateModule.getPageTemplatePositions(this.pageModel);
					const photoPositionState = templateStateId && templatePositions.length
						? _.findWhere(
							templatePositions,
							{ id: templateStateId },
						)
						: null;

					EventBus.$emit(
						'select:photo:position',
						photoPositionState,
					);
				},
			};

			if (this.productModel && !OfferingGroups(
				this.productModel.group,
				['PrintTypes'],
			)) {
				return button;
			} if (this.offeringModel && this.offeringModel.minprintpages == this.offeringModel.maxpages) {
				return button;
			} if (this.offeringModel && this.offeringModel.hasback) {
				const firstPageModel = ProductStateModule.getPageByNumber(0);
				if (firstPageModel?.editable) {
					return button;
				}
			}
		}

		return undefined;
	}

	private get buttonZoom(): ToolbarButton | undefined {
		if (this.objectModel
			&& this.objectModel.type == 'photo'
			&& this.objectModel.photoid
		) {
			const photoModel = PhotosModule.getById(this.objectModel.photoid);
			const photoData = photoModel
				? {
					width: photoModel.full_width,
					height: photoModel.full_height,
				}
				: undefined;
			const maxsize = maxObjectSize(
				{
					maxwidth: this.objectModel.maxwidth,
					maxheight: this.objectModel.maxheight,
					cropwidth: this.objectModel.cropwidth,
					cropheight: this.objectModel.cropheight,
					type: this.objectModel.type,
				},
				photoData,
				this.offeringModel,
			);
			const hasMaxWidth = this.objectModel.cropwidth > 0.99 * this.objectModel.maxwidth
				&& this.objectModel.width > 0.99 * maxsize.width;
			const hasMaxHeight = this.objectModel.cropheight > 0.99 * this.objectModel.maxheight
				&& this.objectModel.height > 0.99 * maxsize.height;

			if (!hasMaxWidth || !hasMaxHeight) {
				const zoomvalues = [];
				for (let x = 1; x <= 100; x += 1) {
					zoomvalues.push(x);
				}
				const specs = PageObject.specs(
					this.objectModel,
					this.pageModel,
				);

				return {
					id: 'zoom',
					type: 'objecttoolbar',
					class: 'button',
					faClass: 'fas fa-search',
					subscript: this.$t('objectToolbar.zoom'),
					scrollbars: [
						{
							type: 'scale',
							text: this.$t('objectToolbar.zoom'),
							value: Math.round(100 * specs.zoomValue),
							values: zoomvalues,
							startObject: JSON.parse(JSON.stringify(this.objectModel)),
							onOpen() {
								AppStateModule.setObjectLock();
							},
							onChange: (value: number, scrollbar) => {
								if (!this.objectModel) {
									throw new Error('Missing required objectModel to perform operation');
								}

								if (scrollbar.startObject) {
									const zoomValue = value / 100;
									zoomObject(
										this.objectModel,
										zoomValue,
										scrollbar.startObject,
										this.pageModel,
									);
								}
							},
							onDragEnd: () => {
								ProductStateModule.pushHistory();
							},
						},
					],
				};
			}
		}

		return undefined;
	}

	protected get buttons(): ToolbarButton[] {
		const buttons: ToolbarButton[] = [];
		const { objectModel } = this;

		if (!objectModel) {
			return buttons;
		}

		if (this.buttonBack) {
			buttons.push(this.buttonBack);
		}

		if (this.activeAlign) {
			if (this.subButtonFontAlignLeft) {
				buttons.push(this.subButtonFontAlignLeft);
			}
			if (this.subButtonFontAlignCenter) {
				buttons.push(this.subButtonFontAlignCenter);
			}
			if (this.subButtonFontAlignRight) {
				buttons.push(this.subButtonFontAlignRight);
			}

			return buttons;
		}

		if (this.activeFit) {
			if (this.subButtonFitCover) {
				buttons.push(this.subButtonFitCover);
			}
			if (this.subButtonFitContain) {
				buttons.push(this.subButtonFitContain);
			}

			return buttons;
		}

		if (this.activeFilter) {
			const sorted = _.sortBy(
				this.subButtonsFilter,
				'serialnumber',
			);
			sorted.forEach((button) => {
				buttons.push(button);
			});

			return buttons;
		}

		if (this.activeMask) {
			const sorted = _.sortBy(
				this.subButtonsMask,
				'serialnumber',
			);
			sorted.forEach((button) => {
				buttons.push(button);
			});

			return buttons;
		}

		if (this.activeStack) {
			if (this.subButtonStackBack) {
				buttons.push(this.subButtonStackBack);
			}
			if (this.subButtonStackFront) {
				buttons.push(this.subButtonStackFront);
			}

			return buttons;
		}

		if (this.buttonRemove) {
			buttons.push(this.buttonRemove);
		}

		if (this.buttonEditText) {
			buttons.push(this.buttonEditText);
		}

		if (this.buttonFontFace) {
			buttons.push(this.buttonFontFace);
		}

		if (this.buttonFontSize) {
			buttons.push(this.buttonFontSize);
		}

		if (this.buttonFontBold) {
			buttons.push(this.buttonFontBold);
		}

		if (this.buttonFontItalic) {
			buttons.push(this.buttonFontItalic);
		}

		if (this.buttonFontColor) {
			buttons.push(this.buttonFontColor);
		}

		if (this.buttonFontAlign) {
			buttons.push(this.buttonFontAlign);
		}

		if (this.buttonSwapPhoto) {
			buttons.push(this.buttonSwapPhoto);
		}

		if (this.buttonFilter) {
			buttons.push(this.buttonFilter);
		}

		if (this.buttonMask) {
			buttons.push(this.buttonMask);
		}

		if (this.buttonFit) {
			buttons.push(this.buttonFit);
		}

		if (this.buttonZoom) {
			buttons.push(this.buttonZoom);
		}

		if (this.buttonRotate) {
			buttons.push(this.buttonRotate);
		}

		if (this.buttonCrop) {
			buttons.push(this.buttonCrop);
		}

		if (this.buttonBorder) {
			buttons.push(this.buttonBorder);
		}

		if (this.buttonStack) {
			buttons.push(this.buttonStack);
		}

		// Note: we set the value of this.buttons once, so that the
		// vue listeners are triggered once
		return buttons;
	}

	private get photoEffects() {
		const effects: PhotoFilter[] = [
			'none',
			...ConfigModule['features.imageEffects'],
		];

		return effects;
	}

	private get subButtonFitCover(): ToolbarButton | undefined {
		if (!this.objectModel
			|| !this.activeFit
			|| !this.objectModel.photoid
			|| !this.objectModel.templatestateid
		) {
			return undefined;
		}

		return {
			id: 'fit_cover',
			type: 'objecttoolbar',
			class: 'button',
			miClass: 'aspect_ratio',
			pressed: Boolean(this.objectModel.fillMethod === 'cover'),
			subscript: this.$t('objectToolbar.fit_cover'),
			target: () => {
				if (!this.objectModel) {
					throw new Error('Missing required objectModel to perform operation');
				}

				ProductState.changePageObjectFill(
					this.objectModel,
					'cover',
				);
			},
		};
	}

	private get subButtonFitContain(): ToolbarButton | undefined {
		if (!this.objectModel
			|| !this.activeFit
			|| !this.objectModel.photoid
			|| !this.objectModel.templatestateid
		) {
			return undefined;
		}

		return {
			id: 'fit_contain',
			type: 'objecttoolbar',
			class: 'button',
			miClass: 'fit_screen',
			pressed: Boolean(this.objectModel.fillMethod === 'contain'),
			subscript: this.$t('objectToolbar.fit_contain'),
			target: () => {
				if (!this.objectModel) {
					throw new Error('Missing required objectModel to perform operation');
				}

				ProductState.changePageObjectFill(
					this.objectModel,
					'contain',
				);
			},
		};
	}

	private get subButtonFontAlignCenter(): ToolbarButton | undefined {
		if (this.objectModel && this.activeAlign) {
			return {
				id: 'fontalign_center',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-align-center',
				subscript: this.$t('objectToolbar.textAlignCenter'),
				pressed: this.objectModel.align
					? this.objectModel.align == 'Center'
					: false,
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						align: 'Center',
					});
					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get subButtonFontAlignLeft(): ToolbarButton | undefined {
		if (this.objectModel && this.activeAlign) {
			return {
				id: 'fontalign_left',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-align-left',
				subscript: this.$t('objectToolbar.textAlignLeft'),
				pressed: this.objectModel.align
					? this.objectModel.align == 'Left'
					: false,
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						align: 'Left',
					});
					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get subButtonFontAlignRight(): ToolbarButton | undefined {
		if (this.objectModel && this.activeAlign) {
			return {
				id: 'fontalign_right',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-align-right',
				subscript: this.$t('objectToolbar.textAlignRight'),
				pressed: this.objectModel.align
					? this.objectModel.align == 'Right'
					: false,
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						align: 'Right',
					});
					ProductStateModule.pushHistory();
				},
			};
		}

		return undefined;
	}

	private get subButtonStackBack(): ToolbarButton | undefined {
		if (this.objectModel && this.activeStack) {
			return {
				id: 'stack_back',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-share fa-rotate-180',
				subscript: this.$t('objectToolbar.stackToBack'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					let maxz = ProductStateModule.getPageMaxZ(this.pageModel);

					// Increase z-axis of all other objects
					ProductStateModule.getPageObjects(this.pageModel).forEach((m) => {
						if (!this.objectModel) {
							throw new Error('Missing required objectModel to perform operation');
						}

						if (m.id != this.objectModel.id) {
							maxz += 1;
							ProductStateModule.changePageObject({
								id: m.id,
								z_axis: maxz,
							});
						}
					});
					ProductStateModule.pushHistory();

					this.activeStack = false;
				},
			};
		}

		return undefined;
	}

	private get subButtonStackFront(): ToolbarButton | undefined {
		if (this.objectModel && this.activeStack) {
			return {
				id: 'stack_front',
				type: 'objecttoolbar',
				class: 'button',
				faClass: 'fas fa-share',
				subscript: this.$t('objectToolbar.stackToFront'),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					const maxz = ProductStateModule.getPageMaxZ(this.pageModel);
					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						z_axis: maxz + 1,
					});
					ProductStateModule.pushHistory();

					this.activeStack = false;
				},
			};
		}

		return undefined;
	}

	private get subButtonsFilter(): ToolbarButton[] {
		const buttons: ToolbarButton[] = [];
		this.photoEffects.forEach((effect, i) => {
			buttons.push({
				id: `objecttoolbar_effect_${effect}`,
				serialnumber: i,
				type: 'photoeffect',
				thumbnail: this.renderedFilters[effect],
				subscript: this.$t(`effects.${effect}`),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					ProductStateModule.changePageObject({
						id: this.objectModel.id,
						effect: effect == 'none' ? null : effect,
					});

					ProductStateModule.pushHistory();
				},
			});
		});

		return buttons;
	}

	private get subButtonsMask(): ToolbarButton[] {
		const buttons: ToolbarButton[] = [];
		const masks = Object.keys(this.renderedMasks) as Exclude<PhotoMask, 'squareLocked'>[];

		masks.forEach((mask, i) => {
			buttons.push({
				id: `objecttoolbar_mask_${mask}`,
				serialnumber: i,
				type: 'photoeffect',
				thumbnail: this.renderedMasks[mask],
				subscript: this.$t(`masks.${mask}`),
				target: () => {
					if (!this.objectModel) {
						throw new Error('Missing required objectModel to perform operation');
					}

					applyMask(
						this.objectModel,
						mask == 'none' ? null : mask,
					);
				},
			});
		});

		return buttons;
	}

	private get fontModel() {
		if (this.objectModel && this.objectModel.fontface) {
			return FontModule.getById(this.objectModel.fontface);
		}

		return undefined;
	}

	private get offeringModel() {
		return ProductStateModule.getOffering;
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get scrollBarWidth() {
		return this.viewportWidth >= 768
			? ((Math.min(
				1440,
				this.viewportWidth,
			) - 70) / this.scrollbars.length) - 40
			: this.viewportWidth - 80; // Note: iOS swipe from left edge of screen will do browser history back, so keep that space empty
	}

	protected get toolbarClass() {
		let className = 'mainToolbar ';
		className += this.toolbarSize ? this.toolbarSize : 'toolbarLow';
		return className;
	}

	private activeAlign = false;

	private activeFilter = false;

	private activeFit = false;

	private activeMask = false;

	private activeStack = false;

	private objectModel: PI.PageObjectModel | null = null;

	private renderedFilters = renderedFilters;

	private renderedMasks = renderedMasks;

	private scrollbars: Scrollbar[] = [];

	private toolbarSize: string | null = null;

	private viewportWidth = 0;

	protected created() {
		this.setViewportWidth();
	}

	protected mounted() {
		// Open object toolbar when object is selected
		EventBus.$on(
			'selectObject',
			this.objectSelected,
		);
		window.addEventListener(
			'resize',
			this.setViewportWidth,
		);
	}

	protected beforeDestroy() {
		EventBus.$off(
			'selectObject',
			this.objectSelected,
		);
		window.removeEventListener(
			'resize',
			this.setViewportWidth,
		);
	}

	protected closeScrollbar() {
		this.scrollbars = [];
		this.toolbarSize = null;
	}

	protected closeToolbar() {
		ProductStateModule.deselectPageObjects();
	}

	protected getButtonColor(i: number) {
		const arrColors = ['7D4997', 'FF7B63', 'D81978', '34B298', '43C0E8'];
		const mod = i % arrColors.length;
		return `#${arrColors[mod]}`;
	}

	private objectSelected(
		pageModel: PI.PageModel,
		objectModelId: string,
		options?: {
			editText: boolean;
			showToolbar: boolean;
		},
	) {
		const defaults = {
			showToolbar: true,
			editText: false,
		};

		// Merge default properties with setup parameters
		options = options ? merge(
			defaults,
			options,
		) : defaults;

		if (options.showToolbar) {
			this.objectModel = null;
			this.resetToolbar();

			// Get selected object
			const objectModel = ProductStateModule.getPageObject(objectModelId);
			if (objectModel) {
				this.objectModel = objectModel;

				if (objectModel.type == 'text'
					&& (
						!objectModel.text
						|| objectModel.text.length === 0
						|| options.editText
					)
				) {
					this.$emit('editText');
				}
			}
		}
	}

	protected pressButton(buttonModel: ToolbarButton) {
		if (buttonModel.scrollbars) {
			this.scrollbars = buttonModel.scrollbars;

			if (this.scrollbars.length > 1 && this.viewportWidth < 768) {
				this.toolbarSize = 'toolbarExtraHigh';
			}
		}
	}

	private renderFilter(
		src: string | File,
		effect: PhotoFilter,
	) {
		if (effect == 'none') {
			this.renderedFilters[effect] = typeof src === 'string' && src.substring(
				0,
				4,
			) == 'http'
				? appendUrlParameter(
					src,
					'noCorsHeader',
				)
				: src as string;
		} else {
			loadImage(
				src,
				{
					effect,
					forceCopy: src instanceof File,
				},
			).then(({ image }) => {
				this.renderedFilters[effect] = image.src;
			}).catch(() => {
				this.renderedFilters[effect] = warningSvg;
			});
		}
	}

	private renderFilters() {
		const filters = this.photoEffects;

		// Reset filter images
		filters.forEach((filter) => {
			this.renderedFilters[filter] = spinnerGif;
		});

		if (!this.objectModel) {
			throw new Error('Cannot render mask, missing required objectModel');
		}

		const maxSize = 140;

		PageObject
			.getPhotoSource(
				this.objectModel,
				{
					resolution: 'thumb',
					maxWidth: maxSize,
					maxHeight: maxSize,
				},
			)
			.then((urlOrFile) => {
				if (urlOrFile instanceof File) {
					loadImage(
						urlOrFile,
						{
							scale: maxSize,
							forceCopy: true,
						},
					).then(({ image }) => {
						filters.forEach((effect) => {
							this.renderFilter(
								image.src,
								effect,
							);
						});
					}).catch((e) => {
						console.log(e);
					});
				} else {
					filters.forEach((effect) => {
						this.renderFilter(
							urlOrFile,
							effect,
						);
					});
				}
			}).catch(() => {
				// Swallow error: no action required
			});
	}

	private renderMask(
		src: string,
		mask: Exclude<PhotoMask, 'squareLocked'>,
	) {
		if (mask == 'none') {
			this.renderedMasks[mask] = src.substring(
				0,
				4,
			) == 'http'
				? appendUrlParameter(
					src,
					'noCorsHeader',
				)
				: src;
		} else {
			loadImage(src)
				.then(({ image }) => ImageMaskClass(
					image,
					maskPresets[mask],
					{ correctRatio: true },
				))
				.then(([res]) => res.genImage())
				.then((genImage) => {
					this.renderedMasks[mask] = genImage.src;
				})
				.catch(() => {
					this.renderedMasks[mask] = warningSvg;
				});
		}
	}

	private renderMasks() {
		const masks = Object.keys(this.renderedMasks) as Exclude<PhotoMask, 'squareLocked'>[];

		// Reset mask images
		masks.forEach((mask) => {
			this.renderedMasks[mask] = spinnerGif;
		});

		if (!this.objectModel) {
			throw new Error('Cannot render mask, missing required objectModel');
		}

		const maxSize = 140;

		PageObject
			.getPhotoSource(
				this.objectModel,
				{
					resolution: 'thumb',
					maxWidth: maxSize,
					maxHeight: maxSize,
				},
			)
			.then((urlOrFile) => {
				if (urlOrFile instanceof File) {
					loadImage(
						urlOrFile,
						{
							scale: maxSize,
							forceCopy: true,
						},
					).then(({ image }) => {
						masks.forEach((mask) => {
							this.renderMask(
								image.src,
								mask,
							);
						});
					}).catch((e) => {
						console.log(e);
					});
				} else {
					masks.forEach((mask) => {
						this.renderMask(
							urlOrFile,
							mask,
						);
					});
				}
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	private resetToolbar() {
		this.scrollbars = [];
		this.toolbarSize = null;

		this.activeAlign = false;
		this.activeFilter = false;
		this.activeFit = false;
		this.activeMask = false;
		this.activeStack = false;
	}

	private setViewportWidth() {
		const viewport = getViewport();
		this.viewportWidth = viewport.width;
	}
}
