import './defines';
import ColorConverter from '@sosocio/color-converter';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import { ExceededColorSelectionPageObjectModel } from 'interfaces/app';
import {
	PageObjectColorReplacementModel,
	PageObjectColorReplacementModels,
} from 'interfaces/project';
import { ConfigModule } from 'store';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ExceededColorSelectionView',
})
export default class ExceededColorSelectionView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the object model in which the color selection is exceeded detected and could be automatically converted to `n` colors',
		required: true,
		schema: 'ExceededColorSelectionPageObjectModel',
		type: Object,
	})
	public readonly objectModel!: ExceededColorSelectionPageObjectModel;

	@Prop({
		description: 'Defines the maximum number of colors allowed in the logo',
		type: Number,
		required: true,
	})
	public readonly value!: number;

	protected get svgContentAttributes(): Record<string, string> {
		if (!this.objectModel._vectorSVG) {
			return {};
		}

		const temporaryDivElement = document.createElement('div');
		temporaryDivElement.innerHTML = this.objectModel._vectorSVG;
		const svgElement = temporaryDivElement.firstChild as SVGElement;

		return Object
			.keys(svgElement.attributes)
			.reduce(
				(attributes, key) => {
					const attribute = svgElement.attributes.item(Number(key));

					if (attribute) {
						attributes[attribute.name] = attribute.value;
					}

					return attributes;
				},
				{} as Record<string, string>,
			);
	}

	protected get svgContentBeforeHTML(): string {
		if (!this.objectModel._vectorSVG) {
			return '';
		}

		const {
			_vectorColors,
			_vectorSVG,
			colorReplacement,
		} = this.objectModel;
		const temporaryDivElement = document.createElement('div');

		if (
			colorReplacement?.length
			&& _vectorColors
		) {
			temporaryDivElement.innerHTML = svgUtils.replaceColors(
				_vectorSVG,
				_vectorColors,
				colorReplacement.map((replacement) => ({
					color: replacement.color,
					replace: (
						replacement.replace.visual
						|| replacement.replace.real
					),
				})),
			);
		} else {
			temporaryDivElement.innerHTML = _vectorSVG;
		}

		return (temporaryDivElement.firstChild as SVGElement).innerHTML;
	}

	protected imageUrlColorLimit = '';

	protected svgContentAfterHTML = '';

	@Watch(
		'objectModel',
		{
			immediate: true,
			deep: true,
		},
	)
	protected onObjectModelChange(): void {
		this.setSVGContentAfterHTML();
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.value) {
			let colorLimitFilename = 'full';

			if (this.value === 1) {
				colorLimitFilename = 'one';
			} else if (this.value === 2) {
				colorLimitFilename = 'two';
			} else if (this.value === 3) {
				colorLimitFilename = 'three';
			} else if (this.value === 4) {
				colorLimitFilename = 'four';
			}

			import(/* webpackMode: "eager" */ `@root/img/colors/${colorLimitFilename}.svg`)
				.then((imageUrl) => {
					this.imageUrlColorLimit = imageUrl.default;
				});
			this.setSVGContentAfterHTML();
		}
	}

	private async setSVGContentAfterHTML(): Promise<void> {
		if (
			!this.objectModel._vectorSVG
			|| !this.objectModel._vectorColors
		) {
			return;
		}

		const {
			_vectorColors,
			_vectorSVG,
		} = this.objectModel;
		const temporaryDivElement = document.createElement('div');
		const svgMergedColorGroups = await svgUtils.limitColorsToTopN(
			_vectorSVG as string,
			_vectorColors,
			{
				topN: this.value,
				threshold: ConfigModule['logoColors.convertToNColorsThreshold'],
			},
		);
		const backgroundColorReplacementFound = this.objectModel.colorReplacement?.find((replacement) => replacement.color === _vectorColors.background?.color);
		const colorReplacement: PageObjectColorReplacementModels = (
			backgroundColorReplacementFound
				? [{
					color: backgroundColorReplacementFound.color,
					replace: {
						...backgroundColorReplacementFound.replace,
					},
				}]
				: []
		);
		const colorConverter = new ColorConverter();

		// eslint-disable-next-line no-restricted-syntax
		for (const mergedColorGroup of svgMergedColorGroups.foreground) {
			// eslint-disable-next-line no-restricted-syntax
			for (const colorToBeReplaced of mergedColorGroup.colors) {
				colorConverter.hex6 = {
					value: mergedColorGroup.color.slice(1),
				};
				const objectModelColorReplacement: PageObjectColorReplacementModel = {
					color: colorToBeReplaced,
					replace: {
						real: `#${colorConverter.hex6.value}`,
					},
				};

				try {
					colorConverter.pantone = {
						name: colorConverter.pantone.name,
					};
					objectModelColorReplacement.replace.real = `#${colorConverter.hex6.value}`;

					if (colorConverter.hex6.showAs) {
						objectModelColorReplacement.replace.visual = `#${colorConverter.hex6.showAs}`;
					}
				} catch {
					// Swallow error: no action required
				}

				colorReplacement.push(objectModelColorReplacement);
			}
		}

		if (colorReplacement.length) {
			temporaryDivElement.innerHTML = svgUtils.replaceColors(
				_vectorSVG,
				_vectorColors,
				colorReplacement.map((replacement) => ({
					color: replacement.color,
					replace: (
						replacement.replace.visual
						|| replacement.replace.real
					),
				})),
			);
		} else {
			temporaryDivElement.innerHTML = _vectorSVG;
		}

		this.svgContentAfterHTML = (temporaryDivElement.firstChild as SVGElement).innerHTML;
	}
}
