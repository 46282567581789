interface IntersectObject {
	x: number;
	y: number;
	width: number;
	height: number;
	borderWidth: number;
	rotation: number;
}

export default function intersect(
	a: IntersectObject,
	b: IntersectObject,
): boolean {
	const aBorderTop = a.borderWidth;
	const aBorderRight = a.borderWidth;
	const aBorderBottom = a.borderWidth;
	const aBorderLeft = a.borderWidth;

	let RectA = {
		X1: a.x - aBorderLeft,
		X2: a.x + a.width + aBorderRight,
		Y1: a.y - aBorderTop,
		Y2: a.y + a.height + aBorderBottom,
	};

	const bBorderTop = b.borderWidth;
	const bBorderRight = b.borderWidth;
	const bBorderBottom = b.borderWidth;
	const bBorderLeft = b.borderWidth;

	let RectB = {
		X1: b.x - bBorderLeft,
		X2: b.x + b.width + bBorderRight,
		Y1: b.y - bBorderTop,
		Y2: b.y + b.height + bBorderBottom,
	};

	// Rotated objects need to have their x- and y-axis adjusted
	if (a.rotation === 90 || a.rotation === 270) {
		const CenterA = {
			X: (RectA.X1 + RectA.X2) / 2,
			Y: (RectA.Y1 + RectA.Y2) / 2,
		};
		const X1 = CenterA.X - (RectA.Y2 - RectA.Y1) / 2;
		const Y1 = CenterA.Y - (RectA.X2 - RectA.X1) / 2;
		const RectAr: {
			X1: number;
			X2: number;
			Y1: number;
			Y2: number;
		} = {
			X1,
			X2: X1 + (RectA.Y2 - RectA.Y1),
			Y1,
			Y2: Y1 + (RectA.X2 - RectA.X1),
		};
		RectA = RectAr;
	}
	if (b.rotation === 90 || b.rotation === 270) {
		const CenterB = {
			X: (RectB.X1 + RectB.X2) / 2,
			Y: (RectB.Y1 + RectB.Y2) / 2,
		};
		const X1 = CenterB.X - (RectB.Y2 - RectB.Y1) / 2;
		const Y1 = CenterB.Y - (RectB.X2 - RectB.X1) / 2;
		const RectBr: {
			X1: number;
			X2: number;
			Y1: number;
			Y2: number;
		} = {
			X1,
			X2: X1 + (RectB.Y2 - RectB.Y1),
			Y1,
			Y2: Y1 + (RectB.X2 - RectB.X1),
		};
		RectB = RectBr;
	}

	const overlap = RectA.X1 < RectB.X2
		&& RectA.X2 > RectB.X1
		&& RectA.Y1 < RectB.Y2
		&& RectA.Y2 > RectB.Y1;

	return overlap;
}
