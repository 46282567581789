import _ from 'underscore';

export interface BrowserDetectionResult {
	majorVersion: number;
	name: string;
	os: string;
	version: string;
}

export default function detectBrowser(): BrowserDetectionResult {
	/*
    * Source: http://stackoverflow.com/questions/12489546/how-to-get-browsers-name-client-side
    */

	const nAgt = navigator.userAgent;
	let browserName = navigator.appName;
	let fullVersion = `${parseFloat(navigator.appVersion)}`;
	let majorVersion = parseInt(
		navigator.appVersion,
		10,
	);
	let os = 'unknown';

	// In Opera, the true version is after "Opera" or after "Version"
	if (nAgt.indexOf('Opera') != -1) {
		let verOffset = nAgt.indexOf('Opera');
		browserName = 'Opera';
		fullVersion = nAgt.substring(verOffset + 6);
		if (nAgt.indexOf('Version') != -1) {
			verOffset = nAgt.indexOf('Version');
			fullVersion = nAgt.substring(verOffset + 8);
		}
	} else if (nAgt.indexOf('MSIE') != -1) {
		// In MSIE, the true version is after "MSIE" in userAgent
		const verOffset = nAgt.indexOf('MSIE');
		browserName = 'Microsoft Internet Explorer';
		fullVersion = nAgt.substring(verOffset + 5);
	} else if (nAgt.indexOf('Trident/') != -1) {
		// IE11 and up
		browserName = 'Microsoft Internet Explorer';
		const rv = nAgt.indexOf('rv:');
		fullVersion = nAgt.substring(
			rv + 3,
			nAgt.indexOf(
				'.',
				rv,
			),
		);
	} else if (nAgt.indexOf('Edge/') != -1) {
		// Edge
		const verOffset = nAgt.indexOf('Edge/');
		browserName = 'Edge';
		fullVersion = nAgt.substring(
			verOffset + 5,
			nAgt.indexOf(
				'.',
				verOffset,
			),
		);
	} else if (nAgt.indexOf('Chrome') != -1) {
		// In Chrome, the true version is after "Chrome"
		const verOffset = nAgt.indexOf('Chrome');
		browserName = 'Chrome';
		fullVersion = nAgt.substring(verOffset + 7);
	} else if (nAgt.indexOf('Safari') != -1) {
		// In Safari, the true version is after "Safari" or after "Version"
		let verOffset = nAgt.indexOf('Safari');
		browserName = 'Safari';
		fullVersion = nAgt.substring(verOffset + 7);
		if (nAgt.indexOf('Version') != -1) {
			verOffset = nAgt.indexOf('Version');
			fullVersion = nAgt.substring(verOffset + 8);
		}
	} else if (nAgt.indexOf('Firefox') != -1) {
		// In Firefox, the true version is after "Firefox"
		const verOffset = nAgt.indexOf('Firefox');
		browserName = 'Firefox';
		fullVersion = nAgt.substring(verOffset + 8);
	} else if (nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/')) {
		// In most other browsers, "name/version" is at the end of userAgent
		const nameOffset = nAgt.lastIndexOf(' ') + 1;
		const verOffset = nAgt.lastIndexOf('/');
		browserName = nAgt.substring(
			nameOffset,
			verOffset,
		);
		fullVersion = nAgt.substring(verOffset + 1);
		if (browserName.toLowerCase() == browserName.toUpperCase()) {
			browserName = navigator.appName;
		}
	}
	// trim the fullVersion string at semicolon/space if present
	if (fullVersion.indexOf(';') != -1) {
		const ix = fullVersion.indexOf(';');
		fullVersion = fullVersion.substring(
			0,
			ix,
		);
	}
	if (fullVersion.indexOf(' ') != -1) {
		const ix = fullVersion.indexOf(' ');
		fullVersion = fullVersion.substring(
			0,
			ix,
		);
	}

	majorVersion = parseInt(
		`${fullVersion}`,
		10,
	);
	if (Number.isNaN(majorVersion)) {
		fullVersion = `${parseFloat(navigator.appVersion)}`;
		majorVersion = parseInt(
			navigator.appVersion,
			10,
		);
	}

	const clientStrings = [
		{ s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
		{ s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
		{ s: 'Windows Vista', r: /Windows NT 6.0/ },
		{ s: 'Windows Server 2003', r: /Windows NT 5.2/ },
		{ s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
		{ s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
		{ s: 'Windows 98', r: /(Windows 98|Win98)/ },
		{ s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
		{ s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: 'Windows CE', r: /Windows CE/ },
		{ s: 'Windows 3.11', r: /Win16/ },
		{ s: 'Windows Phone 8', r: /Windows Phone 8/ },
		{ s: 'Android', r: /Android/ },
		{ s: 'Open BSD', r: /OpenBSD/ },
		{ s: 'Sun OS', r: /SunOS/ },
		{ s: 'Linux', r: /(Linux|X11)/ },
		{ s: 'iOS', r: /(iPhone|iPad|iPod)/ },
		{ s: 'Mac OS X', r: /Mac OS X/ },
		{ s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: 'QNX', r: /QNX/ },
		{ s: 'UNIX', r: /UNIX/ },
		{ s: 'BeOS', r: /BeOS/ },
		{ s: 'OS/2', r: /OS\/2/ },
		{ s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
	];
	_.find(
		clientStrings,
		(cs) => {
			if (cs.r.test(nAgt)) {
				os = cs.s;
				return true;
			}
			return false;
		},
	);

	return {
		name: browserName,
		version: fullVersion,
		majorVersion,
		os,
	};
}
