import './defines';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import {
	HeaderMenuProductCategories,
} from 'interfaces/app';
import Template from './template.vue';

@Component({
	directives: {
		ClickOutside,
	},
})
export default class HeaderMenuView extends Vue.extend(Template) {
	@Prop({
		default: false,
		description: 'Indicates whether the component displays information about app',
		type: Boolean,
	})
	public readonly hasAboutApp!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the app review feature is available.',
		type: Boolean,
	})
	public readonly hasAppReview!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the live chat feature is currently active',
		type: Boolean,
	})
	public readonly hasLiveChat!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the component has a set of Product Categories',
		type: Boolean,
	})
	public readonly hasProductCategories!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the component displays pricing information',
		type: Boolean,
	})
	public readonly hasPricing!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the component displays promotions information',
		type: Boolean,
	})
	public readonly hasPromotions!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the component displays shipping information',
		type: Boolean,
	})
	public readonly hasShipping!: boolean;

	@Prop({
		default: false,
		description: 'Indicates whether the component displays support information.',
		type: Boolean,
	})
	public readonly hasSupport!: boolean;

	@Prop({
		default: [],
		description: 'Defines a list of product categories',
		schema: 'HeaderMenuProductCategories',
		type: Array,
	})
	public readonly productCategories!: HeaderMenuProductCategories;

	private open = false;

	protected clickOutside() {
		if (this.open) {
			this.open = false;
		}
	}

	protected openChat() {
		this.open = false;
		this.$emit('open-chat');
	}

	protected goToAboutApp() {
		this.$emit('open-about-app');
	}

	protected goToCategory(category: HeaderMenuProductCategories[number]) {
		this.$emit(
			'open-category',
			category,
		);
	}

	protected goToCreate() {
		this.open = false;
		this.$emit('open-create');
	}

	protected goToPricing() {
		this.$emit('open-pricing');
	}

	protected goToPromotions() {
		this.$emit('open-promotions');
	}

	protected goToShipping() {
		this.$emit('open-shipping');
	}

	protected goToSupport() {
		this.$emit('open-support');
	}

	protected goToWebsite() {
		this.$emit('open-website');
	}

	protected reviewApp() {
		this.$emit('open-review');
	}

	protected toggleOpen() {
		this.open = !this.open;
	}
}
