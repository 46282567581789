import './defines';
import InstagramIcon from '@root/svg/instagram-icon.svg';
import connector from 'controllers/connector';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		InstagramIcon,
	},
})
export default class InstagramButtonView extends Vue.extend(Template) {
	private disabled = true;

	protected mounted() {
		connector
			.setup('instagram')
			.then(() => connector.init('instagram'))
			.then(() => {
				this.disabled = false;
			})
			.catch((e: Error) => {
				console.log(e);
			});
	}

	protected clickButton() {
		if (!this.disabled) {
			this.$emit('buttonClicked');
		}
	}
}
