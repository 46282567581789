import { render, staticRenderFns } from "./template.vue?vue&type=template&id=40d4acac&scoped=true&lang=pug&"
var script = {}
import style0 from "./template.vue?vue&type=style&index=0&id=40d4acac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d4acac",
  null
  
)

export default component.exports