import ajax from 'controllers/ajax';

export default (
	url: string,
): Promise<string> => ajax
	.request(
		{
			url: `/api/hema/delegate?url=${window.encodeURIComponent(url)}`,
			method: 'get',
		},
		{
			auth: true,
			debug: {
				log: false,
			},
		},
	)
	.then((response) => {
		if (!response.data.url) {
			throw new Error('Invalid data response');
		}

		return response.data.url;
	});
