import './defines';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import { EditorToolbarThemes } from 'interfaces/app';
import {
	ProductModel,
	ThemeModel,
} from 'interfaces/database';
import { OfferingGroups } from 'settings/offerings';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorThemeSelectorView',
	components: {
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
	},
})
export default class EditorThemeSelectorView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the selected theme model',
			schema: 'ThemeModel',
			type: Object,
		},
	)
	public readonly value?: ThemeModel;

	@Prop({
		default: 2,
		description: 'Defines the number of columns in the editor theme selector',
		type: Number,
	})
	public readonly columns!: number;

	@Prop({
		description: 'Defines the project group id, used to show or not a confirmation before changing the theme (cards, single page and photo frame box always change immediately)',
		required: true,
		type: Number,
	})
	public readonly projectGroupId!: ProductModel['group'];

	@Prop({
		default: 'auto',
		description: "Defines the size (width/height) of the theme image, if set to auto it's going to use the available width divided by the number of columns",
		type: [Number, String],
	})
	public readonly themeImageSize!: string | number;

	@Prop({
		description: 'Defines the available themes to select from',
		required: true,
		schema: 'EditorToolbarThemes',
		type: Array,
	})
	public readonly themes!: EditorToolbarThemes;

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, number | string> {
		return {
			'--columns': this.columns,
		};
	}

	protected get computedThemeImageStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			this.themeImageSize
			&& this.themeImageSize !== 'auto'
		) {
			if (typeof this.themeImageSize === 'number') {
				styles.height = `${this.themeImageSize}px`;
				styles.width = `${this.themeImageSize}px`;
			} else {
				styles.height = this.themeImageSize;
				styles.width = this.themeImageSize;
			}
		}

		return styles;
	}

	private internalValue: ThemeModel | null = null;

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.value?.id !== this.internalValue?.id) {
			if (this.value) {
				this.internalValue = {
					...this.value,
				};
			} else {
				this.internalValue = null;
			}
		}
	}

	protected onChange(themeId: ThemeModel['id']): void {
		new Promise<void>((resolve, reject) => {
			if (
				/**
				 * Cards always change immediately, no need to ask for confirmation,
				 * same for single pagers
				 */
				OfferingGroups(
					this.projectGroupId,
					[
						'Cards',
						'BasicProducts',
						'PhotoFrameBox',
					],
				)
			) {
				resolve();
			} else {
				const { close: closeConfirm } = this.$openDialogNew({
					header: {
						hasCloseButton: false,
						title: this.$t('views.editorThemeSelector.confirmDialog.title'),
					},
					body: {
						content: this.$t('views.editorThemeSelector.confirmDialog.content'),
					},
					footer: {
						buttons: [
							{
								id: 'confirm',
								text: this.$t('views.editorThemeSelector.confirmDialog.confirm'),
								click: () => {
									resolve();
									closeConfirm();
								},
							},
							{
								color: 'secondary',
								id: 'cancel',
								text: this.$t('views.editorThemeSelector.confirmDialog.cancel'),
								click: () => {
									reject();
									closeConfirm();
								},
							},
						],
					},
				});
			}
		})
			.then(() => {
				this.internalValue = this.themes.find((theme) => theme.id === themeId) || null;

				if (this.internalValue?.id !== this.value?.id) {
					this.$emit(
						'change',
						this.internalValue,
					);
				}
			})
			.catch(() => {
				const previousInternalValue = this.internalValue;

				if (previousInternalValue !== null) {
					this.internalValue = null;
				} else if (this.value) {
					this.internalValue = {
						...this.value,
					};
				} else {
					this.internalValue = null;
				}

				this.$nextTick(() => {
					this.internalValue = previousInternalValue;
				});
			});
	}
}
