import * as faceapi from 'face-api.js';
import * as PI from 'interfaces/project';
import * as DialogService from 'services/dialog';
import loadImage from 'services/load-image';
import {
	AppStateModule,
	PhotosModule,
	UploadModule,
} from 'store';
import appendUrlParameter from 'tools/append-url-parameter';
import _ from 'underscore';
import FaceDetectionView from 'views/face-detection';
import analytics from './analytics';
import upload from './upload';

interface BoundingBox {
	x1: number|null;
	x2: number|null;
	y1: number|null;
	y2: number|null;
}

class FaceRecognition {
	private model: 'tinyFaceDetector'|'ssdMobileNet' = 'tinyFaceDetector';

	private timestamp = 0;

	loadModels() {
		let assetsUrl = _.isUndefined(window.glStack) || window.glStack == 'local'
			? 'https://sosocio-local.s3-eu-west-1.amazonaws.com/'
			: window.glDataUrl;
		assetsUrl += 'js/vendor/faceapi/';

		if (this.model == 'tinyFaceDetector') {
			if (faceapi.nets.tinyFaceDetector.isLoaded) {
				return Promise.resolve();
			}

			return faceapi.loadTinyFaceDetectorModel(assetsUrl);
		}
		if (this.model == 'ssdMobileNet') {
			if (faceapi.nets.ssdMobilenetv1.isLoaded) {
				return Promise.resolve();
			}

			return faceapi.loadSsdMobilenetv1Model(assetsUrl);
		}

		return Promise.reject();
	}

	detectFaceBox(
		image: HTMLImageElement,
	): Promise<BoundingBox> {
		return new Promise((resolve, reject) => {
			if (!image) {
				reject(new Error('Could not find image'));
			} else {
				this.loadModels().then(() => {
					const maxSize = Math.max(
						image.width,
						image.height,
					);
					const scale = 250 / maxSize;
					const width = image.width * scale;
					const height = image.height * scale;

					AppStateModule.changeFaceDetection({
						width,
						height,
						image,
						detections: [],
					});

					this.timestamp = Date.now();

					const detectorOptions = this.model == 'tinyFaceDetector'
						? new faceapi.TinyFaceDetectorOptions({
							inputSize: 416,
							scoreThreshold: 0.3,
						})
						: new faceapi.SsdMobilenetv1Options({
							minConfidence: 0.5,
						});

					return faceapi.detectAllFaces(
						image,
						detectorOptions,
					).run();
				}).then((detections) => {
					analytics.trackPerformance(
						'Face Recognition',
						Math.floor((Date.now() - this.timestamp) / 1000),
					);

					// resize the detected boxes in case your displayed image has a different size than the original
					AppStateModule.changeFaceDetection({
						detections: faceapi.resizeResults(
							detections,
							{
								width: AppStateModule.faceDetection.width,
								height: AppStateModule.faceDetection.height,
							},
						),
					});

					const boundingBox: BoundingBox = {
						x1: null,
						x2: null,
						y1: null,
						y2: null,
					};

					if (detections.length) {
						boundingBox.x1 = detections.length ? 1 : 0;
						boundingBox.x2 = detections.length ? 0 : 1;
						boundingBox.y1 = detections.length ? 1 : 0;
						boundingBox.y2 = detections.length ? 0 : 1;

						for (let i = 0; i < detections.length; i += 1) {
							if (detections[i].relativeBox) {
								boundingBox.x1 = Math.min(
									detections[i].relativeBox.topLeft.x,
									boundingBox.x1,
								);
								boundingBox.x2 = Math.max(
									detections[i].relativeBox.topRight.x,
									boundingBox.x2,
								);
								boundingBox.y1 = Math.min(
									detections[i].relativeBox.topLeft.y,
									boundingBox.y1,
								);
								boundingBox.y2 = Math.max(
									detections[i].relativeBox.bottomLeft.y,
									boundingBox.y2,
								);
							}
						}
					}

					resolve(boundingBox);
				}).catch((err) => {
					reject(err);
				});
			}
		});
	}

	processPhotoModel(photoModel: PI.PhotoModel): Promise<void> {
		return new Promise((resolve, reject) => {
			let input: string|File|undefined;
			if (photoModel.url) {
				input = photoModel.url;
			} else if (photoModel.full_url) {
				input = photoModel.full_url;
			}

			if (input && typeof photoModel.id == 'number') {
				// Add CORS busting parameter to URL
				input = appendUrlParameter(
					input as string,
					'noCorsHeader',
				);
			}

			if (!input
				&& photoModel.source == 'upload'
				&& typeof photoModel.id == 'string'
				&& photoModel.externalId
			) {
				const uploadModel = UploadModule.find(photoModel.externalId);
				if (uploadModel) {
					input = upload.getLocalFile(uploadModel.id);
				}
			}

			if (!input) {
				reject(new Error('Could not find input'));
			} else {
				loadImage(
					input,
					{
						orientation: photoModel._orientation,
					},
				).then(({ image }) => this.detectFaceBox(image))
					.then((boundingBox) => {
						const photoData: OptionalExceptFor<PI.PhotoModel, 'id'> = {
							id: photoModel.id,
							fcx: null,
							fcy: null,
							fcw: null,
							fch: null,
						};

						if (boundingBox
							&& boundingBox.x1 !== null
							&& boundingBox.y1 !== null
							&& boundingBox.x2 !== null
							&& boundingBox.y2 !== null
							&& photoModel.full_width
							&& photoModel.full_height
						) {
							photoData.fcx = Math.round(boundingBox.x1 * photoModel.full_width);
							photoData.fcy = Math.round(boundingBox.y1 * photoModel.full_height);
							photoData.fcw = Math.round((boundingBox.x2 - boundingBox.x1) * photoModel.full_width);
							photoData.fch = Math.round((boundingBox.y2 - boundingBox.y1) * photoModel.full_height);
						}

						if (typeof photoModel.id === 'string') {
							PhotosModule.updateModel(photoData);
						} else {
							PhotosModule.putModel({
								id: photoModel.id,
								data: photoData,
							});
						}

						resolve();
					})
					.catch((err) => {
						reject(err);
					});
			}
		});
	}

	processPhotoModels(photoModels: PI.PhotoModel[]): Promise<void> {
		return new Promise((resolve, reject) => {
			const unprocessedPhotoModels = photoModels.filter(
				(photoModel) => (
					typeof photoModel.fcx === 'undefined'
					|| typeof photoModel.fcy === 'undefined'
					|| typeof photoModel.fcw === 'undefined'
					|| typeof photoModel.fch === 'undefined'
				),
			);

			if (unprocessedPhotoModels.length) {
				const {
					close: closeDialog,
					isOpen: isDialogOpen,
				} = DialogService.openDialog({
					header: {
						hasCloseButton: false,
						title: window.App.router.$t('dialogHeaderLoad'),
					},
					body: {
						component: FaceDetectionView,
						listeners: {
							closeDialog: () => {
								closeDialog();
							},
						},
					},
					width: 350,
				});

				if (isDialogOpen) {
					let promise = Promise.resolve();
					unprocessedPhotoModels.forEach((photoModel) => {
						promise = promise
							.catch(() => {
								// Swallow error: no action required
							})
							.finally(() => {
								if (!isDialogOpen) {
									return Promise.reject();
								}

								return this.processPhotoModel(photoModel);
							});
					});
					promise
						.catch(() => {
							// Swallow error: no action required
						})
						.finally(() => {
							if (isDialogOpen) {
								closeDialog();
							}

							resolve();
						});
				} else {
					reject();
				}
			} else {
				resolve();
			}
		});
	}
}

const faceRecognition = new FaceRecognition();
export default faceRecognition;
