import './defines';
import PriceCalculator from 'classes/price-calculator';
import * as DB from 'interfaces/database';
import { ServiceEvent } from 'services/service-event';
import { OfferingCountForBulkDiscount } from 'settings/offerings';
import {
	AppDataModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import PriceView from '../price';
import Template from './template.vue';

interface VariantOption {
	id: number;
	price?: number;
	text: string;
	image?: string;
}

// This Component is on the list to be deprecated
// (once all the labels have offering options configured in their databases)

@Component({
	components: {
		PriceView,
	},
})
export default class OfferingVariantSelectView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Array,
	})
	public readonly offerings!: DB.OfferingModel[];

	@Prop({
		required: true,
		type: Object,
	})
	public readonly productModel!: DB.ProductModel;

	protected get currencyModel() {
		return UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
	}

	protected get options() {
		const options: VariantOption[] = [];

		// Build pricing array to see if the prices differ for the selection
		const arrPricing = _.uniq(_.map(
			this.offerings,
			(offering) => {
				const objPrice = PriceCalculator.projectPrice({
					productid: this.productModel.id,
					offeringid: offering.id,
				});
				return objPrice.subTotal;
			},
		));

		this.offerings.forEach((offeringModel) => {
			// Request product price
			const objPrice = PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: offeringModel.id,
			});
			let { subTotal } = objPrice;

			const bulkProductType = AppDataModule.findBulkProductTypeWhere({
				offeringid: offeringModel.id,
			});
			const bulkModel = AppDataModule.bulks.find(
				(model) => model.id === bulkProductType?.bulkid,
			);

			if (bulkModel) {
				const packageSize = OfferingCountForBulkDiscount(
					bulkModel,
					offeringModel,
					objPrice.totalPageCount,
					1,
				);

				const bulkQuantityModel = _.find(
					AppDataModule.bulkquantities,
					(m) => m.bulkid == bulkModel.id && m.from <= packageSize && m.to >= packageSize,
				);
				if (bulkQuantityModel) {
					subTotal *= (1 - bulkQuantityModel.relative / 100);
				}
			}

			const optionText = AppDataModule.getOfferingVariantName(offeringModel.id);
			const option: VariantOption = {
				id: offeringModel.id,
				text: optionText,
			};

			// If prices are different, add it to the selection choice text
			if (arrPricing.length > 1) {
				option.price = subTotal;
			}

			if (offeringModel.variantthumbnail) {
				option.image = offeringModel.variantthumbnail;
			}

			options.push(option);
		});

		return options;
	}

	protected updated() {
		this.$emit('updateDialog');
	}

	protected imageLoaded() {
		this.$emit('updateDialog');
	}

	protected selectOption(offeringId: number) {
		const offeringModel = this.offerings.find((m) => offeringId == m.id);

		if (offeringModel) {
			this.$emit(
				'select',
				new ServiceEvent({
					type: 'select',
					payload: offeringModel,
				}),
			);
		}

		this.$emit('closeDialog');
	}
}
