import merge from 'deepmerge';
import _ from 'underscore';
// @ts-ignore: No declaration file
import vintagejs from 'vintagejs';
import blueimpLoadImage, { LoadImageOptions as blueimpLoadImageOptions, MetaData } from 'blueimp-load-image';
import vintagePresets from 'settings/filters';
import store, { AppStateModule } from 'store/index';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import faceRecognition from 'controllers/face-recognition';
import detectBrowser from 'tools/detect-browser';

export interface LoadImageOptions {
	/** Allow loading the image from the browser cache? */
	cache?: boolean;
	/** The following enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
	 * CORS-enabled images can be reused in the <canvas> element without being tainted.
    */
	crossOrigin?: string | null;
	/** Which image effect to apply to the loaded photo? */
	effect?: string | null;
	/** Perform face recognition and return bounded box? */
	faceRecognition?: boolean;
	/** If input is File, should we force a copy of the loaded object?
	 * In case we need to use the image.src in the resolve callback
	 * we should set this to true (as object url will be revoked)
	*/
	forceCopy?: boolean;
	/** Parse and return meta data in image? */
	meta: boolean;
	/** Use orientation number to rotate the photo to a normalized orientation */
	orientation: number | boolean;
	/** In what image format should the output loaded image be? */
	outputFormat: 'jpeg' | 'png';
	/** How many image loading processes should we run parallel at max? */
	parallel: number;
	/** Set maximum pixel size for width and height of photo */
	scale?: number;
	/** Apply tools to minimize aliasing? */
	smoothing?: boolean;
}

interface ReturnMetaData extends MetaData {
	faces?: {
		boundingBox?: {
			x1: number;
			x2: number;
			y1: number;
			y2: number;
		};
	};
}
interface ReturnData {
	image: HTMLImageElement;
	data?: ReturnMetaData;
}

function validateImage(
	image: HTMLImageElement,
): boolean {
	// Always reset the onload handler, to avoid memory leaks in older browsers (by circular reference)
	image.onload = null;

	// Size check
	if (image.src.split('.').pop() == 'svg' && detectBrowser().name == 'Microsoft Internet Explorer') {
		// Ignore, IE11 returns 0 width and height for svg images
	} else if ('naturalHeight' in image) {
		if (image.naturalHeight + image.naturalWidth === 0) {
			return false;
		}
	// @ts-ignore: Typescript for whatever reason believes this will never happen
	} else if (image.width + image.height === 0) {
		return false;
	}

	// Complete check
	if (typeof image.complete !== 'undefined' && image.complete === false) {
		return false;
	}

	// Validated: now also remove onerror handler
	image.onerror = null;

	return true;
}

function imageLoaded(
	image: HTMLImageElement | HTMLCanvasElement,
	options: LoadImageOptions,
	data?: MetaData,
	faceBox?: {
		x1?: number|null;
		x2?: number|null;
		y1?: number|null;
		y2?: number|null;
	},
): Promise<ReturnData> {
	if (image instanceof HTMLCanvasElement) {
		// Convert canvas element to image element
		const base64 = image.toDataURL(`image/${options.outputFormat}`);

		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				imageLoaded(
					img,
					options,
					data,
					faceBox,
				).then(
					resolve,
					reject,
				);
			};
			img.onerror = () => {
				reject(new Error('Could not convert canvas to image element'));
			};
			img.src = base64;
		});
	}

	if (!validateImage(image)) {
		throw new Error('Invalid image');
	}

	if (!faceBox
		&& options.faceRecognition
	) {
		return faceRecognition.detectFaceBox(image)
			.catch(() => ({}))
			.then((boundingBox) => imageLoaded(
				image,
				options,
				data,
				boundingBox,
			));
	}

	const returnMetaData: ReturnMetaData = _.extend(
		{
			faces: {
				boundingBox: faceBox,
			},
		},
		data,
	);

	return Promise.resolve({
		image,
		data: returnMetaData,
	});
}

function loadImageWithBlueimp(
	input: string|File,
	blueimpOptions: blueimpLoadImageOptions,
): Promise<{
		image: HTMLImageElement | HTMLCanvasElement;
		data: blueimpLoadImage.MetaData | undefined;
	}> {
	return new Promise((resolve, reject) => {
		blueimpLoadImage(
			input,
			(image, data) => {
				if (image instanceof HTMLImageElement || image instanceof HTMLCanvasElement) {
					resolve({ image, data });
				} else {
					// An error occured loading the image

					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							new Error('Could not load the image with blueimp'),
							(event) => {
								event.severity = 'warning';
								event.addMetadata(
									'imageData',
									{
										...blueimpOptions,
										src: typeof input,
									},
								);
							},
						);
					}

					reject(new Error('Could not load the image'));
				}
			},
			blueimpOptions,
		);
	});
}

export default function loadImage(
	input: string|File,
	opts?: Partial<LoadImageOptions>,
): Promise<ReturnData> {
	const defaults: LoadImageOptions = {
		cache: true, // allow getting the image from the cache (no cache-busting)
		meta: false,
		orientation: false,
		crossOrigin: window.glPlatform != 'server' && typeof input === 'string' && input.substring(
			0,
			4,
		) == 'http' ? 'Anonymous' : null,
		scale: 0,
		parallel: 5,
		effect: null,
		forceCopy: false,
		outputFormat: 'jpeg',
	};
	const options: LoadImageOptions = opts ? merge(
		defaults,
		opts,
	) : defaults;

	if (!input || (typeof input === 'string' && input.length === 0)) {
		return Promise.reject(new Error(ERRORS_INVALID_REQUEST_DATA));
	}

	if (AppStateModule.imageLoadCount > options.parallel) {
		// Maximum of five parallel image loads
		return new Promise((resolve, reject) => {
			const f = store.watch(
				(state) => state.appstate.imageLoadCount,
				(imageLoadCount) => {
					if (imageLoadCount < options.parallel) {
						f();
						loadImage(
							input,
							options,
						).then(
							resolve,
							reject,
						);
					}
				},
			);
		});
	}

	if (typeof input === 'string' && !options.cache) {
		// Add cache busting parameter to url
		input += `?${new Date().getTime()}`;
	}

	// @ts-ignore
	const blueimpOptions: blueimpLoadImageOptions = {
		canvas: options.smoothing || (input instanceof File && options.forceCopy),
		meta: options.meta,
		orientation: options.orientation,
	};
	if (options.crossOrigin) {
		blueimpOptions.crossOrigin = options.crossOrigin;
	}
	if (options.scale) {
		blueimpOptions.maxHeight = options.scale;
		blueimpOptions.maxWidth = options.scale;
	}

	AppStateModule.increaseImageLoad();

	return loadImageWithBlueimp(
		input,
		blueimpOptions,
	)
		.then(({ image, data }) => {
			if (!options.effect || store.state.config['features.imageEffects'].length === 0) {
				return imageLoaded(
					image,
					options,
					data,
				);
			}

			if (!vintagePresets.hasOwnProperty(options.effect)) {
				throw new Error('Image effect not known to vintagejs');
			}

			return vintagejs(
				image,
				vintagePresets[options.effect],
			)
				.then((res: any) => res.genImage())
				.then((genImage: HTMLImageElement) => imageLoaded(
					genImage,
					options,
					data,
				))
				.catch(() => {
					// An error occured loading the image
					throw new Error('Could not generate vintagejs image');
				});
		})
		.finally(() => {
			AppStateModule.decreaseImageLoad();
		});
}
