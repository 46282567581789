import './defines';
import { CloudAlbumModel } from 'controllers/connector';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class CloudAlbumView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly albumModel!: CloudAlbumModel;

	private img: string | null = null;

	protected error = false;

	protected get styleObject() {
		return {
			'background-image': `url(${this.img})`,
		};
	}

	protected created() {
		if (this.albumModel.thumbnail) {
			const newImg = new Image();
			newImg.onload = () => {
				this.img = this.albumModel.thumbnail || null;
			};
			newImg.onerror = () => {
				this.error = true;
			};
			newImg.src = this.albumModel.thumbnail;
		} else {
			this.error = true;
		}
	}

	protected selectAlbum() {
		this.$emit(
			'selectAlbum',
			this.albumModel.id,
		);
	}
}
