import experiment from 'controllers/experiment';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import loadPhotoObject from 'mutations/pageobject/load';
import loadObjectMask, { LoadObjectMaskOptions } from 'mutations/pageobject/load-mask';
import loadImage from 'services/load-image';
import { OfferingGroups } from 'settings/offerings';
import {
	COVER_BACK, COVER_BACK_INSIDE, COVER_FRONT, COVER_FRONT_INSIDE,
} from 'settings/values';
import {
	FontModule,
	ProductStateModule,
} from 'store';
import getBucketUrl from 'tools/get-bucket-url';
import getCanvasSize from 'tools/get-canvas-size';
import getColorInverse from 'tools/get-color-inverse';
import getScaledFilename from 'tools/get-scaled-filename';
import _ from 'underscore';
import {
	Component,
	Prop,
	Provide,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import DrawView from './draw';

@Component({
	components: {
		DrawView,
	},
})
export default class FlatPageView extends Vue {
	@Ref('canvas') readonly paintedCanvas!: HTMLCanvasElement;

	@Ref('scaledCanvas') readonly scaledCanvas!: HTMLCanvasElement;

	@Prop({ default: null, type: Object })
	readonly addressModel!: DB.AddressModel;

	@Prop({ default: false, type: Boolean })
	readonly instant!: boolean;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ default: 'low', type: String })
	readonly resolution!: 'thumb'|'low'|'high';

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: false, type: Boolean })
	readonly showBleed!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showMask!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showLoaders!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showOverlay!: boolean;

	// Allows any child component to `inject: ['canvas']` and have access to it.
	// This is the CanvasRenderingContext that children will draw to.
	@Provide('canvas')
		canvas: {
		context: CanvasRenderingContext2D|null;
	} = {
				context: null,
			};

	get bleedMargin() {
		return this.showBleed && this.pageModel.offset ? this.pageModel.offset : 0;
	}

	get canvasHeight() {
		return Math.round(
			(this.pageModel.height + 2 * this.bleedMargin) * this.scaling,
		);
	}

	get canvasWidth() {
		return Math.round(
			(this.pageModel.width + 2 * this.bleedMargin) * this.scaling,
		);
	}

	get errorObjects() {
		return _.filter(
			this.objectModels,
			(objectModel) => objectModel._error >= 3,
		);
	}

	get flippedOverlay() {
		if (!this.offeringModel) {
			return false;
		}

		if (this.pageModel.id === COVER_BACK) {
			return true;
		}
		if (this.pageModel.id === COVER_FRONT) {
			return false;
		}
		if (this.pageModel.id === COVER_FRONT_INSIDE) {
			return true;
		}
		if (this.pageModel.id === COVER_BACK_INSIDE) {
			return false;
		}

		return !!(OfferingGroups(
			this.offeringModel.groupid,
			['BookTypes', 'PhotoSheets'],
		) && (this.pageIndex + this.offeringModel.startright) % 2 === 0);
	}

	get fullCanvasHeight() {
		return Math.round(this.pageModel.height + 2 * this.bleedMargin);
	}

	get fullCanvasWidth() {
		return Math.round(this.pageModel.width + 2 * this.bleedMargin);
	}

	get inverseColor() {
		return getColorInverse(this.pageModel.bgcolor || '#FFFFFF');
	}

	get isReady() {
		// Check if the page background image is loaded and return false otherwise
		if (this.pageModel.bgimage && this.pageModel.bgimage.length && !this.pageModel._bgimage) {
			return false;
		}

		// Check if the page background pattern image is loaded and return false otherwise
		if (this.pageModel.bgpattern && this.pageModel.bgpattern.length && !this.pageModel._bgpattern) {
			return false;
		}

		// Check if the offering overlay image is loaded and return false otherwise
		if (this.showOverlay
			&& !this.overlayImage
			&& (!this.offeringModel || this.offeringModel.overlay)
		) {
			return false;
		}

		// Check if all page object assets are loaded and return false otherwise
		if (this.unloadedObjects.length) {
			return false;
		}

		// Check if the page has an address to show and the font is loaded
		if (this.addressModel) {
			const fontModel = FontModule.getById('Reenie Beanie');
			if (!fontModel || !fontModel._loaded) {
				return false;
			}
		}

		// Check if there were no errors loading objects
		if (this.errorObjects.length) {
			return false;
		}

		return true;
	}

	get maxCanvasHeight() {
		if (this.useDownSampling) {
			const canvasSize = getCanvasSize(
				this.fullCanvasWidth,
				this.fullCanvasHeight,
			);

			return canvasSize.height;
		}

		return this.canvasHeight;
	}

	get maxCanvasWidth() {
		if (this.useDownSampling) {
			const canvasSize = getCanvasSize(
				this.fullCanvasWidth,
				this.fullCanvasHeight,
			);

			return canvasSize.width;
		}

		return this.canvasWidth;
	}

	get maxScale() {
		return this.maxCanvasWidth / this.fullCanvasWidth;
	}

	get objectModels() {
		return ProductStateModule.getPageObjects(this.pageModel);
	}

	get offeringModel() {
		return ProductStateModule.getOffering;
	}

	get offeringMaskImage() {
		return ProductStateModule.getMaskImage;
	}

	get overlayImage() {
		return ProductStateModule.getOverlayImage;
	}

	get pageIndex() {
		return ProductStateModule.getPageIndex(this.pageModel);
	}

	get unloadedObjects() {
		return _.filter(
			this.objectModels,
			(objectModel) => !this.isObjectLoaded(objectModel),
		);
	}

	get useDownSampling() {
		if (this.scaling >= 1) {
			return false;
		}

		return true;
	}

	created() {
		this.loadAssets();

		if (this.instant) {
			this.unloadedObjects.forEach((objectModel) => {
				this.loadObjectAsset(objectModel);
			});
		}
	}

	mounted() {
		// We can't access the rendering context until the canvas is mounted to the DOM.
		// Once we have it, provide it to all child components.
		const canvas = this.$refs.canvas as HTMLCanvasElement;
		this.canvas.context = canvas.getContext('2d');
	}

	isObjectLoaded(objectModel: PI.PageObjectModel) {
		if (objectModel.type == 'text') {
			if (!objectModel.fontface) {
				return false;
			}

			const fontModel = FontModule.getById(objectModel.fontface);
			if (!fontModel || !fontModel._loaded) {
				return false;
			}
		} else if (objectModel.type == 'photo') {
			if (!objectModel._image) {
				return false;
			} if (objectModel.mask && !objectModel._canvas) {
				return false;
			} if (objectModel.borderimage && !objectModel._borderimage) {
				return false;
			}
		}

		return true;
	}

	@Watch(
		'pageModel',
		{ deep: true },
	)
	@Watch(
		'objectModels',
		{ deep: true },
	)
	@Watch('overlayImage')
	@Watch('offeringMaskImage')
	loadAssets() {
		let loaded = true;

		if (this.errorObjects.length) {
			this.$emit(
				'error',
				new Error('error loading objects'),
			);
			loaded = false;
		} else if (
			this.pageModel.bgimage
			&& this.pageModel.bgimage.length > 0
			&& !this.pageModel._bgimage
		) {
			this.loadPageAsset('bgimage');
			loaded = false;
		} else if (
			this.pageModel.bgpattern
			&& this.pageModel.bgpattern.length > 0
			&& !this.pageModel._bgpattern
		) {
			this.loadPageAsset('bgpattern');
			loaded = false;
		} else if (this.unloadedObjects.length) {
			this.loadObjectAsset(this.unloadedObjects[0]);
			loaded = false;
		} else if (this.addressModel) {
			const fontModel = FontModule.getById('Reenie Beanie');
			if (fontModel && !fontModel._loaded) {
				FontModule
					.loadModel('Reenie Beanie')
					.catch(() => {
						// Swallow error: no action required
					})
					.finally(this.loadAssets);
				loaded = false;
			}
		} else {
			if (!this.pageModel.bgimage) {
				ProductStateModule.changePage({
					id: this.pageModel.id,
					_bgimage: null,
				});
			}
			if (!this.pageModel.bgpattern) {
				ProductStateModule.changePage({
					id: this.pageModel.id,
					_bgpattern: null,
				});
			}
		}

		if (this.showOverlay
			&& !this.overlayImage
			&& (!this.offeringModel || this.offeringModel.overlay)
		) {
			ProductStateModule.loadOverlay();
			loaded = false;
		}

		if (this.showMask
			&& !this.offeringMaskImage
			&& (!this.offeringModel || this.offeringModel.mask)
		) {
			loaded = false;
		}

		if (loaded) {
			this.$nextTick(() => {
				if (this.useDownSampling) {
					// Draw the full scale canvas onto the scaled canvas
					this.scaledCanvas.getContext('2d')?.drawImage(
						this.paintedCanvas,
						0,
						0,
						this.maxCanvasWidth,
						this.maxCanvasHeight,
						0,
						0,
						this.canvasWidth,
						this.canvasHeight,
					);
				}

				this.$emit('painted');
			});
		}
	}

	loadPageAsset(type: 'bgimage'|'bgpattern') {
		let src = '';

		// if photo has not been downloaded, load from source
		const propertyValue = this.pageModel[type];
		if (propertyValue && propertyValue.substring(
			0,
			4,
		) == 'http') {
			const scaling = type == 'bgpattern'
				? 1
				: this.pageModel.scaling;

			if (this.resolution == 'high' && scaling > 1) {
				src = getScaledFilename(
					propertyValue,
					scaling,
				);
			} else {
				src = propertyValue;
			}
		} else if (propertyValue) {
			const bucketURL = getBucketUrl('appfiles');
			if (this.resolution == 'high') {
				src = getScaledFilename(
					bucketURL + propertyValue,
					2,
				);
			} else {
				src = bucketURL + propertyValue;
			}
		}

		const k = `_${type}` as '_bgimage'|'_bgpattern';
		const objChange: OptionalExceptFor<PI.PageModel, 'id'> = {
			id: this.pageModel.id,
		};

		loadImage(src).then(
			({ image }) => {
				objChange[k] = image;
				ProductStateModule.changePage(objChange);
			},
			() => {
			// Check if page background image reference has not already been removed
				if (this.pageModel[k]) {
				// Reset page background image reference
					objChange[k] = null;
					ProductStateModule.changePage(objChange);
				}
			},
		);
	}

	loadObjectAsset(objectModel: PI.PageObjectModel) {
		if (objectModel.type === 'photo') {
			loadPhotoObject(
				objectModel,
				{
					resolution: this.resolution,
					scaling: this.scaling,
				},
			);

			if (
				objectModel.mask
				&& objectModel._image
				&& !objectModel._canvas
			) {
				const dynamicPhotoScaledUsed = experiment.getFlagValue('flag_dynamic_photo_scaling');
				const loadObjectMaskOptions: LoadObjectMaskOptions = {
					resolution: this.resolution,
				};

				if (dynamicPhotoScaledUsed) {
					loadObjectMaskOptions.dynamicWidth = objectModel._image.width;
				}

				loadObjectMask(
					objectModel,
					loadObjectMaskOptions,
				);
			}
		} else if (
			objectModel.type === 'text'
			&& objectModel.fontface
		) {
			FontModule
				.loadModel(objectModel.fontface)
				.catch(() => {
					const closeError = this.$openErrorDialog({
						body: {
							content: this.$t('dialogTextLoadError'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: this.$t('dialogButtonOk'),
									click: () => {
										this.loadObjectAsset(objectModel);
										closeError();
									},
								},
							],
						},
					});
				});
		}
	}
}
