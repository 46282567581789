import _ from 'underscore';
import merge from 'deepmerge';
import Vue from 'vue';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import * as DB from 'interfaces/database';
import { AjaxOptions } from 'interfaces/app';
import { ERRORS_INVALID_REQUEST_DATA } from '../../../settings/errors';
import ajax from '../../../controllers/ajax';

@Module({ namespaced: true, name: 'address' })
export default class Addresses extends VuexModule {
	collection: DB.AddressModel[] = [];

	fetched = false;

	modelUrl = '/api/address';

	offset = 0;

	totalRecords: number | null = null;

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	@Mutation
	private _addModel(data: DB.AddressModel) {
		this.collection.push(data);
	}

	@Mutation
	public updateModel(data: OptionalExceptFor<DB.AddressModel, 'id'>) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		Vue.set(
			this.collection,
			i,
			_.extend(
				model,
				data,
			),
		);
	}

	@Mutation
	public updateModels(data: Partial<DB.AddressModel>) {
		_.each(
			this.collection,
			(model, i) => {
				Vue.set(
					this.collection,
					i,
					_.extend(
						model,
						data,
					),
				);
			},
		);
	}

	@Action({ rawError: true })
	public addModel(data: DB.AddressModel): Promise<DB.AddressModel> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getById(data.id)) {
					commit(
						'updateModel',
						data,
					);
				} else {
					commit(
						'_addModel',
						data,
					);
				}

				resolve(getters.getById(data.id));
			}
		});
	}

	@Action
	public addModels(arrData: DB.AddressModel[]) {
		const { dispatch } = this.context;

		_.each(
			arrData,
			(data) => {
				dispatch(
					'addModel',
					data,
				);
			},
		);
	}

	@Action({ rawError: true })
	public createModel({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: Partial<DB.AddressModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.AddressModel> {
		const { dispatch, rootState } = this.context;

		return new Promise((resolve, reject) => {
			if (!data) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else if (data.userid != rootState.user.id) {
				reject(new Error('Invalid userid'));
			} else {
				const defaultRequestOptions: AxiosRequestConfig = {
					method: 'post',
					url: this.modelUrl,
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data,
				};
				const defaultMethodOptions: AjaxOptions = {
					auth: true,
					retry: 1,
					debug: {
						offline: true,
						dialog: true,
						abort: false,
					},
				};

				requestOptions = requestOptions
					? merge(
						defaultRequestOptions,
						requestOptions,
					)
					: defaultRequestOptions;
				methodOptions = methodOptions
					? merge(
						defaultMethodOptions,
						methodOptions,
					)
					: defaultMethodOptions;

				ajax
					.request(
						requestOptions,
						methodOptions,
					)
					.then((response) => dispatch(
						'addModel',
						response.data,
					))
					.then(resolve)
					.catch(reject);
			}
		});
	}

	@Action({ rawError: true })
	public saveModel({
		id,
		model,
		requestOptions,
		methodOptions,
	}: {
		id?: number;
		model?: DB.AddressModel;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}) {
		const { dispatch, getters, rootState } = this.context;

		return new Promise((resolve, reject) => {
			if (id) {
				const m = getters.getById(id);
				if (m) model = m;
			}

			if (!model) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else if (!model.userid || model.userid != rootState.user.id) {
				reject(new Error('Invalid userid'));
			} else {
				const defaultRequestOptions: AxiosRequestConfig = {
					method: 'put',
					url: `${this.modelUrl}/${model.id}`,
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data: model,
				};
				const defaultMethodOptions: AjaxOptions = {
					auth: true,
					retry: 1,
					debug: {
						offline: true,
						dialog: true,
						abort: false,
					},
				};

				requestOptions = requestOptions
					? merge(
						defaultRequestOptions,
						requestOptions,
					)
					: defaultRequestOptions;
				methodOptions = methodOptions
					? merge(
						defaultMethodOptions,
						methodOptions,
					)
					: defaultMethodOptions;

				ajax
					.request(
						requestOptions,
						methodOptions,
					)
					.then((response) => {
						dispatch(
							'addModel',
							response.data,
						);
						resolve(getters.getById(response.data.id));
					})
					.catch(reject);
			}
		});
	}
}
