interface EffectAttributes {
	correctRatio?: boolean;
	cropwidth?: number;
	cropheight?: number;
	cropx?: number;
	cropy?: number;
	photoScale?: number;
}

interface EffectOptions {
	lockAspectRatio?: boolean;
	src: string;
}

const loadImage = (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
	const img = new Image();
	img.onload = () => resolve(img);

	if (src.substring(
		0,
		4,
	) == 'http') {
		img.crossOrigin = 'anonymous';
	}

	img.onerror = (err) => reject(err);
	img.src = src;
});

const createCanvasAndCtxFromImage = (
	el: HTMLImageElement,
	w?: number,
	h?: number,
): [HTMLCanvasElement, CanvasRenderingContext2D] => {
	const canvas = document.createElement('canvas');
	const width = w || el.width;
	const height = h || el.height;

	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext('2d');
	if (ctx) {
		ctx.drawImage(
			el,
			0,
			0,
			width,
			height,
		);
		return [canvas, ctx];
	}

	throw new Error('Could not find 2D Rendering Context');
};

const cloneCanvasAndCtx = (source: HTMLCanvasElement) => {
	const { width, height } = source;
	const target = document.createElement('canvas');
	target.width = width;
	target.height = height;
	const targetCtx = target.getContext('2d');
	if (targetCtx) {
		targetCtx.drawImage(
			source,
			0,
			0,
			width,
			height,
		);
		return [target, targetCtx];
	}

	throw new Error('Could not find 2D Rendering Context');
};

const getCanvasAndCtx = (el: HTMLImageElement | HTMLCanvasElement) => {
	if (el instanceof HTMLImageElement) {
		return createCanvasAndCtxFromImage(el);
	}
	if (el instanceof HTMLCanvasElement) {
		cloneCanvasAndCtx(el);
	}

	throw new Error(
		`Unsupported source element. Expected HTMLCanvasElement or HTMLImageElement, got ${typeof el}.`,
	);
};

const getResult = (canvas: HTMLCanvasElement) => ({
	getDataURL() {
		return canvas.toDataURL(
			'image/png',
			1,
		);
	},
	getCanvas() {
		return canvas;
	},
	genImage() {
		return loadImage(canvas.toDataURL(
			'image/png',
			1,
		));
	},
});

const applyEffect = (
	options: EffectOptions,
	attrs?: EffectAttributes,
) => ([
	canvas,
	ctx,
]: [
	HTMLCanvasElement,
	CanvasRenderingContext2D,
]): Promise<[{
		getDataURL: () => string;
		getCanvas: () => HTMLCanvasElement;
		genImage: () => Promise<HTMLImageElement>;
	}, {
		x: number;
		y: number;
		width: number;
		height: number;
	}]> => loadImage(options.src)
	.then((img) => {
		const fixedRatio = !!(
			options.lockAspectRatio
			&& attrs?.correctRatio
		);
		let width = (
			(!fixedRatio && attrs && attrs.cropwidth)
				? attrs.cropwidth
				: canvas.width
		);
		let height = (
			(!fixedRatio && attrs && attrs.cropheight)
				? attrs.cropheight
				: canvas.height
		);
		const canvasWidth = (
			attrs?.photoScale
				? canvas.width / attrs.photoScale
				: canvas.width
		);
		const canvasHeight = (
			attrs?.photoScale
				? canvas.height / attrs.photoScale
				: canvas.height
		);

		if (fixedRatio) {
			const widthRatio = width / img.width;
			const heightRatio = height / img.height;

			if (widthRatio < heightRatio) {
				// width is leading
				height = widthRatio * img.height;
			} else {
				// height is leading
				width = heightRatio * img.width;
			}
		}

		let x = (canvasWidth - width) / 2;
		let y = (canvasHeight - height) / 2;

		if (
			attrs
			&& typeof attrs.cropx !== 'undefined'
			&& attrs.cropwidth
		) {
			x = Math.min(
				canvasWidth - width,
				((attrs.cropwidth - width) / 2) + attrs.cropx,
			);
		}
		if (
			attrs
			&& typeof attrs.cropy !== 'undefined'
			&& attrs.cropheight
		) {
			y = Math.min(
				canvasHeight - height,
				((attrs.cropheight - height) / 2) + attrs.cropy,
			);
		}

		x = Math.max(
			0,
			x,
		);
		y = Math.max(
			0,
			y,
		);

		ctx.globalCompositeOperation = 'destination-in';
		ctx.drawImage(
			img,
			x,
			y,
			width,
			height,
		);

		return [
			getResult(canvas),
			{
				x,
				y,
				width,
				height,
			},
		];
	});

const applyMask = (
	src: string | HTMLImageElement | HTMLCanvasElement,
	options: EffectOptions,
	attrs?: EffectAttributes,
) => {
	const genSource = typeof src === 'string'
		? loadImage(src).then(getCanvasAndCtx)
		: Promise.resolve(getCanvasAndCtx(src));

	return genSource.then(applyEffect(
		options,
		attrs,
	));
};

export default applyMask;
