import { nanoid } from 'nanoid';

const UserDataModel = {
	credit: {
		currency: null,
		balance: 0,
	},
	_deviceId: nanoid(),
	loyalty: null,
	referralVoucher: null,
};

export default UserDataModel;
