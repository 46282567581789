import _ from 'underscore';
import merge from 'deepmerge';
import Vue from 'vue';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import * as DB from 'interfaces/database';
import { AjaxOptions } from 'interfaces/app';
import { ERRORS_INVALID_REQUEST_DATA } from '../../../settings/errors';
import ajax from '../../../controllers/ajax';

@Module({ namespaced: true, name: 'subscriptions' })
export default class Subscriptions extends VuexModule {
	collection: DB.SubscriptionModel[] = [];

	fetched = false;

	modelUrl = '/api/subscription';

	offset = 0;

	totalRecords: number | null = null;

	private get collectionUrl() {
		return `/api/user/${this.context.rootState.user.id}/subscriptions`;
	}

	public get findWhere() {
		return (properties: Partial<DB.SubscriptionModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	@Mutation
	private _addModel(data: DB.SubscriptionModel) {
		this.collection.push(data);
	}

	@Mutation
	private _addOffset(number: number) {
		this.offset += number;
	}

	@Mutation
	private _setFetched(flag: boolean) {
		this.fetched = flag;
	}

	@Mutation
	private _setTotalRecords(number: number) {
		this.totalRecords = number;
	}

	@Mutation
	private _updateModel(data: DB.SubscriptionModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		Vue.set(
			this.collection,
			i,
			_.extend(
				model,
				data,
			),
		);
	}

	@Action({ rawError: true })
	public addModel(data: DB.SubscriptionModel): Promise<DB.SubscriptionModel> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getById(data.id)) {
					commit(
						'_updateModel',
						data,
					);
				} else {
					commit(
						'_addModel',
						data,
					);
				}

				resolve(getters.getById(data.id));
			}
		});
	}

	@Action
	public addModels(arrData: DB.SubscriptionModel[]) {
		const { dispatch } = this.context;

		_.each(
			arrData,
			(data) => {
				dispatch(
					'addModel',
					data,
				);
			},
		);
	}

	@Action({ rawError: true })
	public createModel({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: RequiredExceptFor<DB.SubscriptionModel, 'id'>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.SubscriptionModel> {
		const { dispatch } = this.context;

		return new Promise((resolve, reject) => {
			if (!data) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else if (data.userid != this.context.rootState.user.id) {
				reject(new Error('Invalid userid'));
			} else {
				const defaultRequestOptions: AxiosRequestConfig = {
					method: 'post',
					url: this.modelUrl,
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data,
				};
				const defaultMethodOptions: AjaxOptions = {
					auth: true,
					retry: 1,
					debug: {
						offline: true,
						dialog: true,
						abort: false,
					},
				};

				requestOptions = requestOptions
					? merge(
						defaultRequestOptions,
						requestOptions,
					)
					: defaultRequestOptions;
				methodOptions = methodOptions
					? merge(
						defaultMethodOptions,
						methodOptions,
					)
					: defaultMethodOptions;

				ajax
					.request(
						requestOptions,
						methodOptions,
					)
					.then((response) => dispatch(
						'addModel',
						response.data,
					))
					.then(resolve)
					.catch(reject);
			}
		});
	}

	@Action({ rawError: true })
	public fetch({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		const { getters, commit, dispatch } = this.context;

		return new Promise((resolve, reject) => {
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'get',
				url: getters.collectionUrl,
				params: {
					offset: this.offset,
					limit: 20,
					orderby: 'id DESC',
				},
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				debug: {
					offline: true,
					dialog: true,
					abort: true,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			const { limit } = requestOptions.params;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					_.each(
						response.data,
						(model) => {
							dispatch(
								'addModel',
								model,
							);
						},
					);

					commit(
						'_addOffset',
						limit,
					);
					commit(
						'_setFetched',
						true,
					);

					// Set total records and limit to collection properties
					if (response.headers.hasOwnProperty('x-total-records')) {
						commit(
							'_setTotalRecords',
							Math.max(
								0,
								parseInt(
									response.headers['x-total-records'],
									10,
								),
							),
						);
					}

					resolve();
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	public saveEvent({
		data,
	}: {
		data: RequiredExceptFor<DB.SubscriptionEventModel, 'id'>;
	}) {
		return new Promise((resolve, reject) => {
			if (!data) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				ajax
					.request(
						{
							method: 'post',
							url: '/api/subscriptionevent',
							headers: {
								'content-type': 'application/json; charset=utf-8',
							},
							data,
						},
						{
							auth: true,
							retry: 1,
							debug: {
								abort: true,
								dialog: true,
							},
						},
					)
					.then(
						resolve,
						reject,
					);
			}
		});
	}
}
