import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import { ConnectorPhotosResponseData } from 'controllers/connector';
import * as DB from 'interfaces/database';
import moment from 'moment';
import {
	PhotosModule,
	UserModule,
} from 'store';
import parseUrl from 'tools/parse-url';

function replaceCloudLocalUrl(url: string): string {
	const parsedUrl = parseUrl(url);
	url = url.replace(
		parsedUrl.host,
		`${window.location.hostname}:${window.location.port}`,
	);
	url = url.replace(
		parsedUrl.protocol,
		window.location.protocol,
	);

	return url;
}

class AppClass {
	static photos(options: {
		nextPage?: string;
		fromDate?: string;
		toDate?: string;
		category?: DB.PhotoModel['type'];
	}): Promise<ConnectorPhotosResponseData> {
		return new Promise((resolve, reject) => {
			let url = options.nextPage
				? options.nextPage
				: `/api/user/${UserModule.id}/photos`;
			const data: {
				orderby: string;
				after?: string;
				before?: string;
				where?: string;
			} = {
				orderby: 'id DESC',
			};

			if (
				window.glStack === 'local'
				&& options.nextPage
			) {
				url = replaceCloudLocalUrl(url);
			}

			if (options.fromDate) {
				data.after = moment(options.fromDate).format('X');
			}
			if (options.toDate) {
				data.before = moment(options.toDate).format('X');
			}
			if (options.category) {
				data.where = JSON.stringify({
					type: options.category,
				});
			}

			const requestOptions: AxiosRequestConfig = {
				url,
				method: 'get',
				params: data,
			};
			Object.defineProperty(
				requestOptions,
				'url',
				{
					enumerable: true,
					get() {
						if (options.nextPage) {
							if (window.glStack !== 'local') {
								return options.nextPage;
							}

							return replaceCloudLocalUrl(options.nextPage);
						}

						return `/api/user/${UserModule.id}/photos`;
					},
				},
			);

			ajax
				.request(
					requestOptions,
					{
						auth: true,
					},
				)
				.then(
					async (response) => {
						const paging: {
							nextPage?: string;
						} = {};
						// Also add these photos to the user photos collection
						const photoModels = await PhotosModule.addModels(response.data);

						if (response.headers.hasOwnProperty('x-pagination-next')) {
							paging.nextPage = response.headers['x-pagination-next'];
						}

						resolve({
							data: photoModels,
							paging,
						});
					},
					reject,
				);
		});
	}
}

export default AppClass;
