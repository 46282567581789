import './defines';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'LogoColorLimitExceededHeaderView',
})
export default class LogoColorLimitExceededHeaderView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the maximum number of colors allowed in the logo',
		type: Number,
		required: true,
	})
	public readonly value!: number;

	protected onCloseClick(): void {
		this.$emit('close');
	}
}
